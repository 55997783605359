<template>
  <div>
    <h2 class="element-form-title"><i class="fas fa-bullseye"></i>
        {{ $t('ui.sbf.edit') }}
    </h2>

    <SbfForm
      :a3_document="a3_document"
      :sbf="sbf"
      :show_deploy="dataManager.getCapability('sbf.deploy')"
      :child_departments="relations.children"
      :push_a3docs="push_a3docs"
      :dataManager="dataManager"
      :interactionManager="interactionManager"
      @submit="saveSbf"
      @cancel="$router.go(-1)"
    />

  </div>
</template>

<script>

import SbfForm from '@/components/forms/SbfForm.vue';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  mixins: [ WithDocumentManager ],
  components: {
    SbfForm,
  },

  props: {
    sbf_id: {
      type: String,
      required: true,
    },
  },


  data: () => ({
    sbf: {
      id: null,
      a3doc_id: null,
      name: '',
      result_area: null,
      comment_chances_risks: null,
      push_default: null,
      sbf_ref: null,
    },
    push_a3docs: []
  }),

  mounted() {
    this.loadSbf();
  },

  methods: {
    saveSbf() {
      return this.dataManager.saveSbf(this.sbf, this.push_a3docs)
        .then(() => {
          this.interactionManager.backToDocument();
        });
    },

    loadSbf() {
      // This is crucial for the review to be able to save the SBF,
      // It works, but I have no idea why
      this.sbf = { ... this.dataManager.getSbf(this.sbf_id) } ;
      this.push_a3docs = this.sbf.child_refs.map(x => x.a3doc_id);
    },
  }

}
</script>
