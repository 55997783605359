<template>
  <WithMenuContainer>
    <div class="container">
      <h1><i class="fas fa-user-plus"></i> {{ $t('ui.accounts.create_account') }} </h1>

      <div class="element-form">
        <div class="form-field" v-if="$hasFeature('Root')">
          <h3>{{ $t('ui.accounts.for_employee') }} :</h3>
          <strong>{{ person_name  }}</strong>
        </div>

        <div class="form-field">
          <h3>{{ $t('ui.accounts.mail') }} </h3>
          <input class="a3-input" v-model="account.mail" />
          <FormError :error="errors.mail" />
        </div>


        <div style="margin-top: 5px; margin-bottom: 30px;">
            <em>{{ $t('ui.accounts.password_reset_mail') }} </em>
        </div>

      </div>

      <button class="a3-button action" @click="saveAccount">
        <i class="fas fa-plus"></i> {{ $t('ui.general.save') }}
      </button>
    </div>
  </WithMenuContainer>
</template>

<script>

export default {
  data: () => ({
    organisations: [],

    errors: { 'mail': [] },

    account: {
      person_id: null,
      mail: '',
    },
  }),

  props: {
    person_id: {
      type: String,
      required: true,
    },
  },

  computed: {
    person_name() {
      return this.$route.query['person_name'];
    },
  },

  methods: {
    saveAccount() {
      this.account.person_id = parseInt(this.person_id);
      this.$http.post('admin/account', this.account)
        .then(() => {
          this.$router.push(`/organisation/people/${this.person_id}`);
        }, (err) => {
            if (err.body.message == "Invalid mail") {
                this.errors.mail = ["invalid_mail"];
            }
            if (err.body.message == "Account already exists") {
                this.errors.mail = ["account_already_exists"];
            }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/variables.scss";

.a3-table {
  border-spacing: 0;
  width: 100%;

  th {
    padding: 8px;
    border-bottom: 1px $a3-green solid;
  }
  thead {
      margin-bottom: 4px;
  }

  tbody tr {
    td {
      padding: 4px 10px;
    }
    cursor: pointer;
    transition: color .1s ease-in-out, background-color, .1s ease-in-out;

    &:hover {
      background-color: $a3-green;
      color: white;
    }
  }
}

.container {
  max-width: 960px;
  margin: 45px auto;
}
</style>
