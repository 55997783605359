const AuthStore = {
  namespaced: true,
  state: {
    notification_count: 0,
    preferences: {
        v2_preview: false,
    },
    displayOptions: {
      action_pi_numbers: true,
      show_trail: true,
      show_plan_source: false,
      show_pushed_to: false,
      hover_focus: false,
      pi_status: true,
      pi_target: false,
      pi_current_value: false,
      action_check: true,
      action_mood: true,
      action_person: true,
      action_milestone: true,
      action_target_date_milestone: false,
      action_cancelled_strikethrough: true,
      action_tasks: false,
      action_progress: false,
    },
  },

  getters: {
    displayOptions: (state) => state.displayOptions,
    hasPreference: (state) => (preference_key) => state.preferences[preference_key],
    notification_count: (state) => state.notification_count,
  },

  mutations: {
    loadSettings(state, settings) {
        // For now, only load the custom display settings
        let display_keys = Object.keys(settings)
            .filter((x) => x.startsWith(".a3_display.defaults"))
            .map(x => x.split(".")[3]);

        for (let k of display_keys) {
            let setting = settings[`.a3_display.defaults.${k}`];
            state.displayOptions[k] = setting.value > 0;
        }

        console.log(settings);
        state.preferences.v2_preview = settings[".preferences.v2_preview"].value;
        // Further settings can be loaded here
    },

    setDisplayOption(state, options) {
        state.displayOptions[options.option] = options.value;
    },

    setNotificationCount(state, count) {
      state.notification_count = count;
    },
  },
};

export default AuthStore;
