
import Vue from "vue";

import { A3DocumentInteractionManager } from "./index";

export class SbfFilloutInteractionManager implements A3DocumentInteractionManager {
  public a3DocId: number;
  public sbfId: number;
  public router: any;

  private baseUrl: string;

  constructor(a3DocId: number, sbfId: number, router: any) {
    this.a3DocId = a3DocId;
    this.sbfId = sbfId;
    this.router = router;

    this.baseUrl = `/a3/${this.a3DocId}/sbf_fillout/${this.sbfId}`;
  }

  public backToDocument() {
    this.router.push(this.baseUrl);
  }
  public backToA3() {
    this.router.push(`/a3/${this.a3DocId}`);
  }

  public selectSbf(sbfId) {
    this.router.push(`/a3/${this.a3DocId}/sbf_fillout/${sbfId}`);
  }
  public createSbf() { return null; }
  public editSbf(sbfId) { return null; }
  public deleteSbf(sbfId) { return null; }

  // Selection of a kpi isn't logical in this case
  public selectKpi(kpiId) { return null; }
  public createKpi( resultArea = null ) {
    let url = `${this.baseUrl}/kpi/create`;
    if (resultArea) {
      url += `?sbf_id=${this.sbfId}`;
    }
    this.router.push(url);
  }
  public createKpiForSbf(sbfId?: string) {
    let url = `${this.baseUrl}/kpi/create`;
    if (sbfId) {
      url += `?sbf_id=${this.sbfId}`;
    }
    this.router.push(url);
  }
  public editKpi(kpiId) {
    this.router.push( `${this.baseUrl}/kpi/${kpiId}/edit` );
  }

  public selectAction(actionId) { return null }
  public createAction(orgArea = null) {
    this.router.push( `${this.baseUrl}/action/create` );
  }
  public createActionForKpi(kpiId: string) {
    this.router.push( `${this.baseUrl}/action/create?kpi_id=${kpiId}` );
  }
  public updateAction(actionId) {
    this.router.push( `${this.baseUrl}/action/${actionId}/edit` );
  }
}
