<template>
  <WithMenuContainer>
    <div >
      <h1><i class="fas fa-tasks"></i> Actie overzicht </h1>

      <AllTasksReport />
    </div>
  </WithMenuContainer>
</template>


<script>

import AllTasksReport from '@/components/reports/ALlTasksReport.vue';

export default {
  components: {
    AllTasksReport,
  },
}

</script>
