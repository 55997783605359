<template>
  <WithReviewMenuContainer
    :a3document_id="a3document_id"
    :review_id="review_id"
  >

    <div style="max-width: 1120px; margin: 0 auto;"
         class="review-report"
      >
        <h1 class="report-title">
            <i class="fas fa-comment-check"></i>
            {{ $t('ui.review.review') }} ::
            {{ dataManager.review.review.name }}
        </h1>
        <h2 class="report-a3-name">{{ a3_title }}</h2>

        <h2 class="review-part">
            <i class="fas fa-heart"></i>
            {{ $tc('definitions.terms.mission') }}
            &amp;
            <i class="fas fa-road"></i>
            {{ $tc('definitions.terms.vision') }}
        </h2>
        <MissionReview :dataManager="dataManager" />
        <VisionReview :dataManager="dataManager" />

        <h2 class="review-part">
            <i class="fas fa-flag-checkered"></i>
            {{ $tc('definitions.terms.sbf', 2) }}
        </h2>
        <SbfReview
            v-for="sbf in dataManager.getSbfList()"
            :key="'sbf' + sbf.id"
            :sbf_id="sbf.id"
            :dataManager="dataManager" />

        <h2 class="review-part">
            <i class="fas fa-bullseye-arrow"></i>
            {{ $tc('definitions.terms.kpi', 2) }}
        </h2>
        <KpiReview
            v-for="kpi in dataManager.getKpiList()"
            :key="'kpi' + kpi.id"
            :kpi_id="kpi.id"
            :dataManager="dataManager" />

        <h2 class="review-part">
            <i class="fas fa-tasks"></i>
            {{ $tc('definitions.terms.action', 2) }}
        </h2>
        <ActionReview
            v-for="action in dataManager.getActionList()"
            :key="'action' + action.id"
            :action_id="action.id"
            :dataManager="dataManager" />
    </div>

  </WithReviewMenuContainer>
</template>


<script>

import { ReviewDataManager } from '@/services/A3DocumentManagers/data_review';


import WithReviewMenuContainer from '@/views/WithReviewMenuContainer.vue';

import MissionReview from '@/components/review/MissionReview.vue';
import VisionReview from '@/components/review/VisionReview.vue';
import SbfReview from '@/components/review/SbfReview.vue';
import KpiReview from '@/components/review/KpiReview.vue';
import ActionReview from '@/components/review/ActionReview.vue';

export default {
  components: {
    WithReviewMenuContainer,
    MissionReview,
    VisionReview,
    SbfReview,
    KpiReview,
    ActionReview,
  },
  props: {
    a3document_id: {
      type: String,
      required: true,
    },
    review_id: {
      type: String,
      required: true,
    },
  },

  computed: {
    a3_document() {
      if (this.dataManager == null) { return null;}
      return this.dataManager.a3Document;
    },
    a3_title() {
        if (this.a3_document == null) return null;
        let meta = this.a3_document.meta;

        if (this.a3_document.meta.department != null) {
            return `${meta.department.name}, ${meta.timeframe.name}`;
        }

        return `${meta.organisation.name}, ${meta.timeframe.name}`;
    },
  },

  data: () => ({
    dataManager: null,
  }),

  mounted() {
      this.dataManager = new ReviewDataManager(
          parseInt(this.a3document_id), // a3doc id
          parseInt(this.review_id),
          this.$store.getters['auth/person'],
      );
    this.dataManager.loadDocument();
  }
};
</script>

<style lang="scss">

h2.review-part {
    color: $a3-green;
    opacity: .7;
    font-size: 1.8em;
    margin-top: 100px;
}

.review-report .review-row {
  margin-top: 40px;
}
</style>

