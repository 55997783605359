<template>
    <div class="budget-row"
         v-if="total_budget > 0 || total_budget_hours > 0 || hide_zero == false"
         @click="$emit('click')"
      >
        <div class="budget-title">
            <i class="fas fa-chevron-right"></i> {{ action.name }}
        </div>

        <div class="budget-estimate-column group-header">
          <div class="budget-number"
               v-if="show_hours"
            >{{ total_budget_hours }} </div>
          <div class="budget-number"
               v-if="show_resources"
            >
            &euro; {{ $n(total_budget_resources) }}
          </div>
          <div class="budget-number"
               v-if="show_total"
            >&euro; {{ $n(total_budget) }} </div>
        </div>
        <div class="budget-realisation-column" style="display: none;">
        </div>

        <div class="budget-row-inner" v-if="depth == 0">
          <div v-for="g in groups" :key="g.name">
            <div class="budget-subtitle" style="text-align: right">
              <span v-for="name in g.name"
                    :key="name"
                    style="width: 150px; display: inline-block;"
                >
                {{ name }}
              </span>
            </div>

            <div class="budget-estimate-column">
              <div class="budget-number"
                   :class="{ unimportant: g.total_budget_hours == 0}"
                 v-if="show_hours"
                > {{ $n(g.total_budget_hours) }} </div>
              <div class="budget-number"
                   :class="{ unimportant: g.total_budget_resources == 0}"
                 v-if="show_resources"
                >&euro; {{ $n(g.total_budget_resources) }} </div>
              <div class="budget-number"
                   :class="{ unimportant: g.total_budget == 0}"
                   v-if="show_total"
                >&euro; {{ $n(g.total_budget) }} </div>
            </div>
            <div class="budget-realisation-column">
            </div>
          </div>
        </div>

    </div>
</template>

<script>

var groupBy = function(xs, keys) {

    return xs.reduce(function(rv, x) {
          let value_key = keys
            .map(key => x[key]);
          x.value_key = value_key;
          (rv[value_key] = rv[value_key] || []).push(x);
          return rv;
        }, {});
};


export default {
    props: {
        action: {
            type: Object,
            required: true,
        },
        depth: {
            type: Number,
            required: false,
            default: 0
        },
        show_details: {
          type: Array,
          required: false,
          default: () => [ "budget_category" ],
        },
        hide_zero: { type: Boolean, required: false, default: true, },
        show_hours: { type: Boolean, required: false, default: true, },
        show_resources: { type: Boolean, required: false, default: true, },
        show_total: { type: Boolean, required: false, default: true, }
    },

    computed: {
      total_budget_hours() {
        return this.action.budget_entries
          .filter(be => be.budget_type == "hours")
          .filter(be => be.count != null)
          .map(be => parseInt(be.count))
          .reduce((x, y) => x + y, 0)
      },
      total_budget_resources() {
        return this.action.budget_entries
          .filter(be => be.budget_type == "resources")
          .map(be => parseFloat(be.total_price))
          .reduce((x, y) => x + y, 0)
      },
      total_budget() {
        return this.action.budget_entries
          .filter(be => be.total_price != null)
          .map(be => parseFloat(be.total_price))
          .reduce((x, y) => x + y, 0)
      },


      groups() {
        if (this.show_details.length == 0) return [];

        return Object.entries(groupBy(
          this.action.budget_entries,
          this.show_details
        ))
          .map((x) => {
            return {
            value_key: x[1][0].value_key,
            name: this.nameFromValueKey(x[1][0].value_key),
            total_budget_hours: x[1]
                .filter(be => be.budget_type == "hours")
                .filter(be => be.count != null)
                .map(be => parseInt(be.count))
                .reduce((x, y) => x + y, 0),
            total_budget_resources: x[1]
                .filter(be => be.budget_type == "resources")
                .map(be => parseFloat(be.total_price))
                .reduce((x, y) => x + y, 0),
            total_budget: x[1]
                .filter(be => be.total_price != null)
                .map(be => parseFloat(be.total_price))
                .reduce((x, y) => x + y, 0),
            }
          })
        ;
      },
    },

    methods: {
      nameFromValueKey(x) {
        return x
          .map((x, index) => {
            if (x == null) return x;
            if (this.show_details[index] == "task_id") {
              return this.action.tasks.find(t => t.id == parseInt(x)).name;
            } else {
              return x;
            }
          })
          .map(y => y !== null ? y : "-")
        ;
      },
    }
};
</script>


<style lang="scss">

.budget-row {
    padding: 5px 2px;
    margin-bottom: 30px;
    cursor: pointer;

    transition: color .15s ease-out, background-color .15s ease-out;

    &:hover {
        background-color: $a3-green;
        color: white;
        .budget-number {
            transition: border-color .15s ease-out;
            border-color: white;
        }
    }

    .budget-title {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
    }
    .budget-subtitle {
        box-sizing: border-box;
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        font-style: italic;
        padding-right: 15px;
    }
    .budget-estimate-column {
        width: 25%;
        display: inline-block;
        vertical-align: middle;
        > div { text-align: right;}

        &.group-header {
            font-weight: bold;
            font-size: 1.1em;
            .budget-number{
                border-width: 0px 0px 1px 0px;
                border-radius: 0;
            }
        }
    }
    .budget-realisation-column {
        width: 25%;
        display: inline-block;
        vertical-align: middle;
    }

    .budget-number {
        border: 1px $a3-green solid;
        height: 1.7em;
        border-radius: 2px;
        text-align: right;

        &.unimportant {
          opacity: .3;
        }
    }
    .budget-number, .budget-number-header {
        display: inline-block;
        padding: 4px 3px;
        box-sizing: border-box;
        width: 100px;
        margin: 2px 10px;
    }

    .budget-row-inner > div {
      animation: budgetFadeIn .3s ease-out;
      animation-fill-mode: backwards;

      &:nth-child(2) { animation-delay: .05s; }
      &:nth-child(3) { animation-delay: .1s; }
      &:nth-child(4) { animation-delay: .15s; }
      &:nth-child(5) { animation-delay: .20s; }
      &:nth-child(6) { animation-delay: .25s; }
    }
}

@keyframes budgetFadeIn {
  from { opacity: 0; transform: translateX(10px); }
  to { opacity: 1; }
}
</style>

