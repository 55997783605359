<template>
  <div id="a3-public-menu-container" key="main-menu">
    <div style="text-align: center">
        <div class="a3-tab" style="float: left"
            v-if="$route.name != 'public-a3'"
                            @click="$router.push(`/public/a3/${publish_key}`)">
          <i class="fas fa-chevron-left"></i>
          Terug naar A3
        </div>
      <div class="a3-public-menu-logo" >
            <img src="@/public/img/a3_online_logo_inverted.png" class="header-logo" />
      </div>
        <div class="a3-public-header-title"> {{ title }} </div>

        <div class="a3-tab" style="float: right"
          @click="openA3Online">
          Over A3 online
        </div>
    </div>


  </div>
</template>

<script>
export default {
    name: 'MainTabMenu',
    data: () => ({
        links: [ ],
    }),

    props: {
      title: {
        type: String,
        required: false,
        default: "A3 jaarplan"
      },
      publish_key: {
        type: String,
        required: false,
      }
    },

    methods: {
      openA3Online() {
        window.open('https://www.a3online.nl', '_blank');
      },
    },
};
</script>

<style lang="scss">

#a3-public-menu-container {
    background-color: $a3-green;
    border-bottom: 1px $green-darkened solid;
    user-select: none;
    -webkit-box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.5);
    box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.5);
    position: relative;

    z-index: 2000;
}

@media print  {
  #a3-public-menu-container {
    display: none !important;
  }
}

.a3-public-menu-logo {
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
    width: 90px;
    font-size: 16px;
    display: inline-block;
    height: $top-menu-height;
    line-height: $top-menu-height;
    color: white;
    vertical-align: top;
    padding-top: 0;
    padding-bottom: 0;
    overflow-y: hidden;

    text-align: center;
    img {
        height: 40px;
        margin-top: 5px;
        marin-bottom: 5px;
    }
}

.a3-public-header-title {
  line-height: $top-menu-height;
  color: white;
  display: inline-block;
  font-size: 24px;
}

</style>
