<template>
  <WithReportMenuContainer :a3doc_id="a3document_id" :filter_slug="filter_slug">
    <div style="max-width: 1120px; margin: 0 auto;">
        <h1 class="report-title">
            <i class="fas fa-comments"></i>
            {{ $t('ui.report.a3_conversation') }}</h1>
        <h2 class="report-a3-name">{{ a3_title }}</h2>

      <FilterPrint :filter="filterOptions"
         v-if="a3_document != null"
         :a3_document="a3_document"
      />

      <template v-if="a3_document != null">
        <template v-for="sbf in a3_document.sbf">
          <A3SbfReport
                  v-if="filterMap == null || filterMap.sbf == null || filterMap.sbf[sbf.id]"
                 :filterMap="filterMap"

                 :relations="relations"
                 :key="sbf.id"
                 :sbf="sbf"
                 :a3_document="a3_document" />
        </template>
      </template>
    </div>
  </WithReportMenuContainer>
</template>

<script>

import FilterSlugUrl from '@/mixins/FilterSlugUrl';

import A3SbfReport from '@/components/reports/A3SbfReport.vue';
import WithReportMenuContainer from '@/views/WithReportMenuContainer.vue';

import FilterPrint from '@/components/elements/FilterPrint.vue';

export default {

  mixins: [FilterSlugUrl],

  components: {
    A3SbfReport,
    FilterPrint,
    WithReportMenuContainer,
  },

  props: {
    a3document_id: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    a3_document: null,
    filterOptions: null,
    filterMap: null,
    relations: {parents: [], children: []},
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    },
    a3_title() {
        if (this.a3_document == null) return null;
        let meta = this.a3_document.meta;

        if (this.a3_document.meta.department != null) {
            return `${meta.department.name}, ${meta.timeframe.name}`;
        }

        return `${meta.organisation.name}, ${meta.timeframe.name}`;
    },
  },

  mounted() {
    this.loadA3();
    this.loadRelations();
  },

  methods: {
    loadA3() {
      this.$http.get(`a3/${this.a3document_id}`)
        .then(res => {
          this.a3_document = res.body;

          if (this.filter_slug != null) {
            this.filterOptions = this.getFilterOptions(this.filter_slug);
            this.filterMap = this.filterMapFromOptions(this.filterOptions);
          }
        });
    },

    loadRelations() {
      this.$http.get(`a3/${this.a3document_id}/relations`)
        .then(res => {
          this.relations = res.body;
        });
    },
  },
};
</script>
