<template>
  <WithMenuContainer>
    <h1>{{ $t('ui.a3_document.edit_options') }}</h1>

    <div class="tab-container">
      <div class="year-tab"
           @click="$router.push(`${base_url}/owner`)"
           :class="{ selected: $route.matched.some(
                 ({name}) => name === 'a3-options-owner'
           )}"
        >
        <i class="fas fa-user"></i> {{ $t('ui.a3_document.owner') }}
      </div>
      <div class="year-tab"
           @click="$router.push(`${base_url}/rights`)"
           :class="{ selected: $route.matched.some(
                 ({name}) => name === 'a3-options-rights'
           )}"
        >
        <i class="fas fa-key"></i> {{ $t('ui.rights.rights') }}
      </div>
    </div>

    <div class="plan-container">
        <router-view></router-view>
    </div>
  </WithMenuContainer>
</template>


<script>

export default {
    props: {
        a3doc_id: {
            type: Number,
            required: true
        },
    },

    computed: {
        base_url() {
            return `/a3/${this.a3doc_id}/options`;
        }
    },
}
</script>

