<template>
      <div class="element-form">

        <div class="form-row">
          <div class="form-field">
              <label>{{ $t('ui.kpi_stories.title') }} </label>
              <input data-cy="input-title" v-model="story.title" class="a3-input" autocomplete="off"
                />
            <FormError :error="errors.title" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-field">
              <label>{{ $t('ui.kpi_stories.date') }} </label>
              <DateInput v-model="story.date" />
            <FormError :error="errors.date" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-field">
              <label>{{ $t('ui.kpi_stories.body') }} </label>
              <div class="form-input">
                <RichTextEditor v-model="story.body" />
              </div>
            <FormError :error="errors.body" />
          </div>
        </div>

        <div class="form-buttons">
            <button @click="submit" class="a3-button action" data-cy="submit">
                <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
            </button>
            <button
                   v-if="story.id"
                  @click="$emit('delete')" class="a3-button danger" data-cy="delete">
                  <i class="fas fa-trash"></i> {{ $t('ui.general.delete') }}
            </button>
            <button @click="$emit('cancel')" class="a3-button cancel" data-cy="cancel">
                <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
            </button>
        </div>
      </div>
</template>

<script>

import RichTextEditor from '@/components/forms/RichTextEditor';

export default {
  components: {
    RichTextEditor,
  },

  props: {
    story: {
      type: Object,
      required: true,
    }
  },

  data: () => ({
    errors: {
      title: [],
      date: [],
      body: [],
    },
  }),

  methods: {
    validate() {

      return Object.values(this.errors)
        .flat()
        .length == 0;
    },

    submit() {
      // reset the errors
      for (let x of Object.keys(this.errors)) {
        this.errors[x] = [];
      }

      if (this.validate()) {
        this.$emit('submit');
      }
    },
  },

}
</script>
