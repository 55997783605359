
import A3PublicReportConversation from "@/views/public/A3Conversation.vue";
import A3PublicView from "@/views/public/A3PublicView.vue";

export default [
  { path: "/public/a3/:publish_key", name: "public-a3", component: A3PublicView,
    props: true,
    meta: { pub: true},
  },
  { path: "/public/a3_conversation/:publish_key", name: "public-a3-conversation",
    component: A3PublicReportConversation,
    props: true,
    meta: { pub: true},
  },
];
