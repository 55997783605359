<template>
    <div v-if="report_row.department">
        <div class="report-row" @click="toggleChildren">
            <div class="report-row-header"
                 :style="{'padding-left': `${depth * 30}px`}"
                >
                <template v-if="report_row.children.length > 0 && show_children">
                    <i class="fas fa-chevron-down"></i>
                </template>
                <template v-if="report_row.children.length > 0 && !show_children">
                    <i class="fas fa-chevron-right"></i>
                </template>
                <template v-if="report_row.children.length == 0">
                    <i class="fas fa-minus"></i>
                </template>

                {{ report_row.department.name }}
            </div>

            <div class="report-row-ruler">
                <template v-if="report_row.measurements.length == 0">
                    <em>{{ $t('ui.kpi.no_values') }}</em>
                </template>
                <KpiValueRuler
                   v-else
                   :kpi="report_row.kpi"
                    :measurement="report_row.measurements[0]" />
            </div>
        </div>

        <div class="row-children" v-show="show_children">
            <div v-for="r in report_row.children" :key="r.id" class="report-ruler">
                <DepartmentKpiRuler :report_row="r" :depth="depth + 1" />
            </div>
        </div>
    </div>
</template>

<script>

import KpiValueRuler from '@/components/elements/KpiValueRuler.vue';

export default {
    name: "DepartmentKpiRuler",

    components: {
        KpiValueRuler,
    },

    props: {
        report_row: {
            type: Object,
            required: true
        },
        depth: {
            type: Number,
            required: false,
            default: 0,
        },
    },

    data: () => ({
        show_children: true,
    }),

    methods: {
        toggleChildren() {
            this.show_children = !this.show_children;
        },
    },
}
</script>
