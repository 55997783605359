<template>
    <div>
        <ChangePassword />
        <TwoFactor />
    </div>
</template>


<script>

import ChangePassword from '@/components/user_settings/ChangePassword.vue';
import TwoFactor from '@/components/user_settings/2FA.vue';

export default {

    components: {
        ChangePassword,
        TwoFactor,
    },

    data: () => ({
        settings: {},
    }),

    mounted() {
        this.loadSettings();
    },

    methods: {
        loadSettings() {
            this.$http.get('me/settings').then((res) => {
                this.settings = res.body.settings;

                for (let key in this.settings) {
                    if (this.settings[key].setting_type == 'Boolean') {
                        this.settings[key].value = this.settings[key].value > 0;
                    }
                }
            })
        },

        saveSetting(key, value) {
            let setting = this.settings[key];
            if (setting.setting_type.Select) {
                value = setting.setting_type.Select.indexOf(value);
            }

            this.$http.put('me/settings', { key, value })
                .then((res) => {
                    this.settings[key].value = value > 0;
            })
        }
    }
}
</script>

<style lang="scss">

.user-settings-section {
    max-width: 1000px;
    padding-bottom: 50px;
    border-bottom: 2px rgba($a3-green, .1) solid;
    margin-top: 50px;
}

</style>

