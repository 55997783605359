<template>
    <div>
        <h2>{{ $t('ui.rights.default_rights') }} </h2>

        <CheckBox v-model="options.organisation_readable" />
        {{ $t('ui.rights.make_organisation_readable') }}


        <h2 style="margin-top: 3em">{{ $t('ui.rights.edit_personal_rights') }} </h2>

        <p>{{ $t('ui.rights.personal_rights_disclaimer') }}</p>

        <h3>{{ $t('ui.rights.current_rights') }} </h3>

        <table class="a3-table">
          <thead>
            <tr>
              <th style="width: 30px"></th>
              <th>{{ $t('ui.general.name') }} </th>
              <th>{{ $t('ui.general.mail') }} </th>
              <th>{{ $t('ui.rights.rights_type') }} </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="r in rights" :key="r.id"
                :class="{'selected': selected_right_id == r.id}"
                @click="selected_right_id = selected_right_id == r.id ? null : r.id"
              >
              <td>
                <i v-if="selected_right_id == r.id" class="fas fa-check"></i>
              </td>
              <td>
                {{ r.person ? r.person.name : '-' }}
              </td>
              <td>
                {{ r.account ? r.account.mail : '-' }}
              </td>
              <td>
                {{ r.features }}
              </td>
            </tr>
          </tbody>
        </table>

      <template v-if="selected_right_id">
        <button
                style="margin-top: 20px"
                class="a3-button danger"
                @click="deleteSelectedRights"
          >
          <i class="fas fa-times"></i> {{ $t('ui.rights.remove_rights') }}
        </button>
      </template>

      <h3>{{ $t('ui.rights.add_person') }} </h3>

        <multiselect
           id="people-multiselect"
           v-model="selected_person"
           :options="people"
           :placeholder="$t('ui.filter_tab.select_person')"
           :selectLabel="$t('ui.general.enter_select')"
           :selectedLabel="$t('ui.general.selected')"
           :deselectLabel="$t('ui.general.enter_deselect')"
           :multiple="false"
           :close-on-select="true"
           track-by="account_id"
           label="label" />

        <button class="a3-button action"
                @click="saveReadRights"
          >
          {{ $t('ui.rights.give_read_rights') }}
        </button>
        <button class="a3-button action"
                @click="saveWriteRights"
              >
          {{ $t('ui.rights.give_readwrite_rights') }}
        </button>
    </div>
</template>

<script>

import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },

  data: () => ({
    current_tab: 'rights',
    selected_person: null,
    people: [],
    rights: [],
    selected_right_id: null,
    options: {
      organisation_readable: false,
    },
  }),

  props: {
    a3doc_id: {
      type: String,
      required: true,
    }
  },

  watch: {
    options: {
      deep: true,
      handler: function() {
        this.saveOptions();
      },
    }
  },

  computed: {
  },

  mounted() {
    this.loadPeople();
    this.loadRights();
    this.loadOptions();
  },

  methods: {
    loadPeople() {
      this.$http.get('admin/account')
        .then(res => this.people = res.body.map(x => ({ account_id: x.account.id, label: `${x.person.name} (${x.account.mail})` })));
    },

    loadRights() {
      this.$http.get(`admin/a3/${this.a3doc_id}/account_rights`)
        .then(res => {
          this.rights = res.body;
        });
    },

    loadOptions() {
      this.$http.get(`a3/${this.a3doc_id}/options`, this.options)
        .then((res) => {
          if (res.body != null) {
            this.options = res.body;
          }
        })
    },

    deleteSelectedRights() {
      let url = `admin/a3_rights/${this.selected_right_id}`;
      this.$http.delete(url)
        .then(() => {
          this.selected_right_id = null;
          this.loadRights()
        });
    },

    saveReadRights() {
      this.$http.put(`admin/account/${this.selected_person.account_id}/a3_rights`, {
        a3doc_id: parseInt(this.a3doc_id), read: true, write: false
      }).then((res) => {
        this.selected_person = null;
        this.loadRights();
        })
    },

    saveWriteRights() {
      this.$http.put(`admin/account/${this.selected_person.account_id}/a3_rights`, {
        a3doc_id: parseInt(this.a3doc_id), read: true, write: true
      }).then((res) => {
        this.selected_person = null;
        this.loadRights();
      })
    },

    saveOptions() {
      return this.$http.put(`a3/${this.a3doc_id}/options`, this.options);
    },
  }
};
</script>
