
import filterEncoding from '@/services/filterEncoding';
import focusCalculations from '@/services/focusCalculations';

export default {

  computed: {
    filter_slug() {
      return this.$route.query['filter'];
    }
  },

  methods: {
    getFilterOptions(filter_slug) {
        return filterEncoding.decodeFilterOptions(filter_slug);
    },

    getFilterMap(filter_slug) {
      let options = this.getFilterOptions(filter_slug);
      return this.filterMapFromOptions(options);
    },

    filterMapFromOptions(filter_options) {
      return focusCalculations.createFocusMap(
        filter_options, this.a3_document
      );
    },

    getFilter() {
      return this.getFilterMap(this.filter_slug);
    },
  },
};
