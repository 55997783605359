<template>
    <div class="confirmation-overlay-container">
        <div class="confirmation-overlay-inner">
            <h1>{{ $t('ui.general.are_you_sure') }}</h1>

            <p>
                <slot></slot>
            </p>

            <div class="buttons" style="margin-top: 30px">
                <button class="a3-button action"
                        @click="$emit('submit')"
                        :class="{'disabled': disable_submit}"
                    >
                    <i class="fas fa-check"></i> {{ $t('ui.general.yes') }}
                </button>
                <button class="a3-button cancel"
                        @click="$emit('cancel')">
                    <i class="fas fa-times"></i>
                    {{ $t('ui.general.cancel') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    props: {
        disable_submit: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
}
</script>

<style lang="scss">

.confirmation-overlay-container {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: rgba(black, .3);
    animation: fadeIn .2s ease-out;

    h1 {
        color: rgba($a3-green, .6);
    }

    .confirmation-overlay-inner {
        box-sizing: border-box;
        color: black;
        background-color: white;
        max-width: 960px;
        margin: 20px auto;
        border-radius: 15px;
        text-align: left;
        padding: 30px;
        animation: popIn .4s ease-out;
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes popIn {
    0% {
        transform: translateY(40px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>
