<template>
  <div>


    <div v-if="!running">
        <p>
            {{ $t('ui.organisation.confirm_people_import', [people.length]) }}
        </p>

      <button class="a3-button action"
              @click="startRun"
        >
        {{ $t('ui.organisation.start_import') }}
      </button>
    </div>

    <div class="progress-bar-container"
         v-if="running"
         style="width: 600px; height: 20px;"
      >
      <div class="progress-bar-inner"
           :style="{'width': `${currentIndex / people.length * 100}%`}"
           style="background-color: green; height: 100%;"
        >
      </div>
    </div>
    <div v-if="running">
        {{ $t('ui.organisation.importing') }}: {{ currentIndex }} / {{ people.length }}
    </div>


    <div style="color: red; "
         v-if="running"
        >
      <i class="fas fa-warning"></i>
      {{ errors.length }} errors:
      <ul>
        <li v-for="(err, index) in errors" :key="index">
          {{ err }}
        </li>
      </ul>
    </div>


  </div>
</template>


<script>


export default {

  props: {
    people: {
      type: Array,
      required: true,
    },
    cols: {
        type: Object,
        required: true,
    },
  },

  data: () => ({
    running: false,
    currentIndex: 0,
    errors: [],
  }),

  methods: {
    startRun() {
      this.running = true;
      this.createUser(0);
    },

    getInitials(value) {
      let splitted = value.split(/[\s.]/);

      if (splitted.length > 0) {
        return splitted
          .map(x => x[0])
          .join("");
      }
    },

    createUser(index) {
      let source = this.people[index];
      let person = {
        'name': source[this.cols.name],
        mail: source[this.cols.mail],
        'function': source[this.cols['function']],
        initials: this.getInitials(source.Naam),
        department_id: source.department_id,
        user_type_id: source.user_type_id,
        create_account: source[this.cols.mail] != null,
      };

      return this.$http.post('admin/person', person)
        .then((res) => {
          this.currentIndex = index + 1;
          return setTimeout(this.createUser(index + 1), 400);
        }, (err) => {
          this.errors.push(person.mail);
          if (this.errors.length >= 5) return;

          this.currentIndex = index + 1;
          return this.createUser(index + 1);
        });
    },
  },
};
</script>

<style lang="scss">

.progress-bar-container {
  border-radius: 10px;
  border: 2px $a3-green solid;
}
</style>
