<template>
    <WithReportMenuContainer :a3doc_id="a3doc_id">
        <h1 class="report-title">
            <i class="fas fa-analytics"></i>
            {{ $t('ui.report.pi_organisation') }}</h1>
        <template v-if="report_data">
            <h2 class="report-a3-name">{{ report_data.root_kpi.name }}</h2>
        </template>

        <div v-if="report_data != null">
            <div class="report-row">
                <div class="report-row-header" style="font-weight: bold; font-size: 1.3em">
                    <i class="fas fa-sitemap"></i> {{ organisation.name }}
                </div>

                <div class="report-row-ruler">
                    <KpiValueRuler :kpi="report_data.root_kpi"
                        :measurement="report_data.measurements[0]" />
                </div>
            </div>
            <div class="row-children">
                <div v-for="row in report_data.children" :key="row.id">
                    <DepartmentKpiRuler :report_row="row" :depth="1" />
                </div>
            </div>
        </div>
    </WithReportMenuContainer>
</template>

<script>

import WithReportMenuContainer from '@/views/WithReportMenuContainer.vue';
import DepartmentKpiRuler from '@/components/reports/DepartmentKpiRuler.vue';
import KpiValueRuler from '@/components/elements/KpiValueRuler.vue';

export default {

    components: {WithReportMenuContainer, DepartmentKpiRuler, KpiValueRuler},

    props: {
        kpi_id: {
            type: Number,
            required: true,
        },
        a3doc_id: {
            type: Number,
            required: true,
        },
    },

    data: () => ({
        report_data: null,
    }),

    computed: {
        organisation() {
            return this.$store.getters['auth/organisation'];
        }
    },


    mounted() {
        this.loadKpiReport();
    },

    methods: {
        loadKpiReport() {
            let url = `report/kpi_organisation/${this.a3doc_id}/${this.kpi_id}`;

            return this.$http.get(url)
              .then(res => {
                this.report_data = res.body;
              })
        },
    },

}
</script>

<style lang="scss">

.report-row-ruler {
    display: inline-block;
    width: 500px;
    vertical-align: top;
    margin-left: 15px;
    padding-top: 10px;
}
.report-row-header {
    box-sizing: border-box;
    display: inline-block;
    width: 550px;
    min-height: 60px;
    padding-top: 10px;
    padding-left: 10px;
    vertical-align: top;
}

.report-row {
    transition: background-color .2s ease-out;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: rgba($a3-green, .2);
    }
}
</style>
