<template>
    <div class="element-form" v-if="kpi != null">

      <div class="form-buttons">
       <p v-if="is_ref">
           <em> {{ $t("ui.kpi.ref_form") }} </em>
        </p>
      </div>

      <div class="form-row">
        <div class="form-field">
            <label>{{ $t('ui.general.name') }} </label>
          <input v-model="kpi.name" class="a3-input" id="kpi-name-input" data-cy="input-name"
              :disabled="is_ref"
          />

            <FormError :error="errors.name" />
        </div>
      </div>

      <div class="form-row" v-show="!is_ref">
        <h3>{{ $tc("definitions.terms.sbf") }}</h3>
        <div class="form-field col-50">
            <label>{{ $t('definitions.short.sbf') }}
              <br>
            <InfoBox
             v-if="kpi.result_area != null && a3_layout.structure.sbf_result_mapping == 'one_to_one'"
             tkey="forms.filter_active" top="1.5em" left="0"
            >
              <template v-slot:display>
                <span class="form-label-notify">
                  <i class="fas fa-filter"></i>
                  {{ $t('forms.active_filter') }}
                </span>
              </template>
            </InfoBox>
            </label>
          <select v-model="kpi.sbf_id" class="a3-input"
                                       id="kpi-sbf-input"
                                       data-cy="input-sbf"
                                                        :disabled="is_ref"
            >
            <option v-for="sbf in sbf_options" :value="sbf.id" :key="`option-sbf-${sbf.id}`">
              {{ sbf.name }}
            </option>
          </select>

            <FormError :error="errors.sbf" />
        </div>

        <div class="form-field col-50">
            <label>{{ $t('definitions.terms.result_area') }} </label>
          <select v-model="kpi.result_area" class="a3-input"
              :disabled="is_ref"
            data-cy="input-result-area" >
            <option :value="null">-</option>
            <option v-for="result_area in result_area_options" :value="result_area.key" >
                {{ result_area.name ? result_area.name : $t(`definitions.result_area.${result_area.key}`) }}
            </option>
          </select>
            <FormError :error="errors.result_area" />
        </div>
      </div>


      <div class="form-row" v-show="!is_ref">
          <h3>{{ $t('ui.general.properties') }} </h3>
        <div class="form-field">
            <label>{{ $t('ui.kpi.kpi_type') }} </label>
          <select v-model="kpi.kpi_type" class="a3-input" data-cy="input-kpi-type"
            >
            <option :value="'kpi'">{{ $t('ui.kpi.type_kpi') }} </option>
            <option :value="'evaluation'">{{ $t('ui.kpi.type_evaluation') }} </option>
          </select>

            <FormError :error="errors.kpi_type" />
        </div>

        <div class="form-field" v-if="kpi.kpi_type === 'kpi'">
            <label>{{ $t('ui.kpi.unit') }} </label>
          <select v-model="kpi.unit" class="a3-input" data-cy="input-unit"
              :disabled="is_ref"
            >
            <option :value="'eur'">{{ $t('ui.kpi.euro') }} </option>
            <option :value="'perc'">{{ $t('ui.kpi.percentage') }} </option>
            <option :value="'number'">{{ $t('ui.kpi.number') }} </option>
          </select>

            <FormError :error="errors.unit" />
        </div>

        <div class="form-field" v-if="kpi.kpi_type === 'kpi'
                    && dataManager.hasLayoutOption('steering_check')
            ">
            <label class="checkbox-label">{{ $t('ui.kpi.steering_indicator') }} ?</label>
            <CheckBox v-model="kpi.is_steering_kpi" data-cy="input-steering-indicator" />
        </div>

            <div class="form-field" v-if="people">
                <label>{{ $t('ui.action.assigned_to') }} </label>

                <div style="width: 60%; display: inline-block; box-sizing: border-box;">
                   <multiselect
                        id="action-assigned_to"
                        :value="assigned_to_multiselect"
                        @input="(value, id) => kpi.assigned_to = value ? value.id : null"
                       :options="people"
                       :placeholder="$t('ui.action.assigned_to')"
                       :selectLabel="$t('ui.general.enter_select')"
                       :selectedLabel="$t('ui.general.selected')"
                       :deselectLabel="$t('ui.general.enter_deselect')"
                       trackBy="id"
                       label="name" />
                </div>
            </div>
      </div>

      <div class="form-row">
          <h3>{{ $t('ui.kpi.norm') }} </h3>
        <div class="form-field">
          <label>{{ $t('ui.kpi.min_short') }}</label>
          <input v-if="!is_ref" v-model="kpi.target_min" class="a3-input-small" data-cy="input-target-min" >
          <input v-if="is_ref" v-model="kpi.kpi_ref.target_min" class="a3-input-small" data-cy="input-target-min" >

          <span v-if="is_ref" style="opacity: .5; padding-left: 15px;">{{ $t('ui.kpi.upper_value') }}: {{ kpi.target_min }}</span>
          <FormError :error="errors.target_min" />
        </div>

        <div class="form-field">
          <label>{{ $t('ui.kpi.max_short') }}</label>
          <input v-if="!is_ref" v-model="kpi.target_max" class="a3-input-small" data-cy="input-target-max" />
          <input v-if="is_ref" v-model="kpi.kpi_ref.target_max" class="a3-input-small" data-cy="input-target-max" />
          <span v-if="is_ref" style="opacity: .5; padding-left: 15px;">{{ $t('ui.kpi.upper_value') }}: {{ kpi.target_max }}</span>

          <FormError :error="errors.target_max" />
        </div>
      </div>

      <div class="form-field">
          <label>{{ $t('ui.kpi.comment_analysis') }}
              <template v-if="is_ref">
                  <br><p style="font-style: italic; font-size: .8em;">
                  {{ $t('ui.kpi.comment_analysis_ref') }}</p>
              </template>
          </label>

        <div class="form-input">
          <RichTextEditor v-if="!is_ref" v-model="kpi.comment_analysis" data-cy="input-comment-analysis" />
          <RichTextEditor v-if="is_ref" v-model="kpi.kpi_ref.comment_analysis" data-cy="input-comment-analysis" />
        </div>
      </div>


      <div class="form-row" v-show="!is_ref">
          <h3>{{ $t('ui.general.organisation') }} </h3>

        <div class="form-field"
             v-if="management_groups
                    && management_groups.length > 0
                    && dataManager.hasLayoutOption('report_groups')
             ">
            <label>{{ $t('ui.kpi.report_groups') }} </label>
          <div class="push-pill" :class="{inactive: !kpi.management_group_ids.some((x) => x == group.id)}"
               @click="toggleManagementGroup(group.id)"
               v-for="group in management_groups" :key="group.id">
            {{ group.name }}
          </div>
        </div>
      </div>

      <div class="form-row" v-if="child_departments && show_deploy">
        <div class="form-field">
            <label class="checkbox-label">{{ $t('ui.a3_document.push') }} ?</label>
          <CheckBox v-model="kpi.push_default" />
          <div class="input-pills" v-if="kpi.push_default">
            <template v-for="c in child_departments">
              <div
                :key="c.department.id"
                class="push-pill"
                v-if="c.a3_ref != null"
                tabindex="0"
                @click="toggleA3Doc(c)"
                @keyup.enter="toggleA3Doc(c)"
                :class="{'inactive': push_a3docs.find((x) => c.a3_ref && x == c.a3_ref.a3doc_id) == null }"
            >
                {{ c.department.name }}
              </div>
            </template>
            <div v-if="child_departments.filter(x => x.a3_ref != null).length == 0">
                <em>{{ $t('ui.a3_document.no_subplans') }} </em>
            </div>
          </div>
        </div>

        <div class="form-field col-50" v-if="kpi.push_default">
            <label>{{ $t('ui.kpi.agg_type') }} </label>
          <select v-model="kpi.agg_type" class="a3-input"
              :disabled="is_ref"
            data-cy="input-agg-type" >
            <option :value="null">-</option>
            <option :value="'Sum'">{{ $t('ui.kpi.sum') }} </option>
            <option :value="'Average'">{{ $t('ui.kpi.average') }} </option>
            <option :value="'Min'">{{ $t('ui.kpi.min') }} </option>
            <option :value="'Max'">{{ $t('ui.kpi.max') }} </option>
          </select>
        </div>

      </div>


      <div class="form-buttons">
          <button class="a3-button action"
                  id="kpi-submit-button"
                  data-cy="submit"
            @click="submit">
              <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
          </button>

          <button class="a3-button cancel" @click="$emit('cancel')">
              <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
          </button>
      </div>
    </div>
</template>

<script>

import RichTextEditor from '@/components/forms/RichTextEditor';
import WithDocumentManager from '@/mixins/WithDocumentManager';

import Multiselect from 'vue-multiselect';

export default {
  components: {
    RichTextEditor,
      Multiselect,
  },

  mixins: [ WithDocumentManager ],

  props: {
    kpi: {
      type: Object,
      required: true,
    },
    show_deploy: {
      type: Boolean,
      required: false,
      default: true,
    },
    child_departments: {
      type: Array,
      required: true,
    },
    management_groups: {
      type: Array,
      required: true,
    },
    push_a3docs: {
      type: Array,
      required: true,
    },
  },

    data: () => ({
        people: [],
      errors: {
        name: [],
        unit: [],
        sbf: [],
        result_area: [],
        target_min: [],
        target_max: [],
      },
    }),

  computed: {
    sbf() {
      if (this.kpi == null || this.kpi.sbf_id == null) return null;
      if (this.a3_document == null) return null;

      return this.dataManager.getSbf(this.kpi.sbf_id);
    },

    is_ref() {
        return this.kpi.kpi_ref != null;
    },

    assigned_to_multiselect() {
        if (this.is_ref) {
            return this.people.find(x => x.id == this.kpi.assigned_to) || null;
        }
        return this.people.find(x => x.id == this.kpi.assigned_to) || null;
    },

    sbf_options() {
      if (this.a3_document == null) return [];

      if (this.kpi.result_area != null
          && this.a3_layout.structure.sbf_result_mapping == 'one_to_one') {
        return this.dataManager
              .getSbfList()
              .filter((sbf) => sbf.result_area == this.kpi.result_area);
      }

      return this.dataManager.getSbfList();
    },

    result_area_options() {
        return this.dataManager.getResultAreas();
    },
  },

  watch: {
    sbf: function () {
      if (!this.sbf) return;

      if (this.a3_layout.structure.sbf_result_mapping == 'one_to_one') {
          this.kpi.result_area = this.sbf.result_area;
      }
      if (this.sbf.push_default != undefined) {
        this.kpi.push_default = this.sbf.push_default;
      }
    },

    'kpi.result_area': function(value) {
      if (this.sbf == null) return;
      if (this.a3_layout.structure.sbf_result_mapping == 'one_to_one') {
          if (this.sbf.result_area != value) {
            this.kpi.sbf_id = null;
          }
      }
    },

    'kpi.target_min'() {
      if (this.kpi.target_min == "") { this.kpi.target_min = null; }
    },
    'kpi.target_max'() {
      if (this.kpi.target_max == "") { this.kpi.target_max = null; }
    },

    'kpi.assigned_to': function() {
      this.kpi.assigned_to_person = this.people
        .filter(x => x.id == this.action.assigned_to)[0];
    },
  },

  mounted() {
    this.loadPeople();

    this.$nextTick(() => {
      let s = document.querySelector('#kpi-name-input');
      if (s) { s.focus() }
    });
  },

  methods: {
    validate() {
      if (this.kpi.name == null || this.kpi.name.length == 0) this.errors.name.push("may_not_be_null");
      if (this.kpi.unit == null) this.errors.unit.push("may_not_be_null");
      if (this.kpi.sbf_id == null) this.errors.sbf.push("may_not_be_null");
      if (this.kpi.result_area == null) this.errors.result_area.push("may_not_be_null");

      const target_regex = RegExp('^[0-9.]+$');
      if (this.kpi.target_min != null && !target_regex.test(this.kpi.target_min)) {
        this.errors.target_min.push("invalid_value");
      }
      if (this.kpi.target_max != null && !target_regex.test(this.kpi.target_max)) {
        this.errors.target_max.push("invalid_value");
      }

      if (this.kpi.kpi_type === 'evaluation') {
        let min = parseFloat(this.kpi.target_min);
        let max = parseFloat(this.kpi.target_max);

        if (min < 0 || min > 5) {
          this.errors.target_min.push("value_between_0_and_5");
        }
        if (max < 0 || max > 5) {
          this.errors.target_max.push("value_between_0_and_5");
        }
      }

      return Object.values(this.errors)
        .flat()
        .length == 0;
    },

    submit() {
      // reset the errors
      for (let x of Object.keys(this.errors)) {
        this.errors[x] = [];
      }

      if (this.validate()) {
        this.$emit('submit');
      }
    },

    toggleManagementGroup(group_id) {
      if (this.kpi.management_group_ids.some((x) => group_id == x)) {
        let item_index = this.kpi.management_group_ids.indexOf(group_id);
        if (item_index != -1) {
           this.kpi.management_group_ids.splice(item_index, 1);
        }
      } else {
        this.kpi.management_group_ids.push(group_id);
      }
    },

    loadPeople() {
      this.$http.get(`organisation/people`)
        .then(res => this.people = res.body);
    },

    toggleA3Doc(child_ref) {
      if (!child_ref.a3_ref) { return ; }
      let a3doc_id = child_ref.a3_ref.a3doc_id;

      let in_array = this.push_a3docs.findIndex(x => x == a3doc_id);
      if (in_array == -1) {
        this.push_a3docs.push(a3doc_id);
      } else {
        this.push_a3docs.splice(in_array, 1);
      }
    }
  },
}
</script>
