<template>
  <WithMenuContainer>
    <div class="container">
      <h1><i class="fas fa-user-plus"></i> {{ $t('ui.accounts.new_employee') }} </h1>

      <PersonForm
             :person="person"
             :include_account_form="true"
             :errors="errors"
             @submit="saveAccount"
             @cancel="$router.go(-1)"
             />

    </div>
  </WithMenuContainer>
</template>

<script>

import PersonForm from '@/components/forms/PersonForm.vue';

export default {
  components: { PersonForm },

  data: () => ({

    errors: { 'mail': [] },
    person: {
      department_id: null,
      name: '',
      initials: null,
      function_name: '',
      create_account: false,
      mail: '',
      password: '',
    },
  }),

  methods: {
    saveAccount() {
      if (this.person.department_id == '') {
        this.person.department_id = null;
      }

      this.$http.post('admin/person', this.person)
        .then((res) => {
            this.$router.push(`/organisation/people/${res.body.person.id}`);
        }, (err) => {
            if (err.body.message == "Invalid mail") {
                this.errors.mail = ["invalid_mail"];
            }
            if (err.body.message == "Account already exists") {
                this.errors.mail = ["account_already_exists"];
            }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/variables.scss";

.a3-table {
  border-spacing: 0;
  width: 100%;

  th {
    padding: 8px;
    border-bottom: 1px $a3-green solid;
  }
  thead {
      margin-bottom: 4px;
  }

  tbody tr {
    td {
      padding: 4px 10px;
    }
    cursor: pointer;
    transition: color .1s ease-in-out, background-color, .1s ease-in-out;

    &:hover {
      background-color: $a3-green;
      color: white;
    }
  }
}

.container {
  max-width: 960px;
  margin: 45px auto;
}
</style>
