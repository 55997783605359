<template>
  <WithOrganisationMenuContainer>
    <div style="max-width: 1200px; width: 100%; margin: 0 auto;">
      <template v-if="$hasFeature('OrgEditPeople') || $hasFeature('OrgEditRights')">
        <div style="text-align: right">
            <button class="a3-button action"
                    v-if="$hasFeature('OrgEditRights')"
                    @click="$router.push('/organisation/user_types')"
              >
              <i class="fas fa-users-cog"></i>
              {{ $t('ui.organisation.edit_user_types') }}
            </button>

            <button class="a3-button action"
                    v-if="$hasFeature('Root')"
                    @click="$router.push('/organisation/advisor')"
              >
              <i class="fas fa-chalkboard-teacher"></i>
              {{ $t('ui.advisor.advisors') }}
            </button>

            <button class="a3-button action"
                      v-if="$hasFeature('OrgEditPeople')"
                      @click="$router.push(`/organisation/people/create`)"
                >
              <i class="fas fa-plus"></i> {{ $t('ui.general.create') }}
            </button>

            <button class="a3-button action"
                      v-if="$hasFeature('OrgEditPeople')"
                      @click="$router.push(`/organisation/people/import`)"
                >
              <i class="fas fa-plus"></i> {{ $t('ui.organisation.import_people') }}
            </button>

        </div>
      </template>

      <h1>{{ organisation.name }} : {{ $t('ui.organisation.employees') }} </h1>

      <table class="a3-table">
        <thead>
          <tr>
            <th>{{ $t('ui.general.name') }} </th>
            <th>{{ $t('ui.organisation.initials') }} </th>
            <th>{{ $t('ui.general.department') }} </th>
            <th>{{ $t('ui.user_type.user_type') }} </th>
            <th>{{ $t('ui.accounts.mail') }} </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="person in people" :key="person.id"
              @click="$router.push(`/organisation/people/${person.id}`)"
            >
            <td> {{ person.name }} </td>
            <td> {{ person.initials }} </td>
            <td> {{ person.department ? person.department.name : '-' }} </td>
            <td> {{ person.user_type ? person.user_type.name : '-' }} </td>
            <td> {{ person.account ? person.account.mail : '-' }} </td>
            <td></td>
          </tr>
        </tbody>
      </table>

    </div>
  </WithOrganisationMenuContainer>
</template>


<script>

export default {

  data: () => ({
    people: [],
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    }
  },

  mounted() {
    this.loadPeople();
  },

  methods: {
    loadPeople() {
      this.$http.get(`organisation/people`)
        .then(res => this.people = res.body);
    },
  }
}
</script>
