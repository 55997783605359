<template>
  <div>
    <h1>{{ $t('ui.a3_document.rename_matrix') }}</h1>

    <div class="element-form">

      <div class="form-row">
        <div class="form-field">
            <label>{{ $t('ui.general.name') }} </label>
            <input v-model="name" class="a3-input" id="matrix-name-input" data-cy="input-name" />

            <FormError :error="errors.name" />
        </div>
      </div>

    </div>

    <div class="buttons" style="margin-top: 20px">
      <button class="a3-button action" @click="save">
        <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
      </button>
      <button class="a3-button cancel" @click="$router.go(-1)">
        <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
      </button>
    </div>
  </div>
</template>

<script>

import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  mixins: [WithDocumentManager],

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    name: "",
    errors: {
      name: []
    }
  }),

  mounted() {
    this.name = this.dataManager.a3Document.meta.meta.matrix_name;
  },

  methods: {
    save() {
      if (!this.validate()) {
        return;
      }

      this.$http.put(`a3/${this.dataManager.a3DocId}/matrix_rename`, { name: this.name })
        .then((res) => {
          this.$router.push(`/a3/${this.dataManager.a3DocId}`);
        });
    },

    validate() {
      this.errors.name = [];

      if (this.name.length == 0) {
        this.errors.name.push("may_not_be_null");
      }

      return this.errors.name.length == 0;
    },
  },

}
</script>

<style lang="scss">

</style>
