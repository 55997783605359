<template>
  <div id="a3-print-frontpage"
       :style="{
          'color': textcolor,
          'background-color': background_color,
       }"
    >
    <div class="left-col">
      <div class="col">
        <h2>Inleiding</h2>
        <p>
          Met trots presenteren wij ons A3 jaarplan voor {{ a3_document.meta.timeframe.name }}. Ons jaarplan
          geeft weer wat wij belangrijk vinden, welke concrete doelen wij hiervoor stellen,
          en welke acties we nemen om deze doelen te bereiken.
        </p>

        <p>
          <RichTextRender :value="a3_document.mission" />
        </p>
        <p>
          <RichTextRender :value="a3_document.vision" />
        </p>
      </div>
      <div class="col">
        <h2>Leeswijzer</h2>

        <h3>Bovenin</h3>
        <p>
          In de bovenste drie blokken leest u van links naar
          rechts de missie, visie en succes-bepalende
          factoren (speerpunten).
        </p>

        <h3>Rechter blokken 6 t/m 9</h3>
        <span><em>(wat willen we bereiken en voor wie)</em></span>
        <p>
          Onder de speerpunten zijn de doelen vertaald op
          vier resultaatgebieden: prestaties voor
          <template v-for="(area, index) in result_areas">
              <template v-if="index == result_areas.length - 1">
                  en
              </template>
              <template v-if="area.name">
                 ‘{{ area.name }}’</template><template v-else>
                ‘{{ $t(`definitions.result_area.${area.key}`) }}’</template><template v-if="index < result_areas.length - 2">,</template>
          </template>.
          Achter elke prestatie indicator staat een ambitie
          genoemd, dit is de werkelijke prestatie die de
          organisatie ambieert.
        </p>

        <h3>Linker blokken 1 t/m 5</h3>
        <span><em>(wat gaan we doen om onze doelen te bereiken en met wie)</em></span>

        <p>
          Om deze doelen te realiseren zijn acties
          gedefinieerd op 5 organisatiegebieden:
          <template v-for="(area, index) in org_areas">
              <template v-if="index == org_areas.length - 1">
                  en
              </template>
              <template v-if="area.name">
                 ‘{{ area.name }}’</template><template v-else>
                ‘{{ $t(`definitions.org_area.${area.key}`) }}’</template><template v-if="index < org_areas.length - 2">,</template>
          </template>.

          Achter elke elke actie staat een nummer,
          hetgeen de relatie weergeeft met de
          succesbepalende factoren.
        </p>
        <p>
          De kleuren staan voor de verschillende resultaatgebieden:
          <ul style="background-color: white;
                     border-radius: 5px;
                     padding-top: 15px;
                     padding-bottom: 15px;
              ">
              <li
                v-for="area in result_areas"
                :style="{'color': area.color}">
                  <template v-if="area.name">
                      {{ area.name }}
                  </template>
                  <template v-else>
                      {{ $t(`definitions.result_area.${area.key}`) }}
                  </template>
              </li>
          </ul>
        </p>

        Wij wensen u veel leesplezier,
      </div>
    </div><div class="right-col">
      <img src="@/public/img/a3-methodiek-logo-plain.png" />
      <div class="right-col-area"
           :style="{ 'background-color': textcolor }"
        >
      </div>
        <h1>{{ $tc('ui.general.plan') }} {{ a3_document.meta.timeframe.name }} </h1>

        <div style="width: 75%">
          <h2 style="font-size: 2.3em">{{ meta.organisation.name }}</h2>
          <h2 v-if="meta.department">
            <span style="opacity: .7">Afdelings- / team plan:</span><br>
            {{ meta.department.name }}
          </h2>

          <h3 v-if="meta.meta.matrix_name != null">Programma: {{ meta.meta.matrix_name }}</h3>
        </div>
    </div>
  </div>
</template>

<script>

import RichTextRender from '@/components/forms/RichTextRender';

export default {
  components: {
    RichTextRender
  },
  props: {
    a3_document: {
      type: Object,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    print_settings: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    textcolor() {
      if (this.print_settings && this.print_settings.front_textcolor) {
        return this.print_settings.front_textcolor
      }
      return '#007385';
    },
    background_color() {
      if (this.print_settings && this.print_settings.front_background) {
        return this.print_settings.front_background
      }
      return '#ffffff';
    },
    a3_header() {
      if (this.meta.department != null) {
        return this.meta.department.name;
      }
      return this.meta.organisation.name;
    },

    result_areas() {
        return this.a3_document.meta.layout.structure.result_areas;
    },
    org_areas() {
        return this.a3_document.meta.layout.structure.org_areas;
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/variables.scss";

#a3-print-frontpage {
  position: relative;
  width: calc(1920px - 40px);
  box-sizing: border-box;
  height: calc(1800px / 29.7 * 21);
  border-radius: 15px;
  overflow: hidden;
  margin: 20px;
  padding: 20px;

  background-color: white;
  color: $a3-green;

  p {
    margin-bottom: 20px;
    margin-top: 5px;
  }

  li.item-employees {
    color: $a3-blue;
  }
  li.item-customers {
    color: $a3-lightgreen;
  }
  li.item-society {
    color: $a3-orange;
  }
  li.item-board {
    color: $a3-red;
  }

  .left-col {
    width: calc(50% - 40px);
    display: inline-block;
    vertical-align: top;
    margin: 0;
    padding: 20px;
    padding-top: 40px;

    h2 {
      font-size: 48px;
      font-weight: strong;
      opacity: .5;
    }
    h3 {
      margin-bottom: 5px;
    }

    .col {
      width: calc((100% - 40px) / 2);
      display: inline-block;
      vertical-align: top;
    }
  }

  .right-col {
    width: calc(50% - 61px);
    height: 100%;
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: top;
    padding-top: 15%;
    padding-left: 60px;
    padding-bottom: 400px;
    h1 {
      font-size: 80px;
      margin: 0;
      padding: 0;
    }

    .right-col-area {
      height: 200px;
      margin-left: -60px;
      margin-top: 10px;
      margin-bottom: 40px;
      margin-right: -20px;
    }
  }

}

</style>
