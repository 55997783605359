<template>
    <div class="grid-preview">

        <h2>{{ $t('ui.layout.custom_grid') }}</h2>

        <div class="grid-inner"
             :class="{'blur': selected_area != null}"
             style="display: grid; grid-column-gap: 20px;
                    grid-row-gap: 20px;"
             :style="{'grid-template-columns': body_layout_columns}"
            >

            <div class="grid-add-button"
                 @click="addOrgArea"
                 :style="{
                    'grid-column': `1 / span ${Math.ceil(structure.grid.columns / 2)}`,
                    'grid-row': `${maxRow + 1} / span 1`,
                 }"
                >
                <i class="fas fa-plus"></i>
                {{ $t('ui.layout.add_org_area') }}
            </div>
            <div class="grid-add-button"
                 @click="addResultArea"
                 :style="{
                          'grid-column': `${Math.ceil(structure.grid.columns / 2) + 1} / span ${Math.floor(structure.grid.columns / 2)}`,
                          'grid-row': `${maxRow + 1 } / span 1`,
                 }"
                >
                <i class="fas fa-plus"></i>
                {{ $t('ui.layout.add_result_area') }}
            </div>

            <div v-for="(result_area, index) in structure.result_areas"
                 class="grid-area-preview"
                 :style="{
                    'grid-column': `${result_area.position.x} / span ${result_area.position.xspan}`,
                    'grid-row': `${result_area.position.y} / span ${result_area.position.yspan}`,
                    '--primary-color': `${result_area.color}`
                 }"
                 @click="selectResultAreaByIndex(index)"
                >
                <i class="fas" :class="`fa-${result_area.icon}`"></i>
                {{ result_area.index }}. {{ result_area.name
                    ?  result_area.name
                    :  $t(`definitions.result_area.${result_area.key}`) }}

                <div class="area-indicator">
                    <i class="fas fa-analytics"></i>
                    {{ $t('definitions.terms.result_area') }}
                </div>
            </div>
            <div v-for="(org_area, index) in structure.org_areas"
                 class="grid-area-preview"
                 :style="{
                    'grid-column': `${org_area.position.x} / span ${org_area.position.xspan}`,
                    'grid-row': `${org_area.position.y} / span ${org_area.position.yspan}`,
                    '--primary-color': '#007385'
                 }"
                 @click="selectOrgAreaByIndex(index)"
                >
                <i class="fas" :class="`fa-${org_area.icon}`"></i>
                {{ org_area.index }}. {{ org_area.name
                    ?  org_area.name
                    :  $t(`definitions.org_area.${org_area.key}`) }}

                <div class="area-indicator">
                    <i class="fas fa-tasks"></i>
                    {{ $t('definitions.terms.org_area') }}
                </div>
            </div>
        </div>

        <div class="grid-edit-overlay"
             v-if="selected_area != null"
            >
            <div style="text-align: right;">
                <i class="fas fa-times close-btn"
                   @click="clearSelection"
                ></i>
            </div>

            <CustomResultAreaForm
                  v-if="selected_area_type == 'result_area'"
                  :value="selected_area"
                  :area_keys="area_keys"
                  @submit="(v) => submittedResultArea(v)"
                  @delete="removeResultAreaAt(selected_area_index)"
                  @close="clearSelection"
            />
            <CustomOrgAreaForm
                  v-if="selected_area_type == 'org_area'"
                  :value="selected_area"
                  :area_keys="area_keys"
                  @submit="(v) => submittedOrgArea(v)"
                  @delete="removeOrgAreaAt(selected_area_index)"
                  @close="clearSelection"
            />
        </div>
    </div>
</template>


<script>

import CustomResultAreaForm from './CustomResultAreaForm.vue';
import CustomOrgAreaForm from './CustomOrgAreaForm.vue';

export default {
  components: {
    CustomResultAreaForm,
    CustomOrgAreaForm,
  },

    data: () => ({
        selected_area_type: null,
        selected_area_index: null,
        area_keys: [],
    }),

    props: {
        structure: {
            type: Object,
            required: true,
        },
    },

    computed: {

        area_keys() {
        },

        maxRow() {
            let max_row_result = Math.max(
                ...this.structure.result_areas
                    .map((a) => a.position.y + a.position.yspan)
            );
            let max_row_org = Math.max(
                ...this.structure.org_areas
                    .map((a) => a.position.y + a.position.yspan)
            );

            return Math.max(max_row_result, max_row_org);
        },

        selected_area() {
            let index = this.selected_area_index;
            if (this.selected_area_type == "org_area") {
                return this.structure.org_areas[index];
            }
            if (this.selected_area_type == "result_area") {
                return this.structure.result_areas[index];
            }
            return null;
        },
      body_layout_columns() {
          let base_widths = this.structure.grid.column_widths;
          let count = 0;
          while (base_widths.length < this.structure.grid.columns) {
              // safeguard
              if (count++ > 10) break;
              base_widths.push(1);
          }
          if (base_widths.length > this.structure.grid.columns) {
              base_widths.splice(0, this.structure.grid.columns);
          }

          return base_widths
              .map((w) => `${w}fr`)
              .join(" ");
      },
    },

    methods: {

        /*
         * We work with internal mutability of object inside
         * an array, which makes reactivity a PITA. Therefore,
         * we just compute the existing keys right before
         * we select an element
         */
        computeAreaKeys() {
            this.area_keys = [
                ...this.structure.result_areas.map((r) => r.key),
                ...this.structure.org_areas.map((o) => o.key)
            ];
        },

        selectOrgAreaByIndex(index) {
            this.computeAreaKeys();
            this.selected_area_index = index;
            this.selected_area_type = "org_area";
        },
        selectResultAreaByIndex(index) {
            this.computeAreaKeys();
            this.selected_area_index = index;
            this.selected_area_type = "result_area";
        },

        submittedResultArea(area) {
            this.structure.result_areas[this.selected_area_index]
                = area;
            this.selected_area_type = null;
            this.selected_area_index = null;
        },

        submittedOrgArea(area) {
            this.structure.org_areas[this.selected_area_index]
                = area;
            this.selected_area_type = null;
            this.selected_area_index = null;
        },

        removeResultAreaAt(index) {
          this.structure.result_areas.splice(index, 1);
          this.clearSelection();
        },
        removeOrgAreaAt(index) {
          this.structure.org_areas.splice(index, 1);
          this.clearSelection();
        },

        addOrgArea() {
          let min_x_org_areas = Math.min(...this.structure.org_areas.map(a => a.position.x));
          let max_x_org_areas = Math.max(...this.structure.org_areas.map(a => a.position.x));
          let max_y_org_areas = Math.max(...this.structure.org_areas.map(a => a.position.y + a.position.yspan - 1));
          let max_index_org_areas = Math.max(...this.structure.org_areas.map(a => a.index));

          this.structure.org_areas.push({
            key: `org_area_${max_index_org_areas + 1}`,
            index: max_index_org_areas + 1,
            position: {
              x: min_x_org_areas,
              y: max_y_org_areas + 1,
              xspan: max_x_org_areas - min_x_org_areas + 1,
              yspan: 1,
            },
          });

          this.selectOrgAreaByIndex(
            this.structure.org_areas.length - 1
          );
        },
        addResultArea() {
          let randomColor = Math.floor(Math.random() * 16777215).toString(16);

          let min_x_result_areas = Math.min(...this.structure.result_areas.map(a => a.position.x));
          let max_x_result_areas = Math.max(...this.structure.result_areas.map(a => a.position.x));
          let max_y_result_areas = Math.max(...this.structure.result_areas.map(a => a.position.y + a.position.yspan - 1));

          let max_index_result_areas = Math.max(...this.structure.result_areas.map(a => a.index));

          this.structure.result_areas.push({
            key: `result_area_${max_index_result_areas + 1}`,
            index: max_index_result_areas + 1,
            position: {
              x: min_x_result_areas,
              y: max_y_result_areas + 1,
              xspan: max_x_result_areas - min_x_result_areas + 1,
              yspan: 1,
            },
            color: `#${randomColor}`
          });

          this.selectResultAreaByIndex(
            this.structure.result_areas.length - 1
          );
        },

        clearSelection() {
            this.selected_area_index = null;
            this.selected_area_type = null;
        },
    },
}
</script>



<style lang="scss">

.grid-preview {
    position: relative;
    margin-bottom: 200px;

    .close-btn {
        margin: 20px;
        cursor: pointer;
        color: $a3-green;
        font-size: 20px;

        transition: transform .2s ease-in-out;
        transform-origin: center center;

        &:hover {
            transform: scale(1.2);
        }
    }
}

.grid-edit-overlay {
    position: absolute;
    top: -20px;
    min-height: calc(100%  + 40px);
    left: -20px;
    width: calc(100% + 40px);
    border-radius: 10px;
    background-color: rgba($a3-green, .5);

    label {
        color: white !important;
    }

    animation: overlay-in .2s ease-out;

    .ql-toolbar, .ql-container {
        background-color: white;
    }
}


@keyframes overlay-in {
    from { opacity: 0; }
    to { opacity: 1; }
}


.grid-inner {
    &.blur {
        filter: blur(5px);
    }

    transition: grid-template-columns .6s ease-in-out;
    transition-timing-function: cubic-bezier(1.000, 0.270, 0.530, 1.480); /* custom */
}

.area-indicator {
    position: absolute;
    top: 5px;
    width: 100%;
    left: 0;
    text-align: center;
    opacity: .4;
}

.grid-area-preview {
    position: relative;

    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    padding: 15px;
    padding-top: 25px;
    border-color: var(--primary-color);
    text-align: center;
    color: var(--primary-color);
    cursor: pointer;

    transition: color .15s ease-out,
      background-color .15s ease-out;

    > i {
        color: var(--primary-color);
        font-size: 60px;
        opacity: .4;
        padding-bottom: 10px;
        text-align: center;
        display: block;
    }

    &:hover {
        background-color: var(--primary-color);
        color: white;
        i {
            color: white;
        }
    }
}


$grid-add-color: $general-green;

.grid-add-button {
    border: 2px $grid-add-color solid;
    color: $grid-add-color;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    opacity: .6;

    transition: opacity .15s ease-out, color .15s ease-out, background-color .15s ease-out;

    &:hover {
        opacity: 1;
        background-color: $grid-add-color;
        color: white;
        cursor: pointer;
    }
}

</style>





