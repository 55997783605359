<template>
  <div class="print-input-settings">
      <h2>{{ $t('ui.print_settings.title') }} </h2>

      <p>{{ $t('ui.print_settings.intro') }}  </p>

    <div class="element-form">
        <h5><CheckBox v-model="print_settings.includeFrontPage" />
            {{ $t('ui.print_settings.include_front') }}
        </h5>

      <div class="form-field">
          <label>{{ $t('ui.print_settings.scale') }} </label>
        <input type="number" v-model="print_settings.scale" class="a3-input" />
      </div>

      <div class="form-row">
        <div class="form-field">
            <label>{{ $t('ui.print_settings.front_background') }} </label>
          <select v-model="print_settings.front_background" class="a3-input">
            <option v-for="color in color_options"
                    :key="color.value"
                    :value="color.value"
                    >
                    {{ $t(`ui.print_settings.color["${color.name}"]`) }}
            </option>
          </select>
        </div>
        <div class="form-field">
            <label>{{ $t('ui.print_settings.front_text') }} </label>
          <select v-model="print_settings.front_textcolor" class="a3-input">
            <option v-for="color in color_options"
                    :key="color.value"
                    :value="color.value"
                    >
                    {{ $t(`ui.print_settings.color["${color.name}"]`) }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div style="margin-top: 15px">
        <button class="a3-button action"
                @click="$emit('submit', print_settings)"
          >
          <i class="fas fa-file-pdf"></i> {{ $t('ui.general.export') }}
        </button>
        <button class="a3-button cancel"
                @click="$emit('cancel')"
          >
          <i class="fas fa-times"></i> {{ $t('ui.general.close') }}
        </button>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    layout: {
      type: Object,
      required: false,
      default: null,
    }
  },

  data: () => ({
    print_settings: {
      includeFrontPage: true,
      front_background: '#FFFFFF',
      front_textcolor: '#007385',
      scale: 100,
    },
    color_options: [
      { value: '#007385', name: 'A3 Basis' },
      { value: '#FFFFFF', name: 'Plain white' },
      { value: '#000000', name: 'Plain black' },
      { value: '#545F66', name: 'Black Coral' },
      { value: '#160F29', name: 'Eerie Black' },
      { value: '#556F7A', name: 'Payne\'s grey' },
      { value: '#1F2041', name: 'Yankees Blue' },
    ]
  }),

  watch: {
    print_settings: {
      deep: true,
      handler() {
        this.$emit('updateSettings', this.print_settings);
      },
    },
  },

  mounted() {
    this.guessScale();

    if (this.layout && this.layout.layout_id && this.layout.style) {
      if (this.layout.style.background_color) {
        this.print_settings.front_background = this.layout.style.background_color;
      }
      if (this.layout.style.title_color) {
        this.print_settings.front_textcolor = this.layout.style.title_color;
      }

      for (let s of Object.keys(this.layout.style)) {
        if (s.endsWith('_color') && this.layout.style[s]) {
          this.color_options.push({
            value: this.layout.style[s],
            name: s
          });
        }
      }
    }

  },

  methods: {
    guessScale() {
      let a3_el = document.getElementById('a3-document-container');
      let h = a3_el.offsetHeight;
      let factor = (1920 / h) / (29.7 / 21);
      this.print_settings.scale = Math.floor(factor * 100);
      if (this.print_settings.scale > 120) {
        this.print_settings.scale = 120;
      }
    },
  }
};

</script>

<style lang="scss">
@import "@/assets/variables.scss";

.print-input-settings {
  position: fixed;
  bottom: 10%;
  right: 0;
  width: 300px;
  padding: 20px;
  border: 2px white solid;
  border-right: none;
  border-radius: 10px 0 0 10px;
  background-color: $a3-green;
  animation: slideIn .2s ease-out;
  h5 {
    margin: 0;
  }

  .element-form .form-row {
    margin-top: 0;
    .a3-input  {
      width: 100%;
    }
  }


  .element-form .form-field label {
    color: white;
    display: block;
    text-align: left !important;
    padding: 0;
    width: 100% ;
  }
}

@keyframes slideIn {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}
</style>

