<template>
  <WithReportMenuContainer :a3doc_id="a3document_id" :filter_slug="filter_slug">

    <div style="max-width: 1120px; margin: 0 auto;">
      <h1 class="report-title">
          <i class="fas fa-bullseye-arrow"></i>
          {{ $t('ui.report.result_area_placemat') }}</h1>
      <h2 class="report-a3-name">{{ a3_title }}</h2>

      <FilterPrint :filter="filterOptions"
         v-if="a3_document != null"
         :a3_document="a3_document"
      />

      <template v-if="a3_document != null">
        <A3ResultAreaReport
            v-for="area in a3_document.meta.layout.structure.result_areas.map(x => x.key)"
               :key="area"
               :filterMap="filterMap"
               :result_area="area"
               :a3_document="a3_document" />
      </template>
    </div>
  </WithReportMenuContainer>
</template>

<script>

import FilterSlugUrl from '@/mixins/FilterSlugUrl';

import A3ResultAreaReport from '@/components/reports/A3ResultAreaReport.vue';
import WithReportMenuContainer from '@/views/WithReportMenuContainer.vue';

import FilterPrint from '@/components/elements/FilterPrint.vue';

export default {
  mixins: [FilterSlugUrl],

  components: {
    A3ResultAreaReport,
    WithReportMenuContainer,
    FilterPrint,
  },

  props: {
    a3document_id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    a3_document: null,
    filterMap: null,
    filterOptions: null,
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    },
    a3_title() {
        if (this.a3_document == null) { return null; }
        const meta = this.a3_document.meta;

        if (this.a3_document.meta.department != null) {
            return `${meta.department.name}, ${meta.timeframe.name}`;
        }

        return `${meta.organisation.name}, ${meta.timeframe.name}`;
    },
  },

  mounted() {
    this.loadA3();
  },

  methods: {
    loadA3() {
      this.$http.get(`a3/${this.a3document_id}`)
        .then((res) => {
          this.a3_document = res.body;
          this.filterOptions = this.getFilterOptions(this.filter_slug);
          this.filterMap = this.filterMapFromOptions(this.filterOptions);
        });
    },
  },
};
</script>
