<template>
  <span :id="picker_id" style="border: 1px black solid;"/>
</template>

<script>

import Pickr from '@simonwep/pickr';

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    picker: null,
  }),

  computed: {
    picker_id() {
      return `color-picker-${this._uid}`;
    },
  },

  mounted() {
    this.picker = Pickr.create({
      el: `#${this.picker_id}`,
      theme: 'nano',
      // default: this.value,

      components: {
        // Main components
        preview: true,
        opacity: true,
        hue: true,

        // Input / output Options
        interaction: {
          hex: true,
          rgba: true,
          input: true,
          clear: false,
          save: true
        }
      }
    });

    if (!this.value) {
      this.$emit('input', null);
    }

    this.picker.on('init', () => {
      if (this.value) {
        this.picker.setColor(this.value);
      } else {
        this.picker.setColor(null);
      }
    });

    this.picker.on('save', (value) => {
      this.$emit('input', value.toHEXA().toString());
      this.picker.hide();
    });
  }
};
</script>

<style>
.pickr .pcr-button {
  border: 1px black solid;
}
</style>
