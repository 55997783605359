<template>
    <WithMenuContainer>
        <div class="container">
            <h1>Adviseur profiel bewerken</h1>

            <div class="profile-grid">
                <div class="profile-photo">
                    <div class="add-photo-container">
                        <i class="fas fa-plus"></i>
                        Foto
                    </div>
                </div>

                <div>
                    <div class="element-form" v-if="advisor_profile != null">
                        <h2>{{ person.name }}</h2>
                        <em> {{ person.function_name }} </em>

                        <div class="form-row">
                            <label>{{ $t('ui.advisor.profile_text') }}</label>
                            <RichTextEditor v-model="advisor_profile.profile_text"
                                            data-cy="profile-text-input"
                            />
                        </div>

                        <div class="form-row">
                            <label>
                                <i class="fas fa-envelope"></i>
                                {{ $t('ui.advisor.mail') }}
                            </label>
                            <input v-model='advisor_profile.mail'
                                   class="a3-input"
                                   data-cy="mail-input"
                            />
                        </div>

                        <div class="form-row">
                            <label>
                                <i class="fas fa-phone-alt"></i>
                                {{ $t('ui.advisor.phone') }}
                            </label>
                            <input v-model='advisor_profile.phone'
                                   class="a3-input"
                                   data-cy="phone-input"
                            />
                        </div>
                    </div>

                  <div class="form-buttons" style="margin-top: 30px">
                      <button class="a3-button action"
                              data-cy="submit-button"
                        @click="submit">
                          <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
                      </button>

                      <button class="a3-button cancel"
                              data-cy="cancel-button"
                          @click="$router.back()">
                          <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
                      </button>
                  </div>
                </div>
            </div>
        </div>
    </WithMenuContainer>
</template>

<script>

import RichTextEditor from '@/components/forms/RichTextEditor';

export default {

    components: {
        RichTextEditor,
    },

    data: () => ({
        advisor_profile: null,
        loading: true,
    }),

    mounted() {
        this.loadProfile();
    },

    computed: {
        person() {
            return this.$store.getters['auth/person'];
        },
        account() {
            return this.$store.getters['auth/account'];
        },
    },

    methods: {
        loadProfile() {
            this.$http.get('advisor/profile').then((res) => {
                this.advisor_profile = res.body;
                if (this.advisor_profile == null) {
                    this.advisor_profile = {
                        profile_text: null,
                        mail: this.account.mail,
                        phone: null,
                    };
                }
            });
        },

        submit() {
            this.$http.put('advisor/profile', this.advisor_profile)
                .then(() => {
                this.$router.push('/me');
            });
        },
    },
};
</script>


<style lang="scss">

.add-photo-container {
    border-radius: 50%;
    background-color: gray;
    width: 125px;
    height: 125px;
    line-height: 125px;
    text-align: center;
    color: white;
    margin: 30px auto;
    cursor: pointer;
    transition: background-color .2s ease-out;

    &:hover {
        background-color: $a3-green;
    }
}

.profile-grid {
    display: grid;

    grid-template-columns: .2fr .8fr;
    grid-column-gap: 30px;
}
</style>
