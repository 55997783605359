<template>
  <div
    @click.shift="$emit('focus')"
    @click.exact="$emit('select')"
      @mouseover="$emit('mouseover')"
      @mouseleave="$emit('mouseleave')"
    @contextmenu.prevent="interactionManager.setContextMenu(
                                $event,
                                'sbf',
                                { sbf }
                                )"
     style="position: relative"
     class="sbf-element"
     :class="[resultAreaToClass(sbf.result_area), inFocus ? '' : 'inactive']"
      :style="{'--main-item-color': getSbfColor(sbf)}"
     >
    {{index + 1}}.

    <span class="sbf-list-element"
          :class="{
            'review-active': reviewAnnotations.filter(x => x.element_type === 'Comment').length > 0,
            'review-deleted': displayStrikethrough,
            'review-added': displayNewStyle
          }"
      >
        <WordDiffRender :base="sbf.name" :newValue="review_name" />
    </span>

    <div class="review-comment-add-button"
         v-if="review != null && editable"
         @click.stop="$emit('comment')"
         >
         <i class="fas fa-comment-alt-plus"></i>
    </div>

    <template v-if="displayOptions.show_plan_source && sbf.sbf_ref != null">
      <div style="padding-left: 21px">
        <i class="fa fa-rotate-90 fa-level-up"></i>
        <span style="padding-left: 5px">Vanuit:
          {{ A3NameFromRelations( relations, sbf.a3doc_id ) }}
        </span>
      </div>
    </template>
    <template v-if="displayOptions.show_pushed_to && sbf.child_refs.length > 0">
      <div style="padding-left: 15px">
        <i class="fas fa-arrow-down"></i> Doorgezet:
        {{ sbf.child_refs.map((x) => A3NameFromRelations( relations, x.a3doc_id))  }}
      </div>
    </template>

  </div>
</template>

<script>

import ResultAreaHelper from '@/mixins/ResultAreaHelper';
import A3RelationHelper from '@/mixins/A3RelationHelper';
import WithDocumentManager from '@/mixins/WithDocumentManager';

import WordDiffRender from '@/components/elements/WordDiffRender';

export default {
  components: { WordDiffRender },
  mixins: [ResultAreaHelper, A3RelationHelper, WithDocumentManager],

  props: {
    sbf: {
      type: Object,
      required: true,
    },

    displayOptions: {
      type: Object,
      required: false
    },

    inFocus: {
      type: Boolean,
      required: false,
      default: true,
    },

    index: {
      type: Number,
      required: true,
    },

    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    review_name() {
      if (this.review == null) { null; }
      let reviewName = this.reviewAnnotations
        .find(x => x.element_type === 'Edit');
      if (reviewName == undefined) { return null; }
      return reviewName.new_value.name;
    },

    reviewAnnotations() {
      if (!this.review || !this.review.elements) return [];
      return this.review.elements
        .filter(x =>
          x.sbf_id == this.sbf.id
          || x.target_type === 'Sbf' && x.new_value.id == this.sbf.id
        );
    },

    displayStrikethrough() {
      return this.reviewAnnotations
            .find(x => x.element_type === 'Delete') !== undefined;
    },
    displayNewStyle() {
      return this.reviewAnnotations
            .find(x => x.element_type === 'Create') !== undefined;
    }
  },

    methods: {
        getSbfColor(sbf) {
            if (this.a3_layout.structure.sbf_result_mapping == "one_to_one"
                && sbf.result_area != "__none__"
            ) {
                return this.area_colors[sbf.result_area]
            }
            if (this.a3_layout.structure.sbf_result_mapping == "one_to_many"
                || sbf.result_area == "__none__") {
                if (sbf.color) return sbf.color;
            }
            return '#333333';
        }
  },
};
</script>

<style lang="scss">
.sbf-list-element {
  &.review-active {
    background-color: yellow;
    padding-left: 2px;
    padding-right: 2px;
  }

  &.review-deleted {
    text-decoration: line-through;
    opacity: .6;
  }

  &.review-added {
    text-decoration: underline;
    &:before {
      content: '(+)';
      color: $general-green;
    }
  }
}
</style>
