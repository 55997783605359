<template>
  <div class="element-form">
    <h1>{{ $t('ui.vision.edit') }}</h1>

    <div style="margin: 30px 0" id="vision-edit-container">
      <RichTextEditor v-model="vision" />
    </div>

    <button class="a3-button action"
            id="vision-save-button"
      @click="save">
      <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
    </button>
    <button class="a3-button cancel"
            id="vision-save-button"
            @click="interactionManager.backToDocument()">
      <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
    </button>
  </div>
</template>

<script>

import RichTextEditor from '@/components/forms/RichTextEditor.vue';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  mixins: [ WithDocumentManager ],
  components: {
    RichTextEditor,
  },

  mounted() {
    this.vision = this.a3_document.vision;
    this.$UiEventBus.$emit('shown', {key: 'vision-edit'});
  },

  watch: {
    vision(value) {
      if (value != null && value.length > 0) {
        this.$UiEventBus.$emit('edited', {key: 'vision-edit'});
      }
    }
  },

  data: () => ({
    vision: "",
  }),

  methods: {
    save() {
      this.dataManager
        .saveVision(this.vision)
        .then(() => {
          this.$UiEventBus.$emit('submit', {key: 'vision-edit'});
          this.interactionManager.backToDocument();
        });
    }
  },
};
</script>
