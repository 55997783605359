<template>
  <div>
      <div class="element-form">
        <div class="form-field">
          <label>{{ $t('ui.general.department') }} </label>
          <select class="a3-input" v-model="person.department_id">
            <option value=""><em>*Geen*</em></option>
            <option v-for="d in departments"
                    :key="d.id"
                    :value="d.id">
              {{ d.name }}
            </option>
          </select>
        </div>

        <div class="form-field">
            <label>{{ $t('ui.general.name') }} </label>
          <input class="a3-input" v-model="person.name" />
        </div>

        <div class="form-field">
            <label>{{ $t('ui.organisation.initials') }} </label>
          <input class="a3-input" v-model="person.initials" />
        </div>

        <div class="form-field">
            <label>{{ $t('ui.organisation.function') }} </label>
          <input class="a3-input" v-model="person.function_name" />
        </div>

        <div class="form-field">
            <label>{{ $t('ui.user_type.user_type') }} </label>
            <select
              class="a3-input"
              v-model="person.user_type_id">
                <option :value="null">-</option>
                <option v-for="ut in user_types"
                        :value="ut.id"
                >
                  {{ ut.name }}
                </option>
            </select>
        </div>


        <template v-if="include_account_form">
          <div class="form-field">
            <label>
              {{ $t('ui.accounts.create_account') }}?
            </label>
            <CheckBox v-model="person.create_account" />
          </div>

          <template v-if="person.create_account">
            <div class="form-field">
                <label>{{ $t('ui.accounts.mail') }} </label>
                <input class="a3-input" v-model="person.mail" />
                <FormError :error="errors.mail" />
            </div>
            <div class="form-buttons" style="margin-top: 5px">
                <em>{{ $t('ui.accounts.password_reset_mail') }} </em>
            </div>
          </template>
        </template>
      </div>

      <div class="form-buttons" style="margin-top: 30px">
        <button class="a3-button action" @click="$emit('submit')">
            <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
        </button>
        <template v-if="person.id">
          <button class="a3-button danger" @click="$emit('delete')">
              <i class="fas fa-trash"></i> {{ $t('ui.general.delete') }}
          </button>
        </template>

        <button class="a3-button cancel" @click="$emit('cancel')">
            <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
        </button>
      </div>
    </div>
</template>


<script>

export default {
  props: {
    include_account_form: {
      type: Boolean,
      required: false,
      default: false,
    },
    person: {
      type: Object,
      required: true,
    },
    errors: {
        type: Object,
        required: true,
    },
  },

  data: () => ({
    departments: [],
    user_types: [],
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    }
  },

  mounted() {
    this.loadDepartments();
    this.loadUserTypes();
    this.person.organisation_id = this.organisation.id;
  },


  watch: {
    'person.name'(value) {
      let splitted = value.split(/[\s.]/);

      if (splitted.length > 0) {
        this.person.initials = splitted
          .map(x => x[0])
          .join("");
      }
    },
  },
  methods: {
    loadDepartments() {
      this.$http.get(`organisation/department`)
        .then((res) => {
           this.departments = this.flattenDepartmentList(res.body);
        });
    },

    loadUserTypes() {
      this.$http.get(`admin/user_type`)
        .then((res) => {
          this.user_types = res.body;
        });
    },

    flattenDepartmentList(departments, depth = 0) {
      let list = [];
      for (let x of Object.values(departments)) {
        list.push({id: x.id, name: `${"----".repeat(depth)} ${x.name}` });
        if (x.children.length > 0) {
          list.push(...this.flattenDepartmentList(x.children, depth + 1));
        }
      }
      return list;
    },
  },
};
</scripT>
