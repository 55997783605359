<template>
  <WithReportMenuContainer :a3doc_id="a3document_id" :filter_slug="filter_slug">
    <div style="float: right">
      <button class="a3-button action"
              @click="$router.push(action_table_url)"
        >
        <i class="fas fa-table"></i> Bekijk actietabel
      </button>
    </div>

    <div style="max-width: 1120px; margin: 0 auto;">
        <h1 class="report-title">
            <i class="fas fa-tasks"></i>
            {{ $t('ui.report.org_area_placemat') }}</h1>
        <h2 class="report-a3-name">{{ a3_title }}</h2>

      <FilterPrint :filter="filterOptions"
         v-if="a3_document != null"
         :a3_document="a3_document"
      />

      <template v-if="a3_document != null && actions != null">

        <A3OrgAreaReport
               v-for="area in org_areas"
               :a3_document="a3_document"
               :filterMap="filterMap"
               :key="area.key"
               :org_area="area"
               :actions="actions" />
      </template>
    </div>
  </WithReportMenuContainer>
</template>

<script>

import FilterSlugUrl from '@/mixins/FilterSlugUrl';

import A3OrgAreaReport from '@/components/reports/A3OrgAreaReport.vue';
import WithReportMenuContainer from '@/views/WithReportMenuContainer.vue';

import FilterPrint from '@/components/elements/FilterPrint.vue';

export default {

  mixins: [FilterSlugUrl],

  components: {
    A3OrgAreaReport,
    WithReportMenuContainer,
    FilterPrint,
  },

  props: {
    a3document_id: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    a3_document: null,
    filterMap: null,
    filterOptions: null,

    actions: null,
    org_areas: []
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    },

    a3_title() {
        if (this.a3_document == null) return null;
        let meta = this.a3_document.meta;

        if (this.a3_document.meta.department != null) {
            return `${meta.department.name}, ${meta.timeframe.name}`;
        }

        return `${meta.organisation.name}, ${meta.timeframe.name}`;
    },

    action_table_url() {
      let url = `/report/action_table/${this.a3document_id}`;
      if (this.filter_slug != null) {
        url += `?filter=${this.filter_slug}`;
      }
      return url
    }
  },

  mounted() {
      this.loadA3();
      this.loadActions();
  },

  methods: {
    loadA3() {
      this.$http.get(`a3/${this.a3document_id}`)
        .then(res => {
          this.a3_document = res.body;
          this.org_areas = this.a3_document.meta.layout.structure.org_areas;

          this.filterOptions = this.getFilterOptions(this.filter_slug);
          this.filterMap = this.filterMapFromOptions(this.filterOptions);
        });
    },

    /*
     * We use the action report form the backend to get the data here,
     *
     * Very soon, this should replace the whole A3 doc retrieval thingy
     */
    loadActions() {
        this.$http.get(`report/action_list/${this.a3document_id}`)
            .then((res) => {
                this.actions = res.body.actions;
            });
    },
  },
};
</script>
