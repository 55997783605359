export default {
  methods: {


    resultAreaToClass(type) {
      switch (type) {
        case 'customers_and_partners':
          return 'item-klanten-partners';
        case 'board_and_financers':
          return 'item-bestuurs-financiers';
        case 'society':
          return 'item-maatschappij';
        case 'employees':
          return 'item-medewerkers';
        default:
          return 'item-default';
      }
    },

    /*
     * Based off of the function in the dataManager
     * in file data.ts
     */
    resultAreaFromLayout(layout, area_key) {
      const ra = layout.structure.result_areas
          .find((area) => area.key === area_key);

      if (ra == null) {
        return {
            color: "#444",
            key: area_key,
            name: "??",
        }
      }

      return ra;
    },


    /*
     * Based off of the function in the dataManager
     * in file data.ts
     */
    orgAreaFromLayout(layout, area_key) {
      const ra = layout.structure.org_areas
          .find((area) => area.key === area_key);

      if (ra == null) {
        return {
            key: area_key,
            name: "??",
        }
      }

      return ra;
    }
  }
}
