<template>
    <div class="element-form"
      style="display: inline-block; width: 1000px; vertical-align: top; padding-left: 15px;">

      <div class="form-row">
        <div class="form-field">
          <label>{{ $t('ui.layout.top_structure') }} </label>
          <select v-model="structure.top_widths" class="a3-input">
            <option :value="[25, 25, 50]">25%-25%-50%</option>
            <option :value="[30, 30, 40]">30%-30%-40%</option>
            <option :value="[33, 33, 33]">33%-33%-33%</option>
          </select>
            <FormError :error="errors.structure.top_widths" />
        </div>
        <div class="form-field">
          <label>{{ $t('ui.layout.bottom_structure') }} </label>
          <select v-model="structure.bottom_widths" class="a3-input">
            <option :value="[20, 20, 20, 20, 20]">20%-20%-20%-20%-20%</option>
            <option :value="[16, 26, 16, 26, 16]">16%-26%-16%-26%-16%</option>
            <option :value="[15, 20, 15, 25, 25]">15%-20%-15%-25%-25%</option>
          </select>
            <FormError :error="errors.structure.top_widths" />
        </div>
      </div>

      <div class="form-row"
           v-if="$hasFeature('A3CustomAreas')"
          >
          <div class="form-field">
              <label>{{ $t('ui.layout.custom_areas') }}</label>
              <CheckBox
                id="layout-custom-areas-checkbox"
                v-model="structure.custom_areas" />
          </div>
      </div>

      <div class="form-row" v-if="structure.custom_areas">
          <h3>{{ $t('ui.layout.a3_grid') }}</h3>

          <div class="form-field">
              <label>{{ $t('ui.layout.grid_x') }}</label>
              <input v-model.number="structure.grid.columns" class="a3-input structure-area-input-grid-x" />
          </div>
          <div class="form-field">
              <label>{{ $t('ui.layout.grid_y') }}</label>
              <input v-model.number="structure.grid.rows" class="a3-input structure-area-input-grid-y" />
          </div>
          <div class="form-field">
              <label>{{ $t('ui.layout.grid_column_widhts') }}</label>
                  <div style="display: inline-grid; width: 70%; grid-column-gap: 30px;"
                       :style="{'grid-template-columns': `repeat(${structure.grid.columns}, 1fr)`}">
                  <template v-for="w, index in structure.grid.column_widths">
                    <input v-model.number="structure.grid.column_widths[index]"
                           style="width: 100%"
                    />
                  </template>
              </div>
          </div>
      </div>

      <div class="form-row" v-if="structure.custom_areas">
          <h3>{{ $t('ui.layout.relations') }}</h3>

            <div class="form-field">
              <label>{{ $t('ui.layout.sbf_result_mapping') }} </label>
              <select v-model="structure.sbf_result_mapping" class="a3-input">
                <option value="one_to_one">1-op-1</option>
                <option value="one_to_many">1-op-veel</option>
              </select>
                <FormError :error="errors.structure.sbf_result_mapping" />
            </div>
      </div>

      <GridPreview :structure="structure"
                   v-if="structure.custom_areas"
      />

    </div>
</template>

<script>

import CustomResultAreaForm from './CustomResultAreaForm.vue';
import CustomOrgAreaForm from './CustomOrgAreaForm.vue';

import GridPreview from './GridPreview.vue';

export default {
  components: {
    CustomResultAreaForm,
    CustomOrgAreaForm,
    GridPreview
  },

  props: {
    structure: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },

  watch: {
    'structure.custom_areas'(value) {
      if (value === true) {
        if (!this.structure.grid) {
          this.initGridValues();
        }

        if (!this.structure.result_areas) {
          this.initResultAreas();
        }

        if (!this.structure.org_areas) {
          this.initOrgAreas();
        }
      }
    },

    'structure.grid.columns'(value) {
        let bw = this.structure.grid.column_widths;
        let count = 0;

        while (bw.length < this.structure.grid.columns) {
            // safeguard
            if (count++ > 100) break;
            bw.push(1);
        }
        if (bw.length > this.structure.grid.columns) {
            bw.splice(this.structure.grid.columns, bw.length - this.structure.grid.columns);
        }

        this.structure.grid.coluns_widths = bw;
    },
  },

  methods: {
    initGridValues() {
      this.structure.grid = {
        columns: 5,
        rows: 3,
        column_widths: [20, 20, 20, 20, 20]
      };
    },

    initResultAreas() {
      console.log(this);
      this.structure.result_areas = [
        {
          key: "customers_and_partners",
          name: this.$t('definitions.result_area.customers_and_partners'),
          index: 6,
          position: { x: 4, y: 2, xspan: 1, yspan: 1 },
          color: "#61c107",
        },
        {
          key: "employees",
          name: this.$t('definitions.result_area.employees'),
          index: 7,
          position: { x: 4, y: 1, xspan: 1, yspan: 1 },
          color: "#0174b7"
        },
        {
          key: "society",
          name: this.$t('definitions.result_area.society'),
          index: 8,
          position: { x: 4, y: 3, xspan: 1, yspan: 1 },
          color: "#f06e00"
        },
        {
          key: "board_and_financers",
          name: this.$t('definitions.result_area.board_and_financers'),
          index: 9,
          position: { x: 5, y: 1, xspan: 1, yspan: 3 },
          color: "#dd291a"
        },
      ]
    },

    addResultArea() {
      let randomColor = Math.floor(Math.random() * 16777215).toString(16);

      let min_x_result_areas = Math.min(...this.structure.result_areas.map(a => a.position.x));
      let max_x_result_areas = Math.max(...this.structure.result_areas.map(a => a.position.x));
      let max_y_result_areas = Math.max(...this.structure.result_areas.map(a => a.position.y));
      let max_index_result_areas = Math.max(...this.structure.result_areas.map(a => a.index));

      this.structure.result_areas.push({
        key: `result_area_${max_index_result_areas + 1}`,
        index: max_index_result_areas + 1,
        position: {
          x: min_x_result_areas,
          y: max_y_result_areas + 1,
          xspan: max_x_result_areas - min_x_result_areas + 1,
          yspan: 1,
        },
        color: `#${randomColor}`
      });
    },

    removeResultAreaAt(index) {
      this.structure.result_areas.splice(index, 1);
    },

    initOrgAreas() {
      this.structure.org_areas = [
        {
          key: "leadership",
          name: this.$t('definitions.org_area.leadership'),
          index: 1,
          position: { x: 1, y: 1, xspan: 1, yspan: 3 }
        },
        {
          key: "strategy_and_policy",
          name: this.$t('definitions.org_area.strategy_and_policy'),
          index: 2,
          position: { x: 2, y: 2, xspan: 1, yspan: 1 }
        },
        {
          key: "management_employees",
          name: this.$t('definitions.org_area.management_employees'),
          index: 3,
          position: { x: 2, y: 1, xspan: 1, yspan: 1 }
        },
        {
          key: "management_resources",
          name: this.$t('definitions.org_area.management_resources'),
          index: 4,
          position: { x: 2, y: 3, xspan: 1, yspan: 1 }
        },
        {
          key: "management_processes",
          name: this.$t('definitions.org_area.management_processes'),
          index: 5,
          position: { x: 3, y: 1, xspan: 1, yspan: 3 }
        },
      ]
    },

    addOrgArea() {
      let min_x_org_areas = Math.min(...this.structure.org_areas.map(a => a.position.x));
      let max_x_org_areas = Math.max(...this.structure.org_areas.map(a => a.position.x));
      let max_y_org_areas = Math.max(...this.structure.org_areas.map(a => a.position.y));
      let max_index_org_areas = Math.max(...this.structure.org_areas.map(a => a.index));

      this.structure.org_areas.push({
        key: `org_area_${max_index_org_areas + 1}`,
        index: max_index_org_areas + 1,
        position: {
          x: min_x_org_areas,
          y: max_y_org_areas + 1,
          xspan: max_x_org_areas - min_x_org_areas + 1,
          yspan: 1,
        },
      });
    },

    removeOrgAreaAt(index) {
      this.structure.org_areas.splice(index, 1);
    },
  },
}
</script>
