<template>
  <div class="review-container"
       :style="{'top': `${topPosition}px`,
        height: `calc(100vh - ${topPosition}px)`
       }"
    >

    <ReviewSelector
       :a3doc_id="dataManager.a3DocId"
       :editable="editable"
       @selectReview="$emit('selectReview', $event)"
       v-if="!review" />


    <template v-if="review && review.elements">
      <ReviewMenu
              @save="$emit('close')"
              @report="$router.push(`/review/a3/${dataManager.a3DocId}/${dataManager.reviewId}`)"
      />

      <ReviewElement
         v-for="(element, index) in review.elements"
         :key="index"
         :element="element"
         :dataManager="dataManager"
         :editable="review.review.status != 'Processed'"
         @update="dataManager.updateElement(element)"
         @approve="dataManager.approveElement(element)"
         @approveComment="dataManager.approveComment(element)"
         @decline="dataManager.declineElement(element)"
         @delete="review.elements.splice(index, 1)"
      />

      <div v-if="review.elements.length == 0"
           style="margin-top: 50px"
        >
        <em>
          Er is nog geen commentaar geplaatst in dit document.
          Je kunt commentaar plaatsten door:

          <ul>
            <li>Een missie, visie, sbf, pi, of actie te bewerken</li>
            <li>Op het 'commentaar' blokje te klikken naast een sbf, actie of pi</li>
          </ul>
        </em>
      </div>
    </template>
  </div>
</template>

<script>

import ReviewElement from '@/components/elements/ReviewElement';
import ReviewSelector from '@/components/elements/ReviewSelector';
import ReviewMenu from '@/components/elements/ReviewMenu';

export default {
  components: {
    ReviewElement,
    ReviewSelector,
    ReviewMenu,
  },
  props: {
    review: {
      type: Object,
      required: false,
      default: null,
    },

    dataManager: {
      type: Object,
      required: true,
    },

    editable: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data: () => ({
    topPosition: 100,
  }),

  mounted() {
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop < 100) {
        this.topPosition = 100 - document.documentElement.scrollTop;
      } else {
        this.topPosition = 0;
      }
    });
  },
};

</script>


<style lang="scss">


.review-container {
  box-sizing: border-box;
  width: 20%;
  padding: 20px;
  position: fixed;
  right: 0;
  display: inline-block;
  background-color: $review-orange;
  z-index: 0;

  animation-name: reviewAnimationIn;
  animation-timing-function: ease-out;
  animation-fill-mode: backwards;
  animation-duration: .3s;
  animation-delay: .25s;

  overflow-y: auto;
}

@keyframes reviewAnimationIn {
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
}

.review-comment {
  box-sizing: border-box;
  padding: 10px;
  background-color: lighten($review-orange, 5%);
  border-radius: 0px 5px 5px 0;
  cursor: pointer;
  transition: transform .15s ease-out;
  margin-bottom: 20px;
  margin-left: -21px;
  transform-origin: left;
  border-left: 2px darken($review-orange, 25%) solid;

  &:hover {
    transform: scale(1.05);
  }
}

.review-author {
  font-style: italic;
  opacity: .6;
  margin-bottom: 10px;
}

.review-header {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 3px;
}
</style>
