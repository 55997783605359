<template>
  <WithMenuContainer>
    <LoadingOverlay :loading="is_loading" />
    <div class="a3-sheet-container"
      >
        <div class="a3-sheet-title">
            <A3SheetMenu
                :a3_right="a3_right"
                :focus_active="isFocusActive"
                @focusTab="show_filter_tab = true"
                @displayTab="show_display_tab = true"
                @report="gotoReport"
                :print_active="print_preview"
                :review_active="review != null"
                @print="print_preview = !print_preview"
                @review="toggleReview"
                @share="$router.push(`/a3/${a3_document.id}/share`)"
                @layout="$router.push(`/a3/${a3_document.id}/layout`)"
                @options="$router.push(`/a3/${a3_document.id}/options/owner`)"
                @delete="show_delete_overlay = true"
             />
        </div>

        <div :class="{'hide-full-height': overlay != null,
                     'print-mode': print_preview
          }" v-if="a3_document != null" id="a3-document-container">

          <img v-if="logo_src" :src="logo_src"
               id="a3-header-logo-img"
               style="max-height: 75px;
                margin-top: -25px;
               display: inline-block; margin-left: 20px;
                  vertical-align: bottom;
                  margin-right: 10px;
               "
          />

          <h1 class="a3-title">{{ $tc('ui.general.plan') }}: {{ a3_header }}
                <InfoBox v-if="a3_document.meta.timeframe.closed"
                         tkey="timeframe.closed_inline">
                    <template v-slot:display>
                        <i class="fas fa-lock" style="opacity:.4" ></i>
                    </template>
                </InfoBox>
          </h1>
          <h2 class="a3-sub-title"
            v-if="a3_document.meta.meta.matrix_name != null">
            Programma:
              <span class="name-edit"
                    v-if="editable"
                    @click="$router.push(`/a3/${a3_document.id}/rename_matrix`)">
                {{ a3_document.meta.meta.matrix_name }}
              </span>
              <span v-else>
                {{ a3_document.meta.meta.matrix_name }}
              </span>

          </h2>

          <A3Document
            v-if="a3_document"
            :displayOptions="displayOptions"
            :focusMap="a3Focus"
            :editable="editable"
            :interactionManager="interactionManager"
            :dataManager="dataManager"
            @setFocus="setFocusElement"
            @selectSbf="interactionManager.selectSbf($event)"
            @el_mouseover="elementMouseOver($event)"
            @el_mouseleave="elementMouseLeave($event)"
          />
        </div>

        <PrintFrontPage v-if="print_preview"
            :a3_document="a3_document"
            :meta="a3_document.meta"
            :print_settings="print_settings"
        />

        <PrintSettingsInput
                        v-if="print_preview"
                        :layout="a3_document.meta.layout"
              @updateSettings="print_settings = $event"
              @submit="printPDF"
              @cancel="print_preview = false"
        />

        <Review v-if="show_review_tab"
                :dataManager="dataManager"
                :editable="editable"
                @selectReview="selectReview"
                @close="toggleReview"
                :review="review"
                />


        <DisplayTab v-if="show_display_tab"
                    @close="show_display_tab = false"
                    @resetDisplayOptions="resetDisplayOptions"
                    :displayOptions="displayOptions"
                    :dataManager="dataManager"
        />
        <FilterTab :a3_document="a3_document"
          v-show="show_filter_tab"
          :focusOptions="focusOptions"
          :dataManager="dataManager"
          :focus_active="isFocusActive"
          :visible="show_filter_tab"
          @focusUpdate="focusUpdate"
          @close="show_filter_tab = false" />

        <ConfirmationOverlay v-if="show_delete_overlay"
                             @submit="deleteA3doc"
                             @cancel="show_delete_overlay = false"
                             :disable_submit="!is_deleteable"
        >
            Weet je zeker dat je dit <strong>Gehele A3 document</strong>
            wilt verwijderen?

            Let op:
            <ul>
                <li>Dit verwijderd alle elementen die in dit jaarplan zijn aangemaakt.</li>
                <li>Dit kan <strong>niet</strong> ongedaan gemaakt worden.</li>
            </ul>

            <div v-if="!is_deleteable" style="font-size: 14px; color: #dd0000;">
                <i class="fas fa-exclamation-square"></i>
                Dit jaarplan kan niet verwijderd worden, omdat elementen
                gelinkt zijn aan subjaarplannen. Verwijder eerst de subjaarplannen,
                alvorens dit jaarplan te verwijderen.
            </div>
        </ConfirmationOverlay>

        <A3DocumentTour v-if="a3_document && show_tour"
                        @close="show_tour = false"
        />

        <A3ContextMenu v-if="interactionManager.contextmenu"
                       :dataManager="dataManager"
                       :interactionManager="interactionManager"
                       @setFocus="setFocusElement"
                       />

        <template v-if="$route.name != 'a3-root'">
          <SelectionOverlay @closeOverlay="interactionManager.backToDocument()" >
            <router-view
                  v-if="a3_document"
                  :a3_document="a3_document"
                  :relations="relations"
                  :dataManager="dataManager"
                  :interactionManager="interactionManager"
                  :editable="editable"
                  :editmode="editmode"
              ></router-view>
          </SelectionOverlay>
        </template>

    </div>
  </WithMenuContainer>
</template>

<script>

import A3Document from '@/components/A3Document';
import A3DocumentTour from '@/components/tour/A3DocumentTour.vue';
import A3SheetMenu from '@/components/A3SheetMenu';
import SelectionOverlay from '@/components/SelectionOverlay';
import WithMenuContainer from '@/views/WithMenuContainer';

import PrintFrontPage from '@/components/print/PrintFrontPage.vue';
import PrintSettingsInput from '@/components/print/PrintSettingsInput.vue';

import A3ContextMenu from '@/components/elements/A3ContextMenu.vue';
import DisplayTab from '@/components/a3tabs/DisplayTab.vue';
import FilterTab from '@/components/a3tabs/FilterTab.vue';
import Review from '@/components/a3tabs/Review.vue';

import focusCalculations from '@/services/focusCalculations.js';
import filterEncoding from '@/services/filterEncoding.js';
import A3Validation from '@/services/a3Validation.js';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { StandardDocumentManager } from '@/services/A3DocumentManagers/index.ts';
import { StandardA3DataManager } from '@/services/A3DocumentManagers/data.ts';
import { ReviewDataManager } from '@/services/A3DocumentManagers/data_review.ts';

export default {
  name: 'a3sheet',

  components: {
    A3Document,
    A3SheetMenu,
    A3ContextMenu,
    A3DocumentTour,
    SelectionOverlay,
    WithMenuContainer,
    DisplayTab,
    FilterTab,
    Review,

    PrintFrontPage,
    PrintSettingsInput,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  watch: {
    $route (to) {
      // Reload A3 if someone enters the route
      if (to.name == 'a3-root') {
        this.loadData();
      }
    },
    review_id() {

    },
    id() {
      // When the ID changes, we need new interaction managers
      this.interactionManager = new StandardDocumentManager(this.id, this.$router);
      this.dataManager = new StandardA3DataManager(parseInt(this.id));

      this.loadData();
    }
  },

  data: () => ({
    is_loading: false,
    is_printing: false,
    show_tour: false,
    logo_src: null,

    interactionManager: null,
    dataManager: null,

    a3_right: null,
    overlay: null,

    escape_listener: null,
    show_display_tab: false,
    show_filter_tab: false,
    show_review_tab: false,
    show_delete_overlay: false,

    // Exact mapping for every SBF, KPI and Action
    // such that we do not recalculate things
    focusMap: null,
    hoverFocusMap: null,
    // The options, as specified in the FilterTab,
    // used to calculate the map when this changes
    focusOptions: null,

    hover_timer: null,
    hover_target_element: null,

    print_preview: false,
    print_settings: null,
  }),

  computed: {
    relations() {
      if (this.a3_document == null) { return null; }
      return this.a3_document.meta.relations;
    },
    a3_document() {
      if (!this.dataManager) { return null; }
      return this.dataManager.a3Document;
    },

    a3Focus() {
        if (this.hoverFocusMap != null) {
            return this.hoverFocusMap;
        }
        return this.focusMap;
    },

    review() {
      if (!this.dataManager) { return null; }
      return this.dataManager.review;
    },
    review_id() {
        return this.$route.query['review_id'];
    },

    displayOptions() {
        return this.$store.getters['settings/displayOptions'];
    },
    editable() {
      if( this.$hasFeature('Root')) return true;
      
      if (!this.a3_right) return false;

      return (
          this.a3_right.includes('A3Edit') && this.editmode
      );
    },
    editmode() {
        return (
          !this.print_preview
          && !(this.review
               && this.review.review
               && this.review.review.status === 'Processed')
        )
    },

    a3_header() {
      let meta = this.a3_document.meta;
      if (meta.department != null) {
        return `${meta.department.name} - ${meta.timeframe.name}`;
      }
      return `${meta.organisation.name} - ${meta.timeframe.name}`;
    },

    isFocusActive() {
      if (this.focusMap == null) return false;
      return (this.focusMap.sbf != null) ||
        (this.focusMap.kpi != null) ||
        (this.focusMap.action != null);
    },

    // Check if we are able to delete the A3 document
    is_deleteable() {
        return A3Validation.canDelete(this.a3_document, this.relations);
    },
  },

  mounted() {
    this.show_tour = this.$store.getters['isTourPending'];
    this.$store.commit('setTourPending', false);

    this.interactionManager = new StandardDocumentManager(this.id, this.$router);
    this.dataManager = new StandardA3DataManager(parseInt(this.id));

    if (this.review_id) {
        this.selectReview(this.review_id);
        this.show_review_tab = true;
    }

    if (this.a3_document == null) {
      this.loadData();
    }
  },

  created() {
    this.escape_listener = (ev) => {
      if (ev.key === 'Escape' && this.$route.name != 'a3-root') {
        this.$router.go(-1);
      }
    };
    window.addEventListener('keyup', this.escape_listener)
  },

  destroyed() {
    window.removeEventListener('keyup', this.escape_listener);
  },

  methods: {
    loadLogo() {
        this.$http.get(`a3/${this.id}/logo`)
            .then(res => {
                this.logo_src = res.body;
            });
    },

    resetDisplayOptions() {
        this.$http.get('me/settings').then((res) => {
            let fullSettings = res.body.settings;
            for (let key in this.displayOptions) {
                let setting_key = `.a3_display.defaults.${key}`;
                if (setting_key in fullSettings) {
                    this.displayOptions[key] = fullSettings[setting_key].value > 0;
                }
            }
        })
    },

    selectReview(reviewId) {
        let oldA3 = this.dataManager.a3Document;
        this.dataManager = new ReviewDataManager(
            parseInt(this.id), // a3doc id
            parseInt(reviewId),
            this.$store.getters['auth/person'],
        );
        this.interactionManager.setQueryOption('review_id', reviewId);
        this.interactionManager.backToDocument();

        this.dataManager.a3Document = oldA3;
    },

    toggleReview() {
        if (this.show_review_tab) {
            if (this.dataManager.review) {
                let oldA3 = this.dataManager.a3Document;
                this.dataManager = new StandardA3DataManager(parseInt(this.id));
                this.dataManager.a3Document = oldA3;
            }
            this.interactionManager.setQueryOption('review_id', null);
            this.interactionManager.backToDocument();
            this.show_review_tab = false;
        } else {
            // We don't create a review manager here, because the user
            // needs to select the appropriate review first.
            this.show_review_tab = true;
        }
    },

    loadData() {
      this.is_loading = true;

      this.loadRights();
      this.dataManager
        .loadDocument()
        .then(a3doc => {
          // update style variables
            let style = a3doc.meta.layout.style;
            if (a3doc.meta.layout.has_logo) {
              this.loadLogo();
            }
            document.documentElement.style.setProperty(
                '--a3-style-background-color',
                style.background_color
            );
            document.documentElement.style.setProperty(
                '--a3-style-title-color',
                style.title_color
            );
            document.documentElement.style.setProperty(
                '--a3-style-header-color',
                style.header_color
            );
            document.documentElement.style.setProperty(
                '--a3-style-border-color',
                style.border_color
            );
            document.documentElement.style.setProperty(
                '--a3-style-border-width',
                `${style.border_width}px`
            );

          this.focusMap = focusCalculations.createFocusMap(this.focusOptions, this.a3_document);

          this.is_loading = false;
        }, err => {
            this.$router.replace('/a3');
        });
    },

    loadRights() {
        this.$http.get('a3_rights')
            .then(res => {
                this.a3_right = res.body[this.id];
                this.interactionManager.setA3Features(this.a3_right);
            })
    },

    gotoReport() {
      let report_url = `/report/${this.a3_document.id}`
      if (this.isFocusActive) {
        let filter_slug = filterEncoding.encodeFilterOptions(this.focusOptions);
        report_url += `?filter=${filter_slug}`;
      }
      this.$router.push(report_url);
    },

    deleteA3doc() {
        if (this.child_doc_count > 0) {
            this.show_delete_overlay = false;
            return;
        }
        this.$http.delete(`a3/${this.id}`)
            .then(() => {
                this.$router.push('/a3');
            });
    },

    focusUpdate(focusOptions) {
      this.focusOptions = focusOptions;
      this.focusMap = focusCalculations.createFocusMap(this.focusOptions, this.a3_document);
    },

    setFocusElement(ev) {
      if (ev.type === 'sbf' && this.focusOptions.sbf) {
        let entryIndex = this.focusOptions.sbf.findIndex(x => x.id == ev.sbf.id);
        if (entryIndex == -1) {
          this.focusOptions.sbf.push(ev.sbf);
        }
        if (entryIndex >= 0) this.focusOptions.sbf.splice(entryIndex, 1);
      }

      if (ev.type === 'kpi' && this.focusOptions.kpi) {
        let entryIndex = this.focusOptions.kpi.findIndex(x => x.id == ev.kpi.id);
        if (entryIndex == -1) {
          this.focusOptions.kpi.push(ev.kpi);
        }
        if (entryIndex >= 0) this.focusOptions.kpi.splice(entryIndex, 1);
      }

      if (ev.type === 'action' && this.focusOptions.action) {
        let entryIndex = this.focusOptions.action.findIndex(
            x => x.id == ev.action.id
        );
        if (entryIndex == -1) {
          this.focusOptions.action.push(ev.action);
        }
        if (entryIndex >= 0) this.focusOptions.action.splice(entryIndex, 1);
      }

      this.focusMap = focusCalculations.createFocusMap(this.focusOptions, this.a3_document);
    },

    elementMouseOver(ev) {
        if (!this.displayOptions.hover_focus) {
            return;
        }

        this.hover_target_element = ev;
        clearTimeout(this.hover_timer);
        this.hover_timer = setTimeout(() => {
            let hoverFocusOptions = {
                kpi_norm: {},
                kpi_steering: {},
                kpi_type: {},
                action_status: {},
                action_tasks: {},
                sbf: [],
                kpi: [],
            };
            if (ev.type == "sbf") {
                hoverFocusOptions.sbf = [ev.sbf];
            }
            if (ev.type == "kpi") {
                hoverFocusOptions.kpi = [ev.kpi];
            }
            if (ev.type == "action") {
                hoverFocusOptions.action = [ev.action];
            }
            this.hoverFocusMap = focusCalculations.createFocusMap(
                hoverFocusOptions,
                this.a3_document
            );
        }, 750);
    },
    elementMouseLeave(ev) {
        clearTimeout(this.hover_timer);
        this.hoverFocusMap = null;
    },

    closeOverlay() {
      this.overlay = null;
    },

    printPDF(settings) {
      this.is_printing = true;
      let a3doc = document.getElementById('a3-document-container');
      let style = this.a3_document.meta.layout.style;
      let w = 1920 / (settings.scale / 100);
      let options = {
        backgroundColor: style.background_color,
        width: w,
        height: w * 21 / 29.7,
        scale: 2,
        x: 0,
        y: -20,
        scrollX: 0,
        scrollY: 0,
        windowWidth: w,
        windowHeight: w * 21 / 29.7,
      };
      html2canvas(a3doc, options).then((canvas_inner) => {
        let front = document.getElementById('a3-print-frontpage');
        let w = 1920;
        html2canvas(front,
          Object.assign(options, {
            'backgroundColor': settings.front_background,
            width: w,
            height: w * 21 / 29.7,
            x: 0,
            y: 0, //
            scrollX: 0,
            scrollY: 0, // front.offsetTop,
            windowWidth: w,
            windowHeight: w * 21 / 29.7,
          })
        )
          .then((canvas_front) => {

            let t = this.a3_document.meta.timeframe.name;
            let o = this.a3_document.meta.organisation.name;
            let d = this.a3_document.meta.department;
            d = d != null ? ` - ${d.name}` : '';

            let documentTitle = `Jaarplan ${t} - ${o}${d}`;

            let doc = new jsPDF({
              'orientation': 'landscape',
              unit: 'cm',
            });
            if (settings.includeFrontPage) {
              doc.addImage(canvas_front.toDataURL('image/jpeg'), 0, 0, 29.7, 21);
              doc.addPage();
            }
            doc.addImage(canvas_inner.toDataURL('image/jpeg'), 0, 0, 29.7, 21);
            doc.save(`${documentTitle}.pdf`);
            this.is_printing = false;
        })
      });
    },
  },
}
</script>

<style lang="scss">

.a3-header-row > .a3-element {
    text-align: left;
}


.a3-element {
    font-size: 14px;
    vertical-align: top;
    padding: 10px;
    border-radius: 5px;
    background-color: hsl(188, 10%, 98%);
    color: black;

    position: relative;
    overflow: hidden;

    border-style: solid;
    border-width: var(--a3-style-border-width);
    border-color: var(--a3-style-border-color);

    transition: border-width .5s ease-in-out, border-color .5s ease-in-out;

    h2 {
        user-select: none;
        margin-top: 5px;
        margin-bottom: 0px;
        color: var(--a3-style-header-color);
        font-size: 16px;
    }
    > p, ol, ul {
        margin-top: 5px;
    }
}

#a3-document-container.print-mode  {
  .a3-title, .action-progress-inner {
    transition: none !important;
    animation: none;
  }

  .item-medewerkers,
  .item-bestuurs-financiers,
  .item-klanten-partners,
  .item-maatschappij,
  .item-default {
      transition: none !important;
  }

  .info-box-content {
      display: none;
  }
}

.a3-sheet-container {
  position: relative;
    background-color: var(--a3-style-background-color);
    transition: background-color .5s ease-in-out;

    /* 51 px is menu height + 1px boredr */
    min-height: calc(100vh - 51px);
    color: white;
    margin-top: 0;
    margin: -30px;

    .a3-title {
        color: var(--a3-style-title-color);
        transition: color .5s ease-in-out;
        padding-left: 30px;
        margin-top: 0;
        margin-bottom: 0;
        display: inline-block;
        vertical-align: bottom;
    }

    .a3-sub-title {
      color: var(--a3-style-title-color);
      transition: color .5s ease-in-out;
      margin-top: 5px;
      padding-left: 30px;
      font-style: italic;

      .name-edit {
        cursor: pointer;
        width: 20%;
        height: 1em;
        display: inline-block;

        &:hover {
          text-decoration: underline;
        }
      }
    }
}
.a3-sheet-title {
    position: relative;
    height: 50px;
}

.a3-sheet-header.review-active, .a3-sheet-body.review-active {
    width: 80%;
}

.a3-header-row {
    box-sizing: border-box;
    margin: 10px;
    display: grid;
    transition: grid-template-columns .5s ease-out;

    @media screen and (min-width: 873px)  {
        grid-column-gap: 10px;
    }

    @media screen and (max-width: 873px)  {
        grid-template-columns: repeat(1, auto) !important;
        grid-row-gap: 10px;
    }
}

.a3-sheet-header {
    width: calc(100% - 20px);
    box-sizing: border-box;
    min-height: 200px;
}

.a3-sheet-body {
    box-sizing: border-box;
    width: calc(100% - 20px);
    min-height: 450px;
}
.a3-body {
    margin: 10px;
    display: grid;

    transition: grid-template-columns .5s ease-out,
        grid-template-rows .5s ease-out
    ;

    @media screen and (min-width: 873px)  {
        grid-column-gap: 10px;
        grid-template-columns: repeat(5, auto);
        grid-template-rows: repeat(3, auto);
    }

    @media screen and (max-width: 873px)  {
        grid-template-columns: repeat(1, auto) !important;
        grid-template-rows: auto;
        grid-template-areas: "l" "sp" "me" "mr" "mp" "e" "cp" "s" "bf";
        grid-row-gap: 10px;
    }


    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.item-medewerkers,
.item-bestuurs-financiers,
.item-klanten-partners,
.item-maatschappij,
.item-default {
    user-select: none;
    transition: all .2s ease-out;
    cursor: pointer;
    border-radius: 2px;
    padding: 2px 5px;
    margin: 0px -5px;

    color: var(--main-item-color);
    &:hover, &.selected {
        color: white;
        background-color: var(--main-item-color);
    }
}


.hide-full-height {
}

#a3-print-container {
  transition: transform .5s ease-out, padding .5s ease-out;
  transform-origin: top left;

  &.print-preview {
    transform: scale(0.5);
    padding: 20px;
    border: 2px white solid;
  }
}


$warning-orange: #EA7317;

.a3-sheet-warnings {
  text-align: right;
  margin-top: -20px;
  margin-right: 20px;
  display: inline-block;
  float: right;
  right: 0;

  color: $warning-orange;
  i {
    cursor: pointer;
    font-size: 30px;
    transition: transform .15s ease-out;
  }
  position: relative;

  &:hover {
    i {
      transform: scale(1.3);
      transform-origin: center center;
    }
  }

  .warnings-inner {
    font-size: 16px;
    width: 450px;
    padding: 15px;
    display: block;
    position: absolute;
    right: 0;
    top: 40px;
    background-color: $warning-orange;
    color: white;
    z-index: 100;
    text-align: left;
    border-radius: 10px;

    transition: opacity .2s ease-out;

    p {
      margin-top: 10px;
    }

    li:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

</style>
