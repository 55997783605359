
import LayoutCreate from "@/views/layout/LayoutCreate.vue";
import LayoutEdit from "@/views/layout/LayoutEdit.vue";
import LayoutList from "@/views/layout/LayoutList.vue";

import Organisation from "@/views/Organisation.vue";
import NewOrganisation from "@/views/admin/NewOrganisation.vue";
import ListOrganisation from "@/views/organisation/List.vue";
import ShowOrganisation from "@/views/organisation/ShowOrganisation.vue";
import AddSSO from "@/components/overlays/AddSSO.vue";
import ManageA3NewLicense from "@/views/manage/NewLicense.vue";
import ManageA3EditLicense from "@/views/manage/EditLicense.vue";
import OrganisationPeople from "@/views/OrganisationPeople.vue";
import OrganisationPeopleImport from "@/views/OrganisationPeopleImport.vue";
import OrganisationUserTypes from "@/views/OrganisationUserTypes.vue";
import OrganisationUserTypeCreate from "@/views/admin/OrganisationUserTypeCreate.vue";
import OrganisationUserTypeDelete from "@/views/admin/OrganisationUserTypeDelete.vue";
import OrganisationUserTypeEdit from "@/views/admin/OrganisationUserTypeEdit.vue";
import OrganisationUserTypeAssignTo from "@/views/admin/OrganisationUserTypeAssignTo.vue";
import OrganisationTeams from "@/views/OrganisationTeams.vue";

import DepartmentList from "@/views/admin/DepartmentList.vue";
import DepartmentEdit from "@/views/organisation/DepartmentEdit.vue";
import OrganisationCreatePerson from "@/views/organisation/PersonCreate.vue";

import OrganisationAddAdvisor from "@/views/organisation/AddAdvisor.vue";
import OrganisationListAdvisor from "@/views/organisation/AdvisorList.vue";
import OrganisationPerson from "@/views/admin/OrganisationPerson.vue";
import OrganisationPersonA3Rights from "@/views/admin/OrganisationPersonA3Rights.vue";
import OrganisationPersonEdit from "@/views/organisation/PersonEdit.vue";

import AccountCreate from "@/views/admin/AccountCreate.vue";

export default [
  { path: "/organisation/layout", component: LayoutList },
  { path: "/organisation/layout/create", component: LayoutCreate },
  { path: "/organisation/layout/edit/:layout_id", component: LayoutEdit, props: true },
  {
    path: "/organisation",
    name: "my-organisation",
    component: Organisation,
  },
  {
    path: "/organisation/new",
    name: "new-organisation",
    component: NewOrganisation,
    meta: {
      requiredFeatures: [ "Root" ],
    }
  },
  {
    path: "/organisations",
    name: "list-organisations",
    component: ListOrganisation,
    meta: {
      requiredFeatures: [ "Root" ],
    }
  },
  {
    path: "/organisations/:organisation_id",
    name: "show-organisations",
    component: ShowOrganisation,
    props: true,
    meta: {
      requiredFeatures: [ "Root" ],
    }
  },
  {
    path: "/organisations/:organisation_id/sso",
    name: "create-SSO",
    component: AddSSO,
    props:true,
    meta: {
      requiredFeatures: [ "Root" ],
    }
  },
  {
    path: "/organisations/:organisation_id/license/new",
    name: "a3-manage-license-new",
    component: ManageA3NewLicense,
    props: true,
    meta: {
      requiredFeatures: [ "Root" ],
    }
  },
  {
    path: "/organisations/:organisation_id/license/:license_id",
    name: "a3-manage-license-edit",
    component: ManageA3EditLicense,
    props: true,
    meta: {
      requiredFeatures: [ "Root" ],
    }
  },
  {
    path: "/organisation/departments",
    name: "my-organisation-departments",
    component: DepartmentList,
  },
  {
    path: "/organisation/advisor/add",
    name: "add-organisation-advisor",
    component: OrganisationAddAdvisor,
  },
  {
    path: "/organisation/advisor",
    name: "list-organisation-advisor",
    component: OrganisationListAdvisor,
  },
  {
    path: "/organisation/people",
    name: "my-organisation-people",
    component: OrganisationPeople,
  },
  {
    path: "/organisation/user_types",
    name: "my-organisation-user-types",
    component: OrganisationUserTypes,
  },
  {
    path: "/organisation/user_types/create",
    name: "my-organisation-user-type-create",
    component: OrganisationUserTypeCreate,
  },
  {
      path: "/organisation/user_types/:user_type_id/delete",
    name: "my-organisation-user-type-delete",
    component: OrganisationUserTypeDelete,
      props: true,
  },
  {
    path: "/organisation/user_types/:user_type_id",
    name: "my-organisation-user-type-view",
    component: OrganisationUserTypeEdit,
    props: true,
  },
  {
    path: "/organisation/user_types/:user_type_id/assign_to",
    name: "my-organisation-user-types-assign-to",
    component: OrganisationUserTypeAssignTo,
    props: true,
  },
  {
    path: "/organisation/people/create",
    name: "my-organisation-people-create",
    component: OrganisationCreatePerson,
  },
  {
    path: "/organisation/people/import",
    name: "my-organisation-people-import",
    component: OrganisationPeopleImport,
  },
  {
    path: "/organisation/people/:person_id",
    name: "organisation-view-person",
    component: OrganisationPerson,
    props: true,
  },
  {
      path: "/organisation/accounts/:account_id/a3_rights",
      name: "organisation-view-person",
      component: OrganisationPersonA3Rights,
      props: true,
  },
  {
    path: "/organisation/people/:person_id/edit",
    name: "organisation-edit-person",
    component: OrganisationPersonEdit,
    props: true,
  },
  {
    path: "/organisation/department/:department_id/edit",
    name: "organisation-edit-department",
    component: DepartmentEdit,
    props: true,
  },
  {
    path: "/organisation/people/:person_id/create_account",
    name: "organisation-create-person-account",
    component: AccountCreate,
    props: true,
  },
  {
    path: "/organisation/teams",
    name: "my-organisation-teams",
    component: OrganisationTeams,
  },
];
