<template>
      <div class="element-form">
        <template v-if="sbf.sbf_ref">
          <p v-html="$t('ui.sbf.push_edit_comment')"></p>
        </template>
        <div class="form-row">
          <div class="form-field">
              <label>{{ $t('ui.general.name') }} </label>
            <input data-cy="input-name" v-model="sbf.name" class="a3-input"
                    id="sbf-name-input"
                    autocomplete="off"
              :disabled="sbf.sbf_ref != null"
              />

            <FormError :error="errors.name" />
          </div>
        </div>

        <div class="form-row"
            >
          <div class="form-field"
             v-if="a3_layout.structure.sbf_result_mapping == 'one_to_one'"
              >
              <label>{{ $t('definitions.terms.result_area') }} </label>
            <select data-cy="input-result-area"
                    id="sbf-result-area-input"
                    v-model="sbf.result_area" class="a3-input"
                :disabled="sbf.sbf_ref != null"
              >
              <option v-for="result_area in result_area_options" :value="result_area.key" >
                {{ result_area.name ? result_area.name : $t(`definitions.result_area.${result_area.key}`) }}
              </option>
            </select>

            <FormError :error="errors.result_area" />
          </div>

        <div
             v-if="sbf && sbf.sbf_ref == null
                   && a3_layout.structure.sbf_result_mapping == 'one_to_many'
                    "
            >
            <div class="form-field"
                >
              <label>{{ $t('ui.sbf.color') }} </label>
              <div style="display: inline-block">
                <ColorPicker v-model="sbf.color" />
              </div>
              <FormError :error="errors.color" />
            </div>
        </div>
        </div>

        <div class="form-field">
            <label>{{ $t('ui.sbf.chances_risks') }} </label>
          <div class="form-input">
            <RichTextEditor v-model="sbf.comment_chances_risks"
                            data-cy="input-comment-chances-risks"
            />
          </div>
        </div>

        <div class="form-field" v-if="show_deploy">
            <label class="checkbox-label">{{ $t('ui.a3_document.push') }}?
            <InfoBox :tkey="'form_deployment'" :html="true"
                :params="[ $t('definitions.short.sbf') ]" />
          </label>
          <CheckBox v-model="sbf.push_default" />
          <div class="input-pills" v-if="sbf.push_default">
            <div
              v-for="c in child_departments"
              v-if="c.a3_ref != null"
              tabindex="0"
              @click="toggleA3Doc(c.a3_ref.a3doc_id)"
              @keyup.enter="toggleA3Doc(c.a3_ref.a3doc_id)"
              :key="c.department.id"
              class="push-pill"
              :class="{'inactive': push_a3docs.find((x) => x == c.a3_ref.a3doc_id) == null }"
              >
              {{ c.department.name }}
            </div>
            <div v-if="child_departments.filter(x => x.a3_ref != null).length == 0">
                <em>{{ $t('ui.a3_document.no_subplans') }} </em>
            </div>
          </div>
        </div>

        <div class="form-buttons">
            <button @click="submit" class="a3-button action"
                                    id="sbf-submit-button"
              data-cy="submit">
                <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
            </button>
            <button @click="$emit('cancel')" class="a3-button cancel" data-cy="cancel">
                <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
            </button>
        </div>
      </div>
</template>

<script>

import ColorPicker from '@/components/forms/ColorPicker.vue';
import RichTextEditor from '@/components/forms/RichTextEditor.vue';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  mixins: [WithDocumentManager],

  components: {
    ColorPicker,
    RichTextEditor,
    WithDocumentManager,
  },

  props: {
    sbf: {
      type: Object,
      required: true,
    },
    push_a3docs: {
      type: Array,
      required: true,
    },
    show_deploy: {
      type: Boolean,
      required: false,
      default: true,
    },
    child_departments: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    errors: {
      name: [],
      result_area: [],
    },
  }),

  mounted() {
    document.querySelector('#sbf-name-input').focus();
  },

  methods: {
    validate() {
      if (this.sbf.name == null || this.sbf.name.length == 0) {
        this.errors.name.push("may_not_be_null");
      }
      if (this.sbf.result_area == null) {
        this.errors.result_area.push("may_not_be_null");
      }

      return Object.values(this.errors)
        .flat()
        .length == 0;
    },

    submit() {
      // reset the errors
      for (let x of Object.keys(this.errors)) {
        this.errors[x] = [];
      }

      if (this.validate()) {
        this.$emit('submit');
      }
    },

    toggleA3Doc(a3doc_id) {
      let in_array = this.push_a3docs.findIndex(x => x == a3doc_id);
      if (in_array == -1) {
        this.push_a3docs.push(a3doc_id);
      } else {
        this.push_a3docs.splice(in_array, 1);
      }
    }
  },

  computed: {
    result_area_options() {
        return this.dataManager.getResultAreas();
    },
  },
}
</script>
