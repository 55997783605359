<style>
.ql-color .ql-picker-options [data-value=color-picker]:before {
  content: 'Pick color'
}

.ql-color .ql-picker-options [data-value=color-picker] {
  background: none !important;
  width: 100% !important;
  height: 25px !important;
  text-align: center;
  color: blue;
  text-decoration: underline;
}
</style>
<template>
  <div style="margin: 0; width: 100%;">
    <div :id="id">
    </div>
  </div>
</template>


<script>

import Quill from 'quill';

export default {
  props: {
    value: {
      required: true,
      validator: () => true,
    },
    editor_mode: {
      type: String,
      required: false,
      default: 'standard',
    },
    setFocus: {
      type: Boolean,
      required: false,
      default: true,
    }
  },

  data: () => ({
    id: null,
    editor: null,
  }),

  mounted() {
    this.id = `quill-editor-${this._uid}`;

    let modules;
    if (this.editor_mode === "minimal"){
      modules= {
        toolbar: null,
      };
    } else {
        modules =  {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [ {'list': 'ordered'}, {'list': 'bullet' }],
            [ {'color': [
              '#000000', '#444444', '#999999', '#ffffff',
              '#007385', '#f06e00', '#fdcf00',
              '#61c107', '#0174b7', '#ea5ea5', '#dd291a', 'color-picker'
            ] }],
          ],
        };
    }

    this.$nextTick(() => {
      this.editor = new Quill(`#${this.id}`, {
        theme: 'snow',
        modules: modules,
      });
      var quill = this.editor;
      var toolbar = this.editor.getModule('toolbar');
      toolbar.addHandler('color', function(value){
          if (value === 'color-picker') {
            var picker = document.getElementById('color-picker');
            if (!picker) {
              picker = document.createElement('input');
              picker.id = 'color-picker';
              picker.type = 'color';
              picker.style.display = 'none';
              picker.value = '#FF0000';
              document.body.appendChild(picker);

              picker.addEventListener('change', function() {
                quill.format('color', picker.value);
              }, false);
            }
            picker.click();
        } else {
          quill.format('color', value);
        }
      });
      if (this.setFocus) { this.editor.focus(); }

      this.editor.on('text-change', () => {
        if (this.editor.getText() == '\n') { this.$emit('input', null); }
        else {
          this.$emit('input', JSON.stringify(this.editor.getContents()));
        }
      });

      try {
        let x = JSON.parse(this.value);
        this.editor.setContents(x);
      } catch (e) {
        this.editor.setText(this.value);
      }
    });
  },

  methods: {
  }
}
</script>




