<template>
  <input
        v-model="input_date"
        :id="`date-input-${uid}`"
        class="a3-input"
        :class="{'error': error}"
    />
</template>

<script>


import flatpickr from 'flatpickr';
import moment from 'moment';

import { v4 as uuidv4 } from 'uuid';

/*
 * The application uses Y-m-d format for dates everywhere,
 * while the user might need to input dates in their own
 * format, most particularly d-m-Y.
 *
 * Therefore, this component ensures the user can rightly
 * import the dates in the right format. Every date
 * input in the application should use this class
 * using the v-model directive
 */

export default {

  props: {
    value: { type: String, }
  },

  data: () => ({
    uid: null,
    error: false,
    input_date: null, // Date that the user modifies
    pickr_instance: null,
  }),


  watch: {
    input_date(val) {
      this.error = false;
      if (val == null || val == "") {
        this.$emit('input', null);
        return;
      }
      if (val.length != 10) {
        this.$emit('input', null);
        this.error = true;
        return;
      }
      let d = moment(val, 'DD-MM-YYYY');

      if (d.isValid()) {
        this.$emit('input', d.format('YYYY-MM-DD'));
        this.pickr_instance.setDate(d.format('DD-MM-YYYY'));
      } else {
        this.$emit('input', null);
        this.error = true;
      }
    },
  },


  beforeCreate() {
  },

  mounted() {
    this.uid = uuidv4();

    if (this.value != null) {
      let d = moment(this.value, 'YYYY-MM-DD');
      this.input_date = d.format('DD-MM-YYYY');
    }

    this.$nextTick(() => {
      this.pickr_instance = flatpickr(`#date-input-${this.uid}`, {
        defaultDate: this.input_date,
        allowInput: true,
        dateFormat: "d-m-Y",
        onChange: (selectedDate) => {
          if (selectedDate == "") {
            this.$nextTick(() => this.input_date = null);
          }
        },
      });
    });
  },

  methods: {
    inputOnBlur() {
      // let value = this.input_date
    },
  },
}
</script>
