
import Vue from "vue";

export interface A3DocumentInteractionManager {

  backToDocument();

  createSbf();
  selectSbf(sbfId: number);
  editSbf(sbfId: number);

  createKpi();
  selectKpi(kpiId: number);
  editKpi(kpiId: number);
}


export class StandardDocumentManager implements A3DocumentInteractionManager {
  public a3DocId: number;
  public router: any;

  public a3Features: any;
  public contextmenu: object | null;

  private queryOptions: object;
  private redirect: string | null;

  constructor(a3DocId: number, router: any) {
    this.a3DocId = a3DocId;
    this.router = router;

    this.contextmenu = null;

    this.redirect = null;
    const route = router.currentRoute;
    if (route && route.query.redirect) {
      this.redirect = route.query.redirect;
    }

    this.queryOptions = {};
  }

  public setA3Features(a3Features: any) {
    this.a3Features = a3Features;
  }
  public hasA3Feature(key: string) {
    if (!this.a3Features) { return false; }
    return this.a3Features.includes(key);
  }

  public setQueryOption(key: string, value: string) {
      this.queryOptions[key] = value;
  }

  public backToDocument() {
    if (this.redirect != null) {
      if (this.redirect === "report/action_table") {
        this.router.push(`/report/action_table/${this.a3DocId}`);
        return;
      }
    }
    this.router.push(`/a3/${this.a3DocId}${this.q()}`);
  }

  public setContextMenu(event: object, type: string, params: object) {
    // type should be sbf, kpi or action
    this.contextmenu = {
      event,
      type,
      params
    };
  }
  public clearContextMenu() {
    this.contextmenu = null;
  }


  public selectSbf(sbfId) {
    this.router.push( `/a3/${this.a3DocId}/sbf/${sbfId}${this.q()}` );
  }
  public createSbf() {
    this.router.push( `/a3/${this.a3DocId}/sbf/create${this.q()}` );
  }
  public editSbf(sbfId) {
    this.router.push( `/a3/${this.a3DocId}/sbf/${sbfId}/edit${this.q()}` );
  }
  public deleteSbf(sbfId) {
    this.router.push( `/a3/${this.a3DocId}/sbf/${sbfId}/delete${this.q()}` );
  }
  public filloutSbf(sbfId) {
    this.router.push( `/a3/${this.a3DocId}/sbf_fillout/${sbfId}${this.q()}` );
  }

  public selectKpi(kpiId) {
    this.router.push( `/a3/${this.a3DocId}/kpi/${kpiId}${this.q()}` );
  }
  public createKpi( resultArea = null, sbfId?: string) {
    let opt = {};
    if (sbfId) { opt['sbf_id'] = sbfId; }
    if (resultArea) { opt['result_area'] = resultArea; }

    const url = `/a3/${this.a3DocId}/kpi/create${this.q(opt)}`;
    this.router.push(url);
  }
  public createKpiForSbf(sbfId?: string) {
    let url = `/a3/${this.a3DocId}/kpi/create`;
    if (sbfId) {
      url += `?sbf_id=${sbfId}`;
    }
    this.router.push(url);
  }
  public editKpi(kpiId) {
    this.router.push( `/a3/${this.a3DocId}/kpi/${kpiId}/edit${this.q()}` );
  }
  public deleteKpi(kpiId) {
    this.router.push( `/a3/${this.a3DocId}/kpi/${kpiId}/delete${this.q()}` );
  }

  public createKpiStory(kpiId) {
    this.router.push( `/a3/${this.a3DocId}/kpi/${kpiId}/stories/create`);
  }
  public updateKpiStory(kpiId, storyId) {
    this.router.push( `/a3/${this.a3DocId}/kpi/${kpiId}/stories/${storyId}/edit`);
  }

  public createKpiMeasurement(kpiId) {
    this.router.push( `/a3/${this.a3DocId}/kpi/${kpiId}/measurements`);
  }

  public selectAction(actionId) {
    this.router.push( `/a3/${this.a3DocId}/action/${actionId}${this.q()}` );
  }
  public createAction(orgArea = null, kpiId?: string, sbfId?: string) {
    let opt = {};
    if (kpiId) { opt['kpi_id'] = kpiId; }
    if (sbfId) { opt['sbf_id'] = sbfId; }
    if (orgArea) { opt['org_area'] = orgArea; }

    this.router.push( `/a3/${this.a3DocId}/action/create${this.q(opt)}` );
  }
  public createActionForSbf(sbfId?: string) {
    let url = `/a3/${this.a3DocId}/action/create`;
    if (sbfId) {
      url += `?sbf_id=${sbfId}`;
    }
    this.router.push(url);
  }
  public createActionForKpi(kpiId?: string) {
    let opt = {};
    if (kpiId) { opt['kpi_id'] = kpiId; }

    this.router.push(
        `/a3/${this.a3DocId}/action/create${this.q(opt)}`
    )
  }
  public editAction(actionId) {
    this.router.push( `/a3/${this.a3DocId}/action/${actionId}/edit${this.q()}` );
  }
  public deleteAction(actionId) {
    this.router.push( `/a3/${this.a3DocId}/action/${actionId}/delete${this.q()}` );
  }
  public updateAction(actionId) {
    this.router.push( `/a3/${this.a3DocId}/action/${actionId}${this.q()}` );
  }
  public changeActionOrder(orgArea) {
    this.router.push(`/a3/${this.a3DocId}/order/${orgArea}${this.q()}`);
  }

  public editTask(actionId, taskId) {
      this.router.push(
          `/a3/${this.a3DocId}/action/${actionId}/task/${taskId}`
      );
  }

  public editBudget(actionId) {
      this.router.push(
          `/a3/${this.a3DocId}/action/${actionId}/budget`
      )
  }

  public copyElements(elements) {
      elements.a3doc_id = this.a3DocId;
      this.router.push(
          `/a3/manage/copy${this.q(elements)}`,
      );
  }

  // Render additional query parameters. Shortened name,
  // to keep the above functions a little bit more clean
  private q(additional: object | null = null): string {
      const opt = Object.assign({}, this.queryOptions, additional);
      if (Object.keys(opt).length === 0) { return ""; }

      const query = Object.entries(opt)
        .filter(([key, value]) => value != null)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      return `?${query}`;
  }
}
