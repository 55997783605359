<template>
  <WithOrganisationMenuContainer>
    <div style="max-width: 960px; width: 100%; margin: 0 auto;">
      <h1>{{ organisation.name }} : {{ $t('ui.nav.layouts') }} </h1>

      <LayoutPreviewBox v-for="layout in layouts"
                        :layout="layout"
                        :key="layout.id"
                        :button="true"
                        @click="$router.push(`/organisation/layout/edit/${layout.id}`)"
      />

      <table class="a3-table" style="display: none;">
        <thead>
          <tr>
            <th>{{ $t('ui.general.name') }} </th>
            <th>{{ $t('ui.general.created_at') }} </th>
            <th>{{ $t('ui.layout.organisation_specific') }} </th>
            <th>{{ $t('ui.layout.default') }} </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="layout in layouts" :key="layout.id"
              @click="$router.push(`/organisation/layout/edit/${layout.id}`)"
            >
            <td> {{ layout.name }} </td>
            <td> {{ layout.created }} </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <template v-if="$hasFeature('A3Layouts')">
        <div style="text-align: right">
          <button class="a3-button action"
                  v-if="$hasFeature('Root')"
                  @click="$router.push(`/organisation/layout/create?mode=import`)"
            >
            <i class="fas fa-plus"></i> {{ $t('ui.layout.import') }}
          </button>

          <button class="a3-button action"
                  @click="$router.push(`/organisation/layout/create`)"
            >
            <i class="fas fa-plus"></i> {{ $t('ui.general.create') }}
          </button>
        </div>
      </template>
    </div>
  </WithOrganisationMenuContainer>
</template>


<script>

import LayoutPreviewBox from '@/components/layout/LayoutPreviewBox.vue';

export default {

  components: {
    LayoutPreviewBox
  },

  data: () => ({
    layouts: [],
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    }
  },

  mounted() {
    this.loadLayouts();
  },

  methods: {
    loadLayouts() {
      this.$http.get(`layout`)
        .then(res => this.layouts = res.body);
    },
  }
}
</script>
