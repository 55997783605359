<template>
    <div class="a3-overlay-action-group">
        <div class="a3-button"
             data-cy="button-group-more"
             :class="{'active': expanded}"
             @click="expanded = !expanded"
            >
            {{ $t('ui.general.more') }}
            <i class="fas fa-chevron-left"
               :class="{'fa-rotate-90': expanded}"
                ></i>
        </div>

        <div class="overlay-button-group"
             :class="{'show': expanded}"
            >
            <template v-for="b in buttons">
                <div
                     v-if="!b.feature || $hasFeature(b.feature)"
                     :data-cy="`button-group-${b.event}`"
                     :key="b.event"
                     class="a3-button"
                     :class="b.class"
                     @click="$emit(b.event)"
                     >
                    <i class="fas" :class="`fa-${b.icon}`"></i>
                    {{ $t(b.label) }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>


export default {

    data: () => ({
        expanded: false,
    }),

    props: {
        // { label, icon, class, event }
        buttons: {
            type: Array,
            required: true,
        },
    },
}
</script>


<style lang="scss">

.a3-overlay-action-group {
    position: relative;
    display: inline-block;
    border-radius: 5px;
    margin-right: 5px;

    user-select: none;


    > .a3-button {
        border: 2px rgba($a3-green, .3) solid;
        transition: border-color .15s ease-out;
        &:hover {
            border: 2px $a3-green solid;
        }
        color: $a3-green;
        margin-right: 0;
        i {
            transition: transform .15s ease-out;
        }

        &.active {
            background-color: $a3-green;
            color: white;
        }
    }

    .overlay-button-group {
        position: absolute;
        bottom: calc(100% + 5px);
        text-align: right;
        right: 40%;
        width: 130px;

        opacity: 0;
        transform: translateY(10px);

        transition: all .15s ease-out;
        pointer-events: none;

        .a3-button {
            width: 100%;
            border-radius: 0px;
            &:first-child {
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;
            }
            &:last-child {
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
            }
        }

        &.show {
            transform: none;
            opacity: 1;
            pointer-events: all;
        }
    }
}
</style>
