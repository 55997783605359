<template>
  <div>
    <div id="sbf-container" v-if="sbfs">
        <SbfListElement v-for="(sbf, index) in sbfs"
             @focus="$emit('focus', sbf)"
             @select="interactionManager.selectSbf(sbf.id)"
             @mouseover="$emit('el_mouseover', sbf)"
             @mouseleave="$emit('el_mouseleave', sbf)"
             :dataManager="dataManager"
             :interactionManager="interactionManager"
             :inFocus="inFocus(sbf)"
             :editable="editable"
             :displayOptions="displayOptions"
             :key="sbf.id"
              :sbf="sbf"
              :index="index"
              :review="review"
              :relations="relations"
              @comment="dataManager.commentSbf(sbf.id)"
             />
    </div>

    <div class="add-button-container"
         id="add-sbf-button"
      v-if="editable"
      tabindex="0"
      @click="$router.push(`/a3/${a3_document.id}/sbf/create`)"
      @keyup.enter="$router.push(`/a3/${a3_document.id}/sbf/create`)"
      >
      <i class="fas fa-plus"></i> {{ $t('ui.sbf.create') }}
    </div>

    <div class="add-button-container"
      v-if="editable && sbfs.length > 1"
      tabindex="0"
      @click="$router.push(`/a3/${a3_document.id}/order/sbf`)"
      @keyup.enter="$router.push(`/a3/${a3_document.id}/order/sbf`)"
      >
      <i class="fas fa-sort"></i> {{ $t('ui.general.change_order') }}
    </div>
  </div>
</template>


<script>

import ResultAreaHelper from '@/mixins/ResultAreaHelper';
import A3RelationHelper from '@/mixins/A3RelationHelper';
import WithDocumentManager from '@/mixins/WithDocumentManager';
import SbfListElement from '@/components/elements/SbfListElement';

export default {

  mixins: [ResultAreaHelper,  A3RelationHelper, WithDocumentManager],
  components: { SbfListElement },

  props: {
    displayOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    focusMap: {
      type: Object,
      required: false,
      default: null,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  computed: {
    sbfs() {
      this.dataManager.a3Document;

      return this.dataManager.getSbfList();
    }
  },

  methods: {
    inFocus(sbf) {
      if (this.focusMap == null) return true;
      if (this.focusMap.sbf == null) return true;
      return this.focusMap.sbf[sbf.id];
    },
  },
};
</script>



<style lang="scss">
@import "@/assets/variables.scss";

.sbf-element.inactive {
  color: $a3-green;
  opacity: .3;
}
</style>
