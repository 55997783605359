
function getTargetMin(kpi) {
    let tmin = kpi.target_min;
    if (kpi.kpi_ref && kpi.kpi_ref.target_min) {
        tmin = kpi.kpi_ref.target_min;
    }
    return tmin;
}

function getTargetMax(kpi) {
    let tmax = kpi.target_max;
    if (kpi.kpi_ref && kpi.kpi_ref.target_max) {
        tmax = kpi.kpi_ref.target_max;
    }
    return tmax;
}

function checkKpiValue(kpi, kpi_measurement) {
  if (kpi_measurement == null) return false;

  let check_value = parseFloat(kpi_measurement.value);
  let tmin = getTargetMin(kpi);
  let tmax = getTargetMax(kpi);
  if (tmin && check_value < parseFloat(tmin)) {
    return false;
  }
  if (tmax && check_value > parseFloat(tmax)) {
    return false;
  }

  return true;
}


export default { checkKpiValue, getTargetMin, getTargetMax }
