
let install = function (Vue, options) {
  Vue.prototype.$hasFeature = function (feature_key) {
    return options.store.getters['auth/hasFeature'](feature_key);
  }

  Vue.prototype.$v2Enabled = function () {
    return options.store.getters['auth/hasFeature']('V2Preview')
        && options.store.getters['settings/hasPreference']('v2_preview') === 1
      ;
  }
}
export default { install };
