<template>
    <div class="element-form"
      style="display: inline-block; width: 1000px; vertical-align: top; padding-left: 15px;">

      <div class="form-row">
        <h3>{{ $t('definitions.terms.direction') }}</h3>
        <div class="form-field">
          <label>{{ $t('definitions.terms.mission') }} </label>
          <input v-model="titles.mission"
                 class="a3-input"
                 id="layout-name-input"
                 data-cy="input-name"
          />
            <FormError :error="errors.titles.mission" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.terms.vision') }} </label>
          <input v-model="titles.vision"
                 class="a3-input"
                 id="layout-vision-input"
          />
            <FormError :error="errors.titles.vision" />
        </div>

        <div class="form-field">
          <label>{{ $tc('definitions.terms.sbf') }} </label>
          <input v-model="titles.sbf"
                 class="a3-input"
                 id="layout-name-input"
                 data-cy="input-name"
          />
            <FormError :error="errors.titles.sbf" />
        </div>
      </div>

      <div class="form-row"
           v-if="!custom_areas"
        >
        <h3>{{ $t('definitions.terms.result_area') }}</h3>
        <div class="form-field">
          <label>{{ $t('definitions.result_area.customers_and_partners') }} </label>
          <input v-model="titles.customers_and_partners"
                 class="a3-input"
          />
            <FormError :error="errors.titles.employees" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.result_area.employees') }} </label>
          <input v-model="titles.employees"
                 class="a3-input"
          />
            <FormError :error="errors.titles.employees" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.result_area.society') }} </label>
          <input v-model="titles.society"
                 class="a3-input"
          />
            <FormError :error="errors.titles.society" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.result_area.board_and_financers') }} </label>
          <input v-model="titles.board_and_financers"
                 class="a3-input"
          />
            <FormError :error="errors.titles.board_and_financers" />
        </div>
      </div>

      <div class="form-row"
           v-if="!custom_areas"
        >
        <h3>{{ $t('definitions.terms.org_area') }}</h3>
        <div class="form-field">
          <label>{{ $t('definitions.org_area.leadership') }} </label>
          <input v-model="titles.leadership"
                 class="a3-input"
          />
            <FormError :error="errors.titles.leadership" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.org_area.strategy_and_policy') }} </label>
          <input v-model="titles.strategy_and_policy"
                 class="a3-input"
          />
            <FormError :error="errors.titles.leadership" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.org_area.management_employees') }} </label>
          <input v-model="titles.management_employees"
                 class="a3-input"
          />
            <FormError :error="errors.titles.management_employees" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.org_area.management_processes') }} </label>
          <input v-model="titles.management_processes"
                 class="a3-input"
          />
            <FormError :error="errors.titles.management_processes" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.org_area.management_resources') }} </label>
          <input v-model="titles.management_resources"
                 class="a3-input"
          />
            <FormError :error="errors.titles.management_resources" />
        </div>
      </div>

      <div class="form-row" v-if="custom_areas">
        <div class="form-field">
          <label></label>
          <div class="form-annotation-div">
            {{ $t('ui.layout.change_titles_in_structure') }}
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  props: {
    titles: {
      type: Object,
      required: true,
    },
    layout: {
        type: Object,
        required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },


  computed: {
    custom_areas() {
      return this.layout.structure.custom_areas;
    }
  },
}
</script>

<style lang="scss">

.form-row .form-annotation-div {
  display: inline-block;
  width: 50%;
  padding-top: 5px;
  opacity: .7;
  color: $a3-green;
}
</style>
