
import Vue from "vue";

import { A3DataManager, StandardA3DataManager } from "./data";

export class ReviewDataManager implements A3DataManager {

  public a3DocId: number;
  public a3Document: any;
  public review: any;
  public reviewId: number;

  private $http: any;
  private reviewer: any;

  constructor(a3DocId: number, reviewId: number, reviewer: any) {
    this.a3DocId = a3DocId;
    this.reviewId = reviewId;
    this.$http = (Vue as any).http;
    this.a3Document = null;
    this.reviewer = reviewer;

    this.review = {
      elements: [],
    };
    this.loadReview();
  }

  public getCapability(cap: string): boolean {
    const capabilities = {
      mission: true,
      vision: true,
      sbf: {
        delete: true,
        deploy: false,
        edit: true,
        fillout: false,
        view: true,
      },
      kpi: {
        view: true,
        edit: true,
        delete: true,
        deploy: false,
        measurements: {
            create: false,
            delete: false,
            view: true,
        },
        links: {
            create: false,
            edit: false,
        },
      },
      action: {
        view: true,
        edit: true,
        delete: true,
        deploy: false,
        status: {
            view: true,
            create: false,
            delete: false,
        },
        task: {
            create: false,
            delete: false,
        },
        budget: {
            edit: false,
            view: false,
        },
        links: {
            create: false,
            edit: false,
        },
      },
    };

    let c = capabilities;
    for (const i of cap.split(".")) {
        if (!c[i]) { return false; }
        c = c[i];
    }
    return c as any;
  }

  public getPerson(personId: number): Promise<object> {
      return this.$http.get(`organisation/people/${personId}`)
        .then((res) => {
          return res.body;
        });
  }

  public loadReview(): Promise<object> {
    return this.$http.get(`a3/${this.a3DocId}/review/${this.reviewId}`)
    .then((res) => {
      this.review = res.body;
      this.review.elements = res.body.elements
        .filter((x) => x.status === "New");

      this.review.elements.sort((a, b) => {
        return this.elementSortScore(a) - this.elementSortScore(b);
      });
    });
  }

  public loadDocument(): Promise<object> {
    return this.$http.get(`a3/${this.a3DocId}`)
      .then((res) => {
        this.a3Document = res.body;
        return this.$http.get(`a3/${this.a3DocId}/relations`);
      }).then((res) => {
        Vue.set(this.a3Document.meta, "relations", res.body);
        return this.a3Document;
      });
  }


  public getResultAreas(): object[] {
    return this.a3Document.meta.layout.structure.result_areas;
  }
  public getResultArea(key: string): object {
    const ra = (this.getResultAreas() as any)
        .find((area) => area.key === key);
    if (ra == null) {
      return { color: "#444", key, name: "??" };
    }
    return ra;
  }
  public getResultAreaColors(): object {
    if (this.a3Document == null) {
      // return default
      return {};
    }

    const colorMap = {};
    for (const resultArea of this.a3Document.meta.layout.structure.result_areas) {
      colorMap[resultArea.key] = resultArea.color;
    }
    return colorMap;
  }


  public getOrgAreas(): object[] {
    return this.a3Document.meta.layout.structure.org_areas;
  }
  public getOrgArea(key: string): object {
    let org_area = (this.getOrgAreas() as any)
        .find((area) => area.key === key);

    if (org_area == null) {
      return {
          color: "#444",
          key,
          name: "??",
      }
    }
    return org_area;
  }



  public getMission(): string {
    const missionEl = this.review.elements
      .find((el) => el.element_type === "Edit"
          && el.target_type === "Mission");
    if (missionEl) {
      return missionEl.new_value;
    }

    return this.a3Document.mission;
  }
  public saveMission(mission: string): Promise<object> {
    return this.createElement({
        element_type: "Edit",
        new_value: mission,
        target_type: "Mission",
    });
  }
  public commentMission(): Promise<object> {
    return this.createElement({
        element_type: "Comment",
        new_value: { comment: "" },
        target_type: "Mission",
    });
  }
  public getVision(): string {
    const visionEl = this.review.elements
      .find((el) => el.element_type === "Edit"
          && el.target_type === "Vision");
    if (visionEl) {
      return visionEl.new_value;
    }

    return this.a3Document.vision;
  }
  public saveVision(vision: string): Promise<object> {
    return this.createElement({
      element_type: "Edit",
      new_value: vision,
      target_type: "Vision",
    });
  }
  public commentVision(): Promise<object> {
    return this.createElement({
        element_type: "Comment",
        new_value: { comment: "" },
        target_type: "Vision",
    });
  }

  public getSbfList(): object[] {
    return this.a3Document.sbf
      .concat(this.review.elements
        .filter((el) => {
          return el.element_type === "Create"
              && el.target_type === "Sbf"
              && el.new_value
        })
        .map((el) => el.new_value));
  }
  /*
   * This will return either an SBF from the document,
   * or a reference to the value in a annotation
   */
  public getSbf(sbfId: string): any {
    const intSbfId = parseInt(sbfId, 10);
    const fromDocument =  Object
      .values(this.a3Document.sbf)
      .find((sbf) => (sbf as any).id === intSbfId);
    if (fromDocument) { return fromDocument; }

    const fromAnnotations = this.review.elements
      .filter((el) =>
        el.element_type === "Create" && el.target_type === "Sbf",
      )
      .filter((el) => el.new_value.id === sbfId)[0];
    if (fromAnnotations) {
      return { ... fromAnnotations.new_value, child_refs: [] };
    }

    return null;
  }
  public createSbf(sbf: any, pushTo?: number[]): Promise<object> {
    sbf.id = "newsbf_" + this.generateId();
    return this.createElement({
      element_type: "Create",
      new_value: { ... sbf },
      target_type: "Sbf",
    });
  }
  public saveSbf(sbf: any, pushTo?: number[]): Promise<object> {
    // First, check whether there is an element that
    // has been created, edit that element if
    // it has.
    const createdElement = this.review.elements
      .find((x) => {
        return x.element_type === "Create"
          && x.new_value.id === sbf.id
          && x.target_type === "Sbf";
      });
    if (createdElement) {
      Object.assign(
        createdElement.new_value,
        {...sbf },
      );
      this.updateElement(createdElement);
      return new Promise((res, rej) => res({}));
    }

    // We only hit the code below when it really
    // is an edit to an existing SBF
    const oldSbf = { ... this.getSbf(sbf.id)};
    const newValues = Object.entries(sbf)
      .filter((entry) => {
        const key = entry[0];
        return typeof entry[1] !== "object"
          && entry[1] !== oldSbf[key];
      })
      .reduce((acc, entry) => {
        acc[entry[0]] = entry[1];
        return acc;
      }, {});

    // We don't create a new element when nothing is changed
    if (Object.keys(newValues).length === 0) {
        return new Promise((r) => r({}));
    }
    return this.createElement({
      element_type: "Edit",
      new_value: { ... newValues },
      old_value: { ... oldSbf },
      sbf_id: sbf.id,
      target_type: "Sbf",
    });
  }
  public deleteSbf(sbfId: string): Promise<any> {
    const createdElement = this.review.elements
      .find((x) => {
        return x.element_type === "Create"
          && x.new_value.id === sbfId
          && x.target_type === "Sbf";
      });
    if (createdElement) {
      this.deleteElement(createdElement);
    } else {
      const oldSbf = { ... this.getSbf(sbfId)};
      return this.createElement({
        element_type: "Delete",
        new_value: {},
        old_value: { ... oldSbf },
        sbf_id: parseInt(sbfId, 10),
        target_type: "Sbf",
      });
    }
    return new Promise((r) => r({}));
  }
  public commentSbf(sbfId: string): Promise<object> {
    return this.createElement({
      element_type: "Comment",
      new_value: { comment: "" },
      sbf_id: sbfId,
      target_type: "Sbf",
    });
  }

  public getKpiList(resultArea?: string): object[] {
    const reviewCreated = this.review
          .elements
          .filter((el) =>
            el.element_type === "Create"
            && el.target_type === "Kpi"
          )
          .map((el) => el.new_value);

    if (resultArea) {
      return this.a3Document.kpi[resultArea]
        .concat(reviewCreated.filter(
          (x) => x.result_area === resultArea
        ));
    }

    return Object.values(this.a3Document.kpi as object)
      .flat()
      .concat(reviewCreated);
  }
  public getKpi(kpiId: string): any {
    if (this.a3Document === null) { return null; }

    const intKpiId = parseInt(kpiId, 10);
    const fromDocument =  Object
      .values(this.a3Document.kpi)
      .flat()
      .find((kpi) => (kpi as any).id === intKpiId);
    if (fromDocument) { return fromDocument; }

    const fromAnnotations = this.review.elements
      .filter((el) => el.element_type === "Create" && el.target_type === "Kpi")
      .filter((el) => el.new_value.id === kpiId)[0];
    if (fromAnnotations) {
      return { ... fromAnnotations.new_value, child_refs: [] };
    }

    return null;
  }
  public createKpi(kpi: any, pushTo?: number[]): Promise<object> {
    kpi.id = `newkpi_${this.generateId()}`;

    return this.createElement({
      element_type: "Create",
      new_value: { ... kpi },
      target_type: "Kpi",
    });
  }
  public saveKpi(kpi: any, pushTo?: number[]): Promise<object> {
    // First, check if we should adapt an existing
    // created element
    const createdElement = this.review.elements
      .find((x) => {
        return x.element_type === "Create"
          && x.new_value.id === kpi.id
          && x.target_type === "Kpi";
      });
    if (createdElement) {
      Object.assign(
        createdElement.new_value,
        {...kpi },
      );
      this.updateElement(createdElement);
      return new Promise((res, rej) => res({}));
    }

    // We only hit the code below if we edit an
    // existing kpi
    const oldKpi = { ... this.getKpi(kpi.id)};
    const newValues = Object.entries(kpi)
      .filter((entry) => {
        const key = entry[0];
        return typeof entry[1] !== "object"
          && entry[1] !== oldKpi[key];
      })
      .reduce((acc, entry) => {
        acc[entry[0]] = entry[1];
        return acc;
      }, {});

    // We don't create a new element when nothing is changed
    if (Object.keys(newValues).length === 0) {
        return new Promise((r) => r({}));
    }
    return this.createElement({
      element_type: "Edit",
      kpi_id: kpi.id,
      new_value: { ... newValues },
      old_value: { ... oldKpi },
      target_type: "Kpi",
    });
  }
  public deleteKpi(kpiId: string): Promise<object> {
    const createdElement = this.review.elements
      .find((x) => {
        return x.element_type === "Create"
          && x.new_value.id === kpiId
          && x.target_type === "Kpi";
      });
    if (createdElement) {
      return this.deleteElement(createdElement);
    } else {
      const oldKpi = { ... this.getKpi(kpiId)};
      return this.createElement({
        element_type: "Delete",
        kpi_id: parseInt(kpiId, 10),
        new_value: { },
        old_value: { ... oldKpi },
        target_type: "Kpi",
      });
    }
  }
  public commentKpi(kpiId: string): Promise<object> {
    const oldKpi = { ... this.getKpi(kpiId)};
    return this.createElement({
      element_type: "Comment",
      kpi_id: kpiId,
      new_value: { comment: "" },
      target_type: "Kpi",
    });
  }

  public loadKpiMeasurements(kpiId: string, includeChildren: boolean = false): Promise<object> {
    let intKpiId = parseInt(kpiId, 10);
    if (!intKpiId) { return new Promise((res) => res([
      // A really nice fake response :)
      {a3doc_id: this.a3Document.id, values: []}])
    );}

    let url = `a3/${this.a3DocId}/kpi/${kpiId}/measurement`;

    const queryOptions: string[] = [];
    if (includeChildren) { queryOptions.push("with_children=true"); }
    if (queryOptions.length > 0) { url += "?" + queryOptions.join("&"); }

    return this.$http.get(url).then((res) => res.body);
  }
  public createKpiMeasurement(kpiId: string, measurement: any): Promise<object> {
    const kpi = { ... this.getKpi(kpiId)};
    this.review.elements.push({
      kpi,
      kpi_id: kpi.id,
      new_value: { measurement },
      person: this.reviewer,
      person_id: this.reviewer.id,
      target_type: "kpi_measurement",
      type: "create",
    });
    return new Promise((r) => r({}));
  }
  public deleteKpiMeasurement(kpiId: string, measurementId: number): Promise<object> {
    const kpi = { ... this.getKpi(kpiId)};
    this.review.elements.push({
      kpi,
      kpi_id: kpi.id,
      kpi_measurement_id: measurementId,
      person: this.reviewer,
      person_id: this.reviewer.id,
      target_type: "kpi_measurement",
      type: "delete",
    });
    return new Promise((r) => r({}));
  }

  public loadKpiLinks(kpiId: string): Promise<any> {
    let intKpiId = parseInt(kpiId, 10);
    if (!intKpiId) { return new Promise((res) => res([])); }

    const url = `a3/${this.a3DocId}/kpi/${kpiId}/link`;
    return this.$http.get(url).then((res) => res.body);
  }
  public loadKpiLink(linkId: number, kpiId: number): Promise<object> {
    return this.loadKpiLinks(`${kpiId}`)
    .then((links: any) => {
      return links.find((x) => x.id === linkId);
    });
  }
  public createKpiLink(link: object, kpiId: number): Promise<object> {
    return new Promise((r) => r({}));
  }

  /*
   * Returns all actions that are present either
   * within the review action, or within the review elements
   */
  public getActionList(orgArea?: string): object[] {
    const reviewCreated = this.review
          .elements
          .filter((el) =>
            el.element_type === "Create"
            && el.target_type === "Action",
          )
          .map((el) => el.new_value);

    if (orgArea) {
      return this.a3Document.actions[orgArea]
        .concat(reviewCreated.filter(
          (x) => x.org_area === orgArea,
        ));
    }

    return Object.values(this.a3Document.actions as object[])
        .flat()
        .concat(reviewCreated);
  }
  /*
   * Retrieves an action from either the original actions in the
   * A3 Document, or retrieve it while it's actually a review
   * element
   */
  public getAction(actionId: string): any {
    if (this.a3Document === null) { return null; }

    const intActionId = parseInt(actionId, 10);
    const fromDocument =  Object
      .values(this.a3Document.actions)
      .flat()
      .find((action) => (action as any).id === intActionId);
    if (fromDocument) { return fromDocument; }

    const fromAnnotations = this.review.elements
      .filter((el) => el.element_type === "Create" && el.target_type === "Action")
      .filter((el) => el.new_value.id === actionId)[0];
    if (fromAnnotations) {
      return { ... fromAnnotations.new_value };
    }

    return null;
  }
  public createAction(action: any, pushTo?: number[]): Promise<object> {
    action.id = `newaction_${this.generateId()}`;
    return this.createElement({
      element_type: "Create",
      new_value: { ...action },
      target_type: "Action",
    });
  }
  public saveAction(action: any, pushTo?: number[]): Promise<object> {
    // First, check if we should adapt an existing
    // created element
    const createdElement = this.review.elements
      .find((x) => {
        return x.element_type === "Create"
          && x.new_value.id === action.id
          && x.target_type === "Action";
      });
    if (createdElement) {
      Object.assign(
        createdElement.new_value,
        {...action },
      );
      this.updateElement(createdElement);
      return new Promise((res, rej) => res({}));
    }

    const oldAction = { ... this.getAction(action.id)};
    const newValues = Object.entries(action)
      .filter((entry) => {
        const key = entry[0];
        return typeof entry[1] !== "object"
          && entry[1] !== oldAction[key];
      })
      .filter((entry) => {
          // filter on non-relevant keys
          return entry[0] !== "assigned_to_person";
      })
      .reduce((acc, entry) => {
        acc[entry[0]] = entry[1];
        return acc;
      }, {});

    // We don't create a new element when nothing is changed
    if (Object.keys(newValues).length === 0) {
        return new Promise((r) => r({}));
    }
    return this.createElement({
      action_id: action.id,
      element_type: "Edit",
      new_value: { ...newValues },
      old_value: { ...oldAction },
      target_type: "Action",
    });
  }
  public deleteAction(actionId: string): Promise<object> {
    const createdElement = this.review.elements
      .find((x) => {
        return x.element_type === "Create"
          && x.new_value.id === actionId
          && x.target_type === "Action";
      });

    if (createdElement) {
      return this.deleteElement(createdElement);
    } else {
      const oldAction = { ... this.getAction(actionId)};
      return this.createElement({
        action_id: parseInt(actionId, 10),
        element_type: "Delete",
        new_value: {},
        old_value: { ...oldAction },
        target_type: "Action",
      });
    }
    return new Promise((r) => r({}));
  }

  public commentAction(actionId: number): Promise<object> {
    return this.createElement({
      action_id: actionId,
      element_type: "Comment",
      new_value: { comment: "" },
      target_type: "Action",
    });
  }

  public loadActionStatusTree(actionId: string): Promise<object> {
    const actionIntId = parseInt(actionId, 10);
    if (actionIntId) {
      const url = `a3/${this.a3DocId}/action/${actionId}/status_tree`;
      return this.$http.get(url).then((res) => res.body);
    }

    // return a basically zero value
    return new Promise((r) => r({}));
  }
  public loadActionStatusList(actionId: string): Promise<object> {
    const actionIntId = parseInt(actionId, 10);
    if (actionIntId) {
      const url = `a3/${this.a3DocId}/action/${actionId}/status`;
      return this.$http.get(url).then((res) => res.body);
    }
    return new Promise((r) => r({}));
  }

  public createActionStatus(aStatus: object, actionId: number): Promise<object> {
    return this.createElement({
      action_id: actionId,
      element_type: "Create",
      new_value: { aStatus },
      target_type: "ActionStatus",
    });
  }
  public deleteActionStatus(statusId: number, actionId: number): Promise<object> {
    return new Promise((r) => r({}));
  }

  public loadActionTaskList(actionId: string): Promise<any> {
    const actionIntId = parseInt(actionId, 10);
    if (actionIntId) {
      const url = `a3/${this.a3DocId}/action/${actionId}/task`;
      return this.$http.get(url).then((res) => res.body);
    }

    return new Promise((r) => r({}));
  }
  public loadActionTask(actionId: string, taskId: number): Promise<any> {
    return this.loadActionTaskList(actionId)
    .then((tasks) => {
      return tasks.find((t) => t.id === taskId);
    });
  }
  public saveActionTask(task: any, actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/task/${task.id}`;
    return this.$http.put(url, task).then((res) => res.body);
  }
  public createActionTask(task: any, actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/task`;
    return this.$http.post(url, task).then((res) => res.body);
  }
  public deleteActionTask(taskId: number, actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/task/${taskId}`;
    return this.$http.delete(url).then((res) => res.body);
  }

  public loadActionLinks(actionId: string): Promise<any> {
    const actionIntId = parseInt(actionId, 10);
    if (actionIntId) {
      const url = `a3/${this.a3DocId}/action/${actionId}/link`;
      return this.$http.get(url).then((res) => res.body);
    }

    return new Promise((r) => r({}));
  }
  public loadActionLink(linkId: number, actionId: string): Promise<object> {
    return this.loadActionLinks(actionId)
    .then((links: any) => {
      return links.find((x) => x.id === linkId);
    });
  }
  public createActionLink(link: object, actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/link`;
    return this.$http.post(url, link).then((res) => res.body);
  }
  public updateActionLink(link: any, actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/link/${link.id}`;
    return this.$http.put(url, link).then((res) => res.body);
  }
  public deleteActionLink(linkId: number, actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/link/${linkId}`;
    return this.$http.delete(url);
  }


  public getLayoutOption(option: string): any {
      return this.a3Document.meta.layout.options[option];
  }
  public hasLayoutOption(option: string): any {
      return this.getLayoutOption(option) === true;
  }


  public updateElement(element: any): Promise<object> {
    const url = `a3/${this.a3DocId}/review/${this.reviewId}/element/${element.id}`;
    return this.$http.put(url, {
        new_value: element.new_value,
        status: element.status,
    }).then((res) => {
      // TODO: Do we need to update anything?
      return ;
    });
  }
  public deleteElement(element: any): Promise<any> {
    const url = `a3/${this.a3DocId}/review/${this.reviewId}/element/${element.id}`;
    return this.$http.delete(url)
      .then((res) => {
        const i = this.review.elements
          .findIndex((el) => el.id === element.id);
        this.review.elements.splice(i, 1);
        return ;
    });
  }

  public approveElement(element: any): Promise<object> {
    return this.applyElement(element)
      .then(() => {
        element.status = "Approved";
        const t0 = new Date();
        this.loadDocument();
        return this.updateElement(element)
          .then(() => {
            const msSinceT0 = (new Date().getTime() - t0.getTime());
            let timeout = 0;
            if (msSinceT0 < 500) {
              timeout = 500 - msSinceT0;
            }
            // ensure we only splice the element when
            // the transition is complete
            setTimeout(() => {
              const elIndex = this.review.elements
                .findIndex((el) => el.id === element.id);
              this.review.elements.splice(elIndex, 1);
            }, timeout);
            return element;
          });
      });
  }

  public approveComment(element: any): Promise<object> {
    element.status = "Approved";
    const t0 = new Date();
    return this.updateElement(element)
      .then(() => {
        const msSinceT0 = (new Date().getTime() - t0.getTime());
        let timeout = 0;
        if (msSinceT0 < 500) {
          timeout = 500 - msSinceT0;
        }
        // ensure we only splice the element when
        // the transition is complete
        setTimeout(() => {
          const elIndex = this.review.elements
            .findIndex((el) => el.id === element.id);
          this.review.elements.splice(elIndex, 1);
        }, timeout);
        return element;
      });
  }

  public declineElement(element: any): Promise<object> {
    element.status = "Declined";
    const t0 = new Date();
    return this.updateElement(element)
      .then(() => {
        const msSinceT0 = (new Date().getTime() - t0.getTime());
        let timeout = 0;
        if (msSinceT0 < 500) {
          timeout = 500 - msSinceT0;
        }
        setTimeout(() => {
          const elIndex = this.review.elements
            .findIndex((el) => el.id === element.id);
          this.review.elements.splice(elIndex, 1);
        }, timeout);
        return element;
      });
  }




  private createElement(element: object): Promise<object> {
    return this.$http.post(`a3/${this.a3DocId}/review/${this.reviewId}/element`, element)
    .then((res) => res.body)
    .then((el) => {
      this.review.elements.push(el);
      this.review.elements.sort((a, b) => {
        return this.elementSortScore(a) - this.elementSortScore(b);
      });

      return el;
    })
  }

  /*
   * We apply an element by proxying the data into
   * the right function in the underlying data manager
   */
  private applyElement(element: any): Promise<object> {
    const dm = new StandardA3DataManager(this.a3DocId);

    const functionMap = {
      Mission: {
        Edit: "saveMission",
      },
      Vision: {
        Edit: "saveVision",
      },
      Sbf: {
        Create: "createSbf",
        Delete: "deleteSbf",
        Edit: "saveSbf",
      },
      Kpi: {
        Create: "createKpi",
        Delete: "deleteKpi",
        Edit: "saveKpi",
      },
      Action: {
        Create: "createAction",
        Delete: "deleteAction",
        Edit: "saveAction",
      },
    };
    const fn = functionMap[element.target_type][element.element_type];

    if (element.element_type === "Delete") {
      return dm[fn](element.old_value.id);
    }

    if (element.element_type === "Create") {
      const value = element.new_value;
      const reviewId = value.id;

      return dm[fn](value, []).then((res) => {
        // Replace all SBF elements
        if (res.sbf_id) {
          for (const el2 of this.review.elements
            .filter((el) => el.target_type === "Kpi"
              || el.target_type === "Action",
            )
            .filter((el) => el.new_value.sbf_id === reviewId)
          ) {
            (el2 as any).new_value.sbf_id = res.sbf_id;
            this.updateElement(el2);
          }
        }

        if (res.kpi_id) {
          for (const el2 of this.review.elements
            .filter((el) => el.target_type === "Action")
            .filter((el) => el.new_value.kpi_id === reviewId)
          ) {
            (el2 as any).new_value.kpi_id = res.kpi_id;
            this.updateElement(el2);
          }
        }
      });
    }

    if (element.element_type === "Edit") {
      let value;
      if (element.target_type === "Mission" ||
          element.target_type === "Vision") {
        value = element.new_value;
      }
      if (element.target_type === "Sbf") {
        const sbf = this.getSbf(element.sbf_id);
        value = Object.assign(sbf, element.new_value);
      }
      if (element.target_type === "Kpi") {
        const kpi = this.getKpi(element.kpi_id);
        value = Object.assign(kpi, element.new_value);
      }
      if (element.target_type === "Action") {
        const action = this.getAction(element.action_id);
        value = Object.assign(action, element.new_value);
      }
      return dm[fn](value, []);
    }

    throw new Error("Invalid element type");
  }

  /*
   * Generate an ID to use for the Sbf, Kpi and Actions
   * This isn't random, but random enough for creating a couple
   * of elements in the review. This should be easier than keeping
   * a counter per element.
   *
   * Chance of duplicates in a _single_ review should be negligible
   */
  private generateId(): string {
    return Math.random().toString(36).substring(5);
  }

  private elementSortScore(element: any): number {
    if (element.target_type === "Mission") {
      return 0;
    }
    if (element.target_type === "Vision") {
      return 1;
    }
    if (element.target_type === "Sbf") {
      return 1000;
    }
    if (element.target_type === "Kpi") {
      return 2000;
    }
    if (element.target_type === "Action") {
      return 5000;
    }

    return -1;
  }


}
