<template>
  <div class="element-form">
      <h2 class="element-form-title">
        <i class="fas fa-tachometer-alt"></i>
        {{ $t("ui.kpi.edit") }}
      </h2>

      <KpiForm :kpi="kpi"
        :dataManager="dataManager"
        :show_deploy="dataManager.getCapability('kpi.deploy')"
        :child_departments="relations.children"
        :management_groups="management_groups"
        :push_a3docs="push_a3docs"
        @submit="saveKpi"
        @cancel="$router.go(-1)" />
  </div>
</template>

<script>

import KpiForm from '@/components/forms/KpiForm.vue';
import ManagementGroups from '@/mixins/ManagementGroups.js';

import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {

  mixins: [ManagementGroups, WithDocumentManager],

  components: {
    KpiForm,
  },

  props: {
    kpi_id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    kpi: null,
    push_a3docs: []
  }),

  mounted() {
    this.loadKpi();
  },

  methods: {
    saveKpi() {
      // When the kpi isn't pushed, we need to update these.
      // This is done here to NOT clear the values when the user ticks
      // and directly unticks the tickbox
      // TODO: Should we move this to the dataManager?
      if (!this.kpi.push_default) {
        this.kpi.agg_type = null;
        this.push_a3docs = [];
      }

      this.dataManager
        .saveKpi(this.kpi, this.push_a3docs)
        .then(() => {
          this.interactionManager.backToDocument();
        });
    },

    loadKpi() {
      let kpi = this.dataManager.getKpi(this.kpi_id);
      this.kpi = { ... kpi };
      this.push_a3docs = kpi.child_refs.map(x => x.a3doc_id);
    },
  },
};
</script>

