<template>
  <div
      class="action-view-overlay overlay-result-area"
      :style="{'--result-area-color': getActionColor()}"

 sbf     v-if="action" >
      <h3 class="overlay-type-header">
          {{ $tc('definitions.terms.action') }}
      </h3>
      <h2>{{ action.name }}</h2>

      <div style="margin-top: 45px">
        <div style="width: 60%; display: inline-block; vertical-align: top;">
          <div style="text-align: center">
            <template v-if="action.status != null">

              <template v-if="!action.status.cancelled">
                <div v-if="current_mood
                           && dataManager.hasLayoutOption('mood_icons')"
                     style="z-index: 100; position: relative;"
                     :style="{'color': `${current_mood.color}`}"
                    >
                    <i class="mood-icon fas" :class="`fa-${current_mood.icon}`"></i>
                </div>

                <div>
              <span style="font-size: 96px; font-weight: bold;
                           position: relative;
                           z-index: 2000;
                  ">{{ action.status.percent_done }}</span> %
                </div>
              </template>
              <template v-else>
                <div>
              <span style="font-size: 40px;
                            font-weight: bold;
                            text-decoration: line-through;
                           position: relative;
                           z-index: 2000;
                           ">
                    <i class="fas fa-times"></i>
                  {{ $t('ui.action_status.cancelled') }}
              </span>
                </div>
                </template>

              <span>
                <em>per: {{ $d(new Date(action.status.date), 'long') }}</em>
              </span>
              <div class="information-column"
                   style="padding-left: 0; text-align: left;"
                   v-if="action.status.comment != null">
                  <h4>{{ $t('ui.action.last_update') }} </h4>
                  <p style="white-space: pre-wrap">{{ action.status.comment }}</p>
              </div>


            </template>
            <template v-else>
              <span style="font-size: 96px; font-weight: bold;">??</span> %
              <br>
              <span>
                  <em>{{ $t('ui.action.no_state') }} </em>
              </span>
            </template>
          </div>

          <div class="overlay-action-button-container" v-if="canUpdate">
            <button class="a3-button action"
                    data-cy="status-edit-button"
                    :class="{disabled: !dataManager.getCapability('action.status.edit')}"
                    @click="$router.push(`${action_id}/status`)"
              >
              <i class="fas fa-edit"></i> {{ $t('ui.action.change_status') }}
            </button>
          </div>

          <h3>{{ $tc('ui.action.tasks', 2) }} </h3>
          <table class="action-status-table">
            <thead>
              <tr>
                  <th>{{ $tc('ui.action.tasks') }} </th>
                  <th>{{ $t('ui.action.deadline') }} </th>
              </tr>
            </thead>
            <tbody>
                  <tr v-for="t in tasks"
                      :key="t.id"
                      :class="{completed: t.done}"
                      @click="interactionManager.editTask(action_id, t.id)">

                      <td>
                          <InfoBox top="2em" left="0" v-if="t.note && !t.done">
                            <template>
                                <RichTextRender :value="t.note" />
                            </template>
                          </InfoBox>

                            <i class="fas fa-check" v-if="t.done"></i>
                            {{ t.name }}
                            <span v-if="t.assigned_to_person">
                              ({{ t.assigned_to_person.initials }})
                            </span>
                      </td>
                      <td v-if="t.target_date"> {{ $d(new Date(t.target_date), 'long') }} </td>
                      <td v-else><em> {{ $t('ui.general.unknown') }} </em></td>
                  </tr>
                  <tr v-if="tasks.length == 0">
                      <td colspan="2">
                          <em>{{ $t('ui.action.no_tasks') }}</em>
                      </td>
                  </tr>
            </tbody>
          </table>

          <div class="overlay-action-button-container" v-if="canUpdate">
            <button class="a3-button action"
                    :class="{disabled: !dataManager.getCapability('action.task.create')}"
                    @click="$router.push(`${action_id}/task/create`)"
                    data-cy="task-add-button"
              >
              <i class="fas fa-plus"></i> {{ $t('ui.action.add_task') }}
            </button>
          </div>


          <div v-if="action.push_default > 0 && relations">
              <h3>Substatus</h3>
              <table class="action-status-table">
                <thead>
                  <tr>
                    <th>Afdeling</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                      <tr v-for="c in action.child_refs"
                          :key="c.a3doc_id">
                          <td>{{ A3NameFromRelations(relations, c.a3doc_id, false) }} </td>
                          <td>
                            <template v-if="status_list[c.a3doc_id]">
                              {{ status_list[c.a3doc_id].percent_done }}%
                              , per {{ $d(new Date(status_list[c.a3doc_id].date), 'long') }}
                              <template v-if="status_list[c.a3doc_id].comment">
                              <br>
                              - {{ status_list[c.a3doc_id].comment }}
                              </template>
                            </template>
                            <template v-else>
                              0%
                            </template>
                          </td>
                      </tr>
                </tbody>
              </table>
          </div>

          <h3>{{ $t('ui.action.comment_result') }} </h3>
          <RichTextRender :value="action.comment_result" />
        </div>



        <div class="information-column" style="width: 30%; display: inline-block; vertical-align: top;">
          <h4><i class="fas fa-user"></i> {{ $t('ui.action.assigned_to') }} </h4>

          <template v-if="action.assigned_to_person">
            <strong>{{action.assigned_to_person.name}}</strong><br>
            <small>{{action.assigned_to_person.function_name}}</small>
          </template>
          <template v-else>
              <em>{{ $t('ui.action.not_assigned') }} </em>
          </template>



          <h4><i class="fas fa-calendar"></i> {{ $t('ui.action.period') }} </h4>

          <div>
              <span class="sub-label">{{ $t('ui.action.start_date') }}</span>
              <template v-if="action.action_ref == null || action.action_ref.start_date == null">
                <template v-if="action.start_date">
                  <strong>{{ $d(new Date(action.start_date), "long") }}</strong><br>
                </template>
                <template v-else>
                    <em>{{ $t('ui.action.not_assigned') }} </em>
                </template>
              </template>
              <template v-else>
                <strong>{{ $d(new Date(action.action_ref.start_date), "long") }}</strong><br>
              </template>
          </div>

          <div>
              <span class="sub-label">{{ $t('ui.action.target_date') }}</span>
              <template v-if="action.action_ref == null || action.action_ref.target_date == null">
                <template v-if="action.target_date">
                  <strong>{{ $d(new Date(action.target_date), "long") }}</strong><br>
                </template>
                <template v-else>
                    <em>{{ $t('ui.action.not_assigned') }} </em>
                </template>
              </template>
              <template v-else>
                <strong>{{ $d(new Date(action.action_ref.target_date), "long") }}</strong><br>
              </template>
          </div>

          <template v-if="$hasFeature('Budget') && action.budget_entries">
            <h4><i class="fas fa-sack-dollar"></i> {{ $t('ui.budget.budget') }} </h4>
            <span :class="{'inner-document-link': editable}"
                  data-cy="budget-detail-ActionChangelog"
                  @click="interactionManager.editBudget(action.id)">
                  <i class="fas fa-stopwatch"></i> {{ $n(total_budget_hours) }} uur
                  /
                  &euro; {{ $n(total_budget_price) }}
            </span>
          </template>


          <template v-if="$hasFeature('A3View')">
            <h4>{{ $t('ui.link.links_and_attachments') }}</h4>
            <LinkElement
               v-for="link in links"
               :key="link.id"
               :link="link"
               :editable="editable"
               @edit="$router.push(`${action_id}/link/${link.id}`)"
            />

            <div
               v-if="$hasFeature('A3Edit')"
               class="add-button-container"
                 style="margin-top: 0; display: block; font-size: .9em;"
                 @click="$router.push(`${action_id}/link/create`)"
              >
              <i class="fas fa-plus"></i> {{ $t('ui.link.add_link') }}
            </div>
          </template>


          <h4>{{ $tc('definitions.terms.sbf') }} </h4>
          <template v-if="sbf">
            <span class="inner-document-link"
                @click="interactionManager.selectSbf(sbf.id)">
              {{ sbf.name }}
            </span>
          </template>
          <template v-else>
             <InfoBox
               tkey="sbf_unknown" left="0" top="15">
               <template v-slot:display>
                <i class="fas fa-exclamation" style="margin-right: 5px"></i>
                <em>{{ $t('ui.general.unknown') }} </em>
               </template>
             </InfoBox>
          </template>

          <h4>{{ $t('definitions.terms.result_area') }} </h4>
          {{ result_area.name ?
            result_area.name :
            $t("definitions.result_area." + action.result_area)
          }}

          <h4>{{ $tc('definitions.terms.result') }} </h4>
          <template v-if="kpi">
            <span class="inner-document-link"
              @click="interactionManager.selectKpi(kpi.id)">
              {{ kpi.name }}
            </span>
          </template>
          <template v-else>
             <InfoBox
               tkey="kpi_unknown" left="0" top="15">
               <template v-slot:display>
                <i class="fas fa-exclamation" style="margin-right: 5px"></i>
                <em>{{ $t('ui.general.unknown') }} </em>
               </template>
             </InfoBox>
          </template>

          <template v-if="action.action_ref != null">
              <h4>{{ $t('ui.a3_document.pushed_from') }} </h4>
            <p>
              {{ A3NameFromRelations(relations, action.a3doc_id) }}
            </p>
          </template>

          <template v-if="relations && relations.children && relations.children.length > 0">
              <h4><i class="fas fa-arrow-right"></i> {{ $t('ui.a3_document.pushed_to') }} </h4>
              <div v-for="c in relations.children"
                   :key="c.department.id"
                   class="push-pill"
                   :class="{'inactive': isPushPillInactive(c)}"
                   @click="() => { if (!isPushPillInactive(c)) { $router.push(`/a3/${c.a3_ref.a3doc_id}/action/${action_id}`) }}"
                   >
                   {{ c.department.name }}
              </div>
          </template>
        </div>
      </div>

      <div class="overlay-action-button-container" v-if="editable">
        <ButtonActionGroup
            :buttons="[
                  { label: 'ui.general.delete', icon: 'trash', class: 'danger', event: 'delete' },
                  { label: 'ui.general.copy_to', icon: 'copy', class: 'action', event: 'copy', feature: 'A3Management' },
                  { label: 'ui.changelog.changelog', icon: 'history', class: 'action', event: 'changelog' },
            ]"
            @copy="interactionManager.copyElements({
                   sbf: [action.sbf_id],
                   kpi: [action.kpi_id],
                   action: [action.id]
            })"
            @delete="$router.push(`${action.id}/delete`)"
            @changelog="$router.push(`${action.id}/changelog`)"
        />
        <div class="a3-button action"
             data-cy="action-edit-button"
            @click="interactionManager.editAction(action.id)">
          <i class="fas fa-pencil"></i>
          {{ $t('ui.general.edit') }} </div>
      </div>
  </div>
</template>

<script>

import RichTextRender from '@/components/forms/RichTextRender';
import ButtonActionGroup from '@/components/elements/ButtonActionGroup.vue';
import A3RelationHelper from '@/mixins/A3RelationHelper';
import ResultAreaHelper from '@/mixins/ResultAreaHelper';

import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  mixins: [A3RelationHelper,
    ResultAreaHelper,
    WithDocumentManager],

  components: {
    RichTextRender,
    ButtonActionGroup,
  },
  props: {
    action_id: {
      type: String,
      required: true,
    },

    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    editmode: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data: () => ({
    status_list: [],
    tasks: [],
    links: null,
  }),

  computed: {
    // Checks whether the user is allowed to update
    canUpdate() {
      return this.editmode && (
        this.interactionManager.hasA3Feature('A3Edit')
          || this.interactionManager.hasA3Feature('A3ActionUpdate')
      );
    },


    result_area() {
        return this.resultAreaFromLayout(
            this.a3_document.meta.layout,
            this.action.result_area
        );
    },

    action() {
      this.dataManager.a3Document;
      return this.dataManager.getAction(this.action_id);
    },

    kpi() {
      if (this.a3_document == null) return null;

      for (let kpi of Object.values(this.a3_document.kpi).flat()) {
        if (kpi.id == this.action.kpi_id) return kpi;
      }
      return null;
    },
    sbf() {
      if (this.a3_document == null) return null;
      return this.dataManager.getSbf(this.action.sbf_id);
    },

    current_status() {
      if (!this.status_list[this.a3_document.id]) return null;
      return this.status_list[this.a3_document.id];
    },

    current_mood() {
        if (this.action.status.mood == null) {
            return null;
        }

        return this.a3_document.meta.layout.options.moodmap
            .find(x => x.key == this.action.status.mood);
    },

    total_budget_price() {
      return this.action.budget_entries
        .filter(x => x.total_price != null)
        .map(x => parseFloat(x.total_price))
        .reduce((x,y) => x + y, 0)
    },
    total_budget_hours() {
      return this.action.budget_entries
        .filter(x => x.budget_type == "hours")
        .map(x => parseInt(x.count))
        .reduce((x,y) => x + y, 0)
    }
  },

  mounted() {
    if (this.a3_document) {
      this.loadStatusList();
      this.loadTaskList();
      this.loadLinks();
    }
  },

  watch: {
    a3_document() {
      this.loadStatusList();
      this.loadTaskList();
      this.loadLinks();
    },
  },

  methods: {

    loadStatusList()  {
      this.dataManager
        .loadActionStatusTree(this.action_id)
        .then((s) => this.status_list = s);
    },

    loadTaskList() {
      this.dataManager
        .loadActionTaskList(this.action_id)
        .then((t) => this.tasks = t);
    },

    loadLinks() {
      this.dataManager
        .loadActionLinks(this.action_id)
        .then((links) => this.links = links);
    },

    getActionColor(action) {
        if (this.a3_layout.structure.sbf_result_mapping == "one_to_one") {
            return this.area_colors[this.action.result_area];
        }
        if (this.a3_layout.structure.sbf_result_mapping == "one_to_many") {
            if(this.sbf && this.sbf.color) return this.sbf.color;
        }
        return "#333333";
    },

    isPushPillInactive(child) {
      return child.a3_ref == null || this.action.child_refs.find((x) => x.a3doc_id == child.a3_ref.a3doc_id) == null;
    }
  },
};
</script>

<style lang="scss">
.action-status-table {
  width: 100%;

  cell-border-spacing: 0;
  border-collapse: collapse;

  text-align: left;

  td {
    width: 50%;
    padding: 5px 0px;
  }
  tbody tr {
    transition: background-color .15s ease-out, color .15s ease-out;

    &:hover {
      background-color: $a3-green;
      color: white;
      cursor: pointer;
    }
  }

  tr.completed {
    opacity: .5;
    text-decoration: line-through;
  }

  thead {
    th {
      border-bottom: 1px $a3-green solid;
      padding: 5px 0px;
    }
  }
}

.action-view-overlay {
  h3 {
    color: rgba($a3-green, .6);
    margin-top: 35px;
    font-size: 24px;
  }

  .mood-icon {
      font-size: 100px;
      position: absolute;
      margin-top: -30px;
      opacity: .35;
  }
}
</style>
