var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WithMenuContainer',[_c('div',{staticClass:"tab-container"},[_c('div',{staticClass:"year-tab",class:{ selected: _vm.$route.matched.some(
                            ({name}) => name === 'user-settings-preferences'
                            )},on:{"click":function($event){return _vm.$router.push('/me/settings/preferences')}}},[_c('i',{staticClass:"fas fa-sliders-v-square"}),_vm._v(" "+_vm._s(_vm.$t('ui.user_settings.preferences'))+" ")]),_c('div',{staticClass:"year-tab",class:{ selected: _vm.$route.matched.some(
                         ({ name }) => name === 'user-settings-security'
                         )},on:{"click":function($event){return _vm.$router.push('/me/settings/security')}}},[_c('i',{staticClass:"fas fa-lock"}),_vm._v(" "+_vm._s(_vm.$t('ui.user_settings.security'))+" ")]),_c('div',{staticClass:"year-tab",class:{ selected: _vm.$route.matched.some(
                         ({ name }) => name === 'user-settings-notifications'
                         )},on:{"click":function($event){return _vm.$router.push('/me/settings/notifications')}}},[_c('i',{staticClass:"fas fa-bell"}),_vm._v(" "+_vm._s(_vm.$t('ui.user_settings.notifications'))+" ")]),_c('div',{staticClass:"year-tab",class:{ selected: _vm.$route.matched.some(
                         ({ name }) => name === 'user-settings-a3-display'
                         )},on:{"click":function($event){return _vm.$router.push('/me/settings/a3_display')}}},[_c('i',{staticClass:"fas fa-telescope"}),_vm._v(" "+_vm._s(_vm.$t('ui.user_settings.a3_display'))+" ")])]),_c('div',{staticClass:"plan-container"},[_c('h2',[_vm._v(_vm._s(_vm.$t('ui.user_settings.user_settings')))]),_c('router-view')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }