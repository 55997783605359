<template>
    <div class="menu-container">
        <div class="menu-item" @click="$emit('focusTab')"
             :class="{'active': focus_active}"
             data-cy="sheet-filter-menu-button"
          >
          <i class="fas fa-filter" /> {{ $t('ui.a3_document.focus') }}
        </div>
        <div class="menu-item" @click="$emit('displayTab')">
            <i class="fas fa-telescope" /> {{ $t('ui.a3_document.display') }}
        </div>
        <div class="menu-item"
             v-if="hasA3Right('Review') || hasA3Right('ReviewEdit')"
             @click="$emit('review')"
             :class="{'active': review_active}"
            >
            <i class="fas fa-comment-check" /> {{ $t('ui.review.review') }}
        </div>
        <div class="menu-item"
             v-if="hasA3Right('A3Print')"
             :class="{'active': print_active }"
             @click="$emit('print')"
          >
            <i class="fas fa-print" /> {{ $t('ui.a3_document.pdf_export') }}
        </div>

        <div class="menu-item menu-more-button"
             id="menu-more-button"
             @click="sub_menu_active = !sub_menu_active"
             :class="{'active': sub_menu_active}"
            >
            {{ $t('ui.general.more') }} <i class="fas fa-caret-down" style="margin-left: 5px"></i>
        </div>

        <div id="a3-sub-menu" class="sub-menu" :class="{'active': sub_menu_active}"
            >
            <div class="sub-menu-item"
                 @click="$emit('report')"
              >
                <i class="fas fa-file-chart-line" /> {{ $t('ui.a3_document.report') }}
            </div>
            <div class="sub-menu-item"
                 v-if="hasA3Right('A3Share')"
                 @click="$emit('share')"
              >
                <i class="fas fa-share" /> {{ $t('ui.a3_document.share') }}
            </div>
            <div class="sub-menu-item"
                 v-if="$hasFeature('A3Layouts') && hasA3Right('A3Edit')"
                 @click="$emit('layout')"
              >
                <i class="fas fa-paint-brush" /> {{ $t('ui.a3_document.layout') }}
            </div>
            <div class="sub-menu-item"
                 v-if="$hasFeature('OrgEditRights')"
                @click="$emit('options')">
                <i class="fas fa-cog" /> {{ $t('ui.general.options') }}
            </div>
            <div class="sub-menu-item"
                 v-if="$hasFeature('A3Remove')"
                @click="$emit('delete')">
                <i class="fas fa-times" /> {{ $t('ui.general.delete') }}
            </div>
        </div>
    </div>
</template>


<script>

export default {
    props: {
        focus_active: { type: Boolean, required: false, default: false },
        review_active: { type: Boolean, required: false, default: false },
        print_active: { type: Boolean, required: false, default: false },
        a3_right: {
            type: Array,
            required: false,
            default: [],
        }
    },

    data: () => ({
        sub_menu_active: false,
        click_event: null,
    }),

    mounted() {
        this.click_event = document.body.addEventListener('click', this.handleClick);
        document.getElementById('a3-sub-menu')
            .addEventListener('click', this.cancelClick);
        document.getElementById('menu-more-button')
            .addEventListener('click', this.cancelClick);
    },
    destroyed() {
        document.body.removeEventListener('click', this.click_event);
    },

    methods: {
        handleClick(ev) {
            let menu_button = document.getElementById('menu-more-button');
            if (menu_button == null) { return; }

            if (ev.target == menu_button || menu_button.contains(ev.target)) { return; }
            this.sub_menu_active = false;
        },
        cancelClick() {
            if (this.click_event) {
                this.click_event.stopPropagation();
            }
        },

        hasA3Right(feature) {
            if (this.a3_right == null) {
                return false;
            }
            return this.a3_right.includes(feature);
        },
    },
}
</script>

<style lang="scss">

.menu-container {
    float: right;
    vertical-align: bottom;
    position: relative;
    background-color: $a3-green;
    border-radius: 0 0 0 5px;
    z-index: 100;

    -webkit-box-shadow: -5px 5px 11px -5px rgba(0,0,0,0.5);
    -moz-box-shadow: -5px 5px 11px -5px rgba(0,0,0,0.5);
    box-shadow: -5px 5px 11px -5px rgba(0,0,0,0.5);

    .sub-menu {
        z-index: 1000;
        user-select: none;
        position: absolute;
        right: 0;
        display: none;
        border: 1px $green-darkened solid;
        border-top: 0px;
        border-radius: 0px 0px 5px 5px;

        &.active {
            display: block;
        }

        .sub-menu-item {
            color: hsl(188, 20%, 75%);
            background-color: $a3-green;
            transition: color .15s ease-out, background-color .15s ease-out;
            display: block;
            width: 200px;
            text-align: left;
            padding: 15px;
            cursor: pointer;
            i {
                pointer-events: none;
                display: inline-block;
                width: 20px;
            }
            &:hover {
                background-color: darken($a3-green, 10%);
                color: white;
            }
        }
    }

    .menu-more-button {
        color: hsl(188, 20%, 75%);
        display: inline-block;
        background-color: $a3-green;
        border: 1px solid $a3-green;
        transition: color .15s ease-out, background-color .15s ease-out;
        border-radius: 5px;
        text-align: center;
        padding: 15px;
        cursor: pointer;
        height: 18px;
        width: 30px;
        &:hover {
            background-color: darken($a3-green, 10%);
            color: white;
        }
        &.active {
          border: 2px white solid;
          color: white;
          font-weight: bold;
          background-color: lighten($a3-green, 5%);
          padding: 14px;
        }
    }

    .menu-item {
        color: hsl(188, 20%, 75%);
        display: inline-block;
        border: 1px solid $a3-green;
        border-bottom: 0px;
        transition: color .15s ease-out, background-color .15s ease-out;
        width: 130px;
        text-align: center;
        border-radius: 5px;
        padding: 15px;
        cursor: pointer;
        &:hover {
            background-color: darken($a3-green, 10%);
            color: white;
        }
        &.active {
          border: 2px white solid;
          color: white;
          font-weight: bold;
          background-color: rgba(#ffffff, .1);
          padding: 13px;
        }
    }
}
</style>
