<template>
  <WithMenuContainer>
    <div>
      <h2>{{ $t('ui.timeframe.edit') }} </h2>

      <TimeframeForm :timeframe="timeframe"
        @submit="saveTimeframe"
        @delete="show_delete_overlay = true"
        @cancel="$router.go(-1)"
      />


      <ConfirmationOverlay
             v-if="show_delete_overlay"
             @submit="deleteTimeframe"
             @cancel="show_delete_overlay = false"
       >
         {{ $t('ui.timeframe.confirm_delete', [timeframe.name]) }}
      </ConfirmationOverlay>
    </div>
  </WithMenuContainer>
</template>

<script>

import TimeframeForm from '@/components/forms/TimeframeForm.vue';

export default {

  components: {
    TimeframeForm
  },

  props: {
    timeframe_id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    timeframe: null,
    show_delete_overlay: false,
  }),

  mounted() {
    this.loadTimeframe();
  },

  methods: {
    loadTimeframe() {
      this.$http.get(`timeframe`)
        .then(res => {
          let c_value = parseInt(this.timeframe_id);
          this.timeframe = res.body.filter(x => x.id === c_value)[0];
        });
    },

    saveTimeframe() {
      this.$http.put(`timeframe/${this.timeframe_id}`, this.timeframe)
        .then(res => {
          this.$router.push('/a3');
        });
    },

    deleteTimeframe() {
      this.$http.delete(`timeframe/${this.timeframe.id}`)
        .then(res => {
          this.$router.push('/a3');
        }, () => {
          // TODO: Error handling!
        });
    },
  },
}
</script>
