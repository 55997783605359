<template>
    <div style="height: 100%;" class="blue-scroll-bar">
        <MainMenu />
        <ReviewMenu :a3doc_id="a3document_id"
                  :review_id="review_id"
        />

        <div class="view-container" style="position: relative;">
            <slot></slot>
        </div>
    </div>
</template>

<script>

import MainMenu from '@/components/nav/MainMenu.vue';
import ReviewMenu from '@/components/nav/ReviewMenu.vue';

export default {
    components: {
        MainMenu,
        ReviewMenu,
    },

    props: {
      a3document_id: {
        type: String,
        required: true,
      },
      review_id: {
        type: String,
        required: true,
      },
    },
};
</script>

