var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sbf-element",class:[_vm.resultAreaToClass(_vm.sbf.result_area), _vm.inFocus ? '' : 'inactive'],staticStyle:{"position":"relative"},style:({'--main-item-color': _vm.getSbfColor(_vm.sbf)}),on:{"click":[function($event){if(!$event.shiftKey)return null;return _vm.$emit('focus')},function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.$emit('select')}],"mouseover":function($event){return _vm.$emit('mouseover')},"mouseleave":function($event){return _vm.$emit('mouseleave')},"contextmenu":function($event){$event.preventDefault();return _vm.interactionManager.setContextMenu(
                              $event,
                              'sbf',
                              { sbf: _vm.sbf }
                              )}}},[_vm._v(" "+_vm._s(_vm.index + 1)+". "),_c('span',{staticClass:"sbf-list-element",class:{
          'review-active': _vm.reviewAnnotations.filter(x => x.element_type === 'Comment').length > 0,
          'review-deleted': _vm.displayStrikethrough,
          'review-added': _vm.displayNewStyle
        }},[_c('WordDiffRender',{attrs:{"base":_vm.sbf.name,"newValue":_vm.review_name}})],1),(_vm.review != null && _vm.editable)?_c('div',{staticClass:"review-comment-add-button",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('comment')}}},[_c('i',{staticClass:"fas fa-comment-alt-plus"})]):_vm._e(),(_vm.displayOptions.show_plan_source && _vm.sbf.sbf_ref != null)?[_c('div',{staticStyle:{"padding-left":"21px"}},[_c('i',{staticClass:"fa fa-rotate-90 fa-level-up"}),_c('span',{staticStyle:{"padding-left":"5px"}},[_vm._v("Vanuit: "+_vm._s(_vm.A3NameFromRelations( _vm.relations, _vm.sbf.a3doc_id ))+" ")])])]:_vm._e(),(_vm.displayOptions.show_pushed_to && _vm.sbf.child_refs.length > 0)?[_c('div',{staticStyle:{"padding-left":"15px"}},[_c('i',{staticClass:"fas fa-arrow-down"}),_vm._v(" Doorgezet: "+_vm._s(_vm.sbf.child_refs.map((x) => _vm.A3NameFromRelations( _vm.relations, x.a3doc_id)))+" ")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }