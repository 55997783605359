<template>
  <span v-if="render_type != 'richtext'">{{ rendered_value }}</span>
  <RichTextRender v-else :value="value" />
</template>

<script>

import RichTextRender from '@/components/forms/RichTextRender';

export default {

  components: { RichTextRender },

  props: {
    field: {
      type: String,
      required: false,
    },
    value: {
      required: true,
      validator: () => true,
    },
    dataManager: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    rendered_value: null,
  }),

  mounted() {
    this.rendered_value = this.render();
  },

  watch: {
    field() {
      this.rendered_value = this.render();
    },
    value() {
      this.rendered_value = this.render();
    },
  },

  computed: {
    // Field the required type to render,
    // by using a mapping from element -> render_type
    render_type() {
      if (this.field == null) return null;

      // TODO: Fix this with all elements!
      return {
        'sbf.name': null,
        'sbf.result_area': 'result_area',
        'sbf.comment_chances_risks': 'richtext',
        'sbf.push_default': 'boolean',

        'kpi.name': null,
        'kpi.unit': 'unit',
        'kpi.sbf_id': 'sbf_id',
        'kpi.result_area': 'result_area',
        'kpi.kpi_type': 'kpi_type',
        'kpi.is_steering_kpi': 'boolean',
        'kpi.target_min': 'number',
        'kpi.target_max': 'number',
        'kpi.comment_analysis': 'richtext',
        'kpi.push_default': 'boolean',

        'action.name': null,
        'action.kpi_id': 'kpi_id',
        'action.sbf_id': 'sbf_id',
        'action.result_area': 'result_area',
        'action.push_default': 'boolean',
        'action.org_area': 'org_area',
        'action.comment_result': 'richtext',
        'action.target_date': 'date',
        'action.start_date': 'date',
        'action.assigned_to': 'person_id',
      }[this.field];
    },
  },

  methods: {
    render() {
      if (this.render_type == null) {
        return this.value;
      }

      if (this.render_type === 'result_area') {
        let result_area = this.dataManager
          .getResultArea(this.value);
        return result_area.name ?
          result_area.name :
          this.$t(`definitions.result_area.${this.value}`);
      } else if (this.render_type === 'org_area') {
        let org_area = this.dataManager
          .getOrgArea(this.value);
        return org_area.name ?
          org_area.name :
          this.$t(`definitions.org_area.${this.value}`);
      } else if (this.render_type === 'sbf_id') {
        let sbf = this.dataManager.getSbf(this.value);
        if (sbf == null) return '';
        return sbf.name;
      } else if (this.render_type === 'kpi_id') {
        let kpi = this.dataManager.getKpi(this.value);
        if (kpi == null) return '';
        return kpi.name;
      } else if (this.render_type === 'action_id') {
        let action = this.dataManager.getAction(this.value);
        if (action == null) return '';
        return action.name;
      } else if (this.render_type === 'kpi_type') {
        return this.$t(`ui.kpi.type_${this.value}`);
      } else if (this.render_type === 'person_id') {
          // This __might__ lead to a race condition with setting
          // the rendered value. The function return is quicker than
          // retrieving data from the server, so this should generally
          // be fine.
          let person = this.dataManager.getPerson(this.value)
            .then((person) => this.rendered_value = person.name);
          return null;
      } else if (this.render_type === 'date') {
        if (this.value == null) return null;
        return this.$d(new Date(this.value), 'long');
      } else if (this.render_type === 'number') {
        return this.$n(this.value);
      } else if (this.render_type === 'boolean') {
        return this.$t(this.value ? 'ui.general.yes': 'ui.general.no');
      } else if (this.render_type === 'unit') {
        return this.$t(`ui.kpi.unit_text.${this.value}`);
      }
    }
  },
};


</script>


