<template>
    <div>
        <h1>{{ $t('ui.nav.a3_list') }}</h1>

        <div style="text-align: right;">
            {{ a3_list.length }} {{ $tc('ui.general.plan', a3_list.length) }}
        </div>
        <div id="a3-manage-list"></div>
    </div>
</template>


<script>


import Tabulator from 'tabulator-tables';

export default {

    data: () => ({
        a3_list: [],
        table: null,
    }),

    mounted() {
        this.$http.get('a3/list_all')
            .then((res) => {
                console.log(res.body);
                this.a3_list = res.body.map((row) => {
                    let type;
                    if (row.org.matrix_name != null) {
                        type = "ui.a3.type.program_plan"
                    } else {
                        if (row.department != null) {
                            type = "ui.a3.type.department_plan"
                        } else {
                            type = "ui.a3.type.organisation_plan"
                        }
                    };
                    return {
                        "a3doc_id": row.a3.id,
                        "type": type,
                        "created": row.a3.created,
                        "department_id": row.department ? row.department.id : null,
                        "department": row.department ? row.department.name : null,
                        "matrix_name": row.org.matrix_name,
                        "timeframe_id": row.timeframe.id,
                        "timeframe": row.timeframe.name,
                    }
                });
                this.renderTable(this.a3_list);
            })
    },


    methods: {
        renderTable(table_data) {
            this.table = new Tabulator ('#a3-manage-list', {
                data: table_data,
                layout: "fitColumns",
                layoutColumnsOnNewData:true,
                rowClick: (ev, row) => {
                    this.$router.push(`/a3/${row._row.data.a3doc_id}`);
                },
                headerSort: true,
                columns: [
                    {
                        title: this.$t('ui.a3.a3_type'),
                        field: "type",
                        formatter: (cell, formatterParams, onRendered) => {
                            let d = cell.getValue();
                            return d ? this.$t(d) : '';
                        },
                    },
                    {
                        title: this.$t('ui.general.department'),
                        field: "department",
                    },
                    { title: this.$t('ui.timeframe.timeframe'), field: "timeframe"},
                    { title: this.$tc('ui.a3.program_plan'), field: "matrix_name" },
                    {
                        title: this.$t('ui.a3.created'),
                        field: "created",
                        formatter: (cell, formatterParams, onRendered) => {
                            let d = cell.getValue();
                            return d ? this.$d(new Date(cell.getValue()), "long") : '';
                        },
                    },
                ],
            });


        }
    },
}


</script>
