<template>
    <div>
        <h3>{{ $t('ui.settings_page.language') }} </h3>
        <LanguageSelector
            @value="saveSetting('.preferences.language', $event)"
        />


        <template v-if="$hasFeature('V2Preview')">
            <h3>{{ $t('ui.settings_page.v2_preview') }}</h3>
            <div>
                <i class="fas fa-rocket"></i>
                <CheckBox v-model="settings['.preferences.v2_preview'].value"
                          @change="saveSetting('.preferences.v2_preview', $event ? 1 : 0)"
                />
            </div>
        </template>
    </div>
</template>


<script>

import LanguageSelector from '@/components/nav/LanguageSelector.vue';

export default {

    components: {
        LanguageSelector,
    },

    data: () => ({
        settings: {},
    }),

    mounted() {
        this.loadSettings();
    },

    methods: {
        loadSettings() {
            this.$http.get('me/settings').then((res) => {
                this.settings = res.body.settings;

                for (let key in this.settings) {
                    if (this.settings[key].setting_type == 'Boolean') {
                        this.settings[key].value = this.settings[key].value > 0;
                    }
                }
            })
        },

        saveSetting(key, value) {
            let setting = this.settings[key];
            if (setting.setting_type.Select) {
                value = setting.setting_type.Select.indexOf(value);
            }

            this.$http.put('me/settings', { key, value })
                .then((res) => {
                    this.settings[key].value = value > 0;
            })
        }
    }
}
</script>

<style lang="scss">

</style>
