var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[`result-area-${_vm.result_area}`]},[(_vm.icon != null)?_c('div',{staticClass:"kpi-list-icon",style:({'color': _vm.area_colors[_vm.result_area]})},[_c('i',{staticClass:"fas",class:`fa-${_vm.icon}`})]):_vm._e(),_vm._l((_vm.kpis),function(kpi,index){return _c('div',{key:'kpi-' + index,staticClass:"kpi-item-element",class:[
        _vm.resultAreaToClass(kpi.result_area),
        _vm.outOfFocus(kpi) ? 'out-of-focus' : ''
    ],style:({'--main-item-color': _vm.getKpiColor(kpi)}),on:{"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.interactionManager.selectKpi(kpi.id)},function($event){if(!$event.shiftKey)return null;return _vm.$emit('focus', kpi)}],"contextmenu":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.interactionManager.setContextMenu(
                             $event,
                             'kpi',
                             { kpi }
    )}}},[_c('KpiListElement',{attrs:{"kpi":kpi,"dataManager":_vm.dataManager,"interactionManager":_vm.interactionManager,"editable":_vm.editable,"show_numbers":_vm.displayOptions.action_pi_numbers,"show_trail":_vm.displayOptions.show_trail,"show_status":_vm.displayOptions.pi_status,"show_target":_vm.displayOptions.pi_target,"show_current_value":_vm.displayOptions.pi_current_value,"show_plan_source":_vm.displayOptions.show_plan_source,"show_pushed_to":_vm.displayOptions.show_pushed_to},on:{"comment":function($event){return _vm.dataManager.commentKpi(kpi.id)},"mouseover":function($event){return _vm.$emit('el_mouseover', kpi)},"mouseleave":function($event){return _vm.$emit('el_mouseleave', kpi)}}})],1)}),(_vm.editable)?_c('div',{staticClass:"add-button-container add-kpi-button",attrs:{"tabindex":"0"},on:{"click":_vm.createKpi,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.createKpi.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('ui.kpi.create'))+" ")]):_vm._e(),(_vm.editable && _vm.kpis.length > 1)?_c('div',{staticClass:"add-button-container",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.$router.push(`/a3/${_vm.a3_document.id}/order/${_vm.result_area}`)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$router.push(`/a3/${_vm.a3_document.id}/order/${_vm.result_area}`)}}},[_c('i',{staticClass:"fas fa-sort"}),_vm._v(" "+_vm._s(_vm.$t('ui.general.change_order'))+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }