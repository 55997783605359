<template>
    <div class="user-settings-section">
      <h3>{{ $t('ui.change_password.change_password') }} </h3>


      <form class="element-form" v-if="!success">

        <div class="form-buttons">
          <div class="error-box" v-show="errors.length > 0 || server_error"
                                 style="text-align: left;"
              >
              <strong>{{ $t('ui.change_password.could_not_save') }}
                  <template v-if="server_error"> {{ $t('ui.change_password.server_error') }} </template>
            </strong>
            <p>
              {{ $t('ui.change_password.check') }} :
              <ul>
                <li v-for="(error, index) in errors" :key="index" >
                  {{ error }}
                </li>

                <li v-if="server_error">
                    {{ $t('ui.change_password.current_password_right') }}
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div class="form-field">
            <label>{{ $t('ui.change_password.current_password') }} <InfoBox tkey="current_password" /></label>
          <input v-model="input.old_password" type="password" />
        </div>

        <div class="form-field" style="margin-top: 30px">
            <label> {{ $t('ui.change_password.new_password') }}
            <InfoBox tkey="new_password" />
          </label>
          <input v-model="input.new_password" type="password" />
        </div>

        <div class="form-field">
            <label> {{ $t('ui.change_password.repeat_new') }}
              <InfoBox tkey="new_password_repeat" />
          </label>
          <input v-model="input.new_password_repeat" type="password" />
        </div>


        <div class="form-buttons">
          <button class="a3-button action" @click.prevent="savePassword" >
              <i class="fas fa-save"></i> {{ $t('ui.change_password.change_password') }}
          </button>
        </div>

      </form>

      <div v-if="success" style="text-align: center; color: #009900;">
        <i class="fas fa-check-circle" style="font-size: 80px;"></i>
        <h2>{{ $t('ui.change_password.saved_successfully') }}</h2>
      </div>
    </div>
</template>


<script>

export default {
  data: () => ({
    input: {
      old_password: "",
      new_password: "",
      new_password_repeat: "",
    },
    errors: [],
    server_error: false,
    success: false,
  }),

  mounted() {
    let pass_from_query = this.$route.query['pass'];
    if (pass_from_query) {
      this.input.old_password = pass_from_query;
    }
  },

  methods: {
    savePassword() {
      this.validatePassword();
      if (this.errors.length > 0) return;

      this.server_error = false;
      this.$http.post('me/change_password', this.input)
        .then(() => {
          this.success = true;
            setTimeout(() => {
                this.success = false;
                this.input.old_password = "";
                this.input.new_password = "";
                this.input.new_password_repeat = "";
                this.errors = [];
                this.server_error = false;
            }, 10000);
        }, () => {
          this.errors = [];
          this.server_error = true;
        })
    },

    validatePassword() {
      this.errors = [];
      if (this.input.new_password !== this.input.new_password_repeat) {
          this.errors.push(this.$t('ui.change_password.error_repeat'));
      }
      if (this.input.new_password.length < 12) {
          this.errors.push(this.$t('ui.change_password.error_min_char', [12]));
      }
    },
  },
}
</script>


