const AuthStore = {
  namespaced: true,
  state: {
    loading: false,
    token: null,
    account: null,
    organisation: null,
    organisation_impersonated: false,
    person: null,
    features: null,
    license: null,
  },

  getters: {
    token: (state) => state.token,
    isLoggedIn: (state) => state.token && state.person,
    account: (state) => state.account,
    person: (state) => state.person,
    organisation: (state) => state.organisation,
    organisation_impersonated: (state) => state.organisation_impersonated,
    license: (state) => state.license,
    features: (state) => state.features,
    hasFeature: (state) => (featureKey) =>
      state.features
        && state.features[featureKey]
        && state.features[featureKey] === "Open",
  },

  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setAccount(state, account) {
      state.account = account;
    },
    setOrganisation(state, organisation) {
      state.organisation = organisation;
    },
    setOrganisationImpersonated(state, b) {
      state.organisation_impersonated = b;
    },
    setPerson(state, person) {
      state.person = person;
    },
    setFeatures(state, features) {
      if (features == null) {
        state.features = null;
        return;
      }

      // We convert the feature array into a map for easy access
      state.features = Object.fromEntries(
          features.map((feature) => [feature, "Open"]),
      );
    },
    setLicense(state, license) {
      state.license = license;
    },
  },
};

export default AuthStore;
