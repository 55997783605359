<template>
  <div class="kpi-measurement-container">
    <h1>{{ $t('ui.kpi_measurement.kpi_values') }} </h1>

    <div class="element-form"
         v-if="dataManager.getCapability('kpi.measurements.create')"
      >
        <div class="form-buttons">
          <h3>{{ $t('ui.kpi_measurement.add_value') }} </h3>
        </div>
        <div class="form-field">
          <label>{{ $t('ui.kpi_measurement.date') }} </label>
          <DateInput v-model="measurement.date" />
        </div>
        <div class="form-field" v-if="kpi.kpi_type == 'kpi'">
          <label>{{ $t('ui.kpi_measurement.value') }} </label>
          <input class="a3-input" v-model="measurement.value" type="number" />
          <FormError :error="errors.value" />
        </div>
        <div class="form-field" v-if="kpi.kpi_type == 'evaluation' && evaluation_type == 'stars'">
          <label>{{ $t('ui.kpi_measurement.value') }} </label>
          <input class="a3-input" v-model="measurement.value" type="number" />
          <p v-if="kpi.kpi_type === 'evaluation'"
             style="margin-left: 21%; margin-top: 5px;
                     opacity: .6;"
          >
            {{ $t('ui.kpi_measurement.evaluation_value') }}
          </p>
          <FormError :error="errors.value" />
        </div>
        <div class="form-field" v-if="kpi.kpi_type == 'evaluation' && evaluation_type == 'smileys'">
          <label>{{ $t('ui.kpi_measurement.value') }} </label>
          <div class="smiley-selector">
              <i class="fas fa-angry"
                 @click="measurement.value = 0"
                 :class="{'selected': measurement.value == 0}"
                  style="--s-color: #f42b03;"
                  ></i>
              <i class="fas fa-frown"
                 @click="measurement.value = 1"
                 :class="{'selected': measurement.value == 1}"
                  style="--s-color: #F18f01;"
                  ></i>
              <i class="fas fa-meh"
                 @click="measurement.value = 2"
                 :class="{'selected': measurement.value == 2}"
                  style="--s-color: #ccc900;"
                  ></i>
              <i class="fas fa-smile"
                 @click="measurement.value = 3"
                 :class="{'selected': measurement.value == 3}"
                  style="--s-color: #80ada0;"
                  ></i>
              <i class="fas fa-laugh"
                 @click="measurement.value = 4"
                 :class="{'selected': measurement.value == 4}"
                  style="--s-color: #09bc8a;"
                  ></i>
          </div>

          <FormError :error="errors.value" />
        </div>
        <div class="form-buttons">
          <button class="a3-button action" @click="saveMeasurement">
            <i class="fas fa-plus"></i> {{ $t('ui.kpi_measurement.add_value')}}
          </button>
        </div>
    </div>

    <div style="margin-top: 45px">
      <h3>{{ $t('ui.kpi_measurement.overview') }} </h3>
      <table class="a3-table" style="max-width: 450px">
        <thead>
          <tr>
            <th style="width: 30px"></th>
            <th>{{ $t('ui.kpi_measurement.date') }} </th>
            <th>{{ $t('ui.kpi_measurement.value') }} </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="m in measurements"
              :key="`table-measurement-` + m.id"
              class='measurement-row'
              @click="selected_measurement = selected_measurement == m.id ? null : m.id"
            >
            <td>
              <template v-if="selected_measurement == m.id">
                <i class="fas fa-check"></i>
              </template>
            </td>
            <td> {{ $d(new Date(m.date), 'long') }} </td>
            <td v-if="kpi.kpi_type == 'kpi'"> {{ $n(m.value) }} </td>
            <td v-if="kpi.kpi_type == 'evaluation'
                      && evaluation_type == 'stars'
                "> {{ $n(m.value) }} </td>
            <td v-if="kpi.kpi_type == 'evaluation'
                      && evaluation_type == 'smileys'
                ">
                <KpiSmileyMeasurementIcon :value="m.value" />
            </td>
          </tr>
        </tbody>
      </table>

      <button v-if="selected_measurement && dataManager.getCapability('kpi.measurements.delete')"
        class="a3-button danger"
        @click="show_delete_overlay = true">
        <i class="fas fa-trash"></i> {{ $t('ui.general.delete') }}
      </button>
    </div>

    <ConfirmationOverlay
           v-if="show_delete_overlay"
           @submit="deleteMeasurement"
           @cancel="show_delete_overlay = false"
    >
        {{ $t('ui.kpi_measurement.confirm_delete') }}
    </ConfirmationOverlay>

    <div style="margin-top: 45px">
      <button class="a3-button action" @click="$router.go(-1)">
        <i class="fas fa-chevron-left"></i> {{ $t('ui.general.back') }}
      </button>
    </div>
  </div>
</template>

<script>

import moment from 'moment';

import KpiSmileyMeasurementIcon from '@/components/elements/KpiSmileyMeasurementIcon.vue';

export default {

  components: {
      KpiSmileyMeasurementIcon,
  },

  props: {
    a3_document: {
      type: Object,
      required: true,
    },
    kpi_id: {
      type: String,
      required: true,
    },

    dataManager: {
      type: Object,
      required: true,
    },
    interactionManager: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.loadMeasurements();
  },

  data: () => ({
    measurement: {
      date: moment().format('YYYY-MM-DD'),
      value: 0,
    },
    errors: {
      value: null,
    },
    measurements: [],
    selected_measurement: null,
    show_delete_overlay: false,
  }),

  computed: {
    kpi() {
      for (let container of Object.values(this.a3_document.kpi)) {
        for (let y of container) {
          if (y.id == this.kpi_id) return y;
        }
      }
      return null;
    },

    evaluation_type() {
        return this.a3_document.meta.layout.options.kpi_evaluation_type;
    },

  },

  methods: {
    initForm() {
      this.measurement = {
        date: moment().format('YYYY-MM-DD'),
        value: null,
      };
    },

    validate() {
      this.errors.value = null;

      if (this.kpi.kpi_type === 'evaluation') {
        if (this.measurement.value < 0 ||
          this.measurement.value > 5) {
          this.errors.value = ["value_between_0_and_5"];
        } else {
          this.errors.value = [];
        }
      }

      return this.errors.value == null ||
        this.errors.value.length == 0;
    },

    saveMeasurement() {
      if (!this.validate()) {
        return;
      }

      return this.dataManager
        .createKpiMeasurement(this.kpi_id, this.measurement)
        .then(measurement => {
          this.initForm();
          this.measurements.push(measurement);

          // TODO: Sort measurements
        })
    },

    loadMeasurements() {
      this.dataManager
        .loadKpiMeasurements(parseInt(this.kpi_id))
        .then((ms) => this.measurements = ms[0].values);
    },

    deleteMeasurement() {
      this.dataManager
        .deleteKpiMeasurement(this.kpi_id, this.selected_measurement)
        .then(() => {
          this.loadMeasurements();
          this.selected_measurement = null;
          this.show_delete_overlay = false;
        })
    },
  },
}
</script>

<style lang="scss">

.kpi-measurement-container {
  h1 {
    color: $a3-green;
    opacity: .6;
    padding-left: 0;
  }

  h3 {
    color: $a3-green;
    margin-bottom: 5px;
    opacity: .6;
  }
}

.smiley-selector {
    width: 70%;
    display: inline-block;
    font-size: 35px;
    padding-top: 4px;

    i {
        margin-right: 10px;
        cursor: pointer;
        color: #888888;
        transition: transform .15s ease-out, color .15s ease-out;

        &:hover {
            transform: scale(1.3);
            color: var(--s-color);
        }

        &.selected {
            color: var(--s-color);
            transform: scale(1.2);
        }
    }
}
</style>

