<template>
    <WithMenuContainer>
        <div>
            <h1>Organisaties</h1>
            <div style="float:right">
                <button class="a3-button action" @click="$router.push(`/organisation/new`)">Nieuw</button>
            </div>
            <div class="form-row">
                <div class="form-field">
                    <label>Zoeken</label>
                    <input data-cy="input-title" v-model="filter" class="a3-input" autocomplete="off"/>
                </div>
            </div>
        <table class="a3-table">
          <thead>
            <tr>
              <th>Naam</th>
              <th>Opties</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="o in organisations_filtered" :key="o.id">
                <td>{{ o.name }}</td>
                <td>
                    <button class="a3-button action" @click="$router.push(`/organisations/${o.id}`)">Show</button>
                </td>
            </tr>
          </tbody>
        </table>

        </div>
    </WithMenuContainer>
</template>


<script>

import Vue from 'vue';

export default {

  components: {
       
  },

  data: () => ({
    organisations:{},
    organisations_filtered: null,
    filter:""
  }),
  computed: {
   
  },
  mounted() {
    this.loadOrganisations();
  },

  

  watch: {
    filter(){
        this.filterOrganisations();
    }
  },

  methods: {
    loadOrganisations() {
      this.$http.get('root/organisation')
        .then((res) => {
          this.organisations = res.body;
          this.filterOrganisations();

        })
    },
    filterOrganisations(){
        let organisations = this.organisations;
        let filterValue = this.filter.toLowerCase();

        this.organisations_filtered = organisations.filter((organisation) => {
            return organisation.name.toLowerCase().includes(filterValue)
        });
    },
    setOrganisation(organisation) {
      Vue.http.headers.common['A3-Organisation-Id'] = `${organisation.id}`;

      return this.$http.get('me').then((res) => {
        this.$store.commit('auth/setOrganisation', res.body.organisation);
        this.$store.commit('auth/setOrganisationImpersonated', true);
        this.$store.commit('auth/setFeatures', res.body.features);
        this.$router.push('/a3');
        this.$emit('close');
        return;
      }, (err) => {

      });
    },
    deleteOrganisation(organisation){
        submit("test");
    },
    editOrganisation(organisation){

    },
    renewLicense(organisation){

}
  },
}
</script>