<template>
  <div class="plan-button-container">
    <div class="yearplan-button"
      :class="{'selected': selected,
        'grayed-out': a3 == null,
        'program-plan': a3 && a3.matrix_name != null
      }"
      @click="clicked"
    >
      {{ name.substring(0, 60) }}
      <template v-if="name.length >= 60">
        ...
      </template>

      <div class="over-container">
        <template v-if="a3">
            <template v-if="canOpen">
                <i class="fas fa-chevron-right"></i> {{ $t('ui.index.open_plan') }}
            </template>
            <template v-else>
                <i class="fas fa-lock"></i> {{ $t('ui.index.no_access') }}
            </template>
        </template>
        <template v-else>
          <template v-if="featureCanCreate">
              <i class="fas fa-plus"></i> {{ $t('ui.index.new_plan') }}
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    a3: {
      required: false,
      default: null,
      validator: () => true,
    },
    a3_rights: {
        required: true,
        type: Object,
    },
  },

  computed: {
    featureCanCreate() {
      return this.$hasFeature('A3Create');
    },
    canOpen() {
      let r = this.a3_rights[this.a3.a3doc_id];
      return (r && r.length > 0) || this.$hasFeature('Root');
    },
  },

  methods: {
    clicked() {
      if (this.selected && this.a3 && this.canOpen) {
          this.$router.push(`/a3/${this.a3.a3doc_id}`);
          return;
      }

      if (this.selected && !this.a3 && this.$hasFeature('A3Create')) {
          this.$emit('new_a3');
          return;
      }

      if (this.a3 && this.a3.matrix_name && this.canOpen) {
        this.$router.push(`/a3/${this.a3.a3doc_id}`);
        return;
      }

      this.$emit('select');
    },
  },
};
</script>
