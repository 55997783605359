<template>
  <div>
    <h2 class="element-form-title"><i class="fas fa-tasks"></i> {{ $t('ui.action_task.edit_task') }} </h2>

    <ActionTaskForm
      v-if="action_task != null"
      :a3_document="a3_document"
      :action_task="action_task"
      @submit="saveActionTask"
      @delete="show_delete_overlay = true"
      @cancel="$router.go(-1)"
    />

    <ConfirmationOverlay
           v-if="show_delete_overlay"
           @submit="deleteActionTask"
           @cancel="show_delete_overlay = false"
     >
       Weet je zeker dat je deze taak wilt verwijderen?
    </ConfirmationOverlay>

  </div>
</template>

<script>

import ActionTaskForm from '@/components/forms/ActionTaskForm.vue';
import moment from 'moment';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {

  mixins: [WithDocumentManager],
  components: {
    ActionTaskForm,
  },

  props: {
    action_id: {
      type: String,
      required: true,
    },

    action_task_id: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    action_task: null,
    show_delete_overlay: false,
  }),

  computed: {
    action() {
      for (let action of Object.values(this.a3_document.actions).flat()) {
        if (action.id == this.action_id) return action;
      }
      return null;
    },
  },

  watch: {
      a3_document() {
          if (this.action_task == null) {
              this.loadActionTask();
          }
      },
  },

  mounted() {
    this.loadActionTask();
  },

  methods: {
    loadActionTask() {
      this.dataManager
        .loadActionTask(this.action_id, parseInt(this.action_task_id))
        .then((task) => {
          this.action_task = task;
          if (this.action_task.target_date != null) {
            this.action_task.target_date = moment(
              this.action_task.target_date, "YYYY-MM-DD"
            ).format('YYYY-MM-DD');
          }
        });
    },

    saveActionTask() {
      this.dataManager
        .saveActionTask(this.action_task, this.action_id)
        .then(() => {
          this.interactionManager.selectAction(this.action_id);
        });
    },

    deleteActionTask() {
      return this.dataManager
        .deleteActionTask(this.action_task_id, this.action_id)
        .then(() => {
          this.interactionManager.selectAction(this.action_id);
        });
    }
  }

}
</script>
