<template>
  <WithMenuContainer>
    <div >
      <h1><i class="fas fa-tasks"></i> {{ $t('ui.action_task.my_tasks') }}</h1>

      <MyTasksReport />
    </div>
  </WithMenuContainer>
</template>


<script>

import MyTasksReport from '@/components/reports/MyTasksReport.vue';

export default {
  components: {
    MyTasksReport,
  },
}

</script>
