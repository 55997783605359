<template>
    <WithMenuContainer>
        <div class="nav-box">
            <button class="a3-button cancel" @click="$router.push('/organisations')">< Terug</button>
        </div>
        <div class="header-box">
            <h1 v-if="organisation != null">{{ organisation.name }}</h1>
            <div class="btn-group">
                <button class="a3-button cancel" @click="setOrganisation(organisation)">Login</button>
                <button class="a3-button review" @click="renewLicense(organisation)">Renew License</button>
                <button class="a3-button danger" @click="deleteOrganisation(organisation)">Delete</button>
            </div>
        </div>
        <div class="sso-box">
            <b>SSO:</b>
            <i v-if="sso != null" class="sso-enabled" @click="disableSSO()"><i></i></i>
            <i v-if="sso == null" class="sso-disabled" @click="enableSSO()"><i></i></i>
            <div>
                <span v-if="sso != null" class="sso-details">
                    <b>Client ID:</b> <i>{{ sso.sso_client_id }}</i>
                </span>
                <span v-if="sso != null" class="sso-details">
                    <b>Client Secret:</b> <i>{{ sso.sso_client_secret }}</i>
                </span>
                <span v-if="sso != null" class="sso-details">
                    <b>Directory ID:</b> <i>{{ sso.azure_directory_id }}</i>
                </span>
            </div>
        </div>
        <div class="org_info_box">
            <div class="org_licenses">
                <table class="a3-table">
                    <thead>
                        <tr>
                            <th>Licentie type</th>
                            <th>Start datum</th>
                            <th>Eind datum</th>
                            <th>Max plans</th>
                            <th>Actief</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="l in licenses" :key="l.id" @click="$router.push(`/organisations/${organisation_id}/license/${l.id}`)">
                            <td>{{ l.license_type }}</td>
                            <td>{{ l.start_date }}</td>
                            <td>{{ l.end_date }}</td>
                            <td>{{ l.max_plans }}</td>
                            <td>{{ l.active }}</td>
                        </tr>
                        <tr>
                            <td colspan="10" class="new_license" @click="$router.push(`/organisations/${organisation_id}/license/new`)">
                                Nieuwe licentie toevoegen
                            </td>
                        </tr>   
                    </tbody>
                </table>
            <div class="org_advisors">
                <h2>Adviseurs:</h2>
                <i v-for="a in advisors" :key="a[0].account_id">{{ a[2].name }}</i>
            </div>
            
            </div>

        </div>
    </WithMenuContainer>
    
</template>


<script>
import Vue from 'vue';

export default {

  props: {
    organisation_id: {
      type: String,
      required: true,
    },
  },
  components: {
       
  },

  data: () => ({
    organisation: null,
    advisors: null,
    licenses: null,
    sso: null,
  }),
  computed: {
   
  },
  mounted() {
    this.loadOrganisation(),
    this.loadAdvisors(),
    this.loadLicenses(),
    this.loadSSO();
  },

  

  watch: {
    
  },

  methods: {
    loadOrganisation(){
        this.$http.get(`root/organisation/${this.organisation_id}`)
        .then((res) => {
          this.organisation = res.body;
        })
    },
    loadAdvisors(){
        this.$http.get(`root/organisation/${this.organisation_id}/advisors`)
        .then((res) => {
          this.advisors = res.body;
        })
    },
    loadLicenses(){
        this.$http.get(`root/organisation/${this.organisation_id}/licenses`)
        .then((res) => {
          this.licenses = res.body;
        })
    },
    loadSSO(){
        this.$http.get(`root/organisation/${this.organisation_id}/sso`)
        .then((res) => {
          this.sso = res.body;
        })
    },
    setOrganisation(organisation) {
      Vue.http.headers.common['A3-Organisation-Id'] = `${organisation.id}`;

      return this.$http.get('me').then((res) => {
        this.$store.commit('auth/setOrganisation', res.body.organisation);
        this.$store.commit('auth/setOrganisationImpersonated', true);
        this.$store.commit('auth/setFeatures', res.body.features);
        this.$router.push('/a3');
        this.$emit('close');
        return;
      }, (err) => {

      });
    },
    deleteOrganisation(organisation){
        if(confirm(`Weet je zeker dat je ${organisation.name} wilt verwijderen?`)){
            this.$http.delete(`root/organisation/${this.organisation_id}`)
                .then((res) => {
                this.$router.push(`/organisations`)
            })
        }
    },
    editOrganisation(organisation){
        
        
    },
    renewLicense(organisation){
        this.$http.post(`root/organisation/${this.organisation_id}/renew_license`)
        .then((res) => {
          this.loadLicenses();
        })
    },
    disableSSO(){
        if(confirm(`Weet je zeker dat je SSO wilt uitschakelen voor ${this.organisation.name}?`)){
            this.$http.delete(`root/organisation/${this.organisation_id}/sso`)
                .then((res) => {
                this.sso = null;
            })
        }
    },
    enableSSO(){
        this.$router.push(`/organisations/${this.organisation.id}/sso`);
    }
  },
}
</script>

<style>
td.new_license {
    text-align: center;
    font-size: 20px;
    text-decoration: underline;
}
.sso-enabled{
    background: green;
    padding: 6px;
    border-radius: 25px;
    font-size: 13px;
    font-weight: 500;
    color: white;
    font-style: normal;
}
.sso-enabled i::before{
    content: "Enabled";
}
.sso-enabled:hover{
    background: red;
    cursor: pointer;
}
.sso-enabled:hover i::before{
    content:"Disable"
}

.sso-disabled{
    background: red;
    padding: 6px;
    border-radius: 25px;
    font-size: 13px;
    font-weight: 500;
    color: white;
    font-style: normal;
}
.sso-disabled i::before{
    content: "Disabled";
}
.sso-disabled:hover{
    background: green;
    cursor: pointer;
}
.sso-disabled:hover i::before{
    content:"Enable"
}

.sso-box{
    margin-bottom: 15px;
    display:flex;
}
.sso-box div{
    margin-left: 10px;
}
.sso-box div .sso-details{
    padding-right: 10px;
}
.sso-box div .sso-details::after {
    content: "|";
    padding-left: 10px;
}
.sso-box div .sso-details:last-child::after {
    content: "";
}
.org_info_box{
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: flex-start;
}
.org_licenses{
  flex:75%;
  display: flex;
  align-items:flex-start;
}
.org_advisors{
  flex:25%;
  display:flex;
  flex-direction:column;
}
.header-box{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}
h2{
    margin-top:0;
}
</style>