<template>
  <div>
    <h2>
      <template v-if="result_area_definition.icon">
        <i class="fas" :class="`fa-${result_area_definition.icon}`"></i>
      </template>
      {{
      result_area_definition.name ?
      result_area_definition.name :
      $t("definitions.result_area." + result_area)
    }}</h2>

    <template v-for="kpi in kpis">

      <div v-if="filterMap == null || filterMap.kpi == null || filterMap.kpi[kpi.id]"
        :key="kpi.id" class="kpi-result-area-report">
        <h3>PI: {{ kpi.name }} </h3>
        <div>
            <div v-if="kpi.child_refs.length > 0"
                 class="add-button-container"
                 style="margin-top: 0px"
                 @click="$router.push(`/report/kpi_org/${a3_document.id}/${kpi.id}`)"
                >
                <i class="fas fa-sitemap"></i> {{ $t('ui.report.view_across_organisation') }}
            </div>
        </div>

        <div style="width: 30%; display: inline-block; vertical-align: top;">
        <KpiGraph
            :measurements="kpi_measurements[kpi.id]"
           :kpi="kpi"
           :show_toolbar="false"
           height="250px"
           />
        </div>
        <div style="display: inline-block; vertical-align: top; min-width: 500px;">
            <div class="kpi-result-area-row">
                <div class="indicators-container" style="margin-bottom: 20px">
                  <InfoBox left="55px" tkey="kpi_unit" v-if="kpi.unit" :params="[$t('ui.kpi.unit_text.' + kpi.unit)]" :html="true">
                    <template v-slot:display>
                        <div class="unit-indicator">
                          <i class="fas fa-percentage" v-if="kpi.unit == 'perc'"></i>
                          <i class="fas fa-euro-sign" v-if="kpi.unit == 'eur'"></i>
                          <i class="fas fa-hashtag" v-if="kpi.unit == 'number'"></i>
                        </div>
                    </template>
                  </InfoBox>
                  <InfoBox left="55px"
                           :html="true"
                  :tkey="kpi.is_steering_kpi ? 'kpi_is_steering' : 'kpi_no_steering'">
                    <template v-slot:display>
                      <div class="unit-indicator" :class="{'inactive': !kpi.is_steering_kpi}">
                        <i class="fas fa-compass"></i>
                      </div>
                    </template>
                  </InfoBox>
              </div>

              <template v-if="kpi.measurement">
                <KpiValueRuler
                        :kpi="kpi"
                        :measurement="kpi.measurement"
                />

                <div style="margin-top: 30px">laaste meting: {{ $d(new Date(kpi.measurement.date), 'long') }} </div>
              </template>
              <template v-else>
                <em>Waarde onbekend</em>
              </template>


            </div>
            <div class="kpi-result-area-row">
                <strong>{{ $t('ui.kpi.comment_analysis') }}</strong><br>

              <RichTextRender :value="kpi.comment_analysis" />

                <RichTextRender
                    v-if="kpi.kpi_ref && kpi.kpi_ref.comment_analysis"
                    :value="kpi.kpi_ref.comment_analysis" />
                    </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import KpiGraph from '@/components/elements/KpiGraph.vue';
import KpiValueRuler from '@/components/elements/KpiValueRuler.vue';
import RichTextRender from '@/components/forms/RichTextRender.vue';
import piValueCheck from '@/services/piValueCheck.js';

import ResultAreaHelper from '@/mixins/ResultAreaHelper';

export default {
  mixins: [ResultAreaHelper],

  components: { RichTextRender, KpiValueRuler, KpiGraph },

  props: {
    result_area: {
      type: String,
      required: true
    },
    a3_document: {
      type: Object,
      required: true,
    },
    filterMap: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
      kpi_measurements: {},
  }),

  computed: {
    kpis() {
      return this.a3_document.kpi[this.result_area];
    },

    result_area_definition() {
      return this.resultAreaFromLayout(
        this.a3_document.meta.layout,
        this.result_area
      );
    },
  },

  mounted() {
      this.kpis
          .forEach((kpi) => this.loadMeasurements(kpi));
  },

  methods: {
    target_min(kpi) {
      return piValueCheck.getTargetMin(kpi);
    },
    target_max(kpi) {
      return piValueCheck.getTargetMax(kpi);
    },

      loadMeasurements(kpi) {
        if (this.a3_document == null) return;

        let url = `a3/${this.a3_document.id}/kpi/${kpi.id}/measurement`;

        let query_options = [];
        if (kpi.child_refs.length > 0) {
          query_options.push("with_children=true");
        }
        if (kpi.kpi_ref) {
          query_options.push(`ref_id=${kpi.kpi_ref.id}`);
        }

        if (query_options.length > 0) {
          url += "?" + query_options.join("&");
        }
        return this.$http.get(url)
          .then(res => {
              this.$set(this.kpi_measurements, kpi.id, res.body);
          })
     },
  }
};
</script>


<style lang="scss" scoped>

.kpi-result-area-report {
  h3 {
    margin-bottom: 5px;
  }
  margin-bottom: 30px;
}
.kpi-result-area-row {
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 20px;
    margin-top: 20px;
}

.unit-indicator {
    background-color: $a3-green;
}
</style>
