<template>
  <WithOrganisationMenuContainer>
    <div class="container">
        <h1>{{ $t('ui.organisation.edit_department') }}</h1>

      <DepartmentForm
         :department="department"
         :people="people_in_department"
         @submit="updateDepartment"
         @delete="show_delete_overlay = true"
         @cancel="$router.go(-1)"
         />

        <ConfirmationOverlay
               v-if="show_delete_overlay"
               @submit="deleteDepartment"
               @cancel="show_delete_overlay = false"
         >
           {{ $t('ui.organisation.confirm_department_delete', [ department.name ]) }}
        </ConfirmationOverlay>
    </div>
  </WithOrganisationMenuContainer>
</template>


<script>

import DepartmentForm from '@/views/admin/forms/DepartmentForm.vue';

export default {

  components: {
    DepartmentForm,
  },

  props: {
    department_id: {
      type: String,
      required: true,
    },
  },

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    }
  },

  data: () => ({
    department: {
      name: null,
    },
    people_in_department: null,
    show_delete_overlay: false,
  }),

  mounted() {
    this.loadDepartment();
    this.loadPeopleInDepartment();
  },

  methods: {
    loadDepartment() {
      this.$http.get(`organisation/department/${this.department_id}`, this.department)
        .then((res) => {
          this.department = res.body;
        });
    },
    updateDepartment() {
      this.$http.put(`organisation/department/${this.department.id}`, this.department)
        .then(() => {
          this.$router.push(`/organisation`);
        })
    },

    loadPeopleInDepartment() {
      this.$http.get(`organisation/department/${this.department_id}/people`)
        .then((res) => {
          this.people_in_department = res.body;
        });
    },

    deleteDepartment() {
      this.$http.delete(`organisation/department/${this.department.id}`)
        .then(() => {
          this.$router.push(`/organisation`);
        })
    }
  },
}
</script>
