<template>
    <div>

        <h2>{{ $t('ui.user_settings.a3_display') }}</h2>

        <p style="width: 600px; opacity: .5;">
            {{ $t('ui.user_settings.a3_display_explanation') }}
        </p>

        <div v-if="settings != null">
            <h3>{{ $t('ui.display_tab.general') }} </h3>

            <div class="display-tab-element">
                <CheckBox v-model="settings[`.a3_display.defaults.action_pi_numbers`].value"
                          @change="saveSetting(`.a3_display.defaults.action_pi_numbers`, $event ? 1 : 0)"
                          />
                <label for="number-action-pi">{{ $t('ui.display_tab.action_pi_numbers') }} </label>
                <InfoBox left="-40px" top="25px" tkey="display_numbering" :html="true" />
            </div>
            <div class="display-tab-element">
                <CheckBox v-model="settings[`.a3_display.defaults.show_trail`].value"
                          @change="saveSetting(`.a3_display.defaults.show_trail`, $event ? 1 : 0)"
                          />
                <label for="number-kruimelpad">{{ $t('ui.display_tab.show_trail') }} </label>
            </div>
            <div class="display-tab-element">
                <CheckBox v-model="settings[`.a3_display.defaults.show_plan_source`].value"
                          @change="saveSetting(`.a3_display.defaults.show_plan_source`, $event ? 1 : 0)"
                          />
                <label for="check-original-plan">{{ $t('ui.display_tab.show_plan_source') }} </label>
            </div>
            <div class="display-tab-element">
                <CheckBox v-model="settings[`.a3_display.defaults.show_pushed_to`].value"
                          @change="saveSetting(`.a3_display.defaults.show_pushed_to`, $event ? 1 : 0)"
                          />
                <label for="check-push-to">{{ $t('ui.display_tab.show_deployed_to') }} </label>
            </div>
            <div class="display-tab-element">
                <CheckBox v-model="settings[`.a3_display.defaults.hover_focus`].value"
                          @change="saveSetting(`.a3_display.defaults.hover_focus`, $event ? 1 : 0)"
                          />
                <label for="check-hover-focus">{{ $t('ui.display_tab.hover_focus') }} </label>
            </div>

            <div class="display-tab-block">
                <h3>{{ $tc('definitions.terms.result', 2) }} </h3>

                <div class="display-tab-element">
                    <CheckBox v-model="settings[`.a3_display.defaults.pi_status`].value"
                              @change="saveSetting(`.a3_display.defaults.pi_status`, $event ? 1 : 0)"
                              />
                    <label for="display-pi-status">{{ $t('ui.kpi.status') }} </label>
                </div>
                <div class="display-tab-element">
                    <CheckBox v-model="settings[`.a3_display.defaults.pi_target`].value"
                              @change="saveSetting(`.a3_display.defaults.pi_target`, $event ? 1 : 0)"
                              />
                    <label for="display-pi-target">{{ $t('ui.kpi.norm') }} </label>
                </div>
                <div class="display-tab-element">
                    <CheckBox v-model="settings[`.a3_display.defaults.pi_current_value`].value"
                              @change="saveSetting(`.a3_display.defaults.pi_current_value`, $event ? 1 : 0)"
                              />
                    <label for="display-pi-value">{{ $t('ui.kpi.current_value') }} </label>
                </div>
            </div>

            <div class="display-tab-block">
                <h3>{{ $tc('definitions.terms.action', 2) }} </h3>

                <div class="display-tab-element">
                    <CheckBox v-model="settings[`.a3_display.defaults.action_check`].value"
                              @change="saveSetting(`.a3_display.defaults.action_check`, $event ? 1 : 0)"
                              />
                    <label for="display-action-done">{{ $t('ui.display_tab.check_when_done') }} </label>
                </div>
                <div class="display-tab-element" >
                    <CheckBox v-model="settings[`.a3_display.defaults.action_mood`].value"
                              @change="saveSetting(`.a3_display.defaults.action_mood`, $event ? 1 : 0)"
                              />
                     <label for="display-action-mood" >{{ $t('ui.action_status.mood') }} </label>
                </div>
                <div class="display-tab-element">
                    <CheckBox v-model="settings[`.a3_display.defaults.action_person`].value"
                              @change="saveSetting(`.a3_display.defaults.action_person`, $event ? 1 : 0)"
                              />
                    <label for="display-action-person">{{ $t('ui.display_tab.initials_assigned_to') }} </label>
                </div>
                <div class="display-tab-element">
                    <CheckBox v-model="settings[`.a3_display.defaults.action_milestone`].value"
                              @change="saveSetting(`.a3_display.defaults.action_milestone`, $event ? 1 : 0)"
                              />
                        <label for="display-action-milestone">{{ $t('ui.display_tab.next_milestone') }} </label>
                </div>
                <div class="display-tab-element" style="padding-left: 10px">
                    <i class="fas fa-level-up-alt fa-rotate-90" style="padding-right: 5px; padding-top: 8px; vertical-align: top; opacity: .5;"></i>
                    <CheckBox v-model="settings[`.a3_display.defaults.action_target_date_milestone`].value"
                              @change="saveSetting(`.a3_display.defaults.action_target_date_milestone`, $event ? 1 : 0)"
                              />
                    <label for="display-action-target-date-milestone">{{ $t('ui.display_tab.target_date_milestone') }}
                        <InfoBox left="-250px" top="25px">
                        {{ $t('ui.display_tab.target_date_milestone_info') }}
                        </InfoBox>
                    </label>
                </div>

                <div class="display-tab-element">
                    <CheckBox v-model="settings[`.a3_display.defaults.action_cancelled_strikethrough`].value"
                              @change="saveSetting(`.a3_display.defaults.action_cancelled_strikethrough`, $event ? 1 : 0)"
                          />
                    <label for="display-action-cancelled-strikethrough">{{ $t('ui.display_tab.show_cancelled_strikethrough') }}</label>
                </div>
                <div class="display-tab-element">
                    <CheckBox v-model="settings[`.a3_display.defaults.action_progress`].value"
                              @change="saveSetting(`.a3_display.defaults.action_progress`, $event ? 1 : 0)"
                              />
                    <label for="display-action-progress" >{{ $t('ui.display_tab.current_progress') }} </label>
                </div>
                <div class="display-tab-element">
                    <CheckBox v-model="settings[`.a3_display.defaults.action_tasks`].value"
                              @change="saveSetting(`.a3_display.defaults.action_tasks`, $event ? 1 : 0)"
                              />
                    <label for="display-action-tasks" >{{ $t('ui.display_tab.tasks') }} </label>
                </div>
            </div>

        </div>
        <div v-else>
            <i class="fas fa-spin fa-spinner"></i>
        </div>
    </div>
</template>


<script>


export default {

    data: () => ({
        settings: null,
    }),

    mounted() {
        this.loadSettings();
    },

    methods: {
        loadSettings() {
            this.$http.get('me/settings').then((res) => {
                this.settings = res.body.settings;

                for (let key in this.settings) {
                    if (this.settings[key].setting_type == 'Boolean') {
                        this.settings[key].value = this.settings[key].value > 0;
                    }
                }
            })
        },

        saveSetting(key, value) {
            this.$http.put('me/settings', { key, value })
                .then((res) => {
                    this.settings[key].value = value > 0;

                    // Set this value also directly in active session
                    let displayOption = key.split(".")[3];
                    this.$store.commit('settings/setDisplayOption', {
                        option: displayOption,
                        value: value > 0
                    });
                })
        }
    }
}
</script>

<style lang="scss">

.notification-grid {
    display: grid;
    grid-template-columns: 300px 100px; /*  100px 150px; */
    grid-row-gap: 20px;
    grid-column-gap: 20px;

    i {
        color: $a3-green;
        font-size: 1.4em;
        margin-right: 5px;
        opacity: .3;
    }
}
</style>
