<template>
  <WithMenuContainer>
    <div>
      <h2>{{ $t('ui.timeframe.create') }}</h2>

      <TimeframeForm :timeframe="timeframe"
        @submit="createTimeframe"
        @cancel="$router.go(-1)"
      />
    </div>
  </WithMenuContainer>
</template>

<script>

import TimeframeForm from '@/components/forms/TimeframeForm.vue';

export default {

  components: {
    TimeframeForm
  },

  data: () => ({
    timeframe: {
      name: null,
      start_date: null,
      end_date: null,
      pin_on_top: false,
      closed: false,
    }
  }),

  methods: {
    createTimeframe() {
      this.$http.post('timeframe', this.timeframe)
        .then(res => {
          this.$router.push('/a3');
        }, () => {
          // TODO: Error handling!
        });
    },
  },
}
</script>
