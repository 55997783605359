<template>
  <WithOrganisationMenuContainer>
    <div style="max-width: 1300px; width: 100%; margin: 0 auto;">
      <template v-if="$hasFeature('OrgEditRights')">
        <div style="text-align: right">
          <button class="a3-button action"
                  @click="$router.push(`/organisation/user_types/create`)"
            >
            <i class="fas fa-plus"></i> {{ $t('ui.general.create') }}
          </button>
        </div>
      </template>

      <h1>{{ organisation.name }} : {{ $t('ui.organisation.user_types') }} </h1>

      <div v-if="user_types.length == 0"
           style="text-align: center"
          >
          <h3>{{ $t('ui.user_type.no_user_types') }}</h3>

          <button class="a3-button action"
                  @click="initDefaultUserTypes"
              >
              <i class="fas fa-rocket"></i>
              {{ $t('ui.user_type.init_default') }}
          </button>
      </div>

      <div class="user-type-container"
           v-for="user_type in user_types">

        <div class="name">
          <h3>{{ user_type.name}}</h3>
          <p v-if="user_type.description">
            {{ user_type.description }}
          </p>
          <p v-else>
            Geen beschrijving beschikbaar
          </p>

          <div style="margin-top: 50px">
            <div class="add-timeframe-button"
                 @click="$router.push(`/organisation/user_types/${user_type.id}`)"
              >
              <i class="fas fa-pencil"></i> {{ $t('ui.general.edit') }}
            </div>
            <div class="add-timeframe-button"
                 @click="$router.push(`/organisation/user_types/${user_type.id}/assign_to`)"
              >
              <i class="fas fa-chevron-right"></i> {{ $t('ui.user_type.assign_to') }}
            </div>
            <div class="add-timeframe-button"
                 @click="$router.push(`/organisation/user_types/${user_type.id}/delete`)"
                >
              <i class="fas fa-times"></i> {{ $t('ui.general.delete') }}
            </div>
          </div>
        </div>

        <div class="features">
          <FeaturesView
            :features="featureObject(user_type.features)"
            :show_groups="['A3Admin', 'Organisation', 'OrganisationAdmin']"
            />
        </div>
      </div>

    </div>
  </WithOrganisationMenuContainer>
</template>


<script>

import FeaturesView from '@/components/admin/FeaturesView.vue';

export default {

  components: { FeaturesView },

  data: () => ({
    user_types: [],
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    }
  },

  mounted() {
    this.loadUserTypes();
  },

  methods: {
    loadUserTypes() {
      return this.$http.get(`admin/user_type`)
        .then(res => this.user_types = res.body);
    },

    featureObject(features) {
      return Object.fromEntries(features
        .map((f) => [f, "Open"])
      );
    },

    initDefaultUserTypes() {
        return this.$http.post('admin/init_default_user_types')
            .then((_) => {
                this.loadUserTypes();
            });
    },
  }
}
</script>


<style lang="scss">

.user-type-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-bottom: rgba($a3-green, .1) solid 1px;
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
