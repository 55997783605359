<template>
  <div class="action-measurement-container">
    <h1>Actie - Link toevoegen</h1>
    <em>{{ action.name }}</em>

    <LinkForm :link="link"
       @cancel="$router.go(-1)"
       @submit="saveLink"
    />
  </div>
</template>

<script>

import LinkForm from '@/components/forms/LinkForm.vue';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  mixins: [WithDocumentManager],
  components: { LinkForm },

  props: {
    action_id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    link: {
      name: null,
      url: null,
      description: null,
    },
  }),

  computed: {
    action() {
      for (let container of Object.values(this.a3_document.actions)) {
        for (let y of container) {
          if (y.id == this.action_id) return y;
        }
      }
      return null;
    },
  },

  methods: {
    saveLink() {
      this.dataManager
        .createActionLink(this.link, this.action_id)
        .then(() => this.interactionManager.selectAction(this.action_id));
    },
  },
}
</script>

<style lang="scss">

.action-measurement-container {
  h1 {
    color: $a3-green;
    opacity: .6;
    padding-left: 0;
  }

  h3 {
    color: $a3-green;
    margin-bottom: 5px;
    opacity: .6;
  }
}
</style>
