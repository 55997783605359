<template>
  <div id="get-started-container">
    <div id="get-started-inner">

      <img src="@/public/img/a3_online_logo.png" class="logo"/>
      <h1>{{ $t('ui.get_started.welcome') }} </h1>

      <div id="header-subtext">
        <template v-if="!disabled">
            <p>{{ $t('ui.get_started.intro_line_1') }} </p>
            <p>{{ $t('ui.get_started.intro_line_2') }} </p>
        </template>

      </div>
      <div v-show="disabled" id="get-started-text-closed">
        <h2>Momenteel is de registratie functie gesloten</h2>

        <p>
          Probeer op een later moment nogmaals,
          of neem contact met ons op voor meer informatie.
        </p>

        <p>
          Mail: <a href="mailto:support@a3online.nl">support@a3online.nl</a>
        </p>
      </div>


      <div class="section" v-if="step >= 1 && !disabled">
          <h2>{{ $t('ui.get_started.question_name') }} </h2>
        <input class="a3-input" v-model="user.name" @keydown.enter="completeStep1()"
                                id="get-started-user-name"
        />

        <button class="a3-button action" @click="completeStep1()">
            {{ $t('ui.get_started.ok_next_question') }}
        </button>
      </div>

      <div class="section" v-if="step >= 2">
          <h2>{{ $t('ui.get_started.question_mail') }} </h2>
        <input class="a3-input" v-model="user.mail" @keydown.enter="completeStep2()"
                                type="email"
                                id="get-started-user-mail"
                                required
        />
        <div v-if="errors.mail">
          {{ errors.mail }}
        </div>

        <button class="a3-button action" @click="completeStep2()">
            {{ $t('ui.get_started.ok_next_question') }}
        </button>
      </div>

      <div class="section" v-if="step >= 3">
        <h2>{{ $t('ui.get_started.question_organisation') }} </h2>
        <input class="a3-input" v-model="user.organisation"
                                id="get-started-organisation"
                                @keydown.enter="completeStep3()"

        />

        <button class="a3-button action" @click="completeStep3()">
            {{ $t('ui.get_started.ok_next_question') }}
        </button>
      </div>

      <div class="section last-section" style="text-align: center" v-if="step == 4 && account == null">
          <h2>{{ $t('ui.get_started.finished_header') }} </h2>
        <p>
        {{ $t('ui.get_started.click_create_account') }}
        </p>

        <div style="font-size: 50px" v-if="!creating_account">
          <button class="a3-button action" style="font-size: 20px;" @click="createAccount">
              <i class="fas fa-check"></i> {{ $t('ui.get_started.create_account') }}
          </button>
        </div>

        <div style="font-size: 30px; margin-top: 30px;" v-if="creating_account">
          <i class="fas fa-spinner fa-spin"></i>
          {{ $t('ui.get_started.creating_account') }}
        </div>

        <div style="font-size: 16px !important; margin-top: 30px; max-width: 540px;
          text-align: left: margin: 0 auto;
          " v-if="errors.creating_account">
          <i class="fas fa-spinner fa-times"></i>
          {{ $t('ui.get_started.create_account_error') }}
        </div>
      </div>

      <div class="account-created" v-if="account">
        <i class="fas fa-check main-icon"></i>
        <p>
          {{ $t('ui.get_started.account_ready') }}
        </p>

        <p style="margin-top: 10px; max-width: 500px; margin: 20px auto;">
          {{ $t('ui.get_started.change_password_mail') }}
        </p>

        <div>
          <button class="a3-button action" @click="$router.push(`/a3/${a3_id}`)">
              {{ $t('ui.get_started.start') }} <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>



<script>

export default {
  data: () => ({
    user: {
      name: null,
      mail: null,
      organisation: null,
    },
    generated_password: null,
    a3_id: null,
    account: null,
    creating_account: false,
    step: 0,
    disabled: false,

    errors: {
      mail: null,
      creating_account: null,
    }
  }),

  mounted() {
    if (this.$store.getters['auth/account'] != null) {
      this.$router.push('/');
      return;
    }
    this.loadState();

    this.$store.commit('setTourPending');

    setTimeout(() => {
      if (this.step == 0) this.step = 1;
    }, 4000);
  },

  methods: {
    loadState() {
      this.$http.get('get_started')
        .then(res => {
          if (res.body.state == false) {
            this.disabled = true;
          }
        })
    },
    completeStep1() {
      if (this.user.name.length > 0) {
        if (this.step == 1) this.step++;
        this.$nextTick(() => document.getElementById('get-started-user-mail').focus());
      }
    },
    completeStep2() {
      this.errors.mail = null;
      let email_field = document.getElementById('get-started-user-mail');
      if (!email_field.checkValidity()) {
        this.errors.mail = "Dit mail adres is niet in orde. Heeft u een typefout gemaakt?";
        return;
      }

      if (this.step == 2) this.step++;
      this.$nextTick(() => document.getElementById('get-started-organisation').focus());
    },
    completeStep3() {
      if (this.user.organisation.length > 0) {
        if (this.step == 3) this.step++;
      }
    },

    createAccount() {
      this.creating_account = true;
      this.$http.post('get_started', {
        user_name: this.user.name,
        user_mail: this.user.mail,
        organisation_name: this.user.organisation,
        departments: [],
      }).then(res => {
          window.localStorage.setItem('auth_token', res.body.token);

          this.generated_password = res.body.password;
          this.a3_id = res.body.a3_id;
          this.creating_account = false;
          this.account = true;
      }, err => {
        this.creating_account = false;
        this.errors.creating_account = true;
      })
    },
  },
}

</script>

<style lang="scss">
#get-started-container {
  background-color: darken($a3-green, 10%);
  box-sizing: border-box;
  min-height: 100vh;
  margin: 0;
  padding-bottom: 150px;

}

#get-started-inner {
  box-sizing: border-box;

  position: relative;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  min-height: 600px;
  max-width: 960px;
  margin: 0 auto;
  padding: 45px;
  padding-top: 60px;
  margin-bottom: 150px;

  transition: height .4s ease-out;

  h1 {
    color: $a3-green;
    text-align: center;
    font-size: 40px;
    display: block;
    animation: headerIn 1s linear;
  }

  .logo {
    height: 100px;
    position: absolute;
    display: inline-block;
    @media only screen and (max-width: 600px) {
      position: relative;
      display: block;
    }
  }

  .section {
    margin-top: 60px;
    animation: sectionIn .4s ease-out;
  }

  .last-section {
    border-top: 1px $a3-green solid;
  }

  h2 {
    font-size: 30px;
    color: rgba($a3-green, .7);
  }

  #header-subtext {
    text-align: center;
    color: $a3-green;
    font-size: 20px;
    margin-top: 10px;

    p {
      margin-top: 5px;
      animation: sectionIn 1s ease-out;
      animation-fill-mode: backwards;
    }
    p:nth-child(1) {
      animation-delay: 1.2s;
    }
    p:nth-child(2) {
      animation-delay: 2.5s;
    }
  }

  .a3-input {
    box-sizing: border-box;
    width: 100%;
    max-width: 450px;
    padding: 10px;
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 2px rgba($a3-green, .2) solid;
    transition: border-color .1s ease-out;

    &:focus {
      outline: 0;
      border: 2px rgba($a3-green, 1) solid;
    }
  }
}

#get-started-text-closed {
    margin-top: 80px;
    animation: sectionIn 1s ease-out;
    animation-delay: 1s;
    animation-fill-mode: backwards;

    p {
      margin-top: 10px;
    }
}

@keyframes sectionIn {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
}

@keyframes headerIn {
  0% {
    transform: scale(1.3);
    opacity: 0;
  }
  60% {
    transform: scale(1.3);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  90% {
    transform: scale(1.3) translateY(10px);
    opacity: 1;
    animation-timing-function: ease-out;
  }
}

.account-created {
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px $a3-green solid;
  i.main-icon {
    font-size: 50px;
    display: block;
    margin-bottom: 20px;
    background-color: $a3-green;
    width: 50px;
    margin: 10px auto;
    padding: 10px;
    color: white;
    border-radius: 50%;
  }

  button {
    margin-top: 20px;
    font-size: 20px;
  }
}

</style>
