<template>
  <div style="text-align: left;
              margin-bottom: 50px;
    ">
      <h2 style="text-align: center">
        {{ $t('ui.kpi.current_score') }}
      </h2>

      <div class="kpi-evaluation-smiley-container"
           :class="{'inactive': currentMeasurement == null}"
        >
        <KpiSmileyMeasurementIcon :value="currentMeasurement.value" />
      </div>
      <div style="text-align: center" v-if="currentMeasurement != null">
        per, {{ $d(new Date(currentMeasurement.date), 'long') }}
      </div>
      <div style="text-align: center" v-else>
        {{ $t('ui.kpi_measurement.no_values') }}
      </div>

  </div>
</template>


<script>

import KpiSmileyMeasurementIcon from '@/components/elements/KpiSmileyMeasurementIcon.vue';

export default {

  components: {
      KpiSmileyMeasurementIcon,
  },

  props: {
    measurements: {
      type: Array,
      required: false,
      // this can be null when it is not loaded
      default: null,
    },
  },


  computed: {
    currentMeasurement() {
      if (!this.measurements || this.measurements.length == 0) { return null; }
      return this.measurements[0].values[0];
    },
  },

  methods: {

  },

};
</script>

<style lang="scss">

.kpi-evaluation-smiley-container {

  font-size: 80px;
  text-align: center;
  position: relative;
  height: 120px;

  &.inactive {
    opacity: .2;
  }

}
</style>
