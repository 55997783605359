<template>
    <WithOrganisationMenuContainer>
        <div class="container">
            <h2>{{ $t('ui.user_type.delete') }}</h2>

            <div v-if="user_type != null">

                <h2>{{ user_type.name }}</h2>

                <div v-html="$t('ui.user_type.delete_confirmation')"></div>

                <button class="a3-button danger"
                        @click="deleteUsertype"
                    >
                    <i class="fas fa-trash"></i>
                    {{ $t('ui.general.delete') }}
                </button>

                <button class="a3-button cancel"
                        @click="$router.go(-1)"
                    >
                    <i class="fas fa-times"></i>
                    {{ $t('ui.general.cancel') }}
                </button>
            </div>

        </div>
    </WithOrganisationMenuContainer>
</template>

<script>

export default {

    props: {
        user_type_id: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        user_type: null,
    }),

    mounted() {
      this.loadUsertype();
    },

    methods: {
        loadUsertype() {
          this.$http.get(`admin/user_type/${this.user_type_id}`)
            .then((res) => {
              this.user_type = res.body[0];
            }, (err) => {
                this.$router.go(-1);
            });
        },

        deleteUsertype() {
           return this.$http.delete(`admin/user_type/${this.user_type_id}`)
            .then((res) => {
                this.$router.push('/organisation/user_types');
            });
        },
    }
};


</script>
