<template>
   <div class="element-form">

    <div class="form-row">
      <div class="form-field">
        <label>{{ $t('ui.license.type') }}</label>
          <select v-model="license.license_type" class="a3-input">
            <option value='l1_trial'>{{ $t('license.types.l1_trial') }}</option>
            <option value='l1_starter'>{{ $t('license.types.l1_starter') }}</option>
            <option value='l1_basic'>{{ $t('license.types.l1_basic') }} </option>
            <option value='l1_enterprise'>{{ $t('license.types.l1_enterprise') }}</option>
            <option value='org_advisor'>{{ $t('license.types.org_advisor') }}</option>
            <option value='org_managing'>{{ $t('license.types.org_managing') }}</option>
          </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-field">
          <label>Start Date</label>
          <input type="date" data-cy="input-title" v-model="license.start_date" class="a3-input" autocomplete="off"/>
      </div>
    </div>
    <div class="form-row">
      <div class="form-field">
          <label>End Date</label>
          <input type="date" data-cy="input-title" v-model="license.end_date" class="a3-input" autocomplete="off"/>
      </div>
    </div>
    <div class="form-row">
      <div class="form-field">
          <label>Max Plans</label>
          <input data-cy="input-title" v-model="license.max_plans" class="a3-input" autocomplete="off"/>
      </div>
    </div>
    <div v-if="submitFunction != null">
      <button class="a3-button cancel" @click="$router.go(-1)">
          <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
      </button>
      <button @click="handleFunctionCall(submitFunction)" class="a3-button action" data-cy="submit">
          <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
      </button>
    </div>

   </div>
</template>

<script>

export default {
  props: {
    license: {
      type: Object,
      required: true,
    },
    submitFunction: String,
  },
  methods:{
    handleFunctionCall(functionName) {
      this.$parent.$parent[functionName](this.license)
    },
  },
}
</script>
