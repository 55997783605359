<template>
  <div :class="`overlay-result-area-${action.result_area}`" class="action-view-overlay"
                                                            v-if="action"
    >
      <h3 class="overlay-type-header">
        <i class="fas fa-sack-dollar"></i> {{ $tc('ui.budget.budget') }}
      </h3>
      <h2>{{ action.name }}</h2>


      <table class="a3-table">
        <thead>
          <tr>
            <th></th>
            <th>{{ $tc('ui.action.tasks', 1) }} </th>
            <th>{{ $t('ui.budget.category') }}</th>
            <th>{{ $t('ui.budget.budget_type') }} </th>
            <th style="width: 75px">{{ $t('ui.budget.count') }}</th>
            <th style="width: 140px">{{ $t('ui.budget.per_unit') }} (&euro;) </th>
            <th style="width: 100px">{{ $t('ui.budget.total') }} (&euro;)</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="entry in action.budget_entries"
            :key="entry.id"
            @click="selected_entry = selected_entry == entry.id ? null : entry.id"
            >
            <td>
              <template v-if="selected_entry == entry.id">
                <i class="fas fa-check"></i>
              </template>
            </td>
            <td> {{ getTaskName(entry.task_id) }} </td>
            <td>
              {{ entry.budget_category }}
              <template v-if="entry.comment">
                <InfoBox>{{ entry.comment }}</InfoBox>
              </template>
            </td>
            <td> {{ $t(`ui.budget.type.${entry.budget_type}`) }} </td>
            <td style="text-align: right;"> {{ entry.count }}
              <template v-if="entry.price_per_unit != null"> x </template>
            </td>
            <td style="text-align: right">
              <template v-if="entry.price_per_unit != null">
                &euro; {{ $n(entry.price_per_unit) }} =
              </template>
            </td>
            <td style="text-align: right;">
              <template v-if="entry.total_price != null">&euro; {{ $n(entry.total_price) }}
              </template>
            </td>
          </tr>

          <tr v-if="show_new_form">
            <td></td>
            <td>
              <select v-model="new_entry.task_id" class="a3-input" >
                <option :value="null">-</option>
                <option v-for="t in tasks" :key="t.id" :value="t.id">
                  {{ t.name }}
                </option>
              </select>
            </td>
            <td>
              <input v-model="new_entry.budget_category" class="a3-input" />
            </td>
            <td>
              <select v-model="new_entry.budget_type" class="a3-input">
                <option value="resources">{{ $t('ui.budget.type.resources') }}</option>
                <option value="hours">{{ $t('ui.budget.type.hours') }}</option>
              </select>
            </td>
            <td>
              <input v-model="new_entry.count" class="a3-input" type="number"
                      style="max-width: 50px;"
              />
            </td>
            <td>
              <input v-model="new_entry.price_per_unit" class="a3-input" type="number"
                      style="max-width: 75px;"
              />
            </td>
            <td>
              <input v-model="new_entry.total_price" class="a3-input" type="number"
                      style="max-width: 75px;"
              />
            </td>
          </tr>
          <tr v-if="show_new_form">
            <td colspan="3" style="vertical-align: top; text-align: right">
              <em>Toelichting:</em>
            </td>
            <td colspan="4">
              <textarea v-model="new_entry.comment" class="a3-input" style="box-sizing: border-box; width: 100%; max-width: 500px; min-height: 50px"></textarea>
            </td>
          </tr>
        </tbody>
      </table>


      <div style="text-align: right"
           v-if="dataManager.getCapability('action.budget.edit')"
        >
        <div style="margin-top: 15px" v-if="show_new_form">
          <button class="a3-button action" @click="saveBudgetEntry">
            <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
          </button>
          <button class="a3-button cancel" @click="show_new_form = false">
            <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
          </button>
        </div>

        <span class="add-button-container"
              v-if="!show_new_form"
              @click="show_new_form = true"
          >
          <i class="fas fa-plus"></i> {{ $t('ui.general.create') }}
        </span>
      </div>

      <template v-if="dataManager.getCapability('action.budget.edit')">
        <button v-if="selected_entry" class="a3-button danger" @click="show_delete_overlay = true">
          <i class="fas fa-trash"></i> {{ $t('ui.general.delete') }}
        </button>
      </template>

      <ConfirmationOverlay
             v-if="show_delete_overlay"
             @submit="deleteEntry"
             @cancel="show_delete_overlay = false"
      >
        {{ $t('ui.action_status.confirm_delete') }}
      </ConfirmationOverlay>



    <div style="margin-top: 45px">
      <button class="a3-button action" @click="$router.go(-1)">
        <i class="fas fa-chevron-left"></i> {{ $t('ui.general.back') }}
      </button>
    </div>
  </div>
</template>

<script>

import A3RelationHelper from '@/mixins/A3RelationHelper';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  mixins: [A3RelationHelper, WithDocumentManager],

  components: {
  },
  props: {
    action_id: {
      type: String,
      required: true,
    },

    relations: {
        type: Object,
        required: true,
    },
  },

  data: () => ({
    show_new_form: false,
    new_entry: {
      task_id: null,
      budget_category: null,
      budget_type: "resources",
      count: null,
      price_per_unit: null,
      total_price: null,
      comment: null,
    },
    selected_entry: null,
    show_delete_overlay: false,
    tasks: [],
  }),

  watch: {
    new_entry: {
      deep: true,
      handler: (val) => {
        if (val.count != null && val.price_per_unit != null) {
          val.total_price = val.count * val.price_per_unit;
        }
      }
    }
  },

  computed: {
    action() {
      if (this.a3_document == null) return null;

      for (let action of Object.values(this.a3_document.actions).flat()) {
        if (action.id == this.action_id) return action;
      }
      return null;
    },
  },

  mounted() {
    if (this.a3_document) {
      this.loadTaskList();
    }
  },

  methods: {
    getTaskName(task_id) {
      if (this.tasks.length == 0 || task_id == null) return '';

      let cs = this.tasks
        .filter(t => t.id == task_id);
      if (cs.length > 0) {
        return cs[0].name;
      }
      return null;
    },

    loadTaskList() {
      if (this.a3_document == null) return;

      let url;
      if (this.publish_key == null) {
          url = `a3/${this.a3_document.id}/action/${this.action_id}/task`;
          if (this.action.action_ref) {
            url += `?ref_id=${this.action.action_ref.id}`;
          }
      } else {
          url = `public/action_tasks/${this.action_id}?key=${this.publish_key}`;
      }
      this.$http.get(url)
        .then(res => this.tasks = res.body);
    },

    saveBudgetEntry() {
      this.$http.post(`budget/a3/${this.a3_document.id}`, {
        action_id: this.action.id,
        task_id: this.new_entry.task_id,
        budget_category: this.new_entry.budget_category,
        budget_type: this.new_entry.budget_type,
        count: parseInt(this.new_entry.count),
        price_per_unit: this.new_entry.price_per_unit,
        total_price: this.new_entry.total_price,
        comment: this.new_entry.comment,
      }).then(res => {
        this.action.budget_entries.push(res.body);
        this.new_entry.count = null;
        this.new_entry.price_per_unit = null;
        this.new_entry.total_price = null;
      });
    },

    deleteEntry() {
      this.$http.delete(`budget/a3/${this.a3_document.id}/entry/${this.selected_entry}`)
        .then(() => {
          this.selected_entry = null;
          this.show_delete_overlay = false;
          let budgetEntryIndex = this.action.budget_entries.findIndex(x => x.id == this.selected_entry);
          this.action.budget_entries.splice(budgetEntryIndex, 1);
        }, () => {

        });
    },
  },
};
</script>

<style lang="scss">
.action-status-table {
  width: 100%;

  cell-border-spacing: 0;
  border-collapse: collapse;

  text-align: left;

  td {
    width: 50%;
    padding: 5px 0px;
  }
  tbody tr {
    transition: background-color .15s ease-out, color .15s ease-out;

    &:hover {
      background-color: $a3-green;
      color: white;
      cursor: pointer;
    }
  }

  tr.completed {
    opacity: .5;
    text-decoration: line-through;
  }

  thead {
    th {
      border-bottom: 1px $a3-green solid;
      padding: 5px 0px;
    }
  }
}

.action-view-overlay {
  h3 {
    color: rgba($a3-green, .6);
    margin-top: 35px;
    font-size: 24px;
  }
}
</style>
