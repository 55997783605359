<template>
  <div class="kpi-report-container">
    <div class="kpi-graph-container">
      <KpiGraph
         :measurements="measurements_real"
         :kpi="kpi"
         :relations="relations"
         :show_toolbar="false"
         height="250px"
         />
      <div style="margin-left: -35px">
        <div :id="`kpi-${kpi.id}-report-graph`"></div>
      </div>

      <div>
        <template v-if="kpi.measurement">
          {{ kpi.measurement.value }} {{ kpi.unit }} <br>
          <small>per {{ $d(new Date(kpi.measurement.date), 'long') }} </small>

          <div style="margin-top: 15px">
            <template v-if="target_min || target_max">
              {{ $t('ui.kpi.norm') }} :
              <template v-if="target_min">
                min: {{target_min }}
              </template>
              <template v-if="target_max">
                max: {{target_max }}
              </template>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div class="kpi-info-container">
      <div class="info-container">
        <h3>{{$tc('definitions.terms.result')}}: {{ kpi.name }}</h3>
      </div>

      <div class='info-container'>
        <h4>{{ $t('ui.kpi.comment_analysis') }} </h4>
        <RichTextRender
            v-if="kpi.comment_analysis"
            :value="kpi.comment_analysis" />

        <RichTextRender
            v-if="kpi.kpi_ref && kpi.kpi_ref.comment_analysis"
            :value="kpi.kpi_ref.comment_analysis" />
      </div>

      <div class="info-container">
        <h4>{{ $tc('definitions.terms.action', 2) }} </h4>

        <table class="kpi-action-status-table">
          <thead>
            <tr>
              <th>{{ $t('ui.general.name') }} </th>
              <th>{{ $t('definitions.terms.org_area') }} </th>
              <th>{{ $t('ui.action.state') }} </th>
            </tr>
          </thead>

          <tbody>
            <template v-for="action in child_actions" >
              <tr :key="action.id" v-if="
                filterMap == null || filterMap.action == null || filterMap.action[action.id]"
              >
                <td>{{ action.name }}</td>
                <td>{{
                  action.org_area_definition.name ?
                  action.org_area_definition.name :
                  $t("definitions.org_area." + action.org_area)
                  }}</td>
                <td style="min-width: 130px">
                  <template v-if="action.status">
                    {{ action.status.percent_done }}%<br>
                    <small>per {{ $d(new Date(action.status.date), 'long') }}</small>
                  </template>
                </td>
              </tr>
            </template>
            <tr v-if="child_actions.length == 0">
              <td colspan="3">
                <em>Geen acties beschikbaar</em>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import A3RelationHelper from '@/mixins/A3RelationHelper';
import ResultAreaHelper from '@/mixins/ResultAreaHelper';
import RichTextRender from '@/components/forms/RichTextRender.vue';

import KpiGraph from '@/components/elements/KpiGraph.vue';

import piValueCheck from '@/services/piValueCheck.js';

export default {
  components: { RichTextRender, KpiGraph },
  mixins: [A3RelationHelper, ResultAreaHelper],

  props: {
    kpi: {
      type: Object,
      required: true,
    },
    a3_document: {
      type: Object,
      required: true,
    },

    relations: {
      type: Object,
      required: true,
    },

    filterMap: {
      type: Object,
      required: false,
      default: null,
    },
    measurements: {
      type: Array,
      required: false,
      default: null,
    }
  },

  data: () => ({
    measurements_real: null,
  }),

  computed: {
    child_actions() {
      return Object
        .values(this.a3_document.actions)
        .flat()
        .filter(x => x.kpi_id == this.kpi.id)
        .map(action => {
          action.org_area_definition = this.orgAreaFromLayout(
            this.a3_document.meta.layout,
            action.org_area
          );
          return action
        })
      ;
    },

    target_min() {
      return piValueCheck.getTargetMin(this.kpi);
    },
    target_max() {
      return piValueCheck.getTargetMax(this.kpi);
    },

    result_area() {
      return this.resultAreaFromLayout(
        this.a3_document.meta.layout,
        this.kpi.result_area
      );
    },


  },

  mounted() {
    if (this.measurements == null) {
      this.loadMeasurements();
    } else {
      this.measurements_real = [{"values": this.measurements}];
    }
  },

  methods: {
      loadMeasurements() {
        if (this.a3_document == null) return;

        let url = `a3/${this.a3_document.id}/kpi/${this.kpi.id}/measurement`;

        let query_options = [];
        if (this.kpi.child_refs.length > 0) {
          query_options.push("with_children=true");
        }
        if (this.kpi.kpi_ref) {
          query_options.push(`ref_id=${this.kpi.kpi_ref.id}`);
        }

        if (query_options.length > 0) {
          url += "?" + query_options.join("&");
        }
        return this.$http.get(url)
          .then(res => {
            this.measurements_real = res.body;
          })
     },
  },
};
</script>


<style lang="scss">

.kpi-report-container {
  margin-bottom: 45px;

  p{
    margin: 0;
    padding: 0;
  }
  h3 {
    margin-bottom: 5px;
  }
  h4 {
    margin: 0;
    margin-bottom: 5px;
  }

  .info-container {
    padding: 10px 5px;
  }
}

.kpi-graph-container {
  display: inline-block;
  vertical-align: top;
  width: 250px;
}

.kpi-info-container {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 250px);
}

.kpi-action-status-table {
  width: 100%;
  cell-border-spacing: 0;
  border-collapse: collapse;

  text-align: left;

  td {
    padding: 5px;
  }
  tbody tr {
    transition: background-color .15s ease-out, color .15s ease-out;
    border-bottom: 1px rgba($a3-green, .2) solid;
  }

  thead {
    th {
      background-color: $a3-green;
      color: white;
      border-bottom: 1px $a3-green solid;
      padding: 5px;
    }
  }
}
</style>
