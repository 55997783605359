<template>
  <WithReportMenuContainer :a3doc_id="a3document_id" :filter_slug="filter_slug">
    <div style="max-width: 1120px; margin: 0 auto;">
        <h1>Selecteer rapportage</h1>

        <div class="report-buttons-container">
            <div
                class="a3-button action"
                @click="gotoReportUrl(report.url)"
                v-for="report in links">
                <i class="fas" :class="`fa-${report.icon}`"></i>
                {{ $t(report.name) }}
            </div>
        </div>
    </div>
  </WithReportMenuContainer>
</template>

<script>

import WithReportMenuContainer from '@/views/WithReportMenuContainer.vue';
import FilterSlugUrl from '@/mixins/FilterSlugUrl';

export default {

  mixins: [FilterSlugUrl],

  components: {
    WithReportMenuContainer,
  },

  props: {
    a3document_id: {
      type: String,
      required: true,
    }
  },

    data: () => ({
        links: [],
    }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    },
    a3_title() {
        if (this.a3_document == null) return null;
        let meta = this.a3_document.meta;

        if (this.a3_document.meta.department != null) {
            return `${meta.department.name}, ${meta.timeframe.name}`;
        }

        return `${meta.organisation.name}, ${meta.timeframe.name}`;
    },
  },

  mounted() {
        this.links = [
            {
                url: `/report/a3/${this.a3document_id}`,
                name: 'ui.nav.a3_conversation',
                id: 'my-teams',
                icon: 'comments',
                active_slug: '/report/a3',
            },
            {
                url: `/report/result_area/${this.a3document_id}`,
                name: 'definitions.terms.result_area',
                id: 'my-teams',
                icon: 'bullseye-arrow',
                active_slug: '/report/result_area',
            },
            {
                url: `/report/org_area/${this.a3document_id}`,
                name: 'definitions.terms.org_area',
                id: 'my-users',
                icon: 'tasks',
                active_slug: '/report/org_area',
            },
            {
                url: `/report/action_table/${this.a3document_id}`,
                name: 'ui.report.action_table',
                id: 'action_table',
                icon: 'check-square',
                active_slug: '/report/action_table',
            },
            {
                url: `/report/action_progress/${this.a3document_id}`,
                name: 'ui.nav.action_progress',
                id: 'my-action-progress-report',
                icon: 'tasks-alt',
                active_slug: '/report/action_progress',
            },
            {
                url: `/report/kpi_dashboard/${this.a3document_id}`,
                name: 'ui.nav.kpi_dashboard',
                id: 'pi-dashboard',
                icon: 'analytics',
                active_slug: '/report/kpi_dashboard',
            },
        ];
      if (this.$hasFeature('Budget')) {
        this.links.push({
              url: `/budget/a3/${this.a3document_id}`,
              name: 'ui.budget.budget',
              id: 'a3-budget',
              icon: 'sack-dollar',
              active_slug: '/budget/a3',
          });
      }
  },

    methods: {
        gotoReportUrl(url) {
            let u = url;
            if (this.filter_slug != null) {
                u += `?filter=${this.filter_slug}`;
            }
            this.$router.push(u);
        },
    }
};
</script>

<style lang="scss">

.report-buttons-container {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(4, 1fr);

    .a3-button {
        font-weight: bold;
        font-size: 16px;
        i {
            display: block;
            margin-bottom: 30px;
            transform: scale(3);
            opacity: .4;
            transform-origin: 10px 5px;
        }
    }
}
</style>
