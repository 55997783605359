<template>
    <div class="feature-edit-container" v-if="feature_info != null">
        <template v-for="g in groups">
          <div v-if="show_groups.includes(g)" :key="g">
            <h3>{{ $t(`ui.feature_groups.${g}`) }}</h3>

            <div v-for="feature in feature_info[g]" :key="feature">
                <template v-if="subfeatures && subfeatures[feature]">
                  <div class="subfeature-icon" >
                    <i class="fas fa-chevron-right"></i>
                  </div>

                  <template v-if="inner_value[subfeatures[feature]] == 'Open'">
                    <div style="display: inline-block; opacity: .5;">
                      <CheckBox :value="true" />
                      {{ $t(`ui.features.${feature}`) }}<br>
                    </div>
                  </template>
                  <template v-else>
                    <CheckBox :value="inner_value[feature] == 'Open'"
                      @input="setValue($event, feature)"
                    />
                    {{ $t(`ui.features.${feature}`) }}<br>
                  </template>
                </template>
                <template v-else>
                  <CheckBox :value="inner_value[feature] == 'Open'"
                    @input="setValue($event, feature)"
                  />
                  {{ $t(`ui.features.${feature}`) }}<br>
                </template>
            </div>

          </div>
        </template>

        <div v-if="show_root && $hasFeature('Root')">
            <h3>-</h3>

            <CheckBox :value="inner_value['Root'] == 'Open'"
              @input="setValue($event, 'Root')"
            />
            Root <br>
        </div>
    </div>
</template>


<script>

export default {

  props: {
    value: {
      required: false,
      validator: () => true,
      default: null,
    },
    input_mode: {
      type: String,
      required: false,
      default: "object",
    },

    show_groups: {
      type: Array,
      required: false,
      default: () => ['A3', 'A3Admin', 'Organisation', 'OrganisationAdmin'],
    },

    show_root: {
        type: Boolean,
        required: false,
        default: false,
    },

    subfeatures: {
      type: Object,
      required: false,
      default: null,
    }
  },

  data: () => ({
    inner_value: {},

    feature_info: null,

    groups: [
      "A3", "A3Admin",
      "Organisation", "OrganisationAdmin",
      "ReleaseToggle",
    ]
  }),

  mounted() {
    this.loadFeatureInfo();
    this.loadValues();
  },

  methods: {
    loadFeatureInfo() {
      this.$http.get('feature_info')
        .then(res => this.feature_info = res.body);
    },

    loadValues() {
      if (this.input_mode == 'object') {
        this.inner_value = this.value;
        return;
      }

      if (this.input_mode == 'array') {
        this.$set(this, 'inner_value', Object.fromEntries(
          this.value.map((f) => [f, "Open"])
        ));
        return;
      }
    },

    // Emit the current selected values
    emitValues() {
      if (this.input_mode == 'object') {
        this.$emit('input', this.inner_value);
        return;
      }
      if (this.input_mode == 'array') {
        this.$emit('input',
          Object.keys(this.inner_value)
            .filter(key => this.inner_value[key] == 'Open')
        );
        return;
      }
    },

    // Set a single value and emit all
    setValue(value, feature_key) {
      if (value) {
        this.inner_value[feature_key] = 'Open';
      } else {
        this.inner_value[feature_key] = 'Closed';
      }
      this.emitValues();
    }
  },
};

</script>

<style lang="scss">
.feature-edit-container h3 {
    padding-left: 0 !important;
}

.subfeature-icon {
  display: inline-block;
  color: rgba($a3-green, .4);
  width: 20px;
  padding-left: 8px;
}
</style>

