<template>
  <div>

    <div class="a3-sheet-warnings"
         @click="show_warnings = !show_warnings"
         v-if="invalid_result_areas != null || invalid_org_areas != null"
      >
      <i class="fas fa-exclamation-triangle"></i>

      <div class="warnings-inner"
           v-if="show_warnings"
        >
        <h3>{{ $t('ui.a3_document.invalid_fields_title') }}</h3>
        <p>
          {{ $t('ui.a3_document.invalid_fields_description') }}
        </p>

        <p v-if="invalid_result_areas">
          {{ $t('ui.a3_document.invalid_fields_kpis') }}
          <ul>
            <li v-for="kpi in invalid_kpis"
               @click="interactionManager.selectKpi(kpi.id)"
              >
              {{ kpi.name }} ({{ kpi.result_area}})
            </li>
          </ul>
        </p>

        <p v-if="invalid_org_areas">
          {{ $t('ui.a3_document.invalid_fields_actions') }}
          <ul>
            <li v-for="action in invalid_actions"
                @click="interactionManager.selectAction(action.id)"
              >
              {{ action.name }} ({{ action.org_area }})
            </li>
          </ul>
        </p>
      </div>
    </div>

    <div class="a3-sheet-header" :class="{'review-active': review != null}">
        <div class="a3-header-row"
             :style="{'grid-template-columns': header_layout_columns }"
            >
          <div class="a3-element a3-missie"
              style="position: relative"
              :style="{}"
              >

              <A3BoxTitle
                :layout="layout"
                :title_key="'mission'"
                fallback_translation_key="definitions.terms.mission" />

              <MissionRender
                :dataManager="dataManager"
                :interactionManager="interactionManager" />

              <div class="review-comment-add-button"
                   v-if="review != null && editable"
                   @click.stop="dataManager.commentMission()"
                   style="top: 20%"
                   >
                   <i class="fas fa-comment-alt-plus"></i>
              </div>

              <div class="add-button-container"
                   id="add-mission-container"
                   v-if="editable"
                   tabindex="0"
                   @keyup.enter="$router.push(`/a3/${a3_document.id}/mission/edit`)"
                   @click="$router.push(`/a3/${a3_document.id}/mission/edit`)">
                  <i class="fas fa-pencil"></i> {{ $t('ui.general.edit') }}
              </div>
          </div>

          <div class="a3-element a3-visie"
              style="position: relative"
              >
              <A3BoxTitle
                :layout="layout"
                title_key="vision"
                fallback_translation_key="definitions.terms.vision" />

              <VisionRender
                :dataManager="dataManager"
                :interactionManager="interactionManager" />

              <div class="review-comment-add-button"
                   v-if="review != null && editable"
                   @click.stop="dataManager.commentVision"
                   style="top: 20%"
                   >
                   <i class="fas fa-comment-alt-plus"></i>
              </div>

              <div class="add-button-container"
                   id="add-vision-container"
                   v-if="editable"
                   tabindex="0"
                   @click="$router.push(`/a3/${a3_document.id}/vision/edit`)"
                   @keyup.enter="$router.push(`/a3/${a3_document.id}/vision/edit`)"
                >

                <i class="fas fa-pencil"></i> {{ $t('ui.general.edit') }}
              </div>
          </div>
          <div class="a3-element a3-sbf">
              <A3BoxTitle
                :layout="layout"
                title_key="sbf"
                fallback_translation_key="definitions.terms.sbf_simple" />

              <SbfModule
                :focusMap="focusMap"
                :displayOptions="displayOptions"
                :editable="editable"
                :dataManager="dataManager"
                :interactionManager="interactionManager"
                @focus="triggerSbfFocus"
                @el_mouseover="$emit('el_mouseover', {
                                  'type': 'sbf',
                                  'sbf': $event
                                  })"
                @el_mouseleave="$emit('el_mouseleave', {
                                   'type': 'sbf',
                                   'sbf': $event
                                   })"
              />
          </div>
        </div>
      </div>

      <div class="a3-sheet-body" :class="{'review-active': review != null}">
        <div class="a3-body"
             :style="{'grid-template-columns': body_layout_columns }"
          >

            <div v-for="org_area in layout.structure.org_areas"
                 class="a3-element"
                 :style="{
                    'grid-column': `${org_area.position.x} / span ${org_area.position.xspan}`,
                    'grid-row': `${org_area.position.y} / span ${org_area.position.yspan}`
                 }"
                >
                    <A3BoxTitle
                      :layout="layout"
                      :prefix="org_area.index ? `${org_area.index}.` : ''"
                      :title_key="org_area.key"
                      :custom_area="org_area"
                      :fallback_translation_key="`definitions.org_area.${org_area.key}`" />

                    <ActionList
                      :displayOptions="displayOptions"
                      :focusMap="focusMap"
                      :editable="editable"
                      :actions="a3_document.actions[org_area.key]"
                      :org_area="org_area.key"
                      :dataManager="dataManager"
                      :interactionManager="interactionManager"
                      :icon="org_area.icon"
                      @focus="triggerActionFocus"
                      @el_mouseover="triggerActionMouseOver"
                      @el_mouseleave="triggerActionMouseLeave"
                      />
            </div>

            <div v-for="result_area in layout.structure.result_areas"
                 class="a3-element"
                 :style="{
                    'grid-column': `${result_area.position.x} / span ${result_area.position.xspan}`,
                    'grid-row': `${result_area.position.y} / span ${result_area.position.yspan}`
                 }"
                >

                    <A3BoxTitle
                      :layout="layout"
                      :prefix="result_area.index ? `${result_area.index}.` : ''"
                      :title_key="result_area.key"
                      :custom_area="result_area"
                      :fallback_translation_key="'definitions.result_area.' + result_area.key" />

                    <KpiList
                      :dataManager="dataManager"
                      :interactionManager="interactionManager"
                      :displayOptions="displayOptions"
                      :focusMap="focusMap"
                      :editable="editable"
                      :review="review"
                      :result_area="result_area.key"
                      :icon="result_area.icon"
                      @focus="triggerKpiFocus"
                      @comment="dataManager.commentKpi($event.id)"
                      @el_mouseover="$emit('el_mouseover', {
                                      'type': 'kpi',
                                      'kpi': $event
                                      })"
                      @el_mouseleave="$emit('el_mouseleave', {
                                       'type': 'kpi',
                                       'kpi': $event
                                       })"
                      />
            </div>

          </div>
      </div>
  </div>
</template>

<script>

import MissionRender from '@/components/elements/MissionRender.vue';
import VisionRender from '@/components/elements/VisionRender.vue';

import SbfModule from '@/components/SbfModule.vue';
import KpiList from '@/components/KpiList.vue';
import ActionList from '@/components/ActionList.vue';

import A3BoxTitle from '@/components/elements/A3BoxTitle';
import RichTextRender from '@/components/forms/RichTextRender.vue';

import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  mixins: [WithDocumentManager],
  components: {
    MissionRender,
    VisionRender,
    SbfModule,
    KpiList,
    ActionList,
    RichTextRender,
    A3BoxTitle,
  },

  data: () => ({
    invalid_result_areas: null,
    invalid_org_areas: null,
    show_warnings: false,
  }),

  props: {
    displayOptions: {
      type: Object,
      required: true,
    },

    focusMap: {
      type: Object,
      required: false,
      default: null,
    },

    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  watch: {
    a3Document() {
      this.checkInvalidElements();
    },
  },

  mounted() {
    this.checkInvalidElements();
  },

  computed: {
    layout() {
      return this.a3_document.meta.layout;
    },
      header_layout_columns() {
          return `
              ${this.layout.structure.top_widths[0] ? `${this.layout.structure.top_widths[0]}fr` : '30fr'}
              ${this.layout.structure.top_widths[1] ? `${this.layout.structure.top_widths[1]}fr` : '30fr'}
              ${this.layout.structure.top_widths[2] ? `${this.layout.structure.top_widths[2]}fr` : '30fr'}
          `;
      },
      body_layout_columns() {
          let base_widths = this.layout.structure.grid.column_widths;
          let count = 0;
          while (base_widths.length < this.layout.structure.grid.columns) {
              // safeguard
              if (count++ > 10) break;
              base_widths.push(1);
          }
          if (base_widths.length > this.layout.structure.grid.columns) {
              base_widths.splice(0, this.layout.structure.grid.columns);
          }

          return base_widths
              .map((w) => `${w}fr`)
              .join(" ");
      },

      invalid_kpis() {
        if (this.invalid_result_areas == null) return null;

        return [].concat.apply([], ...this.invalid_result_areas
          .map((key) => this.a3_document.kpi[key])
        )
      },
      invalid_actions() {
        if (this.invalid_org_areas == null) return null;

        return [].concat.apply([], ...this.invalid_org_areas
          .map((key) => this.a3_document.actions[key])
        )
      }
  },

  methods: {

    checkInvalidElements() {
        if (this.a3_document == null) return;
        // first, the kpi's
        let valid_result_areas = this.a3_document.meta.layout
          .structure.result_areas
          .map((x) => x.key);
        let kpi_result_areas = Object.keys(this.a3_document.kpi);
        let invalid_result_areas = kpi_result_areas.filter(
            (value) => !valid_result_areas.includes(value)
        );

      this.invalid_result_areas = invalid_result_areas.length > 0 ?
          invalid_result_areas : null ;

        // second, the actions
        let valid_org_areas = this.a3_document.meta.layout
          .structure.org_areas
          .map((x) => x.key);
        let action_org_areas = Object.keys(this.a3_document.actions);
        let invalid_org_areas = action_org_areas.filter(
            (value) => !valid_org_areas.includes(value)
        );

      this.invalid_org_areas = invalid_org_areas.length > 0 ?
          invalid_org_areas : null;
    },

    triggerSbfFocus(sbf) {
      this.$emit('setFocus', { 'type': 'sbf', sbf, });
    },

    triggerKpiFocus(kpi) {
      this.$emit('setFocus', { 'type': 'kpi', kpi, });
    },

    triggerActionFocus(action) {
      this.$emit('setFocus', { 'type': 'action', action, });
    },

    triggerActionMouseOver(action) {
        this.$emit('el_mouseover', { 'type': 'action', action, });
    },
    triggerActionMouseLeave(action) {
        this.$emit('el_mouseleave', { 'type': 'action', action, });
    },
  },
}
</script>


