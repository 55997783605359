<template>
    <WithMenuContainer>
    <div style="max-width: 1140px; margin: 0 auto;">
        <h1>2-Factor Authenticatie instellen</h1>

        <div class="qr-instruction-grid">

            <div>
                <h3>Stap 1: Download een 2-Factor authenticatie app</h3>
                <p>
                    Ondersteunde applicaties zijn o.a.:
                    <ul>
                        <li>Microsoft Authenticator</li>
                        <li>Google Authenticator</li>
                        <li>Authy</li>
                    </ul>
                    <em>En andere apps die TOTP ondersteunen</em>
                </p>

                <h3>Stap 2: Scan de QR-code met de app</h3>
                <p>
                    Klik in het menu van de app op: "Account toevoegen". Kies de optie
                    om een QR-code te kunnen scannen.
                </p>
                <p>
                    Gebruik vervolgens de camera om de QR-code aan de rechterkant te scannen.
                </p>

                <h3>Stap 3: Vul de 2-factor authenticatie code hier in en klik op opslaan</h3>
                <p>
                    Na het scannen is het account toegevoegd. Om te bevestigen dat het
                    goed is gegaan voer je hieronder de code uit de app in en klik je op "Opslaan"
                </p>

                <div class="form-field" style="margin-top: 30px; margin-bottom: 30px">
                    <label>Vul de 6-cijferige code in uit de app</label>
                    <input class="a3-input" v-model="token" placeholder="123 456" />
                </div>
                <div class="form-buttons">
                    <button class="a3-button action"
                            @click="submit2FA"
                        >
                        <i class="fas fa-check"></i> {{ $t('ui.general.save') }}
                    </button>

                    <button class="a3-button cancel"
                            @click="$router.go(-1)"
                        >
                        <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
                    </button>
                </div>
            </div>

            <div>
                <canvas v-show="twofa_url != null" id="2fa-qr"></canvas>
            </div>
        </div>

    </div>

    </WithMenuContainer>
</template>

<script>

import qrious from 'qrious';

export default {

    data: () => ({
        twofa_url: null,
        secret: null,
        token: null,
    }),

    mounted() {
        this.getAuthUrl();
    },

    watch: {
        twofa_url() {
            this.renderQR();
        },
    },

    methods: {

        getAuthUrl() {
            this.$http.post('2fa/generate')
                .then((res) => {
                    this.twofa_url = res.body.url;
                    this.secret = res.body.secret;
                });
        },

        submit2FA() {
            this.$http.post('2fa/device', {
                secret: this.secret,
                token: this.token,
            }).then((res) => {
                this.$router.push('/me/2fa');
            });
        },

        renderQR() {
            let qr = new qrious({
                size: 400,
                element: document.getElementById('2fa-qr'),
                value: this.twofa_url,
            });
        },
    },
}
</script>

<style lang="scss">

.qr-instruction-grid {
    display: grid;
    grid-template-columns: auto 400px;
    grid-column-gap: 50px;
}
</style>

