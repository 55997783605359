<template>
    <WithMenuContainer>
        <div>

            <h1>Nieuw licentie toevoegen</h1>
            <LicenseForm :license="license" :submitFunction="'createLicense'" />
            

        </div>
    </WithMenuContainer>
</template>

<script>

import LicenseForm from '@/views/manage/form/LicenseForm.vue';

export default {

    components: {
      LicenseForm        
    },
    props: {
    organisation_id: {
        type: String,
        required: true,
        },
    },

    data: () => ({
        license:{
            license_type:"",
            start_date:"",
            end_date:"",
            max_plans:0,
            active:true,
            organisation_id:""
        }
    }),
    computed: {
        
    },
    mounted() {
        
    },

    

    watch: {
        
    },

    methods: {
        createLicense(license){
            license.organisation_id = parseInt(this.organisation_id);
            license.max_plans = parseInt(license.max_plans);
            this.$http.post("license", license)
                .then((res) => {
                    this.$router.push(`/organisations/${this.organisation_id}`)
                })
        }
    },
}
</script>