<template>
  <div class="kpi-dashboard-report">
    <h2>{{
      result_area_definition.name ?
      result_area_definition.name :
      $t("definitions.result_area." + result_area)
      }}</h2>

    <template v-for="kpi in kpis">
        <KpiDashboardCard
              v-if="filterMap == null || filterMap.kpi == null || filterMap.kpi[kpi.id]"
              :key="kpi.id"
              :a3_document="a3_document"
              :kpi="kpi"
              :relations="relations" />
    </template>
  </div>
</template>

<script>
import KpiDashboardCard from '@/components/reports/KpiDashboardCard.vue'
import ResultAreaHelper from '@/mixins/ResultAreaHelper';

export default {
  mixins: [ResultAreaHelper],

  components: {
    KpiDashboardCard,
    ResultAreaHelper,
  },
  props: {
    result_area: {
      type: String,
      required: true
    },
    a3_document: {
      type: Object,
      required: true,
    },
    relations: {
        type: Object,
        required: true,
    },
    filterMap: {
        type: Object,
        required: false,
    },
  },

  computed: {
    kpis() {
      return this.a3_document.kpi[this.result_area];
    },

    result_area_definition() {
      return this.resultAreaFromLayout(
        this.a3_document.meta.layout,
        this.result_area
      );
    },
  },
};
</script>


<style lang="scss">

.kpi-result-area-report {
  h3 {
    margin-bottom: 5px;
  }
  margin-bottom: 30px;
}
.kpi-result-area-row {
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

@media print {
    .kpi-dashboard-report {
        page-break-inside: avoid;
        page-break-before: always;

        h2 {
            color: $a3-green;
            font-size: 52px;
        }
    }
}
</style>
