<template>
    <div class="element-form">
      <div class="form-buttons">
       <p v-if="is_ref">
           <em> {{ $t("ui.action.ref_form") }} </em>
        </p>
      </div>

      <div class="form-row">
        <div class="form-field">
            <label>{{ $t('ui.general.name') }} </label>
          <input v-model="action.name"
                 data-cy="input-name"
          class="a3-input" id="action-name-input" :disabled="is_ref" />

          <FormError :error="errors.name" />
        </div>
      </div>

      <div class="form-row" v-if="!is_ref">
        <h3>{{ $t('ui.a3_document.a3_links') }} </h3>
        <div class="form-field">
            <label>{{ $tc('definitions.terms.result') }}
              <br>
              <InfoBox
               v-if="action.sbf_id != null || action.result_area != null"
               tkey="forms.filter_active" top="1.5em" left="0"
              >
                <template v-slot:display>
                  <span class="form-label-notify">
                    <i class="fas fa-filter"></i>
                    {{ $t('forms.active_filter') }}
                  </span>
                </template>
              </InfoBox>
            </label>
            <select v-model="action.kpi_id"
                    data-cy="input-kpi"
                    class="a3-input"
                    id="action-kpi-input"
              >
              <option
              v-for="kpi in kpi_options" :value="kpi.id" :key="`option-kpi-${kpi.id}`">
                {{ kpi.name }}
              </option>
            </select>

          <FormError :error="errors.kpi" />
        </div>

        <div class="form-field">
            <label>{{ $t('definitions.short.sbf') }}
              <br>
              <InfoBox
               v-if="action.result_area != null && a3_layout.structure.sbf_result_mapping == 'one_to_one'"
               tkey="forms.filter_active" top="1.5em" left="0"
              >
                <template v-slot:display>
                  <span class="form-label-notify">
                    <i class="fas fa-filter"></i>
                    {{ $t('forms.active_filter') }}
                  </span>
                </template>
              </InfoBox>
            </label>
          <select v-model="action.sbf_id" class="a3-input"
                 data-cy="input-sbf"
              >
            <option :value="null">-</option>
            <option v-for="sbf in sbf_options" :value="sbf.id" :key="`option-sbf-${sbf.id}`">
              {{ sbf.name }}
            </option>
          </select>

          <FormError :error="errors.sbf" />
        </div>

        <div class="form-field">
            <label>{{ $t('definitions.terms.result_area') }} </label>
          <select v-model="action.result_area" class="a3-input"
                data-cy="input-result-area"
              >
            <option :value="null">-</option>
            <option v-for="result_area in result_area_options" :value="result_area.key" >
                {{ result_area.name ? result_area.name : $t(`definitions.result_area.${result_area.key}`) }}
            </option>
          </select>

          <FormError :error="errors.result_area" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-field" v-if="!is_ref">
            <label>{{ $t('definitions.terms.org_area') }} </label>
          <select v-model="action.org_area" class="a3-input"
              data-cy="org-area">
              <option v-for="org_area in org_area_options" :value="org_area.key" >
                  {{ org_area.name ? org_area.name : $t(`definitions.org_area.${org_area.key}`) }}
              </option>
          </select>

          <FormError :error="errors.org_area" />
        </div>
      </div>

      <div class="form-field" v-if="!is_ref">
          <label>{{ $t('ui.action.comment_result') }} </label>
        <div class="form-input">
          <RichTextEditor v-model="action.comment_result"
                          data-cy="input-comment-result"
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-field">
            <label>{{ $t('ui.action.assigned_to') }} </label>

            <div style="width: 60%; display: inline-block; box-sizing: border-box;">
               <multiselect
                    v-if="!is_ref"
                    id="action-assigned_to"
                    :value="assigned_to_multiselect"
                    @input="(value, id) => action.assigned_to = value ? value.id : null"
                   :options="people"
                   :placeholder="$t('ui.action.assigned_to')"
                   :selectLabel="$t('ui.general.enter_select')"
                   :selectedLabel="$t('ui.general.selected')"
                   :deselectLabel="$t('ui.general.enter_deselect')"
                   trackBy="id"
                   label="name" />
               <multiselect
                    v-else
                    id="action-assigned_to"
                    :value="assigned_to_multiselect"
                    @input="(value, id) => action.action_ref.assigned_to = value ? value.id : null"
                   :options="people"
                   :placeholder="$t('ui.action.assigned_to')"
                   :selectLabel="$t('ui.general.enter_select')"
                   :selectedLabel="$t('ui.general.selected')"
                   :deselectLabel="$t('ui.general.enter_deselect')"
                   trackBy="id"
                   label="name" />
            </div>
        </div>
        <div class="form-field">
          <label>{{ $t('ui.action.period') }}</label>

          <div style="display: inline-grid; grid-template-columns: auto auto;" class="subform-grid">
              <div>
                  <label>{{ $t('ui.action.start_date') }}</label><br>
                  <DateInput v-model="action.start_date" data-cy="input-start-date" v-if="!is_ref" />
                  <DateInput v-model="action.action_ref.start_date" data-cy="input-start-date" v-if="is_ref" />
              </div>
              <div>
                  <label>{{ $t('ui.action.target_date') }}</label><br>
                  <DateInput v-model="action.target_date" data-cy="input-target-date" v-if="!is_ref" />
                  <DateInput v-model="action.action_ref.target_date" data-cy="input-target-date" v-if="is_ref" />
              </div>
          </div>
        </div>
      </div>

      <div class="form-field" v-if="show_deploy">
        <label class="checkbox-label">{{ $t('ui.a3_document.push') }} ?</label>
        <CheckBox v-model="action.push_default" data-cy="push-checkbox" />

        <div class="input-pills" v-if="action.push_default">
          <template v-for="c in child_departments">
            <div
              :key="c.department.id"
              class="push-pill"
              v-if="c.a3_ref != null"
              tabindex="0"
              @click="toggleA3Doc(c)"
              @keyup.enter="toggleA3Doc(c)"
              :class="{'inactive': push_a3docs.find((x) => c.a3_ref && x == c.a3_ref.a3doc_id) == null }"
          >
              {{ c.department.name }}
            </div>
          </template>
          <div v-if="child_departments.filter(x => x.a3_ref != null).length == 0">
              <em>{{ $t('ui.a3_document.no_subplans') }} </em>
          </div>
          <div v-if="pending_ref_delete">
            <InlineErrorBox error_key="action.subplan_delete" severity="warning" />
          </div>
        </div>
      </div>

      <div class="form-buttons">
          <button class="a3-button action" @click="submit"
                  data-cy="submit"
                  id="action-submit-button"
            >
              <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
          </button>

          <button class="a3-button cancel"
                  data-cy="cancel"
                  @click="$emit('cancel')">
              <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
          </button>
      </div>
    </div>
</template>


<script>

import RichTextEditor from '@/components/forms/RichTextEditor';
import WithDocumentManager from '@/mixins/WithDocumentManager.js';

import Multiselect from 'vue-multiselect';

export default {
  mixins: [WithDocumentManager],
  components: {
    RichTextEditor,
    Multiselect,
  },

  props: {
    action: {
      type: Object,
      required: true,
    },
    show_deploy: {
      type: Boolean,
      required: false,
      default: true,
    },
    child_departments: {
      type: Array,
      required: true,
    },
    push_a3docs: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    people: [],
    errors: {
      name: [],
      kpi: [],
      sbf: [],
      result_area: [],
      org_area: [],
    },
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    },
    assigned_to_multiselect() {
        if (this.is_ref) {
            return this.people.find(x => x.id == this.action.action_ref.assigned_to) || null;
        }
        return this.people.find(x => x.id == this.action.assigned_to) || null;
    },
    pending_ref_delete() {
      // For each child, check if it is pending to delete
      // then reduce the result
      return this.action.child_refs
        .map((child) => {
          if (!child.a3doc_id) return false;

          let is_pending_delete = this.push_a3docs
            .find((y) => y == child.a3doc_id) == null;
          return is_pending_delete;
        })
        .reduce((a, b) => a || b, false);
    },
    sbf() {
      if (this.action.sbf_id == null) return null;
      if (this.a3_document == null) return null;

      return this.dataManager
        .getSbfList()
        .filter(x => x.id == this.action.sbf_id)[0];
    },
    sbf_options() {
      if (this.a3_document == null) return [];

      if (this.action.result_area != null
          && this.a3_layout.structure.sbf_result_mapping == 'one_to_one') {
        return this.dataManager.getSbfList()
          .filter((sbf) => sbf.result_area == this.action.result_area);
      }

      return this.a3_document.sbf;
    },

    org_area_options() {
        return this.a3_document.meta
            .layout.structure.org_areas;
    },
    result_area_options() {
        return this.dataManager.getResultAreas();
    },

    kpi() {
      if (this.action.kpi_id == null) return null;
      if (this.a3_document == null) return null;

      return this.kpis
        .filter(x => x.id == this.action.kpi_id)[0];
    },

    kpis() {
      return this.dataManager.getKpiList();
    },
    kpi_options() {
      return this.kpis
        .filter((kpi) => {
          return this.action.result_area == null ||
            kpi.result_area == this.action.result_area
        })
        .filter((kpi) => {
          return this.action.sbf_id == null || kpi.sbf_id == this.action.sbf_id
        })
      ;
    },

    is_ref() {
        return this.action.action_ref != null;
    }
  },

  watch: {
    sbf: function () {
      if (this.sbf == null) return;

      if (this.a3_layout.structure.sbf_result_mapping == 'one_to_one') {
        this.action.result_area = this.sbf.result_area;
      }
    },

    kpi: function () {
      if (this.kpi == null) return;

      this.action.sbf_id = this.kpi.sbf_id;
      this.action.result_area = this.kpi.result_area;
    },

    'action.sbf_id': function(newSbfId) {
      if (this.kpi.sbf_id != newSbfId) {
        this.action.kpi_id = null;
      }
    },
    'action.result_area': function(value) {
      if (this.a3_layout.structure.sbf_result_mapping == 'one_to_one') {
        if (this.sbf.result_area != value) {
          this.action.sbf_id = null;
        }
      }
    },
    'action.target_date': function(d) {
        if (d == "") { this.action.target_date = null; }
    },
    'action.action_ref.target_date': function(d) {
        if (d == "") { this.action.action_ref.target_date = null; }
    },

    'action.assigned_to': function() {
      this.action.assigned_to_person = this.people
        .filter(x => x.id == this.action.assigned_to)[0];
    },
  },

  mounted() {
    this.loadPeople();

    document.querySelector('#action-name-input').focus();
  },

  methods: {
    validate() {
      if (this.action.name == null || this.action.name.length == 0) this.errors.name.push("may_not_be_null");
      if (this.action.kpi_id == null) this.errors.kpi.push("may_not_be_null");
      if (this.action.sbf_id == null) this.errors.sbf.push("may_not_be_null");
      if (this.action.result_area == null) this.errors.result_area.push("may_not_be_null");
      if (this.action.org_area == null) this.errors.org_area.push("may_not_be_null");

      return Object.values(this.errors)
        .flat()
        .length == 0;
    },

    submit() {
      // reset the errors
      for (let x of Object.keys(this.errors)) {
        this.errors[x] = [];
      }

      if (this.validate()) {
        this.$emit('submit');
      }
    },

    loadPeople() {
      this.$http.get(`organisation/people`)
        .then(res => this.people = res.body);
    },

    toggleA3Doc(child_ref) {
      if (!child_ref.a3_ref) { return ; }
      let a3doc_id = child_ref.a3_ref.a3doc_id;

      let in_array = this.push_a3docs.findIndex(x => x == a3doc_id);
      if (in_array == -1) {
        this.push_a3docs.push(a3doc_id);
      } else {
        this.push_a3docs.splice(in_array, 1);
      }
    }
  },

}
</script>
