
import SupportView from "@/views/SupportView.vue";
import Me from "@/views/Me.vue";

import UserSettings from "@/views/user/UserSettings.vue";
import NotificationSettings from "@/views/user/NotificationSettings.vue";
import A3DisplayUserSettings from "@/views/user/A3DisplaySettings.vue";
import SecuritySettings from "@/views/user/SecuritySettings.vue";
import UserPreferences from "@/views/user/UserPreferences.vue";

import TwoFactorAuthAddDevice from "@/views/user/2FAAddDevice.vue";

export default [
  { path: "/me", name: "me", component: Me},
  {
      path: "/me/settings",
      name: "user-settings",
      component: UserSettings,
      children: [
          {
              path: "preferences",
              name: "user-settings-preferences",
              component: UserPreferences,
          },
          {
              path: "security",
              name: "user-settings-security",
              component: SecuritySettings,
          },
          {
              path: "notifications",
              name: "user-settings-notifications",
              component: NotificationSettings,
          },
          {
              path: "a3_display",
              name: "user-settings-a3-display",
              component: A3DisplayUserSettings,
          },
      ],
  },
  { path: "/me/2fa/add", name: "2fa-add", component: TwoFactorAuthAddDevice },
];
