<template>
  <div>
    <div class="form-buttons">
        <button class="a3-button action" @click="submit">
            <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
        </button>

        <InfoBox tkey="layout_save_and_use_explanation" top="30px" left="0">
          <template v-slot:display>
            <button class="a3-button action" @click="submit_and_use">
                <i class="fas fa-check-circle"></i> {{ $t('ui.layout.save_and_use') }}
            </button>
          </template>
        </InfoBox>

        <template v-if="layout.id">
          <button class="a3-button danger" @click="$emit('delete')">
              <i class="fas fa-trash"></i> {{ $t('ui.general.delete') }}
          </button>
        </template>

        <button
                 v-if="$hasFeature('Root') && layout.id"
                 class="a3-button action" @click="triggerDownload">
            <i class="fas fa-download"></i> {{ $t('ui.general.download') }}
        </button>

        <button class="a3-button cancel" @click="$emit('cancel')">
            <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
        </button>
    </div>

    <div class="tab-container">
      <div class="year-tab"
        :class="{'selected': selected_tab == 'general' }"
         @click="selected_tab = 'general'"
        >
        {{ $t('ui.layout.general') }}
      </div>
      <div class="year-tab"
        :class="{'selected': selected_tab == 'structure' }"
         @click="selected_tab = 'structure'"
        >
        {{ $t('ui.layout.structure') }}
      </div>
      <div class="year-tab"
        :class="{'selected': selected_tab == 'style' }"
         @click="selected_tab = 'style'"
        >
        {{ $t('ui.layout.style') }}
      </div>
      <div class="year-tab"
        :class="{'selected': selected_tab == 'title' }"
         @click="selected_tab = 'title'"
        >
        {{ $t('ui.layout.titles') }}
      </div>
      <div class="year-tab"
        :class="{'selected': selected_tab == 'descriptions' }"
         @click="selected_tab = 'descriptions'"
        >
        {{ $t('ui.layout.descriptions') }}
      </div>
    </div>

      <template v-if="layout != null">
        <GeneralForm :layout="layout"
                :errors="errors"
                v-if="selected_tab == 'general'" />
        <StructureForm :structure="layout.structure"
                :errors="errors"
                v-if="selected_tab == 'structure'" />
        <TitleForm
                :titles="layout.titles"
                :layout="layout"
                :errors="errors"
                v-if="selected_tab == 'title'" />
        <DescriptionForm
                :descriptions="layout.descriptions"
                :layout="layout"
                :errors="errors"
                v-if="selected_tab == 'descriptions'" />
        <div v-if="selected_tab == 'style'" style="display: inline-block">
          <StyleForm :layout_style="layout.style" :errors="errors" />
          <div style="margin-top: 40px; margin-left: 50px">
            <em>Preview</em><br>
            <LayoutPreviewBox :layout="layout" />
          </div>
        </div>
      </template>
    </div>
</template>

<script>

import TitleForm from './TitleForm.vue';
import GeneralForm from './GeneralForm.vue';
import StructureForm from './StructureForm.vue';
import StyleForm from './StyleForm.vue';
import DescriptionForm from './DescriptionForm.vue';

import LayoutPreviewBox from '@/components/layout/LayoutPreviewBox.vue';

export default {
  components: {
    TitleForm,
    GeneralForm,
    StructureForm,
    DescriptionForm,
    StyleForm,
    LayoutPreviewBox
  },

  props: {
    layout: {
      type: Object,
      required: true,
    },
  },

    data: () => ({
      selected_tab: 'general',
      errors: {
        titles: {},
        descriptions: {},
        _style: {},
        structure: {},
      },
    }),

  mounted() {
      this.$nextTick(() => {
          document.querySelector('#layout-name-input').focus();
      });
  },

  methods: {
    validate() {
      return Object.values(this.errors)
        .flat()
        .length == 0;
    },

    triggerDownload() {
        let shadowObject = JSON.parse(JSON.stringify(this.layout));
        delete shadowObject['id'];
        delete shadowObject['organisation_id'];
        delete shadowObject['logo_file_id'];
        delete shadowObject['created'];
        delete shadowObject['deleted'];
        delete shadowObject['style']['image_preview'];
        delete shadowObject['style']['logo_file'];

        let blobToSave = new Blob([JSON.stringify(shadowObject)], {
            type: 'application/json'
        });

        let name = this.layout.name;

        let a = document.createElement('a');
        a.download = `${name}.a3l`;
        a.rel = 'noopener';
        a.href = URL.createObjectURL(blobToSave);
        a.dispatchEvent(new MouseEvent('click'));
    },

    submit() {
      // reset the errors
      for (let x of Object.keys(this.errors)) {
        this.errors[x] = [];
      }

      if (this.validate()) {
        this.$emit('submit');
      }
    },
    submit_and_use() {
      // reset the errors
      for (let x of Object.keys(this.errors)) {
        this.errors[x] = [];
      }

      if (this.validate()) {
        this.$emit('submit_and_use');
      }
    },
  },
}
</script>
