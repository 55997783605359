<template>
    <div class="element-form"
      style="display: inline-block; width: 1000px; vertical-align: top; padding-left: 15px;">

      <div class="form-row">
        <div class="form-field">
          <label>{{ $t('ui.general.name') }} </label>
          <input v-model="layout.name"
                 class="a3-input"
          />
            <FormError :error="errors.name" />
        </div>
      </div>

      <div class="form-row"
           style="margin-top: 40px">
          <h3>{{ $t('ui.layout.options') }}</h3>

          <div class="form-field">
              <label>{{ $t('ui.layout.mood_icons') }}</label>
              <CheckBox v-model="layout.options.mood_icons" />
          </div>
          <div class="form-field">
              <label>{{ $t('ui.layout.report_groups') }}</label>
              <CheckBox v-model="layout.options.report_groups" />
          </div>
          <div class="form-field">
              <label>{{ $t('ui.layout.steering_check') }}</label>
              <CheckBox v-model="layout.options.steering_check" />
          </div>

          <div class="form-field">
              <label>{{ $t('ui.layout.kpi_evaluation_type') }}</label>
              <select v-model="layout.options.kpi_evaluation_type">
                  <option>-</option>
                  <option :value="'stars'">{{ $t('ui.layout.evaluation_type.stars') }}</option>
                  <option :value="'smileys'">{{ $t('ui.layout.evaluation_type.smileys') }}</option>
              </select>
          </div>
      </div>

    </div>
</template>

<script>

export default {
  props: {
    layout: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },

  mounted() {
      if (this.layout.options == null) {
          this.initOptions();
      }
  },

  methods: {
      initOptions() {
          this.$set(this.layout, 'options', {
              mood_icons: false,
              report_groups: true,
              steering_check: true,
          })
      },
  },
}
</script>
