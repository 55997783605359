<template>
  <div id="notification-bar">
    <div style="float: right;">
      <div
        style="display: inline-block; float:none;"
        class="notification-close-button"
        @click="$router.push('/me/settings/notifications')"
        ><i class="fas fa-cog"></i>
      </div>
      <div
        style="display: inline-block; float:none;"
        class="notification-close-button"
        @click="$emit('close')"
        ><i class="fas fa-times"></i>
      </div>
    </div>



    <h3>
        <i class="fas fa-bell"></i>
        {{ $t('ui.notifications.notifications') }}
    </h3>

    <template v-if="selected_notification == null">
        <div>
            <span
                class="mark-read-button"
                v-if="notifications && notifications.length > 0"
                @click="markAllRead"
                ><i class="fas fa-check"></i>
                {{ $t('ui.notifications.mark_all_read') }}
            </span>

        </div>

        <div class="notification-container">
            <div v-for="(n, index) in notifications"
                 :key="index"
                 class="notification"
                 :class="{'new': n.read_at == null}"
                 @click="selectNotification(n)"
                >
                <div class="title">{{ n.title }}</div>
                <div class="subtitle">{{ n.created_at | formatDate }}</div>

                <div v-if="n.read_at == null" class="indicator-new"> </div>
            </div>

            <div v-if="!notifications || notifications.length == 0"
                 style="margin-top: 40px; text-align: center;"
                >
                <em style="opacity: .8">{{ $t('ui.notifications.no_notifications') }}</em>
            </div>
        </div>
    </template>

    <template v-else>
        <div>
            <span
                class="mark-read-button"
                @click="selected_notification = null"
                ><i class="fas fa-chevron-left"></i>
                {{ $t('ui.general.back') }}
            </span>
        </div>

        <div class="notification-content">
            <h2>{{ selected_notification.title }}</h2>
            <p>
                {{ selected_notification.content }}
            </p>

            <div class="notification-buttons">
                <button class="a3-button action inverted"
                        @click="$router.push(selected_notification.action_url)"
                        v-if="selected_notification.action_url"
                    >
                    <i class="fas fa-chevron-right"></i>
                    Bekijk
                </button>

                <button class="a3-button action danger"
                        @click="deleteNotification(selected_notification.id)"
                    >
                    <i class="fas fa-times"></i>
                    {{ $t('ui.general.delete') }}
                </button>
            </div>
        </div>
    </template>


  </div>
</template>

<script>

import moment from 'moment'

export default {

  data: () => ({
      notifications: [],
      selected_notification: null,
  }),

  mounted() {
      this.loadNotifications();
  },

  filters: {
    formatDate(val) {
      return moment
        .utc(val)
        .local()
        .format('DD-MM-YYYY - HH:mm');
    }
  },

  methods: {
      markAllRead() {
          this.$http.put('notifications/read_all').then((res) => {
              for (let n of this.notifications) { n.read_at = true; }
              this.$emit('updateCountNew', 0);
          });
      },

      loadNotifications()  {
          this.$http.get('notifications').then((res) => {
              this.notifications = res.body;
              this.updateReadCount();
          });
      },

      updateReadCount() {
          let c = this.notifications
            .filter((x) => x.read_at == null)
            .length;
          this.$emit('updateCountNew', c);
      },

      selectNotification(notification) {
          this.$http.put(`notifications/${notification.id}/read`);
          // TODO: Can we safely ignore this result?
          notification.read_at = moment().toISOString();
          this.selected_notification = notification;
          this.updateReadCount();
      },

      deleteNotification(notification_id) {
          this.$http.delete(`notifications/${notification_id}`)
            .then((res) => {
                this.loadNotifications();
                this.selected_notification = null;
            })
      }
  },

};
</script>

<style lang="scss">

#notification-bar {
    position: absolute;
    padding: 20px;
    box-sizing: border-box;
    min-height: calc(100vh - 50px);
    top: 50px;
    right: 0;
    bottom: 0;
    width: 450px;
    background-color: $a3-green;
    color: white;

  -webkit-box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.5);
  box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.5);
  border-bottom: 1px $green-darkened solid;
  border-left: 1px $green-darkened solid;

  transform-origin: top;
  animation: menuSlideDown .2s ease-in-out;

  border-bottom-left-radius: 15px;

  .notification-buttons {
      font-size: .8em !important;
      margin-top: 30px;
  }

  .notification-container {

      margin-top: 20px;
      height: calc(100% - 100px);
      overflow-y: auto;
  }

   .notification {
       position: relative;
       padding: 10px;
       padding-left: 25px;
       border: 1px rgba(white, .3) solid;
       border-bottom: none;
       opacity: .5;
       cursor: pointer;

       transition: color .15s ease-out,
           background-color .15s ease-out,
           opacity .15s ease-out
       ;

       &.new {
           opacity: 1;
       }

       .indicator-new {
           position: absolute;
           left: 8px;
           top: 13px;
           opacity: .9;
           border-radius: 50%;
           right: 20px;
           height: 12px;
           width: 12px;
           background-color: $a3-orange;
           z-index: 50;
       }

       &:hover {
           background-color: white;
           color: $a3-green;
           opacity: 1;
       }

       &:first-child {
           border-top-right-radius: 10px;
           border-top-left-radius: 10px;
       }
       &:last-child {
           border-bottom: 1px rgba(white, .3) solid;
           border-bottom-right-radius: 10px;
           border-bottom-left-radius: 10px;
       }

       .title {
           position: relative;
           font-weight: bold;
           z-index: 100;
       }

       .subtitle {
           opacity: .6;
       }
   }

   .mark-read-button {
       opacity: .6;
       cursor: pointer;
       transition: opacity .15s ease-out;

       &:hover {
           opacity: 1;
       }
   }



    .notification-close-button {
      cursor: pointer;
      display: inline-block;
      width: 30px;
      height: 30px;
      padding: 0;
      margin: 0;
      text-align: center;
      border-radius: 50%;
      line-height: 32px;
      vertical-align: middle;
      transition: color .15s ease-out, background-color .15s ease-out;

      &:hover {
        color: $a3-green;
        background-color: white;
      }
    }

}
</style>
