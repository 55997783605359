<template>
  <div id="loading-login-container">
    <i class="fas fa-spinner fa-spin fa-4x"></i> <br>
    Gebruikersgevens aan het ophalen ...

    <ErrorOverlay v-if="show_license_error"
                  title="Uw licentie is verlopen"
                  @close="continueRedirect"
    >
      Je kunt nog wel al je A3'tjes bekijken, maar niet meer
      bewerken, exporteren of je organisatie aanpassen.

      <p>
        Wil je graag verder? Je kunt A3 online bestellen via de bestelpagina.
        <a href="https://www.a3online.nl/bestellen" target="_blank">
          www.a3online.nl/bestellen
        </a>
      </p>
    </ErrorOverlay>

  </div>
</template>

<script>

import Vue from 'vue';

export default {

  computed: {
    redirect_url() {
      return this.$route.query['r'];
    },
  },

  data: () => ({
    show_license_error: false,
  }),

  mounted() {
    this.logInUsingToken();
  },

  methods: {
    logInUsingToken() {
      const token = window.localStorage.getItem('auth_token');
      this.$store.commit('auth/setToken', token);

      Vue.http.headers.common['Authorization'] = `Bearer ${token}`;

      this.$http.get('me').then((res) => {
          this.$store.commit('auth/setPerson', res.body.person);
          this.$store.commit('auth/setOrganisation', res.body.organisation);
          this.$store.commit('auth/setAccount', res.body.account);
          this.$store.commit('auth/setFeatures', res.body.features);
          this.$store.commit('auth/setLicense', res.body.license);

          this.$store.commit('settings/loadSettings', res.body.settings);

          if (res.body.license == null) {
            this.show_license_error = true;
            return;
          }

          this.loadNotificationCount();

          this.$router.replace(this.redirect_url);
      }, () => {
          window.localStorage.removeItem('auth_token');
          delete this.$http.headers.common['Authorization'];
          this.$router.push('/login');
      });
    },

    loadNotificationCount() {
      return this.$http.get('notifications/count_unread')
        .then((res) => {
          this.$store.commit('settings/setNotificationCount', res.body);
        })
    },

    continueRedirect() {
      this.$router.replace(this.redirect_url);
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/variables.scss";

#loading-login-container {
  color: $a3-green;
  text-align: center;

  i {
    display: block;
    margin-bottom: 10px;
    margin-top: 30px;
  }
}
</style>
