<template>
  <div>
      <div class="import-excel-upload-container">
          <div v-html="$t('ui.people_import.excel_explanation')">
          </div>

          <div>
              <h3>Selecteer bestand</h3>

            <form >
              <input type="file"
                     id="import-people-upload"
                     class="upload-form-input"
                     @change="loadExcel"
                     />
            </form>
          </div>
      </div>

  </div>
</template>


<script>


export default {


  methods: {
    loadExcel(ev) {
      if (ev.target.files.length == 0) { return; }

      let input_file = ev.target.files[0];
      let reader = new FileReader();

      let _this = this;

      reader.onload = (e) => {
        let data = new Uint8Array(e.target.result);
        let workbook = XLSX.read(data, { type: 'array'});

        let sheet = workbook.Sheets[workbook.SheetNames[0]];
        let people = XLSX.utils.sheet_to_json(sheet);

        _this.$emit('input', people);
      }
      reader.readAsArrayBuffer(input_file);
    },
  },
};
</script>


<style lang="scss">
.import-excel-upload-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    max-width: 1200px;

    p { margin-bottom: 20px; }

    h3 {
        color: $a3-green;
        margin-bottom: 10px;
    }

    input[type="file"] {
        width: 100%;
        height: 50px;
        line-height: 50px;
    }
}
</style>
