<template>
  <div class="a3-login-container">
    <div class="background-image"></div>
    <div class="login-inner">
      <h1 style="float: left">Nieuw wachtwoord instellen</h1>

      <div style="text-align: right">
        <img src="@/public/img/a3_online_logo.png" class="login-logo"/>
      </div>

      <div class="error-box" v-show="errors.length > 0 || server_error"
                             style="text-align: left"
        >
        <strong>Wachtwoord kan niet veranderd worden
          <template v-if="server_error"> (fout tijdens opslaan op server)</template>
        </strong>
        <ul>
          <li v-for="(error, index) in errors" :key="index" >
            {{ error }}
          </li>
        </ul>
      </div>


      <div class="element-form" v-if="!completed">
        <div class="login-form-field">
          <label><i class="fas fa-key"></i> Wachtwoord</label>
          <input v-model="form.password"
            class="a3-input" type="password" placeholder="Voer een wachtwoord in"/>
        </div>

        <div class="login-form-field">
          <label><i class="fas fa-key"></i> Herhaal wachtwoord</label>
          <input v-model="form.repeat_password"
            class="a3-input" type="password" placeholder="Herhaal het wachtwoord in"/>
        </div>

        <InlineErrorBox v-if="error_key != null" :error_key="error_key" />

        <div class="form-buttons">
          <button type="submit" class="login-button" @click="sendRequest">
            <i class="fas fa-check"></i> Wachtwoord wijzigen
          </button>
        </div>
      </div>
      <div v-else>
        Uw wachtwoord is succesvol gewijzigd!
        <router-link to="/login">Klik hier om in te loggen.</router-link>
      </div>

      <LoadingOverlay :loading="loading" />
    </div>
  </div>
</template>

<script>

import InlineErrorBox from '@/components/elements/InlineErrorBox.vue';

export default {
  components: {
    InlineErrorBox,
  },

  data: () => ({
    token: '',
    form: {
      password: '',
      repeat_password: '',
    },
    loading: false,
    errors: [],
    completed: false,
  }),

  mounted() {
    this.token = this.$route.query['token'];
  },

  methods: {
    sendRequest() {
      this.validatePassword();
      if (this.errors.length > 0) { return; }

      this.loading = true;
      this.$http.post('reset_password', { ...this.form, token: this.token })
        .then(() => {
          this.loading = false;
          this.completed = true;
        }, (err) => {
          this.loading = false;
          if (err.status === 0) {
            this.error_key = 'general.not_available';
          } else if (err.status === 500) {
            this.error_key = 'general.server_error';
          } else {
            this.error_key = 'general.generic';
          }
        });
    },

    validatePassword() {
      this.errors = [];
      if (this.form.password !== this.form.repeat_password) {
        this.errors.push("Wachtwoord herhaling is niet hetzelfde als nieuw wachtwoord");
      }
      if (this.form.password.length < 12) {
        this.errors.push("Wachtwoord moet minimaal 12 tekens bevatten");
      }
    },
  },
};
</script>


<style lang="scss"></style>
