<template>
  <div class="element-form">
      <h2 class="element-form-title">
        <i class="fas fa-tachometer-alt"></i>
        {{ $t('ui.kpi.create') }}
      </h2>

      <KpiForm :kpi="kpi"
           :dataManager="dataManager"
           :show_deploy="dataManager.getCapability('kpi.deploy')"
           :child_departments="relations.children"
           :management_groups="management_groups"
           :push_a3docs="push_a3docs"
           @submit="saveKpi"
           @cancel="$router.go(-1)"
           />
  </div>
</template>

<script>

import KpiForm from '@/components/forms/KpiForm.vue';
import ManagementGroups from '@/mixins/ManagementGroups.js';

import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  mixins: [ManagementGroups, WithDocumentManager],

  components: {
    KpiForm,
  },

  data: () => ({
    kpi: {
      name: '',
      sbf_id: null,
      result_area: null,
      unit: 'number',
      is_steering_kpi: true,
      agg_type: 'Average',
      target_min: null,
      target_max: null,
      comment_analysis: null,
      management_group_ids: [],
      push_default: false,
      kpi_type: "kpi",
    },
    push_a3docs: [],
  }),

  mounted() {
    this.$UiEventBus.$emit('shown', {key: 'kpi-create'});

    if (this.$route.query['result_area']) {
      this.kpi.result_area = this.$route.query['result_area'];
    }
    if (this.$route.query['sbf_id']) {
      this.kpi.sbf_id = parseInt(this.$route.query['sbf_id'], 10);
    }
  },

  methods: {
    saveKpi() {
      this.dataManager
        .createKpi(this.kpi, this.push_a3docs)
        .then(() => {
          this.$UiEventBus.$emit('submit', {key: 'kpi-create'});
          this.interactionManager.backToDocument();
        });
    },
  },
};
</script>
