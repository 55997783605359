<template>
  <div id="a3-top-menu-container" key="main-menu">
    <div class="a3-top-menu-logo" @click="$router.push('/')" >
        <img src="@/public/img/a3_online_logo_inverted.png" class="header-logo" />
    </div>

    <div
      v-for="(l, link_index) in links"
      :key="'main-menu-link-' + link_index"
      class="a3-tab"
      :class="{'active': $route.path.startsWith(l.active_slug)}"
    >
      <div :id="'menu-bar-' + l.id" @click="subitemClicked(l)" >
        <i style="margin-right: 5px" class="fas" :class="'fa-' + l.icon" /> {{ $t(l.name) }}
      </div>

      <div class="a3-tab-subitems">
        <div
          v-for="(subitem, sublink_index) in l.subitems"
          :key="'submenu-' + link_index + '-link-' + sublink_index"
          class="a3-tab-subitem"
          @iclick="subitemClicked(subitem)"
        >
          <i class="fas" :class="'fa-' + subitem.icon" /> {{ subitem.name }}
        </div>
      </div>
    </div>

      <div class="a3-tab" style="float: right; min-width: 0;"
                          v-if="$v2Enabled()">
          <i class="fas fa-rocket"
             style="position: absolute; transform: scale(3); z-index: 0;
                    opacity: .3;top: 15px;"
              ></i>
          v2
      </div>
      <div class="a3-tab" style="float: right" @click="$router.push('/me')">
          <i class="fas fa-user"></i> {{ user_name }}
      </div>
      <div class="a3-tab"
           id="menu-notifications"
           :class="{'active': show_notification_bar}"
            style="float: right" @click="show_notification_bar = !show_notification_bar">
           <i class="fas fa-bell"></i>
           <div class="notification-counter"
                :class="{'hidden': notification_count == 0}"
               >
               {{ notification_count }}
           </div>
      </div>
      <div class="a3-tab"
           style="float: right"
           @click="show_organisation_selector = !show_organisation_selector"
           v-if="account.advisor_account">
          <em>
              <i class="fas fa-chevron-right"
                  :class="{'fa-rotate-90': show_organisation_selector}"
                  style="transition: transform .15s ease-in-out"
                  ></i>
              {{ organisation.name }}
          </em>
      </div>
      <div  class="a3-tab"
            style="float: right" 
            v-if="$hasFeature('Root')"
            @click="$router.push('/organisations')">
        <em>
              <i class="fas fa-building"></i>
              Organisaties
          </em>
      </div>
      <div class="a3-tab"
           id="about-menu-button"
            style="float: right" @click="$router.push('/support/manual')">
           <i class="fas fa-book"></i> {{ $t('ui.nav.support') }}
      </div>
      <div class="a3-tab" style="float: right" v-if="
        license && license.license_type == 'l1_trial'">
        Try-Out
        <small>{{ $t('license.notifications.days_left', [days_left]) }} </small>
    </div>


    <NotificationBar
            v-if="show_notification_bar"
            @close="show_notification_bar = false"
            @updateCountNew="updateNotificationCount"
            />

    <AdvisorOrganisationSelector v-if="show_organisation_selector"
                                 @close="show_organisation_selector = false"
    />

    <div class="a3-mobile-menu-button a3-button action"
         :class="{'active': show_mobile_menu}"
         @click="show_mobile_menu = !show_mobile_menu"
      >
      <i class="fas fa-bars"></i>
    </div>

    <div class="a3-mobile-menu"
         :class="{'visible': show_mobile_menu}"
      >
      <div class="a3-mobile-tab"
        v-for="(l, link_index) in links"
        :key="link_index"
        @click="subitemClicked(l)"
        >
        <i style="margin-right: 5px" class="fas" :class="'fa-' + l.icon" /> {{ $t(l.name) }}
      </div>
      <div class="a3-mobile-tab" v-if="
        license && license.license_type == 'l1_trial'">
        Try-Out
        <small>{{ $t('license.notifications.days_left', [days_left]) }} </small>
      </div>
      <div class="a3-mobile-tab"
           id="about-menu-button"
           @click="$router.push('/about')">
          {{ $t('ui.nav.about_a3_online') }}
      </div>
      <div class="a3-mobile-tab" @click="$router.push('/me')">
          <i class="fas fa-user"></i> {{ user_name }}
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment';

import AdvisorOrganisationSelector from '@/components/nav/AdvisorOrganisationSelector.vue';
import NotificationBar from '@/components/nav/NotificationBar.vue';

export default {
    name: 'MainTabMenu',
    components: {
      AdvisorOrganisationSelector,
        NotificationBar,
    },
    data: () => ({
      show_mobile_menu: false,
      show_notification_bar: false,
      show_organisation_selector: false,
        links: [
            {
                url: '/a3',
                name: 'ui.nav.my_plans',
                id: 'my_plans',
                icon: 'paperclip',
                active_slug: '/a3',
            },
            {
                url: '/organisation',
                name: 'ui.nav.my_organisation',
                id: 'mijn-organisatie',
                icon: 'sitemap',
                active_slug: '/organisation',
            },
        ],

    }),

    computed: {
        notification_count() {
          return this.$store.getters['settings/notification_count'];
        },

        user_name: function () {
          let person = this.$store.getters['auth/person'];
          if (person) { return person.name; }

          return this.$store.getters['auth/account'].mail;
        },
        account() {
          return this.$store.getters['auth/account'];
        },
        license: function () {
          return this.$store.getters['auth/license'];
        },
        days_left: function()  {
            let d = moment(this.license.end_date);
            return d.diff(moment(), 'days') + 1;
        },
        organisation() {
            return this.$store.getters['auth/organisation'];
        },
    },

    methods: {
        subitemClicked(subitem) {
            if ('onclick' in subitem) {
                subitem.onclick.bind(this)();
            } else {
                this.$router.push(subitem.url);
            }
        },

        updateNotificationCount(ev) {
            this.$store.commit('settings/setNotificationCount', ev);
        },
    },
};
</script>

<style lang="scss">
@import "../../assets/variables.scss";

#a3-top-menu-container {
    background-color: $a3-green;
    border-bottom: 1px $green-darkened solid;
    user-select: none;
    -webkit-box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.5);
    box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.5);
    position: relative;

    z-index: 2000;
}

@media print  {
  #a3-top-menu-container {
    display: none !important;
  }
}

.a3-top-menu-logo {
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
    width: 90px;
    font-size: 16px;
    display: inline-block;
    height: $top-menu-height;
    line-height: $top-menu-height;
    cursor: pointer;
    color: white;
    vertical-align: top;
    padding-top: 0;
    padding-bottom: 0;
    overflow-y: hidden;

    text-align: center;
    img {
        height: 40px;
        margin-top: 5px;
        marin-bottom: 5px;
    }

    &:hover {
        background-color: $green-darkened;
    }
}


@media screen and (max-width: 873px) {
  .a3-tab {
    display: none !important;
  }
  .a3-mobile-menu-button {
    display: inline-block !important;
  }
}
@media screen and (min-width: 873px) {
  .a3-mobile-menu {
    display: none !important;
  }
}

.a3-mobile-menu-button {
  float: right;
  &.a3-button {
    display: none;
  }
}

.a3-mobile-menu.visible {
  display: inline-block;
  pointer-events: all;

  .a3-mobile-tab {
    pointer-events: all;
    transform: translateY(0);
    opacity: 1;
  }
}

.notification-counter {
    position: absolute;
    display: block;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background-color: $general-red;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    font-size: 13px;

    transition: transform .15s ease-in-out,
        opacity .15s ease-in-out;

    &.hidden {
        transform: scale(0);
        opacity: 0;
    }
}



.a3-mobile-menu {
  position: absolute;
  left: 0;
  top: 51px;
  width: 100%;
  pointer-events: none;

  .a3-mobile-tab {
    background-color: $a3-green;
    pointer-events: none;
    z-index: -100;
    box-sizing: border-box;
    color: white;
    display: block;
    width: 100%;
    padding: 15px 20px;
    cursor: pointer;
    z-index: 0;

    opacity: 0;
    transform: translateX(150px);
    transition: transform .3s ease-out, opacity .3s ease-out;

    &:hover, &.active {
        background-color: $green-darkened;
        color: white;
    }
    i {
        color: lighten($a3-green, 40%);
    }

    &:nth-child(2) {
      transition-delay: .05s;
    }
    &:nth-child(3) {
      transition-delay: .1s;
    }
    &:nth-child(4) {
      transition-delay: .15s;
    }
  }
}

.a3-tab {
    font-size: 16px;
    cursor: pointer;

    display: inline-block;
    height: $top-menu-height;
    min-width: 100px;
    line-height: $top-menu-height;
    padding-left: 20px;
    padding-right: 20px;
    background-color: $a3-green;
    color: white;
    text-align: center;
    position: relative;

    transition: background-color .15s ease-out, color .15s ease-out;

    &#menu-notifications {
        min-width: 0px;

        i {
            transition: color .15s ease-out;
        }
        &:hover i {
            color: white;
        }
    }

    &:hover, &.active {
        background-color: $green-darkened;
        color: white;

    }
    &:hover > .a3-tab-subitems {
        display: block;
        opacity: 1;
        pointer-events: auto;
    }

    i {
        color: lighten($a3-green, 40%);
    }
}


.a3-tab-subitems {
    position: absolute;
    z-index: 17000;
    background-color: $a3-green;
    left: 0;
    pointer-events: none;
    text-align: left;
    display: block;
    border-radius: 0px 0px 5px 5px;
    transition: opacity .2s;
    opacity: 0;
}
.a3-tab-subitem {
    padding: 0px 0px 0px 15px;
    width: 200px;
    i {
        padding-right: 5px;
        transition: padding-right .1s ease-out;
    }
    &:last-child {
        border-radius: 0px 0px 5px 5px;
    }
    &:hover, &.active {
        background-color: $green-darkened;
        color: white;
        i {
            padding-right: 10px;
        }
    }
}

#main-tab-menu-right {
    display: inline-block;
    float: right;
}


@keyframes menuSlideDown {
  from { transform: scaleY(0); opacity: 0; }
  to { transform: scaleY(100%); opacity: 1; }
}

#organisation-selector {
  background-color: $a3-green;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  width: 400px;
  position: absolute;
  right: 0px;
  border-radius: 0 0 5px 5px;

  -webkit-box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.5);
  box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.5);
  border-bottom: 1px $green-darkened solid;
  border-left: 1px $green-darkened solid;

  transform-origin: top;
  animation: menuSlideDown .2s ease-in-out;
  z-index: -100;
  min-height: 350px;

  h3 {
    margin-top: -10px;
  }

  .organisation-list {
    margin: 0px;
    border-radius: 0 0 5px 5px;

    .organisation-item {
      padding: 10px 5px;
      background-color: lighten($a3-green, 5%);
      cursor: pointer;

      &:hover {
        background-color: lighten($a3-green, 15%);
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &.selected {
        font-weight: bold;
        border: 2px lighten($a3-green, 15%) solid;
      }
    }
  }
}

</style>
