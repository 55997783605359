<template>
    <div :style="{'color': color}">
        <i class="fas" :class="`fa-${icon}`"></i>
    </div>
</template>

<script>


export default {

    props: {
        value: {
            type: Number,
            required: false,
            default: null,
        }
    },

    computed: {
      icon() {
        let value = parseFloat(this.value);
        let int_value = Math.floor(value);

        if (int_value == 0) { return 'angry'; }
        if (int_value == 1) { return 'frown'; }
        if (int_value == 2) { return 'meh'; }
        if (int_value == 3) { return 'smile'; }
        if (int_value >= 4) { return 'laugh'; }
      },

      color() {
          let value = parseFloat(this.value);

          if (value < 1) { return '#F42B03'; }
          if (value < 2) { return '#F18F01'; }
          if (value < 3) { return '#CCC900'; }
          if (value < 4) { return '#80ADA0'; }
          if (value >= 4) { return '#09BC8A'; }

          return '#dddddd'; // should never hit
      },
    }
}
</script>
