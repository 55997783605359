<template>
  <div>
    <h2 class="element-form-title"><i class="fas fa-tasks"></i> {{ $t('ui.action_task.create') }}</h2>

    <ActionTaskForm
      :a3_document="a3_document"
      :action_task="action_task"
      @submit="saveActionTask"
      @cancel="$router.go(-1)"
    />

  </div>
</template>

<script>

import ActionTaskForm from '@/components/forms/ActionTaskForm.vue';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {

  mixins: [WithDocumentManager],
  components: {
    ActionTaskForm,
  },

  props: {
    action_id: {
      type: String,
      required: true,
    },
  },

  computed: {
    action() {
      for (let action of Object.values(this.a3_document.actions).flat()) {
        if (action.id === parseInt(this.action_id)) { return action; }
      }
      return null;
    },
  },

  data: () => ({
    action_task: {
      name: '',
      done: false,
      target_date: null,
      assigned_to: null,
      note: null,
    },
  }),

  methods: {
    saveActionTask() {
      this.dataManager
        .createActionTask(this.action_task, this.action_id)
        .then(() => {
          this.interactionManager.selectAction(this.action_id);
        })
    }
  }

}
</script>
