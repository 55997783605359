var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"import-preview-grid"},[_c('div',[_c('h2',[_vm._v("Checks")]),_c('ul',_vm._l((_vm.checks),function(result,index){return _c('li',{staticClass:"check-item",class:{'ok': result, 'err': !result}},[(result)?_c('i',{staticClass:"fas fa-check"}):_vm._e(),(!result)?_c('i',{staticClass:"fas fa-times"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(`ui.people_import.checks.${index}`)))])}),0)]),_c('div',{staticStyle:{"margin-top":"30px"}},[_c('table',{staticClass:"a3-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('ui.general.name'))+" ")]),_c('th',[_vm._v(_vm._s(_vm.$t('ui.accounts.mail'))+" ")]),_c('th',[_vm._v(_vm._s(_vm.$t('ui.general.department'))+" ")]),_c('th',[_vm._v(_vm._s(_vm.$t('ui.organisation.function'))+" ")]),_c('th',[_vm._v(_vm._s(_vm.$t('ui.user_type.user_type'))+" ")])])]),_c('tbody',_vm._l((_vm.people),function(p,index){return _c('tr',{key:p.mail},[_c('td',[(
                        _vm.row_errors[index] &&
                        _vm.row_errors[index].includes('row_name_filled'))?[_c('i',{staticClass:"fas fa-exclamation-triangle"})]:_vm._e(),_vm._v(" "+_vm._s(p[_vm.cols.name])+" ")],2),_c('td',[_vm._v(_vm._s(p[_vm.cols.mail]))]),_c('td',[(
                        _vm.row_errors[index] &&
                        _vm.row_errors[index].includes('invalid_department_name'))?[_c('i',{staticClass:"fas fa-exclamation-triangle"})]:_vm._e(),_vm._v(" "+_vm._s(p[_vm.cols.department]))],2),_c('td',[_vm._v(_vm._s(p[_vm.cols.function]))]),_c('td',[(
                        _vm.row_errors[index] &&
                        _vm.row_errors[index].includes('invalid_user_type'))?[_c('i',{staticClass:"fas fa-exclamation-triangle"})]:_vm._e(),_vm._v(" "+_vm._s(p[_vm.cols.user_type]))],2)])}),0)])])]),_c('div',{staticStyle:{"text-align":"right"}},[_c('button',{staticClass:"a3-button action",attrs:{"disabled":!_vm.canImport},on:{"click":function($event){return _vm.$emit('submit', _vm.cols)}}},[_c('i',{staticClass:"fas fa-play"}),_vm._v(" "+_vm._s(_vm.$t('ui.people_import.start_import'))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }