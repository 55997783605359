<template>
  <div>
    <div style="text-align: center">
      <h2 v-if="selected_status == null">{{ $t('ui.review.select_existing') }}</h2>
      <h2 v-if="selected_status == 'Processed'">{{ $t('ui.review.select_approved_review') }}</h2>
    </div>


    <div>
      <div class="review-box"
           v-for="review in reviews"
           :key="review.id"
           @click="$emit('selectReview', review.id)"
        >
        <span class="title">{{ review.name }}</span>
        <span class="created">{{ $d(new Date(review.created), 'long') }}</span>
      </div>
    </div>

    <div class="review-link-container">
      <div
         class='review-shy-link'
         v-if="selected_status == null"
        @click="selected_status = 'Processed'">
        <i class="fas fa-chevron-right"></i> {{ $t('ui.review.view_approved_reviews') }}
      </div>

      <div
         class='review-shy-link'
         v-if="selected_status != null"
        @click="selected_status = null">
        <i class="fas fa-chevron-left"></i> {{ $t('ui.review.view_open_reviews') }}
      </div>
    </div>


    <div class="new-review-container" v-if="selected_status == null
                && editable
      ">
      <div class="new-review-header">
        <em>{{ $t('ui.review.start_new') }} </em>
      </div>

      <input class="a3-input" v-model="new_review_name"
                              placeholder="Naam (optioneel)"
      />
      <button class='a3-button action'
                              style="margin-top: 10px"
              @click="createReview"
        >
        <i class="fas fa-plus"></i> {{ $t('ui.review.new_review') }}
      </button>
    </div>
  </div>
</template>


<script>

export default {

  props: {
    a3doc_id: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    reviews: [],
    selected_status: null,
    new_review_name: null,
  }),

  watch: {
    selected_status() {
      this.loadReviews();
    }
  },

  mounted() {
    this.loadReviews();
  },

  methods: {
    loadReviews() {
      let url = `a3/${this.a3doc_id}/review`
      if (this.selected_status != null) {
        url += `?status=${this.selected_status}`;
      }
      this.$http.get(url)
        .then((res) => { this.reviews = res.body; });
    },

    createReview(){
      this.$http.post(`a3/${this.a3doc_id}/review`, {
        name: this.new_review_name
      }).then((res) => {
        this.$emit('selectReview', res.body.id);
      });
    }
  },
};
</script>


<style lang="scss">

.review-box {
  background-color: darken($review-orange, 10%);
  cursor: pointer;
  padding: 10px;

  transition: color .15s ease-out, background-color .15s ease-out;
  border-bottom: 1px lighten($review-orange, 10%) solid;

  &:hover {
    background-color: lighten($review-orange, 30%);
    color: darken($review-orange, 30%);
  }

  > span.title {
    display: block;
    font-size: 1.3em;
    font-weight: bold;
  }
  > span.created {

  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 10px;
    border-bottom: 0px;
  }
}


.review-link-container {
  margin-top: 10px;

  .review-shy-link {
      text-align: right;
      opacity: .5;
      padding: 3px;
      width: auto;
      cursor: pointer;

      transition: opacity .15s ease-out;

      &:hover {
        opacity: 1;
      }
  }
}

.new-review-container {
  margin-top: 30px;
  text-align: right;

  .new-review-header {
    text-align: center;
    margin-bottom: 8px;
  }
}

</style>

