<template>
  <WithReviewMenuContainer
    :a3document_id="a3document_id"
    :review_id="review_id"
  >
    <div style="max-width: 1120px; margin: 0 auto;">
      <h1>
        <i class="fas fa-clipboard-check"></i>
        {{ $t('ui.review.complete_review') }}
      </h1>

      <p style="max-width: 600px; margin-bottom: 20px;
                white-space: pre-wrap;
      ">{{ $t('ui.review.complete_explanation') }}</p>

      <button class="a3-button action"
              @click="completeReview">
        <i class="fas fa-check"></i>
        {{ $t('ui.review.complete_review') }}
      </button>

      <button class="a3-button cancel"
              @click="cancelReview"
        >
        <i class="fas fa-times"></i>
        {{ $t('ui.review.cancel_review') }}
      </button>
    </div>
  </WithReviewMenuContainer>
</template>


<script>

import { ReviewDataManager } from '@/services/A3DocumentManagers/data_review';


import WithReviewMenuContainer from '@/views/WithReviewMenuContainer.vue';

export default {
  components: {
    WithReviewMenuContainer,
  },
  props: {
    a3document_id: {
      type: String,
      required: true,
    },
    review_id: {
      type: String,
      required: true,
    },
  },

  methods: {
    completeReview() {
      this.$http.put(`a3/${this.a3document_id}/review/${this.review_id}`, {
        status: "Processed"
      }).then((res) => {
        this.$router.push(`/a3/${this.a3document_id}`);
      });
    },

    cancelReview() {
      this.$http.put(`a3/${this.a3document_id}/review/${this.review_id}`, {
        status: "Cancelled"
      }).then((res) => {
        this.$router.push(`/a3/${this.a3document_id}`);
      });
    },
  },
};
</script>

<style lang="scss">

h2.review-part {
    color: $a3-green;
    opacity: .7;
    font-size: 1.8em;
    margin-top: 100px;
}

.review-report .review-row {
  margin-top: 40px;
}
</style>

