
import A3SbfFillout from "@/views/A3SbfFillout.vue";

import KpiCreate from "@/components/overlays/KpiCreate.vue";
import KpiEdit from "@/components/overlays/KpiEdit.vue";

import ActionCreate from "@/components/overlays/ActionCreate.vue";
import ActionEdit from "@/components/overlays/ActionEdit.vue";

export default [
  {
    path: "/a3/:a3doc_id/sbf_fillout/:sbf_id",
    component: A3SbfFillout,
    name: "a3-sbf-fillout",
    props: true,
    children: [
      { path: "kpi/create", name: "a3-kpi-create", component: KpiCreate },
      { path: "kpi/:kpi_id/edit", name: "a3-kpi-edit", component: KpiEdit, props: true },
      { path: "action/create", name: "a3-action-create", component: ActionCreate },
      { path: "action/:action_id/edit", name: "a3-action-edit", component: ActionEdit, props: true },
    ],
  },
];
