import Vue from "vue";
import Vuex from "vuex";

import AuthStore from "./auth";
import UserSettings from "./user_settings";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tour_pending: false,
  },
  getters: {
    isTourPending: (state) => state.tour_pending,
  },
  mutations: {
    setTourPending(state, value = true) {
      state.tour_pending = value;
    },
  },
  actions: {

  },
  modules: {
    auth: AuthStore,
    settings: UserSettings,
  },

});
