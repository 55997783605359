<template>
    <div>
        <div v-for="(locale, index) in locales" :key="index" class="locale-button"
            :class="{'selected': $i18n.locale == index}"
            @click="setLocale(index)"
            >
            <img :src="locale.flag" />
            <span>
                <i class="fas fa-check" v-if="$i18n.locale == index"></i>
                {{ locale.label }}
            </span>
        </div>
    </div>
</template>

<script>

export default {

    data: () => ({
        locales: {
            'nl-NL': { label: "Nederlands", flag: '/img/flag_nl.svg' },
            'en-US': { label: "English", flag: '/img/flag_en.svg' },
        },
    }),

    methods: {
        setLocale(locale) {
            this.$emit('value', locale);
            this.$root.$i18n.locale = locale;
        },
    },
}
</script>

<style lang="scss">

.locale-button {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    padding: 10px;
    margin-right: 5px;
    border: 2px $a3-green solid;
    border-radius: 5px;
    width: 250px;
    overflow: hidden;
    cursor: pointer;
    opacity: .6;

    transition: color .15s ease-out, background-color .15s ease-out;

    &.selected {
        font-weight: bold !important;
        opacity: 1;
        img {
            opacity: .6;
            transform: scale(1.3);
        }
    }

    &:hover {
        background-color: $a3-green;
        color: white;
        img {
            transform: scale(1.3);
        }
    }

    span {
        padding-left: 40px;
    }
  img {
      pointer-events: none;
    transition: transform .3s ease-out;
    position: absolute;
    left: -5px;
    top: -3px;
    opacity: .2;
    height: calc(100% + 6px);
    display: inline-block;
    cursor: pointer;

    &:hover {
      border: 2px white solid;
      transform: scale(1.1);
    }
  }


}
</style>
