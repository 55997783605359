<template>
    <div class="a3-plan-selector">

        <div class="add-timeframe-button"
             @click="$emit('cancel')"
            >
            <i class="fas fa-chevron-left"></i> {{ $t('ui.general.back') }}
        </div>

        <h3>Jaarplan selecteren</h3>

        <div class="a3-plan-selector-header">
            <div>
                <template v-if="timeframes">
                    <div style="width: 100%; clear: both;">
                        <div style="width: 350px;" class="form-row">
                            <label>{{ $t('ui.timeframe.timeframe') }}</label>
                          <multiselect
                                    id="timeframe-multiselect"
                                    v-model="selected_timeframe"
                                   :options="timeframes"
                                   placeholder="Selecteer periode"
                                   :selectLabel="$t('ui.general.enter_select')"
                                   :selectedLabel="$t('ui.general.selected')"
                                   :deselectLabel="$t('ui.general.enter_deselect')"
                                   :multiple="false"
                                   :close-on-select="true"
                                   track-by="id"
                                   label="name" />
                        </div>
                    </div>
                </template>
            </div>
        </div>



        <div class="plan-select-element"
             v-if="rootPlan != null"
             @click="$emit('select', rootPlan)"
            >
            <i class="fas fa-chevron-right"></i> Organisatieplan
        </div>
        <div v-else style="opacity: .3">
            <i class="fas fa-chevron-right"></i> Organisatieplan
        </div>

        <div class="program-plan-select-container"
             v-if="matrixPlans.length > 0"
            >
            <div v-for="p in matrixPlans"
                 class="plan-select-element"
                 @click="$emit('select', p)"
                >
                <i class="fas fa-clipboard-list"></i>
                {{ p.matrix_name }}
            </div>
        </div>


        <A3PlanSelectorInner
            @select="$emit('select', $event)"
            :departments="departments"
            :a3_map="a3_map"
            :a3_rights="a3_rights"
            />

    </div>
</template>


<script>

import A3PlanSelectorInner from '@/components/elements/A3PlanSelectorInner';
import Multiselect from 'vue-multiselect';

export default {

    components: {
        A3PlanSelectorInner,
        Multiselect,
    },

    data: () => ({
        timeframes: null,
        departments: null,
        a3_map: null,
        a3_rights: null,

        selected_timeframe: null,
    }),

    mounted() {
        this.loadOrganisation();
        this.loadA3Rights();
        this.loadTimeframes()
          .then(() => this.loadA3List());
    },

    watch: {
        selected_timeframe() {
            this.a3_map = null;
            this.loadA3List();
        },
    },

    computed: {
        rootPlan() {
            if (this.a3_map == null) return null;
            if (this.a3_map[null] == null ||
                this.a3_map[null].length === 0) {
                return null;
            }

            return this.a3_map[null][0];
        },

        matrixPlans()  {
            if (this.a3_map == null) return [];
            if (this.a3_map[null] == null ||
                this.a3_map[null].length === 0) {
                return [];
            }

            return this.a3_map[null]
                .filter(x => x.matrix_name != null);
        },
    },

    methods: {
        loadTimeframes() {
          return this.$http.get('timeframe')
            .then(res => {
              this.timeframes = res.body;
                if (this.timeframes.length > 0) {
                    this.selected_timeframe = this.timeframes[0];
                }
            });
        },

        loadOrganisation() {
          this.$http.get(
            `organisation/department`
          ).then(res => this.departments = res.body);
        },

        loadA3List() {
          this.$http.get(`a3/org?timeframe=${this.selected_timeframe.id}`)
                .then(res => {
                    let a3list = res.body;
                    // map from department_id -> list of a3
                    this.a3_map = {};
                    for (let a3 of a3list) {
                        if (!this.a3_map[a3.department_id]) {
                            this.a3_map[a3.department_id] = [];
                        }
                        this.a3_map[a3.department_id].push(a3);
                    }
                });
        },

        loadA3Rights() {
            this.$http.get('a3_rights')
                .then(res => {
                    this.a3_rights = res.body;
                });
        },
    },
}
</script>


<style lang="scss">

.a3-plan-selector {
    padding: 15px;
    border: 1px rgba($a3-green, .2) solid;
    border-radius: 10px;
    margin-bottom: 30px;
}

.a3-plan-selector-header {
    display: grid;
    grid-template-columns: 6fr 4fr;
    grid-column-gap: 20px;
    margin-bottom: 20px;
}

.plan-select-element {
    color: $a3-green;
    cursor: pointer;
    transition: all .1s ease-in-out;
    padding: 3px 0px;

    &:hover {
        color: lighten($a3-green, 10%);
        background-color: rgba($a3-green, .1);
        transform: translateX(5px);
    }
}
</style>
