<template>
  <WithReportMenuContainer :a3doc_id="a3document_id" :filter_slug="filter_slug">
      <div style="float: right">
        <button class="a3-button action"
                @click="downloadCsv"
          >
          <i class="fas fa-file-csv"></i>
          Download CSV
        </button>

        <button class="a3-button action"
                @click="downloadXlsx"
          >
          <i class="fas fa-file-excel"></i>
          Download Excel
        </button>
      </div>

    <div style="margin: 0 auto;">
        <h1 class="report-title">
            <i class="fas fa-clipboard-list"></i>
            {{ $t('ui.report.action_table') }}</h1>
        <h2 class="report-a3-name">{{ a3_title }}</h2>

      <FilterPrint :filter="filterOptions"
         v-if="a3_document != null"
         :a3_document="a3_document"
      />


      <div id="a3-action-report-tabulator"></div>

    </div>
  </WithReportMenuContainer>
</template>

<script>

import FilterSlugUrl from '@/mixins/FilterSlugUrl';

import WithReportMenuContainer from '@/views/WithReportMenuContainer.vue';
import FilterPrint from '@/components/elements/FilterPrint.vue';

import richtextRender from '@/services/richtextRender';

import Tabulator from 'tabulator-tables';



export default {

  mixins: [FilterSlugUrl],

  components: {
    WithReportMenuContainer,
    FilterPrint,
  },

  props: {
    a3document_id: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    a3_document: null,
    filterMap: null,
    filterOptions: null,

    actions: null,
    table: null,
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    },

    a3_title() {
        if (this.a3_document == null) return null;
        let meta = this.a3_document.meta;

        if (this.a3_document.meta.department != null) {
            return `${meta.department.name}, ${meta.timeframe.name}`;
        }

        return `${meta.organisation.name}, ${meta.timeframe.name}`;
    },
  },

  mounted() {
      this.loadA3();
      this.loadActions();
  },

  methods: {
    loadA3() {
      this.$http.get(`a3/${this.a3document_id}`)
        .then(res => {
          this.a3_document = res.body;
          this.filterOptions = this.getFilterOptions(this.filter_slug);
          this.filterMap = this.filterMapFromOptions(this.filterOptions);

          this.renderTable();
        });
    },

    /*
     * We use the action report form the backend to get the data here,
     *
     * Very soon, this should replace the whole A3 doc retrieval thingy
     */
    loadActions() {
        this.$http.get(`report/action_list/${this.a3document_id}`)
            .then((res) => {
                this.actions = res.body.actions;
                if (this.a3_document != null) {
                  this.renderTable();
                }
            });
    },

    downloadCsv() {
      this.table.download(
        "csv",
        `Acties - ${this.a3_title}.csv`,
      );
    },

    downloadXlsx() {
      this.table.download(
        "xlsx",
        `Acties - ${this.a3_title}.xlsx`,
        { sheetName: "Acties" }
      );
    },

    renderTable() {
      let sbf_map = Object.fromEntries(this.a3_document.sbf.map((sbf) => {
        return [sbf.id, sbf.name];
      }));
      let kpi_map = Object.fromEntries(Object.values(this.a3_document.kpi).flat()
        .map((kpi) => {
          return [kpi.id, kpi.name];
        }))

      let result_area_map = Object.fromEntries(
          this.a3_document.meta.layout.structure.result_areas
          .map((ra) => [ra.key, ra.name])
      );


      let table_data = this.actions.map((action) => ({
        "name": action.name,
        "action_name": action.name,
        "action_id": action.id,
        "action_type": this.$t('definitions.short.action'),
        "sbf": sbf_map[action.sbf_id],
        "kpi": kpi_map[action.kpi_id],
        "result_area": result_area_map[action.result_area] ?
              result_area_map[action.result_area] :
              this.$t(`definitions.result_area.${action.result_area}`),
        "target_date": action.target_date,
        "comment_result": action.comment_result,
        "state": action.status !== null ? `${action.status.percent_done}%` : null,
        "state_comment": action.status !== null && action.status.comment !== null ? `${action.status.comment}` : null,
        "assigned_to": action.assigned_to_person !== null ? `${action.assigned_to_person.name}` : null,
        "_children": action.tasks.map(
            task => ({
                "action_id": action.id,
                "task_id": task.id,
                "name": task.name,
                "action_name": action.name,
                "action_type": this.$tc('ui.action.tasks', 1),
                "state": task.done ? "Gereed" : "Te doen",
                "target_date": task.target_date,
                "assigned_to": task.person !== null ? `${task.person.name}` : null,
            })
        )
      }));
      //define column header menu as column visibility toggle
      let headerMenu = () => {
        var menu = [];
        var columns = this.table.getColumns();

        for (let column of columns){

          //create checkbox element using font awesome icons
          let icon = document.createElement("i");
          icon.classList.add("fas");
          icon.classList.add(column.isVisible() ? "fa-check-square" : "fa-square");

          //build label
          let label = document.createElement("span");
          let title = document.createElement("span");

          title.textContent = " " + column.getDefinition().title;

          label.appendChild(icon);
          label.appendChild(title);

          //create menu item
          menu.push({
            label:label,
            action:function(e){
              //prevent menu closing
              e.stopPropagation();

              //toggle current column visibility
              column.toggle();

              //change menu item icon
              if(column.isVisible()){
                icon.classList.remove("fa-square");
                icon.classList.add("fa-check-square");
              }else{
                icon.classList.remove("fa-check-square");
                icon.classList.add("fa-square");
              }
            }
          });
        }

        return menu;
      };
      this.table = new Tabulator ('#a3-action-report-tabulator', {
          data: table_data,
          movableColumns: true,
          layout: "fitColumns",
          dataTree: true,
          dataTreeStartExpanded: true,
          layoutColumnsOnNewData:true,
          rowClick: (ev, row) => {
              let url = `/a3/${this.a3document_id}/action/${row._row.data.action_id}`;
              if (row._row.data.task_id) {
                  url += `/task/${row._row.data.task_id}`;
              }
              this.$router.push(`${url}?redirect=report/action_table`);
          },
        headerSort: true,
          columns: [
              {
                  title: this.$t('ui.action.action_type'),
                  field: "action_type",
                  headerMenu,
                  visible: false,
              },
              {
                  title: this.$t('ui.action.action_name'),
                  field: "action_name",
                  headerMenu,
                  visible: false,
              },
              {
                  title: this.$t('ui.general.name'),
                  field: "name",
                  headerMenu,
              },
              { title: this.$t('ui.action.result_area'), field: "result_area", headerMenu },
              { title: this.$tc('definitions.terms.sbf'), field: "sbf", headerMenu },
              { title: this.$tc('definitions.terms.kpi'), field: "kpi", headerMenu },
              { title: this.$t('ui.action.assigned_to'), field: "assigned_to", headerMenu,
                accessorDownload: (value) => {
                  if (value == null) return '';
                  return value;
                },
              },
              {
                title: this.$t('ui.action.target_date'),
                field: "target_date",
                formatter: (cell, formatterParams, onRendered) => {
                  let d = cell.getValue();
                  return d ? this.$d(new Date(cell.getValue()), "long") : '';
                },
                accessorDownload: (value) => {
                  if (value == null) return '';
                  return value;
                },
                headerMenu
              },
              { title: this.$t('ui.action.state'), field: "state", headerMenu,
                accessorDownload: (value) => {
                  if (value == null) return '';
                  return value;
                },
              },
              { title: this.$t('ui.action_status.comment'),
                field: "state_comment",
                headerMenu,
                accessorDownload: (value) => {
                  if (value == null) return '';
                  return value;
                },
              },
              { title: this.$t('ui.action.comment_result'),
                field: "comment_result",
                headerMenu,
                visible: false,
                formatter: (cell, formatterParams, onRendered) => {
                    let rt = cell.getValue();
                    return richtextRender.renderContent(
                        rt
                    );
                },
                accessorDownload: (value) => {
                  if (value == null) return '';
                  return richtextRender.renderPlainText(
                      value
                  );
                },
              },
          ],
      });
    },
  },
};
</script>
