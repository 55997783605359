<template>
  <div class="filter-text" v-if="filter">
    <i class="fas fa-filter"></i> {{ $t('ui.a3_document.focus') }}:

    <template v-if="filter.sbf && filter.sbf.length > 0">
      <span class="filter-tag">{{ $t('definitions.short.sbf') }}: {{ filtered_sbf.join(" + " ) }}</span>
    </template>
    <template v-if="filter.kpi && filter.kpi.length > 0">
      <span class="filter-tag">{{ $tc('definitions.terms.result')}}: {{ filtered_kpi.join(" + " ) }}</span>
    </template>
    <template v-if="filter.action && filter.action.length > 0">
      <span class="filter-tag">{{ $t('definitions.short.action')}}: {{ filtered_action.join(" + " ) }}</span>
    </template>
    <template v-if="filter.report_groups && filter.report_groups.length > 0">
      <span class="filter-tag">{{ $t('definitions.terms.report_groups') }}: {{ filtered_report_groups.join(" + " ) }}</span>
    </template>
    <template v-if="filter.kpi_norm && Object.values(filter.kpi_norm).filter(x => x).length > 0">
      <template v-for="(value, key) in filter.kpi_norm">
        <span v-if="value" :key="key" class="filter-tag">
          {{ $t('ui.kpi.norm') }}: {{ $t(`ui.kpi.norm_${key}`) }}
        </span>
      </template>
    </template>

    <template v-if="filter.kpi_steering && Object.values(filter.kpi_steering).filter(x => x).length > 0">
      <template v-for="(value, key) in filter.kpi_steering">
        <span v-if="value" :key="key" class="filter-tag">
          {{ $t('ui.kpi.steering_indicator') }}:
          <template v-if="key == 'is_steering'">{{ $t(`ui.general.yes`) }}</template>
          <template v-if="key == 'no_steering'">{{ $t(`ui.general.no`) }}</template>
        </span>
      </template>
    </template>

    <template v-if="filter.kpi_type && Object.values(filter.kpi_type).filter(x => x).length > 0">
      <template v-for="(value, key) in filter.kpi_type">
        <span v-if="value" :key="key" class="filter-tag">
          {{ $t('ui.kpi.kpi_type') }}:
          <template v-if="key == 'kpi'">{{ $t(`ui.kpi.type_kpi`) }}</template>
          <template v-if="key == 'evaluation'">{{ $t('ui.kpi.type_evaluation') }}</template>
        </span>
      </template>
    </template>

    <template v-if="filter.kpi_update_after">
        <span class="filter-tag">
            {{ $t('ui.filter_tab.kpi_update_after') }} ({{$t('definitions.short.kpi')}}) :
          {{ $d(new Date(filter.kpi_update_after), 'long') }}
        </span>
    </template>

    <template v-if="filter.milestone_before">
        <span class="filter-tag">
          {{ $t('ui.filter_tab.milestone_before') }}:
          {{ $d(new Date(filter.milestone_before), 'long') }}
        </span>
    </template>

    <template v-if="filter.action_status && Object.values(filter.action_status).filter(x => x).length > 0">
      <template v-for="(value, key) in filter.action_status">
        <span v-if="value" :key="key" class="filter-tag">
          {{ $t('ui.action.state') }}: {{ $t(`ui.action.${key}`) }}
        </span>
      </template>
    </template>
    <template v-if="filter.action_tasks && Object.values(filter.action_tasks).filter(x => x).length > 0">
      <template v-for="(value, key) in filter.action_tasks">
        <span v-if="value" :key="key" class="filter-tag">
          {{ $tc('ui.action.tasks', 2) }}: {{ $t(`ui.filter_tab.${key}`) }}
        </span>
      </template>
    </template>
    <template v-if="filter.action_people && filter.action_people.length > 0">
      <template v-for="person in filtered_person">
        <span class="filter-tag" :key="person.id">
          {{ $t('ui.action.assigned_to') }}: {{ person.name }}
        </span>
      </template>
    </template>

    <template v-if="filter.action_update_after">
        <span class="filter-tag">
            {{ $t('ui.filter_tab.action_update_after') }} ({{$t('definitions.short.action')}}) :
          {{ $d(new Date(filter.action_update_after), 'long') }}
        </span>
    </template>

  </div>
</template>


<script>

export default {
  props: {
    filter: {
      type: Object,
      required: false,
      default: null
    },

    a3_document: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    report_groups: [],
  }),

  mounted() {
    if (this.filter.report_groups != null) {
      this.loadManagementGroups();
    }
  },

  computed: {
    filtered_sbf() {
      if (this.filter.sbf == null || this.a3_document == null) return null;

      return Object.entries(this.a3_document.sbf)
        .filter(x => this.filter.sbf.findIndex(y => y.id == x[1].id) > -1)
        .map(x => parseInt(x[0]) + 1);
    },
    filtered_kpi() {
      if (this.filter.kpi == null || this.a3_document == null) return null;

      // These numbers are only used for show, not structural,
      // see x[0] on rel line 14 below
      let result_areas = {
        6: "customers_and_partners",
        7: "employees",
        8: "society",
        9: "board_and_financers",
      };
      let kpi_number_map = {};
      Object.entries(result_areas)
        .forEach(x => {
          for (let index in this.a3_document.kpi[x[1]]) {
            let kpi = this.a3_document.kpi[x[1]][index];
            kpi_number_map[kpi.id] = `${x[0]}.${parseInt(index) + 1}`;
          }
        })

      return this.filter.kpi
        .map(x => kpi_number_map[x.id]);
    },
    filtered_action() {
      if (this.filter.action == null || this.a3_document == null) return null;

      // These numbers are only used for show, not structural,
      // see x[0] on rel line 14 below
      let org_areas = {
        1: "leadership",
        2: "strategy_and_policy",
        3: "management_employees",
        4: "management_resources",
        5: "management_processes",
      };

      let action_number_map = {};
      Object.entries(org_areas)
        .forEach(x => {
          for (let index in this.a3_document.actions[x[1]]) {
            let action = this.a3_document.actions[x[1]][index];
            action_number_map[action.id] = `${x[0]}.${parseInt(index) + 1}`;
          }
        })

      return this.filter.action
        .map(x => action_number_map[x.id]);
    },

    filtered_report_groups() {
      if (this.filter.report_groups == null || this.report_groups == null) return null;

      let rg_number_map = {};
      this.report_groups
        .forEach(x => {
          rg_number_map[x.id] = x.name;
        })

      return this.filter.report_groups
        .map(x => rg_number_map[x.id]);
    },

    filtered_person() {
      if (this.filter.action_people == null || this.a3_document == null) return null;

      let people = Object.values(this.a3_document.actions)
        .flat()
        .map(x => x.assigned_to_person)
        .filter(p => p != null)
        .filter(p => this.filter.action_people.findIndex(x => x.id == p.id) > -1);
      return people.filter(function(item, pos, self) {
        return self.findIndex(x => x.id == item.id) == pos;
      })
    },
  },

  methods: {
    loadManagementGroups() {
      this.$http.get('organisation/report_group')
        .then((res) => this.report_groups = res.body)
    },
  },
}
</script>


<style lang="scss">

.filter-tag {
  background-color: $a3-green;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
  line-height: 2em;

  white-space: nowrap;
}

</style>
