<template>
  <div class="loading-overlay"
       :class="{'active': loading }"
       v-show="show_element">
    <i class="fas fa-spinner fa-spin"></i>
    <span style="display: inline-block; max-width: 80%">
      {{ text }}
    </span>
  </div>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    show_element: true,
    show_timeout: null,
    states: [
      [5000, ""],
      [1000, "de allernieuwste informatie aan het ophalen ."],
      [1000, "de allernieuwste informatie aan het ophalen .."],
      [1000, "de allernieuwste informatie aan het ophalen ..."],
      [1000, "de allernieuwste informatie aan het ophalen ...."],
      [2000, "de allernieuwste informatie aan het ophalen ."],
      [1000, "de allernieuwste informatie aan het ophalen .."],
      [1000, "de allernieuwste informatie aan het ophalen ..."],
      [1000, "de allernieuwste informatie aan het ophalen ...."],
      [10000, "de server is hard aan het werk"],
      [1500, "speciaal voor u"],
      [1500, "Nog heel even geduld :)"],
    ],
    current_state: 0,
  }),

  computed: {
    text() {
      return this.states[this.current_state][1];
    },
  },

  mounted() {
    this.handleNewState();
    this.show_element = this.loading;
  },

  watch: {
    loading() {
      if (this.show_timeout != null) {
        clearTimeout(this.show_timeout);
      }

      if (!this.loading && this.show_element) {
        // this.show_timeout = setTimeout(() => this.show_element = false, 100);
        this.show_element = false;
      }
      if (this.loading) {
        // this.show_element = true;
        this.show_timeout = setTimeout(() => this.show_element = true, 100);
        this.current_state = 0;
      }
    },
  },

  methods: {
    handleNewState() {
      if (this.current_state == this.states.length){
        this.current_state = 0;
      }
      let timeout = this.states[(this.current_state + 1) % this.states.length][0];
      setTimeout(() => {
        this.current_state++;
        this.handleNewState();
      }, timeout)
    }
  },
}
</script>

<style lang="scss">

@keyframes opacityIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  line-height: 100%;

  background-color: rgba(#cccccc, .4);
  z-index: 10000;
  font-size: 24px;
  text-align: center;
  padding-top: 50px;
  color: rgba($a3-green, 1);

  opacity: 1;
  transition: opacity .15s ease-out;
  animation: opacityIn .15s ease-out;
  &.active {
    opacity: 1 !important;
  }

  i {
    display: block;
    font-size: 70px;
    margin-bottom: 10px;
  }
}

</style>
