<template>
  <div>
    <div class="import-preview-grid">
        <div>
            <h2>Checks</h2>

            <ul>
                <li
                    v-for="(result, index) in checks"
                    class="check-item"
                    :class="{'ok': result, 'err': !result}"
                    >
                    <i v-if="result" class="fas fa-check"></i>
                    <i v-if="!result" class="fas fa-times"></i>
                    {{ $t(`ui.people_import.checks.${index}`) }}</li>
            </ul>
        </div>

        <div style="margin-top: 30px">
            <table class="a3-table">

              <thead>
                <tr>
                  <th>{{ $t('ui.general.name') }} </th>
                  <th>{{ $t('ui.accounts.mail') }} </th>
                  <th>{{ $t('ui.general.department') }} </th>
                  <th>{{ $t('ui.organisation.function') }} </th>
                  <th>{{ $t('ui.user_type.user_type') }} </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(p, index) in people" :key="p.mail">
                  <td>
                      <template v-if="
                          row_errors[index] &&
                          row_errors[index].includes('row_name_filled')">
                          <i class="fas fa-exclamation-triangle"></i>
                      </template>
                      {{ p[cols.name] }}
                  </td>
                  <td>{{ p[cols.mail] }}</td>
                  <td>
                      <template v-if="
                          row_errors[index] &&
                          row_errors[index].includes('invalid_department_name')">
                          <i class="fas fa-exclamation-triangle"></i>
                      </template>
                      {{ p[cols.department] }}</td>
                  <td>{{ p[cols.function] }}</td>
                  <td>
                      <template v-if="
                          row_errors[index] &&
                          row_errors[index].includes('invalid_user_type')">
                          <i class="fas fa-exclamation-triangle"></i>
                      </template>
                      {{ p[cols.user_type] }}</td>
                </tr>
              </tbody>
            </table>
        </div>
    </div>

    <div style="text-align: right">
      <button class="a3-button action"
              @click="$emit('submit', cols)"
              :disabled="!canImport"
        >
        <i class="fas fa-play"></i> {{ $t('ui.people_import.start_import') }}
      </button>
    </div>
  </div>
</template>


<script>


export default {

  props: {
    people: {
      type: Array,
      required: true,
    },

    errors: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
      checks: {},
      row_errors: {},
      cols: {
          name: null,
          mail: null,
          'function': null,
          department: null,
      },

      departments: [],
  }),

  computed: {
      canImport() {
          return Object.values(this.checks)
            .filter((x) => !x)
            .length == 0;
      },
  },

  mounted() {
      this.process();
  },

  methods: {
      process() {
          this.setNameCol();
          this.setMailCol();
          this.setDepartmentCol();
          this.setFunctionCol();
          this.setUserTypeCol();

          this.mapDepartments()
            .then(() => this.mapUserTypes())
            .then(() => this.validate());
      },

      validate() {
          let checks = {
              'includes_name': this.cols.name != null,
              'includes_mail': this.cols.mail != null,
              'includes_department': this.cols.department != null,
              'includes_function': this.cols.function != null,
              'row_name_filled': this.checkNames(),
              'row_user_type_filled': this.checkUserTypes(),
              'row_department_mapped': Object.values(this.row_errors)
                  .filter(x => x.includes("invalid_department_name")).length == 0
          };

          this.checks = checks;
      },

      checkNames() {
          let ret = true;
          for (let p_index in this.people) {
              let p = this.people[p_index];
              if (!p[this.cols.name] ||
                  p[this.cols.name].trim() === "") {
                  ret = false;
                  this.row_errors[p_index] = ["row_name_filled"];
              }
          }
          return ret;
      },

      checkUserTypes() {
          let ret = true;
          for (let p_index in this.people) {
              let p = this.people[p_index];
              if (!p['user_type_id']) {
                  ret = false;
                  this.row_errors[p_index] = ["invalid_user_type"];
              }
          }
          return ret;
      },

      setCol(names, index) {
          for (let key of Object.keys(this.people[0])) {
              for (let n of names) {
                  if (key.toLowerCase() === n) {
                      this.cols[index] = key;
                      return;
                  }
              }
          }
      },


      mapDepartments() {
          return this.$http.get("organisation/department")
              .then((res) => {
                  let deps = this.flattenDepartments(res.body);
                  for (let i in this.people) {
                      let department_name = this.people[i][this.cols.department];
                      if (department_name == null) continue;

                      let id = deps[department_name];
                      if (id == null || id == undefined) {
                          this.row_errors[i] = ["invalid_department_name"]
                      }
                      this.people[i]['department_id'] = id;
                  }
              }, (err) => {

              })
      },

    flattenDepartments(departments, depth = 0) {
      let department_map = {};
      for (let x of Object.values(departments)) {
        department_map[x.name] = x.id;
        if (x.children.length > 0) {
            Object.assign(
                department_map,
                this.flattenDepartments(x.children, depth + 1)
            );
        }
      }
      return department_map;
    },

    mapUserTypes() {
        return this.$http.get("admin/user_type")
            .then((res) =>  {
                let ut_map = {};
                for (let ut of res.body) {
                    ut_map[ut.name] = ut.id;
                }
                for (let i in this.people) {
                    this.people[i]['user_type_id'] = ut_map[
                        this.people[i][this.cols.user_type]
                    ];
                }
            }, (err) => {

            })
    },

      setNameCol() {
          this.setCol([ 'naam', 'name', ], 'name');
      },

      setMailCol() {
          this.setCol(
              ['mail', 'email', 'e-mail',  'mailadres'],
              'mail'
          );
      },

      setDepartmentCol() {
          this.setCol(
              ['department', 'afdeling'],
              'department'
          );
      },

      setFunctionCol() {
          this.setCol(
              ['function', 'functie', 'rol'],
              'function'
          );
      },

      setUserTypeCol() {
          this.setCol(
              ['gebruikerstype', 'type', 'rol', 'user_type'],
              'user_type'
          );
      },
  },
};
</script>

<style lang="scss">

.import-preview-grid {
    display: grid;
    margin-bottom: 30px;
    grid-template-columns: 400px auto;
    grid-column-gap: 30px;

    ul { padding-left: 0px; }
    .check-item {
        list-style-type: none;
        margin-bottom: 5px;

        border-radius: 5px;
        padding:  5px;
        &.ok {
            color: $a3-lightgreen;
            border: 1px solid $a3-lightgreen;
            background-color: rgba($a3-lightgreen, .05);
        }
        &.err {
            color: $a3-red;
            border: 1px solid $a3-red;
            background-color: rgba($a3-red, .05);
        }
    }

    td > i {
        color: $general-orange;
    }
}
</style>
