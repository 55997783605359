<template>
  <div class="kpi-dashboard-card">

      <KpiGraph :measurements="measurements" :relations="relations"
        :kpi="kpi"
        :show_toolbar="false"
      />

      <template v-if="status == 'ok'">
        <div class="status-icon ok">
          <i class="fas fa-check-circle"></i>
        </div>
      </template>
      <template v-if="status == 'not-ok'">
        <div class="status-icon not-ok">
          <i class="fas fa-times-circle"></i>
        </div>
      </template>
      <template v-if="status == 'unknown'">
        <div class="status-icon unknown">
          <i class="fas fa-question-circle"></i>
        </div>
      </template>

      <div style="min-height: 50px">
        <h3
           class="kpi-dashboard-kpi-link"
           @click="$router.push(`/a3/${a3_document.id}/kpi/${kpi.id}`)"
          >{{ kpi.name }}</h3>
      </div>
  </div>
</template>

<script>
import KpiGraph from '@/components/elements/KpiGraph.vue';
import piValueCheck from '@/services/piValueCheck';

export default {
  components: {
      KpiGraph,
  },
  props: {
    kpi: {
      type: Object,
      required: true,
    },

    a3_document: {
      type: Object,
      required: true,
    },

    relations: {
      type: Object,
      required: false,
      default: () => ({
        parents: [],
        children: [],
      })
    }
  },

  data: () => ({
    status: null,
    measurements: [],
  }),

  mounted() {
    this.loadMeasurements();
  },

  methods: {
      checkStatus(measurement) {
        let tmin = piValueCheck.getTargetMin(this.kpi);
        let tmax = piValueCheck.getTargetMax(this.kpi);
        if ((tmin == null && tmax == null) || !measurement) {
          this.status = 'unknown';
          return;
        }

        this.status = piValueCheck.checkKpiValue(this.kpi, measurement) ? 'ok' : 'not-ok';
      },

      loadMeasurements() {
        if (this.a3_document == null) return;

        let url = `a3/${this.a3_document.id}/kpi/${this.kpi.id}/measurement`;

        let query_options = [];
        if (this.kpi.child_refs.length > 0) {
          query_options.push("with_children=true");
        }
        if (this.kpi.kpi_ref) {
          query_options.push(`ref_id=${this.kpi.kpi_ref.id}`);
        }

        if (query_options.length > 0) {
          url += "?" + query_options.join("&");
        }
        return this.$http.get(url)
          .then(res => {
            this.measurements = res.body;
            let last_measurement = this.measurements[0].values[0];
            this.checkStatus(last_measurement);
          })
      },
  }
}
</script>

<style lang="scss">
.kpi-dashboard-card {
  box-sizing: border-box;
  position: relative;
  width: 30%;
  border: 1px rgba($a3-green, .3) solid;
  padding: 10px;
  margin: 10px;
  display: inline-block;
  vertical-align: top;
  border-radius: 5px;

  .status-icon {
    background-color: white;
    border-radius: 50%;
    padding: 4px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 50px;
    transform: translate(14px, -14px);

    &.ok {
      color: #00bb00;
    }
    &.unknown {
      color: #aaaaaa;
    }
    &.not-ok {
      color: #bb0000;
    }
  }
}

.kpi-dashboard-kpi-link {
  transition: color .15s ease-out;

  &:hover {
    color: $a3-green;
    text-decoration: underline;
    cursor: pointer;
  }
}

</style>
