    <template>
    <div class="element-form"
      style="display: inline-block; width: 1000px; vertical-align: top; padding-left: 15px;">

      <div class="form-row">
        <h3>{{ $t('ui.layout.logo') }} </h3>

        <div class="form-field">
          <img v-show="preview_image != null" :src="preview_image"
               style="max-height: 250px; clear: both; display: block;
                margin-left: 210px; margin-bottom: 20px;
               "
               :style="{'background-color': layout_style.background_color}"
          />

          <label>Bestand</label>
          <input type="file"
               id="upload-layout-logo"
               class="upload-form-input"
               @change="onLogoFileChange"
          />

          <div style="margin-top: 5px">
            <label></label>
            <button class="a3-button danger"
                    v-if="preview_image != null"
                    @click="removeLogo"
              >
              <i class="fas fa-trash"></i> {{ $t('ui.layout.remove_logo') }}
            </button>
          </div>
        </div>
      </div>

      <div class="form-row">
        <h3>{{ $t('ui.layout.colors') }} </h3>

        <div class="form-field">
          <label>{{ $t('ui.layout.background_color') }} </label>
          <div style="display: inline-block">
            <ColorPicker v-model="layout_style.background_color" />
          </div>
          <FormError :error="errors._style.background_color" />
        </div>

        <div class="form-field">
          <label>{{ $t('ui.layout.header_color') }} </label>
          <div style="display: inline-block">
            <ColorPicker v-model="layout_style.header_color" />
          </div>
            <FormError :error="errors._style.background_color" />
        </div>

        <div class="form-field">
          <label>{{ $t('ui.layout.title_color') }} </label>
          <div style="display: inline-block">
            <ColorPicker v-model="layout_style.title_color" />
          </div>
            <FormError :error="errors._style.background_color" />
        </div>

        <h3>{{ $t('ui.layout.borders') }}</h3>
        <div class="form-field">
          <label>{{ $t('ui.layout.border_width') }} </label>
          <div style="display: inline-block">
            <input v-model="layout_style.border_width" type="number"
                                                       class="a3-input"
            />
          </div>
            <FormError :error="errors._style.border_width" />
        </div>

        <div class="form-field">
          <label>{{ $t('ui.layout.border_color') }} </label>
          <div style="display: inline-block">
            <ColorPicker v-model="layout_style.border_color" />
          </div>
            <FormError :error="errors._style.background_color" />
        </div>
      </div>

    </div>
</template>

<script>

import ColorPicker from '@/components/forms/ColorPicker.vue';

export default {
  components: {
    ColorPicker,
  },

  props: {
    layout_style: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    preview_image: null,
  }),

  mounted() {
    let default_colors = {
        'background_color': '#007385',
        'border_color': '#007385',
        'header_color': '#007385',
        'title_color': '#ffffff',
    };
    for (let [key, value] of Object.entries(default_colors)) {
        if (this.layout_style[key] == null) {
            this.layout_style[key] = value;
        }
    }

    if (!this.layout_style.image_preview) {
      this.layout_style.image_preview = null;
    } else {
      this.preview_image = this.layout_style.image_preview;
    }
  },

  methods: {
    onLogoFileChange(ev) {
      if (ev.target.files.length > 0) {
        let input_file = ev.target.files[0];
        let data_url = URL.createObjectURL(input_file);

        this.preview_image = data_url;
        this.layout_style.logo_file = input_file;
      }
    },

    removeLogo() {
      this.preview_image = null;
      this.layout_style.logo_file = null;
      this.layout_style.image_preview = null;
    },
  }
}
</script>

<style lang="scss">
.upload-form-input {
    padding: 15px;
    border: 1px $a3-green solid;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: rgba($a3-green, .1);
    }
}
</style>
