<template>
  <div class="element-form">
      <div class="form-row" v-if="!is_loading">
          <div class="form-field">
              <label>Adviseur</label>
              <div class="form-input">
                  <multiselect
                            id="advisor-multiselect"
                            v-model="selected_advisor"
                           :options="advisors"
                           :placeholder="$t('ui.organisation.select_advisor')"
                           :selectLabel="$t('ui.general.enter_select')"
                           :selectedLabel="$t('ui.general.selected')"
                           :deselectLabel="$t('ui.general.enter_deselect')"
                           :multiple="false"
                           :close-on-select="true"
                           track-by="account_id">

                    <template slot="singleLabel" slot-scope="props">
                      <strong>{{ props.option.person.name }}</strong><br>
                      <em>{{ props.option.organisation.name }}</em>
                    </template>
                    <template slot="option" slot-scope="props">
                      <strong>{{ props.option.person.name }}</strong><br>
                      <em>{{ props.option.organisation.name }}</em>
                    </template>
                  </multiselect>
              </div>
          </div>

            <div class="form-field">
              <label>{{ $t('ui.general.department') }} </label>
              <select class="a3-input" v-model="oa.department_id">
                <option :value="null"><em>*Geen*</em></option>
                <option v-for="d in departments"
                        :key="d.id"
                        :value="d.id">
                  {{ d.name }}
                </option>
              </select>
            </div>

            <div class="form-field">
                <label>{{ $t('ui.user_type.user_type') }} </label>
                <select
                  class="a3-input"
                  v-model="oa.user_type_id">
                    <option v-for="ut in user_types"
                            :value="ut.id"
                    >
                      {{ ut.name }}
                    </option>
                </select>
            </div>


          <div class="form-buttons">
            <button class="a3-button action"
                    @click="saveOrganisationAdvisor"
              >
              <i class="fas fa-plus"></i> {{ $t('ui.general.create') }}
            </button>

            <button class="a3-button cancel"
                    @click="$router.go(-1)"
                >
                <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
            </button>
          </div>
      </div>

      <LoadingOverlay :loading="is_loading" />
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect
  },

  data: () => ({
    advisors: null,

    selected_advisor: null,
    oa: {
        // advisor_id from selected_advisor
        department_id: null,
        user_type_id: null,
    },
    departments: null,
    user_types: null,
  }),

  computed: {
      is_loading() {
          return this.advisors == null
            || this.departments == null
            || this.user_types == null;
      }
  },

  mounted() {
    this.loadAdvisors();
    this.loadDepartments();
    this.loadUserTypes();
  },

  methods: {
    loadDepartments() {
      this.$http.get(`organisation/department`)
        .then((res) => {
           this.departments = this.flattenDepartmentList(res.body);
        });
    },

    loadUserTypes() {
      this.$http.get(`admin/user_type`)
        .then((res) => {
          this.user_types = res.body;
        });
    },

    loadAdvisors() {
      this.$http.get('root/advisor_account')
        .then((res) => {
          this.advisors = res.body
              .map(x => ({
                account_id: x[0].id,
                account: x[0],
                person: x[1],
                organisation: x[2],
              }));
        });
    },

    saveOrganisationAdvisor() {
      this.$http.put('organisation/advisor', {
          account_id: this.selected_advisor.account.id,
          department_id: this.oa.department_id,
          user_type_id: this.oa.user_type_id
      }).then(() => {
          this.$router.push('/organisation/advisor');
      }, err => {
          console.log("Could not save advisor");
      });
    },

    flattenDepartmentList(departments, depth = 0) {
      let list = [];
      for (let x of Object.values(departments)) {
        list.push({id: x.id, name: `${"----".repeat(depth)} ${x.name}` });
        if (x.children.length > 0) {
          list.push(...this.flattenDepartmentList(x.children, depth + 1));
        }
      }
      return list;
    },
  },
};
</script>
