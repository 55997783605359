<template>
  <WithOrganisationMenuContainer>
    <div>
      <h1>{{ $t('ui.organisation.import_people') }} </h1>

      <PeopleImportLoadExcel
        v-if="currentStep == 1"
        @input="loadPeople"
      />

      <PeopleImportPreview
        :people="people"
        v-if="currentStep == 2"
        @submit="handlePreviewSubmit"
        />

      <PeopleImportRunner
        v-if="currentStep == 3"
        :cols="cols"
        :people="people" />

    </div>
  </WithOrganisationMenuContainer>
</template>


<script>

import PeopleImportLoadExcel from '@/components/organisation/PeopleImportLoadExcel.vue';
import PeopleImportPreview from '@/components/organisation/PeopleImportPreview.vue';
import PeopleImportRunner from '@/components/organisation/PeopleImportRunner.vue';

export default {

  components: {
    PeopleImportLoadExcel,
    PeopleImportPreview,
    PeopleImportRunner,
  },

  data: () => ({
    currentStep: 1,
      cols: {},
    people: [],
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    }
  },

  mounted() {
  },

  methods: {
    loadPeople(people) {
      this.people = people;
      this.currentStep = 2;
    },

    handlePreviewSubmit(cols) {
        this.cols = cols;
        this.currentStep = 3;
    },
  }
}
</script>
