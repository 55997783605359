<template>
    <div class="ruler-container"
         :id='`ruler-${this._uid}`'
        :style="{'width': `${width}px`}">

        <div class="ruler-min-range" v-if="kpi.target_min"
                                     :style="{'width': `${min_range_width}px`}"
            >
        </div>
        <div class="ruler-ok-range" :style="{'width': `${ok_range_width}px`}"
            >
            <template v-if="kpi.target_min">{{ $n(kpi.target_min) }}
                <template v-if="kpi.unit">
                    {{ $t(`ui.kpi.unit_char.${kpi.unit}`) }}
                </template>
            </template>
        </div>
        <div class="ruler-max-range" v-if="kpi.target_max"
                                     :style="{'width': `${max_range_width}px`}"
            >
            {{ $n(kpi.target_max) }}

                <template v-if="kpi.unit">
                    {{ $t(`ui.kpi.unit_char.${kpi.unit}`) }}
                </template>
        </div>

        <div class="ruler-value-container"
             :style="{'left': `${ruler_value_x}px`}"
            >
            <div class="ruler-value-line"></div>
            <div class="ruler-value-text">
                <span class="value">{{ $n(measurement.value) }}</span>
                <template v-if="kpi.unit">
                    {{ $t(`ui.kpi.unit_char.${kpi.unit}`) }}
                </template>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    props: {
        kpi: {
            type: Object,
            required: true,
        },

        measurement: {
            type: Object,
            required: true
        },
    },

    data: () => ({
        // TODO: Dynamically scale these values
        scale_min: 0,
        scale_max: 100,
        width: 450,
    }),

    mounted() {
        this.width = document.getElementById(`ruler-${this._uid}`)
            .parentElement
            .offsetWidth;

        if (this.kpi.target_max > this.scale_max * .95) {
            this.scale_max = this.kpi.target_max * 1.2;
        }
        if (this.measurement.value > this.scale_max * .95) {
            this.scale_max = this.measurement.value * 1.2;
        }
    },

    computed: {
        ruler_value_x() {
            return (this.measurement.value - this.scale_min) / (this.scale_max - this.scale_min) * this.width;
        },
        min_range_width() {
            if (this.kpi.target_min == null) return null;
            let min_value = parseFloat(this.kpi.target_min);
            return (min_value - this.scale_min) / (this.scale_max - this.scale_min) * this.width;
        },
        ok_range_width() {
            return this.width - this.min_range_width - this.max_range_width;
        },
        max_range_width() {
            if (this.kpi.target_max == null) return null;
            let max_value = parseFloat(this.kpi.target_max);
            return (this.scale_max - max_value) / (this.scale_max - this.scale_min) * this.width;
        }
    }
}
</script>


<style lang="scss" scoped>

.ruler-container {
    height: 1.2em;
    background-color: white;
    border: 2px $a3-green solid;
    border-radius: 5px;
    position: relative;
}

.ruler-min-range {
    display: inline-block;
    background-color: $general-red;
    height: 100%;
    z-index: 10;
}
.ruler-ok-range {
    display: inline-block;
    background-color: $general-green;
    height: 100%;
    box-sizing: border-box;
    vertical-align: top;
    padding-left: 5px;
    color: rgba(white, .7);
}
.ruler-max-range {
    box-sizing: border-box;
    vertical-align: top;
    padding-left: 5px;
    color: rgba(white, .7);
    display: inline-block;
    background-color: $general-red;
    height: 1.2em;
}

.ruler-value-container {
    position: absolute;
    z-index: 1000;
    top: 0;

    .ruler-value-line {
        height: 1.5em;
        margin-top: -.3em;
        width: 4px;
        border-left: 2px white solid;
        border-right: 2px white solid;
        background-color: $a3-green;
    }
    .ruler-value-text {
        position: absolute;
        text-align: center;
        margin-left: -20px;
        width: 70px;
        padding: 5px;
        border-radius: 10px;
        top: 1.2em;
        border: 2px white solid;
        background-color: $a3-green;
        color: white;
        font-size: .8em;

        & > span {
            font-size: 1.4em;
            font-weight: bold;
        }
    }
}
</style>
