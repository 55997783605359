<template>
  <WithMenuContainer>
      <div class="tab-container">
          <div class="year-tab"
               @click="$router.push('/me/settings/preferences')"
               :class="{ selected: $route.matched.some(
                              ({name}) => name === 'user-settings-preferences'
                              )}"
               >
               <i class="fas fa-sliders-v-square"></i> {{ $t('ui.user_settings.preferences') }}
          </div>
          <div class="year-tab"
               @click="$router.push('/me/settings/security')"
               :class=" { selected: $route.matched.some(
                           ({ name }) => name === 'user-settings-security'
                           )}"
               >
               <i class="fas fa-lock"></i> {{ $t('ui.user_settings.security') }}
          </div>
          <div class="year-tab"
               @click="$router.push('/me/settings/notifications')"
               :class=" { selected: $route.matched.some(
                           ({ name }) => name === 'user-settings-notifications'
                           )}"
               >
               <i class="fas fa-bell"></i> {{ $t('ui.user_settings.notifications') }}
          </div>
          <div class="year-tab"
               @click="$router.push('/me/settings/a3_display')"
               :class=" { selected: $route.matched.some(
                           ({ name }) => name === 'user-settings-a3-display'
                           )}"
               >
               <i class="fas fa-telescope"></i> {{ $t('ui.user_settings.a3_display') }}
          </div>
      </div>

      <div class="plan-container">
          <h2>{{ $t('ui.user_settings.user_settings') }}</h2>
          <router-view></router-view>
      </div>
  </WithMenuContainer>
</template>


<script>

export default {

  data: () => ({
  }),

}
</script>

<style lang="scss">

</style>
