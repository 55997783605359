<template>
  <WithOrganisationMenuContainer>
    <div class="container">
      <h2>{{ $t('ui.user_type.create') }}</h2>

      <UserTypeForm
        :user_type="user_type"
        @submit="createUserType"
        @cancel="$router.push('/organisation/user_types')"
        />
    </div>
  </WithOrganisationMenuContainer>
</template>

<script>

import UserTypeForm from '@/views/admin/forms/UserTypeForm.vue';

export default {

  components: {
    UserTypeForm
  },

  data: () => ({
    user_type: {
      name: '',
      description: '',
      features: ['OrgView'],
      a3_rules: [{
        "a3_document_selection": "all",
        "features": ["A3Read"]
      }],
    },
  }),

  methods: {
    createUserType() {
      this.$http.post('admin/user_type', this.user_type)
        .then((res) => {
          this.$router.push('/organisation/user_types');
        })
    },
  },
};
</script>
