<template>
  <div>
    <h2 class="element-form-title"><i class="fas fa-tasks"></i>
        {{ $t('ui.action.create') }}
    </h2>

    <ActionForm
      :dataManager="dataManager"
      :interactionManager="interactionManager"
      :show_deploy="dataManager.getCapability('action.deploy')"
      :action="action"
      :child_departments="relations.children"
      :push_a3docs="push_a3docs"
      @submit="saveAction"
      @cancel="$router.go(-1)"
    />

  </div>
</template>

<script>

import ActionForm from '@/components/forms/ActionForm.vue';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {

  mixins: [ WithDocumentManager ],
  components: {
    ActionForm,
  },

  data: () => ({
    action: {
      a3doc_id: null,
      name: '',
      sbf_id: null,
      kpi_id: null,
      result_area: null,
      org_area: null,
      comment_result: null,
      start_date: null,
      target_date: null,
      assigned_to: null,
      child_refs: [],
    },
    push_a3docs: [],
  }),

  mounted() {
      this.$UiEventBus.$emit('shown', {key: 'action-create'});
      if (this.$route.query.org_area != null) {
          this.action.org_area = this.$route.query.org_area;
      }
      if (this.$route.query.sbf_id != null) {
        // TODO: This breaks down for the review function,
        this.action.sbf_id = parseInt(this.$route.query.sbf_id, 10);
      }

      if (this.$route.query.kpi_id != null) {
        this.action.kpi_id = parseInt(this.$route.query.kpi_id, 10);
      }
  },

  methods: {
    saveAction() {
      this.dataManager
        .createAction(this.action, this.push_a3docs)
        .then(() => {
          this.$UiEventBus.$emit('submit', {key: 'action-create'});
          this.interactionManager.backToDocument();
        });
    }
  }

}
</script>
