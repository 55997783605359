<template>
  <span class="fa-stack" style="font-size: .7em">
    <template v-if="element.target_type == 'Mission'">
      <i class="fas fa-heart fa-stack-2x"
         style="opacity: .4;"
         ></i>
    </template>
    <template v-if="element.target_type == 'Vision'">
      <i class="fas fa-road fa-stack-2x"
         style="opacity: .4;"
         ></i>
    </template>
    <template v-if="element.target_type == 'Sbf'">
      <i class="fas fa-flag-checkered fa-stack-2x"
         style="opacity: .4;"
         ></i>
    </template>
    <template v-if="element.target_type == 'Kpi'">
      <i class="fas fa-bullseye-arrow fa-stack-2x"
         style="opacity: .4;"
         ></i>
    </template>
    <template v-if="element.target_type == 'Action'">
      <i class="fas fa-tasks fa-stack-2x"
         style="opacity: .4;"
         ></i>
    </template>

    <template v-if="element.element_type == 'Create'">
      <i class="fas fa-plus fa-stack-1x"
         style="margin-left: .4em; margin-top: .4em;
                        font-size: 1.3em;
                        "
         ></i>
    </template>
    <template v-if="element.element_type == 'Edit'">
      <i class="fas fa-pencil fa-stack-1x"
         style="margin-left: .4em; margin-top: .4em;
                        font-size: 1.3em;
                        "
         ></i>
    </template>
    <template v-if="element.element_type == 'Comment'">
      <i class="fas fa-comment fa-stack-1x"
         style="margin-left: .4em; margin-top: .4em;
                        font-size: 1.3em;
                        "
         ></i>
    </template>
    <template v-if="element.element_type == 'Delete'">
      <i class="fas fa-times fa-stack-1x"
         style="margin-left: .4em; margin-top: .4em;
                        font-size: 1.3em;"
         ></i>
    </template>
  </span>
</template>

<script>

export default {
  props: {
    element: {
      type: Object,
      required: true
    },
  },
};
</script>
