<template>
    <v-tour name="a3_document_tour" :steps="steps"
            :callbacks="{'onNextStep': onNextStep,
                'onPreviousStep': onPreviousStep,
                'onSkip': onSkip,
            }"
            :options="options"
        ></v-tour>
</template>

<script>

export default {
    data: () => ({
        options: {
            labels: {
                buttonSkip: "Sluiten",
                buttonPrevious: "Vorige",
                buttonNext: "Volgende",
                buttonStop: "Klaar",
            },
        },
        steps: [
            {
                target: "#add-mission-container",
                header: {
                    title: "Missie toevoegen"
                },
                content: "We starten met de missie: Wie zijn wij? Wat doen wij? Voor wie? En waarom?",
            },
            {
                target: "#mission-edit-container",
                content: `Bijvoorbeeld:
                    <ul>
                <li>'Wij leveren de beste technologie'</li>
                <li>of: 'Bij ons ontwikkeld iedere leerling zich op zijn eigen manier'</li>
                </ul>`,
            },
            {
                target: "#mission-save-button",
                content: 'Klik op opslaan om de missie op te slaan',
            },
            {
                target: "#add-vision-container",
                header: {
                    title: "Visie toevoegen",
                },
                content: "Hoe geeft je de komende jaren invulling aan je missie?",
            },
            {
                target: "#vision-edit-container",
                content: `Bijv: 'Door persoonlijk contact zorgen we voor de beste
                service, zodat onze klanten hier gelukkig van worden.', <br> of <br>
                'Dit doen we door een focus op innovatie'`,
            },
            {
                target: "#vision-save-button",
                content: 'Klik op opslaan om de visie op te slaan',
            },
            {
                target: "#add-sbf-button",
                header: {
                    title: "Succesbepalende factor",
                },
                content: `Top!
                    Een succesbepalende factor geeft aan wat écht belangrijk is.
                    Klik op toevoegen om er nieuwe aan te maken
                `,
            },
            {
                target: "#sbf-name-input",
                content: `
                    Bijv. 'Persoonlijk contact met klanten', of 'Resultaat- en klantgericht werken'
                `,
            },
            {
                target: "#sbf-result-area-input",
                content: `
                    Geef aan op welk resultaatgebied deze SBF van toepassing is.
                `,
            },
            {
                target: "#sbf-submit-button",
                content: `En klik op opslaan om de SBF toe te voegen`,
            },
            {
                target: ".add-kpi-button",
                header: {
                    title: "Prestatie-indicatoren",
                },
                content: `Een prestatie-indicator geeft een concreet doel weer.
                    Klik op toevoegen om je eerste prestatie-indicator toe te voegen.
                `,
            },
            {
                target: "#kpi-name-input",
                content: `Bijv. '% Cliënten dat tevreden is met peroonlijk contact',
                    of '% ziekteverzuim'
                `,
            },
            {
                target: "#kpi-sbf-input",
                content: `
                    Koppel deze prestatie-indicator aan de SBF die je net hebt aangemaakt
                `,
            },
            {
                target: "#kpi-submit-button",
                content: `
                    En ook deze slaan we natuurlijk op! &#128521;
                `,
            },
            {
                target: ".add-action-button",
                header: {
                    title: 'Acties',
                },
                content: `
                    Tijd om een concrete actie toe te voegen!
                `,
            },
            {
                target: "#action-name-input",
                content: `
                    Bijv. 'Medewerkers stimuleren om persoonlijk contact te verbeteren',
                    <br> of <br> 'Monitoren of er klantgericht gewerkt wordt'
                `,
            },
            {
                target: "#action-kpi-input",
                content: `
                    Koppel deze actie aan de Prestatie-indicator die je net hebt
                    ingevoerd. Het resultaatgebied en de SBF worden automatisch gevuld.
                `,
            },
            {
                target: "#action-submit-button",
                content: `
                    En ook hier klikken we weer op opslaan!
                `,
            },
            {
                target: "#about-menu-button",
                content: `
                    <p>Gefeliciteerd! Je hebt de eerste stappen met je A3'tje gemaakt.</p>
                    <p>Klik op "Over A3 online" voor de handleiding en extra instructies,
                        als je dat nodig hebt.
                    </p>
                `,
            },
        ],
    }),

    computed: {
        tour() {
            return this.$tours['a3_document_tour'];
        }
    },

    mounted() {
        this.$tours['a3_document_tour'].start();
        this.$UiEventBus.$on('shown', this.handleUiShown);
        this.$UiEventBus.$on('edited', this.handleUiEdit);
        this.$UiEventBus.$on('submit', this.handleUiSubmit);
        this.$UiEventBus.$on('cancel', this.handleUiCancel);
    },

    methods: {
        onNextStep() {
            for (let x of [1,2,3,4,5,6,7,8]) {
                let current_step = parseInt(this.tour.currentStep);
                let next_step = this.steps[current_step + x];
                if (!next_step || document.querySelector(next_step.target) != null) {
                    if (x > 0) {
                        this.tour.currentStep = current_step + (x - 1);
                    }
                    return;
                }
            }
        },
        onPreviousStep() {
            for (let x of [1,2,3,4,5,6,7,8]) {
                let current_step = parseInt(this.tour.currentStep);
                let next_step = this.steps[current_step - x];
                if (!next_step || document.querySelector(next_step.target) != null) {
                    if (x > 0) {
                        this.tour.currentStep = current_step - (x - 1);
                    }
                    return;
                }
            }
        },

        onSkip() {
            this.$emit('close');
        },

        getStepIndexForTarget(target) {
            for (let x in this.steps) {
                if (this.steps[x].target == target) return x;
            }
            return null;
        },

        setStepByTarget(target) {
            this.tour.currentStep = this.getStepIndexForTarget(target);
        },

        handleUiShown(data) {
            if (data.key == 'mission-edit') {
                this.$nextTick(() => this.setStepByTarget('#mission-edit-container'));
            }
            if (data.key == 'vision-edit') {
                this.$nextTick(() => this.setStepByTarget('#vision-edit-container'));
            }
            if (data.key == 'sbf-create') {
                this.$nextTick(() => this.setStepByTarget('#sbf-name-input'));
            }
            if (data.key == 'kpi-create') {
                this.$nextTick(() => this.setStepByTarget('#kpi-name-input'));
            }
            if (data.key == 'action-create') {
                this.$nextTick(() => this.setStepByTarget('#action-name-input'));
            }
        },
        handleUiSubmit(data) {
            if (data.key == 'mission-edit') {
                this.$nextTick(() => this.setStepByTarget('#add-vision-container'));
            }
            if (data.key == 'vision-edit') {
                this.$nextTick(() => this.setStepByTarget('#add-sbf-button'));
            }
            if (data.key == 'sbf-create'){
                this.$nextTick(() => this.setStepByTarget('.add-kpi-button'));
            }
            if (data.key == 'kpi-create')  {
                this.$nextTick(() => this.setStepByTarget('.add-action-button'));
            }
            if (data.key == 'action-create') {
                this.$nextTick(() => this.setStepByTarget('#about-menu-button'));
            }
        },
        handleUiCancel(data) {
            if (data.key == 'mission-edit') {
                this.$nextTick(() => this.setStepByTarget('#add-mission-container'));
            }
            if (data.key == 'vision-edit') {
                this.$nextTick(() => this.setStepByTarget('#add-vision-container'));
            }
            if (data.key == 'sbf-create'){
                this.$nextTick(() => this.setStepByTarget('#add-sbf-button'));
            }
            if (data.key == 'kpi-create')  {
                this.$nextTick(() => this.setStepByTarget('.add-kpi-button'));
            }
            if (data.key == 'action-create') {
                this.$nextTick(() => this.setStepByTarget('.add-action-button'));
            }
        },
    },
}
</script>

<style>
.v-step {
    z-index: 100000000 !important;
}
</style>
