<template>
    <div style="height: 100%;" class="blue-scroll-bar">
        <MainMenu />
        <SheetMenu :a3doc_id="a3doc_id" />

        <div class="view-container" style="position: relative;">
            <slot></slot>
        </div>
    </div>
</template>

<script>

import MainMenu from '@/components/nav/MainMenu.vue';
import SheetMenu from '@/components/nav/SheetMenu.vue';

export default {
    components: {
        MainMenu,
        SheetMenu,
    },

    props: {
      a3doc_id: {
        type: String,
        required: true,
      },
    },
};
</script>

