<template>
  <div>
    <h1>{{ $t('ui.a3_share.share_a3') }}</h1>

    <p>
      {{ $t('ui.a3_share.explanation') }}
      
    </p>

    <div style="padding-bottom: 30px">
      <h2>{{ $t('ui.a3_share.publish') }} </h2>

      <CheckBox v-model="publish" /> 
        {{ $t('ui.a3_share.publish_via_link') }}

      <div id="publish-link" class="publish-link" v-if="key != null">
        {{ publish_url }}
      </div>

      <div class="publish-action-container">
        <span class="publish-action" data-clipboard-target="#publish-link">
          <i class="fas fa-check" v-if="copy_link_completed"></i>

          {{ $t('ui.a3_share.copy_link')}}
        </span>
      </div>
    </div>

    <div class="buttons">
      <button class="a3-button action" @click="savePublication">
        <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
      </button>
    </div>
  </div>
</template>

<script>

import clipboard from 'clipboard';


export default {

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    key: null,
    publish: false,

    copy_link_completed: false,

  }),

  computed: {
    publish_url() {
      if (this.key == null) return null;
      return `${window.location.origin}/public/a3/${this.key}`;
    },
  },

  mounted() {
    this.loadPublication();

    let c = new clipboard('.publish-action');
    c.on('success', () => {
      this.copy_link_completed = true;
      setTimeout(() => this.copy_link_completed = false, 2000);
    });
  },

  methods: {
    savePublication() {
      if (this.publish && this.key == null) {
        this.$http.post(`a3/${this.id}/publish`)
          .then(res => {
            this.key = res.body.key;
          }, () => {
            this.error_key = 'general.generic';
          })
      }
      if (!this.publish) {
        this.$http.delete(`a3/${this.id}/publish`)
          .then(() => { this.key = null })
      }
    },

    loadPublication() {
      this.$http.get(`a3/${this.id}/publish`)
        .then(res => {
          this.key = res.body.key;
          this.publish = this.key != null;
        })
    },
  },

}
</script>

<style lang="scss">

.publish-link {
  padding: 5px;
  border: 1px $a3-green solid;
  background-color: white;
  margin: 10px 0;
}


.publish-action-container {
  text-align: right;
}

.publish-action {
  cursor: pointer;
  color: $a3-green;
}

</style>
