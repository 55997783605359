<template>
  <WithOrganisationMenuContainer>
    <div class="container">
        <div style="float: right">
          <button class="a3-button action"
                  :class="{disabled: assign_count == 0}"
                  @click="show_confirmation = true"
              >
              <i class="fas fa-arrow-right"></i>
              {{ $t('ui.user_type.assign_to_button', [assign_count]) }}
          </button>
        </div>

      <h2>{{ $t('ui.user_type.assign_to_title', [user_type.name]) }}</h2>

      <div class="element-form">
        <div class="form-field" style="margin-bottom: 20px">
          <label><i class="fas fa-search"></i></label>
          <input v-model="search_input"
                 class="a3-input"
                 placeholder="Persoon zoeken"
          />
        </div>

        <div style="display: grid; grid-template-columns: repeat(4, auto)">
            <div></div>
            <div>
                <CheckBox v-model="show_only_with_account" />
                <label>Kan inloggen</label>
            </div>
            <div>
                <CheckBox v-model="show_only_without_user_type" />
                <label>Zonder gebruikerstype</label>
            </div>
        </div>
      </div>

      <table class="a3-table">
        <thead>
          <tr>
            <th>
                <CheckBox
                  :value="false"
                  @input="selectAll($event)"
                />
            </th>
            <th>{{ $t('ui.general.name') }} </th>
            <th>{{ $t('ui.accounts.mail') }} </th>
            <th>{{ $t('ui.user_type.user_type') }} </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="person in people_list" :key="person.id"
              @click="setPersonSelected(
                      !people_selected[person.id]
              , person.id)"
              >
            <td>
                <CheckBox :value="people_selected[person.id]"
              @input="setPersonSelected(!people_selected[person.id], person.id)"
                />
            </td>
            <td> {{ person.name }} </td>
            <td> {{ person.account ? person.account.mail : '-' }} </td>
            <td> {{ person.user_type ? person.user_type.name : '-' }} </td>
          </tr>
        </tbody>
      </table>

      <ConfirmationOverlay
          v-if="show_confirmation"
          @submit="assignUserType"
          @cancel="show_confirmation = false"
      >
          {{ $t('ui.user_type.assign_to_confirmation', [user_type.name, assign_count]) }}
      </ConfirmationOverlay>

    </div>
  </WithOrganisationMenuContainer>
</template>

<script>

export default {

  props: {
    user_type_id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    user_type: null,
    people: [],
    people_selected: {},
    search_input: null,

    show_confirmation: false,

    show_only_with_account: true,
    show_only_without_user_type: true,
  }),

  mounted() {
    this.loadUsertype();
    this.loadPeople();
  },

  computed: {
      people_list() {
          let list = this.people;

          if (this.show_only_with_account) {
              list = list.filter(p => p.account != null);
          }
          if (this.show_only_without_user_type) {
              list = list.filter(p => p.user_type == null);
          }

          if (this.search_input !== null &&
              this.search_input !== "") {
              list = list
                .filter(x => x.name.toLowerCase().includes(
                    this.search_input.toLowerCase()
                ));
          }

          return list;
      },

      assign_count() {
          return Object.values(this.people_selected)
            .filter(x => x)
            .length
      },
  },

  methods: {
    loadUsertype() {
      this.$http.get(`admin/user_type/${this.user_type_id}`)
        .then((res) => {
          this.user_type = res.body[0];
          this.user_type.a3_rules = res.body[1];
        });
    },

    loadPeople() {
      this.$http.get(`organisation/people`)
            .then(res => {
                this.people = res.body
                for (let p of this.people) {
                    this.$set(this.people_selected, p.id, false);
                }
            });
    },

    setPersonSelected(value, person_id) {
        this.people_selected[person_id] = value;
    },

    selectAll(value) {
        for (let p of this.people_list) {
            this.people_selected[p.id] = value;
        }
    },

    assignUserType() {
      let person_ids = Object.keys(this.people_selected)
        .filter((person_id) => this.people_selected[person_id])
        .map((person_id) => parseInt(person_id, 10));


      this.$http.put(
          `admin/user_type/${this.user_type_id}/assign_to`,
          { person_ids }
      )
        .then((res) => {
          this.$router.push('/organisation/user_types');
        })
    },
  },
};
</script>
