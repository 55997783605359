
import piValueCheck from '@/services/piValueCheck';

function createFocusMap(focusOptions, a3_document) {
  if (focusOptions == null || a3_document == null) return null;

  let selected_sbf_ids = focusOptions.sbf == null ? [] : focusOptions.sbf.map(x => x.id);
  let sbfFocusMap = {};
  for (let sbf of Object.values(a3_document.sbf)) {
    let sbfIsSelected = selected_sbf_ids.find(x => x === sbf.id) != null;
    sbfFocusMap[sbf.id] = sbfIsSelected;
  }

  if (!Object.values(sbfFocusMap).includes(true)) { sbfFocusMap = null; }

  let kpiFocusMap = {};
  for (let kpi of Object.values(a3_document.kpi).flat()) {
    kpiFocusMap[kpi.id] = isKpiInFocus(
      focusOptions,
      a3_document,
      kpi,
      sbfFocusMap,
    );
  }
  if (!Object.values(kpiFocusMap).includes(false)) { kpiFocusMap = null; }

  let actionFocusMap = {};
  for (let action of Object.values(a3_document.actions).flat()) {
    actionFocusMap[action.id] = isActionInFocus(
      focusOptions,
      a3_document,
      action,
      sbfFocusMap,
      kpiFocusMap,
    );
  }
  if (!Object.values(actionFocusMap).includes(false)) { actionFocusMap = null; }

  let focusMap = {
    sbf: sbfFocusMap,
    kpi: kpiFocusMap,
    action: actionFocusMap
  };

  let applyActionBackFilter = shouldApplyActionBackfilter(focusOptions)
  if (applyActionBackFilter) {
    kpiActionBackFilter(focusMap, a3_document);
  }
  if (applyActionBackFilter || shouldApplyKpiBackfilter(focusOptions)) {
    sbfKpiBackFilter(focusMap, a3_document);
  }


  return focusMap;
}


function sbfKpiBackFilter(focusMap, a3_document) {
  if (focusMap.kpi == null) return;

  let sbfs_selected_from_kpi = Object.values(a3_document.kpi).flat()
    // only include the kpi's that
    .filter(kpi => focusMap.kpi[kpi.id])
    .map(kpi => kpi.sbf_id);

  if (!focusMap.sbf) {
    focusMap.sbf = {};
    for (let sbf of Object.values(a3_document.sbf)) {
      focusMap.sbf[sbf.id] = true;
    }
  }

  for (let sbf_id of Object.keys(focusMap.sbf)) {
    if (!focusMap.sbf[sbf_id]) continue; // it is already filtered
    if (sbfs_selected_from_kpi.findIndex(x => x === parseInt(sbf_id)) == -1) {
      focusMap.sbf[sbf_id] = false;
    }
  }
}


function kpiActionBackFilter(focusMap, a3_document) {
  if (focusMap.action == null) return;

  let kpis_selected_from_actions = Object.values(a3_document.actions).flat()
    .filter(action => focusMap.action[action.id])
    .map(action => action.kpi_id);

  if (!focusMap.kpi) {
    focusMap.kpi = {};
    for (let kpi of Object.values(a3_document.kpi).flat()) {
      focusMap.kpi[kpi.id] = true;
    }
  }

  for (let kpi_id of Object.keys(focusMap.kpi)) {
    if (!focusMap.kpi[kpi_id]) continue; // it is already filtered
    if (kpis_selected_from_actions.findIndex(x => parseInt(x) === parseInt(kpi_id)) == -1) {
      focusMap.kpi[kpi_id] = false;
    }
  }
}


function isKpiInFocus(focusOptions, a3_document, kpi, sbfFocusMap) {
  if (!isSbfOfKpiInFocus(sbfFocusMap, kpi)) return false;

  if (focusOptions.kpi != null && focusOptions.kpi.length > 0) {
    let selected_ids = focusOptions.kpi.map(x => x.id);
    let is_selected = selected_ids.find(x => x == kpi.id);
    if (!is_selected) return false;
  }

  let measurement = kpi.measurement;
  if (!isKpiNormStatusInFocus(focusOptions.kpi_norm, kpi, measurement)) return false;

  if (!isKpiAsSteeringIndicatorInFocus(focusOptions.kpi_steering, kpi)) return false;
  if (!isKpiTypeInFocus(focusOptions.kpi_type, kpi)) return false;
  if (!isKpiUpdateAfterInFocus(focusOptions.kpi_update_after, kpi)) return false;
  if (!isKpiReportGroupInFocus(focusOptions.report_groups, kpi)) return false;

  return true;
}

function isSbfOfKpiInFocus(sbfResult, kpi) {
  if (sbfResult == null) return true;
  return sbfResult[kpi.sbf_id];
}

function isKpiNormStatusInFocus(normFocusOptions, kpi, measurement) {
  let should_filter_norm = Object.values(normFocusOptions).includes(true);
  if (!should_filter_norm) return true;

  if (measurement == null) {
    return normFocusOptions.unknown
  }


  let value_in_norm = piValueCheck.checkKpiValue(kpi, measurement);
  if (value_in_norm && normFocusOptions.ok) return true;
  if (!value_in_norm && normFocusOptions.not_ok) return true;

  return false;
}

function isKpiAsSteeringIndicatorInFocus(steeringKpiFocusOptions, kpi) {
  let should_filter_steering = Object.values(steeringKpiFocusOptions).includes(true);
  if (!should_filter_steering) return true;

  if (kpi.is_steering_kpi && steeringKpiFocusOptions.is_steering) {
    return true;
  }
  if (!kpi.is_steering_kpi && steeringKpiFocusOptions.no_steering) {
    return true;
  }

  return false;
}

function isKpiTypeInFocus(kpiTypeFocusOptions, kpi) {
  let should_filter_type = Object.values(kpiTypeFocusOptions)
    .includes(true);
  if (!should_filter_type) return true;

  if (kpi.kpi_type == 'kpi' && kpiTypeFocusOptions.kpi) {
    return true;
  }
  if (kpi.kpi_type == 'evaluation' && kpiTypeFocusOptions.evaluation) {
    return true;
  }

  return false;
}

function isKpiUpdateAfterInFocus(kpiUpdateAfterFocusOptions, kpi) {
    if (kpiUpdateAfterFocusOptions == null) return true;
    if (kpi.measurement == null) return false;

    let d = kpi.measurement.date;
    if (d == null) return false;

    return d > kpiUpdateAfterFocusOptions;
}

function isKpiReportGroupInFocus(reportGroupKpiFocusOptions, kpi) {
  if (reportGroupKpiFocusOptions == null || reportGroupKpiFocusOptions.length == 0) {
    return true;
  }

  let selected_management_groups = reportGroupKpiFocusOptions.map((x) => x.id)

  let result = kpi.management_group_ids
    .filter(x => selected_management_groups.includes(x));
  return result.length > 0;
}


function shouldApplyActionBackfilter(focusOptions) {
  if (focusOptions.action_people != null && focusOptions.action_people.length > 0) {
    return true;
  }
  if (focusOptions.action != null && focusOptions.action.length > 0) {
      return true;
  }

  if (Object.values(focusOptions.action_status).includes(true)) {
    return true;
  }
  if (Object.values(focusOptions.action_tasks).includes(true)) {
    return true;
  }
  if (focusOptions.milestone_before != null) { return true; }
  if (focusOptions.action_update_after != null) { return true; }

  return false;
}


function shouldApplyKpiBackfilter(focusOptions) {
  if (focusOptions.kpi != null && focusOptions.kpi.length > 0) {
    return true;
  }

  if (focusOptions.kpi_update_after != null) { return true; }
  if (focusOptions.report_groups != null && focusOptions.report_groups.length > 0) {
    return true;
  }
  if (Object.values(focusOptions.kpi_norm).includes(true)) {
    return true;
  }
  if (Object.values(focusOptions.kpi_steering).includes(true)) {
    return true;
  }
  if (Object.values(focusOptions.kpi_type).includes(true)) {
    return true;
  }

  return false;
}

function isActionInFocus(focusOptions, a3_document, action, sbfFocusMap, kpiFocusMap) {
  if (!isSbfOfActionInFocus(sbfFocusMap, action)) return false;
  if (!isKpiOfActionInFocus(kpiFocusMap, action)) return false;

  if (focusOptions.action != null && focusOptions.action.length > 0) {
    let selected_ids = focusOptions.action.map(x => x.id);
    let is_selected = selected_ids.find(x => x == action.id);
    if (!is_selected) return false;
  }

  if (focusOptions.action_people != null && focusOptions.action_people.length > 0) {
    let selected_person_ids = focusOptions.action_people.map(x => x.id);
    if (!action.assigned_to_person) return false;
    if (selected_person_ids.indexOf(action.assigned_to_person.id) == -1) return false;
  }

  if (!isActionStatusInFocus(focusOptions.action_status, action, action.status)) return false;
  if (!isActionTasksInFocus(focusOptions.action_tasks, action, action.tasks)) return false;
  if (!isActionMilestoneInFocus(focusOptions.milestone_before, action)) return false;
  if (!isActionUpdateAfterInFocus(focusOptions.action_update_after, action)) return false;

  return true;
}

function isSbfOfActionInFocus(sbfResult, action) {
  if (sbfResult == null) return true;
  return sbfResult[action.sbf_id];
}

function isKpiOfActionInFocus(kpiFocusMap, action) {
  if (kpiFocusMap == null) return true;
  return kpiFocusMap[action.kpi_id];
}

function isActionStatusInFocus(focusOptions, action, action_status) {
  let should_filter = Object.values(focusOptions).includes(true);
  if (!should_filter) return true;

  if (action_status == null) {
    return focusOptions.unknown
  }

  let value = parseInt(action_status.percent_done);
  let action_is_done = value == 100;
  if (action_is_done && focusOptions.done) {
    return true;
  }
  if (!action_is_done && focusOptions.not_done) {
    return true;
  }

  return false;
}

function isActionTasksInFocus(focusOptions, action, action_tasks) {
  let should_filter = Object.values(focusOptions).includes(true);
  if (!should_filter) return true;

  if (action_tasks.length == 0) { return focusOptions.no_tasks; }
  if (action_tasks.length > 0) { return focusOptions.has_tasks; }

  return false;
}

function isActionMilestoneInFocus(focusOptions, action) {
    if (focusOptions == null) return true;

    let target_date = action.target_date;
    if (action.next_task && action.next_task.target_date) {
        if (target_date == null || action.next_task.target_date < target_date) {
            target_date = action.next_task.target_date;
        }
    }
    if (target_date == null) return false;

    return target_date < focusOptions;
}

function isActionUpdateAfterInFocus(focusOptions, action) {
    if (focusOptions == null) return true;

    if (action.status == null) return false;

    let d = action.status.date;
    if (d == null) return false;

    return d > focusOptions;
}

export default { createFocusMap }
