<template>
    <div class="sublevel-container">
      <div class="arrow-container">
        <i class="fas fa-level-up fa-4x sublevel-arrow-icon"></i>
      </div>


      <A3IndexButton
        v-for="d in departments"
        :key="d.id"
        :name="d.name"
        :selected="selected_id == d.id"
        :a3="getA3ForDepartment(d.id)"
        :a3_rights="a3_rights"
        @select="selected_id = d.id"
        @new_a3="triggerNew(d)"
        />


      <div style="display: inline-block; vertical-align: middle">
          <span v-if="departments.length == 0">
            <em>Geen subafdelingen</em>
          </span>

          <button class="a3-button action" v-if="departments.length == 0 && $hasFeature('OrgEditDepartments')"
                @click="$router.push('/organisation')"
               style="display: inline-block; margin-left: 30px;"
              >
              <i class="fas fa-plus"></i> Afdelingen toevoegen
          </button>
      </div>

      <template v-if="selected_id">
        <A3ProgramPlanList
           :a3_list="a3_list"
           :parent_department_id="selected_id"
           :a3_rights="a3_rights"
           @new_a3="triggerNewProgram(selected_id)"
        />

        <template v-if="children.length > 0">
          <A3SubLevelContainer
            :departments="children"
            :a3_list="a3_list"
            :a3_rights="a3_rights"
            @new_a3="$emit('new_a3', $event)"/>
        </template>
      </template>
    </div>
</template>

<script>

import A3IndexButton from '@/components/organisation/A3IndexButton.vue';
import A3ProgramPlanList from '@/components/organisation/A3ProgramPlanList.vue';

export default {
  name: 'A3SubLevelContainer',

  components: {
    A3IndexButton,
    A3ProgramPlanList,
  },

  props: {
    departments: {
      type: Array,
      required: true
    },
    a3_list: {
      type: Array,
      required: true,
    },
    a3_rights: {
      type: Object,
      required: true,
    },
  },

  watch: {
    departments() {
      this.selected_id = null;
    },
  },

  computed: {
    children() {
      if (this.selected_id == null) return [];
      return this.departments
        .filter(x => x.id == this.selected_id)[0]
        .children;
    }
  },


  data: () => ({
    selected_id: null,
  }),

  methods: {
    getA3ForDepartment(department_id) {
      for (let x of this.a3_list) {
        if (x.department_id == department_id && x.matrix_name == null) {
          return x;
        }
      }

      return null;
    },

    triggerNew(department) {
      this.$emit('new_a3', {
        department_id: department.id,
      });
    },
    triggerNewProgram(selected_id) {
      this.$emit('new_a3', {
        department_id: selected_id,
        program_plan: true,
      });
    },
  }
};
</script>
