<template>
  <div class="department-container">
    <span class="name" @click="show_children = !show_children">
      <i class="fas fa-chevron-right"
        v-if="!show_children && department.children && department.children.length > 0">
      </i>
      <i class="fas fa-chevron-down"
        v-if="show_children && department.children && department.children.length > 0"></i>
      <i class="fas fa-minus" v-if="!department.children || (department.children && department.children.length == 0)"></i>
      {{ department.name }}

      <span
        style="float: right; color: #aaa;"
        v-if="can_edit"
        @click="$router.push(`/organisation/department/${department.id}/edit`)"
        > <i class="fas fa-pencil"></i> {{ $t('ui.general.edit').toLowerCase() }} </span>
    </span>

    <div>
      <div v-if="department.children" :class="{'hide': !show_children}">
        <DepartmentListElement
          v-for="child in department.children"
          :can_edit="can_edit"
          :key="`department-element-${child.id}`"
          :department="child" />

      <div v-if="show_form && can_edit" class="add-form">
        <input v-model="new_child.name"
               class="a3-input"
               @keyup.enter="saveChild"
               :id="`department-${department.id}-child-input`"
        />

        <button class="a3-button action" @click="saveChild">
          {{ $t('ui.general.save') }}
        </button>
        <button class="a3-button cancel" @click="show_form = false">
          {{ $t('ui.general.cancel') }}
        </button>
      </div>
      <div v-if="!show_form && can_edit">
        <span class="add-button" style="color: #aaa;" @click="showForm"><i class="fas fa-plus"></i>
          {{ $t('ui.general.create') }}
        </span>
      </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    department: {
      type: Object,
      required: true,
    },

    can_edit: {
      type: Boolean,
      required: false,
      default: false,
    }
  },


  data: () => ({
    new_child: {
      name: '',
    },
    show_form: false,
    show_children: false,
  }),

  mounted() {
    if (this.can_edit && this.department.children.length == 0) {
      this.show_children = true;
    }
  },

  methods:{
    showForm() {
      this.show_form = true;
      this.$nextTick(() => {
        document.getElementById(`department-${this.department.id}-child-input`).focus();
      });
    },

    saveChild() {
      this.new_child.parent_id = this.department.id;

      this.$http.post(`organisation/department`, this.new_child)
        .then(res => {
          let department = res.body;
          if (!department.children) { department.children = []; }
          this.department.children.push(department);

          this.new_child.name = null;
          document.getElementById(`department-${this.department.id}-child-input`).focus();
        });
    },
  },
}
</script>

<style lang="scss">
.department-container {
  &:last-child {
    margin-bottom: 0px;
  }

  div > div {
    transition: height .3s ease-out, opacity .3s linear;
  }
  div > div.hide {
    opacity: 0;
    display: none;
  }

  padding-left: 35px;

  .name {
    display: inline-block;
    padding: 2px 5px;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: $a3-green;
      color: white;
    }
  }
  .add-button {
    display: inline-block;
    margin-top: 5px;
    margin-left: 0px;
    padding-left: 45px;

    &:hover {
      color: $a3-green !important;
      cursor: pointer;
    }
  }

  .add-form {
    padding-left: 35px;

    input {
      padding: 5px;
      margin-right: 10px;
    }
    button {
      padding: 10px;
    }
  }
}
</style>
