
import LoggingIn from "@/views/LoggingIn.vue";
import Login from "@/views/Login.vue";

import SSOLogIn from "@/views/SSOLogin.vue";
import SSOLoggingIn from "@/views/SSOLoggingIn.vue";

import RequestResetPassword from "@/views/RequestResetPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";

import GetStarted from "@/views/GetStarted.vue";

export default [
  { path: "/login", name: "login", component: Login, meta: { pub: true } },
  { path: "/request_reset_password", name: "reset_password", component: RequestResetPassword, meta: { pub: true } },
  { path: "/reset_password", name: "reset_password", component: ResetPassword, meta: { pub: true } },
  { path: "/get_started", name: "get-started", component: GetStarted, meta: { pub: true } },
  { path: "/loading", name: "loading", component: LoggingIn, meta: { pub: true } },

  { path: "/sso", name: "sso-login", component: SSOLogIn, meta: { pub: true } },
  { path: "/sso/login", name: "sso-login", component: SSOLoggingIn, meta: { pub: true } },
];
