<template>
    <div class="error-overlay-container">
        <div class="error-overlay-inner">
            <h1><i class="fas fa-exclamation-triangle"></i> {{ title }} </h1>

            <p>
                <slot></slot>
            </p>

            <div class="buttons" style="margin-top: 30px">
                <button class="a3-button action" @click="$emit('close')">
                  {{ $t('ui.error_overlay.ok_i_understand') }}
                </button>
            </div>

            <div class="contact-footer" v-html="$t('ui.error_overlay.footer')">
            </div>
        </div>
    </div>
</template>

<script>


export default {
    props: {
        title: {
            type: String,
            required: false,
            default: "Er is een fout opgetreden",
        }
    }
}
</script>

<style lang="scss">

.error-overlay-container {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: rgba(black, .3);
    animation: fadeIn .2s ease-out;

    h1 {
        color: rgba(#dd0000, .6);
    }

    .error-overlay-inner {
        box-sizing: border-box;
        background-color: white;
        max-width: 960px;
        margin: 20px auto;
        border-radius: 15px;
        text-align: left;
        padding: 30px;
        animation: popIn .4s ease-out;
    }

    .contact-footer {
        margin-top: 30px;
    }

    p {
        margin-top: 10px;
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes popIn {
    0% {
        transform: translateY(40px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>
