<template>
  <WithOrganisationMenuContainer>
    <div class="container account-view">
      <template v-if="person_info">

          <div v-if="$hasFeature('Root') && account" style="text-align: right; margin-top: -50px">
            <button class="a3-button action"
                    @click="setAdvisorAccount(true)"
                    v-if="!account.advisor_account"
              >
              <i class="fas fa-user-chart"></i> {{ $t('ui.accounts.make_advisor') }}
            </button>
            <button class="a3-button action"
                    @click="setAdvisorAccount(false)"
                    v-if="account.advisor_account"
              >
              <i class="fas fa-times"></i> {{ $t('ui.accounts.unset_advisor') }}
            </button>
          </div>

          <div style="display: grid; grid-template-columns: 3fr 2fr;">

              <div>
                <i style="float: left; padding-right: 20px;" class="fas fa-4x fa-user"></i>
                <h1 style="margin-bottom: 5px;">{{ person_info.person.name }} </h1>

                <div style="margin-top: 100px">
                   <h3>{{ $t('ui.accounts.credentials') }} </h3>

                  <template v-if="account">
                      {{ $t('ui.accounts.can_login') }}
                      <strong> {{ account.mail }}</strong>

                    <div v-if="resetted_password != null">
                        {{ $t('ui.accounts.password_reset_to') }} : <strong>{{ resetted_password }}</strong>
                    </div>

                    <div v-if="$hasFeature('OrgEditAccount')">
                      <button class="a3-button action"
                              @click="resetPassword"
                        >
                        <i class="fas fa-redo"></i> {{ $t('ui.accounts.reset_password') }}
                      </button>
                      <InfoBox top="60px" left="0" tkey="account_delete">
                          <template v-slot:display>
                              <button class="a3-button danger"
                                      @click="show_delete_overlay = true"
                                  >
                                  <i class="fas fa-trash"></i> {{ $t('ui.accounts.delete_account') }}
                              </button>
                          </template>
                      </InfoBox>
                    </div>

                    <ConfirmationOverlay
                           v-if="show_delete_overlay"
                           @submit="deleteAccount"
                           @cancel="show_delete_overlay = false"
                     >
                        Weet je zeker dat je het account
                        <strong>{{ account.mail }}</strong> wilt verwijderen?
                    </ConfirmationOverlay>
                  </template>
                  <template v-else>
                    <em>{{ person_info.person.name }} kan niet inloggen</em>
                    <div v-if="$hasFeature('OrgEditAccount')">
                      <button class="a3-button action"
                              @click="$router.push(`/organisation/people/${person.id}/create_account?person_name=${person.name}`)"
                          >
                          <i class="fas fa-plus"></i> {{ $t('ui.accounts.create_account') }}
                      </button>
                    </div>
                  </template>

                </div>

              </div>

              <div>
                  <h2>Over mij</h2>

                  <h3>Initialen</h3>
                  {{ person.initials }}

                  <h3>Afdeling</h3>
                  {{ person_info.department ? person_info.department.name : '-' }}

                  <h3>Functie</h3>
                  {{ person.function_name }}

                  <h3>Gebruikerstype</h3>
                  {{ person_info.user_type ? person_info.user_type.name : '-' }}


                  <div v-if="$hasFeature('OrgEditRights')">
                      <span class="add-button-container" @click="$router.push(`/organisation/people/${person.id}/edit`)">
                        <i class="fas fa-pencil"></i> {{ $t('ui.general.edit') }}
                      </span>
                  </div>
              </div>
          </div>


        <div v-if="features != null && $hasFeature('OrgEditRights') && account">
            <h2>{{ $t('ui.accounts.rights') }} </h2>

            <div v-if="person_info.user_type == null">
                <em>{{ $t('ui.user_type.no_user_type_a3_rights_explanation') }}</em>
              <FeaturesView
                     :features="account_features"
                     :show_groups="['A3']"
              />
            </div>

          <FeaturesView
                 :features="account_features"
                 :show_groups="['A3Admin', 'Organisation', 'OrganisationAdmin']"
          />

          <template v-if="person_info.user_type == null">
              <button class="a3-button action"
                      v-if="$hasFeature('OrgEditRights')"
                      @click="$router.push(`/admin/accounts/${account.id}/features/edit`)">
                  <i class="fas fa-pencil"></i> {{ $t('ui.general.edit') }}
              </button>
          </template>
          <template v-else>
              <div>
                  <em>{{ $t('ui.accounts.rights_user_type_explanation') }}</em>
              </div>
          </template>
        </div>
      </template>
    </div>
  </WithOrganisationMenuContainer>
</template>

<script>

import FeaturesView from '@/components/admin/FeaturesView.vue';

export default {
  components: {
    FeaturesView,
  },
  props: {
    person_id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    person: null,
    person_info: null,
    account: null,
    organisation: null,
    features: null,
    account_auth: null,

    resetted_password: null,

    show_delete_overlay: false,
  }),

  watch: {
    account() {
      if (this.account == null) return;
      this.loadFeatures();
      this.loadAuthSettings();
    },
  },

  computed: {
      account_features() {
          if (!this.account_auth) return {};

          let features = {};
          for (let f of this.account_auth.features) {
              features[f] = 'Open';
          }
          return features;
      },
  },

  mounted() {
    this.loadPersonInfo();
  },

  methods: {
    loadPersonInfo() {
      this.$http.get(`admin/people/${this.person_id}`)
        .then(res => {
          this.person_info = res.body;
          this.organisation = res.body.organisation;
          this.person = res.body.person;
          this.account = res.body.account;
        })
    },

    loadFeatures() {
      this.$http.get(`admin/account/${this.account.id}/features`)
        .then(res => {
          this.features = res.body;
        })
    },

    loadAuthSettings() {
        return this.$http.get(`admin/account/${this.account.id}/auth`)
            .then(res => {
                this.account_auth = res.body;
                return res.body;
            });
    },

    deleteAccount() {
      this.$http.delete(`admin/account/${this.account.id}`)
        .then(() => {
            this.account = null;
            this.features = null;
            this.show_delete_overlay = false;
        })
    },

    resetPassword()  {
      this.$http.post('admin/reset_password', { account_id: this.account.id })
        .then(res => {
          this.resetted_password = res.body.password;
        })
    },

    setAdvisorAccount(value) {
      this.$http.put('root/advisor_account', {
        account_id: this.account.id,
        advisor_account: value
      }).then(() => {
        this.account.advisor_account = value;
      });
    },
  },
}
</script>

<style lang="scss">
.account-view {
  h2 {
    color: $a3-green;
    margin-bottom: 5px;
    opacity: .6;
    font-size: 32px;
  }

  button {
    margin-top: 15px;
  }
}
</style>
