<template>
    <div class="profile-render">
        <div class="profile-photo"
             style="display: none;"
          >
            <div class='profile-photo-placeholder'>
                {{ person.initials }}
            </div>
        </div>

        <div class="profile-information"
            >
            <h2>{{ person.name }}</h2>
            <div
              v-if="person.function_name"
              class="function-title">
              <i class="fas fa-chevron-right"></i>
              {{ person.function_name }}
            </div>

            <div v-if="advisor_profile" class="profile-contact-information">
              <div class="profile-text">
                <RichTextRender :value="advisor_profile.profile_text" />
              </div>

              <div class="contact-details">
                <div style="margin-top: 0px"
                     v-if="advisor_profile.mail"
                  >
                    <i class="fas fa-envelope"></i>
                    <a :href="`mailto:${advisor_profile.mail}`">
                      {{ advisor_profile.mail }}
                    </a>
                </div>

                <div style="margin-top: 10px;"
                     v-if="advisor_profile.phone"
                  >
                    <i class="fas fa-phone-alt"></i>
                    {{ advisor_profile.phone }}
                </div>
              </div>
            </div>
        </div>
    </div>
</template>



<script>

import RichTextRender from '@/components/forms/RichTextRender.vue';

export default {

    components: {
        RichTextRender,
    },

    props: {
        advisor_profile: {
            required: false,
            type: Object,
        },
        person: {
            required: true,
            type: Object,
        },
    },
}
</script>


<style lang="scss">

.profile-photo-placeholder {
    border-radius: 50%;
    background-color: gray;
    width: 125px;
    height: 125px;
    line-height: 125px;
    text-align: center;
    color: white;
    margin: 30px auto;
    cursor: pointer;
    transition: background-color .2s ease-out;
    background-color: lighten($a3-green, 10%);
    border: 5px solid $a3-green;
    font-size: 32px;
    font-weight: bold;
}

.profile-render {

    background-color: lighten($a3-green, 72%);
    padding: 20px;
    max-width: 850px;
    border-radius: 15px;

    box-shadow: 2px 2px 5px -3px rgba(0,115,133,1) inset;
    -moz-box-shadow: 2px 2px 5px -3px rgba(0,115,133,1) inset;
    -webkit-box-shadow: 2px 2px 5px -3px rgba(0,115,133,1) inset;

    a {
      color: black;
      text-decoration: none;
      transition: color .15s ease-out;

      &:hover {
        text-decoration: underline;
        color: $a3-green;
      }
    }

    .profile-contact-information .profile-text {
      margin-top: -10px;
      margin-bottom: 20px;
    }

    .profile-contact-information .contact-details {
      i {
        color: $a3-green;
        opacity: .3;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    h2 {
        margin-bottom: 0px;
        color: $a3-green;
        font-weight: bold;
        font-size: 1.4em;
    }
    .function-title {
        margin-bottom: 30px;
        color: $a3-green;
        font-size: 1em;
        opacity: .6;

        i {
          font-size: .7em;
          line-height: 1em;
          margin-top: -.2em;
          padding-left: 3px;
          vertical-align: middle;
        }
    }
}

</style>
