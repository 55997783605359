<template>

  <div id="filter-tab" @keyup.esc="$emit('close')">
    <div @click="$emit('close')" class="close-button">
      <i class="fas fa-times"></i>
    </div>

    <h2><i class="fas fa-filter"></i> {{ $t('ui.filter_tab.filter') }} </h2>

    <div class="display-tab-block">
      <button class="a3-button inverted"
              @click="clearFilter"
              :class="{'disabled': !focus_active}"
          >
          <span class="fa-stack" style="margin-left: -10px; margin-top: -2px;">
            <i class="fas fa-slash fa-stack-1x" style="opacity: .6; transform: scale(1.3)"></i>
            <i class="fas fa-filter fa-stack-1x" style="margin-left: 0px"></i>
          </span>
          {{ $t('ui.filter_tab.clear_filter') }}
      </button>
    </div>

    <div class="display-tab-block" v-if="a3_document && focusOptions">
      <h3>{{ $tc('definitions.terms.sbf', 2) }} </h3>

      <multiselect
                    id="sbf-multiselect"
                    v-model="focusOptions.sbf"
                    data-cy="filter-sbf-select"
                   :options="a3_document.sbf"
                   :placeholder="$t('ui.filter_tab.select_sbf')"
                   :selectLabel="$t('ui.general.enter_select')"
                   :selectedLabel="$t('ui.general.selected')"
                   :deselectLabel="$t('ui.general.enter_deselect')"
                   :multiple="true"
                   :close-on-select="false"
                   track-by="id"
                   label="name">
        <template v-slot:noOptions>
          {{ $t('ui.general.list_is_empty') }}
        </template>
      </multiselect>
    </div>

    <div class="display-tab-block" v-if="a3_document && focusOptions">
      <h3>{{ $tc('definitions.terms.result', 2) }} </h3>

      <multiselect
         v-model="focusOptions.kpi"
                   :options="kpi_options"
                   :multiple="true"
                   :close-on-select="false"
                   :placeholder="$t('ui.filter_tab.select_kpi')"
                   :selectLabel="$t('ui.general.enter_select')"
                   :selectedLabel="$t('ui.general.selected')"
                   :deselectLabel="$t('ui.general.enter_deselect')"
                   track-by="id"
                   label="name">
        <template v-slot:noOptions>
          {{ $t('ui.general.list_is_empty') }}
        </template>
      </multiselect>

      <div class="filter-element-container">
        <div class="filter-element-header">
          <h4>{{ $tc('ui.kpi.norm') }}
            <InfoBox tkey='filter_norm' :html="true" />
          </h4>
        </div>
        <div class="filter-element-options-container">
          <div class="filter-element-option">
            <CheckBox :id="'c_kpi_norm_ok'" v-model="focusOptions.kpi_norm.ok" />
            <label for="c_kpi_norm_ok"> {{ $t('ui.kpi.norm_ok') }} </label>
          </div>
          <div class="filter-element-option">
            <CheckBox id="c_kpi_norm_not_ok" v-model="focusOptions.kpi_norm.not_ok" />
            <label for="c_kpi_norm_not_ok"> {{ $t('ui.kpi.norm_notok') }} </label>
          </div>
          <div class="filter-element-option">
            <CheckBox id="c_kpi_norm_unknown" v-model="focusOptions.kpi_norm.unknown" />
            <label for="c_kpi_norm_unknown">{{ $t('ui.general.unknown') }} </label>
          </div>
        </div>
      </div>

      <div class="filter-element-container">
        <div class="filter-element-header">
          <h4>{{ $t('ui.kpi.kpi_type') }} </h4>
        </div>
        <div class="filter-element-options-container col2">
          <div class="filter-element-option">
            <CheckBox id="c_kpi_type_kpi"
              v-model="focusOptions.kpi_type.kpi" />
            <label for="c_kpi_type_kpi"> {{ $t('ui.kpi.type_kpi') }} </label>
          </div>
          <div class="filter-element-option">
            <CheckBox
              id="c_kpi_type_evaluation"
              v-model="focusOptions.kpi_type.evaluation" />
            <label for="c_kpi_type_evaluation"> {{ $t('ui.kpi.type_evaluation') }} </label>
          </div>
        </div>
      </div>

      <div class="filter-element-container" v-if="dataManager.hasLayoutOption('steering_check')">
        <div class="filter-element-header">
          <h4>{{ $t('ui.kpi.steering_indicator') }} </h4>
        </div>
        <div class="filter-element-options-container">
          <div class="filter-element-option">
            <CheckBox id="c_kpi_steering_is" v-model="focusOptions.kpi_steering.is_steering" />
            <label for="c_kpi_steering_is"> {{ $t('ui.general.yes') }} </label>
          </div>
          <div class="filter-element-option">
            <CheckBox id="c_kpi_steering_no" v-model="focusOptions.kpi_steering.no_steering" />
            <label for="c_kpi_steering_no"> {{ $t('ui.general.no') }} </label>
          </div>
        </div>
      </div>

      <div class="filter-element-container" v-if="focusOptions">
        <div class="filter-element-header">
            <h4>{{ $t('ui.filter_tab.kpi_update_after') }}</h4>
        </div>
        <div class="filter-element-options-container">
          <DateInput v-model="focusOptions.kpi_update_filter" />
        </div>
      </div>

      <div class="filter-element-container" v-if="management_groups != null
                  && dataManager.hasLayoutOption('report_groups')
          ">
        <div class="filter-element-header">
          <h4>{{ $t('definitions.terms.report_groups') }} </h4>
        </div>
        <div class="filter-element-options-container">
          <multiselect v-model="focusOptions.report_groups"
                       :options="management_groups"
                       :multiple="true"
                       :close-on-select="false"
                       :placeholder="$t('ui.filter_tab.select_report_group')"
                       :selectLabel="$t('ui.general.enter_select')"
                       :selectedLabel="$t('ui.general.selected')"
                       :deselectLabel="$t('ui.general.enter_deselect')"
                       track-by="id"
                       label="name" >
                     <template v-slot:noOptions>
                       {{ $t('ui.general.list_is_empty') }}
                     </template>
          </multiselect>

        </div>

      </div>
    </div>

    <div class="display-tab-block" v-if="a3_document && focusOptions">
      <h3>{{ $tc('definitions.terms.action', 2) }} </h3>

      <multiselect
         v-model="focusOptions.action"
                   :options="action_options"
                   :multiple="true"
                   :close-on-select="false"
                   :placeholder="$t('ui.filter_tab.select_action')"
                   :selectLabel="$t('ui.general.enter_select')"
                   :selectedLabel="$t('ui.general.selected')"
                   :deselectLabel="$t('ui.general.enter_deselect')"
                   track-by="id"
                   label="name">
        <template v-slot:noOptions>
          {{ $t('ui.general.list_is_empty') }}
        </template>
      </multiselect>

      <div class="filter-element-container" v-if="focusOptions">
        <div class="filter-element-header">
          <h4>{{ $t('ui.action.state') }} </h4>
        </div>
        <div class="filter-element-options-container">
          <div class="filter-element-option">
            <CheckBox id="c_action_status_done" v-model="focusOptions.action_status.done" />
            <label for="c_action_status_done"> {{ $t('ui.action.done') }} </label>
          </div>
          <div class="filter-element-option">
            <CheckBox id="c_action_status_not_done" v-model="focusOptions.action_status.not_done" />
            <label for="c_action_status_not_done"> {{ $t('ui.action.not_done') }} </label>
          </div>
          <div class="filter-element-option">
            <CheckBox v-model="focusOptions.action_status.unknown"
                      id="c_action_status_unknown"
            />
            <label for="c_action_status_unknown"> {{ $t('ui.general.unknown') }} </label>
          </div>
        </div>
      </div>

      <div class="filter-element-container" v-if="focusOptions">
        <div class="filter-element-header">
          <h4>{{ $tc('ui.action.tasks', 3) }} </h4>
        </div>
        <div class="filter-element-options-container">
          <div class="filter-element-option">
            <CheckBox id="c_action_tasks_no_tasks" v-model="focusOptions.action_tasks.no_tasks" />
            <label for="c_action_tasks_no_tasks"> {{ $t('ui.filter_tab.no_tasks') }} </label>
          </div>
          <div class="filter-element-option">
            <CheckBox id="c_action_tasks_has_tasks" v-model="focusOptions.action_tasks.has_tasks" />
            <label for="c_action_tasks_has_tasks"> {{ $t('ui.filter_tab.has_tasks') }} </label>
          </div>
        </div>
      </div>

      <div class="filter-element-container" v-if="focusOptions">
        <div class="filter-element-header">
          <h4>{{ $t('ui.filter_tab.assigned_to') }}</h4>
        </div>
        <div class="filter-element-options-container">
          <multiselect
             id="people-multiselect"
             v-model="focusOptions.action_people"
             :options="people"
             :placeholder="$t('ui.filter_tab.select_person')"
             :selectLabel="$t('ui.general.enter_select')"
             :selectedLabel="$t('ui.general.selected')"
             :deselectLabel="$t('ui.general.enter_deselect')"
             :multiple="true"
             :close-on-select="false"
             data-cy="filter-person-select"
             track-by="id"
             label="name">
             <template v-slot:noOptions>
               {{ $t('ui.general.list_is_empty') }}
             </template>
          </multiselect>
        </div>
      </div>

      <div class="filter-element-container" v-if="focusOptions">
        <div class="filter-element-header">
            <h4>{{ $t('ui.filter_tab.milestone_before') }}</h4>
        </div>
        <div class="filter-element-options-container">
          <DateInput v-model="focusOptions.milestone_before" />
        </div>
      </div>

      <div class="filter-element-container" v-if="focusOptions">
        <div class="filter-element-header">
            <h4>{{ $t('ui.filter_tab.action_update_after') }}</h4>
        </div>
        <div class="filter-element-options-container">
          <DateInput v-model="focusOptions.action_update_after" />
        </div>
      </div>
    </div>

    <div class="display-tab-block">
      <button class="a3-button inverted" @click="$emit('close')">
        <i class="fas fa-chevron-left"></i> {{ $t('ui.filter_tab.show_plan') }}
      </button>
    </div>
  </div>
</template>


<script>

import ManagementGroups from '@/mixins/ManagementGroups';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  mixins: [ManagementGroups],

  props: {
    a3_document: {
      required: true,
      validator: () => true,
    },

    dataManager: {
        type: Object,
        required: true,
    },

    focusOptions_prop: {
      type: Object,
      required: false,
    },

    focus_active: {
        type: Boolean,
        required: false,
        default: false,
    },

    visible: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data: () => ({
      focusOptions: null,
  }),

  computed: {
    kpi_options() {
      if (!this.a3_document) return [];

      return Object.values(this.a3_document.kpi).flat();
    },
    action_options() {
      if (!this.a3_document) return [];

      return Object.values(this.a3_document.actions).flat();
    },
    people() {
      if (!this.a3_document) return [];

      let person_map = {};
      Object.values(this.a3_document.actions)
        .flat()
        .map((action) => action.assigned_to_person)
        .filter((person) => person !== null)
        .forEach((person) => {
          if (!person_map[person.id]) {
            person_map[person.id] = person;
          }
        });
      return Object.values(person_map);
    }
  },

  beforeMount() {
    if (!this.focusOptions) {
      this.initFocusOptions();
     }
  },

  watch: {
    a3_document() {
      if (this.focusOptions != null) return;
      this.initFocusOptions();
    },

    focusOptions: {
        deep: true,
        handler() {
            if (this.focusOptions.milestone_before == "") {
                this.focusOptions.milestone_before = null;
            }
            if (this.focusOptions.action_update_after == "") {
                this.focusOptions.action_update_after = null;
            }
            if (this.focusOptions.kpi_update_after == "") {
                this.focusOptions.kpi_update_after = null;
            }
            this.$emit('focusUpdate', this.focusOptions);
        }
    }
  },

  methods: {
    initFocusOptions() {
      let focusOptions = {
        sbf: [],
        kpi: [],
        kpi_update_after: null,
        report_groups: [],
        action: [],
        action_people: [],
        milestone_before: null,
        action_update_after: null,
      };
      this.$set(focusOptions, 'kpi_norm', {
        'ok': false,
        'not_ok': false,
        'unknown': false,
      });
      this.$set(focusOptions, 'kpi_steering', {
        is_steering: false,
        no_steering: false,
      });
      this.$set(focusOptions, 'kpi_type', {
        kpi: false,
        evaluation: false,
      });
      this.$set(focusOptions, 'action_status', {
        done: false,
        not_done: false,
        unknown: false,
      });
      this.$set(focusOptions, 'action_tasks', {
        no_tasks: false,
        has_tasks: false,
      });


      this.focusOptions = focusOptions;
    },

      clearFilter() {
          this.initFocusOptions();
          this.$emit('close');
      },

    toggleSbf(sbf_id) {
      let to_set = !this.focusOptions.sbf[sbf_id];
      this.$set(this.focusOptions.sbf, sbf_id, to_set);
      this.$emit('focusUpdate', this.focusOptions);
    }
  },


};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

#filter-tab {
  z-index: 2000;
  height: calc(100% - 30px);
  position: absolute;
  top: 0;
  right: 0;
  background-color: $a3-green;
  color: white;
  border-left: 2px $green-darkened solid;

  -webkit-box-shadow: -8px 3px 10px -5px rgba(0,0,0,0.49);
  -moz-box-shadow: -8px 3px 10px -5px rgba(0,0,0,0.49);
  box-shadow: -8px 3px 10px -5px rgba(0,0,0,0.49);

  transform-origin: right;

  padding: 15px;
  width: 650px;

  animation: .5s cubic-bezier(0, 0, 0, 1.01) slidein;
}
/*
@keyframes slidein {
  from { transform: translateX(700px); }
  to { transform: translateX(0); }
}
*/

@keyframes slidein {
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
}


.display-tab-block {
  margin-top: 30px;
  margin-bottom: 40px;

  h3 {
    margin-bottom: 5px;
    opacity: .6;
    font-size: 1.4em;
  }

  .display-tab-element {
    margin-top: 3px;
  }
}

.filter-element-container {
  padding: 5px 0px;
  vertical-align: middle;
  .filter-element-header {
    width: 20%;
    display: inline-block;
    text-align: right;
  vertical-align: middle;
    h4 {
      padding-right: 10px;
      margin: 0;
    }
  }

  .filter-element-options-container {
    width: 80%;
    display: inline-block;
    vertical-align: middle;

    &.col2 > .filter-element-option {
      width: 50% !important;
    }

    .filter-element-option {
      label {
        cursor: pointer;
      }
      box-sizing: border-box;
      padding: 5px;
      width: 33.3333%;
      display: inline-block;
      border: 1px rgba(white, .4) solid;
      border-right: 0px;

      &:last-child {
        border-right: 1px rgba(white, .4) solid;
        border-radius: 0px 5px 5px 0px;
      }
      &:first-child {
        border-radius: 5px 0px 0px 5px;
      }
    }
  }

  .sbf-filter-element {
    cursor: pointer;
    &:hover {
      color: lighten($a3-green, 20%);
    }
  }
}
</style>
