<template>
    <div>
        <div v-if="!data_set"
             style="text-align: center; margin-top: 30px">
          <i class="fas fa-question-circle"></i>
          {{ $t('ui.kpi.no_values') }}
        </div>
        <div v-if="!chart_enabled"
          style="text-align: center;"
          :style="{'height': height}"
          >
          <i class="fas fa-spinner fa-spin fa-4x"></i>
          {{ data_set.length }}
        </div>
  
        <div
          :id="`horizontal-${id}-org-area-graph`">
        </div>
    </div>
  </template>

<script>

import ApexCharts from 'apexcharts';

export default {


  props: {
    id:{
        type: String,
        required: true,
    },
    data_set: {
      type: Object,
      required: true,
      default: null,
    },
    height: {
      type: String,
      required: false,
      default: '300px'
    },
    color: {
      type: String,
      required: false,
      default: '#0174b7'
    }
  },

  data: () => ({
    chart_enabled: false
  }),

  mounted() {
    this.renderChart();
  },

  methods: {
      renderChart() {
        let data = [];
        for (const [key, value] of Object.entries(this.data_set)) {
            data.push({x: value.name, y: value.value});
        }
        let options = {
          chart: {
            type: 'bar',
            toolbar: { show: true },
            animations: {enabled: false},
            width: '100%'
          },
          tooltip: {
            enabled: false
          },
          colors: 
            [this.color]
          ,
          series: [{
            data: data
          }],
          xaxis: { type: 'numeric' },
        }

        
        let chart = new ApexCharts(
          document.querySelector(`#horizontal-${this.id}-org-area-graph`),
          options
        );
        
        chart.render();
        this.chart_enabled = true;
      }
  },
}
</script>

