<template>
    <RichTextRender :value="vision" />
</template>

<script>

import RichTextRender from '@/components/forms/RichTextRender.vue';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {

    mixins: [WithDocumentManager],

    components: {
        RichTextRender
    },

    computed: {
        vision() {
            return this.dataManager.getVision();
        },
    },
};
</script>
