<template>
    <RichTextRender :value="mission" />
</template>

<script>

import RichTextRender from '@/components/forms/RichTextRender.vue';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {

    mixins: [WithDocumentManager],

    components: {
        RichTextRender
    },

    computed: {
        mission() {
            return this.dataManager.getMission();
        },
    },
};
</script>
