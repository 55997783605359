
import Vue from "vue";

import { A3DataManager } from "./data";

export class PublicA3DataManager implements A3DataManager {

  public a3Document: any;

  private publishKey: string;
  private $http: any;

  constructor(publishKey: string) {
    this.publishKey = publishKey;
    this.$http = (Vue as any).http;
  }

  public loadDocument(): Promise<object> {
    return this.$http
      .get(`public/a3?key=${this.publishKey}`)
      .then((res) => {
        this.a3Document = res.body;
        this.a3Document.meta.relations = {
          children: res.body.children,
          parents: res.body.parents,
        };
        return this.a3Document;
      });
  }
  public getCapability(): boolean {
    // TODO: Fix the get capability function
    return false;
  }

  public getResultAreas(): object[] {
    return this.a3Document.meta.layout.structure.result_areas;
  }
  public getResultArea(key: string): object {
    const ra = (this.getResultAreas() as any)
        .find((area) => area.key === key);
    if (ra == null) {
      return {
          color: "#444",
          key,
          name: "??",
      }
    }
    return ra;
  }
  public getResultAreaColors(): object {
    if (this.a3Document == null) {
      // return default
      return {};
    }

    const colorMap = {};
    for (const resultArea of this.a3Document.meta.layout.structure.result_areas) {
      colorMap[resultArea.key] = resultArea.color;
    }
    return colorMap;
  }

  public getOrgAreas(): object[] {
    return this.a3Document.meta.layout.structure.org_areas;
  }
  public getOrgArea(key: string): object {
    let org_area = (this.getOrgAreas() as any)
        .find((area) => area.key === key);

    if (org_area == null) {
      return { color: "#444", key, name: "??" };
    }
    return org_area;
  }

  public getMission(): string {
    return this.a3Document.mission;
  }
  public saveMission(mission: string): Promise<object> {
    return new Promise((r) => r({}));
  }
  public getVision(): string {
    return this.a3Document.vision;
  }
  public saveVision(vision: string): Promise<object> {
    return new Promise((r) => r({}));
  }
  public createSbf(sbf: object, pushTo?: number[]): Promise<object> {
    return new Promise((r) => r({}));
  }

  public getSbfList(): any {
    return this.a3Document.sbf;
  }
  public getSbf(sbfId: string): any {
    const intSbfId = parseInt(sbfId, 10);
    return Object
      .values(this.a3Document.sbf)
     .find((sbf: any) => sbf.id === intSbfId);
  }
  public saveSbf(_sbf: object, _pushTo?: number[]): Promise<object> {
    return new Promise((r) => r({}));
  }

  public getKpiList(resultArea?: string): any[] {
    if (this.a3Document == null) { return []; }
    if (resultArea) { return this.a3Document.kpi[resultArea]; }

    return Object.values(this.a3Document.kpi).flat();
  }
  public getKpi(kpiId: string): any {
    if (this.a3Document === null) { return null; }
    const intKpiId = parseInt(kpiId, 10);

    return Object
      .values(this.a3Document.kpi)
      .flat()
      .find((kpi: any) => kpi.id === intKpiId);
  }
  public createKpi(kpi: object, pushTo?: number[]): Promise<object> {
    return new Promise((r) => r({}));

  }
  public saveKpi(kpi: object, pushTo?: number[]): Promise<object> {
    return new Promise((r) => r({}));
  }
  public loadKpiMeasurements(kpiId: number, includeChildren: boolean = false): Promise<object> {
    const url = `public/kpi_measurement/${kpiId}?key=${this.publishKey}`;
    return this.$http.get(url).then((res) => res.body);
  }

  public getAction(actionId: string): any {
    const intId = parseInt(actionId, 10);
    return Object.values(this.a3Document.actions)
      .flat()
      .find((action: any) => action.id === intId);
  }
  public getActionList(orgArea?: string): any[] {
    if (this.a3Document == null) { return []; }
    if (orgArea) { return this.a3Document.actions[orgArea]; }

    return Object.values(this.a3Document.actions).flat();
  }
  public createAction(action: any, pushTo?: number[]): Promise<object> {
    return new Promise((r) => r({}));
  }

  public loadActionStatusTree(actionId: number): Promise<object> {
    const url = `public/action_status/${actionId}?key=${this.publishKey}`;
    return this.$http.get(url)
        .then((res) => res.body);
  }
  public loadActionStatusList(actionId: number): Promise<object> {
    const url = `public/action_status/${actionId}?key=${this.publishKey}`;
    return this.$http.get(url)
        .then((res) => res.body);
  }
  public loadActionTaskList(actionId: number): Promise<any> {
    const url = `public/action_tasks/${actionId}?key=${this.publishKey}`;
    return this.$http.get(url)
        .then((res) => res.body);
  }

  public loadActionLinks(actionId: number): Promise<any> {
    return new Promise((r) => r({}));
  }

  // createAction
  public saveAction(action: object): Promise<object> {
    return new Promise((r) => r({}));
  }


  public getLayoutOption(option: string): any {
      return this.a3Document.meta.layout.options[option];
  }
  public hasLayoutOption(option: string): any {
      return this.getLayoutOption(option) === true;
  }

}
