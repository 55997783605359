<template>
  <div>
    <h2>{{ $t(`ui.change_order.${mode}`) }} </h2>

    <p style="margin-bottom: 30px; font-style: italic" >
        {{ $t('ui.change_order.explanation') }}
    </p>

    <div id="order-element-container">
      <div v-for="(el, index) in list" :key="el.id" class="order-element"
                                                                 :data-id="el.id"
        >
        {{ index + 1 }}. {{ el.name }}
      </div>
    </div>

    <div class="button-container" style="margin-top: 30px">
      <button class="a3-button action" @click="saveList">
        <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
      </button>
      <button class="a3-button cancel" @click="$router.go(-1)">
        <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
      </button>
    </div>
  </div>
</template>

<script>

import dragula from 'dragula';

export default {

  props: {
    a3_document: {
      type: Object,
      required: true,
    },

    order_type: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    list: [],
    mode: null,
  }),

  mounted() {
    if (this.order_type == 'sbf') {
      this.list = this.a3_document.sbf;
      this.mode = 'sbf';
    }

    let kpi_lists = this.a3_document.meta.layout.structure.result_areas.map(x => x.key);
    if (kpi_lists.indexOf(this.order_type) > -1) {
      this.list = this.a3_document.kpi[this.order_type];
      this.mode = 'kpi';
    }

    let action_lists = this.a3_document.meta.layout.structure.org_areas.map(x => x.key);
    if (action_lists.indexOf(this.order_type) > -1) {
      this.list = this.a3_document.actions[this.order_type];
      this.mode = 'action';
    }

    this.initDragula();
  },

  methods: {
    initDragula() {
      let drake = dragula([document.getElementById('order-element-container')]);
      drake.on('drop', (el, target, source, sibling) => {
        let droppedId = el.getAttribute('data-id');
        let nextId = sibling == null ? null : sibling.getAttribute('data-id');

        let index = this.list.findIndex(x => x.id == droppedId);
        let listEl = this.list.splice(index, 1);

        let dropIndex = nextId == null ? this.list.length : this.list.findIndex(x => x.id == nextId);
        this.list.splice(dropIndex, 0, listEl[0]);

        this.$forceUpdate();
      });
    },
    saveList() {
      this.$http.put(`a3/${this.a3_document.id}/${this.mode}/order`, this.generateOrderMap())
        .then(() => {
          this.$router.go(-1);
        });
    },

    generateOrderMap() {
      // id => order
      let id_order_map = {};
      this.list.forEach((element, index) => {
        id_order_map[element.id] = index + 1;
      });
      return id_order_map;
    },
  },
}
</script>

<style lang="scss">

#order-element-container {
  .order-element {
    padding: 10px;
    cursor: grab;

    border: 1px $a3-green solid;
    border-radius: 5px;
    margin: 5px auto;

    &.gu-transit {
      background-color: $a3-green;
      color: white;
      cursor: grabbing;
    }
  }
}


.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

</style>
