<template>
  <div id="organisation-selector">
    <h3>Externe organisaties</h3>

    <!-- <input class="a3-input" placeholder="Zoeken... " />-->

    <div class="organisation-list">
      <div class="organisation-item"
      v-if="!$hasFeature('Root')"
           :class="{'selected': org.organisation.id == selectedOrganisationId}"
           v-for="org in organisations"
           :key="org.organisation_advisor.id"
           @click="setOrganisation(org.organisation)"
        >
        {{ org.organisation.name }}
      </div>
      <div class="organisation-item"
      v-if="$hasFeature('Root')"
           :class="{'selected': org.id == selectedOrganisationId}"
           v-for="org in organisations"
           :key="org.id"
           @click="setOrganisation(org)"
        >
        {{ org.name }}
      </div>
    </div>
    <div style="margin-top: 30px" v-if="$hasFeature('Root')">
      <button class="a3-button inverted"
            @click="$router.push('/organisation/new')">
        <i class="fas fa-plus"></i> Nieuwe organisatie
      </button>
    </div>
    <div style="margin-top: 30px"
         v-if="selectedOrganisationId != null"
      >
      <button class="a3-button inverted"
            @click="resetOrganisation">
        <i class="fas fa-times"></i> Externe organisatie sluiten
      </button>
    </div>

  </div>
</template>

<script>

import Vue from 'vue';

export default {

  data: () => ({
    organisations: [],
  }),

  computed: {
    selectedOrganisationId() {
      return Vue.http.headers.common['A3-Organisation-Id'];
    },
  },

  mounted() {
    this.loadOrganisations();
  },

  methods: {
    loadOrganisations() {
      this.$http.get('me/advisor_organisations')
        .then((res) => {
          this.organisations = res.body;
        })
    },

    setOrganisation(organisation) {
      Vue.http.headers.common['A3-Organisation-Id'] = `${organisation.id}`;

      return this.$http.get('me').then((res) => {
        this.$store.commit('auth/setOrganisation', res.body.organisation);
        this.$store.commit('auth/setOrganisationImpersonated', true);
        this.$store.commit('auth/setFeatures', res.body.features);
        this.$router.push('/a3');
        this.$emit('close');
        return;
      }, (err) => {

      });
    },

    resetOrganisation() {
      delete Vue.http.headers.common['A3-Organisation-Id'];
      this.$http.get('me').then((res) => {
        this.$store.commit('auth/setOrganisation', res.body.organisation);
        this.$store.commit('auth/setOrganisationImpersonated', false);
        this.$store.commit('auth/setFeatures', res.body.features);
        this.$router.push('/a3');
        this.$emit('close');
      })
    },
  },

};
</script>
