
import quill_render from 'quill-render';

function renderContent(value) {
  let render = quill_render;
  render.format.inline.color = function($, formatValue) {
    return $('<span>').css('color', formatValue);
  }
  render.format.inline.underline = function($) {
    return $('<span>').css('text-decoration', 'underline');
  }
  render.format.inline.strike = function($) {
    return $('<span>').css('text-decoration', 'line-through');
  }
  render.format.lineify.list = {
    group: function($) { return $('<ul>'); },
    line: function(_, b) {
      // nasty hack because quill-render doesn't support it out of the box
      if (b === "bullet") {
        this[0].parent.name = 'ul';
      } else {
        this[0].parent.name = 'ol';
      }
      this[0].name = 'li';
    }
  };

  if (value == null) { return null; }
  try {
    let x = JSON.parse(value).ops;
    for (let o of x) {
      if (o.insert && o.insert.image) {
        o.attributes.image = o.insert.image;
        o.insert = 1;
      }
    }
    return render(x);
  } catch (e) {
    return render([{ "insert": value }]);
  }
}

function renderPlainText(value) {
  try {
    let x = JSON.parse(value).ops;
    let result_text = '';

    for (let o of x) {
      if (o.insert) { result_text += o.insert + ' '; }
    }

    return result_text;
  } catch (e) {
    return '';
  }
}



export default { renderContent, renderPlainText }
