<template>
  <WithMenuContainer>
    <div class="container">
      <h1><i class="fas fa-user-plus"></i> {{ $t('ui.accounts.new_employee') }} </h1>

      <div class="element-form">
        <div class="form-field" v-if="$hasFeature('Root')">
            <h3>{{ $t('ui.general.organisation') }} </h3>
          <select class="a3-input" v-model="person.organisation_id">
            <option v-for="org in organisations"
                    :key="org.id"
                    :value="org.id">
              {{ org.name }}
            </option>
          </select>
        </div>

        <div class="form-field">
            <h3>{{ $t('ui.general.name') }} </h3>
          <input class="a3-input" v-model="person.person_name" />
        </div>

        <div class="form-field">
            <h3>{{ $t('ui.organisation.initials') }} </h3>
          <input class="a3-input" v-model="person.initials" />
        </div>

        <div class="form-field">
            <h3>{{ $t('ui.organisation.function') }} </h3>
          <input class="a3-input" v-model="person.function_name" />
        </div>

        <div class="form-field">
          <h3>
            <CheckBox v-model="person.create_account" />
            {{ $t('ui.accounts.create_account') }}?
          </h3>
        </div>

        <template v-if="person.create_account">
          <div class="form-field">
              <h3>{{ $t('ui.accounts.mail') }} </h3>
            <input class="a3-input" v-model="person.mail" />
          </div>

          <div class="form-field">
              <h3>{{ $t('ui.accounts.password') }} </h3>
              <em>{{ $t('ui.accounts.leave_empty_to_generate_password') }} </em>
            <input class="a3-input" v-model="person.password" />
          </div>
        </template>
      </div>

      <button class="a3-button action" @click="saveAccount">
          <i class="fas fa-plus"></i> {{ $t('ui.general.save') }}
      </button>
    </div>
  </WithMenuContainer>
</template>

<script>

export default {
  data: () => ({
    organisations: [],

    person: {
      organisation_id: null,
      person_name: '',
      initials: null,
      function_name: '',
      create_account: false,
      mail: '',
      password: '',
    },
  }),

  watch: {
    'person.person_name'(value) {
      let splitted = value.split(' ');
      if (this.initials != null) return;

      if (splitted.length > 1) {
        this.person.initials = splitted
          .map(x => x[0])
          .join("");
      }
    }
  },

  mounted() {
    this.loadOrganisations();

    if (this.$route.query.organisation_id && this.person.organisation_id == null) {
      this.person.organisation_id = parseInt(this.$route.query.organisation_id);
    }
  },

  methods: {
    loadOrganisations() {
      this.$http.get('organisation')
        .then((res) => this.organisations = res.body);
    },
    saveAccount() {
      this.person.organisation_id = parseInt(this.person.organisation_id);
      this.$http.post('admin/person', this.person)
        .then((res) => {
          if (this.$hasFeature('Root')) {
              this.$router.push(`/admin/organisation/${this.person.organisation_id}`);
          } else {
              this.$router.push(`/organisation/people/${res.body.person.id}`);
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/variables.scss";

.a3-table {
  border-spacing: 0;
  width: 100%;

  th {
    padding: 8px;
    border-bottom: 1px $a3-green solid;
  }
  thead {
      margin-bottom: 4px;
  }

  tbody tr {
    td {
      padding: 4px 10px;
    }
    cursor: pointer;
    transition: color .1s ease-in-out, background-color, .1s ease-in-out;

    &:hover {
      background-color: $a3-green;
      color: white;
    }
  }
}

.container {
  max-width: 960px;
  margin: 45px auto;
}
</style>
