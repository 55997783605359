<template>
      <div class="element-form">

        <div class="form-row">
          <div class="form-field">
              <label>{{ $t('ui.general.name') }} </label>
              <input data-cy="input-name" v-model="link.name" class="a3-input" autocomplete="off"
                />
            <FormError :error="errors.name" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-field">
              <label>{{ $t('ui.link.url') }} </label>
              <input data-cy="input-url" v-model="link.url" class="a3-input" autocomplete="off"
                />
            <FormError :error="errors.url" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-field">
              <label>{{ $t('ui.general.description') }} </label>
              <textarea v-model="link.description" class="a3-input" autocomplete="off" >
              </textarea>
            <FormError :error="errors.url" />
          </div>
        </div>

        <div class="form-buttons">
            <button @click="submit" class="a3-button action" data-cy="submit">
                <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
            </button>
            <button
                   v-if="link.id"
                  @click="$emit('delete')" class="a3-button danger" data-cy="delete">
                  <i class="fas fa-trash"></i> {{ $t('ui.general.delete') }}
            </button>
            <button @click="$emit('cancel')" class="a3-button cancel" data-cy="cancel">
                <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
            </button>
        </div>
      </div>
</template>

<script>

export default {
  props: {
    link: {
      type: Object,
      required: true,
    }
  },

  data: () => ({
    errors: {
      name: [],
      url: [],
      description: [],
    },
  }),

  methods: {
    validate() {
      if (this.link.name == null || this.link.name.length == 0) this.errors.name.push("may_not_be_null");
      if (this.link.url == null || this.link.url.length == 0) this.errors.url.push("may_not_be_null");

      return Object.values(this.errors)
        .flat()
        .length == 0;
    },

    submit() {
      // reset the errors
      for (let x of Object.keys(this.errors)) {
        this.errors[x] = [];
      }

      if (this.validate()) {
        this.$emit('submit');
      }
    },
  },

}
</script>
