<template>
  <div class="action-element" style="position: relative"
                  :class="{'status-cancelled': statusIsCancelled}"
    >
      <div style="display: inline-block; vertical-align: top;
           text-align: right;"
           :style="{'width': `${prefix_width}px`}"
        >
        <i class="fas" :class="icon"
           v-if="icon != null"
          ></i> {{ prefix }}
      </div>
      <div style="display: inline-block;
           vertical-align: top;
           padding-left: 3px;"
           :style="{'max-width': `calc(95% - ${prefix_width}px - 3px)`}"
        >
        <span class="action-list-element"
            :class="{
              'review-active': reviewAnnotations.filter(x => x.element_type === 'Comment').length > 0,
              'review-deleted': displayStrikethrough,
              'review-added': displayNewStyle
              }">


          <template v-if="typeof render_name === 'string'">
            {{ render_name }}
          </template>
          <template v-else>
            <template v-for="(row, index) in render_name">
              <span v-if="row.text" :key="index">{{ row.text }} </span>
              <span v-if="row.add" :key="index" style="color: green; text-decoration: underline;">{{ row.add }} </span>
              <span v-if="row.remove" :key="index" style="color: red; text-decoration: line-through;">{{ row.remove }} </span>
            </template>
          </template>
        </span>

      <template v-if="postfix != null">
        ({{ postfix }})
      </template>
      <template v-if="show_trail && trail == null">
        ( <i class="fas fa-exclamation"></i> )
      </template>

      <div class="review-comment-add-button"
           v-if="review != null && editable"
           @click.stop="$emit('comment', action)"
        >
        <i class="fas fa-comment-alt-plus"></i>
      </div>

      <div v-if="show_progress && currentStatus" style="display: inline-block;">
        <div class="action-progress-container" :class="`action-progress-${action.result_area}`">
          <div class="action-progress-inner"
               :style="{'transform': `scaleX(${currentStatus.percent_done / 100} )`}"></div>
        </div>
      </div>

      <div v-if="show_mood && currentMood"
           class="action-mood"
           :style="{'color': currentMood.color}"
          >
          <i class="fas" :class="`fa-${currentMood.icon}`"></i>
      </div>

      <div v-if="show_milestone && milestone_date"
        style="margin-top: 2px; padding-left: 20px;">
        <i class="fas fa-map-signs"></i>
        {{ milestone_date.date_formatted }}<template v-if="milestone_date.name">:</template>
        {{ milestone_date.name.substring(0, 20) }}
        <template v-if="milestone_date.name.length > 20">...</template>
      </div>

      <template v-if="show_plan_source && action.action_ref != null">
        <div style="padding-left: 21px">
          <i class="fa fa-rotate-90 fa-level-up"></i>
          <span style="padding-left: 5px">Vanuit:
              {{ A3NameFromRelations( relations, action.a3doc_id ) }}
          </span>
        </div>
      </template>
      <template v-if="show_pushed_to && action.child_refs && action.child_refs.length > 0">
        <div style="padding-left: 15px">
          <i class="fas fa-arrow-down"></i> Doorgezet:
            {{ action.child_refs.map((x) => A3NameFromRelations( relations, x.a3doc_id ))  }}
        </div>
      </template>

      <template v-if="show_tasks && action.tasks.length > 0">
        <ul>
          <li
            v-for="task in action.tasks"
            :class="{'task-completed': show_progress && task[0].done}"
            :key="task[0].id"
            >
            {{ task[0].name }}
            <template v-if="show_person && task[1]">
              ({{ task[1].initials }})
            </template>
          </li>
        </ul>
      </template>

      </div>
  </div>
</template>

<script>

import A3RelationHelper from '@/mixins/A3RelationHelper';
import WithDocumentManager from '@/mixins/WithDocumentManager';

import wordDiff from '@/../vendor/js/jsDiff';

export default {
  mixins: [A3RelationHelper, WithDocumentManager],

  props: {
    action: {
      type: Object,
      required: true,
    },

    editable: {
      type: Boolean,
      required: false,
      default: false,
    },

    show_numbers: { type: Boolean, required: true },
    show_mood: { type: Boolean, required: true },
    show_trail: { type: Boolean, required: true },
    show_check: { type: Boolean, required: true },
    show_person: { type: Boolean, required: true },
    show_progress: { type: Boolean, required: true },
    show_milestone: { type: Boolean, required: true },
    show_target_date_milestone: { type: Boolean, required: true },
    show_plan_source: { type: Boolean, required: false, default: false },
    show_pushed_to: { type: Boolean, required: false, default: false },
    show_tasks: { type: Boolean, required: false, default: false },
    show_cancelled_strikethrough: { type: Boolean, required: false, default: true },
  },

  computed: {
    currentStatus() {
      return this.action.status;
    },

    currentMood() {
        if (this.action.status == null
            || this.a3_document == null
        ) return null;

        return this.a3_document.meta.layout.options.moodmap
            .find((x) => x.key == this.currentStatus.mood);
    },

    statusIsCancelled() {
        if (this.currentStatus ==  null
            || this.a3_document == null
        ) return null;

        return this.currentStatus.cancelled
          && this.show_cancelled_strikethrough;
      ;
    },

    prefix_width() {
      if (this.show_numbers && this.show_check) {
        return 35;
      }
      return 18;
    },

    render_name() {
      if (this.review == null) { return this.action.name; }

      let reviewName = this.reviewAnnotations
        .find(x => x.element_type === 'Edit');
      if (reviewName == undefined) { return this.action.name; }
      return wordDiff.diffString(this.action.name, reviewName.new_value.name);
    },

    index() {
      return this.dataManager.getActionList(
        this.action.org_area
        ).findIndex(x => x.id == this.action.id) + 1;
    },

    prefix() {
      if (this.show_numbers) return `${this.index})`;
      return null;
    },

    postfix() {
      let to_show = [];
      if (this.show_progress) {
        if (!this.currentStatus) { to_show.push("0%") }
        else { to_show.push(`${this.currentStatus.percent_done}%`); }
      }
      if (this.show_trail && this.trail) {
        to_show.push(this.trail);
      }
      if (this.show_person && this.action.assigned_to_person) {
        to_show.push(this.action.assigned_to_person.initials);
      }
      if (to_show.length > 0) return to_show.join(", ");
      return null;
    },

    milestone_date() {
      let target_date = this.action.target_date;
      if (this.action.next_task && this.action.next_task.target_date) {
          if (target_date == null
            || !this.show_target_date_milestone
            || this.action.next_task.target_date < target_date) {
            return {
              'date': this.action.next_task.target_date,
              date_formatted: this.formatMilestoneDate(this.action.next_task.target_date),
              name: this.action.next_task.name
            };
          }
      }
      if (target_date == null || !this.show_target_date_milestone) {
        return null;
      }


      return {
        'date': this.action.target_date,
        date_formatted: this.formatMilestoneDate(this.action.target_date),
        name: ''
      }
    },


    icon() {
      if (!this.show_check && this.show_numbers) return null;
      if (!this.show_check) return 'fa-minus';

      if (!this.currentStatus) { return 'fa-minus'; }
      if (this.currentStatus.percent_done == 100) { return 'fa-check'; }
      return 'fa-minus';
    },

    trail() {
      let result_areas = this.a3_document
        .meta.layout
        .structure.result_areas;

      let kpi_path = "";
      for (let result_area of result_areas) {
        let kpi_rows = this.dataManager.getKpiList(result_area.key);

        for (let kpi_index in kpi_rows) {
          let kpi = kpi_rows[kpi_index];
          if (kpi.id == this.action.kpi_id) {
            let kpi_number = parseInt(kpi_index) + 1;
            kpi_path = `${result_area.index}.${kpi_number}`;
          }
        }
      }

      let sbf_index = this.dataManager.getSbfList()
          .findIndex((x) => x.id === this.action.sbf_id)
      ;
      let sbf_path = "";
      if (sbf_index > -1) {
        sbf_path = `${sbf_index + 1}`;
      }
      if (kpi_path === "" && sbf_path === "") { return null; }

      return `${kpi_path}/${sbf_path}`;
    },

    reviewAnnotations() {
      if (!this.review || !this.review.elements) return [];
      return this.review.elements
        .filter(x => x.action_id == this.action.id
          || x.target_type === 'Action' && x.new_value.id == this.action.id
        );
    },

    displayStrikethrough() {
      return this.reviewAnnotations.find(x => x.element_type === 'Delete') !== undefined;
    },
    displayNewStyle() {
      return this.reviewAnnotations
            .find(x => x.element_type === 'Create') !== undefined;
    }
  },

  methods: {
    formatMilestoneDate(d) {
      let isCurrentYear = (new Date(d)).getFullYear() == (new Date()).getFullYear();
      if (isCurrentYear) {
        return this.$d(new Date(d), 'noyear');
      } else {
        return this.$d(new Date(d), 'long');
      }
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/variables.scss';


.action-list-element {
  &.review-active {
    background-color: yellow;
    padding-left: 2px;
    padding-right: 2px;
  }

  &.review-deleted {
    text-decoration: line-through;
    opacity: .6;
  }

  &.review-added {
    text-decoration: underline;
    &:before {
      content: '(+)';
      color: $general-green;
    }
  }
}

.action-element {

  &.status-cancelled {
    > div {
      text-decoration: line-through !important;
    }
    opacity: .6;
  }

  .action-mood {
      overflow: hidden;
      position: relative;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      i {
          margin-top: -8px;
          margin-right: -8px;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 40px;
          opacity: .4;
      }
  }
}

.action-element .fa-minus {
  font-size: .7em;
  line-height: 1.8em;
  vertical-align: top;
}
.action-element .fa-question {
  opacity: .4;
}

.action-element:hover {
  .action-progress-container {
    border-color: white;
    .action-progress-inner {
      background-color: white;
    }
  }
}

.out-of-focus {
  .action-progress-container {
    border-color: #ccc !important;
    .action-progress-inner {
      background-color: #ccc !important;
    }
  }
}

@keyframes grow {
  from { transform: scaleX(0); }
}

.action-progress-container {
  display: inline-block;
  margin-left: 5px;
  width: 50px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  height: 10px;
  transition: border-color .2s ease-out;

  border-color: var(--main-item-color);

  .action-progress-inner {
    background-color: var(--main-item-color);

    height: 100%;
    width: 100%;
    transition: background-color .2s ease-out;
    animation: grow .4s ease-out;
    transform-origin: left;
  }
}

li.task-completed {
  opacity: .5;
  text-decoration: line-through;
}


</style>
