<template>
  <div>
     <div v-if="icon != null"
          class="action-list-icon">
         <i class="fas" :class="`fa-${icon}`"></i>
     </div>


    <div
      style="position: relative; z-index: 100;"
      v-for="(action, index) in actions"
      :key="'action-' + index"
      @click.exact="interactionManager.selectAction(action.id)"
      @click.shift="$emit('focus', action)"
      @contextmenu.exact.prevent="interactionManager.setContextMenu(
                               $event,
                               'action',
                               { action }
      )"
      @mouseover="$emit('el_mouseover', action)"
      @mouseleave="$emit('el_mouseleave', action)"
      :class="[resultAreaToClass(action.result_area), outOfFocus(action) ? 'out-of-focus' : '']"
      :style="{'--main-item-color': getActionColor(action)}"
      >
        <ActionListElement
          :action="action"
          :dataManager="dataManager"
          :interactionManager="interactionManager"
          @comment="dataManager.commentAction($event.id)"
          :editable="editable"
          :show_numbers="displayOptions.action_pi_numbers"
          :show_mood="displayOptions.action_mood
                      && dataManager.hasLayoutOption('mood_icons')"
          :show_trail="displayOptions.show_trail"
          :show_check="displayOptions.action_check"
          :show_person="displayOptions.action_person"
          :show_progress="displayOptions.action_progress"
          :show_milestone="displayOptions.action_milestone"
          :show_target_date_milestone="displayOptions.action_target_date_milestone"
          :show_plan_source="displayOptions.show_plan_source"
          :show_tasks="displayOptions.action_tasks"
          :show_pushed_to="displayOptions.show_pushed_to"
          :show_cancelled_strikethrough="displayOptions.action_cancelled_strikethrough"
        />

    </div>

    <div class="add-button-container add-action-button"
         v-if="editable && org_area != null"
         tabindex="0"
         :data-cy="`add-action-${org_area}`"
         @click="createAction"
         @keyup.enter="createAction"
         >
        <i class="fas fa-plus"></i> {{ $t('ui.action.create') }}
    </div>
    <div class="add-button-container"
      v-if="editable && actions.length > 1"
      tabindex="0"
      @click="interactionManager.changeActionOrder(org_area)"
      @keyup.enter="interactionManager.changeActionOrder(org_area)"
      >
      <i class="fas fa-sort"></i> {{ $t('ui.general.change_order') }}
    </div>
  </div>
</template>


<script>

import ResultAreaHelper from '@/mixins/ResultAreaHelper';
import ActionListElement from '@/components/elements/ActionListElement.vue';

import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {

  components: {
    ActionListElement,
  },

  mixins: [ResultAreaHelper, WithDocumentManager],

  props: {
    org_area: {
      type: String,
      required: false,
      default: null,
    },

    displayOptions: {
      type: Object,
      required: true,
    },

    focusMap: {
      type: Object,
      required: false,
      default: null,
    },

    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    }
  },

  computed: {
      actions() {
          this.dataManager.a3Document;
          return this.dataManager.getActionList(this.org_area);
      },
  },

  methods: {
    outOfFocus(action) {
      if (this.focusMap == null) return false;
      if (this.focusMap.action == null) return false;
      return !this.focusMap.action[action.id];
    },

    getActionColor(action) {
        if (this.a3_layout.structure.sbf_result_mapping == "one_to_one") {
            return this.area_colors[action.result_area];
        }
        if (this.a3_layout.structure.sbf_result_mapping == "one_to_many") {
            let sbf = this.dataManager.getSbf(action.sbf_id);
            if(sbf && sbf.color) return sbf.color;
        }
        return "#333333";
    },

    createAction() {
        let focusKpi = this.kpiFromFocusMap();
        let focusSbf = null;
        if (focusKpi == null) {
            focusSbf = this.sbfFromFocusMap();
        }
        this.interactionManager.createAction(
            this.org_area,
            focusKpi,
            focusSbf
        );
    },

    kpiFromFocusMap() {
        if (this.focusMap == null) return null;
        if (this.focusMap.kpi == null) return null;

        let selected = Object.entries(this.focusMap.kpi)
            .filter((value) => value[1]);
        // id of the first entry
        if (selected.length == 1) { return selected[0][0]; }
        return null;
    },

    sbfFromFocusMap() {
        if (this.focusMap == null) return null;
        if (this.focusMap.sbf == null) return null;

        let selected = Object.entries(this.focusMap.sbf)
            .filter((value) => value[1]);
        // id of the first entry
        if (selected.length == 1) { return selected[0][0]; }
        return null;
    }
  }
}
</script>

<style lang="scss">

#a3-document-container.print-mode .action-list-icon  {
  display: none;
}

.action-list-icon {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  top: -5px;
  right: -10px;
  font-size: 80px;
  opacity: .15;
  color: $a3-green;
}

</style>
