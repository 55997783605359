<template>
  <WithManageMenuContainer>
    <router-view></router-view>
  </WithManageMenuContainer>
</template>

<script>

import WithManageMenuContainer from '@/views/WithManageMenuContainer.vue';

export default {

    components: {
        WithManageMenuContainer,
    },

  data: () => ({
    a3: {
      organisation_id: null,
      department_id: null,
      timeframe_id: null,
      title: '',
      matrix_name: null,
    },

    errors: {
      program_name: [],
    },

    create_program_plan: false,

    organisation: null,
    department: null,
    timeframe: { name: '' },
    parent_department: null,
    parent_a3: null,

    elements_from_parent: {
      mission: true,
      vision: true,
      sbf: true,
      kpi: true,
      action: true,
    },
  }),

  mounted() {
    this.a3.timeframe_id = parseInt(this.$route.query.timeframe_id);
    this.a3.title = null;

    this.loadOrganisation();
    if (this.$route.query.department_id) {
      this.a3.department_id = parseInt(this.$route.query.department_id);
    }

    if (this.$route.query.program_plan) {
      this.create_program_plan = true;
    }

    this.loadTimeframe();
    this.loadDepartment();
    this.loadParentDepartment()
        .then(() => this.loadParentA3());
  },

  methods: {
    validate() {
      if (this.create_program_plan && (this.a3.matrix_name == null
        || this.a3.matrix_name.length == 0)) {
        this.errors.program_name.push('may_not_be_null');
      }

      return Object.values(this.errors)
        .flat()
        .length == 0;
    },

    submit() {
      // reset the errors
      for (let x of Object.keys(this.errors)) {
        this.errors[x] = [];
      }

      if (this.validate()) { this.create() }
    },

    create() {
        let payload = this.a3;
        if (this.parent_a3 != null) {
            let copy_from = {
                'a3_id': this.parent_a3.a3doc_id,
                mission: this.elements_from_parent.mission,
                vision: this.elements_from_parent.vision,
                sbf: this.elements_from_parent.sbf,
                kpi: this.elements_from_parent.kpi,
                action: this.elements_from_parent.action,
            };
            payload = Object.assign(payload, {copy_from});
        }

        this.$http.post('a3', payload)
        .then(res => {
          let id = res.body.id;
          this.$router.push(`/a3/${id}`);
        })
    },

    loadTimeframe() {
      this.$http.get('timeframe')
        .then((res) => {
          this.timeframe = res.body.filter(x => x.id == this.a3.timeframe_id)[0];
        })
    },

    loadOrganisation() {
      this.organisation = this.$store.getters['auth/organisation'];
      if (this.a3.department_id == null) {
        this.a3.title = this.organisation.name + ", " + this.timeframe.name;
      }
    },

    loadDepartment() {
      if (this.a3.department_id == null) return Promise.resolve();

      return this.$http.get(`organisation/department/${this.a3.department_id}`)
        .then((res) => {
          this.department = res.body;
          this.a3.title = this.department.name + ", " + this.timeframe.name;
        })
    },

    loadParentDepartment() {
      if (this.a3.department_id == null) { return Promise.resolve() }

      return this.$http.get(`organisation/department/${this.a3.department_id}/parent`)
        .then((res) => this.parent_department = res.body);
    },


    loadParentA3() {
      // Program plans to not have parents
      if (this.create_program_plan) return;

        // No department, so this A3 is the root, meaning we are the top level, so no parent
        if (this.a3.department_id == null) { this.parent_a3 = null; return; }

        // When we're the first department level, return the A3 ID of the organisation
        if (this.parent_department == null) {
            this.$http.get(
              `a3/org?timeframe=${this.a3.timeframe_id}`
            ).then((res) => {
              let roots = res.body.filter(x => x.matrix_name == null
                && x.department_id == null
              );
              if (roots.length > 0) {
                  this.parent_a3 = roots[0];
              }
            });
            return;
        }

        this.$http.get(`a3/org?department_id=${this.parent_department.id}&timeframe=${this.a3.timeframe_id}`).then((res) => {
            let roots = res.body.filter(x => x.matrix_name == null);
            if (roots.length > 0) {
              this.parent_a3 = roots[0];
            }
        });
    },
  },
}
</script>


<style lang="scss">
@import "@/assets/variables.scss";

.small-container {
  max-width: 960px;
  margin: 0 auto;
}

#new-a3-input {
  width: 100%;
  padding: 15px;
  font-size: 24px;
  border-radius: 5px;
  border: 1px $a3-green solid;
}

.new-a3-button {
  padding: 15px 45px;
  margin-top: 45px;
  background-color: $a3-green;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 24px;
  border-radius: 5px;

  &:hover {
    background-color: lighten($a3-green, 10%);
  }
}
</style>
