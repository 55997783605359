<template>
    <div style="padding-left: 30px">
        <div v-for="d in departments">
            <div
                v-if="planForDepartment(d.id) != null"
                @click="$emit('select', planForDepartment(d.id))"
                class="plan-select-element"
                >
                <i class="fas fa-chevron-right"></i> {{ d.name }}
            </div>
            <div v-else style="opacity: .3">
                <i class="fas fa-chevron-right"></i> {{ d.name }}
            </div>

            <div class="program-plan-select-container"
                 v-if="matrixPlans(d.id).length > 0"
                >
                <div v-for="p in matrixPlans(d.id)"
                     class="plan-select-element"
                     @click="$emit('select', p)"
                    >
                    <i class="fas fa-clipboard-list"></i>
                    {{ p.matrix_name }}
                </div>
            </div>


            <A3PlanSelectorInner
                @select="$emit('select', $event)"
                :departments="d.children"
                :a3_map="a3_map"
                :a3_rights="a3_rights"
              />
        </div>
    </div>
</template>

<script>

export default {
    name: "A3PlanSelectorInner",

    props: {
        departments: {
            type: Array,
            required: true,
        },
        a3_map: {
            type: Object,
            required: false,
            default: null
        },
        a3_rights: {
            type: Object,
            required: true,
        },
    },

    computed: {
        planForDepartment() {
            return (department_id) => {
                if (this.a3_map == null) return null;
                if (!this.a3_map[department_id]) return null;

                return this.a3_map[department_id]
                    .find(x => x.matrix_name == null);
            };
        },

        matrixPlans()  {
            return (department_id) => {
                if (this.a3_map == null) return [];
                if (!this.a3_map[department_id]) return [];

                return this.a3_map[department_id]
                    .filter(x => x.matrix_name != null);
            };
        },
    },
}
</script>

<style lang="scss">

.program-plan-select-container {
    margin-top: 0;
    padding-left: 30px;
}
</style>
