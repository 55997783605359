

function canDelete(a3_document, relations) {
    for (let sbf of a3_document.sbf) {
        if (sbf.child_refs == null || sbf.child_refs.length > 0) {
            return false;
        }
    }
    for (let kpi of Object.values(a3_document.kpi).flat()) {
        if (kpi.child_refs == null || kpi.child_refs.length > 0) {
            return false;
        }
    }
    for (let action of Object.values(a3_document.actions).flat()) {
        if (action.child_refs == null || action.child_refs.length > 0) {
            return false;
        }
    }

    return true;
}

export default { canDelete };
