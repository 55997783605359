<template>
  <div class="overlay-result-area"
    :style="{'--result-area-color': getSbfColor()}"
    v-if="sbf != null">
      <h3 class="overlay-type-header">
        {{ $tc("definitions.terms.sbf") }}
      </h3>
      <h2>{{ sbf.name }}</h2>

      <div style="margin-top: 45px">
        <div style="width: 60%; display: inline-block; vertical-align: top;">

          <h3>{{ $t('ui.sbf.chances_risks') }} </h3>
          <div style="margin-bottom: 45px">
            <RichTextRender :value="sbf.comment_chances_risks" />
          </div>

          <h3>
            <i class="fas fa-bullseye-arrow"></i>
            {{ $tc('definitions.terms.result', 2) }} </h3>

          <ul>
            <li v-for="kpi in kpis" :key="kpi.id"
                style="margin-bottom: 10px;"
              >
              <span
                  class="sbf-view-list-element"
                  @click="interactionManager.selectKpi(kpi.id)"
                  >{{ kpi.name }}</span> <br>

              <em>
                {{ $t('definitions.terms.result_area') }}:
                <template v-if="kpi.result_area_definition">
                  {{
                    kpi.result_area_definition.name ?
                    kpi.result_area_definition.name :
                    $t("definitions.result_area." + kpi.result_area)
                  }}
                </template>
                <template v-else>
                  ??
                </template>
              </em>
            </li>
          </ul>

          <div
               v-if="editable"
               class="add-button-container"
               @click="interactionManager.createKpiForSbf(sbf_id)"
               style="margin-top: 0px;"
              >
              <i class="fas fa-plus"></i> {{ $t('ui.kpi.create') }}
          </div>

          <h3 style="margin-top: 40px">
            <i class="fas fa-tasks"></i>
            {{ $tc('definitions.terms.action', 2) }} </h3>
          <ul>
            <li v-for="action in actions" :key="action.id"
                style="margin-bottom: 10px;"
              >
              <span
                  class="sbf-view-list-element"
                  @click="interactionManager.selectAction(action.id)"
                  >{{ action.name }}
              </span> <br>

              <em>
                {{ $t('definitions.terms.org_area') }}:
                {{
                  action.org_area_definition.name ?
                  action.org_area_definition.name :
                  $t("definitions.org_area." + action.org_area)
                }}
              </em>
            </li>
          </ul>

          <div
               v-if="editable"
               class="add-button-container"
               @click="interactionManager.createActionForSbf(sbf_id)"
               style="margin-top: 0px;"
              >
              <i class="fas fa-plus"></i> {{ $t('ui.action.create') }}
          </div>
        </div>

        <div class="information-column" style="width: 30%; display: inline-block; vertical-align: top;">
          <div v-if="a3_layout.structure.sbf_result_mapping == 'one_to_one'">
            <h4>{{ $t('definitions.terms.result_area') }} </h4>
            {{ result_area.name ? result_area.name : $t("definitions.result_area." + result_area.key) }}
          </div>

          <template v-if="sbf.sbf_ref != null && relations">
            <h4>{{ $t('ui.a3_document.pushed_from') }} </h4>
            <p>
              {{ A3NameFromRelations( relations, sbf.a3doc_id ) }}
            </p>
          </template>

          <template v-if="relations && relations.children && relations.children.length > 0">
            <h4><i class="fas fa-arrow-right"></i> {{ $t('ui.a3_document.pushed_to') }} </h4>
            <div v-for="c in relations.children"
                 :key="c.department.id"
                 class="push-pill"
                 :class="{'inactive': isPushPillInactive(c)}"
                 @click="() => { if (!isPushPillInactive(c)) { $router.push(`/a3/${c.a3_ref.a3doc_id}/sbf/${sbf_id}`) }}"
                 >
                 {{ c.department.name }}
            </div>
          </template>
        </div>
      </div>

      <div class="overlay-action-button-container" v-if="editable">
        <ButtonActionGroup
            :buttons="[
                      { label: 'ui.general.delete', icon: 'trash', class: 'danger', event: 'delete' },
                      { label: 'ui.general.copy_to', icon: 'copy', class: 'action', event: 'copy', feature: 'A3Management' },
                      { label: 'ui.changelog.changelog', icon: 'history', class: 'action', event: 'changelog' },
            ]"
            @copy="interactionManager.copyElements({ sbf: [sbf.id] })"
            @delete="interactionManager.deleteSbf(sbf.id)"
            @changelog="$router.push(`${sbf.id}/changelog`)"
        />
        <div class="a3-button action"
             data-cy="sbf-fillout-link"
             :class="{ 'disabled': !dataManager.getCapability('sbf.fillout') }"
             @click="interactionManager.filloutSbf(sbf.id)">
          <i class="fas fa-expand-alt"></i>
          {{ $t('ui.sbf_fillout.sbf_fillout') }}
        </div>
        <button class="a3-button action"
                data-cy="sbf-edit-button"
          @click="interactionManager.editSbf(sbf.id)">
          <i class="fas fa-pencil"></i>
          {{ $t('ui.general.edit') }}
        </button>
      </div>
  </div>
</template>

<script>
import RichTextRender from '@/components/forms/RichTextRender';
import ButtonActionGroup from '@/components/elements/ButtonActionGroup.vue';

import A3RelationHelper from '@/mixins/A3RelationHelper';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  mixins: [A3RelationHelper, WithDocumentManager],

  components: {
      RichTextRender,
      ButtonActionGroup,
  },

  props: {
    sbf_id: {
      type: String,
      required: true,
    },
    editable: {
        type: Boolean,
        required: false,
        default: false,
    },
  },

  computed: {
    actions() {
      if (this.a3_document == null) { return null; }
      return Object
        .values(this.a3_document.actions)
        .flat()
        .filter((action) => action.sbf_id == this.sbf_id)
        .map((action) => {
          action.org_area_definition =
            this.dataManager.getOrgArea(action.org_area);
          return action;
        })
      ;
    },
    kpis() {
      if (this.a3_document == null) { return null; }
      return Object
        .values(this.a3_document.kpi)
        .flat()
        .filter((kpi) => kpi.sbf_id == this.sbf_id)
        .map((kpi) => {
          kpi.result_area_definition =
            this.dataManager.getResultArea(kpi.result_area);
          return kpi;
        })
      ;
    },
    sbf() {
      return this.dataManager.getSbf(this.sbf_id);
    },

    result_area() {
      return this.dataManager.getResultArea(this.sbf.result_area);
    },
  },

  methods: {
    isPushPillInactive(child) {
      return child.a3_ref == null || this.sbf.child_refs.find((x) => x.a3doc_id == child.a3_ref.a3doc_id) == null;
    },

    getSbfColor() {
      if (this.a3_layout.structure.sbf_result_mapping == "one_to_one") {
        return this.area_colors[this.sbf.result_area]
      }
      if (this.a3_layout.structure.sbf_result_mapping == "one_to_many") {
        if (this.sbf.color) return this.sbf.color;
      }
      return '#333333';
    }
  },
};
</script>

<style lang="scss">
.status-table {
  width: 100%;

  cell-border-spacing: 0;
  border-collapse: collapse;

  text-align: left;

  td {
    width: 50%;
    padding: 5px;
  }
  tbody tr {
    transition: background-color .15s ease-out, color .15s ease-out;

    &:hover {
      background-color: $a3-green;
      color: white;
      cursor: pointer;
    }
  }

  thead {
    th {
      border-bottom: 1px $a3-green solid;
      padding: 5px;
    }
  }
}

.sbf-view-list-element {
    font-weight: bold;
    transition: color .15s ease-out;
    cursor: pointer;

    &:hover {
        color: $a3-green;
    }
}
</style>
