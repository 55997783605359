<template>
    <div>
        <h1>{{ $t('ui.advisor.advisors') }} </h1>

        <p v-if="advisors.length == 0">
           {{ $t('ui.advisors.no_advisors') }}
        </p>

        <div v-for="advisor in advisors"
             style="margin-top: 40px">

          <ProfileRender
            :person="advisor[0]"
            :advisor_profile="advisor[1]"
            />

          <em v-if="$hasFeature('OrgEditRights')">
              {{ $t('ui.user_type.user_type') }}: {{ advisor[2] ? advisor[2].name : 'Geen' }}
          </em>
        </div>
    </div>
</template>



<script>

import ProfileRender from '@/components/advisor/ProfileRender.vue';

export default {

  components: {
    ProfileRender,
  },

  data: () => ({
    advisors: []
  }),

  mounted() {
    this.loadAdvisors()
  },

  methods: {
    loadAdvisors() {
      this.$http.get('organisation/advisors')
        .then((res) => this.advisors = res.body);
    }
  },
};
</script>

