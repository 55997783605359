<template>
  <div class="sbf-report-container">
      <h2>{{ $t('definitions.short.sbf') }} : {{ sbf.name }}</h2>
    <p>
        <em>
            {{ $t('definitions.terms.result_area') }} :
            {{ result_area.name ? result_area.name : $t("definitions.result_area." + result_area.key) }}
        </em>
    </p>

    <template v-for="kpi in child_kpis">
        <A3KpiReport
          v-if="filterMap == null || filterMap.kpi == null || filterMap.kpi[kpi.id]"
          :filterMap="filterMap"
          :relations="relations"
          :measurements="measurements ? measurements[kpi.id] : null"
          :key="kpi.id"
          :kpi="kpi"
          :a3_document="a3_document" />

    </template>

  </div>
</template>


<script>

import A3KpiReport from '@/components/reports/A3KpiReport.vue';
import ResultAreaHelper from '@/mixins/ResultAreaHelper';

export default {

  mixins: [ResultAreaHelper],

  components: {
    A3KpiReport,
  },

  props: {
    sbf: {
      type: Object,
      required: true,
    },
    a3_document: {
      type: Object,
      required: true,
    },
    relations: {
      type: Object,
      required: true,
    },
    filterMap: {
        type: Object,
        required: true,
    },
    measurements: {
        type: Object,
        required: false,
        default: null,
    }
  },

  computed: {
    child_kpis() {
      let x = Object.values(this.a3_document.kpi).flat();
      return x.filter(x => x.sbf_id == this.sbf.id);
    },

    result_area() {
      return this.resultAreaFromLayout(
        this.a3_document.meta.layout,
        this.sbf.result_area
      );
    },
  },
}
</script>

<style lang="scss">

.sbf-report-container {
  margin-bottom: 90px;
  page-break-inside: avoid;

  p {
    margin: 0;
    padding: 0;
  }
  h2 {
    font-size: 1.4em;
    margin-bottom: 5px;
  }
}
</style>
