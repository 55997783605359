<template>
  <div id="loading-login-container">
    <i class="fas fa-spinner fa-spin fa-4x"></i> <br>
    Gebruikersgevens aan het ophalen ...

  </div>
</template>

<script>

import Vue from 'vue';

export default {

  computed: {
    redirect_url() {
        return '/a3'
    },
  },

  data: () => ({
    show_license_error: false,
  }),

  mounted() {
      let client_key = window.localStorage.getItem('sso_auth_client_key');

      let query = {
          code: this.$route.query['code'],
          state: this.$route.query['state'],
          session_state: this.$route.query['session_state'],
          client_key,
          callback_url: window.location.origin
      };

      this.$http.post('sso/login', query)
        .then(r => {
            this.logInUsingToken(r.body.token);
        });
  },

  methods: {

    logInUsingToken(token) {
      this.$store.commit('auth/setToken', token);
      window.localStorage.setItem('auth_token', token);

      Vue.http.headers.common['Authorization'] = `Bearer ${token}`;

      this.$http.get('me').then((res) => {
          this.$store.commit('auth/setPerson', res.body.person);
          this.$store.commit('auth/setOrganisation', res.body.organisation);
          this.$store.commit('auth/setAccount', res.body.account);
          this.$store.commit('auth/setFeatures', res.body.features);
          this.$store.commit('auth/setLicense', res.body.license);

          this.$store.commit('settings/loadSettings', res.body.settings);

          if (res.body.license == null) {
            this.show_license_error = true;
            return;
          }

          this.loadNotificationCount();

          this.$router.replace(this.redirect_url);
      }, () => {
          window.localStorage.removeItem('auth_token');
          delete this.$http.headers.common['Authorization'];
          this.$router.push('/login');
      });
    },

    loadNotificationCount() {
      return this.$http.get('notifications/count_unread')
        .then((res) => {
          this.$store.commit('settings/setNotificationCount', res.body);
        })
    },

    continueRedirect() {
      this.$router.replace(this.redirect_url);
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/variables.scss";

#loading-login-container {
  color: $a3-green;
  text-align: center;

  i {
    display: block;
    margin-bottom: 10px;
    margin-top: 30px;
  }
}
</style>
