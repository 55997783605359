<template>
  <WithMenuContainer>
    <div class="horizontal_report">
    <div>
        <div class="input-pills">
            <div
              v-for="c in a3_list"
              tabindex="0"
              @click="toggleA3Doc(c.a3doc_id)"
              @keyup.enter="toggleA3Doc(c.a3doc_id)"
              :key="c.a3doc_id"
              class="push-pill"
              :class="{'inactive': push_a3docs.find((x) => x == c.a3doc_id) == null }"
              >
              {{ getDepartment(c.department_id) }}
            </div>
          </div>
    </div>

    <div>
      <div class="charts">
        <div id="chart-resultArea">
          
        </div>
        <div id="chart-orgArea">

        </div>
      </div>
    <div v-for="(resultArea, resultAreaKey) in report" :key="resultAreaKey" class="result_area" :class="resultArea.name" :style="{'--main-color': resultArea.color, '--main-color-light': resultArea.color + '80'}">
      <h2 v-if="resultArea.layout_id">Resultaat gebide {{ layoutStorage[resultArea.layout_id].result_area[resultArea.name].name }}</h2>
      <h2 v-if="!resultArea.layout_id">Resultaat gebied: {{ $t("definitions.result_area." + resultArea.name) }}</h2>

      <div v-for="(dep, depKey) in resultArea.deps" :key="depKey">
        <h3>Afdeling: {{ dep.name }}</h3>
        <div class="result_row">
          <div class="chart">
            <HorizontalGraph :id="resultAreaKey+'_'+depKey" :data_set="dep.chart_data" :color="resultArea.color"></HorizontalGraph>
          </div>
          <div class="sbf">
            <div v-for="(sbf, sbfKey) in dep.sbf" :key="sbfKey" class="sbf_item">
              <h4>SBF: {{ sbf.name }}</h4>
              <table>
                <thead>
                  <tr>
                    <th>
                      Naam
                    </th>
                    <th style="width: 180px;">
                      Organisatiegebied
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(action, actionIndex) in sbf.actions" :key="actionIndex">
                    <td>
                      {{ action.name }}
                    </td>
                    <td v-if="resultArea.layout_id">
                      {{ layoutStorage[resultArea.layout_id].org_area[action.org_area] }}
                    </td>
                    <td v-if="!resultArea.layout_id">
                      {{ $t("definitions.org_area." + action.org_area) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </WithMenuContainer>

</template>
<style>
.horizontal_report{
  max-width: 1420px; 
  margin: 0px auto;
}

.horizontal_report div{
  color: var(--main-color);
}

.horizontal_report h2{
  color: var(--main-color);
  padding: 10px 0px 10px 0px;
}

.horizontal_report .result_area{
    border-style: none none solid none;
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-color: #00738544;
    border-width: 1.5px;
}

.horizontal_report table{
  text-align: left;
  width: 100%;
  cell-border-spacing: 0;
  border-collapse: collapse;
  text-align: left
}
.horizontal_report table thead tr th{
  background-color: var(--main-color-light);
  color:white;
  padding: 5px;
}
.horizontal_report table thead tr td{
  padding: 5px;
}

.horizontal_report .result_row{
  display: flex;
  justify-content: flex-start;
  align-items: center;
} 

.horizontal_report .result_row .chart{
width: 35%;
}
.horizontal_report .result_row .sbf{
width: 65%;
}

.charts{
  display:flex;
  justify-content:space-between;
  align-items:flex-start;
}
.charts div{
  width: 45%;
}

/* .horizontal_report  .sbf{
  margin-left:50px;
} */
.horizontal_report .employees{
  --main-color:#0174b7;
  --main-color-light:#0174b780;
  
}
.horizontal_report .society{
  --main-color:#f06e00;
  --main-color-light:#f06e0080;
}

.horizontal_report .board_and_financers{
  --main-color:#dd291a;
  --main-color-light:#dd291a80;
}

.horizontal_report .customers_and_partners{
  --main-color:#61c107;
  --main-color-light:#61c10780;
}


</style>
<script>

import ColorPicker from '@/components/forms/ColorPicker.vue';
import RichTextEditor from '@/components/forms/RichTextEditor.vue';
import WithDocumentManager from '@/mixins/WithDocumentManager';
import HorizontalGraph from "@/components/elements/HorizontalGraph.vue";
import ApexCharts from 'apexcharts';


export default {
  mixins: [WithDocumentManager],

  components: {
    ColorPicker,
    RichTextEditor,
    WithDocumentManager,
    HorizontalGraph,
  },
  props: {
        timeframe_id: {
            required: true
        },
    },
    watch: { 
      report: function(newVal) { // watch it
        // console.log(newVal)
        //   newVal.forEach((item) => {
        //   console.log(item)
        // })
        },
      },
  data: () => ({
    departments: [],
    a3_list: [],
    push_a3docs: [],
    report: {},
    resultChart: "",
    orgChart: "",
    fillColors: [],
    layoutStorage: [],
  }),

  mounted() {
    this.loadA3List();
    this.loadDepartments()
    this.loadLayouts();
  },
  


  methods: {
    destroyCharts(){
      if(this.resultChart){
        this.resultChart.destroy()
      }
      if(this.orgChart){
        this.orgChart.destroy()
      }
    },
    renderResultAreaChart() {
        let data = [];
        for (const [key, value] of Object.entries(this.overal_chart.resultArea)) {
            data.push(value);
        }

        let options = {
          chart: {
            type: 'bar',
            toolbar: { show: true },
            animations: {enabled: false},
            width: '100%',
            
          },
          tooltip: {
            enabled: false
          },
          title:{
            text: "Resultaat gebied voor geselecteerde plannen:"
          },
          series: [{
            data: data
          }],
          xaxis: { type: 'numeric' },
        }
        
        let chart = new ApexCharts(
          document.querySelector('#chart-resultArea'),
          options
        );
        
        chart.render();

        this.resultChart = chart;
      },
      renderOrgAreaChart(org_chart) {
        let options = {
          chart: {
            type: 'bar',
            toolbar: { show: true },
            animations: {enabled: true},
            width: '100%',
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: '13px',
                    fontWeight: 900
                  }
                }
              }
            },
          },
          tooltip: {
            enabled: true
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          title:{
            text: "Organisatiegebied voor geselecteerde plannen:"
          },
          series: org_chart.series,
          xaxis: {
            categories: org_chart.categories,
            type: 'string'
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          colors: org_chart.colors,
        }
        
        let chart = new ApexCharts(
          document.querySelector('#chart-orgArea'),
          options
        );
        
        chart.render();
        this.orgChart = chart;
      },
    loadDepartments() {
      this.$http.get(`organisation/department`)
        .then(res =>{
          if (res.body != null) {
            this.departments = res.body;
          }

        })
    },
    loadLayouts() {
      let layoutStorage = [];
      this.$http.get(`layout`)
        .then(res =>{
          res.body
          res.body.forEach(layout => {
            layoutStorage[layout.id] = {
              org_area: [],
              result_area: []
            }

            Object.entries(layout.structure.org_areas).forEach(([orgIndex, org_area]) => { 
              layoutStorage[layout.id].org_area[org_area.key] = org_area.name;
            });
            Object.entries(layout.structure.result_areas).forEach(([resultIndex, result_area]) => { 
              layoutStorage[layout.id].result_area[result_area.key] = {
                name: result_area.name,
                color: result_area.color
              }
            });
          });
          this.layoutStorage = layoutStorage;
        })
    },
    loadReport(){
      this.destroyCharts();
      var overal_chart = {
        resultArea: [],
        orgArea: []
      };
      var fillColor = {
        society: "#f06e00",
        board_and_financers: "#dd291a",
        customers_and_partners: "#61c107",
        employees: "#0174b7"
      }
      this.fillColors = fillColor;
      
      if(this.push_a3docs.toString().length != 0){
        let all_results = [];
        let all_orgArea = [];
        let all_orgAreaNames = [];
        let chart_colors = [];
        var fillColor = {
          society: "#f06e00",
          board_and_financers: "#dd291a",
          customers_and_partners: "#61c107",
          employees: "#0174b7"
        }
        this.$http.get(`report/horizontal?a3doc_id_list=${this.push_a3docs.toString()}&timeframe_id=${this.timeframe_id}`)
        .then(res => {
          let report = {}; // Initialize as an object
          res.body.forEach(action => {
            
            // Ensure the result_area key exists in the report object
            if (!report[action.action.result_area]) {
              report[action.action.result_area] = {
                name: action.action.result_area,
                deps: {}, // Initialize as an object for key-based indexing
                layout_id: action.a3_doc.layout_id,
                color:(action.a3_doc.layout_id) ?  this.layoutStorage[action.a3_doc.layout_id].result_area[action.action.result_area].color : fillColor[action.action.result_area]
              };
            }

            
            
            // Ensure the a3_doc.id key exists in the deps object
            if (!report[action.action.result_area]['deps'][action.a3_doc.id]) {
              report[action.action.result_area]['deps'][action.a3_doc.id] = {
                name: action.a3_doc.title,
                sbf: {}, // Initialize as an object for key-based indexing
                chart_data: {}
              };
            }

            // Ensure the sbf.id key exists in the sbf object
            if (!report[action.action.result_area]['deps'][action.a3_doc.id]['sbf'][action.sbf.id]) {
              report[action.action.result_area]['deps'][action.a3_doc.id]['sbf'][action.sbf.id] = {
                name: action.sbf.name,
                actions: [] // Initialize as an array to store actions
              };
            }
            if(!report[action.action.result_area]['deps'][action.a3_doc.id]['chart_data'][action.action.org_area]){
              if(action.a3_doc.layout_id){
                  report[action.action.result_area]['deps'][action.a3_doc.id]['chart_data'][action.action.org_area] = {
                    name: this.layoutStorage[action.a3_doc.layout_id].org_area[action.action.org_area],
                    value: 1
                  };
                }
                else{
                  report[action.action.result_area]['deps'][action.a3_doc.id]['chart_data'][action.action.org_area] = {
                    name: this.$t('definitions.org_area.'+action.action.org_area),
                    value: 1
                  };
                }
            }
            else{
              report[action.action.result_area]['deps'][action.a3_doc.id]['chart_data'][action.action.org_area].value++;
            }
            
            // Push the action into the actions array
            report[action.action.result_area]['deps'][action.a3_doc.id]['sbf'][action.sbf.id]['actions'].push(action.action);
          });

          this.$http.get(`report/horizontalref?a3doc_id_list=${this.push_a3docs.toString()}&timeframe_id=${this.timeframe_id}`)
          .then(res_ref => {
              res_ref.body.forEach(action => {
                // Ensure the result_area key exists in the report object
                if (!report[action.action.result_area]) {
                  report[action.action.result_area] = {
                    name: action.action.result_area,
                    deps: {}, // Initialize as an object for key-based indexing
                    layout_id: action.a3_doc.layout_id,
                    color:(action.a3_doc.layout_id) ?  this.layoutStorage[action.a3_doc.layout_id].result_area[action.action.result_area].color : fillColor[action.action.result_area]
                  };
                }

                

                // Ensure the a3_doc.id key exists in the deps object
                if (!report[action.action.result_area]['deps'][action.a3_doc.id]) {
                  report[action.action.result_area]['deps'][action.a3_doc.id] = {
                    name: action.a3_doc.title,
                    sbf: {}, // Initialize as an object for key-based indexing
                    chart_data: {}
                  };
                }

                // Ensure the sbf.id key exists in the sbf object
                if (!report[action.action.result_area]['deps'][action.a3_doc.id]['sbf'][action.sbf.id]) {
                  report[action.action.result_area]['deps'][action.a3_doc.id]['sbf'][action.sbf.id] = {
                    name: action.sbf.name,
                    actions: [] // Initialize as an array to store actions
                  };
                }
                if(!report[action.action.result_area]['deps'][action.a3_doc.id]['chart_data'][action.action.org_area]){
                  if(action.a3_doc.layout_id){
                    report[action.action.result_area]['deps'][action.a3_doc.id]['chart_data'][action.action.org_area] = {
                      name: this.layoutStorage[action.a3_doc.layout_id].org_area[action.action.org_area],
                      value: 1
                    };
                  }
                  else{
                    report[action.action.result_area]['deps'][action.a3_doc.id]['chart_data'][action.action.org_area] = {
                      name: this.$t('definitions.org_area.'+action.action.org_area),
                      value: 1
                    };
                  }
                }
                else{
                  report[action.action.result_area]['deps'][action.a3_doc.id]['chart_data'][action.action.org_area].value++;
                }
                
                // Push the action into the actions array
                report[action.action.result_area]['deps'][action.a3_doc.id]['sbf'][action.sbf.id]['actions'].push(action.action);
            });
            return report;
          })
          .then(report => {
            Object.entries(report).forEach(([resultArea, value]) => {         
              //Fill all results
                if(value.layout_id){
                  var result_name = this.layoutStorage[value.layout_id].result_area[resultArea].name;
                  var result_color = this.layoutStorage[value.layout_id].result_area[resultArea].color;
                }
                else{
                  var result_name = this.$t('definitions.result_area.'+resultArea);
                  var result_color = fillColor[resultArea];
                }
                
                all_results.push({rawName: resultArea, name: result_name, color: result_color});
                //Fill all org areas
                Object.entries(value.deps).forEach(([dep_id, deps]) => {
                  Object.entries(deps.sbf).forEach(([sbf_id, sbf]) => {
                    Object.entries(sbf.actions).forEach(([action_id, action]) => {
                      if(!all_orgArea.includes(action.org_area)){
                        //store all org areas
                        all_orgArea.push(action.org_area);
                        if(value.layout_id){
                          all_orgAreaNames.push(this.layoutStorage[value.layout_id].org_area[action.org_area])
                        }
                        else{
                          all_orgAreaNames.push(this.$t('definitions.org_area.'+action.org_area))
                        }
                        
                      }

                        //fill overal_chart with resultArea for chart data
                        if(!overal_chart.resultArea[action.result_area]){

                          if(value.layout_id){
                            overal_chart.resultArea[action.result_area] = {
                              x: this.layoutStorage[value.layout_id].result_area[action.result_area].name,
                              y: 0,
                              fillColor:this.layoutStorage[value.layout_id].result_area[action.result_area].color
                            }

                          }
                          else{
                            overal_chart.resultArea[action.result_area] = {
                              x: this.$t('definitions.result_area.'+action.result_area),
                              y: 0,
                              fillColor:fillColor[action.result_area]
                            }
                          }
                          
                        }
                        overal_chart.resultArea[action.result_area].y++;

                        //fill overal chart with org area for chart data.
                        if(!overal_chart.orgArea[action.org_area]){
                          overal_chart.orgArea[action.org_area] = [];
                        }
                        if(!overal_chart.orgArea[action.org_area][action.result_area]){
                          overal_chart.orgArea[action.org_area][action.result_area] = 0;
                        }
                        overal_chart.orgArea[action.org_area][action.result_area]++
                      
                    });
                    
                  });
                  
                }); 
            });
            Object.entries(overal_chart.orgArea).forEach(([orgKey, value]) => {
              all_results.forEach(result => {
                if(!value[result.rawName]){
                  value[result.rawName] = 0;
                }
              })
            });
            all_results.sort();
            all_orgArea.sort(); 

            all_results.forEach(result => {
              chart_colors.push(result.color);
            });
            let org_chart = {
            series: [],
            categories: all_orgAreaNames,
            colors: chart_colors,
          }

          all_results.forEach(result => {
            var dataObject = {
              name: result.name,
              data: []
            }
            Object.entries(overal_chart.orgArea).forEach(([orgKey, value]) => {
              // console.log("area", value);
              Object.entries(value).forEach(([key, value]) => {
                if(key == result.rawName){
                  // console.log(key, value);
                  dataObject.data.push(value);
                }
              });
            });
            org_chart.series.push(dataObject);

          }); 
          this.report = report;
          this.overal_chart = overal_chart;
          this.renderResultAreaChart();
          this.renderOrgAreaChart(org_chart);
          });
          


          
        });

      }
      else{
        this.report = [];
        this.overal_chart = [];
        this.renderResultAreaChart();
        this.renderOrgAreaChart([]);
      }
      
      
    },
    toggleA3Doc(a3doc_id){
      let in_array = this.push_a3docs.findIndex(x => x == a3doc_id);
      if (in_array == -1) {
        this.push_a3docs.push(a3doc_id);
      } else {
        this.push_a3docs.splice(in_array, 1);
      }
      this.loadReport();
    },
    getDepartment(department_id){
      if(department_id){  
        return this.departments.find(x => x.id == department_id).name;
      }
      else{
        return this.$store.getters['auth/organisation'].name;
      }      
    },
    loadA3List() {
      this.$http.get(`a3/org?timeframe=${this.timeframe_id}`)
        .then(res => {
            this.a3_list = res.body;
        });
    },
  },

}
</script>
