<template>
  <div id="a3-report-menu-container"
       style="padding-left: 55px"
    >
    <div
      v-for="(l, link_index) in links"
      :key="'main-menu-link-' + link_index"
      class="a3-tab"
      :class="{'active': $route.path.startsWith(l.active_slug)}"
    >
      <div
        :id="'menu-bar-' + l.id"
        @click="$router.push(l.url)"
      >
        <i style="margin-right: 5px" class="fas" :class="'fa-' + l.icon" />
            {{ $t(l.name) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
      a3doc_id: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      links: [],
    }),
    mounted() {
        this.links = [
            {
                url: `/a3/${this.a3doc_id}`,
                name: 'ui.nav.back_to_a3',
                id: 'back-a3',
                icon: 'chevron-left',
                active_slug: '/none',
            },
        ];
    },

};
</script>
