<template>
  <div class="form-row layout-form-org-area">
      <div class="form-field">
          <label>
            {{ $t('ui.layout.key') }}
            <InfoBox>
              De {{ $t('ui.layout.key') }} wordt gebruikt om
              elementen in het jaarplan te koppelen aan dit
              organisatiegebied. Deze moet uniek zijn tussen
              **alle** gebieden in het A3 jaarplan.
            </InfoBox>
          </label>
          <input v-model="inner_value.key" class="a3-input"
                style="width: 350px"
          />

          <FormError :error="errors.key" />
      </div>

      <div class="form-field">
          <label>
              {{ $t('ui.layout.index') }} &amp;
              {{ $t('ui.layout.title') }}
          </label>

          <input v-model.number="inner_value.index"
                 class="a3-input"
                 type="number"
                 style="width: 50px; margin-right: 10px;"
          />
          <input v-model="inner_value.name" class="a3-input"
                style="width: calc(350px - 74px);"
          />

          <FormError :error="errors.index" />
          <FormError :error="errors.name" />
      </div>
      <div class="form-field">
          <label>{{ $t('ui.layout.description') }}</label>
          <RichTextEditor v-model="inner_value.description"
                 style="display: inline-block; width: 700px"
                 id="layout-name-input"
                 data-cy="input-name"
          />
      </div>


      <div style="display: grid; grid-template-columns: 1fr 1fr;
                  margin-left: 20%;
                   "

        >

        <div>
          <h3 style="color: white; padding-left: 0;
                     opacity: .5;
            ">Positie</h3>
          <div class="position-form-field" >
              <label>
                <i class="fas fa-arrow-from-left"></i>
                {{ $t('ui.layout.grid_x') }}</label>
              <input v-model.number="inner_value.position.x" class="a3-input" />
          </div>
          <div class="position-form-field">
                <i class="fas fa-arrows-h"></i>
              <label>{{ $t('ui.layout.grid_xspan') }}</label>
              <input v-model.number="inner_value.position.xspan" class="a3-input" />
          </div>
          <div class="position-form-field">
                <i class="fas fa-arrow-from-top"></i>
              <label>{{ $t('ui.layout.grid_y') }}</label>
              <input v-model.number="inner_value.position.y" class="a3-input" />
          </div>
          <div class="position-form-field">
                <i class="fas fa-arrows-v"></i>
              <label>{{ $t('ui.layout.grid_yspan') }}</label>
              <input v-model.number="inner_value.position.yspan" class="a3-input" />
          </div>
        </div>

        <div class="icon-form-field">
            <label>{{ $t('ui.layout.icon') }}</label>
            <div class="picker-container">
              <IconPicker v-model="inner_value.icon" />
            </div>
        </div>

      </div>


      <div class="form-buttons">
          <button class="a3-button action"
                  :disabled="has_errors"
                @click="$emit('submit', inner_value)">
              <i class="fas fa-check"></i> {{ $t('ui.general.save') }}
          </button>
          <button class="a3-button cancel"
              @click="$emit('close')">
              <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
          </button>
          <button class="a3-button danger"
            @click="$emit('delete')">
              <i class="fas fa-trash"></i> {{ $t('ui.general.delete') }}
          </button>
      </div>
  </div>
</template>

<script>

import RichTextEditor from '@/components/forms/RichTextEditor.vue';
import IconPicker from '@/components/forms/IconPicker.vue';

export default {
  components: {
    RichTextEditor,
    IconPicker
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    inner_value: null,
    errors: {},
  }),

  mounted() {
    this.inner_value = {...this.value};
  },

  watch: {
    inner_value: {
      deep: true,
      handler: function(new_value) {
        this.validate(new_value);
      }
    },
  },

  computed: {
    has_errors() {
      for (let err of Object.values(this.errors)) {
        if (err.length > 0) return true;
      }
      return false;
    },
  },

  methods: {
    validate(value) {
      this.errors = {
        'name': [],
        'key': [],
        'index': [],
      };

      if (value.name == null || value.name == "") {
        this.errors['name'].push('may_not_be_null');
      }
      if (value.index == null || value.index == "") {
        this.errors['index'].push('may_not_be_null');
      }
      if (value.key == null || value.key == "") {
        this.errors['key'].push('may_not_be_null');
      }
      if (! /^[A-Za-z0-9_]*$/.test(value.key) ) {
        this.errors['key'].push('must_contain_alphanumeric');
      }


      for (let err of Object.values(this.errors)) {
        if (err.length > 0) return false;
      }
      return true;
    },
  }
}
</script>


<style lang="scss">

.position-form-field {
  width: 50%;
  box-sizing: border-box;
  padding-right: 30px;
  display: inline-block;
  margin-bottom: 10px;

  i {
    color: white !important;
    display: inline-block;
    width: 20px;
  }
}

.icon-form-field {
  label {
    margin-top: 35px;
    font-size: 40px;
    opacity: .5;
  }

  .picker-container .icon-picker-button {
    border: 1px white solid;
    transform: scale(2);
    transform-origin: top left;
  }
}

.color-form-field {
  label {
    margin-top: 35px;
    font-size: 40px;
    opacity: .5;
    display: block;
  }

  > div {
    display: block;
    transform: scale(2);
    transform-origin: top left;
  }
}

</style>
