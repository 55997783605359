<template>
  <div class="info-box-container">
    <div class="info-box-show">
      <slot name="display">
        <i class="fas fa-question-circle info-box-icon"></i>
      </slot>
    </div>
    <div class="info-box-content"
         :style="{'left': left, top: top}"
      >
      <template v-if="tkey">
        <div v-if="html" v-html="$t(`infobox.${tkey}`, params)"></div>
        <template v-else>
          {{ $t(`infobox.${tkey}`, params) }}
        </template>
      </template>
      <template v-else>
        <slot></slot>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    'left': {
      type: String,
      required: false,
      default: 'calc(1em + 12px)',
    },
    'top': {
      type: String,
      required: false,
      default: '0',
    },

    tkey: {
      type: String,
      required: false,
    },
    params: {
      required: false,
      validator: () => true,
    },
    html: {
      type: Boolean,
      required: false,
      default: false,
    }
  }
}
</script>


<style lang="scss">

.info-box-container {
  display: inline-block;
  position: relative;

  .info-box-show {
    cursor: pointer;
    .info-box-icon {
      opacity: .4;
      transition: opacity .25s ease-out;
    }
  }
  .info-box-show:hover {
    .info-box-icon {
      opacity: 1;
    }
    &+ .info-box-content {
      opacity: 1;
    }
  }
}

.info-box-content {
  position: absolute;
  pointer-events: none;
  text-align: left;

  opacity: 0;
  transition: opacity .25s ease-out;

  min-width: 250px;
  padding: 5px;
  border-radius: 5px;

  font-size: 14px;
  font-weight: normal;
  border: 2px white solid;

  color: white;
  background-color: $a3-green;

  p {
    margin: 5px;
  }

  z-index: 1000;
}

</style>
