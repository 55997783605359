<template>
    <div class="user-settings-section">
        <div style="float: right">
            <div class="twofactor-toggle enabled"
                 @click="disable2Fa"
                v-if="enabled === true">
                <div class="bulb"></div> Aan
            </div>
            <div class="twofactor-toggle disabled"
                 @click="enable2Fa"
                v-if="enabled === false">
                <div class="bulb"></div> Uit
            </div>

            <button class="a3-button action"
                    @click="$router.push('/me/2fa/add')">
                 <i class="fas fa-plus"></i> {{ $t('ui.general.create') }}
            </button>
        </div>

        <h3>2-Factor Authenticatie (bèta)</h3>

        <h3>{{ $t('ui.2fa.devices') }}</h3>

        <table class="a3-table twofactor-table">
            <thead>
                <tr>
                    <th>{{ $t('ui.2fa.created') }}</th>
                    <th>{{ $t('ui.2fa.last_used') }}</th>
                    <th>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="d in devices"
                    >
                    <td>{{ d.created }}</td>
                    <td>{{ d.last_used ? d.last_used : '-' }}</td>
                    <td>
                        <span class="add-timeframe-button"
                              @click="deleteDevice(d.id)"
                            >
                            <i class="fas fa-times"></i> {{ $t('ui.general.delete') }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</template>

<script>

import qrious from 'qrious';

export default {

    data: () => ({
        enabled: false,
        devices: [],
    }),

    mounted() {
        this.loadStatus();
    },

    methods: {

        loadStatus() {
            this.$http.get('2fa/status')
                .then((res) => {
                    this.enabled = res.body.two_factor_enabled;
                    this.devices = res.body.devices;
                });
        },

        deleteDevice(id) {
            this.$http.delete(`2fa/device/${id}`)
                .then((res) => {
                    this.loadStatus();
                });
        },

        disable2Fa() {
            this.$http.post(`2fa/disable`)
                .then(() => {
                    this.loadStatus();
                })
        },

        enable2Fa() {
            this.$http.post(`2fa/enable`)
                .then(() => {
                    this.loadStatus();
                })
        }
    },
}
</script>

<style lang="scss">

.qr-instruction-grid {
    display: grid;
    grid-template-columns: auto 400px;
    grid-column-gap: 50px;
}

.twofactor-toggle {
    display: inline-block;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 5px;
    border: 2px solid black;
    transition: border-color .15s ease-out, background-color .15s ease-out;
    cursor: pointer;

    .bulb {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }

    &.enabled {
        color: $general-green;
        border-color: rgba($general-green, .3);
        &:hover {
            border-color: rgba($general-green, 1);
            background-color: rgba($general-green, .2);
        }
        .bulb {
            background-color: $general-green;
        }
    }

    &.disabled {
        color: $general-red;
        border-color: rgba($general-red, .3);
        &:hover {
            border-color: rgba($general-red, 1);
            background-color: rgba($general-red, .2);
        }
        .bulb {
            background-color: $general-red;
        }
    }
}

table.twofactor-table tbody tr:hover {
    background-color: transparent !important;
    color: black !important;
}
table.twofactor-table tbody tr td:last-child {
    width: 200px;
}
</style>

