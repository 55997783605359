<template>
    <div>
    <div style="max-width: 1120px; margin: 0 auto;">
        <h1>Jaarplan beheer</h1>

        <div class="management-buttons-container">
            <div
                class="a3-button action"
                @click="$router.push(report.url)"
                v-for="report in links">
                <i class="fas" :class="`fa-${report.icon}`"></i>
                {{ $t(report.name) }}
            </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {

    data: () => ({
        links: [],
    }),

  mounted() {
        this.links = [
            {
                url: `/a3/manage/list`,
                name: 'ui.nav.a3_list',
                id: 'list-elements',
                icon: 'list',
                active_slug: '/a3/manage/list',
            },
            {
                url: `/a3/manage/copy`,
                name: 'ui.nav.a3_copy',
                id: 'copy-elements',
                icon: 'copy',
                active_slug: '/a3/manage/copy',
            },
        ];
  },
};
</script>

<style lang="scss">

.management-buttons-container {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(4, 1fr);

    .a3-button {
        font-weight: bold;
        font-size: 16px;
        i {
            display: block;
            margin-bottom: 30px;
            transform: scale(3);
            opacity: .4;
            transform-origin: 10px 5px;
        }
    }
}
</style>
