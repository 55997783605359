<template>
  <div style="position: relative"
       @mouseover="$emit('mouseover')"
       @mouseleave="$emit('mouseleave')"
      >
      <div style="display: inline-block; vertical-align: top; text-align: right;"
           :style="{'width': `${prefix_width}px`}"
        >
        <i class="fas" :class="icon"
           v-if="icon != null"
           :style="{'margin-left': icon == 'fa-check' ? '-7px' : '-5px'}"
          ></i> {{ prefix }}
      </div>


      <div style="display: inline-block;
           vertical-align: top;
           padding-left: 3px;"
           :style="{'max-width': `calc(95% - ${prefix_width}px - 3px)`}"
        >
        <span class="kpi-list-element"
              :class="{
                'review-active': reviewAnnotations.filter(x => x.element_type === 'Comment').length > 0,
                'review-deleted': displayStrikethrough,
                'review-added': displayNewStyle
              }"
          >
          <WordDiffRender :base="kpi.name" :newValue="review_name" />
        </span>

      <template v-if="postfix">
        ({{postfix}})
      </template>
      <template v-if="trail == null && show_trail">
        ( <i class="fas fa-exclamation"></i> )
      </template>

      <div class="review-comment-add-button"
           v-if="review != null && editable"
           @click.stop="$emit('comment', kpi)"
        >
        <i class="fas fa-comment-alt-plus"></i>
      </div>

      <div v-if="show_target || show_current_value" style="padding-left: 15px">
        <template v-if="show_current_value && currentMeasurement">
          <i class="fas fa-ruler"></i>
          {{ $n(currentMeasurement.value) }}
        </template>
        <template v-if="show_target && target_min">
          - {{ $t('ui.kpi.min_short').toLowerCase() }} {{ $n(target_min) }}
        </template>
        <template v-if="show_target && target_max">
          - {{ $t('ui.kpi.max_short').toLowerCase() }} {{ $n(target_max) }}
        </template>
      </div>
      <template v-if="show_plan_source && kpi.kpi_ref != null">
        <div style="padding-left: 21px">
          <i class="fa fa-rotate-90 fa-level-up"></i>
          <span style="padding-left: 5px">Vanuit:
              {{ A3NameFromRelations( relations, kpi.a3doc_id ) }}
          </span>
        </div>
      </template>
      <template v-if="show_pushed_to && kpi.child_refs && kpi.child_refs.length > 0">
        <div style="padding-left: 15px">
          <i class="fas fa-arrow-down"></i> Doorgezet:
            {{ kpi.child_refs.map((x) => A3NameFromRelations( relations, x.a3doc_id ))  }}
        </div>
      </template>
      </div>
  </div>
</template>

<script>

import A3RelationHelper from '@/mixins/A3RelationHelper';
import piValueCheck from '@/services/piValueCheck';

import WordDiffRender from '@/components/elements/WordDiffRender';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  components: { WordDiffRender },
  mixins: [A3RelationHelper, WithDocumentManager],

  props: {
    kpi: {
      type: Object,
      required: true,
    },

    editable: {
      type: Boolean,
      required: false,
      default: false,
    },

    show_numbers: { type: Boolean, required: true },
    show_trail: { type: Boolean, required: true },
    show_status: { type: Boolean, required: true },
    show_target: { type: Boolean, required: true },
    show_current_value: { type: Boolean, required: true },
    show_plan_source: { type: Boolean, required: false, default: false },
    show_pushed_to: { type: Boolean, required: false, default: false },
  },

  computed: {
    currentMeasurement() {
      return this.kpi.measurement;
    },

    prefix_width() {
      if (this.show_numbers && this.show_status) {
        return 25;
      }
      if (this.show_numbers) {
        return 18;
      }
      return 10;
    },

    review_name() {
      if (this.review == null) { null; }
      let reviewName = this.reviewAnnotations
        .find(x => x.element_type === 'Edit');
      if (reviewName == undefined) { return null; }
      return reviewName.new_value.name;
    },

    index() {
      return this.dataManager
            .getKpiList(this.kpi.result_area)
            .findIndex(x => x.id == this.kpi.id) + 1;
    },

    target_min() {
      return piValueCheck.getTargetMin(this.kpi);
    },
    target_max() {
      return piValueCheck.getTargetMax(this.kpi);
    },

    prefix() {
      if (this.show_numbers) return `${this.index})`;
      return null;
    },

    postfix() {
      let to_show = [];
      if (this.show_trail && this.trail) {
        to_show.push(this.trail);
      }
      if (to_show.length > 0) return to_show.join(", ");
      return null;
    },

    icon() {
      if (!this.show_status && this.show_numbers) return null;
      if (!this.show_status) return 'fa-chevron-right';
      if (!this.currentMeasurement) { return 'fa-chevron-right'; }

      if (piValueCheck.checkKpiValue(this.kpi, this.currentMeasurement)) {
        return 'fa-check';
      }

      return 'fa-times';
    },

    trail() {
      let sbf_index = this.a3_document
          .sbf
          .findIndex((x) => x.id === this.kpi.sbf_id)
      ;

      if (sbf_index != -1) return `${sbf_index + 1}`;
      return null;
    },

    reviewAnnotations() {
      if (!this.review || !this.review.elements) return [];
      return this.review.elements
        .filter(x => (
          x.kpi_id == this.kpi.id
          || x.target_type === 'Kpi' && x.new_value.id == this.kpi.id
        ));
    },

    displayStrikethrough() {
      return this.reviewAnnotations
            .find(x => x.element_type === 'Delete') !== undefined;
    },
    displayNewStyle() {
      return this.reviewAnnotations
            .find(x => x.element_type === 'Create') !== undefined;
    }
  }

}
</script>


<style lang="scss">
.kpi-list-element {

  &.review-active {
    background-color: yellow;
    padding-left: 2px;
    padding-right: 2px;
  }

  &.review-deleted {
    text-decoration: line-through;
    opacity: .6;
  }

  &.review-added {
    text-decoration: underline;
    &:before {
      content: '(+)';
      color: $general-green;
    }
  }
}

.review-comment-add-button {
  position: absolute;
  color: $review-orange;
  top: calc(50% - 0.75em);
  right: -15px;
  border: 1px $review-orange solid;
  box-sizing: border-box;
  vertical-align: middle;
  height: 1.5em;
  transform: scale(1.3);

  background-color: white;
  border-radius: 3px;
  padding: 2px;
  opacity: .4;
  transition: transform .15s ease-out, opacity .15s ease-out;

  &:hover {
    transform: scale(2);
    opacity: 1;
    z-index: 10000;
  }
}
</style>
