<template>
  <span v-if="newValue == null">
    {{ base }}
  </span>
  <span v-else>
    <template v-for="(row, index) in render_diff">
      <span v-if="row.text" :key="index">{{ row.text }} </span>
      <span v-if="row.add" :key="index" style="color: green; text-decoration: underline;">{{ row.add }} </span>
      <span v-if="row.remove" :key="index" style="color: red; text-decoration: line-through;">{{ row.remove }} </span>
    </template>
  </span>
</template>


<script>

import wordDiff from '@/../vendor/js/jsDiff';

export default {
  props: {
    base: {
      type: String,
      required: true,
    },

    newValue: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    render_diff() {
      if (this.newValue == null) { return null; }
      return wordDiff.diffString(this.base, this.newValue);
    },
  },
};
</script>
