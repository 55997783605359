<template>
  <div style="position: relative">
      <template v-if="result_area.icon">
          <i class="fas sbf-fillout-result-icon" :class="`fa-${result_area.icon}`"></i>
      </template>

      <h2>{{ $tc('definitions.terms.result') }}</h2>
      {{ kpi.name }}

      <div class="result-goal">
        <div>
            {{ $t('ui.kpi.target') }}:
        </div>
        <div>
          <div>
              {{ $t('ui.kpi.target_min') }}: {{ target_min }}
          </div>
          <div>
              {{ $t('ui.kpi.target_max') }}: {{ target_max }}
          </div>
        </div>
      </div>

      <button class="a3-button action"
              @click="interactionManager.editKpi(kpi.id)"
        >
        <i class="fas fa-pencil"></i> {{ $t('ui.general.edit') }}
      </button>
  </div>
</template>

<script>

import WithDocumentManager from '@/mixins/WithDocumentManager';

import piValueCheck from '@/services/piValueCheck';

export default {

  mixins: [WithDocumentManager],

  props: {
    kpi: {
      type: Object,
      required: true,
    }
  },

  data: () => ({
  }),


  computed: {
    target_min() {
      return piValueCheck.getTargetMin(this.kpi);
    },
    target_max() {
      return piValueCheck.getTargetMax(this.kpi);
    },
    result_area() {
        return this.a3_layout
            .structure
            .result_areas
            .find(x => x.key == this.kpi.result_area);
    },
  },
}
</script>


<style lang="scss">

.sbf-fillout-result-icon {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 70px;
    opacity: .4;
}

</style>
