<template>
  <div class="review-row"
       v-if="reviewEdits.length > 0 || reviewComments.length > 0
             || reviewIsCreate || reviewIsDeleted
              "
    >

    <h3>
      <template v-if="reviewIsCreate">
        <i class="fas fa-plus"></i>
      </template>
      <template v-if="reviewIsDeleted">
        <i class="fas fa-trash"></i>
      </template>
      {{ sbf.name }}
    </h3>

    <table v-if="reviewEdits.length > 0
                 || reviewIsCreate
      " >
      <thead>
        <tr>
          <th></th>
          <th>{{ $t('ui.review.was') }}</th>
          <th>{{ $t('ui.review.becomes') }}</th>
        </tr>
      </thead>
      <template v-for="reviewEdit in reviewEdits">
        <template v-for="(value, key) in reviewEdit.new_value" >
          <tr v-if="value !== sbf[key] && value !== null" :key="key">
            <td><em>{{ $t(`ui.sbf.${key}`) }}:</em> </td>
            <td>
              <FieldRender
                :field="`sbf.${key}`"
                :value="sbf[key]"
                :dataManager="dataManager" />
            </td>
            <td>
              <FieldRender
                :field="`sbf.${key}`"
                :value="value"
                :dataManager="dataManager" />
            </td>
          </tr>
        </template>
      </template>
      <template v-if="reviewIsCreate">
        <template
          v-for="(value, key) in reviewCreate.new_value" >
          <tr :key="key" v-if="key != 'id' && value != null">
            <td><em>{{ $t(`ui.sbf.${key}`) }}:</em> </td>
            <td></td>
            <td>
              <FieldRender
                :field="`sbf.${key}`"
                :value="value"
                :dataManager="dataManager" />
            </td>
          </tr>
        </template>
      </template>
    </table>

    <div class="review-report-comment-container"
         v-if="reviewComments.length > 0"
      >
      <h4>{{ $t('ui.review.comments') }}</h4>
      <div class="review-report-comment"
           v-for="comment in reviewComments"
           :key="comment.id"
        >
        <RichTextRender :value="comment.new_value.comment" />
      </div>
    </div>
  </div>
</template>


<script>

import WithDocumentManager from '@/mixins/WithDocumentManager';

import ReviewElementIcon from '@/components/review/ReviewElementIcon';
import RichTextRender from '@/components/forms/RichTextRender';
import FieldRender from '@/components/elements/FieldRender';

export default {
  mixins: [WithDocumentManager],
  components: {
    ReviewElementIcon,
    RichTextRender,
    FieldRender,
  },

  props: {
    sbf_id: {
      required: true,
      validator: () => true,
    },
  },

  computed: {
    sbf() {
      return this.dataManager.getSbf(this.sbf_id);
    },

    reviewCreate() {
      let l = this.dataManager
        .review
        .elements
        .filter(x => x.target_type === 'Sbf' && x.new_value.id == this.sbf.id
        )
        .filter(x => x.element_type == "Create");

      if (l.length > 0) { return l[0]; }
      return null;
    },
    reviewIsCreate() {
      return this.reviewCreate != null;
    },
    reviewIsDeleted() {
      return this.dataManager
        .review
        .elements
        .filter(x => x.sbf_id == this.sbf_id)
        .filter(x => x.element_type == "Delete")
        .length > 0
      ;
    },

    reviewEdits() {
      return this.dataManager
        .review
        .elements
        .filter(x => x.sbf_id == this.sbf_id)
        .filter(x => x.element_type == "Edit")
      ;
    },

    reviewComments() {
      return this.dataManager
        .review
        .elements
        .filter(x => x.sbf_id == this.sbf_id)
        .filter(x => x.element_type == "Comment")
      ;
    }
  },
};
</script>

<style lang="scss">

.review-row {
    table {
        margin-top: 15px;
        width: 100%;
        td:first-child {
            width: 20% !important;
        }
        td {
            width: 40%;
        }
        th {
            text-align: left;
        }
    }

    h4 {
        color: $a3-green;
        opacity: .6;
        margin-bottom: 8px;
    }
}

.review-report-comment-container {
    box-sizing: border-box;
    padding-left: 20%;
    padding-top: 10px;

    .review-report-comment {
        padding: 20px;
        background-color: rgba($a3-green, .05);
        border-radius: 10px;
        width: auto;
        margin-bottom: 10px;
    }
}
</style>
