<template>
  <div class="kpi-measurement-container">
    <h1>PI - Link bewerken</h1>
    <em>{{ kpi.name }}</em>

    <LinkForm
       v-if="link != null"
       :link="link"
       @cancel="$router.go(-1)"
       @delete="deleteLink"
       @submit="saveLink"
    />

    <ConfirmationOverlay
           v-if="show_delete_overlay"
           @submit="deleteLink"
           @cancel="show_delete_overlay = false"
    >
      Weet je zeker dat je deze meetwaarde wilt verwijderen?
    </ConfirmationOverlay>
  </div>
</template>

<script>

import LinkForm from '@/components/forms/LinkForm.vue';

export default {

  components: { LinkForm },

  props: {
    a3_document: {
      type: Object,
      required: true,
    },
    kpi_id: {
      type: String,
      required: true,
    },
    link_id: {
      type: String,
      required: true,
    },

    dataManager: {
      type: Object,
      required: true,
    },
    interactionManager: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    link: null,
    show_delete_overlay: false,
  }),

  computed: {
    kpi() {
      for (let container of Object.values(this.a3_document.kpi)) {
        for (let y of container) {
          if (y.id == this.kpi_id) return y;
        }
      }
      return null;
    },
  },

  mounted() {
    this.loadLink();
  },

  methods: {
    loadLink() {
      this.dataManager
        .loadKpiLink(parseInt(this.link_id), this.kpi_id)
        .then((link) => this.link = link);
    },

    saveLink() {
      let url = `a3/${this.a3_document.id}/kpi/${this.kpi_id}/link/${this.link.id}`;
      this.$http.put(url, this.link)
        .then(() => {
          this.$router.go(-1);
        });
    },

    deleteLink() {
      let url = `a3/${this.a3_document.id}/kpi/${this.kpi_id}/link/${this.link.id}`;
      this.$http.delete(url)
        .then(() => {
          this.$router.go(-1);
        });
    },
  },
}
</script>

<style lang="scss">

.kpi-measurement-container {
  h1 {
    color: $a3-green;
    opacity: .6;
    padding-left: 0;
  }

  h3 {
    color: $a3-green;
    margin-bottom: 5px;
    opacity: .6;
  }
}
</style>
