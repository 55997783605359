<template>
  <div style="text-align: left;
              margin-bottom: 50px;
    ">
      <h2 style="text-align: center">
        {{ $t('ui.kpi.current_score') }}
      </h2>

      <div class="kpi-evaluation-score-container"
           :class="{'inactive': currentMeasurement == null}"
        >
        <div class="kpi-evaluation-score-outline">
          <i class="far fa-star"></i>
          <i class="far fa-star"></i>
          <i class="far fa-star"></i>
          <i class="far fa-star"></i>
          <i class="far fa-star"></i>
        </div>
        <div class="kpi-evaluation-score-stars" v-if="currentMeasurement != null">
          <div style="position: relative; display: inline-block;">
            <div :style="{'clip-path': `polygon(0% 0%, ${clipPercent}% 0, ${clipPercent}% 100%, 0% 100%)`}">
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
            </div>
          </div>
        </div>

      </div>
      <div style="text-align: center" v-if="currentMeasurement != null">
        per, {{ $d(new Date(currentMeasurement.date), 'long') }}
      </div>
      <div style="text-align: center" v-else>
        {{ $t('ui.kpi_measurement.no_values') }}
      </div>

  </div>
</template>


<script>


export default {

  props: {
    measurements: {
      type: Array,
      required: false,
      // this can be null when it is not loaded
      default: null,
    },
  },

  computed: {
    clipPercent() {
      let star_width = 56.25;
      let margin_width = 12;
      let offset = 6;
      let total_width = 5 * star_width + 5 * margin_width;

      // the percentage of the total width that consists of margins

      let value = parseFloat(this.currentMeasurement.value);
      let margin_count = Math.floor(value);
      if (!value) return 0;

      let value_stars = value * star_width;
      let value_margins = margin_count * margin_width;

      return (
        (value_stars + value_margins + offset) / total_width
      ) * 100;
    },

    currentMeasurement() {
      if (!this.measurements || this.measurements.length == 0) { return null; }
      return this.measurements[0].values[0];
    },
  },

  methods: {

  },

};
</script>

<style lang="scss">

.kpi-evaluation-score-container {

  font-size: 50px;
  text-align: center;
  color: $a3-blue;
  position: relative;
  height: 75px;

  &.inactive {
    opacity: .2;
  }

  .kpi-evaluation-score-outline {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    color: darken($a3-blue, 10%);
  }
  .kpi-evaluation-score-stars {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 9;
  }

  i {
    margin: 6px;
  }
}
</style>
