<template>
    <div class="element-form">
      <div class="form-row">
        <div class="form-field">
          <label>{{ $t('ui.general.name') }}</label>
          <input v-model="action_task.name" class="a3-input" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-field">
          <label>{{ $t('ui.action.target_date') }} </label>
          <DateInput v-model="action_task.target_date" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-field">
          <label>{{ $t('ui.action.assigned_to') }}</label>
            <div style="width: 60%; display: inline-block; box-sizing: border-box;">
           <multiselect
                id="action-assigned_to"
                :value="assigned_to_multiselect"
                @input="(value, id) => action_task.assigned_to = value ? value.id: null"
               :options="people"
               :placeholder="$t('ui.action.assigned_to')"
               :selectLabel="$t('ui.general.enter_select')"
               :selectedLabel="$t('ui.general.selected')"
               :deselectLabel="$t('ui.general.enter_deselect')"
               trackBy="id"
               label="name" />
            </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-field">
          <label>{{ $t('ui.action.note') }}</label>
          <div class="form-input">
            <RichTextEditor v-model="action_task.note" />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-field">
          <label>{{ $t('ui.action.done') }}</label>
          <CheckBox v-model="action_task.done" />
        </div>
      </div>

      <div class="form-buttons">
          <button class="a3-button action" @click="$emit('submit')">
            <i class="fas fa-save"></i> {{ $t('ui.general.save')}}
          </button>

          <button class="a3-button danger"
                  v-if="action_task.id"
            @click="$emit('delete')">
            <i class="fas fa-trash"></i> {{ $t('ui.general.delete')}}
          </button>

          <button class="a3-button cancel" @click="$emit('cancel')">
            <i class="fas fa-times"></i>
            {{ $t('ui.general.cancel') }}
          </button>
      </div>
    </div>
</template>


<script>

import moment from 'moment';
import Multiselect from 'vue-multiselect';

import RichTextEditor from '@/components/forms/RichTextEditor.vue';

export default {
  components: {
    Multiselect,
    RichTextEditor,
  },
  props: {
    action_task: {
      type: Object,
      required: true,
    },
    a3_document: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    people: [],
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    },
    assigned_to_multiselect() {
        return this.people.find(x => x.id == this.action_task.assigned_to) || null;
    },
  },

  mounted() {
    this.loadPeople();
  },

  methods: {
    loadPeople() {
      this.$http.get(`organisation/people`)
        .then(res => this.people = res.body);
    }
  },

}
</script>
