<template>
  <div style="margin-bottom: 40px">
    <h2>{{
      org_area.name ?
      org_area.name :
      $t("definitions.org_area." + org_area.key)
    }}</h2>

      <table class="org-area-action-status-table">
        <thead>
          <tr>
            <th></th>
            <th>{{ $t('ui.general.name') }} </th>
            <th style="width: 180px">{{ $t('definitions.terms.result_area') }}</th>
            <th style="width: 140px">{{ $t('ui.action.state') }} </th>
          </tr>
        </thead>

        <tbody>
          <template v-for="action in filtered_actions">
            <tr :key="action.id" class="main-row">
              <td>
                <template v-if="action.status">
                  <template v-if="action.status.percent_done == 100">
                    <i class="fas fa-check"></i>
                  </template>
                </template>
              </td>
              <td>{{ action.name }}</td>
              <td>{{
                action.result_area_definition.name ?
                action.result_area_definition.name :
                $t("definitions.result_area." + action.result_area)
                }}</td>
              <td style="min-width: 130px">
                <template v-if="action.status">
                  {{ action.status.percent_done }}%<br>
                  <small>per {{ $d(new Date(action.status.date), 'long') }}</small>
                </template>
                <template v-else>
                  <span style="opacity: .4">0%</span>
                </template>
              </td>
            </tr>
            <tr :key="`${action.id}_person`" class="person-row" v-if="action.assigned_to_person">
              <td></td>
              <td colspan="3">
                <i class="fas fa-user"></i> Toegewezen aan: {{ action.assigned_to_person.name }}
              </td>
            </tr>
            <tr :key="`${action.id}_tasks`" class="task-row">
              <td colspan="4">
                <div v-for="t in action.tasks" :key="t.id" class="task-container"
                      :class="{'completed': t.done}"
                  >
                  <div class="task-name">
                    <template v-if="t.done"><i class="fas fa-check"></i></template>
                    {{ t.name }}
                    <template v-if="t.person"> ({{t.person.initials}})</template>
                  </div>
                  <template v-if="t.target_date">
                    <div :style="{'margin-left': getFactorInYear(t.target_date) * 200 + 'px'}" class="task-gantt"></div>
                    <div class="task-date">{{ $d(new Date(t.target_date), 'long') }}</div>
                  </template>
                </div>
              </td>
            </tr>
          </template>

          <tr v-if="filtered_actions.length == 0">
            <td colspan="3">
              <em>{{ $t('ui.action.no_actions') }} </em>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</template>

<script>

import ResultAreaHelper from '@/mixins/ResultAreaHelper';
import moment from 'moment';

export default {
  mixins: [ResultAreaHelper],
  props: {
    a3_document: {
      type: Object,
      required: true,
    },
    org_area: {
      type: Object,
      required: true
    },
    actions: {
      type: Array,
      required: true,
    },
    filterMap: {
      type: Object,
      required: false,
    },
  },

  computed: {
    filtered_actions() {
      if (this.actions == null) return [];

      let filter = this.filterMap;
      return this.actions
        .filter(action => action.org_area === this.org_area.key)
        .filter(action =>
          filter == null || filter.action == null || filter.action[action.id]
        )
        .map(action => {
          action.result_area_definition = this.resultAreaFromLayout(
            this.a3_document.meta.layout,
            action.result_area
          );
          return action;
        })
      ;
    },

  },

  methods: {
    getFactorInYear(date_string) {
      let d = moment(date_string);
      let s = moment(date_string).startOf('year');
      return d.diff(s, 'day') / 366;
    }
  },
};
</script>


<style lang="scss">

.org-area-action-status-table {
  width: 100%;
  cell-border-spacing: 0;
  border-collapse: collapse;

  text-align: left;

  td {
    padding: 5px;
  }

  .task-container {
    margin-bottom: 3px;
    box-sizing: border-box;
    width: calc(100% - 160px);
    margin-left: 80px;
    line-height: 1.4em;
    border-bottom: 1px rgba($a3-green, .2) solid;

    &:last-child {
      margin-bottom: 15px;
    }

    &.completed {
      opacity: .4;
      color: $general-green;
    }

    .task-gantt {
      background-color: $a3-green;
      height: 1.2em;
      vertical-align: middle;
      width: 3px;
      margin-right: 10px;
    }

    > div {
      display: inline-block;

      &.task-name {
        width: 400px;
      }

      &.task-date {
        width: 140px;
      }
    }
  }

  tbody tr {
    &.main-row {
      border-top: 1px rgba($a3-green, .2) solid;
      td:nth-child(2) {
        font-weight: bold;
        font-size: 18px;
      }
    }
    &.person-row {
      i {
        font-size: 20px;
        margin-right: 5px;
        color: rgba($a3-green, .4);
      }
    }
  }

  thead {
    th {
      background-color: $a3-green;
      color: white;
      border-bottom: 1px $a3-green solid;
      padding: 5px;
    }
  }
}
</style>
