<template>
  <div>
    <h1>Layout veranderen</h1>

    <p>
      Kies hier een van de layouts voor uw A3 plan. Uw beheerder kan
      nieuwe layouts aanmaken, mits u de enterprise licentie heeft.
    </p>

    <div class="layout-preview-grid">
        <div v-for="layout in layouts" :key="layout.id" class="layout-preview-container">
          <LayoutPreviewBox :layout="layout"
             :button="true"
             :inactive="layout.id != selected_layout_id"
             @click="toggleLayoutId(layout.id)"
          />
        </div>
    </div>

    <div class="buttons">
      <button class="a3-button action" @click="setLayout">
        <i class="fas fa-save"></i> Opslaan
      </button>
    </div>
  </div>
</template>

<script>

import LayoutPreviewBox from '@/components/layout/LayoutPreviewBox.vue';

export default {

  components: {
    LayoutPreviewBox,
  },

  props: {
    a3_document: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    layouts: [],
    selected_layout_id: null,
  }),

  mounted() {
    this.selected_layout_id =
      this.a3_document.meta.layout.layout_id;
    this.loadLayouts();
  },

  methods: {
    toggleLayoutId(layoutId) {
      if (this.selected_layout_id == layoutId) {
        this.selected_layout_id = null;
        return;
      }

      this.selected_layout_id = layoutId;
    },

    loadLayouts() {
      this.$http.get(`layout`)
        .then(res => {
          this.layouts = res.body;
        })
    },

    setLayout() {
      this.$http.put(`a3/${this.a3_document.id}/layout`, {
        layout_id: this.selected_layout_id
      }).then(
        () => this.$router.push(`/a3/${this.a3_document.id}`)
      );
    },
  },

}
</script>

<style lang="scss">

.layout-preview-grid {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.layout-preview-container {
    margin: 0;
    padding: 0;
    display: inline-block;
    box-sizing: border-box;
}

</style>
