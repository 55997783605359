<template>

  <WithReportMenuContainer :a3doc_id="a3document_id" :filter_slug="filter_slug">
    <div style="max-width: 1120px; margin: 0 auto;">
        <h1 class="report-title">
            <i class="fas fa-tasks-alt"></i>
            {{ $t('ui.report.action_progress') }}</h1>
        <h2 class="report-a3-name">{{ a3_title }}</h2>

      <FilterPrint :filter="filterOptions"
         v-if="a3_document != null"
         :a3_document="a3_document"
      />

      <template v-if="a3_document != null && actions != null">
          <div class="action-progress-report-grid">
              <div>
              </div>
              <div class="date-header" style="">
                  <div class="start-icon" :style="{'left': '0%'}" >
                      <i class="fas fa-play"></i><br>
                      <strong>{{ start_date | formatDate }}</strong>
                  </div>
                  <div class="today"
                       :style="{'left': `${getDatePosition(today)}%`}"
                      >
                      <i class="fas fa-calendar-day"></i><br>
                      <strong> {{ $t('ui.general.today') }} </strong>
                  </div>
                  <div class="end-icon" :style="{'right': '0%'}" >
                      <i class="fas fa-flag-checkered"></i><br>
                      <strong>{{ end_date | formatDate }}</strong>
                  </div>
              </div>

              <template v-for="a in actions">
                  <template v-if="filterMap == null || filterMap.action == null || filterMap.action[a.id]">
                      <div class="action-label"
                           @click="$router.push(`/a3/${a3document_id}/action/${a.id}`)"
                          >
                          <p>{{ a.name }}</p>
                      </div>
                      <div class="action-progress">
                          <div class="action-bar-outer-container">
                              <div class="action-bar-container"
                                   :style="{
                                        'margin-left': `${getDatePosition(a.start_date)}%`,
                                        'margin-right': `${100 - Math.max(getDatePosition(a.target_date), a.start_date ? getDatePosition(a.start_date) + 20 : 20)}%`
                                   }"
                                  >
                                  <div class="action-bar-progress"
                                       :style="{'width': `${a.status ? a.status.percent_done : 0}%`}"
                                      >
                                      {{ a.status ? a.status.percent_done : '' }}%
                                  </div>
                              </div>
                          </div>

                          <div class="action-task"
                               v-for="t in a.tasks"
                               :class="{'done': t.done }"
                               :style="{'left': `${getDatePosition(t.target_date)}%`}"
                              >
                              <i class="fas fa-map-signs"></i>

                              <div class="task-description">
                                  {{ t.name }} <br>
                                  <template v-if="t.target_date">
                                      {{ t.target_date | formatDate }}
                                  </template>
                              </div>
                          </div>

                          <div class="action-status"
                               v-for="s in a.status_history"
                               :style="{'left': `${getDatePosition(s.date)}%`}"
                               @click="$router.push(`/a3/${a3document_id}/action/${a.id}/status`)"
                              >
                              <i class="fas fa-rotate-180 fa-map-pin"></i>
                              <p>
                                  {{ s.percent_done }}%<br>
                                  <span class="date">{{ s.date | formatDate }}</span>
                              </p>

                          </div>

                          <div class="action-status-history-table"
                               v-if="a.status_history.length > 0"
                               style="margin-top: 75px;"
                              >
                              <h4>{{ $t('ui.action_status.history') }}</h4>
                              <table class="a3-table">
                                  <thead>
                                      <tr>
                                          <th>{{ $t('ui.action_status.date') }}</th>
                                          <th>{{ $t('ui.action_status.percent_done') }}</th>
                                          <th>{{ $t('ui.action_status.comment') }}</th>
                                      </tr>
                                  </thead>

                                  <tbody>
                                      <tr v-for="s in a.status_history">
                                          <td>{{ s.date }}</td>
                                          <td>{{ s.percent_done }}</td>
                                          <td>{{ s.comment }}</td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </template>
              </template>
          </div>
      </template>
    </div>
  </WithReportMenuContainer>
</template>

<script>

import moment from 'moment';

import FilterSlugUrl from '@/mixins/FilterSlugUrl';

import WithReportMenuContainer from '@/views/WithReportMenuContainer.vue';

import FilterPrint from '@/components/elements/FilterPrint.vue';

export default {

  mixins: [FilterSlugUrl],

  components: {
    WithReportMenuContainer,
    FilterPrint,
  },

  filters: {
    formatDate(val) {
      return moment(val).format('DD-MM-YYYY');
    }
  },

  props: {
    a3document_id: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    a3_document: null,
    filterMap: null,
    filterOptions: null,

    actions: null,
    org_areas: []
  }),

  computed: {

    today() {
        return moment();
    },

    organisation() {
      return this.$store.getters['auth/organisation'];
    },

    start_date() {
        if (this.a3_document == null) return null;
        if (this.a3_document.meta.timeframe.start_date == null) {
          if (this.a3_document.meta.timeframe.end_date == null) {
            return moment().startOf('year');
          }

          return moment(
              this.a3_document.meta.timeframe.end_date,
              'YYYY-MM-DD'
          ).startOf('year');

        }
        return moment(
            this.a3_document.meta.timeframe.start_date,
            'YYYY-MM-DD'
        );
    },
    end_date() {
        if (this.a3_document == null) return null;
        if (this.a3_document.meta.timeframe.end_date == null) {
          if (this.a3_document.meta.timeframe.start_date == null) {
            return moment().endOf('year');
          }

          return moment(
              this.a3_document.meta.timeframe.start_date,
              'YYYY-MM-DD'
          ).endOf('year');

        }
        return moment(
            this.a3_document.meta.timeframe.end_date,
            'YYYY-MM-DD'
        );
    },

    a3_title() {
        if (this.a3_document == null) return null;
        let meta = this.a3_document.meta;

        if (this.a3_document.meta.department != null) {
            return `${meta.department.name}, ${meta.timeframe.name}`;
        }

        return `${meta.organisation.name}, ${meta.timeframe.name}`;
    },
  },

  mounted() {
      this.loadA3();
      this.loadActions();
  },

  methods: {

      getDatePosition(d) {
          let total_diff = this.end_date - this.start_date;
          let p_diff = moment(d, 'YYYY-MM-DD') - this.start_date;
          let percent_position = Math.ceil((p_diff / total_diff) * 100);
          if (percent_position < 0) percent_position = 0;
          if (percent_position >= 100) percent_position = 100;

          return percent_position;
      },

    loadA3() {
      this.$http.get(`a3/${this.a3document_id}`)
        .then(res => {
          this.a3_document = res.body;
          this.org_areas = this.a3_document.meta.layout.structure.org_areas;

          this.filterOptions = this.getFilterOptions(this.filter_slug);
          this.filterMap = this.filterMapFromOptions(this.filterOptions);
        });
    },

    /*
     * We use the action report form the backend to get the data here,
     *
     * Very soon, this should replace the whole A3 doc retrieval thingy
     */
    loadActions() {
        this.$http.get(`report/action_list/${this.a3document_id}`)
            .then((res) => {
                this.actions = res.body.actions;
            });
    },
  },
};
</script>



<style lang="scss">

.action-progress-report-grid {
    display: grid;
    grid-template-columns: 400px auto;
    grid-column-gap: 20px;
    grid-row-gap: 75px;
    position: relative;


    .today {
        position: absolute;
        padding-left: 5px;
        padding-bottom: 15px;
        color: $a3-green;
        border-left: 2px $a3-green solid;
    }
    .start-icon {
        position: absolute;
        padding-left: 5px;
        padding-bottom: 15px;
        color: $a3-green;
        border-left: 2px $a3-green solid;
        i {
            font-size: 24px;
            margin-bottom: 5px;
        }
    }
    .end-icon {
        i {
            font-size: 24px;
            margin-bottom: 5px;
        }
        position: absolute;
        padding-right: 5px;
        text-align: right;
        padding-bottom: 15px;
        color: $a3-green;
        border-right: 2px $a3-green solid;
    }


    .date-header {
        border-bottom: 2px $a3-green solid;
        position: relative;
        height: 62px;
    }

}

.action-label {
    margin-top: 0;
    cursor: pointer;

    transition: color .15s ease-out;

    &:hover {
        text-decoration: underline;
        color: $a3-green;
    }
}


.action-progress {

    position: relative;

    .action-task {
        position: absolute;
        top: 0;
        font-size: 23px;
        cursor: pointer;

        color: $a3-green;
        transition: transform .15s ease-out;
        transform-origin: bottom left;

        &.done {
            color: $general-green;
            opacity: .5;
        }

        .task-description {
            position: absolute;
            pointer-events: none;
            bottom: 100%;
            opacity: 0;
            font-size: 16px;
            user-select: none;
            background-color: white;
            border: 2px $a3-green solid;
            border-radius: 5px;
            width: 250px;
            max-width: 400px;
            padding: 5px;
            z-index: 1000;
        }

        &:hover {
            transform: scale(1.1);

            .task-description {
                opacity: 1;
            }
        }
    }

    .action-status {
        position: absolute;
        top: bottom;

        i {
            font-size: 23px;

            &:hover {
                transform: scale(1.1) rotate(180deg);
            }
        }

        color: $a3-green;
        transition: transform .15s ease-out;
        cursor: pointer;
        transform-origin: top left;

        z-index: 500;

        .date {
            font-size: 12px;
            vertical-align: top;
        }

    }

    .action-bar-container {
        position: relative;
        margin-top: 25px;
        border: 2px $a3-green solid;
        background-color: rgba($a3-green, .1);
        border-bottom: 0px;
        height: 20px;
        border-radius: 5px 5px 0 0;
    }

    .action-bar-outer-container {
        border-bottom: 2px rgba($a3-green, .8) solid;
    }

    .action-bar-progress {
        position: aboslute;
        height: 100%;
        line-height: 100%;
        text-align: center;
        color: white;
        vertical-align: bottom;
        padding-top: 3px;
        font-weight: bold;
        box-sizing: border-box;
        background-color: $general-green;
        border-radius: 3px;
    }

    .action-status-history-table {
        max-width: 500px;
        margin-right: 0;
        margin-left: auto;
        margin-bottom: 50px;

        h4 {
            margin-bottom: 5px;
            color: $a3-green;
        }
    }

}
</style>
