<template>
  <div class="a3-login-container">
    <div class="background-image"></div>
    <div class="login-inner">
      <div style="text-align: right">
        <img src="@/public/img/a3_online_logo.png" class="login-logo"/>
      </div>


      <div class="element-form">
        <div class="login-form-field" v-if="!show_two_factor">
          <label><i class="fas fa-envelope"></i>
          {{ $t('ui.login.mail') }} </label>
          <input v-model="login.mail"
            class="a3-input" type="email" placeholder="naam@organisatie.nl"/>
        </div>

        <InlineErrorBox v-if="error_key != null" :error_key="error_key" />

        <div class="form-buttons">
          <button type="submit" class="login-button" @click="getSSOUrl">
            {{ $t('ui.login.login') }} <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
      <LoadingOverlay :loading="loading" />

    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import InlineErrorBox from '@/components/elements/InlineErrorBox.vue';

export default {
  components: {
    InlineErrorBox,
  },

  data: () => ({
    login: {
      mail: '',
    },
    loading: false,
    error_key: null,
    show_license_error: false,
    show_two_factor: false,
  }),

  methods: {
      getSSOUrl() {
          this.$http.post("sso/url", { mail: this.login.mail, callback_url: window.location.origin }).then((r) => {
              window.localStorage.setItem('sso_auth_client_key', r.data.client_key);
              window.location.href = r.data.auth_url;
          });
      },

    loadNotificationCount() {
      return this.$http.get('notifications/count_unread')
        .then((res) => {
          this.$store.commit('settings/setNotificationCount', res.body);
        })
    },

    continueRedirect() {
      this.$router.replace('/a3');
    },
  }
}
</script>


<style lang="scss">

@import "@/assets/variables.scss";

.a3-login-container {
  background-color: darken($a3-green, 10%);
  margin: 0;
  position: relative;

  height: calc(100vh - 0px);
  width: calc(100vw - 0px);

  .background-image {
    background-image: url('../public/img/a3_login_background.png');
    background-size: cover;
    opacity: .6;
    filter: blur(8px);
    height: 100%;
  }

  @media (max-width: 680px) {
    .login-inner {
      left: 5px;

      label {
        margin-top: 20px;
        margin-bottom: 10px;
        width: 100%;
        display: block;
        text-align: left;
      }

    }
  }
  @media (min-width: 681px) {
    .login-inner {
      left: calc((100% - 600px) / 2);

      label {
        margin-top: 20px;
        margin-bottom: 10px;
        vertical-align: middle !important;
        width: 150px !important;
      }
    }

  }


  .login-inner {
    position: absolute;
    top: 90px;
    background-color: white;
    width: calc(100% - 70px);
    max-width: 600px;
    min-width: 250px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    padding: 30px;
    border-radius: 10px;

    -webkit-box-shadow: 0px 0px 52px 2px rgba(0,0,0,0.49);
    -moz-box-shadow: 0px 0px 52px 2px rgba(0,0,0,0.49);
    box-shadow: 0px 0px 52px 2px rgba(0,0,0,0.49);

    .form-buttons {
      text-align: center;
      padding-left: 0;
    }
  }

  .a3-input {
    padding: 15px;
    width: calc(100% - 30px - 2px);
    margin: 0;
    vertical-align: middle;

    border-radius: 5px;
    border: 2px rgba($a3-green, .2) solid;
    transition: border-color .1s ease-out;
    &:focus {
      outline: 0;
      border: 2px rgba($a3-green, 1) solid;
    }
  }

  text-align: center;

  h2 {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    padding-left: 165px;
    color: $a3-green;
  }

  .input-container {
    margin-top: 30px;

    h3 {
      margin-bottom: 4px;
    }
  }
}
.login-help-button {
  font-size: .8em;
  color: $a3-green;
  margin-left: 15px;
  cursor: pointer;

  &:hover {
    color: lighten($a3-green, 10%);
    text-decoration: underline;
  }
}


.login-logo {
  max-height: 90px;
  margin-top: 20px;
}
.login-button {
  padding: 10px 30px;
  display: inline-block;
  text-align: center;
  background-color: $a3-green;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border: 2px $a3-green solid;

  &:hover {
    background-color: white;
    color: $a3-green;
  }
}
</style>
