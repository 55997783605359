<template>
    <WithMenuContainer>
        <div>
            <h1>SSO invullen:</h1>
            <div class="form-row">
                <div class="form-field">
                    <label>Client ID</label>
                    <input data-cy="input-title" v-model="sso.sso_client_id" class="a3-input" autocomplete="off"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-field">
                    <label>Client secret</label>
                    <input data-cy="input-title" v-model="sso.sso_client_secret" class="a3-input" autocomplete="off"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-field">
                    <label>Directory ID</label>
                    <input data-cy="input-title" v-model="sso.azure_directory_id" class="a3-input" autocomplete="off"/>
                </div>
            </div>
            <div>
                <button class="a3-button cancel" @click="$router.go(-1)">
                    <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
                </button>
                <button @click="saveSSO()" class="a3-button action" data-cy="submit">
                    <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
                </button>
            </div>

        </div>
    </WithMenuContainer>
  </template>
  
  <script>
 
  
  export default {
  
    props: {
      organisation_id: {
        type: String,
        required: true,
      },
    },
  
    data: () => ({
        sso: {
            sso_client_id: "",
            sso_client_secret: "",
            azure_directory_id: ""
        },
    }),
  
    mounted() {
    },
  
    methods: {
      saveSSO(){
        this.$http.post(`root/organisation/${this.organisation_id}/sso`, {
            organisation_id: parseInt(this.organisation_id),
            sso_client_id: this.sso.sso_client_id,
            sso_client_secret: this.sso.sso_client_secret,
            azure_directory_id: this.sso.azure_directory_id,
        })
                .then((res) => {
                    this.$router.push(`/organisations/${this.organisation_id}`);
                })
      }
    },
  
  }
  </script>
  
  <style lang="scss">
  
  
  </style>
  