<template>
    <div>
        <div style="float: right; ">
            <div class="form-buttons">
                <button class="a3-button action"
                        @click="execute"
                    >
                    <i class="fas fa-play"></i> {{ $t('ui.restructure.execute') }}
                </button>
            </div>
        </div>

        <h1>{{ $t('ui.restructure.copy') }}</h1>

        <div class="element-move-grid">

            <div>
                <h3>{{ $t('ui.restructure.from') }}</h3>
                <div class="plan-select-container"
                     v-if="!selecting_plan_from"
                     @click="selecting_plan_from = true"
                    >
                    <h3 v-if="plan_from != null">
                        <template v-if="plan_from.meta.department != null">
                            {{ plan_from.meta.department.name }} -
                        </template>
                        <template v-else>
                            {{ plan_from.meta.organisation.name }} -
                        </template>

                        {{ plan_from.meta.timeframe.name }}
                    </h3>
                    <div v-if="plan_from != null && plan_from.meta"
                         class="program-name-select-subtitle"
                        >
                        {{ plan_from.meta.meta.matrix_name }}
                    </div>
                </div>
                <div v-if="selecting_plan_from">
                  <A3PlanSelector
                      @select="selectPlanFrom"
                      @cancel="selecting_plan_from = false"
                   />
                </div>

                <div class="plan-element-select-container" v-if="plan_from != null && !selecting_plan_from">
                    <div class="mission">
                        <CheckBox
                         v-model="elementSelection.mission"
                        /> <label>{{ $t('definitions.terms.mission') }}</label>
                    </div>
                    <div class="vision">
                        <CheckBox
                         v-model="elementSelection.vision"
                        /> <label>{{ $t('definitions.terms.vision') }}</label>
                    </div>
                    <div class="sbf element-box">
                        <div>
                            <h4>{{ $tc('definitions.short.sbf') }}</h4>
                        </div>

                        <div class="options">
                            <h4 class="selection-subtitle">{{ $t('ui.restructure.selection_method') }}</h4>

                            <select class="a3-input"
                                    v-model="elementSelection.sbf_selection"
                                >
                                <option value='all'>{{ $t('ui.restructure.all_sbf') }}</option>
                                <option value='custom'>{{ $t('ui.restructure.custom_selection') }} </option>
                                <option value='none'>{{ $t('ui.restructure.none') }} </option>
                            </select>

                            <div style="padding-left: 25px">
                                <multiselect
                                    id="sbf-multiselect"
                                    v-model="elementSelection.sbf"
                                    v-if="elementSelection.sbf_selection == 'custom'"
                                    data-cy="filter-sbf-select"
                                    :options="plan_from.sbf"
                                    :placeholder="$t('ui.filter_tab.select_sbf')"
                                    :selectLabel="$t('ui.general.enter_select')"
                                    :selectedLabel="$t('ui.general.selected')"
                                    :deselectLabel="$t('ui.general.enter_deselect')"
                                    :multiple="true"
                                    :close-on-select="false"
                                    track-by="id"
                                    label="name">
                                <template v-slot:noOptions>
                                    {{ $t('ui.general.list_is_empty') }}
                                </template>
                                </multiselect>
                            </div>
                        </div>


                    </div>
                    <div class="kpi element-box">
                        <div>
                            <h4>{{ $tc('definitions.short.kpi') }}</h4>
                        </div>

                        <div>
                            <h4 class="selection-subtitle">{{ $t('ui.restructure.selection_method') }} </h4>
                            <select class="a3-input"
                                    v-model="elementSelection.kpi_selection"
                                >
                                <option value='all'>{{ $t('ui.restructure.all_kpi') }}</option>
                                <option value='linked'>{{ $t('ui.restructure.kpi_linked_to_sbf') }}</option>
                                <option value='custom'>{{ $t('ui.restructure.custom_selection') }} </option>
                                <option value='none'>{{ $t('ui.restructure.none') }} </option>
                            </select>

                            <div style="padding-left: 25px;
                                        max-width: 100%;
                                ">
                                <multiselect
                                    v-model="elementSelection.kpi"
                                    v-if="elementSelection.kpi_selection == 'custom'"
                                    :options="Object.values(plan_from.kpi).flat()"
                                    :multiple="true"
                                    :close-on-select="false"
                                    :placeholder="$t('ui.filter_tab.select_kpi')"
                                    :selectLabel="$t('ui.general.enter_select')"
                                    :selectedLabel="$t('ui.general.selected')"
                                    :deselectLabel="$t('ui.general.enter_deselect')"
                                    track-by="id"
                                    label="name">
                                    <template v-slot:noOptions>
                                        {{ $t('ui.general.list_is_empty') }}
                                    </template>
                                </multiselect>
                            </div>

                            <div class="option-container"
                                 v-if="elementSelection.kpi_selection != 'none'"
                                >
                              <h4 class="selection-subtitle">{{ $t('ui.general.options') }} </h4>
                              <div>
                                <CheckBox
                                          v-model="elementSelection.kpi_with_measurements"
                                /> <label>{{ $t('ui.restructure.with_measurements') }}</label>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="action element-box">
                        <div>
                            <h4>{{ $tc('definitions.short.action') }}</h4>
                        </div>


                        <div>
                            <h4 class="selection-subtitle">{{ $t('ui.restructure.selection_method') }} </h4>
                            <select class="a3-input"
                                    v-model="elementSelection.action_selection"
                                >
                                <option value='all'>{{ $t('ui.restructure.all_action') }}</option>
                                <option value='linked'>{{ $t('ui.restructure.action_linked_to_kpi') }}</option>
                                <option value='custom'>{{ $t('ui.restructure.custom_selection') }} </option>
                                <option value='none'>{{ $t('ui.restructure.none') }} </option>
                            </select>

                            <div style="padding-left: 25px">
                                <multiselect
                                    v-model="elementSelection.action"
                                    v-if="elementSelection.action_selection == 'custom'"
                                    :options="Object.values(plan_from.actions).flat()"
                                    :multiple="true"
                                    :close-on-select="false"
                                    :placeholder="$t('ui.filter_tab.select_action')"
                                    :selectLabel="$t('ui.general.enter_select')"
                                    :selectedLabel="$t('ui.general.selected')"
                                    :deselectLabel="$t('ui.general.enter_deselect')"
                                    track-by="id"
                                    label="name">
                                <template v-slot:noOptions>
                                    {{ $t('ui.general.list_is_empty') }}
                                </template>
                                </multiselect>
                            </div>

                            <div class="option-container"
                                 v-if="elementSelection.action_selection != 'none'"
                                >
                              <h4 class="selection-subtitle">{{ $t('ui.general.options') }} </h4>
                                <div>
                                  <CheckBox v-model="elementSelection.action_with_tasks" />
                                  <label>{{ $t('ui.restructure.with_tasks') }}</label>
                                </div>
                                <div>
                                  <CheckBox v-model="elementSelection.action_with_status" />
                                  <label>{{ $t('ui.restructure.with_status') }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="icon-container" style="padding-top: 30px;">
                <i class="fas fa-arrow-right fa-3x"></i>

                <h3>{{ $t('ui.restructure.copy_short') }} </h3>


                <div style="text-align: left">
                    <h2>{{ $t('ui.restructure.preview_selection') }}</h2>

                    <h4>{{ $tc('definitions.short.sbf') }} ({{ preview.sbf.length}})</h4>
                    <ul>
                        <li v-for="sbf in preview.sbf">
                            {{ sbf.name }}
                        </li>
                    </ul>

                    <h4>{{ $tc('definitions.short.kpi') }} ({{ preview.kpi.length }})</h4>
                    <ul>
                        <li v-for="kpi in preview.kpi">
                            {{ kpi.name }}
                        </li>
                    </ul>

                    <h4>{{ $tc('definitions.short.action') }} ({{ preview.action.length }})</h4>
                    <ul>
                        <li v-for="action in preview.action">
                            {{ action.name }}
                        </li>
                    </ul>
                </div>
            </div>

            <div>
                <h3>{{ $t('ui.restructure.to') }}</h3>

                <div class="plan-select-container"
                     v-if="!selecting_plan_to"
                     @click="selecting_plan_to = true"
                    >
                    <template v-if="plan_to != null">
                        <template v-if="plan_to.meta.department != null">
                            {{ plan_to.meta.department.name }} -
                        </template>
                        <template v-else>
                            {{ plan_to.meta.organisation.name }} -
                        </template>

                        {{ plan_to.meta.timeframe.name }}
                    </template>

                    <div v-if="plan_to.meta"
                         class="program-name-select-subtitle"
                        >
                        {{ plan_to.meta.meta.matrix_name }}
                    </div>
                </div>

                <div v-if="selecting_plan_to">
                  <A3PlanSelector
                      @select="selectPlanTo"
                      @cancel="selecting_plan_to = false"
                   />
                </div>
            </div>
        </div>


    </div>
    </div>
</template>

<script>

import WithManageMenuContainer from '@/views/WithManageMenuContainer.vue';
import A3PlanSelector from '@/components/elements/A3PlanSelector';
import Multiselect from 'vue-multiselect';

export default {

    components: {
      WithManageMenuContainer,
        A3PlanSelector,
        Multiselect,
    },

    data: () => ({
        selecting_plan_from: false,
        selecting_plan_to: false,

        // the selected mode for
        mode: "copy",
        selected_from_id: null,
        selected_to_id: null,

        plan_from: null,
        plan_to: null,
        elementSelection: {
            mission: false,
            vision: false,
            sbf_selection: 'all',
            sbf: [],
            kpi_selection: 'linked',
            kpi: [],
            kpi_with_measurements: true,

            action_selection: 'linked',
            action: [],
            action_with_status: true,
            action_with_tasks: true,
        },
    }),

    mounted() {
      if (this.$route.query.a3doc_id) {
        this.selected_from_id = parseInt(this.$route.query.a3doc_id);
      }
      if (this.$route.query.sbf) {
        this.elementSelection.sbf.push({"id": parseInt(this.$route.query.sbf)});
        this.elementSelection.sbf_selection = 'custom';
        this.elementSelection.kpi_selection = 'none';
        this.elementSelection.action_selection = 'none';
      }
      if (this.$route.query.kpi) {
        this.elementSelection.kpi.push({"id": parseInt(this.$route.query.kpi)});
        this.elementSelection.kpi_selection = 'custom';
        this.elementSelection.action_selection = 'none';
      }
      if (this.$route.query.action) {
        this.elementSelection.action.push({"id": parseInt(this.$route.query.action)});
        this.elementSelection.action_selection = 'custom';
      }
      if (this.selected_from_id == null) {
        this.selecting_plan_from = true;
      }
      if (this.selected_to_id == null) {
        this.selecting_plan_to = true;
      }

      if (this.selected_from_id != null) {
        this.loadElements();
      }
    },

    computed: {
        preview() {
            let preview = {
                sbf: [],
                kpi: [],
                action: [],
            };
            if (this.plan_from == null) return preview;

            if (this.elementSelection.sbf_selection == 'all') {
                preview.sbf = this.plan_from.sbf;
            } else if (this.elementSelection.sbf_selection == 'custom') {
                preview.sbf = this.elementSelection.sbf;
            }


            if (this.elementSelection.kpi_selection == 'all') {
                preview.kpi = Object.values(this.plan_from.kpi).flat();
            } else if (this.elementSelection.kpi_selection == 'custom') {
                preview.kpi = this.elementSelection.kpi;
            } else if (this.elementSelection.kpi_selection == 'linked') {
                let sbf_ids = preview.sbf.map((sbf) => sbf.id);
                preview.kpi = Object.values(this.plan_from.kpi)
                    .flat()
                    .filter((kpi) => sbf_ids.includes(kpi.sbf_id));
            }

            if (this.elementSelection.action_selection == 'all') {
                preview.action = Object.values(this.plan_from.actions).flat();
            } else if (this.elementSelection.action_selection == 'custom') {
                preview.action = this.elementSelection.action;
            } else if (this.elementSelection.action_selection == 'linked') {
                let kpi_ids = preview.kpi.map((kpi) => kpi.id);
                preview.action = Object.values(this.plan_from.actions)
                    .flat()
                    .filter((action) => kpi_ids.includes(action.kpi_id));
            }

            return preview;
        },
    },

    watch: {
        selected_to_id() {
            this.$http.get(`a3/${this.selected_to_id}/meta`)
                .then((res) => {
                    this.plan_to = { meta: res.body };
                });
        }
    },

    methods: {
        setMode(mode) {
            this.mode = mode;
        },

        execute() {
            let endpoint = `a3_operations/copy_elements/${this.selected_from_id}/${this.selected_to_id}`;
            let reqBody = {...this.elementSelection};
            reqBody.sbf_ids = this.elementSelection.sbf.map(x => x.id);
            reqBody.kpi_ids = this.elementSelection.kpi.map(x => x.id);
            reqBody.action_ids = this.elementSelection.action.map(x => x.id);

            this.$http.post(endpoint, reqBody).then(() => {
                this.$router.push(`/a3/${this.selected_to_id}`);
            });
        },

        selectPlanFrom(ev) {
          this.plan_from = null;
          this.selected_from_id = ev.a3doc_id;
          this.loadElements();
          this.selecting_plan_from = false;
        },
        selectPlanTo(ev) {
          this.selected_to_id = ev.a3doc_id;
          this.selecting_plan_to = false;
        },

        loadElements() {
            this.$http.get(`a3/${this.selected_from_id}`)
                .then((res) => {
                    this.plan_from = res.body;

                    this.elementSelection.sbf = this.elementSelection.sbf
                      .map((sbf) => {
                        return this.plan_from.sbf.find((x) => x.id === sbf.id);
                      })
                      .filter((x) => x !== null && x !== undefined);
                    if (!this.elementSelection.sbf) this.elementSelection.sbf = [];

                    this.elementSelection.kpi = this.elementSelection.kpi
                      .map((kpi) => {
                        return Object.values(this.plan_from.kpi)
                            .flat()
                            .find((x) => x.id === kpi.id);
                      })
                      .filter((x) => x !== null && x !== undefined);
                    if (!this.elementSelection.kpi) this.elementSelection.kpi = [];

                    this.elementSelection.action = this.elementSelection.action
                      .map((action) => {
                        return Object.values(this.plan_from.actions)
                              .flat()
                              .find((x) => x.id === action.id);
                      })
                      .filter((x) => x !== null && x !== undefined);
                    if (!this.elementSelection.action) this.elementSelection.action = [];
                });
        }
    },
}
</script>

<style lang="scss">

.element-move-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 500px;
    grid-column-gap: 50px;
    grid-row-gap: 20px;
}

.plan-select-container {
    border: 1px rgba($a3-green, .2) solid;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: color .15s ease-out, background-color .15s ease-out;

    &:hover {
        background-color: rgba($a3-green, 1);
        color: white;
    }
}

.icon-container {
    text-align: center;
    color: rgba($a3-green, .4);
}

.plan-element-select-container {
    padding: 10px 20px;
    margin: 0 15px;
    border: 1px rgba($a3-green, .2) solid;
    border-radius: 0 0 10px 10px;
    border-top: 0px;

    h4 {
        margin-top: 5px;
        margin-bottom:5px;
    }

    > .mission {
        grid-area: mission;
    }
    > .vision {
        grid-area: vision;
    }
    > .sbf {
        max-width: 100%;
        grid-area: sbf;
    }
    > .kpi {
        max-width: 100%;
        grid-area: kpi;
    }
    > .action {
        max-width: 100%;
        grid-area: action;
    }


    display: grid;
    grid-template-areas: "mission vision"
                         "sbf sbf"
                         "kpi kpi"
                         "action action";
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    padding-bottom: 30px;
    padding-top: 30px;
}

.element-box {
    display: grid;
    grid-template-columns: 100px auto;

    select {
        margin-bottom: 4px;
    }


    .multiselect__tag {
        white-space: normal;
    }


    .selection-subtitle {
        color: $a3-green;
        opacity: .6;
    }
}


.program-name-select-subtitle {
    font-style: italic;
}

</style>
