<template>
  <div>
      <div v-if="!has_measurements && measurements != null"
           style="text-align: center; margin-top: 30px">
        <i class="fas fa-question-circle"></i>
        {{ $t('ui.kpi.no_values') }}
      </div>
      <div v-if="measurements == null || measurements.length == 0"
        style="text-align: center;"
        :style="{'height': height}"
        >
        <i class="fas fa-spinner fa-spin fa-4x"></i>
      </div>

      <div v-show="measurements != null && measurements.length > 0"
        :id="`kpi-${kpi.id}-report-graph`">
      </div>
  </div>
</template>


<script>

import ApexCharts from 'apexcharts';
import A3RelationHelper from '@/mixins/A3RelationHelper';

export default {

  mixins: [A3RelationHelper],

  props: {
    measurements: {
      type: Array,
      required: false,
      default: null,
    },

    relations: {
      type: Object,
      required: false,
      default: () => ({
        parents: [],
        children: [],
      })
    },

    kpi: {
      type: Object,
      required: true,
    },

    height: {
      type: String,
      required: false,
      default: '300px'
    },
    show_toolbar: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  data: () => ({
    // measurements: [],
  }),

  watch: {
    measurements() {
      this.$nextTick(() => this.renderChart());
    }
  },

  computed: {
    has_measurements() {
      if (this.measurements == null) return;
      return this.measurements
        .map(x => x.values)
        .flat()
        .length > 0
    },
  },

  methods: {
      renderChart() {
        // Not loaded
        if (this.measurements == null || this.measurements.length == 0) return;
        // No values
        if (!this.has_measurements) return;

        let series = this.measurements
          .map(x => {
            let name = x.a3doc_id == this.kpi.a3doc_id ?
                'Huidig jaarplan' :
                this.A3NameFromRelations(this.relations, x.a3doc_id);
            return {
              name: name.substring(0, 40) + '...',
              data: x.values.map(y => ({ x: y.date, y: y.value}))
            }
          });

        let annotations = { yaxis: [] };

        let tmin = this.kpi.target_min;
        if (this.kpi.kpi_ref && this.kpi.kpi_ref.target_min) {
          tmin = this.kpi.kpi_ref.target_min;
        }
        if (tmin) {
          annotations.yaxis.push({
            y: tmin, borderColor: '#a33', label: { text: `Min: ${tmin}` } });
        }

        let tmax = this.kpi.target_max;
        if (this.kpi.kpi_ref && this.kpi.kpi_ref.target_max) {
          tmax = this.kpi.kpi_ref.target_max;
        }
        if (tmax) {
          annotations.yaxis.push({
            y: tmax, borderColor: '#3a3', label: { text: `Max: ${tmax}` }
          });
        }

        let lowest_value = null;
        let highest_value = null;
        if (series[0].data.length > 0) {
          lowest_value = Math.min(
            ...series[0].data.map((x) => x.y),
            tmin == null ? 999999999 : tmin,
            tmax == null ? 999999999 : tmax
          );
          highest_value = Math.max(
            ...series[0].data.map((x) => x.y),
            tmin == null ? -99999999999 : tmin,
            tmax == null ? -99999999999 : tmax
          );
          let range = (highest_value - lowest_value);
          lowest_value -= range * .1;
          highest_value += range * .1;
        }

        let options = {
          chart: {
            type: 'line',
            toolbar: { show: this.show_toolbar },
            animations: {enabled: false},
            height: this.height,
          },
          stroke: { curve: 'straight' },
          markers: { size: 5 },
          colors: [
            '#0174b7', '#B5ABAB', '#85756E', '#B2A497', '#A5939A', '#C9BFBF'
          ],
          series,
          annotations,
          xaxis: { type: 'datetime' },
          yaxis: { min: lowest_value, max: highest_value },
        }
        let chart = new ApexCharts(
          document.querySelector(`#kpi-${this.kpi.id}-report-graph`),
          options
        );
        chart.render();
      }
  },
}
</script>

