<template>
  <div>
    <h1>{{ $t('ui.action_status.action_status') }}</h1>

    <h3>{{ $t('ui.action_status.update_status') }} </h3>
    <div class="element-form">
        <div class="form-field">
          <label>{{ $t('ui.action_status.date') }} </label>
          <DateInput v-model="new_status.date" />
        </div>
        <div class="form-field">
          <label>{{ $t('ui.action_status.percent_done') }} </label>
          <input class="a3-input" v-model="new_status.percent_done" type="number" />
        </div>
        <div class="form-field">
          <label>{{ $t('ui.action_status.cancelled') }} </label>
          <CheckBox v-model="new_status.cancelled" />
        </div>
        <div class="form-field" style="padding-bottom: 10px"
             v-show="dataManager.hasLayoutOption('mood_icons')"
            >
            <label>{{ $t('ui.action_status.mood') }}</label>
            <div class="mood-selector-container" >
                <div class="mood-select-button"
                     v-for="(mood, key) in a3_document.meta.layout.options.moodmap"
                     :class="{'selected': new_status.mood === mood.key}"
                     :style="{'color': mood.color }"
                     @click="selectMood(mood.key)"
                    >
                    <i class="fas fa-2x" :class="`fa-${mood.icon}`"></i><br>
                    {{ mood.caption }}
                    <div class="mood-background"
                         :style="{'background-color': mood.color}">
                    </div>
                </div>
            </div>
        </div>
        <div class="form-field">
          <label>{{ $t('ui.action_status.comment') }} </label>
          <textarea class="a3-input" v-model="new_status.comment" />
        </div>
        <div class="form-buttons">
          <button class="a3-button action" @click="saveStatus">
            <i class="fas fa-plus"></i>
            {{ $t('ui.action_status.save_new_status') }}
          </button>
        </div>
    </div>

    <div style="margin-top: 45px">
      <h3 style="margin: 0">{{ $t('ui.action_status.history') }} </h3>
      <table class="a3-table">
        <thead>
          <tr>
            <th style="width: 30px"></th>
            <th>{{ $t('ui.action_status.date') }} </th>
            <th>{{ $t('ui.action_status.percent_done') }} </th>
            <th>{{ $t('ui.action_status.cancelled') }} </th>
            <th
                 v-if="dataManager.hasLayoutOption('mood_icons')"
                >{{ $t('ui.action_status.mood') }} </th>
            <th>{{ $t('ui.action_status.comment') }} </th>
          </tr>
        </thead>

        <tbody>
          <tr
                v-for="s in status_list"
                :key="`table-measurement-` + s.id"
                @click="selected_status = selected_status == s.id ? null : s.id"
            >
            <td>
              <template v-if="selected_status == s.id">
                <i class="fas fa-check"></i>
              </template>
            </td>
            <td> {{ $d(new Date(s.date), 'long') }} </td>
            <td> {{ s.percent_done }} </td>
            <td> {{ s.cancelled ? 'x' : '' }} </td>
            <td v-if="dataManager.hasLayoutOption('mood_icons')" >
                <template v-if="getMood(s.mood) != null">
                    <i class="fas" :class="`fa-${getMood(s.mood).icon}`"></i>
                </template>
            </td>
            <td style="white-space: pre-wrap">{{ s.comment }}</td>
          </tr>
        </tbody>
      </table>

      <button v-if="selected_status" class="a3-button danger" @click="show_delete_overlay = true">
        <i class="fas fa-trash"></i> {{ $t('ui.general.delete') }}
      </button>

      <ConfirmationOverlay
             v-if="show_delete_overlay"
             @submit="deleteStatus"
             @cancel="show_delete_overlay = false"
      >
        {{ $t('ui.action_status.confirm_delete') }}
      </ConfirmationOverlay>
    </div>

    <div style="margin-top: 45px">
      <button class="a3-button action" @click="$router.go(-1)">
        <i class="fas fa-chevron-left"></i> {{ $t('ui.general.back') }}
      </button>
    </div>
  </div>
</template>

<script>

import moment from 'moment';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {

  mixins: [WithDocumentManager],
  props: {
    action_id: {
      type: String,
      required: true,
    },
  },

  computed: {
    action() {
      let id_to_find = parseInt(this.action_id);
      for (let action of Object.values(this.a3_document.actions).flat()) {
        if (action.id === id_to_find) { return action; }
      }
      return null;
    },
  },

  mounted() {
    this.loadStatusList();
  },

  data: () => ({
    new_status: {
      date: moment().format('YYYY-MM-DD'),
      percent_done: 0,
      comment: null,
      mood: null,
    },
    status_list: [],

    selected_status: null,
    show_delete_overlay: false,
  }),

  methods: {
    initStatusForm() {
        this.new_status = {
          date: moment().format('YYYY-MM-DD'),
          percent_done: 0,
          comment: null,
          mood: null,
        };
    },

    selectMood(key) {
        if (this.new_status.mood == key) {
            this.new_status.mood = null;
            return;
        }

        this.new_status.mood = key;
    },

    saveStatus() {
      this.new_status.percent_done = parseInt(this.new_status.percent_done);
      this.dataManager
        .createActionStatus(this.new_status, parseInt(this.action_id))
        .then((newStatus) => {
          this.initStatusForm()
          // TODO: This shouldn't update when the date isn't the newest
          this.action.status = newStatus;
          this.status_list.push(newStatus);
        });
    },

    loadStatusList() {
      this.dataManager
        .loadActionStatusList(this.action_id)
        .then((sl) => this.status_list = sl);
    },

    getMood(mood) {
        return this.a3_document.meta.layout.options.moodmap
            .find(x => x.key == mood);
    },

    deleteStatus() {
      this.dataManager
        .deleteActionStatus(this.selected_status, this.action_id)
        .then(() => {
            this.show_delete_overlay = false;
            this.selected_status = null;
            this.loadStatusList();
        });
    },
  },
}
</script>

<style lang="scss">

.mood-selector-container {
    width: calc(60% + 10px);
    display: inline-grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    .mood-select-button {
        position: relative;
        transition: opacity .15s ease-out;
        opacity: .3;
        cursor: pointer;
        text-align: center;
        box-sizing: border-box;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 10px;

        i {
            margin-bottom: 5px;
        }

        &.selected {
            opacity: 1;
            margin-top: -2px;
            margin-bottom: -2px;
            border: 2px $a3-green solid;
        }
        &:hover {
            opacity: 1;
        }

        .mood-background {
            padding: -10px;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0px;
            opacity: .1;
            border-radius: 10px;
        }
    }

}

</style>
