<template>
  <WithMenuContainer>
    <div class="container">
        <h1>{{ $t('ui.accounts.edit_features') }} </h1>

        <FeatureEditor
             v-if="feature_input != null"
             v-model='feature_input' />

      <button class="a3-button action"
              @click="saveFeatures"
        >
        <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
      </button>

      <button class="a3-button cancel"
              @click="$router.go(-1)"
        >
        <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
      </button>
    </div>
  </WithMenuContainer>
</template>

<script>

import FeatureEditor from '@/views/admin/forms/FeatureEditor.vue';

export default {
  components: {
    FeatureEditor,
  },

  props: {
    account_id: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    feature_input: null,
  }),

  mounted() {
    this.loadFeatures();
  },

  methods: {
    loadFeatures() {
      this.$http.get(`admin/account/${this.account_id}/features`)
        .then(res => this.feature_input = res.body);
    },
    saveFeatures() {
      this.$http.post(`admin/account/${this.account_id}/features`,
        {'features': this.feature_input}
      )
        .then(() => {
          this.$router.push(`/admin/accounts/${this.account_id}`);
        });
    },
  },
}
</script>

<style lang="scss">

.feature-edit-container {
  display: grid;
  grid-template-columns: repeat(3, auto);

  margin-bottom: 30px;

  h3 {
    color: $a3-green;
    font-size: 1.3em;
  }
}
</style>
