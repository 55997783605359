<template>
  <WithMenuContainer>
    <div class="element-form">
      <h1>Nieuwe organisatie</h1>
      <div class="form-row">
        <div class="form-field">
            <label>Organisatie naam</label>
            <input data-cy="input-title" v-model="organisation_name" class="a3-input" autocomplete="off"/>
        </div>
      </div>
      <hr>
      <h2>Licentie</h2>
      <LicenseForm :license="license" />

      <button class="a3-button cancel" @click="$router.go(-1)">
          <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
      </button>
      <button @click="createOrganisation()" class="a3-button action" data-cy="submit">
          <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
      </button>
    </div>
      
  </WithMenuContainer>
</template>

<script>
import LicenseForm from '@/views/manage/form/LicenseForm.vue';
import Vue from 'vue';

export default {

  components: {
       LicenseForm
  },

  data: () => ({
    organisation_name: "",
    license:{
        license_type:"",
        start_date:"",
        end_date:"",
        max_plans:0,
        active:true,
        organisation_id:""
    },
    organisation: null,
  }),
  computed: {
   
  },
  mounted() {
      
  },

  

  watch: {
      
  },

  methods: {
      createOrganisation(){
        this.$http.post("root/organisation", {name: this.organisation_name})
            .then((res) => {
                this.organisation = res.body;
                this.license.organisation_id = res.body.id;
                this.license.max_plans = parseInt(this.license.max_plans);
                console.log(this.license);
                this.$http.post("license", this.license)
                .then((res) => {
                  this.setOrganisation(this.organisation);
                })
            })
      },
      setOrganisation(organisation) {
        Vue.http.headers.common['A3-Organisation-Id'] = `${organisation.id}`;

        return this.$http.get('me').then((res) => {
          this.$store.commit('auth/setOrganisation', res.body.organisation);
          this.$store.commit('auth/setOrganisationImpersonated', true);
          this.$store.commit('auth/setFeatures', res.body.features);
          this.$router.push('/a3');
          this.$emit('close');
          return;
        }, (err) => {

        });
      },
  },
}
</script>

<style lang="scss">

</style>
