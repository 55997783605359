<template>
  <WithMenuContainer>
    <div>
      <h2>{{ $t('ui.layout.create') }} </h2>

      <template v-if="import_mode">
          <h2>{{ $t('ui.layout.import') }}</h2>
          <input type="file"
               id="upload-layout-file"
               class="upload-form-input"
               @change="onFileChange"
          />
      </template>
      <LayoutForm
        v-else
        :layout="layout"
        @submit="createLayout"
        @submit_and_use="createAndUseLayout"
        @cancel="$router.go(-1)"
      />
    </div>
  </WithMenuContainer>
</template>

<script>

import LayoutForm from '@/components/layout/LayoutForm.vue';

export default {

  components: {
    LayoutForm
  },

  data: () => ({
    import_mode: false,
    layout: {
      language: 'nl-NL',
      name: "Nieuwe layout",
      titles: {},
      descriptions: {},
      structure: {},
      style: {
        background_color: null,
        header_color: null,
        title_color: null,
      },
      options: null,
    }
  }),

  mounted() {
    if (this.$route.query.mode === 'import') {
      this.import_mode = true;
    }
  },

  methods: {
      onFileChange(ev) {
          if (ev.target.files.length > 0) {
            let input_file = ev.target.files[0];
            input_file.text().then((data) => {
              let json = JSON.parse(data);
              for (let key of Object.keys(this.layout)) {
                if (key in json) {
                  this.layout[key] = json[key];
                }
              }
              this.import_mode = false;
            })
          }
      },

    createLayout() {
      this.$http.post('layout', this.layout)
        .then((res) => {
          if (this.layout.style.logo_file && this.layout.style.logo_file != null) {
             return this.saveLogo(res.body.id)
          }
        }, () => {
          // TODO: Error handling!
        })
        .then(() => {
          this.$router.push('/organisation/layout');
        });
    },

    createAndUseLayout() {
      this.$http.post('layout?activate=true', this.layout)
        .then((res) => {
          if (this.layout.style.logo_file && this.layout.style.logo_file != null) {
             return this.saveLogo(res.body.id)
          }
        }, () => {
          // TODO: Error handling!
        })
        .then(() => {
          this.$router.push('/organisation/layout');
        });
    },

    saveLogo(layout_id) {
      let file = this.layout.style.logo_file;

      let formData = new FormData();
      formData.append('logo_file', file);

      return this.$http.post(`layout/${layout_id}/logo`, formData);
    },
  },
}
</script>
