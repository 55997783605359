<template>
  <div class="kpi-measurement-container">
    <h1>{{ $t('ui.kpi_stories.update') }}</h1>
    <em>{{ kpi.name }}</em>

    <KpiStoryForm
      v-if="story != null"
       :story="story"
       @cancel="$router.go(-1)"
       @delete="deleteStory"
       @submit="saveStory"
    />
  </div>
</template>

<script>

import KpiStoryForm from '@/components/forms/KpiStoryForm.vue';

export default {

  components: { KpiStoryForm },

  props: {
    a3_document: {
      type: Object,
      required: true,
    },
    kpi_id: {
      type: String,
      required: true,
    },
    story_id: {
      type: String,
      required: true,
    },

    dataManager: {
      type: Object,
      required: true,
    },
    interactionManager: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    story: null,
  }),

  computed: {
    kpi() {
      return this.dataManager.getKpi(this.kpi_id);
    },
  },

  mounted() {
    this.loadStory();
  },

  methods: {
    loadStory() {
      this.dataManager
        .loadKpiStories(this.kpi_id)
        .then((stories) => {
          for (let s of stories) {
            if (s.id === parseInt(this.story_id)) {
              this.story = s;
              break;
            }
          }
        });
    },

    saveStory() {
      this.dataManager
        .updateKpiStory(this.story, this.kpi_id, this.story_id)
        .then(() => {
          this.$router.go(-1);
        })
    },

    deleteStory() {
      this.dataManager
        .deleteKpiStory(this.story, this.kpi_id, this.story_id)
        .then(() => {
          this.$router.go(-1);
        })
    },
  },
}
</script>

<style lang="scss">

.kpi-measurement-container {
  h1 {
    color: $a3-green;
    opacity: .6;
    padding-left: 0;
  }

  h3 {
    color: $a3-green;
    margin-bottom: 5px;
    opacity: .6;
  }
}
</style>
