<template>
  <WithMenuContainer>
    <div class="container">
      <h1><i class="fas fa-user-edit"></i> {{ $t('ui.accounts.edit_employee') }} </h1>

      <PersonForm
             v-if="person != null"
             :person="person"
             @submit="updatePerson"
             @delete="deletePerson"
             @cancel="$router.go(-1)"
             />

    </div>
  </WithMenuContainer>
</template>

<script>

import PersonForm from '@/components/forms/PersonForm.vue';

export default {
  components: { PersonForm },

  props: {
    person_id: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    person: null,
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    },
  },

  mounted() {
    this.loadPerson();
  },

  methods: {
    loadPerson() {
      let url = `admin/people/${this.person_id}`;
      this.$http.get(url)
        .then((res) => {
          this.person = res.body.person;
        })
    },

    updatePerson() {
      if (this.person.department_id == '') {
        this.person.department_id = null;
      }

      let url = `organisation/people/${this.person_id}`;
      this.$http.put(url, this.person)
        .then((res) => {
            this.$router.push(`/organisation/people/${res.body.id}`);
        })
    },

    deletePerson() {
      let url = `organisation/people/${this.person_id}`;
      this.$http.delete(url)
        .then(() => {
            this.$router.push('/organisation/people');
        })
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/variables.scss";

.a3-table {
  border-spacing: 0;
  width: 100%;

  th {
    padding: 8px;
    border-bottom: 1px $a3-green solid;
  }
  thead {
      margin-bottom: 4px;
  }

  tbody tr {
    td {
      padding: 4px 10px;
    }
    cursor: pointer;
    transition: color .1s ease-in-out, background-color, .1s ease-in-out;

    &:hover {
      background-color: $a3-green;
      color: white;
    }
  }
}

.container {
  max-width: 960px;
  margin: 45px auto;
}
</style>
