<template>
  <div class="kpi-stories-container">
    <h1><i class="fas fa-comments"></i> {{ $t('ui.kpi_stories.stories') }}: {{ kpi.name }}</h1>

    <div class="buttons-container" style="margin-bottom: 30px">
      <div>
        <button class="a3-button action" @click="$router.go(-1)">
          <i class="fas fa-chevron-left"></i> {{ $t('ui.general.back') }}
        </button>
      </div>

      <div class="spacer"></div>

      <div style="text-align: right">
        <button class="a3-button action"
                @click="interactionManager.createKpiMeasurement(kpi_id)">
          <i class="fas fa-star"></i> {{ $t('ui.kpi_stories.judge') }}
        </button>
      </div>
      <div style="text-align: right">
        <button class="a3-button action"
                @click="interactionManager.createKpiStory(kpi_id)"
          >
          <i class="fas fa-plus"></i> {{ $t('ui.general.create') }}
        </button>
      </div>
    </div>

    <div class="stories-masonry-container"
         v-if="stories.length > 0"
      >
        <div v-for="story in stories"
             :key="story.id"
             class="stories-masonry-brick"
             :style="{'grid-row': `span ${storyBrickHeight(story.id)}`}"
            >
            <div :id="`kpi-story-inner-${story.id}`">
              <h3>{{ story.title }}</h3>
              <p class="story-date">{{ $d(new Date(story.date), 'long') }}</p>

             <RichTextRender :value="story.body" />

              <div v-if="editable" class="brick-buttons">
                <div class="add-timeframe-button"
                     @click="interactionManager.updateKpiStory(kpi_id, story.id)"
                  >
                  <i class="fas fa-pencil"></i> {{ $t('ui.general.edit') }}
                </div>
              </div>
            </div>
        </div>
    </div>

    <div v-if="stories.length == 0"
         class="no-stories-container"
         style="text-align: center; height: 400px;"
      >
      <h3>{{ $t('ui.kpi_stories.no_stories') }}</h3>

      <button class="a3-button big action"
              @click="interactionManager.createKpiStory(kpi_id)"
        >
        <i class="fas fa-plus"></i> {{ $t('ui.general.create') }}
      </button>
    </div>

    <div class="buttons-container">
      <div>
        <button class="a3-button action" @click="$router.go(-1)">
          <i class="fas fa-chevron-left"></i> {{ $t('ui.general.back') }}
        </button>
      </div>

      <div class="spacer"></div>

      <div style="text-align: right">
        <button class="a3-button action"
                @click="interactionManager.createKpiMeasurement(kpi_id)">
          <i class="fas fa-star"></i> {{ $t('ui.kpi_stories.judge') }}
        </button>
      </div>
      <div style="text-align: right">
        <button class="a3-button action"
                @click="interactionManager.createKpiStory(kpi_id)"
          >
          <i class="fas fa-plus"></i> {{ $t('ui.general.create') }}
        </button>
      </div>
    </div>



  </div>
</template>

<script>

import moment from 'moment';

import RichTextRender from '@/components/forms/RichTextRender';

export default {

  components: {
    RichTextRender,
  },

  props: {
    kpi_id: {
      type: String,
      required: true,
    },

    editable: {
      type: Boolean,
      required: false,
      default: false,
    },

    dataManager: {
      type: Object,
      required: true,
    },
    interactionManager: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.loadMeasurements();
  },

  data: () => ({
      stories: [],
    selected_measurement: null,
    show_delete_overlay: false,
  }),

  watch: {
    stories() {
      setTimeout(() => {
        this.$forceUpdate();
      }, 10);
    },
  },

  computed: {
    kpi() {
      return this.dataManager.getKpi(this.kpi_id);
    },

    storyBrickHeight() {
      return (story_id) => {
        let p_el = document.getElementById(`kpi-story-inner-${story_id}`);
        if (!p_el) { return 5; }

        let height = p_el.offsetHeight;
        let span = Math.ceil(height / 35);

        return 1 + span;
      }
    },
  },

  mounted() {
    this.loadStories();
  },

  methods: {
    loadStories() {
      this.dataManager
        .loadKpiStories(parseInt(this.kpi_id))
        .then((stories) => this.stories = stories);
    },

    deleteMeasurement() {
      this.dataManager
        .deleteKpiMeasurement(this.kpi_id, this.selected_measurement)
        .then(() => {
          this.loadMeasurements();
          this.selected_measurement = null;
          this.show_delete_overlay = false;
        })
    },
  },
}
</script>

<style lang="scss">

.kpi-stories-container {
  h1 {
    color: $a3-green;
  }

  .buttons-container {
    display: grid;
    grid-template-columns: 100px auto 150px 150px;
  }
}

.no-stories-container {
  text-align: center;
  height: 400px;
  box-sizing: border-box;
  padding-top: 50px;

  h3 {
    font-size: 24px;
    color: $a3-green;
  }

  .a3-button {
    margin-top: 20px;
  }
}

.stories-masonry-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  grid-auto-rows: 25px;
  width: 100%;

  .stories-masonry-brick {
      transition: all .2s ease-out;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 5px;
      background-color: white;
      overflow-y: hidden;

      p.story-date {
        margin-top: -5px;
        margin-bottom: 5px;
        opacity: .6;
        color: $a3-green;
        font-size: 15px;
        font-style: italic;
      }
  }

  h3 {
    color: $a3-green;
    margin-top: 5px;
    margin-bottom: 5px;
    opacity: .8;
  }
}
</style>


