
import Vue from "vue";

/*
 * The job of a Data Managers is to make sure all data
 * that is edited in A3 documents is saved and retrieved.
 *
 * Depending on the state of the application, we may
 * have different needs to save and retrieve. The standard
 * data manager uses user credentials to store and retrieve
 * data in the regular way.
 *
 * Other types of managers include:
 *  - ReviewDataManager (which doesn't save, but creates review elements)
 *  - PublicDataManager, can only read public endpoints using a public key
 *  - DemoDataManager, only stores the data locally
 *
 */
export interface A3DataManager {

  loadDocument(): Promise<object>;

  getCapability(capability: string): boolean;

  getMission(): string;
  getVision(): string;
  saveMission(mission: string): Promise<object>;
  saveVision(vision: string): Promise<object>;

  // Get all SBFs from this data manager
  getSbfList(): object[];
  // Get a single SBF from this data manager
  getSbf(sbfId: string): object;
  createSbf(sbf: object, pushTo?: number[]): Promise<object>;
  saveSbf(sbf: object, pushTo?: number[]): Promise<object>;

  // Get all KPIs from this data manager
  getKpiList(resultArea?: string): object[];
  // Get a single KPI from this data manager
  getKpi(kpiId: string): object;
  createKpi(kpi: object, pushTo?: number[]): Promise<object>;
  saveKpi(kpi: object, pushTo?: number[]): Promise<object>;

  // Get all KPIs from this data manager
  getActionList(orgArea?: string): object[];
  // Get a single KPI from this data manager
  getAction(actionId: string): object;
  createAction(action: object, pushTo?: number[]): Promise<object>;
  saveAction(action: object): Promise<object>;

  // loadActionChangelog(actionId: string): object[];

  // loadKpiMeasurements
  // loadKpiLinks
  // loadActionTasks
  // loadActionStatus
  // loadActionLinks
}

export class StandardA3DataManager implements A3DataManager {

  public a3DocId: number;
  public a3Document: any;
  private $http: any;

  constructor(a3DocId: number) {
    this.a3DocId = a3DocId;
    this.$http = (Vue as any).http;
    this.a3Document = null;
  }

  public getCapability(cap: string): boolean {
      return true;
  }

  public loadDocument(): Promise<object> {
    let tempA3;
    return this.$http.get(`a3/${this.a3DocId}`)
      .then((res) => {
        // Setting the document here already updates the interface,
        // but not the relations. This could lead to flickering
        // interface changes in the UI.
        tempA3 = res.body;
        return this.$http.get(`a3/${this.a3DocId}/relations`);
      }).then((res) => {
        this.a3Document = tempA3;
        Vue.set(this.a3Document.meta, "relations", res.body);
        return this.a3Document;
      });
  }

  public getResultAreas(): object[] {
    return this.a3Document.meta.layout.structure.result_areas;
  }
  public getResultArea(key: string): object {
    const ra = (this.getResultAreas() as any)
        .find((area) => area.key === key);
    if (ra == null) {
      return {
          color: "#444",
          key,
          name: "??",
      }
    }
    return ra;
  }
  public getResultAreaColors(): object {
    if (this.a3Document == null) {
      // return default
      return {};
    }

    const colorMap = {};
    for (const resultArea of this.a3Document.meta.layout.structure.result_areas) {
      colorMap[resultArea.key] = resultArea.color;
    }
    return colorMap;
  }


  public getOrgAreas(): object[] {
    return this.a3Document.meta.layout.structure.org_areas;
  }
  public getOrgArea(key: string): object {
    let org_area = (this.getOrgAreas() as any)
        .find((area) => area.key === key);

    if (org_area == null) {
      return {
          color: "#444",
          key,
          name: "??",
      }
    }
    return org_area;
  }

  public getPerson(personId: number): Promise<object> {
      return this.$http.get(`organisation/people/${personId}`)
        .then((res) => {
          return res.body;
        });
  }

  public getMission(): string {
    return this.a3Document.mission;
  }
  public saveMission(mission: string): Promise<object> {
    return this.$http.post("a3/mission",
      { mission, a3doc_id: this.a3DocId },
    ).then((res) => {
      if (this.a3Document) {
        this.a3Document.mission = res.body.mission;
        return this.a3Document.mission;
      }
      return mission;
    });
  }
  public getVision(): string {
    return this.a3Document.vision;
  }
  public saveVision(vision: string): Promise<object> {
    return this.$http.post("a3/vision",
      {vision, a3doc_id: this.a3DocId },
    ).then((res) => {
      if (this.a3Document) {
        this.a3Document.vision = res.body.vision;
        return this.a3Document.vision;
      }
      return vision;
    });
  }

  public getSbfList(): any {
    return this.a3Document.sbf;
  }
  public getSbf(sbfId: string): any {
    // In this default case, the id just comes from the db.
    // This is an int, and allows us to properly search this way
    const intSbfId = parseInt(sbfId, 10);
    return Object
      .values(this.a3Document.sbf)
      .find((sbf) => (sbf as any).id === intSbfId);
  }
  public createSbf(sbf: any, pushTo?: number[]): Promise<object> {
    const url = `a3/${this.a3DocId}/sbf`;
    return this.$http.post(url, sbf)
      .then((res) => {
        const resSbf = res.body;
        return this.$http.put(`${url}/${resSbf.id}/refs`, {
          a3doc_ids: pushTo,
          sbf_id: resSbf.id,
          // reference is always null @creation time
          sbf_ref_id: null,
        }).then(() => ({
          sbf_id: resSbf.id,
        }));
      });
  }
  public saveSbf(sbf: any, pushTo?: number[]): Promise<object> {
    const url = `a3/${this.a3DocId}/sbf/${sbf.id}`;
    const queue: any[] = [];

    if (!sbf.push_default) {
      pushTo = [];
    }

    if (sbf.sbf_ref == null) {
      queue.push( this.$http.put(url, sbf) );
    }
    queue.push(
        this.$http.put(`${url}/refs`, {
          a3doc_ids: pushTo,
          sbf_id: sbf.id,
          sbf_ref_id: sbf.sbf_ref ? sbf.sbf_ref.id : null,
      }).then(() => {
          // A somewhat dirty hack to update the child_refs (is updated anyway when back to a3)
          // this.sbf.child_refs = this.push_a3docs.map(a3doc_id => ({a3doc_id: a3doc_id }));
      }),
    );
    return Promise.all(queue);
  }
  public deleteSbf(sbfId: string): Promise<any> {
    return this.$http.delete(`a3/${this.a3DocId}/sbf/${sbfId}`);
  }

  public loadSbfChangelog(sbfId: string): Promise<any> {
    const url = `a3/${this.a3DocId}/sbf/${sbfId}/changelog`;
    return this.$http.get(url)
        .then((res) => res.body);
  }


  public getKpiList(resultArea?: string): any[] {
    if (this.a3Document == null) { return []; }
    if (resultArea) { return this.a3Document.kpi[resultArea]; }

    return Object.values(this.a3Document.kpi).flat();
  }
  public getKpi(kpiId: string): any {
    if (this.a3Document === null) { return null; }

    // This data manager just retrieves the ids
    // from the database in the backend
    const intKpiId = parseInt(kpiId, 10);

    return Object
      .values(this.a3Document.kpi)
      .flat()
      .find((kpi: any) => kpi.id === intKpiId);
  }
  public createKpi(kpi: any, pushTo?: number[]): Promise<object> {
    return this.$http.post(`a3/${this.a3DocId}/kpi`, kpi)
      .then((res) => {
        const kpiUrl = `a3/${this.a3DocId}/kpi/${res.body.id}`;
        const kpiId = res.body.id;
        return this.$http.put(`${kpiUrl}/refs`, {
          a3doc_ids: pushTo,
          kpi_id: res.body.id,
          // When creating an KPI, its ref is always null
          kpi_ref_id: null,
        }).then((res) => {
          return { kpi_id: kpiId }
        });
      });
  }
  public saveKpi(kpi: any, pushTo?: number[]): Promise<object> {
    const kpiUrl = `a3/${this.a3DocId}/kpi/${kpi.id}`;
    const queue: any[] = [];

    if (!kpi.push_default) {
      kpi.agg_type = null;
      pushTo = [];
    }

    // Determine if we should save the ref or the kpi
    // in the backend, the ref is automatically selected in the endpoint
    // using the ref
    if (kpi.kpi_ref == null) {
        queue.push(this.$http.put(kpiUrl, kpi));
    } else {
        queue.push(this.$http.put(`${kpiUrl}/ref`, kpi.kpi_ref));
    }

    queue.push(
      this.$http.put(`${kpiUrl}/refs`, {
        a3doc_ids: pushTo,
        kpi_id: kpi.id,
        kpi_ref_id: kpi.kpi_ref ? kpi.kpi_ref.id : null,
      }).then(() => {
          // A somewhat dirty hack to update the child_refs (is updated anyway when back to a3)
          // this.kpi.child_refs = this.push_a3docs.map(a3doc_id => ({a3doc_id: a3doc_id }));
      }),
    );

    return Promise.all(queue);
  }
  public deleteKpi(kpiId: string): Promise<object> {
      return this.$http.delete(`a3/${this.a3DocId}/kpi/${kpiId}`);
  }

  public loadKpiChangelog(kpiId: string): Promise<any> {
    const url = `a3/${this.a3DocId}/kpi/${kpiId}/changelog`;
    return this.$http.get(url)
        .then((res) => res.body);
  }

  public loadKpiMeasurements(kpiId: number, includeChildren: boolean = false): Promise<object> {
    let url = `a3/${this.a3DocId}/kpi/${kpiId}/measurement`;

    const queryOptions: string[] = [];
    if (includeChildren) { queryOptions.push("with_children=true"); }
    if (queryOptions.length > 0) { url += "?" + queryOptions.join("&"); }

    return this.$http.get(url)
      .then((res) => res.body);
  }
  public createKpiMeasurement(kpiId: number, measurement: any): Promise<object> {
    const url = `a3/${this.a3DocId}/kpi/${kpiId}/measurement`;
    return this.$http.post(url, measurement).then((res) => res.body);
  }
  public deleteKpiMeasurement(kpiId: number, measurementId: number): Promise<object> {
      const url = `a3/${this.a3DocId}/kpi/${kpiId}/measurement/${measurementId}`;
      return this.$http.delete(url);
  }

  public loadKpiLinks(kpiId: number): Promise<any> {
    const url = `a3/${this.a3DocId}/kpi/${kpiId}/link`;
    return this.$http.get(url).then((res) => res.body);
  }
  public loadKpiLink(linkId: number, kpiId: number): Promise<object> {
    return this.loadKpiLinks(kpiId)
      .then((links: any) => {
          return links.find((x) => x.id === linkId);
      });
  }
  public createKpiLink(link: object, kpiId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/kpi/${kpiId}/link`;
    return this.$http.post(url, link).then((res) => res.body);
  }

  public loadKpiStories(kpiId: number): Promise<any> {
    const url = `a3/${this.a3DocId}/kpi/${kpiId}/story`;
    return this.$http.get(url)
      .then((res) => res.body);
  }
  public createKpiStory(story: object, kpiId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/kpi/${kpiId}/story`;
    return this.$http.post(url, story)
      .then((res) => res.body);
  }
  public updateKpiStory(story: object, kpiId: number, storyId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/kpi/${kpiId}/story/${storyId}`;
    return this.$http.put(url, story)
      .then((res) => res.body);
  }
  public deleteKpiStory(story: object, kpiId: number, storyId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/kpi/${kpiId}/story/${storyId}`;
    return this.$http.delete(url);
  }

  public getActionList(orgArea?: string): any[] {
    if (this.a3Document == null) { return []; }
    if (orgArea) { return this.a3Document.actions[orgArea]; }

    return Object.values(this.a3Document.actions)
      .flat();
  }
  public getAction(actionId: string): any {
    const intId = parseInt(actionId, 10);
    return this.getActionList()
      .find((action: any) => action.id === intId);
  }
  public saveAction(action: any, pushTo?: number[]): Promise<object> {
    const actionUrl = `a3/${this.a3DocId}/action/${action.id}`;
    const queue: any[] = [];

    if (!action.push_default) {
      pushTo = [];
    }

    // Determine if we should save the ref or the action
    // in the backend, the ref is automatically selected in the endpoint
    // using the ref
    if (action.action_ref == null) {
        queue.push(this.$http.put(actionUrl, action));
    } else {
        queue.push(this.$http.put(`${actionUrl}/ref`, action.action_ref));
    }

    queue.push(
      this.$http.put(`${actionUrl}/refs`, {
        a3doc_ids: pushTo,
        action_id: action.id,
        action_ref_id: action.action_ref ? action.action_ref.id : null,
      }).then(() => {
          // A somewhat dirty hack to update the child_refs (is updated anyway when back to a3)
          // this.action.child_refs = this.push_a3docs.map(a3doc_id => ({a3doc_id: a3doc_id }));
      }),
    );

    return Promise.all(queue);
  }
  public createAction(action: any, pushTo?: number[]): Promise<object> {
    return this.$http.post(`a3/${this.a3DocId}/action`, action)
      .then((res) => {
        const actionUrl = `a3/${this.a3DocId}/action/${res.body.id}`;
        return this.$http.put(`${actionUrl}/refs`, {
          a3doc_ids: pushTo,
          action_id: res.body.id,
          // When creating an KPI, its ref is always null
          action_ref_id: null,
        });
      });
  }
  public deleteAction(actionId: string): Promise<object> {
    return this.$http.delete(`a3/${this.a3DocId}/action/${actionId}`);
  }

  public loadActionChangelog(actionId: string): Promise<any> {
    const url = `a3/${this.a3DocId}/action/${actionId}/changelog`;
    return this.$http.get(url)
        .then((res) => res.body);
  }

  public loadActionStatusTree(actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/status_tree`;
    return this.$http.get(url)
        .then((res) => res.body);
  }
  public loadActionStatusList(actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/status`;
    return this.$http.get(url)
        .then((res) => res.body);
  }
  public createActionStatus(aStatus: object, actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/status`;
    return this.$http.post(url, aStatus)
      .then((res) => res.body);
  }
  public deleteActionStatus(statusId: number, actionId: number): Promise<object> {
      const url = `a3/${this.a3DocId}/action/${actionId}/status/${statusId}`;
      return this.$http.delete(url);
  }

  public loadActionTaskList(actionId: number): Promise<any> {
    const url = `a3/${this.a3DocId}/action/${actionId}/task`;
    return this.$http.get(url)
        .then((res) => res.body);
  }
  public loadActionTask(actionId: number, taskId: number): Promise<any> {
    return this.loadActionTaskList(actionId)
      .then((tasks) => {
        return tasks.find((t) => t.id === taskId);
      });
  }
  public saveActionTask(task: any, actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/task/${task.id}`;
    return this.$http.put(url, task).then((res) => res.body);
  }
  public createActionTask(task: any, actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/task`;
    return this.$http.post(url, task).then((res) => res.body);
  }
  public deleteActionTask(taskId: number, actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/task/${taskId}`;
    return this.$http.delete(url).then((res) => res.body);
  }

  public loadActionLinks(actionId: number): Promise<any> {
    const url = `a3/${this.a3DocId}/action/${actionId}/link`;
    return this.$http.get(url).then((res) => res.body);
  }
  public loadActionLink(linkId: number, actionId: number): Promise<object> {
    return this.loadActionLinks(actionId)
      .then((links: any) => {
          return links.find((x) => x.id === linkId);
      });
  }
  public createActionLink(link: object, actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/link`;
    return this.$http.post(url, link).then((res) => res.body);
  }
  public updateActionLink(link: any, actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/link/${link.id}`;
    return this.$http.put(url, link).then((res) => res.body);
  }
  public deleteActionLink(linkId: number, actionId: number): Promise<object> {
    const url = `a3/${this.a3DocId}/action/${actionId}/link/${linkId}`;
    return this.$http.delete(url);
  }

  public getLayoutOption(option: string): any {
      return this.a3Document.meta.layout.options[option];
  }
  public hasLayoutOption(option: string): any {
      return this.getLayoutOption(option) === true;
  }
}
