
export default {
  props: {
    dataManager: {
      type: Object,
      required: true,
    },
    interactionManager: {
      type: Object,
      required: false
    },
  },

  computed: {
    a3_document() {
      if (this.dataManager == null) return null;
      return this.dataManager.a3Document;
    },
    relations() {
      if (this.a3_document == null) return [];
      return this.a3_document.meta.relations;
    },
    a3_layout() {
        if (this.a3_document == null) return null;
        return this.a3_document.meta.layout;
    },
    review() {
      return this.dataManager.review;
    },
    area_colors() {
      return this.dataManager.getResultAreaColors();
    }
  },
}
