var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manual-container",staticStyle:{"max-width":"900px"}},[(_vm.$i18n.locale == 'nl-NL')?[_c('h1',[_vm._v("Ondersteuning")]),_c('p',[_vm._v(" Wij helpen je om van strategie naar realisatie te gaan! Naast de applicatie A3 online helpen we je graag met het maken van de beste strategie, en deze op de juiste manier te implementeren in jouw organisatie. ")]),_vm._m(0),_c('h2',[_vm._v("Algemene ondersteuning")]),_vm._m(1),_vm._m(2)]:_vm._e(),(_vm.$i18n.locale == 'en-US')?[_c('h1',[_vm._v("Support")]),_c('p',[_vm._v(" We're here to help you get from strategy to realisation! ")]),_c('p',[_vm._v(" You're already using A3 online, which is a great start. The tools below might help you further to develop and implement your strategy. ")]),_c('h2',[_vm._v("Algemene ondersteuning")]),_vm._m(3),_vm._m(4),_vm._m(5)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Op deze pagina vind je algemene ondersteuning die je kan helpen. Behoefte aan 1-op-1 ondersteuning? Neem dan "),_c('a',{attrs:{"href":"/support/advisors"}},[_vm._v("contact op met een van jouw adviseurs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"a3-button big action",attrs:{"href":"https://www.a3online.nl/kennisbank","target":"_blank"}},[_c('i',{staticClass:"fas fa-books"}),_vm._v(" Kennisbank ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"a3-button big action",attrs:{"href":"https://www.youtube.com/channel/UCV9bJWHZdgaNG1sBTb_Qs0Q","target":"_blank"}},[_c('i',{staticClass:"fas fa-film"}),_vm._v(" Video's ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a3-button big action"},[_c('i',{staticClass:"fas fa-books"}),_vm._v(" Knowledge base ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a3-button big action"},[_c('i',{staticClass:"fas fa-film"}),_vm._v(" Videos (in dutch) ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a3-button big action"},[_c('i',{staticClass:"fas fa-file-alt"}),_vm._v(" Manual ")])
}]

export { render, staticRenderFns }