<template>
    <div>

        <h3>{{ $t('ui.a3_document.owner') }} </h3>

        <multiselect
           id="people-multiselect"
           v-model="selected_person"
           :options="people"
           :placeholder="$t('ui.filter_tab.select_person')"
           :selectLabel="$t('ui.general.enter_select')"
           :selectedLabel="$t('ui.general.selected')"
           :deselectLabel="$t('ui.general.enter_deselect')"
           :multiple="false"
           :close-on-select="true"
           track-by="id"
           label="label" />

        <div style="margin-top: 20px">
            <button class="a3-button action"
                    @click="saveOwner"
                    :class="{'disabled': save_state == 'saved'}"
                >

                <template v-if="save_state == 'in_progress'">
                    <i class="fas fa-spinner fa-spin"></i>
                </template>
                <template v-else>
                    <i class="fas fa-save"></i>
                </template>
                {{ $t('ui.general.save') }}
            </button>
            <template v-if="save_state == 'saved'">
                <i class="fas fa-check"></i>
                {{ $t('ui.general.saved_succesfully') }}
            </template>
        </div>
    </div>
</template>

<script>

import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },

    props: {
        a3doc_id: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        original_person: null,
        selected_person: null,
        people: [],
        meta: null,
        save_state: null,
    }),

    watch: {
        selected_person() {
            if (this.selected_person.id != this.original_person.id) {
                this.save_state = 'not_saved';
            }
        },
    },

    mounted() {
        this.loadPeople();
        this.loadA3Meta();
    },

    methods: {
        loadPeople() {
          this.$http.get('organisation/people')
            .then(res => this.people = res.body.map(x => ({
                    id: x.id,
                    label: x.name
                })
            ));
        },

        saveOwner() {
            this.save_state = 'in_progress';
            this.$http.put(`a3/${this.a3doc_id}/owner`, { person_id: this.selected_person.id })
                .then((res) => {
                    this.loadA3Meta();
                    this.save_state = 'saved';
                })
        },

        loadA3Meta() {
            return this.$http.get(`a3/${this.a3doc_id}/meta`)
                .then((res) => {
                    this.meta = res.body;
                    this.original_person = {
                        id: this.meta.owner.id,
                        label: this.meta.owner.name,
                    };
                    this.selected_person = {
                        id: this.meta.owner.id,
                        label: this.meta.owner.name,
                    };
                    this.save_state = "saved";
                })
        },
    }
}
</script>

