<template>
  <div class='sbf-fillout-container'>

     <div class="sbf-header">
         <h1>{{ $tc('definitions.terms.sbf', 1) }} </h1>
       <h2>{{ sbf.name }}</h2>

       <i class="fas fa-arrow-down sbf-arrow"></i>

       <div class="sbf-selector"
              v-if="sbf_options.length > 0"
           >
         <div class="main"
              @click="sbf_select_active = !sbf_select_active"
           >
           {{ $t('ui.sbf_fillout.select_sbf') }}
           <i class="fas fa-chevron-down"></i>
         </div>

         <div class="dropdown"
              :class="{'active': sbf_select_active}"
           >
           <div class="sbf-dropdown-item"
                :key="sbf.id"
                v-for="sbf in sbf_options"
                @click="() => {
                  interactionManager.selectSbf(sbf.id);
                  sbf_select_active = false;
                 } "
                >
             {{ sbf.name  }}
           </div>
         </div>
       </div>
     </div>

     <div class="sbf-fillout-body">
       <div class="sbf-fillout-row"
            v-for="(kpi, index) in kpis"
            :key="kpi.id"
         >
         <div class="sbf-fillout-action">
           <h2>{{ $tc('definitions.terms.action') }} </h2>

           <p style="text-align: center"
              class="first-add-button"
              @click="interactionManager.createActionForKpi(kpi.id)"
              v-if="actions[index].length == 0"
           >
              <i class="fas fa-plus"></i>
              {{ $t('ui.sbf_fillout.add_first_action') }}
           </p>

           <div v-for="action in actions[index]"
                class="action-list-item"
               :key="action.id"
                @click="interactionManager.updateAction(action.id)"
               >
               <template v-if="icons.get(action.org_area)">
                 <i class="fas" :class="`fa-${icons.get(action.org_area)}`"></i>
               </template>
             {{ action.name }}

             <div class="border-bottom"></div>
           </div>

          <div class="add-button-container add-action-button"
               tabindex="0"
               v-if="actions[index].length > 0"
               :data-cy="`add-action-${kpi.id}`"
               @click="interactionManager.createActionForKpi(kpi.id)"
               @keyup.enter="interactionManager.createActionForKpi(kpi.id)"
               >
              <i class="fas fa-plus"></i> {{ $t('ui.action.create') }}
          </div>
         </div>

         <div class="sbf-fillout-result">

           <KpiCardEdit
              :dataManager="dataManager"
              :interactionManager="interactionManager"
              :kpi="kpi"
              />

           <i class="fas fa-arrow-left result-arrow"></i>
         </div>
       </div>

       <div v-if="kpis.length == 0" class="sbf-fillout-row">
         <div class="sbf-fillout-action"
              style="opacity: .6"
           >
           <h2>{{ $tc('definitions.terms.action') }}</h2>

           <p style="text-align: center">
             {{ $t('ui.sbf_fillout.add_the_result_first') }}
             <i class="fas fa-chevron-double-right"></i>
           </p>
         </div>
         <div class="sbf-fillout-result">
           <h2>{{ $tc('definitions.terms.result') }} </h2>

           <p style="text-align: center"
              class="first-add-button"
               @click="interactionManager.createKpiForSbf(sbf_id)"
           >
              <i class="fas fa-plus"></i>
              {{ $t('ui.sbf_fillout.add_first_result') }}
           </p>
         </div>
       </div>
     </div>

     <div class="button-container"
          style="z-index: 150"
       >
       <button class="a3-button inverted"
               @click="interactionManager.createKpiForSbf(sbf_id)"
         >
         <i class="fas fa-chart-line"></i> {{ $t('ui.sbf_fillout.add_result') }}
       </button>
     </div>

  </div>
</template>



<script>

import KpiCardEdit from '@/components/sheets/KpiCardEdit';

export default {

  components: {
    KpiCardEdit,
  },

  props: {
    sbf_id: {
      type: String,
      required: true,
    },

    dataManager: {
      type: Object,
      required: true,
    },

    interactionManager: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    // Use of Map because of security reasons and user-settable keys
    icons: new Map([
      ['leadership', 'user-tie'],
      ['strategy_and_policy', 'chess'],
      ['management_employees', 'users'],
      ['management_resources', 'sack'],
      ['management_processes', 'cogs']
    ]),
    sbf_select_active: false
  }),

  mounted() {
      for (let oa of this.dataManager.a3Document.meta.layout.structure.org_areas) {
          if (oa.icon) {
              this.icons.set(oa.key, oa.icon);
          }
      }
      this.$forceUpdate();
  },

  computed: {
    sbf() {
      return this.dataManager.getSbf(this.sbf_id);
    },

    sbf_options() {
      return this.dataManager
        .getSbfList()
        .filter(x => x.id !== parseInt(this.sbf_id, 10));
    },

    kpis() {
      return this.dataManager.getKpiList()
        .filter(x => x.sbf_id == this.sbf_id);
    },

    actions() {
      return this
        .kpis
        .map((kpi) => {
          return this.dataManager
            .getActionList()
            .filter(x => x.kpi_id == kpi.id);
      })
    }
  },


};
</script>


<style lang="scss">

.sbf-fillout-container {
  box-sizing: border-box;
  padding: 10px 30px;
}
.sbf-header {
  position: relative;
  box-sizing: border-box;
  padding: 25px;
  min-height: 150px;
  background-color: white;
  color: $a3-green;
  border-radius: 10px;

  h1 {
    opacity: .4;
    margin-top: -5px;
    margin-bottom: 10px;
    font-size: 1.9em;
  }

  h2 {
    margin-top: -5px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 2.2em;
  }

  .sbf-selector {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 200;
    border-radius: 0 0 5px 5px;
    pointer-events: none;

    .main {
      opacity: .5;
      transition: opacity .2s ease-out;
      cursor: pointer;
      pointer-events: auto;
      &:hover {
        opacity: 1;
      }
      text-align: right;
    }

    .dropdown {
      margin-top: 5px;
      background-color: white;
      border-radius: 10px;
      width: 300px;
      transform: scaleY(0);
      transform-origin: top;
      pointer-events: none;
      transition: transform .15s ease-out;

      &.active {
        transform: scaleY(1);
        pointer-events: auto;
      }

      .sbf-dropdown-item {
        cursor: pointer;
        padding: 5px;
        border-color: rgba($a3-green, .2);
        border-width: 0px 1px 1px 1px;
        border-style: solid;
        &:last-child {
          border-radius: 0 0 5px 5px;
        }
        &:hover {
          background-color: $a3-green;
          color: white;
          border-color: white;
        }
      }
    }
  }
}

.sbf-fillout-row {
  display: grid;
  margin-top: 30px;
  grid-template-columns: .6fr .4fr;
  grid-column-gap: 30px;
}

.sbf-fillout-result, .sbf-fillout-action {
  position: relative;
  font-size: 1.3em;
  h2 {
    opacity: .4;
    margin-top: -5px;
    margin-bottom: 10px;
    font-size: 1em;
  }
  box-sizing: border-box;
  padding: 25px;
  border-radius: 10px;
  background-color: white;
  min-height: 125px;
  color: $a3-green;
  cursor: pointer;

  .add-button-container {
    font-size: 0.8em;
  }
}


.sbf-arrow {
  position: absolute;
  color: white;
  text-shadow: 1px 1px 2px $a3-green;
  right: 30px;
  bottom: -60px;
  font-size: 120px;
  z-index: 100;
}
.result-arrow {
  position: absolute;
  color: white;
  text-shadow: 1px 1px 2px $a3-green;
  left: -45px;
  bottom: -20px;
  font-size: 80px;
  z-index: 100;
}

.result-goal {
  display: grid;
  margin-top: 15px;
  grid-template-columns: 150px auto;
}

.sbf-fillout-container > .button-container {
  text-align: right;
  margin-top: 20px;
  > button {
    font-size: 1.3em;
    font-weight: bold;
  }
}


.sbf-fillout-container .action-list-item {
  i {
    width: 30px;
    text-align: center;
  }

  margin: 4px 0;

  .border-bottom {
    transform: scaleX(0);
    transform-origin: left;
    border-bottom: 1px rgba($a3-green, .5) solid;
    transition: transform .3s ease-out;
  }
  &:hover {
    color: lighten($a3-green, 10%);

    .border-bottom {
      transform: scaleX(.7);
      transform-origin: left;
    }
  }
}


.first-add-button {
  border: 2px $a3-green solid;
  padding: 10px;
  border-radius: 15px;
  transition: background-color .2s ease-out, color .2s ease-out;
  &:hover {
    background-color: $a3-green;
    color: white;
  }
}


</style>
