<template>
  <div>
    <PublicA3Header :title="a3_title" :publish_key="publish_key" />

    <div style="max-width: 1120px; margin: 0 auto;">
      <LoadingOverlay :loading="is_loading" />

      <div
         v-if="error_key != null"
        style="background: white; max-width: 450px; margin: 30px auto; border-radius: 5px;">
        <InlineErrorBox :error_key="error_key" />
      </div>

        <h1 class="report-title">
            <i class="fas fa-comments"></i>
            {{ $t('ui.report.a3_conversation') }}</h1>
        <h2 class="report-a3-name">{{ a3_title }}</h2>

      <FilterPrint :filter="filterOptions"
         v-if="a3_document != null"
         :a3_document="a3_document"
      />

      <template v-if="a3_document != null && measurements != null">
        <template v-for="sbf in a3_document.sbf">
          <A3SbfReport
                  v-if="filterMap == null || filterMap.sbf == null || filterMap.sbf[sbf.id]"
                 :filterMap="filterMap"
                 :relations="{ children: [], parents: []}"
                 :measurements="measurements"
                 :key="sbf.id"
                 :sbf="sbf"
                 :a3_document="a3_document" />
        </template>
      </template>
    </div>
  </div>
</template>

<script>

import FilterSlugUrl from '@/mixins/FilterSlugUrl';
import PublicA3Header from '@/components/nav/PublicA3Header';

import A3SbfReport from '@/components/reports/A3SbfReport.vue';

import FilterPrint from '@/components/elements/FilterPrint.vue';

export default {

  mixins: [FilterSlugUrl],

  components: {
    A3SbfReport,
    PublicA3Header,
    FilterPrint,
  },

  props: {
    publish_key: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    a3_document: null,
    is_loading: false,
    measurements: null,
    filterOptions: null,
    filterMap: null,
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    },
    a3_title() {
        if (this.a3_document == null) return null;
        let meta = this.a3_document.meta;

        if (this.a3_document.meta.department != null) {
            return `${meta.department.name}, ${meta.timeframe.name}`;
        }

        return `${meta.organisation.name}, ${meta.timeframe.name}`;
    },
  },

  mounted() {
    this.loadA3();
    this.loadMeasurements();
  },

  methods: {
    loadA3() {
      this.is_loading = true;
      this.a3_document = null;
      this.error_key = null;
      this.$http.get(`public/a3?key=${this.publish_key}`)
        .then(res => {
          this.a3_document = res.body;
          this.relations = {
            parents: res.body.parents,
            children: res.body.children,
          };

          this.is_loading = false;
        }, () => {
          this.error_key = "a3.not_found";
          this.is_loading = false;
        });
    },

    loadMeasurements() {
      this.$http.get(`public/kpi_measurements?key=${this.publish_key}`)
        .then(res => {
          this.measurements = res.body;
        });
    }
  },
};
</script>
