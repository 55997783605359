<template>
    <WithMenuContainer>
        <div>

            <h1>Licentie wijzigen</h1>
            <LicenseForm v-if="license != null" :license="license" :submitFunction="'updateLicense'"></LicenseForm>


        </div>
    </WithMenuContainer>
</template>

<script>

import WithManageMenuContainer from '@/views/WithManageMenuContainer.vue';
import LicenseForm from '@/views/manage/form/LicenseForm.vue';

export default {

    components: {
      WithManageMenuContainer,
      LicenseForm 
    },
    props: {
        organisation_id: {
            type: String,
            required: true,
        },
        license_id: {
            type: String,
            required: true,
        }
    },
    data: () => ({
        license: null,
    }),
    computed: {
    },
    mounted() {
        this.getLicense();
    },

    

    watch: {
        
    },

    methods: {
        getLicense(){
            this.$http.get(`license/${this.license_id}`)
                .then(res => this.license = res.body);
        },

        updateLicense(license){
            license.max_plans = parseInt(license.max_plans);
            this.$http.post(`license/${license.id}`, license)
                .then((res) => {
                    this.$router.push(`/organisations/${this.organisation_id}`)
                })
        }
    },
}
</script>