<template>
  <div class="kpi-measurement-container">
    <h1>{{ $t('ui.kpi_stories.create') }}</h1>
    <em>{{ kpi.name }}</em>

    <KpiStoryForm
       :story="story"
       @cancel="$router.go(-1)"
       @submit="saveStory"
    />
  </div>
</template>

<script>

import moment from 'moment';
import KpiStoryForm from '@/components/forms/KpiStoryForm.vue';

export default {

  components: { KpiStoryForm },

  props: {
    a3_document: {
      type: Object,
      required: true,
    },
    kpi_id: {
      type: String,
      required: true,
    },

    dataManager: {
      type: Object,
      required: true,
    },
    interactionManager: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    story: {
      title: null,
      date: moment().format('YYYY-MM-DD'),
      body: null,
    },
  }),

  computed: {
    kpi() {
      return this.dataManager.getKpi(this.kpi_id);
    },
  },

  methods: {
    saveStory() {
      this.dataManager
        .createKpiStory(this.story, this.kpi_id)
        .then(() => {
          this.$router.go(-1);
        })
    },
  },
}
</script>

<style lang="scss">

.kpi-measurement-container {
  h1 {
    color: $a3-green;
    opacity: .6;
    padding-left: 0;
  }

  h3 {
    color: $a3-green;
    margin-bottom: 5px;
    opacity: .6;
  }
}
</style>
