<template>
  <div id="display-tab">
    <div style="float: right;" class="icon-button-bar" >
      <div
        style="display: inline-block; float:none;"
        class="icon-button"
        @click="$emit('resetDisplayOptions')"
        ><i class="fas fa-redo"></i>
      </div>
      <div
        style="display: inline-block; float:none;"
        class="icon-button"
        @click="$router.push('/me/settings/a3_display')"
        ><i class="fas fa-cog"></i>
      </div>
      <div
        style="display: inline-block; float:none;"
        class="icon-button"
        @click="$emit('close')"
        ><i class="fas fa-times"></i>
      </div>
    </div>

    <h2><i class="fas fa-telescope"></i> {{ $t('ui.display_tab.display') }} </h2>

    <div class="display-tab-block">
      <h3>{{ $t('ui.display_tab.general') }} </h3>

      <div class="display-tab-element">
        <CheckBox id="number-action-pi" v-model="displayOptions.action_pi_numbers" />
        <label for="number-action-pi">{{ $t('ui.display_tab.action_pi_numbers') }} </label>
        <InfoBox left="-40px" top="25px" tkey="display_numbering" :html="true" />
      </div>
      <div class="display-tab-element">
        <CheckBox id="number-kruimelpad" v-model="displayOptions.show_trail" />
        <label for="number-kruimelpad">{{ $t('ui.display_tab.show_trail') }} </label>
      </div>
      <div class="display-tab-element">
        <CheckBox id="check-original-plan" v-model="displayOptions.show_plan_source" />
        <label for="check-original-plan">{{ $t('ui.display_tab.show_plan_source') }} </label>
      </div>
      <div class="display-tab-element">
        <CheckBox id="check-push-to" v-model="displayOptions.show_pushed_to" />
        <label for="check-push-to">{{ $t('ui.display_tab.show_deployed_to') }} </label>
      </div>
      <div class="display-tab-element">
        <CheckBox id="check-hover-focus" v-model="displayOptions.hover_focus" />
        <label for="check-hover-focus">{{ $t('ui.display_tab.hover_focus') }} </label>
      </div>
    </div>

    <div class="display-tab-block">
        <h3>{{ $tc('definitions.terms.result', 2) }} </h3>

      <div class="display-tab-element">
        <CheckBox id="display-pi-status"
          v-model="displayOptions.pi_status"
          />
        <label for="display-pi-status">{{ $t('ui.kpi.status') }} </label>
      </div>
      <div class="display-tab-element">
        <CheckBox id="display-pi-target"
                               v-model="displayOptions.pi_target"
        />
        <label for="display-pi-target">{{ $t('ui.kpi.norm') }} </label>
      </div>
      <div class="display-tab-element">
        <CheckBox id="display-pi-value"
          v-model="displayOptions.pi_current_value" />
        <label for="display-pi-value">{{ $t('ui.kpi.current_value') }} </label>
      </div>
    </div>

    <div class="display-tab-block">
        <h3>{{ $tc('definitions.terms.action', 2) }} </h3>

      <div class="display-tab-element">
        <CheckBox id="display-action-done"
            v-model="displayOptions.action_check"
        />
        <label for="display-action-done">{{ $t('ui.display_tab.check_when_done') }} </label>
      </div>
      <div class="display-tab-element"
           v-if="dataManager.hasLayoutOption('mood_icons')"
          >
        <CheckBox id="display-action-mood"
            v-model="displayOptions.action_mood"
        />
        <label for="display-action-mood" >{{ $t('ui.action_status.mood') }} </label>
      </div>
      <div class="display-tab-element">
        <CheckBox id="display-action-person"
            v-model="displayOptions.action_person"
        />
        <label for="display-action-person">{{ $t('ui.display_tab.initials_assigned_to') }} </label>
      </div>
      <div class="display-tab-element">
        <CheckBox id="display-action-milestone"
            v-model="displayOptions.action_milestone"
        />
        <label for="display-action-milestone">{{ $t('ui.display_tab.next_milestone') }} </label>
      </div>
      <div class="display-tab-element" style="padding-left: 10px">
        <i class="fas fa-level-up-alt fa-rotate-90" style="padding-right: 5px; padding-top: 8px; vertical-align: top; opacity: .5;"></i>
        <CheckBox id="display-action-target-date-milestone"
            v-model="displayOptions.action_target_date_milestone"
        />
        <label for="display-action-target-date-milestone">{{ $t('ui.display_tab.target_date_milestone') }}
          <InfoBox left="-250px" top="25px">
            {{ $t('ui.display_tab.target_date_milestone_info') }}
          </InfoBox>
        </label>
      </div>

      <div class="display-tab-element">
        <CheckBox id="display-action-cancelled-strikethrough"
            v-model="displayOptions.action_cancelled_strikethrough"
        />
        <label for="display-action-cancelled-strikethrough">{{ $t('ui.display_tab.show_cancelled_strikethrough') }}</label>
      </div>


      <div class="display-tab-element">
        <CheckBox id="display-action-progress"
            v-model="displayOptions.action_progress"
        />
        <label for="display-action-progress" >{{ $t('ui.display_tab.current_progress') }} </label>
      </div>
      <div class="display-tab-element">
        <CheckBox id="display-action-tasks"
            v-model="displayOptions.action_tasks"
        />
        <label for="display-action-tasks" >{{ $t('ui.display_tab.tasks') }} </label>
      </div>
    </div>

    <div class="display-tab-block">
      <button class="a3-button inverted" @click="$emit('close')">
          <i class="fas fa-chevron-left"></i> {{ $t('ui.filter_tab.show_plan') }}
      </button>
    </div>
  </div>
</template>


<script>

export default {
  props: {
    displayOptions: {
      type: Object,
      required: true,
    },

    dataManager: {
        type: Object,
        required: true,
    },
  },


};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

#display-tab {
  z-index: 2000;
  height: calc(100% - 30px);
  position: absolute;
  top: 0;
  right: 0;
  background-color: $a3-green;
  color: white;
  border-left: 2px $green-darkened solid;

  -webkit-box-shadow: -8px 3px 10px -5px rgba(0,0,0,0.49);
  -moz-box-shadow: -8px 3px 10px -5px rgba(0,0,0,0.49);
  box-shadow: -8px 3px 10px -5px rgba(0,0,0,0.49);

  transform-origin: right;

  padding: 15px;
  width: 450px;

  animation: .3s cubic-bezier(0, 0, 0, 1.01) slidein;
}

@keyframes slidein {
  from { transform: translateX(500px); }
  to { transform: translateX(0); }
}

.display-tab-block {
  margin-top: 30px;
  h3 {
    margin-bottom: 0px;
  }

  .display-tab-element {
    margin-top: 3px;

    label {
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
