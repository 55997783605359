<template>
  <div :class="`overlay-result-area-${kpi.result_area}`"
    class="kpi-view-overlay overlay-result-area"
    :style="{'--result-area-color': getKpiColor()}"
    >
      <h3 class="overlay-type-header">
        {{ $tc("definitions.terms.result") }}
      </h3>
      <h2>{{ kpi.name }}</h2>

      <div style="margin-top: 45px">
        <div style="width: 60%; display: inline-block; vertical-align: top; text-align: right;">
          <KpiGraph
              v-if="kpi.kpi_type === 'kpi'"
             :relations="relations"
             :measurements="measurements"
             :kpi="kpi"
             height="450px"
          />

          <KpiEvaluationScore
             v-if="kpi.kpi_type === 'evaluation' && evaluation_type == 'stars'"
             :relations="relations"
             :measurements="measurements"
             :kpi="kpi" />

          <KpiEvaluationSmileys
             v-if="kpi.kpi_type === 'evaluation' && evaluation_type == 'smileys'"
             :relations="relations"
             :measurements="measurements"
             :kpi="kpi" />

          <div style="text-align: center; margin-bottom: 40px; margin-top: 10px;"
               v-if="(kpi.kpi_type == 'evaluation' || noMeasurements)
                     && (kpi.target_min || kpi.target_max)"
               >
               {{ $t('ui.kpi.target') }}:
              <template v-if="kpi.target_min">
                  {{ $t('ui.kpi.target_min') }} {{ $n(kpi.target_min) }}
              </template>
              <template v-if="kpi.target_min && kpi.target_max"> - </template>
              <template v-if="kpi.target_max">
                  {{ $t('ui.kpi.target_max') }} {{ $n(kpi.target_max) }}
              </template>
          </div>


          <template v-if="kpi.child_refs.length == 0 || kpi.agg_type == null">

            <template v-if="canUpdate && kpi.kpi_type === 'kpi'">
              <button class="a3-button action"
                      data-cy="kpi-measurements-button"
                      @click="interactionManager.createKpiMeasurement(kpi_id)">
                  {{ $t('ui.kpi.edit_values') }}  <i class="fas fa-chevron-right"></i>
              </button>
            </template>

            <template v-if="canUpdate && kpi.kpi_type === 'evaluation'">
              <button class="a3-button action"
                      data-cy="kpi-measurements-button"
                      @click="$router.push(`${kpi.id}/stories`)">
                  <i class="fas fa-comments"></i>
                  {{ $t('ui.kpi.stories') }}
              </button>

              <button class="a3-button action"
                      data-cy="kpi-measurements-button"
                      @click="interactionManager.createKpiMeasurement(kpi_id)">
                  <i class="fas fa-star"></i>
                  {{ $t('ui.kpi_stories.judge') }}
              </button>

            </template>

          </template>
          <template v-else>
            <div class="kpi-graph-subtext">
              Waarden voor dit jaarplan worden automatisch gegenereerd,<br>
              op basis van de subjaarplannen.
            </div>
          </template>

          <div style="text-align: left; margin-top: 45px;"
               v-if="kpi.comment_analysis != null
                   || (kpi.kpi_ref && kpi.kpi_ref.comment_analysis != null)"
             >
              <h3 style="margin-bottom: 0px">{{ $t('ui.kpi.comment_analysis') }} </h3>

              <RichTextRender
                  v-if="kpi.comment_analysis"
                  :value="kpi.comment_analysis" />

              <RichTextRender
                  v-if="kpi.kpi_ref && kpi.kpi_ref.comment_analysis"
                  :value="kpi.kpi_ref.comment_analysis" />
          </div>



        </div>
        <div class="information-column" style="width: 30%; display: inline-block; vertical-align: top;">

            <div class="indicators-container">
              <InfoBox left="55px" tkey="kpi_unit" :params="[$t('ui.kpi.unit_text.' + kpi.unit)]" :html="true">
                <template v-slot:display>
                    <div class="unit-indicator">
                      <i class="fas fa-percentage" v-if="kpi.unit == 'perc'"></i>
                      <i class="fas fa-euro-sign" v-if="kpi.unit == 'eur'"></i>
                      <i class="fas fa-hashtag" v-if="kpi.unit == 'number'"></i>
                    </div>
                </template>
              </InfoBox>
              <template v-if="dataManager.hasLayoutOption('steering_check')">
                  <InfoBox left="55px"
                           :html="true"
                      :tkey="kpi.is_steering_kpi ? 'kpi_is_steering' : 'kpi_no_steering'">
                    <template v-slot:display>
                      <div class="unit-indicator" :class="{'inactive': !kpi.is_steering_kpi}">
                        <i class="fas fa-compass"></i>
                      </div>
                    </template>
                  </InfoBox>
              </template>
          </div>


          <h4>{{ $tc('definitions.terms.sbf', 1) }} </h4>
          <template v-if="sbf">
              {{ sbf.name }}
          </template>
          <template v-else>
             <InfoBox
               tkey="sbf_unknown" left="0" top="15">
               <template v-slot:display>
                <i class="fas fa-exclamation" style="margin-right: 5px"></i>
                <em>{{ $t('ui.general.unknown') }} </em>
               </template>
             </InfoBox>
          </template>

          <h4>{{ $t('definitions.terms.result_area') }} </h4>
          {{ result_area.name ?
              result_area.name :
              $t("definitions.result_area." + kpi.result_area) }}

          <template v-if="management_groups && management_groups.length > 0
                          && dataManager.hasLayoutOption('report_groups')
              ">
            <h4>{{ $t('ui.kpi.report_groups') }} </h4>
            <ul v-if="kpi_report_groups && kpi_report_groups.length > 0">
              <li v-for="rg in kpi_report_groups" :key="rg.id">
                  {{ rg.name }}
              </li>
            </ul>
            <template v-if="kpi_report_groups.length == 0">
              <em>Geen gekoppelde rapportage groepen</em>
            </template>
          </template>

          <h4><i class="fas fa-user"></i> {{ $t('ui.action.assigned_to') }} </h4>

          <template v-if="kpi.assigned_to_person">
            <strong>{{kpi.assigned_to_person.name}}</strong><br>
            <small>{{kpi.assigned_to_person.function_name}}</small>
          </template>
          <template v-else>
              <em>{{ $t('ui.action.not_assigned') }} </em>
          </template>

          <template v-if="!publish_key">
            <h4>{{ $t('ui.link.links_and_attachments') }}</h4>

            <LinkElement
               v-for="link in links"
               :key="link.id"
               :link="link"
               :editable="editable"
               @edit="$router.push(`${kpi_id}/link/${link.id}`)"
            />

            <div
               v-if="editable && dataManager.getCapability('kpi.links.create')"
               class="add-button-container"
                 style="margin-top: 0; display: block; font-size: .9em;"
                 @click="$router.push(`${kpi_id}/link/create`)"
              >
              <i class="fas fa-plus"></i> {{ $t('ui.link.add_link') }}
            </div>
          </template>


          <template v-if="kpi.kpi_ref != null && relations">
              <h4>{{ $t('ui.a3_document.pushed_from') }} </h4>
            <p>
              {{ A3NameFromRelations(relations, kpi.a3doc_id) }}
            </p>
          </template>

          <template v-if="relations && relations.children && relations.children.length > 0">
              <h4><i class="fas fa-arrow-right"></i> {{ $t('ui.a3_document.pushed_to') }} </h4>
              <div v-for="c in relations.children"
                   :key="c.department.id"
                   class="push-pill"
                   :class="{'inactive': isPushPillInactive(c)}"
                   @click="() => {
                     if (!isPushPillInactive(c)) {
                      $router.push(`/a3/${c.a3_ref.a3doc_id}/kpi/${kpi_id}`)
                      }
                   }"
                   >
                   {{ c.department.name }}
              </div>
          </template>

        </div>

      </div>

      <div class="overlay-action-button-container" v-if="editable">
        <ButtonActionGroup
            :buttons="[
                  { label: 'ui.general.delete', icon: 'trash', class: 'danger', event: 'delete' },
                  { label: 'ui.action.create', icon: 'plus', class: 'action', event: 'createAction' },
                  { label: 'ui.general.copy_to', icon: 'copy', class: 'action', event: 'copy', feature: 'A3Management'
                  },
                  { label: 'ui.changelog.changelog', icon: 'history', class: 'action', event: 'changelog' },

            ]"
            @copy="interactionManager.copyElements({
                sbf: [kpi.sbf_id],
                kpi: [kpi.id]
            })"
            @createAction="interactionManager.createActionForKpi(kpi_id)"
            @delete="$router.push(`${kpi.id}/delete`)"
            @changelog="$router.push(`${kpi.id}/changelog`)"
        />
        <div class="a3-button action"
             data-cy="kpi-edit-button"
          @click="$router.push(`${kpi.id}/edit`)">
          <i class="fas fa-pencil"></i>
          {{ $t('ui.general.edit') }}
        </div>
      </div>
  </div>
</template>

<script>

import RichTextRender from '@/components/forms/RichTextRender';
import ButtonActionGroup from '@/components/elements/ButtonActionGroup.vue';

import ManagementGroups from '@/mixins/ManagementGroups';
import A3RelationHelper from '@/mixins/A3RelationHelper';
import ResultAreaHelper from '@/mixins/ResultAreaHelper';
import WithDocumentManager from '@/mixins/WithDocumentManager';

import KpiGraph from '@/components/elements/KpiGraph.vue';
import KpiEvaluationScore from '@/components/elements/KpiEvaluationScore.vue';
import KpiEvaluationSmileys from '@/components/elements/KpiEvaluationSmileys.vue';

  export default {
    components: {
      RichTextRender,
      ButtonActionGroup,
      KpiGraph,
      KpiEvaluationScore,
      KpiEvaluationSmileys,
    },

    mixins: [ManagementGroups,
        ResultAreaHelper,
        A3RelationHelper,
        WithDocumentManager],

    props: {
      kpi_id: {
        type: String,
        required: true,
      },

      publish_key: {
        type: String,
        required: false,
        default: null,
      },

      editable: {
        type: Boolean,
        required: false,
        default: false,
      },
      editmode: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data: () => ({
      measurements: null,
      links: null,
    }),

    computed: {

      canUpdate() {
        return this.editmode && (
            this.interactionManager.hasA3Feature('A3Edit')
             || this.interactionManager.hasA3Feature('A3KpiUpdate')
          );
      },

      noMeasurements() {
        if (this.measurements == null) { return true; }

        let measurementcount = this.measurements
          .map(x => x.values.length)
          .reduce((x, y) => x + y, 0);
        return measurementcount == 0;
      },

      kpi() { return this.dataManager.getKpi(this.kpi_id); },
      sbf() {
        return this.dataManager.getSbf(this.kpi.sbf_id)
      },

      result_area() {
          return this.resultAreaFromLayout(
              this.a3_document.meta.layout,
              this.kpi.result_area
          );
      },

      evaluation_type() {
          return this.a3_document.meta.layout.options.kpi_evaluation_type;
      },

      kpi_report_groups() {
        if (this.kpi.management_group_ids == null) return null;

        return this.kpi.management_group_ids
          .map(mg_id => ({id: mg_id, name: this.getManagementGroupName(mg_id)}))
          .filter(x => x.name != null);
      },
    },

    watch: {
      a3_document() {
        this._computedWatchers.kpi.run();
        this.$forceUpdate();

        this.measurements = null;
        this.loadMeasurements();
      },
    },

    mounted() {
      this.loadMeasurements();
      this.loadLinks();
    },

    methods: {
      loadLinks() {
        this.dataManager
          .loadKpiLinks(this.kpi_id)
          .then((links) => this.links = links);
      },

      loadMeasurements() {
        let includeChildren = this.kpi.child_refs.length > 0;

        this.dataManager
          .loadKpiMeasurements( this.kpi_id, includeChildren )
          .then((measurements) => { this.measurements = measurements; })
      },

      getKpiColor() {
          if (this.a3_layout.structure.sbf_result_mapping == "one_to_one") {
              return this.area_colors[this.kpi.result_area];
          }
          if (this.a3_layout.structure.sbf_result_mapping == "one_to_many") {
              if(this.sbf && this.sbf.color) return this.sbf.color;
          }

          return this.area_colors[this.kpi.result_area];
      },

      getManagementGroupName(mg_id) {
        let mg = this.management_groups.find(x => x.id == mg_id);
        if (mg == null) return null;
        return mg.name;
      },

      isPushPillInactive(child) {
        return child.a3_ref == null || this.kpi.child_refs.find((x) => x.a3doc_id == child.a3_ref.a3doc_id) == null;
      }
  },
}
</script>

<style lang="scss">

.push-pill {
  display: inline-block;
  border-radius: 4px;
  color: white;
  margin-right: 5px;
  font-size: 12px;
  padding: 4px 8px;
  font-weight: normal;
  cursor: pointer;
  user-select: none;

  &.inactive {
    cursor: auto;
  }
  margin-bottom: 4px;
}

.kpi-graph-subtext {
  color: $a3-green;
  text-align: left;
  padding-left: 45px;
}
</style>
