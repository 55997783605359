<template>
    <WithMenuContainer>
        <div class="container">
            <h1>Plan kopiëren vanuit periode</h1>

            <div class="element-form">
                <label>Naar periode:</label>
                {{ to_timeframe.name }}
            </div>

            <div class="element-form" style="margin-top: 30px; margin-bottom: 30px;">
              <label>Vanuit periode</label>
              <multiselect
                    id="timeframe-import-select"
                    v-model="selected_timeframe"
                   :options="available_timeframes"
                   :placeholder="$t('ui.timeframe.select_timeframe')"
                   :selectLabel="$t('ui.general.enter_select')"
                   :selectedLabel="$t('ui.general.selected')"
                   :deselectLabel="$t('ui.general.enter_deselect')"
                   :multiple="false"
                   :close-on-select="true"
                   track-by="id"
                   label="name" />
            </div>

            <div class="form-element">
              <CheckBox v-model="request_options.include_subplans" />
              <label>Inclusief subjaarplannen</label>
            </div>

            <div style="margin-top: 30px">
                <button class="a3-button action" @click="triggerRequest">
                    <i class="fas fa-copy"></i> Kopiëren
                </button>
                <button class="a3-button cancel" @click="$router.go(-1)">
                    <i class="fas fa-times"></i> Annuleren
                </button>
            </div>

              <div class="form-error" v-if="error" style="margin-top: 15px">
                <i class="fas fa-exclamation-triangle"></i> Er is een fout opgetreden, probeer
                  opnieuw of neem contact op met uw beheerder.
              </div>

            <LoadingOverlay :loading="loading" />
        </div>
    </WithMenuContainer>
</template>



<script>

import Multiselect from 'vue-multiselect';

export default {

    components: {
        Multiselect,
    },

    data: () => ({
      timeframes: [],
      selected_timeframe: null,
      loading: false,
      error: false,
      request_options: {
          department_id: null,
          to_timeframe_id: null,
          from_timeframe_id: null,
          include_subplans: false,
      },
    }),

    computed: {
        available_timeframes() {
            return this.timeframes
                .filter((x) => x.id != this.request_options.to_timeframe_id);
        },
        to_timeframe() {
            return this.timeframes
                .find(x => x.id == this.request_options.to_timeframe_id);
        },
    },

    mounted() {
        this.loadTimeframes();

        if (this.$route.query.department_id) {
          this.request_options.department_id = parseInt(this.$route.query.department_id);
        }
        this.request_options.to_timeframe_id =
            parseInt( this.$route.query.to_timeframe_id );
    },

    methods: {
        triggerRequest() {
            if (this.selected_timeframe == null) {
                return null;
            }
            this.request_options.from_timeframe_id = this.selected_timeframe.id;
            this.loading = true;
            this.$http.post('a3/copy/from_timeframe', this.request_options)
                .then((res) => {
                    let entry = res.body
                        .find(x => x.department_id == this.request_options.department_id);
                    this.loading = false;
                    this.$router.push(`/a3/${entry.a3.id}`);
                }, (err) => {
                    console.log(this.error);
                    this.loading = false;
                    this.error = true;
                });
        },

        loadTimeframes() {
            this.$http.get('timeframe')
                .then((res) => {
                    this.timeframes = res.body;
                });
        },
    }
}
</script>

<style lang="scss">
#upload-plan-file {
    padding: 15px;
    border: 1px $a3-green solid;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: rgba($a3-green, .1);
    }
}
</style>
