<template>
  <div class="layout-preview-box"
       :class="{'as-button': button, 'inactive': inactive}"
       :style="{'background-color': layout.style.background_color}"
       @click="$emit('click')"
    >
    <h3
       :style="{'color': layout.style.title_color}"
      >{{ layout.name }}</h3>

    <div class="layout-preview-inner"
         :style="{'border-width': `${layout.style.border_width}px`,
          'border-color': `${layout.style.border_color}`,
          'border-style': 'solid'
         }"
      >
      <h4
         :style="{'color': layout.style.header_color}"
          >{{ $t('definitions.terms.mission') }}</h4>
    </div>

    <div v-if="layout.structure.top_widths && layout.structure.bottom_widths"
         :style="{'color': layout.style.title_color, 'padding': '5px 0'}"
      >
      {{ layout.structure.top_widths.join("-") }} /
      {{ layout.structure.bottom_widths.join("-") }}
    </div>
  </div>
</template>

<script>


export default {

  props: {
    layout: {
      type: Object,
      required: true,
    },
    button: {
      type: Boolean,
      required: false,
      default: false,
    },
    inactive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>


<style lang="scss">

.layout-preview-box {
  box-sizing: border-box;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  border: 2px #444 solid;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;

  &.inactive {
    opacity: .4;
  }

  &.as-button {
    transition: transform .15s ease-out, opacity .15s ease-out;
    cursor: pointer;
  }
  &.as-button:hover {
    transform: scale(1.05);
    opacity: 1;
  }

  .layout-preview-inner {
    background-color: white;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
  }

  h3, h4 {
    margin: 0;
  }

  h3 {
    margin-top: 20px;
    font-size: 2em;
  }
}
</style>
