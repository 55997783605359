<template>
    <WithReportMenuContainer :a3doc_id="a3document_id">
        <h1 class="report-title">
            <i class="fas fa-sack-dollar"></i>
            {{ $t('ui.budget.budget') }}</h1>
        <h2 class="report-a3-name">{{ a3_title }}</h2>

        <div>
            {{ $t('ui.budget.split_per') }}:
            <CheckBox v-model="show_details.category" /> {{ $t('ui.budget.cost_place') }}
            <CheckBox v-model="show_details.task" /> {{ $t('ui.budget.task') }}
        </div>
        <div>
            {{ $t('ui.budget.show') }} :
            <CheckBox v-model="show_details.empty_rows" /> {{ $t('ui.budget.zero_budget') }}
            <CheckBox v-model="show_details.hours" /> {{ $t('ui.budget.type.hours') }}
            <CheckBox v-model="show_details.resources" /> {{ $t('ui.budget.type.resources') }}
            <CheckBox v-model="show_details.total" /> {{ $t('ui.budget.total') }}
        </div>

        <div class="budget-row" style="pointer-events: none">
            <div class="budget-title"></div>

            <div class="budget-estimate-column">
                <div class="budget-number-header"
                     v-if="show_details.hours"
                     >{{ $t('ui.budget.type.hours') }} </div>
                <div class="budget-number-header"
                     v-if="show_details.resources"
                     >&euro; {{ $t('ui.budget.type.resources') }} </div>
                <div class="budget-number-header"
                     v-if="show_details.total"
                     >&euro; {{ $t('ui.budget.total') }} </div>
            </div>
            <div class="budget-realisation-column">
            </div>
        </div>
        <BudgetRow v-for="action in budget.action_budgets"
                   :key="action.id"
                   :action="action"
                   :show_details="details_array"
                   :hide_zero="!show_details.empty_rows"
                   :show_hours="show_details.hours"
                   :show_resources="show_details.resources"
                   :show_total="show_details.total"
                   @click="$router.push(`/a3/${a3document_id}/action/${action.id}/budget`)"
                   />
    </WithReportMenuContainer>
</template>

<script>

import WithReportMenuContainer from '@/views/WithReportMenuContainer.vue';


export default {

  components: {WithReportMenuContainer },

    props: {
        a3document_id: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        budget: null,
        show_details: {
          task: true,
          category: false,
          empty_rows: false,
          hours: true,
          resources: true,
          total: true,
        }
    }),

    computed: {
      details_array() {
        let res = [];
        if (this.show_details.category) {
          res.push('budget_category');
        }
        if (this.show_details.task) {
          res.push('task_id');
        }
        return res;
      },
      a3_title() {
          if (this.budget == null) return null;
          let meta = this.budget.a3_meta;

          if (meta.department != null) {
              return `${meta.department.name}, ${meta.timeframe.name}`;
          }

          return `${meta.organisation.name}, ${meta.timeframe.name}`;
      },
    },

    mounted() {
        //this.loadA3Doc();
      this.loadBudget();
    },

    methods: {
        loadA3Doc() {
            this.$http.get(`a3/${this.a3document_id}`)
                .then(res => {
                    this.a3doc = res.body;
                });
        },

        loadBudget() {
          this.$http.get(`budget/a3/${this.a3document_id}`)
            .then(res => {
              this.budget = res.body;
            })
        },
    },
}
</script>
