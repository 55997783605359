
import { A3DocumentInteractionManager } from "./index";

export class DocumentSelectionInteractionManager implements A3DocumentInteractionManager {

  public selection: any;
  public A3Features: string[];

  constructor() {
    this.selection = null;
    this.A3Features = ["A3Read"];
  }

  public setA3Features(A3Features: any) {
    this.A3Features = A3Features;
  }
  public hasA3Feature(key: string) {
    return this.A3Features.includes(key);
  }


  public backToDocument() { this.selection = null; }

  public selectSbf(sbfId: number) { this.selection = { sbf: sbfId }; }
  public createSbf() {}
  public editSbf(sbfId) { }

  // Ignore the context menu in the public version
  public setContextMenu(event, type, params) { }
  public clearContextMenu() {}

  public editTask(actionId, taskId) {}

  public selectKpi(kpiId: number) { this.selection = { kpi: kpiId }; }
  public createKpi() { }
  public editKpi(kpiId) { }

  public selectAction(actionId: number) { this.selection = { action: actionId }; }
  public createAction(orgArea = null) { }
  public editAction(actionId) { }
  public changeActionOrder(orgArea) { }
}
