<template>
    <div class="review-comment" @click="commentClicked"
         :class="{'review-declined': element.status == 'Declined',
           'review-approved': element.status == 'Approved'}"
      >

        <div class="review-header">

          <ReviewElementIcon :element="element" />

          {{ render_element_title }}
        </div>
        <div class="review-author">
          {{ element.person.name }}
        </div>


        <template v-if="element.element_type == 'Comment'">
          <RichTextEditor v-if="edit" editor_mode="minimal" v-model="element.new_value.comment" />
          <RichTextRender v-else :value="element.new_value.comment" />
        </template>

        <div v-if="element.element_type == 'Edit'" class="review-edit-container">

          <template v-if="element.target_type == 'Mission'">
            <div><em>Veranderen naar:</em></div>
            <RichTextRender :value="element.new_value.mission" />
          </template>
          <template v-if="element.target_type == 'Vision'">
            <div><em>Veranderen naar:</em></div>
            <RichTextRender :value="element.new_value.vision" />
          </template>
          <ul v-if="element.target_type == 'Sbf'">
            <template v-for="(value, key) in element.new_value">
              <li v-if="value !== element.sbf[key]" :key="key">
                <span class="field-key">
                  {{ $t(`ui.sbf.${key}`) }}
                </span>
                <br>
                <i class="fas fa-arrow-right"></i>
                <FieldRender
                    :field="`sbf.${key}`"
                    :value="element.new_value[key]"
                    :dataManager="dataManager"
                    />
              </li>
            </template>
          </ul>
          <ul v-if="element.target_type == 'Kpi'">
            <template v-for="(value, key) in element.new_value">
              <li v-if="value !== element.kpi[key]" :key="key">
                <span class="field-key">
                  {{ $t(`ui.kpi.${key}`) }}
                </span>
                <br>
                <i class="fas fa-arrow-right"></i>
                <FieldRender
                   :field="`kpi.${key}`"
                   :value="element.new_value[key]"
                   :dataManager="dataManager"
                />
              </li>
            </template>
          </ul>
          <ul v-if="element.target_type == 'Action'">
            <template v-for="(value, key) in element.new_value">
              <li v-if="value !== element.action[key]" :key="key">
                <span class="field-key">
                  {{ $t(`ui.action.${key}`) }}
                </span>
                  <br>
                <i class="fas fa-arrow-right"></i>
                <FieldRender
                   :field="`action.${key}`"
                   :value="element.new_value[key]"
                   :dataManager="dataManager"
                />
              </li>
            </template>
          </ul>

          <div v-if="element.target_type == 'ActionStatus'">
            <p> Nieuwe status: <strong>{{ element.new_value.percent_done }}%</strong></p>
            <p>{{ element.new_value.comment }}</p>
          </div>
        </div>

        <div v-if="(element.element_type == 'Edit' || element.element_type == 'Create'|| element.element_type == 'Delete') && editable"
          class="button-group small" style="font-size: 12px !important;
          ">
              <button class="a3-button review"
                      :class="{'disabled': isDisabled}"
                      data-cy="review-apply-button"
                  @click.stop="$emit('approve')">
                  <InfoBox tkey="review_apply"
                           left="-10px"
                           top="-65px"
                  >
                <template slot="display">
                    <i class="fas fa-check"></i> {{ $t('ui.review.apply') }}
                </template>
          </InfoBox>
              </button>
          <button class="a3-button review-shy" @click.stop="$emit('decline')">
            <i class="fas fa-trash"></i> {{ $t('ui.general.delete') }}
          </button>
        </div>

        <div v-if="edit && editable && element.element_type == 'Comment'"
             class="button-group"
              style="margin-top: 10px;">
          <button class="a3-button review" data-cy="review-save-button" @click.stop="save">
            <i class="fas fa-save"></i> {{ $t('ui.review.save') }}
          </button>
          <button class="a3-button review"
              @click.stop="$emit('approveComment')">
            <i class="fas fa-check"></i> {{ $t('ui.review.mark_resolved') }}
          </button>
          <button class="a3-button review-shy" @click.stop="$emit('decline')">
            <i class="fas fa-trash"></i> {{ $t('ui.general.delete') }}
          </button>
        </div>


      </div>
</template>

<script>

import RichTextEditor from '@/components/forms/RichTextEditor';
import RichTextRender from '@/components/forms/RichTextRender';

import ReviewElementIcon from '@/components/review/ReviewElementIcon';

import FieldRender from '@/components/elements/FieldRender';

export default {
  components: {
    FieldRender,
    ReviewElementIcon,
    RichTextEditor,
    RichTextRender,
  },

  data: () => ({
    edit: false,
    isNew: true,
  }),

  props: {
    element: {
      type: Object,
      required: true,
    },
    editable: {
        type: Boolean,
        required: false,
        default: true,
    },
    dataManager: {
      type: Object,
      required: true,
    },
  },

  computed: {

    /* Applying an element should be disabled when it
     * is in an state that would not be able to save.
     * e.g. change the sbf_id to an SBF that doesn't exist
     */
    isDisabled() {
      // An KPI cannot be saved if it tries to set
      // the sbf_id to a non-integer value.
      if (this.element.target_type === "Kpi") {
        if (this.element.new_value.sbf_id &&
          !parseInt(this.element.new_value.sbf_id, 10)) {
          return true;
        }
      }

      if (this.element.target_type === "Action") {
        if (
          this.element.new_value.sbf_id &&
          !parseInt(this.element.new_value.sbf_id, 10)) {
          return true;
        }
        if (
          this.element.new_value.kpi_id &&
          !parseInt(this.element.new_value.kpi_id, 10)) {
          return true;
        }
      }
      return false;
    },

    render_element_title() {
      if (this.element.target_type == "Mission") {
        return this.$t('definitions.terms.mission');
      }
      if (this.element.target_type == "Vision") {
        return this.$t('definitions.terms.vision');
      }

      if (this.element.target_type == "Sbf"
        || this.element.target_type == "Kpi"
        || this.element.target_type == "Action"
      ) {
        if (this.element.element_type == 'Create') {
          return this.element.new_value.name;
        }
        if (this.element.element_type == 'Edit' || this.element.element_type == 'Comment') {
          if (this.element.sbf) { return this.element.sbf.name; }
          if (this.element.kpi) { return this.element.kpi.name; }
          if (this.element.action) { return this.element.action.name; }
          return this.element.new_value.name;
        }
        if (this.element.element_type == 'Delete') {
          return this.element.old_value.name;
        }
      }

      return '-';
    },
  },

  mounted() {
    if (this.element.element_type == 'Comment') {
      if (!this.element.new_value.comment && this.editable) {
        this.edit = true;
      }
    }
  },

  methods: {
    save() {
      this.$emit('update');
      this.edit = false;
      this.isNew = false;
    },
    commentClicked() {
      if (!this.edit && this.editable) this.edit = true;
    },
  },
};
</script>


<style lang="scss">

.a3-button.review-shy {
  background-color: $review-orange;
  border: none;
  color: lighten($review-orange, 50%);
  padding: 8px 13px;

  &:hover, &.active {
    background-color: lighten($general-green, 40%);
    color: darken($general-green, 20%);
  }
}

.a3-button {
  &.review {
    padding: 8px 13px;
    background-color: darken($general-green, 20%);
    border: 2px darken($general-green, 20%) solid;
    color: white;

    &:hover, &.active {
      background-color: lighten($general-green, 40%);
      color: darken($general-green, 20%);
    }
  }
}

.review-edit-container {
    ul {
        padding-inline-start: 20px;
    }
}

.review-comment {
  position: relative;
  z-index: 2;

  &.review-approved {
    animation: approveOut .5s ease-out;
    animation-fill-mode: forwards;
  }
  &.review-declined {
    animation: declineOut .5s ease-out;
    animation-fill-mode: forwards;
    z-index: 1;
  }


  .fa-arrow-right {
    margin-right: 3px;
    opacity: .4;
  }
  .field-key {
    opacity: .4;
    font-weight: bold;
  }
}

@keyframes approveOut {
  30% {
    background-color: green;
    border-color: darken($general-green, 20%);
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.1) translateX(100%);
  }
}

@keyframes declineOut {
  30% {
    opacity: .9;
    background-color: red;
    transform: scale(0.8);
  }
  100% {
    opacity: 0;
    transform: scale(0.8) translateY(200px);
  }
}
</style>
