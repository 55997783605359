<template>
    <div class="element-form">
      <div class="form-row">
        <div class="form-field">
          <label>{{ $t('ui.general.name') }}</label>
          <input v-model="department.name" class="a3-input" data-cy="input-name" />
        </div>
      </div>


      <div class="form-row" v-if="people != null">
        <div class="form-field">
          <label>{{ $t('ui.organisation.employees') }}</label>
          <table class="a3-table">
            <thead>
              <tr>
                <th>{{ $t('ui.general.name') }} </th>
                <th>{{ $t('ui.accounts.mail' ) }}</th>
                <th>{{ $t('ui.user_type.user_type') }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="p in people"
                  @click="editPerson(p)"
                >
                <td>{{ p.name }}</td>
                <td>{{ p.account ? p.account.mail : '' }}</td>
                <td>{{ p.user_type ? p.user_type.name : '' }}</td>
              </tr>
              <tr v-if="people && people.length == 0">
                <td colspan="3">
                  {{ $t('ui.organisation.no_employees') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="form-buttons">
          <button class="a3-button action" @click="$emit('submit')">
            <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
          </button>

          <template v-if="department.id">
            <button
                    v-if="people == null || people.length == 0"
              class="a3-button danger" @click="$emit('delete')">
              <i class="fas fa-trash"></i> {{ $t('ui.general.delete') }}
            </button>
            <InfoBox tkey="department.delete_with_people"
                     top="40" left="0"
            v-else>
              <template v-slot:display>
                <button disabled class="a3-button disabled danger">
                  <i class="fas fa-trash"></i> {{ $t('ui.general.delete') }}
                </button>
              </template>
            </InfoBox>
          </template>

          <button class="a3-button cancel" @click="$emit('cancel')">
            {{ $t('ui.general.cancel') }}
          </button>
      </div>
    </div>
</template>

<script>

export default {
  props: {
    department: {
      type: Object,
      required: true,
    },
    people: {
      type: Array,
      required: true,
    }
  },

  methods: {
    editPerson(p) {
      if (this.$hasFeature('OrgEditPeople')) {
        this.$router.push(`/organisation/people/${p.id}`);
      }
    },
  },
}
</script>
