var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-preview"},[_c('h2',[_vm._v(_vm._s(_vm.$t('ui.layout.custom_grid')))]),_c('div',{staticClass:"grid-inner",class:{'blur': _vm.selected_area != null},staticStyle:{"display":"grid","grid-column-gap":"20px","grid-row-gap":"20px"},style:({'grid-template-columns': _vm.body_layout_columns})},[_c('div',{staticClass:"grid-add-button",style:({
                'grid-column': `1 / span ${Math.ceil(_vm.structure.grid.columns / 2)}`,
                'grid-row': `${_vm.maxRow + 1} / span 1`,
             }),on:{"click":_vm.addOrgArea}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('ui.layout.add_org_area'))+" ")]),_c('div',{staticClass:"grid-add-button",style:({
                      'grid-column': `${Math.ceil(_vm.structure.grid.columns / 2) + 1} / span ${Math.floor(_vm.structure.grid.columns / 2)}`,
                      'grid-row': `${_vm.maxRow + 1 } / span 1`,
             }),on:{"click":_vm.addResultArea}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('ui.layout.add_result_area'))+" ")]),_vm._l((_vm.structure.result_areas),function(result_area,index){return _c('div',{staticClass:"grid-area-preview",style:({
                'grid-column': `${result_area.position.x} / span ${result_area.position.xspan}`,
                'grid-row': `${result_area.position.y} / span ${result_area.position.yspan}`,
                '--primary-color': `${result_area.color}`
             }),on:{"click":function($event){return _vm.selectResultAreaByIndex(index)}}},[_c('i',{staticClass:"fas",class:`fa-${result_area.icon}`}),_vm._v(" "+_vm._s(result_area.index)+". "+_vm._s(result_area.name ? result_area.name : _vm.$t(`definitions.result_area.${result_area.key}`))+" "),_c('div',{staticClass:"area-indicator"},[_c('i',{staticClass:"fas fa-analytics"}),_vm._v(" "+_vm._s(_vm.$t('definitions.terms.result_area'))+" ")])])}),_vm._l((_vm.structure.org_areas),function(org_area,index){return _c('div',{staticClass:"grid-area-preview",style:({
                'grid-column': `${org_area.position.x} / span ${org_area.position.xspan}`,
                'grid-row': `${org_area.position.y} / span ${org_area.position.yspan}`,
                '--primary-color': '#007385'
             }),on:{"click":function($event){return _vm.selectOrgAreaByIndex(index)}}},[_c('i',{staticClass:"fas",class:`fa-${org_area.icon}`}),_vm._v(" "+_vm._s(org_area.index)+". "+_vm._s(org_area.name ? org_area.name : _vm.$t(`definitions.org_area.${org_area.key}`))+" "),_c('div',{staticClass:"area-indicator"},[_c('i',{staticClass:"fas fa-tasks"}),_vm._v(" "+_vm._s(_vm.$t('definitions.terms.org_area'))+" ")])])})],2),(_vm.selected_area != null)?_c('div',{staticClass:"grid-edit-overlay"},[_c('div',{staticStyle:{"text-align":"right"}},[_c('i',{staticClass:"fas fa-times close-btn",on:{"click":_vm.clearSelection}})]),(_vm.selected_area_type == 'result_area')?_c('CustomResultAreaForm',{attrs:{"value":_vm.selected_area,"area_keys":_vm.area_keys},on:{"submit":(v) => _vm.submittedResultArea(v),"delete":function($event){return _vm.removeResultAreaAt(_vm.selected_area_index)},"close":_vm.clearSelection}}):_vm._e(),(_vm.selected_area_type == 'org_area')?_c('CustomOrgAreaForm',{attrs:{"value":_vm.selected_area,"area_keys":_vm.area_keys},on:{"submit":(v) => _vm.submittedOrgArea(v),"delete":function($event){return _vm.removeOrgAreaAt(_vm.selected_area_index)},"close":_vm.clearSelection}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }