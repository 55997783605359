<template>
  <div id="a3-org-menu-container">
    <span class="menu-title-container">
      <strong style="color: white; padding-right: 30px;">{{ $t('ui.nav.my_organisation') }}:</strong>
    </span>
    <div
      v-for="(l, link_index) in links"
      :key="'main-menu-link-' + link_index"
      class="a3-tab"
      :class="{'active': $route.path.startsWith(l.active_slug)}"
    >
      <div
        :id="'menu-bar-' + l.id"
        @click="$router.push(l.url)"
      >
        <i style="margin-right: 5px" class="fas" :class="'fa-' + l.icon" />
            {{ $t(l.name) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'MainTabMenu',
    data: () => ({
        links: [
            {
                url: '/organisation',
                name: 'ui.nav.departments',
                id: 'overview',
                icon: 'sitemap',
                active_slug: '/organisation',
            },
            {
                url: '/organisation/teams',
                name: 'ui.nav.report_groups',
                id: 'my-teams',
                icon: 'users',
                active_slug: '/organisation/teams',
            },
            {
                url: '/organisation/people',
                name: 'ui.nav.users',
                id: 'my-users',
                icon: 'user',
                active_slug: '/organisation/people',
            },
        ],
    }),

    mounted() {
      if (this.$hasFeature('A3Layouts')) {
        this.links.push(
          {
            url: '/organisation/layout',
            name: 'ui.nav.layouts',
            id: 'my-layouts',
            icon: 'palette',
            active_slug: '/organisation/layout',
          }
        );
      }
    },
};
</script>

<style lang="scss">
@import "../../assets/variables.scss";

#a3-org-menu-container {
    background-color: $a3-green;
    border-bottom: 1px $green-darkened solid;
    user-select: none;
    -webkit-box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.75);

    z-index: 16000;

    .menu-title-container {
      padding-left: 140px;
    }
}

@media screen and (max-width: 980px) {
  #a3-org-menu-container .a3-tab {
    display: block !important;
    width: 100%;
    padding-left: 0;

  }
  #a3-org-menu-container{
    .menu-title-container {
      padding-left: 0;
      padding: 10px;
      text-align: center;
      width: 100%;
      display: block;
    }
  }
}

</style>
