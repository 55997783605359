<template>
    <div>

        <h2>{{ $t('ui.user_settings.notifications') }}</h2>

        <div class="notification-grid">
            <div></div>
            <div>
                <i class="fas fa-bell"></i> {{ $t('ui.notifications.notify') }}
            </div>
            <div v-if="false">
                <i class="fas fa-envelope"></i> {{ $t('ui.notifications.send_mail') }}
            </div>
            <div v-if="false">
                <i class="fas fa-file-alt"></i> {{ $t('ui.notifications.include_in_summary') }}
            </div>

            <template v-for="n in notification_keys">
                <div>
                    {{ $t(`ui.notifications.${n}`) }}
                </div>
                <div v-if="settings[`.notifications.${n}.notify`]">
                    <i class="fas fa-bell"></i>
                    <CheckBox v-model="settings[`.notifications.${n}.notify`].value"
                              @change="saveSetting(`.notifications.${n}.notify`, $event ? 1 : 0)"
                    />
                </div>
                <!-- hide for now -->
                <div v-if="false">
                    <i class="fas fa-envelope"></i>
                    <CheckBox v-model="settings[`.notifications.${n}.send_mail`].value"
                              @change="saveSetting(`.notifications.${n}.send_mail`, $event ? 1 : 0)"
                    />
                </div>
                <!-- hide for now -->
                <div v-if="false">
                    <i class="fas fa-file-alt"></i>
                    <CheckBox v-model="settings[`.notifications.${n}.in_summary`].value"
                              @change="saveSetting(`.notifications.${n}.in_summary`, $event ? 1 : 0)"
                    />
                </div>
            </template>
        </div>
    </div>
</template>


<script>


export default {

    data: () => ({
        settings: {},
        notification_keys: [
            'action_assigned',
            'action_deadline_expired',
            // 'action_deadline_coming_close',
        ],
    }),

    mounted() {
        this.loadSettings();
    },

    methods: {
        loadSettings() {
            this.$http.get('me/settings').then((res) => {
                this.settings = res.body.settings;

                for (let key in this.settings) {
                    if (this.settings[key].setting_type == 'Boolean') {
                        this.settings[key].value = this.settings[key].value > 0;
                    }
                }
            })
        },

        saveSetting(key, value) {
            this.$http.put('me/settings', { key, value })
                .then((res) => {
                    this.settings[key].value = value > 0;
            })
        }
    }
}
</script>

<style lang="scss">

.notification-grid {
    display: grid;
    grid-template-columns: 300px 100px; /*  100px 150px; */
    grid-row-gap: 20px;
    grid-column-gap: 20px;

    i {
        color: $a3-green;
        font-size: 1.4em;
        margin-right: 5px;
        opacity: .3;
    }
}
</style>
