<template>
    <div class="element-form" v-if="timeframe != null">

      <div class="form-row">
        <div class="form-field">
            <label>{{ $t('ui.general.name') }} </label>
          <input v-model="timeframe.name" class="a3-input" id="timeframe-name-input"
                                                           data-cy="input-name"
          />
            <FormError :error="errors.name" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-field">
            <label>{{ $t('ui.timeframe.start_date') }} </label>
            <DateInput v-model="timeframe.start_date" data-cy="input-start-date" />
            <FormError :error="errors.start_date" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-field">
            <label>{{ $t('ui.timeframe.end_date') }} </label>
            <DateInput v-model="timeframe.end_date" data-cy="input-start-date" />
            <FormError :error="errors.end_date" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-field">
            <label>{{ $t('ui.timeframe.pin_on_top') }} </label>
            <CheckBox v-model="timeframe.pin_on_top" />

            <FormError :error="errors.pin_on_top" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-field">
            <label>{{ $t('ui.timeframe.closed') }}
                <InfoBox :tkey="'timeframe.closed'" />
            </label>
            <CheckBox v-model="timeframe.closed" />

            <FormError :error="errors.closed" />
        </div>
      </div>

      <div class="form-buttons">
          <button class="a3-button action" @click="submit">
              <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
          </button>

          <template v-if="timeframe.id">
            <button class="a3-button danger" @click="$emit('delete')">
                <i class="fas fa-trash"></i> {{ $t('ui.general.delete') }}
            </button>
          </template>

          <button class="a3-button cancel" @click="$emit('cancel')">
              <i class="fas fa-times"></i> {{ $t('ui.general.cancel') }}
          </button>
      </div>
    </div>
</template>

<script>

export default {
  props: {
    timeframe: {
      type: Object,
      required: true,
    },
  },

    data: () => ({
      errors: {
        name: [],
        start_date: [],
        end_date: [],
      },
    }),

  mounted() {
    document.querySelector('#timeframe-name-input')
      .focus();
  },

  methods: {
    validate() {
      if (this.timeframe.name == null || this.timeframe.name.length == 0) this.errors.name.push("may_not_be_null");

      if (this.timeframe.start_date == "") {
        this.timeframe.start_date = null;
      }
      if (this.timeframe.end_date == "") {
        this.timeframe.end_date = null;
      }

      return Object.values(this.errors)
        .flat()
        .length == 0;
    },

    submit() {
      // reset the errors
      for (let x of Object.keys(this.errors)) {
        this.errors[x] = [];
      }

      if (this.validate()) {
        this.$emit('submit');
      }
    },
  },
}
</script>
