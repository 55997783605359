<template>
  <WithOrganisationMenuContainer>
    <div class="container">
      <h1>{{ organisation.name }}: {{ $t('ui.nav.departments') }} </h1>

      <p>
          {{ $t('ui.nav.departments') }}
      </p>

      <div style="margin-left: -35px">
        <DepartmentListElement
          v-for="department in departments"
          :key="`department-element-${department.id}`"
          :department="department"
          :can_edit="editable"
          />
      </div>
      <div v-if="departments.length == 0">
        <em>{{ $t('ui.organisation.no_departments', [organisation.name]) }}</em>
      </div>

      <div style="margin-top: 20px" v-if="editable">
        <input class="a3-input" v-model="new_department.name" id="root-new-department-input"
                                                              @keyup.enter="addDepartment"
        />

        <button class="a3-button action" @click="addDepartment">
          <i class="fas fa-plus"></i> {{ $t('ui.organisation.add_department') }}
        </button>
      </div>
    </div>
  </WithOrganisationMenuContainer>
</template>

<script>

export default {

  data: () => ({
    departments: [],
    new_department: {
      name: null,
    },
    editable: false,
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    },
  },

  mounted() {
    this.loadDepartments();

    if (this.$hasFeature('OrgEditDepartments')) {
      this.editable = true;
    }
  },

  methods: {
    loadDepartments() {
      this.$http.get(`organisation/department`)
        .then(res =>{
          if (res.body != null) {
            this.departments = res.body;
          }
        })
    },

    addDepartment() {
      this.$http.post(`organisation/department`, this.new_department)
        .then(res => {
          let department = res.body;
          if (!department.children) department.children = [];
          this.departments.push(department)

          this.new_department.name = null;
          document.getElementById('root-new-department-input').focus();
        });
    }
  },
}
</script>
