import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueI18n from "vue-i18n";

import "./assets/style.scss";

// Import '../vendor/fontawesome5/css/solid.css';
import "@/assets/fontawesome.css"; // Needed because of html2canvas
import "../vendor/fontawesome5/css/regular.min.css";

import "@/assets/tabulator_modern.css";
import "@simonwep/pickr/dist/themes/nano.min.css";
import "flatpickr/dist/flatpickr.min.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue-tour/dist/vue-tour.css";
import "../vendor/fontawesome5/css/fontawesome.css";

(window as any).XLSX = require('xlsx');


import VueResource from "vue-resource";
import VueTour from "vue-tour";


import WithMenuContainer from "@/views/WithMenuContainer.vue";
import WithOrganisationMenuContainer from "@/views/WithOrganisationMenuContainer.vue";

import BudgetRow from "@/components/budget/BudgetRow.vue";
import DepartmentListELement from "@/components/organisation/DepartmentListElement.vue";

import ConfirmationOverlay from "@/components/elements/ConfirmationOverlay.vue";
import ErrorOverlay from "@/components/elements/ErrorOverlay.vue";
import InfoBox from "@/components/elements/InfoBox.vue";
import InlineErrorBox from "@/components/elements/InlineErrorBox.vue";
import LinkElement from "@/components/elements/LinkElement.vue";
import LoadingOverlay from "@/components/elements/LoadingOverlay.vue";
import CheckBox from "@/components/forms/CheckBox.vue";
import DateInput from "@/components/forms/DateInput.vue";
import FormError from "@/components/forms/FormError.vue";
import A3SubLevelContainer from "@/components/organisation/A3SubLevelContainer.vue";

Vue.component("WithMenuContainer", WithMenuContainer);
Vue.component("WithOrganisationMenuContainer", WithOrganisationMenuContainer);
// Necessary because it's a recursive component
Vue.component("DepartmentListElement", DepartmentListELement);
Vue.component("BudgetRow", BudgetRow);

Vue.component("A3SubLevelContainer", A3SubLevelContainer);
Vue.component("CheckBox", CheckBox);
Vue.component("FormError", FormError);
Vue.component("LoadingOverlay", LoadingOverlay);
Vue.component("InfoBox", InfoBox);
Vue.component("InlineErrorBox", InlineErrorBox);
Vue.component("ConfirmationOverlay", ConfirmationOverlay);
Vue.component("ErrorOverlay", ErrorOverlay);
Vue.component("LinkElement", LinkElement);
Vue.component("DateInput", DateInput);

Vue.use(VueResource);
Vue.use(VueI18n);
Vue.use(VueTour);

Vue.prototype.$UiEventBus = new Vue();

import A3Features from "@/plugins/A3Features.js";
Vue.use(A3Features, { store });


const appSettingsRequest = new XMLHttpRequest();
appSettingsRequest.open("GET", "/app_settings.json", false);
appSettingsRequest.send(null);
const appSettings = JSON.parse(appSettingsRequest.responseText);


const nlMessages = require("@/locale/nl.json");
const enMessages = require("@/locale/en.json");

const messages = {
  "nl-NL": nlMessages,
  "en-US": enMessages,
};

const i18n = new VueI18n({
  locale: appSettings.locale,
  messages,
  dateTimeFormats: {
        "nl-NL": {
            short: { year: "numeric", month: "short", day: "numeric" },
            long: { year: "numeric", month: "long", day: "numeric" },
            datetime: { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" },
            noyear: { month: "long", day: "numeric"},
        },
        "en-US": {
            short: { year: "numeric", month: "short", day: "numeric" },
            long: { year: "numeric", month: "long", day: "numeric" },
            noyear: { month: "long", day: "numeric"},
            datetime: { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" },
        },
    },
});

(Vue as any).http.options.root = appSettings.api_url;

(Vue as any).http.headers.common["Content-Type"] = "application/json; charset='utf-8'";

Vue.config.productionTip = false;

const token = window.localStorage.getItem("auth_token");
store.commit("auth/setToken", token);

const key = "Authorization";
(Vue as any).http.headers.common[key] = `Bearer ${token}`;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
