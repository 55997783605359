<template>
  <div class="element-form">
    <h1>{{ $t('ui.mission.edit') }}</h1>

    <div style="margin: 30px 0" id="mission-edit-container">
      <RichTextEditor v-model="mission" />
    </div>

    <button class="a3-button action"
            data-cy="submit-button"
            id="mission-save-button"
          @click="save">
      <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
    </button>
  </div>
</template>

<script>

import RichTextEditor from '@/components/forms/RichTextEditor.vue';
// import { UiEventBus } from '@/services/eventbus.js';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  mixins: [ WithDocumentManager ],
  components: {
    RichTextEditor,
  },

  data: () => ({
    mission: "",
    editor: null,
  }),

  watch: {
    mission(value) {
      if (value != null && value.length > 0) {
         this.$UiEventBus.$emit('edited', {key: 'mission-edit'});
      }
    }
  },

  mounted() {
    this.mission = this.a3_document.mission;
    this.$UiEventBus.$emit('shown', {key: 'mission-edit'});
  },

  methods: {
    save() {
      this.dataManager
        .saveMission(this.mission)
        .then(() => {
          this.$UiEventBus.$emit('submit', { key: 'mission-edit' });
          this.interactionManager.backToDocument();
        })
    }
  },
};
</script>


<style lang="scss">

.a3-mv-textarea {
  width: 100%;
  min-height: 350px;
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: 'Rubik';
  font-size: 16px;
}
</style>
