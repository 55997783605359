<template>
    <div class="element-form"
      style="display: inline-block; width: 1000px; vertical-align: top; padding-left: 15px;">

      <div class="form-row">
        <h3>{{ $t('definitions.terms.direction') }}</h3>
        <div class="form-field">
          <label>{{ $t('definitions.terms.mission') }} </label>
          <RichTextEditor v-model="descriptions.mission"
                 style="display: inline-block; width: 700px"
                 id="layout-name-input"
                 data-cy="input-name"
          />
            <FormError :error="errors.descriptions.mission" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.terms.vision') }} </label>
          <div class="form-input">
            <RichTextEditor v-model="descriptions.vision"
                   style="display: inline-block; width: 700px"
                   id="layout-vision-input"
            />
          </div>
            <FormError :error="errors.descriptions.vision" />
        </div>

        <div class="form-field">
          <label>{{ $tc('definitions.terms.sbf') }} </label>
          <RichTextEditor v-model="descriptions.sbf"
                 style="display: inline-block; width: 700px"
                 id="layout-name-input"
                 data-cy="input-name"
          />
            <FormError :error="errors.descriptions.sbf" />
        </div>
      </div>

      <div class="form-row" v-if="!custom_areas">
        <h3>{{ $t('definitions.terms.result_area') }}</h3>
        <div class="form-field">
          <label>{{ $t('definitions.result_area.customers_and_partners') }} </label>
          <RichTextEditor v-model="descriptions.customers_and_partners"
                 style="display: inline-block; width: 700px"
          />
            <FormError :error="errors.descriptions.employees" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.result_area.employees') }} </label>
          <RichTextEditor v-model="descriptions.employees"
                 style="display: inline-block; width: 700px"
          />
            <FormError :error="errors.descriptions.employees" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.result_area.society') }} </label>
          <RichTextEditor v-model="descriptions.society"
                 style="display: inline-block; width: 700px"
          />
            <FormError :error="errors.descriptions.society" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.result_area.board_and_financers') }} </label>
          <RichTextEditor v-model="descriptions.board_and_financers"
                 style="display: inline-block; width: 700px"
          />
            <FormError :error="errors.descriptions.board_and_financers" />
        </div>
      </div>

      <div class="form-row" v-if="!custom_areas">
        <h3>{{ $t('definitions.terms.org_area') }}</h3>
        <div class="form-field">
          <label>{{ $t('definitions.org_area.leadership') }} </label>
          <RichTextEditor v-model="descriptions.leadership"
                 style="display: inline-block; width: 700px"
          />
            <FormError :error="errors.descriptions.leadership" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.org_area.strategy_and_policy') }} </label>
          <RichTextEditor v-model="descriptions.strategy_and_policy"
                 style="display: inline-block; width: 700px"
          />
            <FormError :error="errors.descriptions.leadership" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.org_area.management_employees') }} </label>
          <RichTextEditor v-model="descriptions.management_employees"
                 style="display: inline-block; width: 700px"
          />
            <FormError :error="errors.descriptions.management_employees" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.org_area.management_processes') }} </label>
          <RichTextEditor v-model="descriptions.management_processes"
                 style="display: inline-block; width: 700px"
          />
            <FormError :error="errors.descriptions.management_processes" />
        </div>

        <div class="form-field">
          <label>{{ $t('definitions.org_area.management_resources') }} </label>
          <RichTextEditor v-model="descriptions.management_resources"
                 style="display: inline-block; width: 700px"
          />
            <FormError :error="errors.descriptions.management_resources" />
        </div>
      </div>

      <div class="form-row" v-if="custom_areas">
        <div class="form-field">
          <label></label>
          <div class="form-annotation-div">
            {{ $t('ui.layout.change_descriptions_in_structure') }}
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import RichTextEditor from '@/components/forms/RichTextEditor.vue';

export default {
  components: {
    RichTextEditor,
  },

  props: {
    descriptions: {
      type: Object,
      required: true,
    },
    layout: {
        type: Object,
        required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },

  computed: {
    custom_areas() {
      return this.layout.structure.custom_areas;
    }
  },
}
</script>
