<template>
  <div class="form-error" v-if="error.length > 0">
    <div v-for="(err_type, index) in error" :key="index">
      <i class="fas fa-exclamation-triangle"></i> {{ $t(`forms.errors.${err_type}`) }}
    </div>
  </div>
</template>

<script>

export default {

  props: {
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
}
</script>
