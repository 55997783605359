<template>
  <WithOrganisationMenuContainer>
    <div class="container">
      <h1>{{ organisation.name }}: {{ $t('ui.nav.departments') }} </h1>

      <div style="margin-left: -35px">
          <table class="a3-table">
              <thead>
                  <tr>
                      <th>Id</th>
                      <th>Naam</th>
                      <th>Bovenliggend</th>
                  </tr>
              </thead>

              <tbody>
                  <tr v-for="d, id in departments" :key="id"
                      @click="$router.push(`/organisation/department/${id}/edit`)"
                      >
                      <td>{{ id }}</td>
                      <td>{{ d.name }}</td>
                      <td>
                          <template v-if="d.parent_department_id == null">
                              -
                          </template>
                          <template v-else>
                              <template v-if="d.parent_department_id">
                                  {{ departments[d.parent_department_id] }}
                              </template>
                              <template>
                                  <i class="fas fa-exclamation-triangle"></i> Verwijderde afdeling
                              </template>
                          </template>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
      <div v-if="departments.length == 0">
        <em>{{ $t('ui.organisation.no_departments', [organisation.name]) }}</em>
      </div>
    </div>
  </WithOrganisationMenuContainer>
</template>

<script>

export default {

  data: () => ({
    departments: [],
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    },
  },

  mounted() {
    this.loadDepartments();
  },

  methods: {
    loadDepartments() {
      this.$http.get(`organisation/department/list`)
        .then(res =>{
            let department_list = res.body;

            this.departments = {};
            for (let d of department_list) {
                this.departments[d.id] = d;
            }
        })
    },
  },
}
</script>
