<template>
  <WithMenuContainer>
        <div class="tab-container">
          <div class="year-tab"
               @click="$router.push('/support/manual')"
               :class="{ selected: $route.matched.some(
                         ({name}) => name === 'support-manual'
               )}"
              >
              <i class="fas fa-book"></i> {{ $t('ui.support.support') }}
          </div>
          <div class="year-tab"
               @click="$router.push('/support/advisors')"
               :class=" { selected: $route.matched.some(
                      ({ name}) => name === 'support-advisors'
              )}"
              >
              <i class="fas fa-user-chart"></i> {{ $t('ui.support.my_advisors') }}
          </div>
        </div>

        <div class="plan-container">
          <router-view></router-view>
        </div>
  </WithMenuContainer>
</template>

<script>

export default {
}

</script>
