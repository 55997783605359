<template>
  <div class="a3-login-container">
    <div class="background-image"></div>
    <div class="login-inner">
      <h1 style="float: left">
        {{ $t('ui.login.password_reset') }}
      </h1>

      <div style="text-align: right">
        <img src="@/public/img/a3_online_logo.png" class="login-logo"/>
      </div>

      <div class="element-form" v-if="!completed">
        <div class="login-form-field">
          <label><i class="fas fa-envelope"></i> 
            {{ $t('ui.login.mail')}}
          </label>
          <input v-model="mail"
            class="a3-input" type="email" placeholder="naam@organisatie.nl"/>
        </div>

        <InlineErrorBox v-if="error_key != null" :error_key="error_key" />

        <div class="form-buttons">
          <button type="submit" class="login-button" @click="sendRequest">
            {{ $t('ui.login.request_password_reset') }} <i class="fas fa-key"></i>
          </button>
        </div>
      </div>
      <div style="margin-top: 30px" v-else>
        {{ $t('ui.login.mail_sent', [mail]) }}
      </div>

      <LoadingOverlay :loading="loading" />
    </div>
  </div>
</template>

<script>

import InlineErrorBox from '@/components/elements/InlineErrorBox.vue';

export default {
  components: {
    InlineErrorBox,
  },

  data: () => ({
    mail: '',
    loading: false,
    error_key: null,
    completed: false,
  }),

  methods: {
    sendRequest() {
      this.error_key = null;
      this.loading = true;
      this.$http.post('request_password_reset', { mail: this.mail })
        .then((res) => {
          this.loading = false;
          this.completed = true;
        }, (err) => {
          this.loading = false;
          if (err.status === 0) { 
            this.error_key = 'general.not_available'; 
          } else if (err.status === 500) { 
            this.error_key = 'general.server_error'; 
          } else { 
            this.error_key = 'general.generic'; 
          }
        });
    },
  },
};
</script>


<style lang="scss"></style>
