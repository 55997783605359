<template>
  <div class="action-view-overlay">
      <h3 class="overlay-type-header">
        {{ $t('ui.sbf.delete') }}
      </h3>
      <h2>{{ sbf.name }}</h2>

      <p v-html="$t('ui.sbf.delete_confirmation')"></p>

      <button class="a3-button danger" @click="deleteSbf">
        <i class="fas fa-times"></i> {{ $t('ui.general.confirm_delete') }}
      </button>
      <button class="a3-button cancel" @click="interactionManager.selectSbf(sbf_id)">
          <i class="fas fa-chevron-left"></i> {{ $t('ui.general.cancel') }}
      </button>

  </div>
</template>

<script>

import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  mixins: [WithDocumentManager],
  props: {
    sbf_id: {
      type: String,
      required: true,
    },
  },

  computed: {
    sbf() {
      return this.dataManager.getSbf(this.sbf_id);
    },
  },

  methods: {
      deleteSbf() {
        this.dataManager
          .deleteSbf(this.sbf_id)
          .then(() => this.interactionManager.backToDocument());
      }
  },
};
</script>

<style lang="scss">
.action-status-table {
  width: 100%;

  cell-border-spacing: 0;
  border-collapse: collapse;

  text-align: left;

  td {
    width: 50%;
    padding: 5px 0px;
  }
  tbody tr {
    transition: background-color .15s ease-out, color .15s ease-out;

    &:hover {
      background-color: $a3-green;
      color: white;
      cursor: pointer;
    }
  }

  tr.completed {
    opacity: .5;
    text-decoration: line-through;
  }

  thead {
    th {
      border-bottom: 1px $a3-green solid;
      padding: 5px 0px;
    }
  }
}

.action-view-overlay {
  h3 {
    color: rgba($a3-green, .6);
    margin-top: 35px;
    font-size: 24px;
  }
}
</style>
