<template>
  <div
      :class="[`result-area-${result_area}`]"
      >
     <div v-if="icon != null"
          class="kpi-list-icon"
          :style="{'color': area_colors[result_area]}"
         >
         <i class="fas" :class="`fa-${icon}`"></i>
     </div>

     <div
      @click.exact="interactionManager.selectKpi(kpi.id)"
      @click.shift="$emit('focus', kpi)"
      @contextmenu.exact.prevent="interactionManager.setContextMenu(
                               $event,
                               'kpi',
                               { kpi }
      )"
      v-for="(kpi, index) in kpis"
      :key="'kpi-' + index"
      class="kpi-item-element"
      :class="[
          resultAreaToClass(kpi.result_area),
          outOfFocus(kpi) ? 'out-of-focus' : ''
      ]"
      :style="{'--main-item-color': getKpiColor(kpi)}"
      >
      <KpiListElement
        @comment="dataManager.commentKpi(kpi.id)"
        @mouseover="$emit('el_mouseover', kpi)"
        @mouseleave="$emit('el_mouseleave', kpi)"
        :kpi="kpi"
        :dataManager="dataManager"
        :interactionManager="interactionManager"
        :editable="editable"
        :show_numbers="displayOptions.action_pi_numbers"
        :show_trail="displayOptions.show_trail"
        :show_status="displayOptions.pi_status"
        :show_target="displayOptions.pi_target"
        :show_current_value="displayOptions.pi_current_value"
        :show_plan_source="displayOptions.show_plan_source"
        :show_pushed_to="displayOptions.show_pushed_to"
      />
    </div>

    <div
      v-if="editable"
      tabindex="0"
      class="add-button-container add-kpi-button"
      @click="createKpi"
      @keyup.enter="createKpi"
     >
     <i class="fas fa-plus"></i> {{ $t('ui.kpi.create') }}
    </div>

    <div class="add-button-container"
      v-if="editable && kpis.length > 1"
      tabindex="0"
      @click="$router.push(`/a3/${a3_document.id}/order/${result_area}`)"
      @keyup.enter="$router.push(`/a3/${a3_document.id}/order/${result_area}`)"
      >
      <i class="fas fa-sort"></i> {{ $t('ui.general.change_order') }}
    </div>
  </div>
</template>


<script>

import ResultAreaHelper from '@/mixins/ResultAreaHelper';
import KpiListElement from '@/components/elements/KpiListElement';

import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  components: {
    KpiListElement,
  },
  mixins: [ResultAreaHelper, WithDocumentManager],

  props: {
    result_area: {
      type: String,
      required: false,
      default: null,
    },

    displayOptions: {
      type: Object,
      required: true,
    },
    focusMap: {
      type: Object,
      required: false,
      default: () => ({
        type: null,
        id: null,
      }),
    },

    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
  },

  computed: {
    kpis() {
      this.dataManager.a3Document;
      return this.dataManager.getKpiList(this.result_area);
    },
  },


  watch: {
    focusMap() {
      this.$forceUpdate();
    }
  },

  methods: {
    outOfFocus(kpi) {
      if (this.focusMap == null) return false;
      if (this.focusMap.kpi == null) return false;
      return !this.focusMap.kpi[kpi.id];
    },

    getKpiColor(kpi) {
        let sbf = this.dataManager.getSbf(kpi.sbf_id);
        if (this.a3_layout.structure.sbf_result_mapping == "one_to_one"
            && (!sbf || sbf.result_area != "__none__")
        ) {
            return this.area_colors[kpi.result_area];
        }
        if (this.a3_layout.structure.sbf_result_mapping == "one_to_many"
            || (sbf && sbf.result_area == "__none__")) {
            if(sbf && sbf.color) return sbf.color;
        }

        return this.area_colors[kpi.result_area];
    },

    createKpi() {
        let sbfFromFocus = this.sbfFromFocusMap();
        let sbfId = null;
        if (sbfFromFocus) {
            let sbf = this.dataManager.getSbf(sbfFromFocus);
            if (sbf.result_area == this.result_area) {
                sbfId = sbfFromFocus;
            }
        }

        this.interactionManager.createKpi(this.result_area, sbfId);
    },

    sbfFromFocusMap() {
        if (this.focusMap == null) return null;
        if (this.focusMap.sbf == null) return null;

        let selected = Object.entries(this.focusMap.sbf)
            .filter((value) => value[1]);
        // id of the first entry
        if (selected.length == 1) { return selected[0][0]; }
        return null;
    }

  }
}
</script>

<style lang="scss">
.out-of-focus {
  color: #ccc !important;
}

#a3-document-container.print-mode .kpi-list-icon {
  display: none
}

.kpi-list-icon {
  position: absolute;
  top: -5px;
  right: -10px;
  font-size: 80px;
  opacity: .15;
}

</style>
