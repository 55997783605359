<template>
  <div v-html="rendered_content" v-if="rendered_content != null"
                                 class="rich-text-render"
    ></div>
  <div v-else></div>
</template>

<script>

import quill_render from 'quill-render';
import richtextRender from '@/services/richtextRender';

export default {
  props: {
    value: {
      required: true,
      validator: () => true,
    },
  },

  data: () => ({
    rendered_content: "",
  }),

  watch: {
    value() {
      this.renderContent();
    },
  },

  mounted() {
    this.renderContent();
  },


  methods: {
    renderContent() {
        this.rendered_content = richtextRender.renderContent(
            this.value
        );
    }
  },
};
</script>

<style lang="scss">

.rich-text-render p {
  min-height: 1.2em;
}
.rich-text-render img {
  max-width: 100%;
}
</style>
