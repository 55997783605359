
import A3Sheet from "@/views/A3Sheet.vue";

import ChangeLayout from "@/components/overlays/ChangeLayout.vue";
import RenameMatrix from "@/components/overlays/RenameMatrix.vue";
import ChangeOrder from "@/components/overlays/ChangeOrder.vue";
import ShareA3 from "@/components/overlays/ShareA3.vue";
import RestructureA3 from "@/components/overlays/RestructureA3.vue";

import KpiCreate from "@/components/overlays/KpiCreate.vue";
import KpiDelete from "@/components/overlays/KpiDelete.vue";
import KpiEdit from "@/components/overlays/KpiEdit.vue";
import KpiLinkCreate from "@/components/overlays/KpiLinkCreate.vue";
import KpiLinkEdit from "@/components/overlays/KpiLinkEdit.vue";
import KpiMeasurements from "@/components/overlays/KpiMeasurements.vue";
import KpiStories from "@/components/overlays/KpiStories.vue";
import KpiStoryCreate from "@/components/overlays/KpiStoryCreate.vue";
import KpiStoryUpdate from "@/components/overlays/KpiStoryUpdate.vue";
import KpiView from "@/components/overlays/KpiView.vue";
import KpiChangelog from "@/components/overlays/KpiChangelog.vue";

import ActionBudget from "@/components/overlays/ActionBudget.vue";
import ActionCreate from "@/components/overlays/ActionCreate.vue";
import ActionDelete from "@/components/overlays/ActionDelete.vue";
import ActionEdit from "@/components/overlays/ActionEdit.vue";
import ActionLinkCreate from "@/components/overlays/ActionLinkCreate.vue";
import ActionLinkEdit from "@/components/overlays/ActionLinkEdit.vue";
import ActionStatus from "@/components/overlays/ActionStatus.vue";
import ActionTaskCreate from "@/components/overlays/ActionTaskCreate.vue";
import ActionTaskEdit from "@/components/overlays/ActionTaskEdit.vue";
import ActionChangelog from "@/components/overlays/ActionChangelog.vue";
import ActionView from "@/components/overlays/ActionView.vue";

import MissionEdit from "@/components/overlays/MissionEdit.vue";
import VisionEdit from "@/components/overlays/VisionEdit.vue";

import SbfCreate from "@/components/overlays/SbfCreate.vue";
import SbfDelete from "@/components/overlays/SbfDelete.vue";
import SbfEdit from "@/components/overlays/SbfEdit.vue";
import SbfView from "@/components/overlays/SbfView.vue";
import SbfChangelog from "@/components/overlays/SbfChangelog.vue";

export default [
  {
    component: A3Sheet,
    path: "/a3/:id",
    props: true,
    children: [
      { path: "", name: "a3-root" },
      { path: "share", name: "a3-share", component: ShareA3, props: true },
      { path: "layout", name: "a3-layout", component: ChangeLayout, props: true },
      { path: "rename_matrix", name: "a3-rename-matrix", component: RenameMatrix, props: true },
      { path: "restructure", name: "a3-restructure", component: RestructureA3, props: true },
      { path: "kpi/create", name: "a3-kpi-create", component: KpiCreate },
      { path: "kpi/:kpi_id", name: "a3-kpi-view", component: KpiView, props: true },
      { path: "kpi/:kpi_id/measurements", name: "a3-kpi-measurement", component: KpiMeasurements, props: true },
      { path: "kpi/:kpi_id/stories", name: "a3-kpi-stories", component: KpiStories, props: true },
      { path: "kpi/:kpi_id/stories/create", name: "a3-kpi-stories", component: KpiStoryCreate, props: true },
      { path: "kpi/:kpi_id/stories/:story_id/edit", name: "a3-kpi-stories", component: KpiStoryUpdate, props: true },
      { path: "kpi/:kpi_id/link/create", name: "a3-kpi-link-create", component: KpiLinkCreate, props: true },
      { path: "kpi/:kpi_id/link/:link_id", name: "a3-kpi-link-edit", component: KpiLinkEdit, props: true },
      { path: "kpi/:kpi_id/edit", name: "a3-kpi-edit", component: KpiEdit, props: true },
      { path: "kpi/:kpi_id/delete", name: "a3-kpi-delete", component: KpiDelete, props: true },
      { path: "kpi/:kpi_id/changelog", name: "a3-kpi-changelog", component: KpiChangelog, props: true },
      { path: "action/create", name: "a3-action-create", component: ActionCreate },
      { path: "action/:action_id/changelog", name: "a3-action-changelog", component: ActionChangelog, props: true },
      { path: "action/:action_id", name: "a3-action-view", component: ActionView, props: true },
      { path: "action/:action_id/edit", name: "a3-action-edit", component: ActionEdit, props: true },
      { path: "action/:action_id/delete", name: "a3-action-delete", component: ActionDelete, props: true },
      { path: "action/:action_id/budget", name: "a3-action-budget", component: ActionBudget, props: true },
      { path: "action/:action_id/status", name: "a3-action-status", component: ActionStatus, props: true },
      { path: "action/:action_id/task/create", name: "a3-action-task-create",
        component: ActionTaskCreate, props: true },
      { path: "action/:action_id/task/:action_task_id", name: "a3-action-task-edit",
        component: ActionTaskEdit, props: true },
      { path: "action/:action_id/link/create",
        name: "a3-action-link-create", component: ActionLinkCreate, props: true },
      { path: "action/:action_id/link/:link_id", name: "a3-action-link-edit", component: ActionLinkEdit, props: true },
      { path: "sbf/create", name: "a3-sbf-create", component: SbfCreate },
      { path: "sbf/:sbf_id", name: "a3-sbf-view", component: SbfView, props: true },
      { path: "sbf/:sbf_id/edit", name: "a3-sbf-edit", component: SbfEdit, props: true },
      { path: "sbf/:sbf_id/delete", name: "a3-sbf-delete", component: SbfDelete, props: true },
      { path: "sbf/:sbf_id/changelog", name: "a3-sbf-changelog", component: SbfChangelog, props: true },
      { path: "mission/edit", name: "a3-mission-edit", component: MissionEdit },
      { path: "vision/edit", name: "a3-vision-edit", component: VisionEdit },
      { path: "order/:order_type", name: "a3-order", component: ChangeOrder, props: true },
    ],
  },
];
