<template>
  <WithMenuContainer>
    <div class="container">
        <h1>
            <i class="fas fa-chalkboard-teacher"></i>
            {{ $t('ui.advisor.add_advisor') }}
        </h1>

      <AdvisorForm
         :advisor="organisation_advisor"
         :include_account_form="true"
         @submit="saveAdvisorForOrganisation"
         @cancel="$router.go(-1)"
      />
    </div>
  </WithMenuContainer>
</template>

<script>

import AdvisorForm from '@/components/advisor/AdvisorForm.vue';

export default {
  components: { AdvisorForm },

  data: () => ({

    organisation_advisor: {
        advisor_id: null,
    },
  }),

  methods: {
    saveAdvisorForOrganisation() {
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/variables.scss";

.a3-table {
  border-spacing: 0;
  width: 100%;

  th {
    padding: 8px;
    border-bottom: 1px $a3-green solid;
  }
  thead {
      margin-bottom: 4px;
  }

  tbody tr {
    td {
      padding: 4px 10px;
    }
    cursor: pointer;
    transition: color .1s ease-in-out, background-color, .1s ease-in-out;

    &:hover {
      background-color: $a3-green;
      color: white;
    }
  }
}

.container {
  max-width: 960px;
  margin: 45px auto;
}
</style>
