

function encodeFilterOptions(filterOptions) {
  // TODO: Actions?
  // TODO: Leave fields empty when nothing is put in
  let encodeObject = {
    ap: filterOptions.action_people.map(x => x.id).join('.'),
    rg: filterOptions.report_groups.map(x => x.id).join('.'),
    as: [
      encodeBool(filterOptions.action_status.done),
      encodeBool(filterOptions.action_status.not_done),
      encodeBool(filterOptions.action_status.unknown),
    ].join(''),
    at: [
      encodeBool(filterOptions.action_tasks.no_tasks),
      encodeBool(filterOptions.action_tasks.has_tasks),
    ].join(''),
    mb: filterOptions.milestone_before,
    aua: filterOptions.action_update_after,
    kua: filterOptions.kpi_update_after,
    kn: [
      encodeBool(filterOptions.kpi_norm.ok),
      encodeBool(filterOptions.kpi_norm.not_ok),
      encodeBool(filterOptions.kpi_norm.unknown),
    ].join(''),
    ks: [
      encodeBool(filterOptions.kpi_steering.is_steering),
      encodeBool(filterOptions.kpi_steering.no_steering),
    ].join(''),
    kt: [
      encodeBool(filterOptions.kpi_type.kpi),
      encodeBool(filterOptions.kpi_type.evaluation),
    ].join(''),
    s: filterOptions.sbf.map(x => x.id).join('.'),
    k: filterOptions.kpi.map(x => x.id).join('.'),
    a: filterOptions.action.map(x => x.id).join('.'),
  };

  return btoa(JSON.stringify(encodeObject));
}

function encodeBool(value) {
  if (value === true) return 't';
  if (value === false) return 'f';

  throw new Error("Invalid boolean");
}


function decodeFilterOptions(filterSlug) {
  if (filterSlug == null) return;
  let encodedObject = JSON.parse(atob(filterSlug));

  return {
    action_people: decodeDottedIds(encodedObject.ap),
    action_status: decodeBoolList(encodedObject.as, ['done', 'not_done', 'unknown']),
    action_tasks: decodeBoolList(encodedObject.at, ['no_tasks', 'has_tasks']),
    milestone_before: encodedObject.mb,
    action_update_after: encodedObject.aua,
    kpi_update_after: encodedObject.kua,
    kpi_norm: decodeBoolList(encodedObject.kn, ['ok', 'not_ok', 'unknown']),
    kpi_steering: decodeBoolList(encodedObject.ks, ['is_steering', 'no_steering']),
    kpi_type: decodeBoolList(encodedObject.kt, ['kpi', 'evaluation']),
    report_groups: decodeDottedIds(encodedObject.rg),
    sbf: decodeDottedIds(encodedObject.s),
    kpi: decodeDottedIds(encodedObject.k),
    action: decodeDottedIds(encodedObject.a),
  }
}

function decodeDottedIds(encoded) {
  if (encoded.length == 0) return null;
  return encoded.split('.')
      .map(id => ({ id: parseInt(id) }));
}

function decodeBoolList(encoded, keys) {
  if (encoded.length == 0) return null;
  if (encoded.length != keys.length) {
    console.error("Invalid encoded length for bool list");
    return;
  }

  let obj = {};
  for (let char_index in encoded) {
    let key = keys[char_index];
    if (encoded[char_index] == 't') obj[key] = true;
    if (encoded[char_index] == 'f') obj[key] = false;
  }
  return obj;
}
export default { encodeFilterOptions, decodeFilterOptions }
