<template>
  <div class="a3-context-menu"
       :style="{
            'left': `${position.x}px`,
            'top': `${position.y}px`
        }"
       @mouseleave="close"
    >
      <div class="context-menu-item"
           v-for="(opt, index) in options"
           :key="index"
           @click="opt.onclick()"
           :class="{'danger': opt.style == 'danger'}"
        >
          <i class="fas"
             :class="`fa-${opt.icon}`"
            ></i>
          {{ opt.name }}
      </div>
  </div>
</template>


<script>

import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  name: 'a3-context-menu',
  mixins: [WithDocumentManager],

  data: () => ({
    options: [],
  }),

  computed: {
    contextmenu() {
      return this.interactionManager.contextmenu;
    },

    position() {
      return {
        'x': this.contextmenu.event.clientX + 10,
        'y': this.contextmenu.event.clientY - 20,
      }
    }
  },

  mounted() {
    if (this.contextmenu.type == 'action') {
      this.options = [
        {
          icon: "filter",
          name: this.$t('ui.a3_document.focus'),
          onclick: () => {
            this.$emit('setFocus', {
              type: 'action',
              action: this.contextmenu.params.action
            });
            this.close();
          }
        },
        {
          icon: "pencil",
          name: this.$t('ui.general.edit'),
          onclick: () => {
            this.interactionManager.editAction(this.contextmenu.params.action.id)
          }
        },
        {
          icon: "times",
          name: this.$t('ui.general.delete'),
          style: 'danger',
          onclick: () => {
            this.interactionManager.deleteAction(this.contextmenu.params.action.id)
          }
        }
      ];
      if (!this.interactionManager.hasA3Feature("A3Edit")) {
        this.options = [this.options[0]];
      }
    }

    if (this.contextmenu.type == 'kpi') {
      this.options = [
        {
          icon: "filter",
          name: this.$t('ui.a3_document.focus'),
          onclick: () => {
            this.$emit('setFocus', {
              type: 'kpi',
              kpi: this.contextmenu.params.kpi
            });
            this.close();
          }
        },
        {
          icon: "pencil",
          name: this.$t('ui.general.edit'),
          onclick: () => {
            this.interactionManager.editKpi(this.contextmenu.params.kpi.id)
          }
        },
        {
          icon: "plus",
          name: this.$t('ui.action.create'),
          onclick: () => {
            this.interactionManager.createActionForKpi(this.contextmenu.params.kpi.id)
          }
        },
        {
          icon: "times",
          name: this.$t('ui.general.delete'),
          style: 'danger',
          onclick: () => {
            this.interactionManager.deleteKpi(this.contextmenu.params.kpi.id)
          }
        }
      ];
      if (!this.interactionManager.hasA3Feature("A3Edit")) {
        this.options = [this.options[0]];
      }
    }

    if (this.contextmenu.type == 'sbf') {
      this.options = [
        {
          icon: "filter",
          name: this.$t('ui.a3_document.focus'),
          onclick: () => {
            this.$emit('setFocus', {
              type: 'sbf',
              sbf: this.contextmenu.params.sbf
            });
            this.close();
          }
        },
        {
          icon: "expand-alt",
          name: this.$t('ui.sbf_fillout.sbf_fillout'),
          onclick: () => {
            this.interactionManager.filloutSbf(this.contextmenu.params.sbf.id)
          }
        },
        {
          icon: "plus",
          name: this.$t('ui.kpi.create'),
          onclick: () => {
            this.interactionManager.createKpiForSbf(this.contextmenu.params.sbf.id)
          }
        },
        {
          icon: "pencil",
          name: this.$t('ui.general.edit'),
          onclick: () => {
            this.interactionManager.editSbf(this.contextmenu.params.sbf.id)
          }
        },
        {
          icon: "times",
          name: this.$t('ui.general.delete'),
          style: 'danger',
          onclick: () => {
            this.interactionManager.deleteSbf(this.contextmenu.params.sbf.id)
          }
        }
      ];

      if (!this.interactionManager.hasA3Feature("A3Edit")) {
        this.options = [this.options[0]];
      }
    }
  },

  methods: {
    close() {
      this.interactionManager.clearContextMenu();
    },
  },
}

</script>


<style lang="scss">

.a3-context-menu {
  backface-visibility: hidden;
  background-color: $a3-green;
  position: fixed;
  width: 200px;
  border-radius: 5px;
  z-index: 1000;
  font-size: 14px;

  animation: flipDown .2s ease-out;
  transform-origin: top center;

  .context-menu-item {
    padding: 8px 10px;
    border: 2px $a3-green solid;
    border-radius: 5px;
    cursor: pointer;

    transition: background-color .15s ease-out,
      color .15s ease-out;

    &.danger {
      background-color: $general-red;
      border-color: $general-red;
      &:hover {
        color: $general-red;
      }
    }

    &:hover {
      background-color: white;
      color: $a3-green;
    }
  }
}

@keyframes flipDown {
  from {
    opacity: 0;
    transform: scaleY(0);
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
}


</style>
