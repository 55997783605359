
import ManageA3 from "@/views/ManageA3.vue";
import ManageOverview from "@/views/manage/Overview.vue";
import ManageA3List from "@/views/manage/List.vue";
import ManageA3Copy from "@/views/manage/Copy.vue";

import A3EditOptions from "@/views/A3EditOptions.vue";
import A3Rights from "@/components/a3_settings/A3Rights.vue";
import A3OptionsOwner from "@/components/a3_settings/Owner.vue";

import CopyA3FromTimeframe from "@/views/CopyA3FromTimeframe.vue";
import ImportA3 from "@/views/ImportA3.vue";
import NewA3 from "@/views/NewA3.vue";

export default [
  {
      path: "/a3/manage",
      name: "a3-manage",
      component: ManageA3,
      meta: {
          requiredFeatures: [ "A3Management" ],
      },
      children: [
          {
              path: "",
              name: "a3-manage-overview",
              component: ManageOverview,
          },
          {
              path: "list",
              name: "a3-manage-list",
              component: ManageA3List,
          },
          {
              path: "copy",
              name: "a3-manage-copy",
              component: ManageA3Copy,
          }
      ],
  },
  { path: "/a3/create", name: "a3-create", component: NewA3 },
  { path: "/a3/import", name: "a3-import", component: ImportA3 },
  { path: "/a3/copy_from_timeframe", name: "a3-copy-from_timeframe", component: CopyA3FromTimeframe },
  {
      component: A3EditOptions,
      name: "a3-options",
      path: "/a3/:a3doc_id/options",
      props: true,
      children: [
          {
              path: "owner",
              name: "a3-options-owner",
              component: A3OptionsOwner,
              props: true,
          },
          {
              path: "rights",
              name: "a3-options-rights",
              component: A3Rights,
              props: true,
          },
      ],
  },
];
