<template>
    <div>
        <h1>{{ $t('ui.restructure.title') }}</h1>

        <div class="select-a3" v-if="input_mode == 'select-from'
                    || input_mode == 'select-to'
            ">
            <A3PlanSelector
                @select="selectPlan"
                @cancel="input_mode = 'elements'"
             />
        </div>

        <div class="element-move-grid" v-if="input_mode == 'elements'">

            <div>
            </div>
            <div style="text-align: left; margin-bottom: 30px;">
                <h3>Methode</h3>

                <button class="a3-button"
                        :class="{
                             'action': mode === 'copy',
                             'cancel': mode !== 'copy'
                        }"
                        @click="setMode('copy')"
                    >
                    <i class="fas fa-copy"></i>
                    {{ $t('ui.restructure.copy') }}
                </button>
                <button class="a3-button action"
                        :class="{
                             'action': mode === 'move',
                             'cancel': mode !== 'move'
                        }"
                        @click="setMode('move')"
                    >
                    <i class="fas fa-arrow-right"></i>
                    {{ $t('ui.restructure.move') }}
                </button>
                <button class="a3-button action disabled"
                        :class="{
                             'action': mode === 'push_down',
                             'cancel': mode !== 'push_down'
                        }"
                        @click="setMode('push_down')"

                    >
                    <i class="fas fa-arrow-down"></i>
                    {{ $t('ui.restructure.push_down') }}
                </button>
                <button class="a3-button action disabled"
                        :class="{
                             'action': mode === 'lift_and_push',
                             'cancel': mode !== 'lift_and_push'
                        }"
                        @click="setMode('lift_and_push')"
                    >
                    <i class="fas fa-exchange fa-rotate-90"></i>
                    {{ $t('ui.restructure.lift_and_pull') }}
                </button>
            </div>


            <div>
                <h3>{{ $t('ui.restructure.from') }}</h3>
            </div>

            <div>
                <div class="plan-select-container"
                     @click="input_mode = 'select-from'"
                    >
                    <template v-if="plan_from != null">
                        <template v-if="plan_from.meta.department != null">
                            {{ plan_from.meta.department.name }} -
                        </template>
                        <template v-else>
                            {{ plan_from.meta.organisation.name }} -
                        </template>

                        {{ plan_from.meta.timeframe.name }}
                    </template>
                    <template v-if="selected_from_id == id">
                         - <em>{{ $t('ui.restructure.current_plan') }}</em>
                    </template>
                </div>
                <div class="plan-element-select-container" v-if="plan_from != null">
                    <div class="mission">
                        <CheckBox /> <label>{{ $t('definitions.terms.mission') }}</label>
                    </div>
                    <div class="vision">
                        <CheckBox /> <label>{{ $t('definitions.terms.vision') }}</label>
                    </div>
                    <div class="sbf element-box">
                        <h4>{{ $tc('definitions.short.sbf') }}</h4>

                        <multiselect
                            id="sbf-multiselect"
                            v-model="elementSelection.sbf"
                            data-cy="filter-sbf-select"
                            :options="plan_from.sbf"
                            :placeholder="$t('ui.filter_tab.select_sbf')"
                            :selectLabel="$t('ui.general.enter_select')"
                            :selectedLabel="$t('ui.general.selected')"
                            :deselectLabel="$t('ui.general.enter_deselect')"
                            :multiple="true"
                            :close-on-select="false"
                            track-by="id"
                            label="name">
                        <template v-slot:noOptions>
                            {{ $t('ui.general.list_is_empty') }}
                        </template>
                        </multiselect>
                    </div>
                    <div class="kpi element-box">
                        <h4>{{ $tc('definitions.short.kpi') }}</h4>

                        <multiselect
                            v-model="elementSelection.kpi"
                            :options="Object.values(plan_from.kpi).flat()"
                            :multiple="true"
                            :close-on-select="false"
                            :placeholder="$t('ui.filter_tab.select_kpi')"
                            :selectLabel="$t('ui.general.enter_select')"
                            :selectedLabel="$t('ui.general.selected')"
                            :deselectLabel="$t('ui.general.enter_deselect')"
                            track-by="id"
                            label="name">
                            <template v-slot:noOptions>
                                {{ $t('ui.general.list_is_empty') }}
                            </template>
                        </multiselect>
                    </div>
                    <div class="action element-box">
                        <h4>{{ $tc('definitions.short.action') }}</h4>

                        <multiselect
                            v-model="elementSelection.action"
                            :options="Object.values(plan_from.actions).flat()"
                            :multiple="true"
                            :close-on-select="false"
                            :placeholder="$t('ui.filter_tab.select_action')"
                            :selectLabel="$t('ui.general.enter_select')"
                            :selectedLabel="$t('ui.general.selected')"
                            :deselectLabel="$t('ui.general.enter_deselect')"
                            track-by="id"
                            label="name">
                        <template v-slot:noOptions>
                            {{ $t('ui.general.list_is_empty') }}
                        </template>
                        </multiselect>
                    </div>
                </div>
            </div>

            <div></div>
            <div class="icon-container">
                <i class="fas fa-arrow-down fa-2x"></i>
            </div>

            <div>
                <h3>{{ $t('ui.restructure.to') }}</h3>
            </div>
            <div>
                <div class="plan-select-container"
                     @click="input_mode = 'select-to'"
                    >
                    <template v-if="plan_to != null">
                        <template v-if="plan_to.meta.department != null">
                            {{ plan_to.meta.department.name }} -
                        </template>
                        <template v-else>
                            {{ plan_to.meta.organisation.name }} -
                        </template>

                        {{ plan_to.meta.timeframe.name }}
                    </template>
                    <template v-if="selected_to_id == id">
                         - <em>{{ $t('ui.restructure.current_plan') }}</em>
                    </template>
                </div>
            </div>
        </div>

        <div style="text-align: right; margin-top: 30px;">
            <div class="form-buttons" v-if="input_mode == 'elements'">
                <button class="a3-button action"
                        @click="execute"
                    >
                    <i class="fas fa-check"></i> {{ $t('ui.restructure.execute') }}
                </button>
            </div>
        </div>

    </div>
</template>

<script>

import A3PlanSelector from '@/components/elements/A3PlanSelector';
import Multiselect from 'vue-multiselect';

export default {

    components: {
        A3PlanSelector,
        Multiselect,
    },

    props: {
        id: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        // mode for the user input in the UI
        input_mode: 'elements',

        // the selected mode for
        mode: "copy",
        selected_from_id: null,
        selected_to_id: null,

        plan_from: null,
        plan_to: null,
        elementSelection: {
            mission: false,
            vision: false,
            sbf: [],
            kpi: [],
            action: [],
        },
    }),

    mounted() {
        this.selected_from_id = parseInt(this.id);
        this.loadElements();
    },

    watch: {
        selected_to_id() {
            this.$http.get(`a3/${this.selected_to_id}/meta`)
                .then((res) => {
                    this.plan_to = { meta: res.body };
                });
        }
    },

    methods: {
        setMode(mode) {
            this.mode = mode;
        },

        execute() {
            let endpoint = `a3_operations/copy_elements/${this.selected_from_id}/${this.selected_to_id}`;
            this.$http.post(endpoint, {
                mission: this.elementSelection.mission,
                vision: this.elementSelection.vision,
                sbf_ids: this.elementSelection.sbf.map(x => x.id),
                kpi_ids: this.elementSelection.kpi.map(x => x.id),
                action_ids: this.elementSelection.action.map(x => x.id),
            }).then(() => {
                this.$router.push(`/a3/${this.selected_to_id}`);
            });
        },

        selectPlan(ev) {
            if (this.input_mode == 'select-from') {
                this.plan_from = null;
                this.selected_from_id = ev.a3doc_id;
                this.loadElements();
            }
            if (this.input_mode == 'select-to') {
                this.selected_to_id = ev.a3doc_id;
            }
            this.input_mode = 'elements';
        },

        loadElements() {
            this.$http.get(`a3/${this.selected_from_id}`)
                .then((res) => {
                    this.plan_from = res.body;
                });
        }
    },
}
</script>

<style lang="scss">

.element-move-grid {
    display: grid;
    grid-template-columns: 100px auto;
    grid-template-rows: auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.plan-select-container {
    border: 1px rgba($a3-green, .2) solid;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: color .15s ease-out, background-color .15s ease-out;

    &:hover {
        background-color: rgba($a3-green, 1);
        color: white;
    }
}

.icon-container {
    text-align: center;
    color: rgba($a3-green, .4);
}

.plan-element-select-container {
    padding: 10px 20px;
    margin: 0 15px;
    border: 1px rgba($a3-green, .2) solid;
    border-radius: 0 0 10px 10px;
    border-top: 0px;

    h4 {
        margin-top: 5px;
        margin-bottom:5px;
    }

    > .mission {
        grid-area: mission;
    }
    > .vision {
        grid-area: vision;
    }
    > .sbf {
        max-width: 100%;
        grid-area: sbf;
    }
    > .kpi {
        max-width: 100%;
        grid-area: kpi;
    }
    > .action {
        max-width: 100%;
        grid-area: action;
    }


    display: grid;
    grid-template-areas: "mission vision"
                         "sbf sbf"
                         "kpi kpi"
                         "action action";
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    padding-bottom: 30px;
    padding-top: 30px;
}

</style>
