<template>
  <div class="program-plan-container"
       v-if="$hasFeature('A3ProgramPlans')"
       v-show="plans.length > 0 || $hasFeature('A3Create')"
    >
    <i class="fas fa-clipboard-list fa-3x main-icon"></i>

    <A3IndexButton
       v-for="a3 in plans"
       :key="a3.id"
       :name="a3.matrix_name"
       :selected="false"
       :a3="a3"
       :a3_rights="a3_rights"
       />

    <button class="a3-button action program-plan-button"
            v-if="$hasFeature('A3Create')"
            @click="triggerNew"
      >
      <i class="fas fa-plus"></i> {{ $t('ui.a3.add_program_plan') }}
    </button>
  </div>
</template>

<script>

import A3IndexButton from '@/components/organisation/A3IndexButton';

export default {
  components: { A3IndexButton },

  props: {
    a3_list: {
      type: Array,
      required: false,
      default: () => [],
    },

    a3_rights: {
        type: Object,
        required: true,
    },

    parent_department_id: {
      type: Number,
      required: false,
      default: null,
    },
  },


  computed: {
    plans() {
      return this.a3_list
        .filter(x => x.department_id === this.parent_department_id)
        .filter(x => x.matrix_name != null);
    },
  },

  methods: {
    triggerNew() {
      this.$emit('new_a3', {
        department_id: this.parent_department_id,
        program_plan: true,
      });
    },
  }
}
</script>

<style lang="scss">

.program-plan-container {
  padding-left: 50px;
  margin-top: 10px;

  i {
    vertical-align: middle;
  }
  .main-icon {
    margin-right: 20px;
    color: $program-plan-blue;
  }

  .a3-button.program-plan-button {
    background-color: $program-plan-blue;
    border-color: $program-plan-blue;

    &:hover {
      color: $program-plan-blue;
    }
  }
}

</style>
