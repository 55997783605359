<template>
    <div style="position: relative">
        <div class="icon-picker-button"
             @click="picker_open = !picker_open"
            >
            <i class="fas" :class="`fa-${selected_icon}`"></i>
        </div>

        <div class="icon-selector"
             v-show="picker_open"
            >
            <div class="icon-selector-inner">
                <div v-for="icon in available_icons"
                     @click="selectIcon(icon)"
                     class="icon-button">
                    <i class="fas" :class="`fa-${icon}`"></i>
                </div>
            </div>

            <div style="width: 100%;" class="icon-options"
                    @click="selectIcon(null)"
                >
                <div class="icon-option-button danger">
                    <i class="fas fa-times"></i> {{ $t('ui.layout.remove_icon') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
  },

  data: () => ({
      selected_icon: "star",
      picker_open: false,
      available_icons: [
          "address-book",
          "alarm-clock",
          "analytics",
          "angle-down",
          "angle-right",
          "atlas",
          "baby",
          "balance-scale",
          "bell",
          "book-user",
          "boxes-alt",
          "box-heart",
          "briefcase",
          "calendar-alt",
          "cactus",
          "capsules",
          "chalkboard",
          "chart-pie",
          "check-circle",
          "clipboard-check",
          "cloud",
          "coffee",
          "comment",
          "comment-smile",
          "cube",
          "desktop",
          "dice",
          "faucet",
          "female",
          "flag",
          "flask",
          "flower-tulip",
          "gift",
          "globe-europe",
          "graduation-cap",
          "hands-helping",
          "heart",
          "home",
          "hourglass",
          "industry",
          "key",
          "landmark",
          "leaf",
          "lightbulb",
          "map",
          "male",
          "map-signs",
          "megaphone",
          "money-bill-wave",
          "mountain",
          "paint-brush",
          "paperclip",
          "pen",
          "people-arrows",
          "people-carry",
          "piggy-bank",
          "play",
          "poll",
          "puzzle-piece",
          "random",
          "road",
          "rocket",
          "ruler",
          "shapes",
          "shield-check",
          "sitemap",
          "stopwatch",
          "tachometer",
          "tags",
          "telescope",
          "users-cog",
          "users",
          "walking",
          "warehouse-alt"
      ]
  }),

  mounted() {
    this.selected_icon = this.value;

    if (!this.value) {
      this.$emit('input', null);
    }
  },

  methods: {
      selectIcon(icon) {
          this.selected_icon = icon;
          this.$emit('input', icon);
          this.picker_open = false;
      }
  },
};
</script>

<style lang="scss">
.icon-picker-button {
  padding: 2px;
  margin-top: 6px;
  width: 35px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: $a3-green;
  font-size: 24px;

  &:hover {
      color: white;
      background-color: $a3-green;
  }
}


.icon-selector {
    position: absolute;
    top: 30px;
    left: 0;

    background-color: white;
    padding: 5px;
    font-size: 24px;
    border-radius: 5px;

    -webkit-box-shadow: 6px 6px 17px -1px rgba(0,115,133,0.67);
    box-shadow: 6px 6px 17px -1px rgba(0,115,133,0.67);

    .icon-options {
        text-align: center;
        margin-top: 5px;
        padding-right: 10px;

        .icon-option-button {
            margin: 0 auto;
            width: 200px;
            font-size: 18px;
            border: 1px solid $general-orange;
            border-radius: 5px;
            cursor: pointer;
            padding: 2px;
            user-select: none;

            transition: color .15s ease-out, background-color .15s ease-out;

            &.danger {
                color: $general-orange;
                opacity: .7;

                &:hover {
                    opacity: 1;
                    background-color: $general-orange;
                    color: white;
                }
            }
        }
    }

    .icon-selector-inner {
        display: grid;
        grid-template-columns: repeat(15, 38px);
        grid-template-rows: repeat(6, 38px);


        .icon-button {
            text-align: center;
            border-radius: 2px;
            cursor: pointer;
            line-height: 35px;
            vertical-align: middle;

            &:hover {
                background-color: $a3-green;
                color: white;
            }
        }
    }

}
</style>
