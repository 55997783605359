<template>
  <div v-if="feature_info != null && features != null">
    <template v-if="features['Root'] && features['Root'] == 'Open'">
      <strong><i class='fas fa-user-crown'></i> root user</strong>
    </template>
      <div class="feature-edit-container">

          <template v-for="g in groups">
            <div v-if="show_groups.includes(g)" :key="g">
              <h3>{{ $t(`ui.feature_groups.${g}`) }}</h3>
              <div v-for="feature in feature_info[g]" :key="feature">
                   <template v-if="features[feature] == 'Open'">
                     <i class="fas fa-check"></i>
                   </template>
                   <template v-else>
                     <i class="fas fa-times"></i>
                   </template>
                  {{ $t(`ui.features.${feature}`) }}<br>
              </div>
            </div>
          </template>
      </div>

  </div>
</template>

<script>


export default {
  props: {
    features: {
      type: Object,
      required: true,
    },
    show_groups: {
      type: Array,
      required: false,
      default: () => ['A3', 'A3Admin', 'Organisation', 'OrganisationAdmin'],
    }
  },

  data: () => ({
    feature_info: null,

    groups: [
      "A3", "A3Admin",
      "Organisation", "OrganisationAdmin",
      "ReleaseToggle",
    ]
  }),

  mounted() {
    this.loadFeatureInfo();
  },

  methods: {
    loadFeatureInfo() {
      this.$http.get('feature_info')
        .then(res => this.feature_info = res.body);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

.feature-info-container {
  border-radius: 5px;
  user-select: none;

  i {
    padding-right: 5px;
  }
}
</style>
