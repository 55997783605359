<template>
    <InfoBox :top="'25px'" left="0" v-if="description">
      <template v-slot:display>
        <h2>{{ prefix }} {{ title }}</h2>
      </template>

      <RichTextRender :value="description" />
    </InfoBox>
    <h2 v-else>{{prefix}} {{ title }}</h2>
</template>

<script>

import RichTextRender from '@/components/forms/RichTextRender.vue';

export default {
  components: {
    RichTextRender,
  },

  props: {
    layout: {
      type: Object,
      required: true,
    },

    title_key: {
      type: String,
      required: false,
    },

    // All definitions set in this custom area
    // will override other settings
    custom_area: {
        type: Object,
        required: false,
    },

    fallback_translation_key: {
      type: String,
      required: true,
    },

    prefix: {
      type: String,
      required: false,
      default: ""
    },
  },

  computed: {
    title() {
      if (this.custom_area
          && this.custom_area.name
      ) { return this.custom_area.name; };

      return this.layout.titles[this.title_key]
        ? this.layout.titles[this.title_key]
        : this.$tc(this.fallback_translation_key);
    },
    description() {
      if (this.custom_area
          && this.custom_area.description
      ) { return this.custom_area.description; };

      return this.layout.descriptions[this.title_key]
        ? this.layout.descriptions[this.title_key]
        : null;
    },
  },
};

</script>
