<template>
    <div class="element-form">
        <h2 class="element-form-title"><i class="fas fa-bullseye-arrow"></i>
            {{ $t('ui.sbf.create') }}
        </h2>

        <SbfForm :sbf="sbf"
          :dataManager="dataManager"
          :child_departments="relations.children"
          :push_a3docs="push_a3docs"
          :show_deploy="dataManager.getCapability('sbf.deploy')"
          @submit="createSbf"
          @cancel="cancel" />
    </div>
</template>



<script>


import SbfForm from '@/components/forms/SbfForm';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {
  mixins: [WithDocumentManager],
  components: {
    SbfForm,
  },

  data: () => ({
    sbf: {
      name: null,
      result_area: null,
      comment_chances_risks: null,
      push_default: false,
    },
    push_a3docs: []
  }),

  mounted() {
    this.$UiEventBus.$emit('shown', {key: 'sbf-create'});

    if (this.a3_layout.structure.sbf_result_mapping == 'one_to_many'
      && this.sbf.result_area == null
    ) {
      this.sbf.result_area = '__none__';
    }
  },

  methods: {
    createSbf() {
      this.dataManager
        .createSbf(this.sbf, this.push_a3docs)
        .then((res) => {
          this.a3_document.sbf.push(res.body);
          this.$UiEventBus.$emit('submit', {key: 'sbf-create'});
          this.$router.push(`/a3/${this.a3_document.id}/`);
        });
    },

    cancel() {
      this.$UiEventBus.$emit('cancel', {key: 'sbf-create'});
      this.$router.go(-1);
    },
  },
};
</script>

