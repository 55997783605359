<template>
  <div
      class="sbf-view-overlay overlay-result-area"
      :style="{'--result-area-color': area_colors[sbf.result_area]}"

 sbf     v-if="sbf" >
      <h3 class="overlay-type-header">
          {{ $tc('definitions.terms.sbf') }} -
          {{ $t('ui.changelog.changelog') }}
      </h3>
      <h2>{{ sbf.name }}</h2>

      <table class="a3-table changelog-table">
          <thead>
              <tr>
                  <th>{{ $t('ui.changelog.date') }}</th>
                  <th>{{ $t('ui.changelog.change') }}</th>
              </tr>
          </thead>

          <tbody>
              <tr
                  v-for="c in changelog"
                  :key="c.id"
                  >
                  <td style="vertical-align: top; padding-top: 15px;">
                      {{ $d(c[0].created, 'datetime') }}<br>
                      {{ $t(`ui.changelog.edit_type.${c[0].edit_type}`) }}<br>
                      <span class="changed-by">
                          {{ $t('ui.changelog.by') }} {{ c[1].name }}
                      </span>
                  </td>
                  <td>
                      <div
                            style="padding-top: 3px"
                            v-for="(value, key) in c[0].new_values"
                                :key="key">
                        <em style="opacity: .5">{{ $t(`ui.sbf.${key}`) }}:</em><br>
                          <FieldRender
                              :field="`sbf.${key}`"
                              :value="value"
                              :dataManager="dataManager"
                              />
                      </div>
                  </td>
              </tr>
              <tr v-if="changelog == null || changelog.length == 0">
                <td colspan="2" style="text-align: center;">
                  <em> {{ $t('ui.changelog.no_changes') }} </em>
                </td>
              </tr>
          </tbody>
      </table>

    <div style="margin-top: 45px">
      <button class="a3-button action" @click="$router.go(-1)">
        <i class="fas fa-chevron-left"></i> {{ $t('ui.general.back') }}
      </button>
    </div>
  </div>
</template>

<script>

import FieldRender from '@/components/elements/FieldRender';
import RichTextRender from '@/components/forms/RichTextRender';
import ButtonActionGroup from '@/components/elements/ButtonActionGroup.vue';
import A3RelationHelper from '@/mixins/A3RelationHelper';
import ResultAreaHelper from '@/mixins/ResultAreaHelper';

import WithDocumentManager from '@/mixins/WithDocumentManager';

import moment from 'moment';

export default {
  mixins: [A3RelationHelper,
    ResultAreaHelper,
    WithDocumentManager],

  components: {
    FieldRender,
    RichTextRender,
    ButtonActionGroup,
  },
  props: {
    sbf_id: {
      type: String,
      required: true,
    },

    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    editmode: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data: () => ({
      changelog: []
  }),

  computed: {

    result_area() {
        return this.resultAreaFromLayout(
            this.a3_document.meta.layout,
            this.sbf.result_area
        );
    },

    sbf() {
      this.dataManager.a3Document;
      return this.dataManager.getSbf(this.sbf_id);
    },
  },

  mounted() {
    if (this.a3_document) {
      this.loadChangelog();
    }
  },

  methods: {
    loadChangelog()  {
      this.dataManager
        .loadSbfChangelog(this.sbf_id)
        .then((log) => {
          this.changelog = log;
          for (let l of this.changelog) {
            let created_date = moment.utc(l[0].created);
            l[0].created = created_date.toDate();
          }
        });
    },
  },
};
</script>

<style lang="scss">

.changelog-table span.changed-by {
    opacity: .6;
    display: inline-block;
    padding-left: 10px;
}

.changelog-table tbody tr {
  pointer-events: none;
  border-bottom: 1px solid #eee;
}

</style>


