<template>
  <div class="link-element">
    <i class="fas fa-link"></i>
    <a :href="link.url"
       target="_blank">{{ link.name }}</a>

    <span v-if="editable" style="padding-left: 2px; cursor: pointer;"
                          @click="$emit('edit')"
      >
       | <i class="fas fa-pencil"></i>
    </span>

    <div class="link-element-description"
       >
       <span style="padding-bottom: 4px; display: block;">
         <em>{{ link.domain }}</em></span>
       <template v-if="link.description">{{ link.description }}</template>
    </div>


  </div>
</template>


<script>

export default {
  props: {
    link: {
      required: true,
      type: Object,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
};
</script>


<style lang="scss">

.link-element {
  background-color: #ddd;
  padding: 5px 15px;
  border-radius: 5px;
  color: $a3-green;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  transition: background-color .15s ease-out, color .15s ease-out;

  a {
    color: $a3-green;
    padding-left: 5px;
  }

  &:hover {
    background-color: $a3-green;
    color: white;
    a { color: white; }
    .link-element-description {
      transform: scaleY(1);
    }
  }

  .link-element-description {
    position: absolute;
    min-width: 250px;
    pointer-events: none;
    background-color: $a3-green;
    margin-left: -15px;
    padding: 10px;
    border-radius: 0px 5px 5px 5px;
    z-index: 100;

    transform: scaleY(0);
    transition: all .15s ease-out;
    transform-origin: top;
  }
}
</style>
