<template>
    <WithMenuContainer>
        <div class="container">
            <h1>Plan importeren vanuit A3 Digitaal</h1>

            <p>
                Hier kunt u een plan vanuit A3 Digitaal importeren.
                <ol>
                    <li>Log in op A3 Digitaal (uw oude tool)</li>
                    <li>Open het jaarplan dat u wilt importeren</li>
                    <li>Klik op -> Rapportage -> <strong>Organisatiegebieden XLSX</strong></li>
                </ol>

                Nu kunt u een Excel bestand downloaden, met daarin de (meeste) inhoud van uw jaarplan. Vervolgens:

                <ol>
                    <li>Klik op "Bestand kiezen" hieronder</li>
                    <li>Selecteer het Excel bestand dat u zojuist ge-exporteerd heeft</li>
                    <li>Klik op "Importeren"</li>
                </ol>
            </p>

            <div style="margin: 45px auto">
                <input type="file" id="upload-plan-file" />
            </div>

            <div>
                <button class="a3-button action" @click="triggerRequest">
                    <i class="fas fa-file-import"></i> Importeren
                </button>
                <button class="a3-button cancel" @click="$router.go(-1)">
                    <i class="fas fa-times"></i> Annuleren
                </button>
            </div>
        </div>
    </WithMenuContainer>
</template>



<script>

export default {

    data: () => ({
      organisation_id: null,
      department_id: null,
      timeframe_id: null,
    }),

    mounted() {
        this.organisation_id = this.$route.query.organisation_id;
        if (this.$route.query.department_id) {
          this.department_id = this.$route.query.department_id;
        }
        this.timeframe_id = this.$route.query.timeframe_id;
    },

    methods: {
        triggerRequest() {
            let fileElement = document.getElementById('upload-plan-file');

            let formData = new FormData();
            formData.append('organisation_id', this.organisation_id);
            formData.append('department_id', this.department_id);
            formData.append('timeframe_id', this.timeframe_id);
            formData.append('file', fileElement.files[0]);

            this.$http.post('a3/import/org_area', formData)
                .then(res => {
                    this.$router.push(`/a3/${res.body.id}`);
                });
        },
    }
}
</script>

<style lang="scss">
#upload-plan-file {
    padding: 15px;
    border: 1px $a3-green solid;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: rgba($a3-green, .1);
    }
}
</style>
