<template>
    <div style="height: 100%;" class="blue-scroll-bar">
        <MainMenu />
        <ManageMenu />

        <div class="view-container" style="position: relative;">
            <slot></slot>
        </div>
    </div>
</template>

<script>

import MainMenu from '@/components/nav/MainMenu.vue';
import ManageMenu from '@/components/nav/ManageMenu.vue';

export default {
    components: {
        MainMenu,
        ManageMenu,
    },
};
</script>

