<template>
  <WithOrganisationMenuContainer>
    <div class="container">
      <h2>{{ $t('ui.user_type.edit') }}</h2>

      <UserTypeForm
        v-if="user_type != null"
        :user_type="user_type"
        @submit="updateUserType"
        @cancel="$router.push('/organisation/user_types')"
        />
    </div>
  </WithOrganisationMenuContainer>
</template>

<script>

import UserTypeForm from '@/views/admin/forms/UserTypeForm.vue';

export default {

  components: {
    UserTypeForm
  },

  props: {
    user_type_id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    user_type: null,
  }),

  mounted() {
    this.loadUsertype();
  },

  methods: {
    loadUsertype() {
      this.$http.get(`admin/user_type/${this.user_type_id}`)
        .then((res) => {
          this.user_type = res.body[0];
          this.user_type.a3_rules = res.body[1];
        });
    },

    updateUserType() {
      this.$http.put(`admin/user_type/${this.user_type_id}`, this.user_type)
        .then((res) => {
          this.$router.push('/organisation/user_types');
        })
    },
  },
};
</script>
