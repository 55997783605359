<template>
  <div class="checkbox-container">
    <input type="checkbox" name="cb" :id="checkbox_id" :checked="value" @change="onChange">
    <label :for="checkbox_id" tabindex="0"
      @keyup.enter="$emit('input', !value)"
      >
      <svg viewBox="0 0 100 100">
        <path class="box" d="M82,89H18c-3.87,0-7-3.13-7-7V18c0-3.87,3.13-7,7-7h64c3.87,0,7,3.13,7,7v64C89,85.87,85.87,89,82,89z"/>
        <polyline class="check" points="25.5,53.5 39.5,67.5 72.5,34.5 "/>
      </svg>
    </label>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: false,
      default: null,
    }
  },

  computed: {
    checkbox_id() {
      if (this.id) {
        return this.id;
      }
      return 'checkbox_' + this._uid;
    },
  },

  methods: {
    onChange(ev) {
      this.$emit('input', ev.target.checked);
      this.$emit('change', ev.target.checked);
    },
  }
};
</script>

<style lang="scss">
.checkbox-container {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
}

.checkbox-container input[type=checkbox] {
  display: none;
  pointer-events: none;

  & + label {
    &:focus {
      outline: none;

      svg {
        stroke: rgba($a3-green, 1);
      }
    }
    svg {
      width: 1.3em;
      stroke: rgba($a3-green, .2);
      stroke-width: 5;
      fill: white;
      cursor: pointer;

      &:hover .box {
        stroke: rgba($a3-green, 1);
      }

      .box {
        stroke-dasharray: 320;
        stroke-dashoffset: 0;
        fill: white;
        transition: stroke .15s ease-out;
      }
      .check {
        stroke: $a3-green;
        stroke-width: 8;
        stroke-dasharray: 70;
        stroke-dashoffset: 70;
        fill: none;
        transition: stroke-dashoffset .15s ease-out;
      }
    }

    span {
      padding-top: .3em;
      margin-left: .3em;
    }
  }

  &:checked + label {
    .check {
      stroke-dashoffset: 0;
    }
  }
}
</style>
