<template>
  <WithMenuContainer>
    <div class="container">
      <h1>{{ $t('ui.accounts.view_rights') }} </h1>

      <table class="a3-table">
        <thead>
          <tr>
            <th>Afdeling</th>
            <th>Periode</th>
            <th>Lees/Schrijf</th>
            <th>Reden</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, index) in a3_access" :key="index"
              @click="$router.push(`/a3/${row.a3_id}`)"
            >
            <td>
              {{ row.department ? row.department.name : 'Organisatie' }}
            </td>
            <td>
              {{ row.timeframe ? row.timeframe.name : '-' }}
            </td>
            <td>
              {{ $t(`ui.a3.access_types.${row.access_type}`) }}
            </td>
            <td>
              {{ $t(`ui.a3.access_reason.${row.access_reason}`) }}
            </td>
          </tr>
        </tbody>
      </table>

      <button class="a3-button cancel"
              @click="$router.go(-1)"
        >
        <i class="fas fa-chevron-left"></i> {{ $t('ui.general.back') }}
      </button>
    </div>
  </WithMenuContainer>
</template>

<script>

export default {
  props: {
    account_id: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    a3_access: []
  }),

  mounted() {
    this.loadAccessRights();
  },

  methods: {
    loadAccessRights() {
      this.$http.get(`admin/account/${this.account_id}/a3_rights`)
        .then(res => this.a3_access = res.body);
    },
    loadFeatureInfo() {
      this.$http.get('feature_info')
        .then(res => this.feature_info = res.body);
    },
  },
}
</script>
