<template>
  <div class="public-a3-container">
      <PublicA3Header :title="a3_header" :publish_key="publish_key" />

      <LoadingOverlay :loading="is_loading" />

      <div
         v-if="error_key != null"
        style="background: white; max-width: 450px; margin: 30px auto; border-radius: 5px;">
        <InlineErrorBox :error_key="error_key" />
      </div>

      <div style="position: relative; min-height: calc(100vh - 50px);">
        <div class="menu-container" style="margin-top: 0;">
        <div class="menu-item" @click="show_filter_tab = true"
             :class="{'active': isFocusActive}"
             >
             <i class="fas fa-filter" /> {{ $t('ui.a3_document.focus') }}
        </div>
          <div class="menu-item" @click="show_display_tab = true">
              <i class="fas fa-telescope" /> {{ $t('ui.a3_document.display') }}
          </div>
          <div class="menu-item" @click="$router.push(`/public/a3_conversation/${publish_key}`)">
              <i class="fas fa-telescope" /> {{ $t('ui.a3_document.report') }}
          </div>
        </div>

        <div class="a3-document-container">
          <h1 class="a3-title">{{ $tc('ui.general.plan') }}: {{ a3_header }}</h1>
          <A3Document
            v-if="a3_document != null"
            :displayOptions="displayOptions"
            :focusMap="focusMap"
            :editable="false"
            :dataManager="dataManager"
            :interactionManager="interactionManager"
            @setFocus="setFocusElement"
          />
        </div>

        <DisplayTab v-if="show_display_tab"
                    @close="show_display_tab = false"
                    :displayOptions="displayOptions"
                    :dataManager="dataManager"
        />
        <FilterTab :a3_document="a3_document"
          v-show="show_filter_tab"
          :focusOptions="focusOptions"
          :visible="show_filter_tab"
          :dataManager="dataManager"
          @focusUpdate="focusUpdate"
          @close="show_filter_tab = false" />
      </div>



      <SelectionOverlay v-if="selected != null"
        @closeOverlay="interactionManager.backToDocument()">
        <SbfView v-if="selected.sbf"
              :sbf_id="selected.sbf"
              :dataManager="dataManager"
              :interactionManager="interactionManager"
        />
        <KpiView v-if="selected.kpi"
              :kpi_id="selected.kpi"
              :dataManager="dataManager"
              :interactionManager="interactionManager"
        />
        <ActionView v-if="selected.action"
              :action_id="selected.action"
              :dataManager="dataManager"
              :interactionManager="interactionManager"
        />
      </SelectionOverlay>
  </div>
</template>


<script>

import A3Document from '@/components/A3Document';
import PublicA3Header from '@/components/nav/PublicA3Header';

import KpiView from '@/components/overlays/KpiView';
import ActionView from '@/components/overlays/ActionView';
import SbfView from '@/components/overlays/SbfView';
import SelectionOverlay from '@/components/SelectionOverlay';

import DisplayTab from '@/components/a3tabs/DisplayTab.vue';
import FilterTab from '@/components/a3tabs/FilterTab.vue';

import focusCalculations from '@/services/focusCalculations.js';

import { PublicA3DataManager } from '@/services/A3DocumentManagers/data_public';
import { DocumentSelectionInteractionManager } from '@/services/A3DocumentManagers/interaction_public';


export default {

  components: {
    A3Document,
    PublicA3Header,
    SelectionOverlay,
    KpiView,
    SbfView,
    ActionView,
    DisplayTab,
    FilterTab,
  },

  props: {
    publish_key: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    error_key: null,
    is_loading: false,

    dataManager: null,
    interactionManager: null,

    displayOptions: {
      action_pi_numbers: false,
      show_trail: true,
      show_plan_source: false,
      show_pushed_to: false,
      pi_status: true,
      pi_target: false,
      pi_current_value: false,
      action_check: true,
      action_person: true,
      action_milestone: true,
      action_progress: false,
    },

    // Exact mapping for every SBF, KPI and Action
    // such that we do not recalculate things
    focusMap: null,
    // The options, as specified in the FilterTab,
    // used to calculate the map when this changes
    focusOptions: null,

    show_display_tab: false,
    show_filter_tab: false,
  }),

  computed: {
    isFocusActive() {
      if (this.focusMap == null) return false;
      return this.focusMap.sbf ||
        this.focusMap.kpi ||
        this.focusMap.action;
    },
    selected() {
      return this.interactionManager.selection;
    },
    a3_header() {
      if (this.a3_document == null) return '';

      let meta = this.a3_document.meta;
      if (meta.department != null) {
        return `${meta.department.name} - ${meta.timeframe.name}`;
      }
      return `${meta.organisation.name} - ${meta.timeframe.name}`;
    },

    a3_document() {
      if (this.dataManager == null) { return null; }

      return this.dataManager.a3Document;
    },
    relations() {
      return this.a3_document.meta.relations;
    },
  },

  mounted() {
    this.dataManager = new PublicA3DataManager(this.publish_key);
    this.interactionManager = new DocumentSelectionInteractionManager();

    this.loadData();
  },

  watch: {
    publish_key() {
      this.dataManager = new PublicA3DataManager(this.publish_key);
      this.loadData();
    }
  },

  methods: {

    loadData() {
      this.is_loading = true;
      this.error_key = null;

      this.dataManager
        .loadDocument()
        .then((a3_document) => {
          this._computedWatchers.a3_document.run();
          this._computedWatchers.a3_header.run();
          this.$forceUpdate();

          let style = a3_document.meta.layout.style;
          document.documentElement.style.setProperty(
              '--a3-style-background-color',
              style.background_color
          );
          document.documentElement.style.setProperty(
              '--a3-style-title-color',
              style.title_color
          );
          document.documentElement.style.setProperty(
              '--a3-style-header-color',
              style.header_color
          );
            document.documentElement.style.setProperty(
                '--a3-style-border-color',
                style.border_color
            );
            document.documentElement.style.setProperty(
                '--a3-style-border-width',
                `${style.border_width}px`
            );

          this.is_loading = false;
        }, () => {
          this.error_key = "a3.not_found";
          this.is_loading = false;
        });
    },

    focusUpdate(focusOptions) {
      this.focusOptions = focusOptions;
      this.focusMap = focusCalculations.createFocusMap(this.focusOptions, this.a3_document);
    },

    setFocusElement(ev) {
      if (ev.type === 'sbf' && this.focusOptions.sbf) {
        let entryIndex = this.focusOptions.sbf.findIndex(x => x.id == ev.sbf.id);
        if (entryIndex == -1) {
          this.focusOptions.sbf.push(ev.sbf);
        }
        if (entryIndex >= 0) this.focusOptions.sbf.splice(entryIndex, 1);
      }

      if (ev.type === 'kpi' && this.focusOptions.kpi) {
        let entryIndex = this.focusOptions.kpi.findIndex(x => x.id == ev.kpi.id);
        if (entryIndex == -1) {
          this.focusOptions.kpi.push(ev.kpi);
        }
        if (entryIndex >= 0) this.focusOptions.kpi.splice(entryIndex, 1);
      }

      this.focusMap = focusCalculations.createFocusMap(this.focusOptions, this.a3.a3_document);
    },
  },
}

</script>

<style lang="scss">
.public-a3-container {
  background-color: var(--a3-style-background-color);
}

.public-a3-container .a3-title {
    color: var(--a3-style-title-color);
    padding-left: 30px;
    padding-top: 30px;
    margin-top: 0;
    margin-bottom: 0;
}
</style>
