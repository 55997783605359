export default {
  data: () => ({
    management_groups: null,
  }),

  methods: {
    loadManagementGroups() {
      this.$http.get('organisation/report_group')
        .then((res) => this.management_groups = res.body)
    },
  },

  mounted() {
    this.loadManagementGroups();
  },

}
