<template>
  <WithMenuContainer>
    <div>
      <h2>{{ $t('ui.layout.edit') }} </h2>

      {{ error_key }}

      <LayoutForm
        v-if="layout != null"
        :layout="layout"
        @submit="updateLayout"
        @submit_and_use="updateAndUseLayout"
        @delete="show_delete_overlay = true"
        @cancel="$router.go(-1)"
      />

      <ConfirmationOverlay
             v-if="show_delete_overlay"
             @submit="deleteLayout"
             @cancel="show_delete_overlay = false"
       >
         {{ $t('ui.layout.confirm_delete', [layout.name]) }}
      </ConfirmationOverlay>
    </div>
  </WithMenuContainer>
</template>

<script>

import LayoutForm from '@/components/layout/LayoutForm.vue';

export default {

  components: {
    LayoutForm
  },

  props: {
    layout_id: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    layout: null,
    show_delete_overlay: false,
    error_key: null,
  }),

  mounted() {
    this.loadLayout();
  },

  methods: {
    loadLayout() {
      this.$http.get(`layout/${this.layout_id}`, this.layout)
        .then(res => {
          this.layout = res.body;
          if (this.layout.descriptions == null) { this.layout.descriptions = {}; }
          if (this.layout.style == null) { this.layout.style = {}; }
          if (this.layout.structure == null) { this.layout.structure = {}; }

          return this.$http.get(`layout/${this.layout_id}/logo`)
        })
        .then(res => {
          this.layout.style.image_preview = res.body;
        }, err => {
          this.layout.style.image_preview = null;
        });

    },

    updateLayout() {
      this.$http.put(`layout/${this.layout.id}`, this.layout)
        .then(() => {
           return this.saveLogo()
        }, () => {
            this.error_key = "layout_error_on_save";
            return;
        })
        .then(() => {
            if (this.error_key == null) {
               this.$router.push('/organisation/layout');
            }
        }, () => {
            this.error_key = "invalid_logo";
        });
    },

    saveLogo() {
      let file = this.layout.style.logo_file;
      if (!file || !file.name) {
        if (this.layout.style.image_preview == null) {
          return this.$http.delete(`layout/${this.layout.id}/logo`);
        }

        return Promise.resolve();
      }

      let formData = new FormData();
      formData.append('logo_file', file);

      return this.$http.post(`layout/${this.layout.id}/logo`, formData);
    },

    updateAndUseLayout() {
      this.$http.put(`layout/${this.layout.id}?activate=true`, this.layout)
        .then(() => {
           return this.saveLogo()
        }, (err) => {
            this.error_key = "layout_error_on_save";
        })
        .then(() => {
            if (this.error_key == null) {
               this.$router.push('/organisation/layout');
            }
        }, () => {
            this.error_key = "invalid_logo";
        });
    },

    deleteLayout() {
      this.$http.delete(`layout/${this.layout.id}`)
        .then(() => {
          this.$router.push('/organisation/layout');
        });
    },
  },
}
</script>
