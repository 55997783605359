
import SupportView from "@/views/SupportView.vue";

import Advisors from "@/views/support/Advisors.vue";
import Feedback from "@/views/support/Feedback.vue";
import Manual from "@/views/support/Manual.vue";
import Updates from "@/views/support/Updates.vue";

export default [
    {
        path: "/support",
        component: SupportView,
        children: [
            {
                component: Manual,
                name: "support-manual",
                path: "manual",
            },
            {
                component: Advisors,
                name: "support-advisors",
                path: "advisors",
            },
            {
                component: Feedback,
                name: "support-feedback",
                path: "feedback",
            },
            {
                component: Updates,
                name: "support-updates",
                path: "updates",
            },
        ]
    },
];

