<template>
  <WithOrganisationMenuContainer>
    <div style="width: 960px; margin: 0 auto;">
      <h1>{{ organisation.name }} : {{ $t('definitions.terms.report_groups') }}

        <InfoBox tkey="report_groups" :html="true" />
      </h1>

      <table class="a3-table">
        <thead>
          <tr>
            <th style="width: 10%"></th>
            <th>{{ $t('ui.general.name') }} </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(mg, index) in management_groups" :key="mg.id"
              :class="{'selected': selected == index}"
              @click="toggleSelection(index)"
            >
            <td>
              <i class="fa fa-check" v-if="selected == index"></i>
              {{ index + 1 }} </td>
            <td>{{mg.name}} </td>
          </tr>



          <tr v-if="$hasFeature('OrgEditReportGroup') && !adding_group">
            <td></td>
            <td @click="adding_group = true">
              <i class="fas fa-plus"></i> {{ $t('ui.organisation.add_report_group') }}
            </td>
          </tr>
        </tbody>
      </table>

        <ConfirmationOverlay
               v-if="show_delete_overlay"
               @submit="deleteGroup"
               @cancel="show_delete_overlay = false"
         >
            Weet je zeker dat je deze rapportage groep wilt verwijderen?
        </ConfirmationOverlay>

      <div style="text-align: right;" v-if="selected != null">
        <button class="a3-button danger" @click="show_delete_overlay = true">
          <i class="fas fa-trash"></i> {{ $t('ui.general.delete') }}
        </button>
      </div>

      <div class="element-form">
        <div class="form-field" v-if="adding_group">
          <label>{{ $t('ui.general.name') }} </label>
            <input v-model="new_group.name" class="a3-input" />

            <div class="form-buttons" >
              <button class="a3-button action" @click="saveGroup">
                {{ $t('ui.general.save') }}
              </button>
              <button class="a3-button cancel" @click="adding_group = false">
                {{ $t('ui.general.cancel') }}
              </button>
            </div>
        </div>
      </div>
    </div>
  </WithOrganisationMenuContainer>
</template>


<script>

import ManagementGroups from '@/mixins/ManagementGroups.js';

export default {

  mixins: [ManagementGroups],

  data: () => ({
    adding_group: false,
    selected: null,
    show_delete_overlay: false,
    new_group: {
      name: null,
    },
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    }
  },

  methods: {
    toggleSelection(index) {
      this.selected = this.selected == index ? null : index;
    },

    deleteGroup() {
      let rg_id = this.management_groups[this.selected].id;
      this.$http.delete(`organisation/report_group/${rg_id}`)
        .then(() => {
          this.loadManagementGroups();
          this.show_delete_overlay = false;
        })
    },

    saveGroup() {
      this.$http.post(`organisation/report_group`, this.new_group)
        .then(res => {
          this.new_group.name = null;
          this.adding_group = false;
          this.management_groups.push(res.body);
        }, err => {
          // TODO!
        })
    },
  },
}
</script>
