<template>
  <div>
    <h2 class="element-form-title"><i class="fas fa-tasks"></i>
        {{ $t('ui.action.edit') }}
    </h2>

    <ActionForm
      :dataManager="dataManager"
      :interactionManager="interactionManager"
      :show_deploy="dataManager.getCapability('action.deploy')"
      :action="action"
      :child_departments="relations.children"
      :push_a3docs="push_a3docs"
      @submit="saveAction"
      @cancel="$router.go(-1)"
      v-if="action.id != null"
    />

  </div>
</template>

<script>

import ActionForm from '@/components/forms/ActionForm.vue';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {

  mixins: [ WithDocumentManager ],
  components: {
    ActionForm,
  },

  props: {
    action_id: {
      type: String,
      required: true,
    },
  },

  watch: {
    a3_document() {
      this.loadAction();
    },
  },

  data: () => ({
    action: {
      a3doc_id: null,
      name: '',
      sbf_id: null,
      kpi_id: null,
      result_area: null,
      org_area: null,
      start_date: null,
      target_date: null,
    },
    push_a3docs: [],
  }),

  mounted() {
    this.loadAction();
    if (this.$route.query.org_area != null) {
      this.action.org_area = this.$route.query.org_area;
    }
  },

  methods: {
    saveAction() {
      this.dataManager
        .saveAction(this.action, this.push_a3docs)
        .then(() => {
            this.interactionManager.backToDocument();
        })
    },

    loadAction() {
      this.action = {...this.dataManager.getAction(this.action_id)};
      this.push_a3docs = this.action
        .child_refs
        .map(x => x.a3doc_id);
    },
  }

}
</script>
