<template>
  <div class="action-measurement-container">
    <h1>Actie - Link bewerken</h1>
    <em>{{ action.name }}</em>

    <LinkForm
       v-if="link != null"
       :link="link"
       @cancel="$router.go(-1)"
       @delete="show_delete_overlay = true"
       @submit="saveLink"
    />

    <ConfirmationOverlay
           v-if="show_delete_overlay"
           @submit="deleteLink"
           @cancel="show_delete_overlay = false"
    >
      Weet je zeker dat je deze link wilt verwijderen?
    </ConfirmationOverlay>
  </div>
</template>

<script>

import LinkForm from '@/components/forms/LinkForm.vue';
import WithDocumentManager from '@/mixins/WithDocumentManager';

export default {

  mixins: [WithDocumentManager],
  components: { LinkForm },

  props: {
    action_id: {
      type: String,
      required: true,
    },
    link_id: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    link: null,
    show_delete_overlay: false,
  }),

  computed: {
    action() {
      for (let container of Object.values(this.a3_document.actions)) {
        for (let y of container) {
          if (y.id == this.action_id) return y;
        }
      }
      return null;
    },
  },

  mounted() {
    this.loadLink();
  },

  methods: {
    loadLink() {
      this.dataManager
        .loadActionLink(parseInt(this.link_id), this.action_id)
        .then((link) => { this.link = link; });
    },

    saveLink() {
      this.dataManager
        .updateActionLink(this.link, this.action_id)
        .then(() => { this.interactionManager.selectAction(this.action_id) });
    },

    deleteLink() {
      this.dataManager
        .deleteActionLink(this.link.id, this.action_id)
        .then(() => { this.interactionManager.selectAction(this.action_id) });
    },
  },
}
</script>
