
import AdminView from "@/views/admin/AdminView.vue";

import TimeframeCreate from "@/views/organisation/TimeframeCreate.vue";
import TimeframeEdit from "@/views/organisation/TimeframeEdit.vue";

import PersonCreate from "@/views/admin/PersonCreate.vue";
import AccountEditFeatures from "@/views/admin/AccountEditFeatures.vue";

export default [
  {
    path: "/admin",
    component: AdminView,
    children: [
      { path: "accounts/create", name: "admin-account-create", component: PersonCreate },
      {
        path: "accounts/:account_id/features/edit",
        name: "admin-account-edit-features",
        component: AccountEditFeatures,
        props: true,
      },
    ],
  },
  { path: "/timeframe/create", component: TimeframeCreate },
  { path: "/timeframe/edit/:timeframe_id", component: TimeframeEdit, props: true },
];
