<template>
  <WithSheetMenuContainer :a3doc_id="a3doc_id">
    <LoadingOverlay :loading="is_loading" />
    <div class="a3-sheet-container">

        <div v-if="a3_document != null" id="a3-document-container">

          <h1 class="sbf-sheet-title">
            {{ $tc('ui.sbf_fillout.sbf_fillout') }}: {{ a3_header }}
          </h1>
          <h2 class="sbf-sheet-sub-title" v-if="a3_document.meta.meta.matrix_name != null">Programma: {{ a3_document.meta.meta.matrix_name }}</h2>

        <SbfFilloutSheet
          v-if="dataManager && interactionManager"
          :sbf_id="sbf_id"
          :dataManager="dataManager"
          :interactionManager="interactionManager"
          />
        </div>

        <template v-if="$route.name != 'a3-sbf-fillout'">
          <SelectionOverlay @closeOverlay="interactionManager.backToDocument()" >
            <router-view
                  :dataManager="dataManager"
                  :interactionManager="interactionManager"
                  :editable="editable"
              ></router-view>
          </SelectionOverlay>
        </template>
    </div>
  </WithSheetMenuContainer>
</template>

<script>

import SbfFilloutSheet from '@/components/sheets/SbfFilloutSheet.vue';
import WithSheetMenuContainer from '@/views/WithSheetMenuContainer.vue';

import SelectionOverlay from '@/components/SelectionOverlay';
import { SbfFilloutInteractionManager } from '@/services/A3DocumentManagers/interaction_sbffillout.ts';
import { StandardA3DataManager } from '@/services/A3DocumentManagers/data.ts';

export default {
  name: 'a3sheet',

  components: {
    SbfFilloutSheet,
    SelectionOverlay,
    WithSheetMenuContainer,
  },

  props: {
    a3doc_id: {
      type: String,
      required: true,
    },

    sbf_id: {
      type: String,
      required: true,
    },
  },

  watch: {
    a3doc_id() {
      // When the ID changes, we need new interaction managers
      this.interactionManager = new SbfFilloutInteractionManager(
        parseInt(this.a3doc_id, 10),
        parseInt(this.sbf_id, 10),
        this.$router
      );
      this.dataManager = new StandardA3DataManager(parseInt(this.a3doc_id));

      this.loadData();
    },
    sbf_id() {
      // When the ID changes, we need new interaction managers
      this.interactionManager = new SbfFilloutInteractionManager(
        parseInt(this.a3doc_id, 10),
        parseInt(this.sbf_id, 10),
        this.$router
      );

      this.loadData();
    },

    // Reload A3 if someone enters the route
    $route (to) {
      if (to.name == 'a3-sbf-fillout') { this.loadData(); }
    },
  },

  data: () => ({
    is_loading: false,

    interactionManager: null,
    dataManager: null,

    a3_right: null,
  }),

  computed: {
    relations() {
      return this.a3_document.meta.relations;
    },
    a3_document() {
      if (!this.dataManager) { return null; }
      return this.dataManager.a3Document;
    },

    displayOptions() {
        return this.$store.getters['settings/displayOptions'];
    },
    editable() {
      if (!this.a3_right) return false;

      return (
          (this.a3_right.access_reason === "Department" && this.$store.getters['auth/hasFeature']('A3Edit'))
            || this.a3_right.access_type == "ReadWrite"
          )
        && !this.print_preview;
    },

    a3_header() {
      let meta = this.a3_document.meta;
      if (meta.department != null) {
        return `${meta.department.name} - ${meta.timeframe.name}`;
      }
      return `${meta.organisation.name} - ${meta.timeframe.name}`;
    },
  },

  mounted() {
    this.interactionManager = new SbfFilloutInteractionManager(
      this.a3doc_id,
      this.sbf_id,
      this.$router
    );
    this.dataManager = new StandardA3DataManager(this.a3doc_id);

    if (this.a3_document == null) {
      this.loadData();
      this.loadRights();
    }
  },

  methods: {
    loadData() {
      this.is_loading = true;
      this.dataManager
        .loadDocument()
        .then(a3doc => {
          // update style variables
            let style = a3doc.meta.layout.style;
            document.documentElement.style.setProperty(
                '--a3-style-background-color',
                style.background_color
            );
            document.documentElement.style.setProperty(
                '--a3-style-title-color',
                style.title_color
            );
            document.documentElement.style.setProperty(
                '--a3-style-header-color',
                style.header_color
            );
            document.documentElement.style.setProperty(
                '--a3-style-border-color',
                style.border_color
            );
            document.documentElement.style.setProperty(
                '--a3-style-border-width',
                `${style.border_width}px`
            );

          this.is_loading = false;
        });
    },

    loadRights() {
        this.$http.get('a3_rights')
            .then(res => {

                let available_rights = res.body[this.id];

                if (available_rights == null) return;

                let read = available_rights.find((x) => x.access_type === "Read");
                let write = available_rights.find((x) => x.access_type === "ReadWrite");

                if (write) { this.a3_right = write; return; }
                if (read) { this.a3_right = read; return; }
            })
    },

  },
}
</script>

<style lang="scss">

.a3-header-row > .a3-element {
    text-align: left;
}

.a3-element {
    font-size: 14px;
    vertical-align: top;
    padding: 10px;
    border-radius: 5px;
    background-color: hsl(188, 10%, 98%);
    color: black;

    border-style: solid;
    border-width: var(--a3-style-border-width);
    border-color: var(--a3-style-border-color);

    transition: border-width 2s ease-in-out, border-color 2s ease-in-out;

    h2 {
        user-select: none;
        margin-top: 5px;
        margin-bottom: 0px;
        color: var(--a3-style-header-color);
        font-size: 16px;
    }
    > p, ol, ul {
        margin-top: 5px;
    }
}


.a3-sheet-container {
    background-color: var(--a3-style-background-color);
    transition: background-color 2s ease-in-out;

    .sbf-sheet-title {
        color: white;
        transition: color 2s ease-in-out;
        margin-left: 55px;
        margin-top: 20px;
        margin-bottom: 0;
        display: inline-block;
        vertical-align: bottom;
    }

    .sbf-sheet-sub-title {
      margin-top: 5px;
      margin-left: 55px;
      font-style: italic;
    }
}
.a3-sheet-title {
    position: relative;
    height: 50px;
}

.a3-sheet-header.review-active, .a3-sheet-body.review-active {
    width: 80%;
}

.a3-sheet-header {
    transition: width .3s ease-in-out;
    padding: 10px;
    width: 100%;
    border-spacing: 10px;
    min-height: 200px;

    & td {
        transition: width 2s ease-in-out, border-width 2s ease-in-out, border-color 2s ease-in-out;
    }
}

.a3-sheet-body {
    transition: width .3s ease-in-out;
    border-spacing: 10px;
    padding: 10px;
    width: 100%;
    min-height: 450px;
    & td {
        transition: width 2s ease-in-out, border-width 2s ease-in-out, border-color 2s ease-in-out;
    }
}




.hide-full-height {
}

#a3-print-container {
  transition: transform .5s ease-out, padding .5s ease-out;
  transform-origin: top left;

  &.print-preview {
    transform: scale(0.5);
    padding: 20px;
    border: 2px white solid;
  }
}

</style>
