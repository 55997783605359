<template>
  <div class="error-box" :class="severity">
    <i class="fas fa-exclamation-triangle"></i>
    <div class="error-box-text">
      {{ $t(`errors.${error_key}`) }}
    </div>
  </div>
</template>


<script>

export default {
  props: {
    error_key: {
      type: String,
      required: false,
      default: 'general.generic',
    },

    severity: {
      type: String,
      required: false,
      default: 'error',
    },
  }
}
</script>

<style lang="scss">

$error-color: #dd1c1a;
$warning-color: #B88400;

.error-box {
  margin: 15px 0;
  padding: 10px;
  text-align: center;

  &.error {
    color: $error-color;
    background-color: rgba($error-color, 0.03);
    border: 1px rgba($error-color, .3) solid;
    i {
      color: rgba($error-color, .3);
    }
  }
  &.warning {
    color: $warning-color;
    background-color: rgba($warning-color, 0.03);
    border: 1px rgba($warning-color, .3) solid;
    i {
      color: rgba($warning-color, .3);
    }
  }

  border-radius: 5px;

  i {
    font-size: 2em;
    display: inline-block;
    padding-right: 20px;
    width: 30px;
  }

  .error-box-text {
    display: inline-block;
    text-align: left;
    width: calc(100% - 50px);
    vertical-align: top;
  }
}
</style>
