export default {

  methods: {
    A3NameFromRelations(relations, a3_id, shortened = true) {
        if (!this.relations || !this.relations.children) return ".";

        let found_child = this.relations.children
          .find(x => x.a3_ref && x.a3_ref.a3doc_id == a3_id)
        let name;
        if (found_child) name = found_child.department.name;

        if (!name) {
          for (let p of this.relations.parents) {
              let a3_ref = p.a3_ref.find(x => x.a3doc_id == a3_id);
              if (!a3_ref) continue;

              if (a3_ref.matrix_name != null) {
                  name = `[P] ${a3_ref.matrix_name}`;
              } else {
                  if (p && p.parent.Organisation) {
                      name = p.parent.Organisation.name;
                  }
                  if (p && p.parent.Department) {
                      name = p.parent.Department.name;
                  }
              }
              break;
          }
        }

        if (!name) return `[onbekende a3.. (${a3_id})]`;

        if (shortened && name.length > 30) {
          name = name.substring(0, 30) + '...';
        }

        return name;
    },
  },
}
