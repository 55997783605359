<template>
  <div class="review-row"
       v-if="reviewEdits.length > 0 || reviewComments.length > 0"
    >

    <h3>{{ $t('definitions.terms.vision') }}</h3>
    <table v-if="reviewEdits.length > 0" >
      <thead>
        <tr>
          <th></th>
          <th>{{ $t('ui.review.was') }}</th>
          <th>{{ $t('ui.review.becomes') }}</th>
        </tr>
      </thead>
      <template v-for="(reviewEdit, index) in reviewEdits">
          <tr :key="index">
            <td></td>
            <td>--</td>
            <td>
              <RichTextRender :value="reviewEdit.new_value" />
            </td>
          </tr>
      </template>
    </table>

    <div class="review-report-comment-container"
         v-if="reviewComments.length > 0"
      >
      <h4>{{ $t('ui.review.comments') }}</h4>
      <div class="review-report-comment"
           v-for="comment in reviewComments"
           :key="comment.id"
        >
        <RichTextRender :value="comment.new_value.comment" />
      </div>
    </div>
  </div>
</template>


<script>

import WithDocumentManager from '@/mixins/WithDocumentManager';

import RichTextRender from '@/components/forms/RichTextRender';

export default {
  mixins: [WithDocumentManager],
  components: {
    RichTextRender,
  },

  computed: {
    reviewEdits() {
      return this.dataManager
        .review
        .elements
        .filter(x => x.target_type == "Vision")
        .filter(x => x.element_type == "Edit")
      ;
    },

    reviewComments() {
      return this.dataManager
        .review
        .elements
        .filter(x => x.target_type == "Vision")
        .filter(x => x.element_type == "Comment")
      ;
    }
  },
};
</script>

<style lang="scss">

.review-row {
    table {
        margin-top: 15px;
        width: 100%;
        td:first-child {
            width: 20% !important;
        }
        td {
            width: 40%;
        }
        th {
            text-align: left;
        }
    }

    h4 {
        color: $a3-green;
        opacity: .6;
        margin-bottom: 8px;
    }
}

.review-report-comment-container {
    box-sizing: border-box;
    padding-left: 20%;
    padding-top: 10px;

    .review-report-comment {
        padding: 20px;
        background-color: rgba($a3-green, .05);
        border-radius: 10px;
        width: auto;
        margin-bottom: 10px;
    }
}
</style>
