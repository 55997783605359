<template>
  <WithOrganisationMenuContainer>
    <div class="container">

      <h2>{{ $t('ui.advisor.advisors') }} </h2>

      <table class="a3-table">
        <thead>
          <tr>
            <th>{{ $t('ui.general.name') }} </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="p in organisation_advisors" :key="p.id" >
              <td> {{ p.person.name }} </td>
              <td>
                <button class="a3-button danger"
                        @click="deleteOrganisationAdvisor(p.person)"
                  >
                  <i class="fas fa-unlink"></i> {{ $t('ui.advisor.unlink') }}
                </button>
              </td>
          </tr>
        </tbody>
      </table>

      <div style="text-align: right">
        <button class="a3-button action"
                v-if="$hasFeature('Root')"
                @click="$router.push('/organisation/advisor/add')"
          >
          <i class="fas fa-chalkboard-teacher"></i>
          {{ $t('ui.advisor.add_advisor') }}
        </button>
      </div>
    </div>
  </WithOrganisationMenuContainer>
</template>

<script>

export default {

  data: () => ({
    organisation_advisors: [],
  }),

  mounted() {
    this.loadOrganisationAdvisors();
  },

  methods: {
    loadOrganisationAdvisors() {
      this.$http.get(`organisation/advisors`)
        .then((res) => {
          this.organisation_advisors = res.body
              .map(x => ({
                person: x[0],
              }));
        })
    },

    deleteOrganisationAdvisor(person) {
      this.$http.delete(`organisation/advisor/${person.id}`)
        .then(() => {
            return this.loadOrganisationAdvisors();
      });
    },
  },
};
</script>
