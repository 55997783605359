import store from "@/store/index";

import Vue from "vue";
import VueRouter from "vue-router";

import A3Index from "@/views/A3Index.vue";

import About from "@/views/About.vue";
import Main from "@/views/Main.vue";
import AdvisorProfileEdit from "@/views/user/AdvisorProfileEdit.vue";

import adminRoutes from "@/router/admin";
import authRoutes from "@/router/auth";
import meRoutes from "@/router/me";
import organisationRoutes from "@/router/organisation";
import publicA3Routes from "@/router/publica3";
import supportRoutes from "@/router/support";
import reportRoutes from "@/router/report";

import a3sheetRoutes from "@/router/a3sheet";
import a3sbffilloutRoutes from "@/router/a3sbffillout";
import a3AdminRoutes from "@/router/a3admin";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "main", component: Main},
  { path: "/about", name: "about", component: About},
  { path: "/a3", name: "a3-index", component: A3Index },

  ...authRoutes,

  ...meRoutes,
  ...publicA3Routes,

  ...adminRoutes,

  ...a3AdminRoutes,
  ...a3sheetRoutes,
  ...a3sbffilloutRoutes,

  ...organisationRoutes,
  ...reportRoutes,

  ...supportRoutes,

  {
      path: "/advisor/profile/edit",
      name: "advisor-profile-edit",
      component: AdvisorProfileEdit,
  },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta !== undefined && to.meta.pub) {
        next();
        return;
    }

    // We explicitly do not retrieve information from the vuex store,
    // As it seems unreliable.
    const token = window.localStorage.getItem("auth_token");
    if (token == undefined) { next("/login"); return; }

    for (const feature of to.matched.map(x => x.meta.requiredFeatures ? x.meta.requiredFeatures : []).flat()) {
        if (!store.getters["auth/hasFeature"](feature))  {
            next(false);
            return;
        }
    }

    // Load the user before continuing the route
    if (store.getters["auth/account"] == undefined) {
        const query = Object.entries(to.query)
            .map((kv) => `${kv[0]}=${String(kv[1])}`)
            .join("%26");
        next(`/loading?r=${to.path}%3F${query}`);

        return;
    }

    if (store.getters["auth/organisation"] == undefined
        && !to.fullPath.startsWith("/admin")
    ) {
        next("/admin/organisation");
    }

    next();
});

export default router;
